import React, {useRef, useEffect, useState} from 'react';
import {Column} from '@ant-design/plots';
import PropTypes from 'prop-types';
import Meituan from '../../../../../asset/meituanDot.png';
import Haluo from '../../../../../asset/haluoDot.png';
import Qingju from '../../../../../asset/qingjuDot.png';
import {Button} from 'antd';
import html2canvas from 'html2canvas';

const DemoColumn = ({data, barType, isExportOpen, id, title, dataType}) => {
  // console.log(data);
  const titleType = () => {
    switch (barType) {
      case '未备案':
        return '未备案率';
      case '跨区域':
        return '跨区域率';
      case '重点工作区域保障':
        return '告警数';
      case '人员在线':
        return '在线率';
      case '车辆在线':
        return '在线率';
      default:
        return '出勤率';
    }
  };
  const [urlGet, setUrlGet] = useState();
  const config = {
    data,
    xField: 'type',
    yField: dataType === 'value' ? 'value' : 'persentValue',
    yAxis: {
      label: {
        formatter: (v) => {
          return dataType === 'value' ? v : `${v}%`;
        },
      },
    },
    color: ({type}) => {
      switch (type) {
        case '美团':
          return '#E89E0D';
        case '哈啰':
          return '#1D39C4';
        default:
          return '#13BEC6';
      }
    },
    label: {
      position: 'middle', // 'top', 'bottom', 'middle',】
      // 配置样式
      formatter: (value) => {
        // return value.persent;
        return dataType === 'value' ? value.value : value.persent;
      },
      style: {
        fill: '#FFF',
        opacity: 0.7,
      },
    },
    tooltip: {
      customContent: (title, data) => {
        return `
            <h6>
            <img src=${title === '美团' ? Meituan : title === '哈啰' ? Haluo : Qingju} alt="" />
            ${title}
            </h6>
            <h5>${titleType()}：${barType !== '重点工作区域保障' ? data[0]?.data.persent : data[0]?.data.value}</h5>
            <h6></h6>
            `;
        // <h6>${barType === '未备案' ? '未备案数：' : '出勤数：'}${data[0]?.data.value}</h6>
      },
    },
    meta: {
      value: {
        alias: '出勤',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    minColumnWidth: 80,
    maxColumnWidth: 80,
  };
  const ref = useRef();

  // 导出图片
  const downloadImage = (name) => {
    const downName = `${name}柱状图`;
    ref.current?.downloadImage(downName);
  };
  useEffect(() => {
    if (isExportOpen) {
      setTimeout(() => {
        const node = document.getElementById(id);
        html2canvas(node, {backgroundColor: '#ffffff'}).then((canvas) => {
          setUrlGet(canvas.toDataURL());
        });
      }, 1500);
    }
  }, []);
  return (
    <div className={!isExportOpen ? 'bar-export' : ''}>
      {!isExportOpen && (
        <Button type="primary" onClick={() => downloadImage(title)} className="btn-export">
          下载
        </Button>
      )}
      {urlGet ? (
        <img src={urlGet} alt="" />
      ) : (
        <div id={id}>
          <Column
            className="bar-column"
            {...config}
            onReady={(plot) => {
              ref.current = plot;
            }}
          />
        </div>
      )}
    </div>
  );
};

DemoColumn.propTypes = {
  data: PropTypes.array.isRequired,
  barType: PropTypes.string,
  isExportOpen: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  dataType: PropTypes.string,
};

export default DemoColumn;
