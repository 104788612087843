import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber, message,
  Row,
  Space,
  TimePicker,
} from 'antd';
import editIcon from '../../../asset/edit.png';
import PropTypes from 'prop-types';
import {attendanceDateStatus} from '../../../components/statusCollection';
import {clockFormat, initTimeValue, momentToClock} from '../../../components/baseTime';
import {arr, options} from '../config';
import {DeleteOutlined, PlusOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import SetClockInArea from './SetClockInArea';
import {updateGroupAttendanceRules} from '../../../api';


const RuleInfo = ({baseInfo, id, onFinished, updateInfo}) => {
  const [commonRule] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [initAddress, setInitAddress] = useState(null);
  const [initLat, setInitLat] = useState(null);
  const [initLng, setInitLng] = useState(null);
  const [initRadius, setInitRadius] = useState(null);
  const onFinish = (values) => {
    const data={
      attendanceCommonRule: {
        attendanceDate: values.attendanceDate,
        attendanceDayInterval: momentToClock(values.attendanceDayInterval),
        isExcludeHolidays: values.isExcludeHolidays[0] || false,
        id: id,
      },
      addAttendanceRules: values.addAttendanceRules.map((item) => ({
        ...item,
        startTime: momentToClock(item.startTime),
        endTime: momentToClock(item.endTime),
        earliestTime: momentToClock(item.earliestTime),
        latestTime: momentToClock(item.latestTime),
      })),
    };
    updateGroupAttendanceRules(id, data, 2).then((res)=>{
      message.success(res.msg);
      onFinished();
      setEdit(false);
    });
  };

  const onClose = () => {
    setVisible(false);
    setInitLat(null);
    setInitAddress(null);
    setInitLng(null);
    setInitRadius(null);
    setIndex(null);
  };
  const onSave = (data) => {
    const value = commonRule.getFieldValue('addAttendanceRules');
    value[index] = {
      ...value[index],
      ...data,
    };
    commonRule.setFieldsValue({
      addAttendanceRules: value,
    });
    commonRule.validateFields();
    setVisible(false);
  };
  useEffect(()=>{
    if (baseInfo&&edit) {
      commonRule.setFieldsValue({
        ...baseInfo,
        isExcludeHolidays: [baseInfo.isExcludeHolidays],
        attendanceDayInterval: initTimeValue(baseInfo.attendanceDayInterval),
        addAttendanceRules: baseInfo.attendanceBasicRule.map((item)=>({
          ...item,
          startTime: initTimeValue(item.startTime),
          endTime: initTimeValue(item.endTime),
          earliestTime: initTimeValue(item.earliestTime),
          latestTime: initTimeValue(item.latestTime),
        })),
      });
    }
  }, [baseInfo, edit]);
  return (
    <div>
      <h3 className="details-title">
        考勤规则
        <span onClick={() => {
          setEdit(true);
        }} className="padding-l-m btn">
          <img src={editIcon} alt="edit"/>
        </span>
      </h3>
      {edit ? (
        <div>
          <Alert message="考勤规则修改后，默认今日24:00:00后生效" type="warning" className="margin-t-l" showIcon/>
          <div className="bg-gray margin-t-m">
            <Form name="commentRules" onFinish={onFinish} form={commonRule}>
              <div className="padding-l-m padding-r-m">
                <Row className="margin-t-l">
                  <Col flex="90px" className="base-rule-header">
                    通用规则:
                  </Col>
                  <Col flex="600px">
                    <Form.Item label="考勤日:" name="attendanceDate" >
                      <Checkbox.Group options={options} className="margin-l-m"/>
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Form.Item name="isExcludeHolidays">
                      <Checkbox.Group>
                        <Checkbox value={true}>中国节假日不打卡</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider style={{marginRight: -32, marginLeft: -32, width: 'calc(100% + 64px)'}}/>
              <Form.List name="addAttendanceRules" rules={[
                {
                  required: true,
                  message: '请添加考勤时段！',
                },
              ]}>
                {(fields, {add, remove}) => (
                  <>
                    <Collapse defaultActiveKey={arr} ghost collapsible="header">
                      {fields.map(({key, name, ...restField}) => (
                        <Collapse.Panel
                          extra={name!==0&&(<DeleteOutlined
                            className="icon-btn"
                            onClick={() => {
                              remove(name);
                            }}/>)}
                          header={<h3>考勤时段{name+1}
                            <span className="tipInfo margin-l-m">
                              <ExclamationCircleFilled style={{color: '#FAAD14'}} />
                           &nbsp;考勤时段时间不可重叠</span>
                          </h3>}
                          key={name}>
                          <Row>
                            <Col span={10}>
                              <Form.Item
                                {...restField}
                                name={[name, 'startTime']}
                                label="该考勤时段上班时间"
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择',
                                  },
                                ]}
                              >
                                <TimePicker
                                  format="HH:mm:ss" style={{width: 180}}/>
                              </Form.Item>
                            </Col>
                            <Col span={14}>
                              <Form.Item
                                {...restField}
                                label="该考勤时段下班时间"
                                name={[name, 'endTime']}
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择',
                                  },
                                ]}
                              >
                                <TimePicker
                                  format="HH:mm:ss" style={{width: 180}}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={10}>
                              <Form.Item
                                {...restField}
                                name={[name, 'earliestTime']}
                                label="最早上班打卡时间"
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择',
                                  },
                                ]}
                              >
                                <TimePicker
                                  format="HH:mm:ss" style={{width: 180}}/>
                              </Form.Item>
                            </Col>
                            <Col span={14}>
                              <Form.Item
                                {...restField}
                                label="最晚下班打卡时间"
                                name={[name, 'latestTime']}
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择',
                                  },
                                ]}
                              >
                                <TimePicker format="HH:mm:ss" style={{width: 180}}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={10}>
                              <Form.Item
                                {...restField}
                                name={[name, 'address']}
                                label="打卡地点"
                                className="padding-r-m"
                                rules={[
                                  {
                                    required: true,
                                    message: '请设置！',
                                  },
                                ]}
                              >
                                <Input.TextArea disabled/>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                label="有效半径"
                                name={[name, 'radius']}
                                rules={[
                                  {
                                    required: true,
                                    message: '请设置！',
                                  },
                                ]}
                              >
                                <InputNumber
                                  addonAfter="米"
                                  min={1}
                                  disabled/>
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <Button onClick={() => {
                                const values = commonRule.getFieldValue('addAttendanceRules')[name];
                                console.log(values);
                                if (values?.latitudeLongitude) {
                                  setInitLng(values.latitudeLongitude.split(',')[0]);
                                  setInitLat(values.latitudeLongitude.split(',')[1]);
                                  setInitAddress(values.address);
                                  setInitRadius(values.radius);
                                } else {
                                  setInitLat(null);
                                  setInitAddress(null);
                                  setInitLng(null);
                                  setInitRadius(null);
                                }
                                setVisible(true);
                                setIndex(name);
                              }} type="link">
                              设置地点
                              </Button>
                              <Form.Item
                                {...restField}
                                label="经纬度"
                                hidden
                                name={[name, 'latitudeLongitude']}
                              >
                                <Input/>
                              </Form.Item>
                            </Col>
                          </Row>
                          {name !== (fields.length - 1) && <Divider/>}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                    <Form.Item className="padding-l-m padding-r-m">
                      <Button type="dashed" size="large" onClick={() => {
                        add();
                      }} block icon={<PlusOutlined/>}>
                      添加考勤时段
                      </Button>
                    </Form.Item>

                  </>
                )}
              </Form.List>
              <Form.Item>
                <div className="text-right">
                  <Space>
                    <Button onClick={()=>{
                      setEdit(false);
                    }}>
                   取消
                    </Button>
                    <Button htmlType="submit" type="primary">
                    保存
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className="details-info margin-t-l">
          <div className="margin-b-l">
            <span className="padding-r-m">
              考勤时段数：
            </span>
            {baseInfo.attendanceCount||'暂无数据'}
          </div>
          <Row className="margin-b-l" gutter={16}>
            <Col flex="100px">
              通用规则：
            </Col>
            <Col flex="auto">
              <Row gutter={16}>
                <Col span={16}>
                  <span className="padding-r-m">
                  考勤日：
                  </span>
                  <span className="text-gray">
                    {attendanceDateStatus(baseInfo.attendanceDate)||'暂无数据'}
                    ，节假日{baseInfo.isExcludeHolidays?'不':''}打卡
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          {baseInfo.attendanceBasicRule&&baseInfo.attendanceBasicRule.map((item, index) => (
            <Row className="margin-b-l" gutter={16} key={'base' + index}>
              <Col flex="100px">
                考勤时段{index + 1}：
              </Col>
              <Col flex="auto">
                <Row gutter={16} className="margin-b-l">
                  <Col span={8}>
                    <span className="padding-r-m">
                    考勤时段：
                    </span>
                    <span className="text-gray">上班：
                      {clockFormat(item.startTime)}～下班：{clockFormat(item.endTime)}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span className="padding-r-m">
                    最早上班打卡时间:
                    </span>
                    <span className="text-gray">{clockFormat(item.earliestTime)}</span>
                  </Col>
                  <Col span={8}>
                    <span className="padding-r-m">
                    最晚下班打卡时间：
                    </span>
                    <span className="text-gray">{clockFormat(item.latestTime)}</span>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <span className="padding-r-m">
                    打卡地点：
                    </span>
                    <span className="text-gray">
                      {item.address}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span className="padding-r-m">
                    打卡地点坐标：
                    </span>
                    <span className="text-gray">{item.latitudeLongitude}</span>
                  </Col>
                  <Col span={8}>
                    <span className="padding-r-m">
                   打卡地点有效半径：
                    </span>
                    <span className="text-gray">距离{item.radius}米以内</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
          {updateInfo&&(<Alert
            message={`${updateInfo}考勤规则修改成功，默认今日24:00:00后生效`}
            type="warning"
            className="margin-t-s margin-b-s" showIcon/>
          )}
        </div>
      )}
      <Drawer
        title="设置打卡地点"
        placement="right"
        width={768}
        onClose={onClose}
        visible={visible}
        destroyOnClose
        maskClosable={false}
      >
        <SetClockInArea
          onSave={onSave}
          initAddress={initAddress}
          initLat={initLat}
          initLng={initLng}
          initRadius={initRadius}
        />
      </Drawer>
    </div>
  );
};

RuleInfo.propTypes = {
  baseInfo: PropTypes.object,
  id: PropTypes.string.isRequired,
  onFinished: PropTypes.func.isRequired,
  updateInfo: PropTypes.string,
};

export default RuleInfo;
