import React from 'react';
import {InfoCircleOutlined} from '@ant-design/icons/lib/icons';
import PieCharts from './PieCharts';
import PropTypes from 'prop-types';
const Supervise = ({pieData, cardData, vehicleType, tabsKey}) => {
  // 占比
  const ratio = (title, num, key) => {
    return (
      <div className="card-content" key={key}>
        <div className="supervise-card-title">
          <div
            className={key === 1 ? 'card-dot card-dot-M' : key === 3 ? 'card-dot card-dot-H' : 'card-dot card-dot-Q'}
          ></div>
          <span>
            {title === 1 ?
              `美团监测${vehicleType}占比` :
              title === 2 ?
              `青桔监测${vehicleType}占比` :
              `哈啰监测${vehicleType}占比`}
          </span>
        </div>
        <p>{num}</p>
      </div>
    );
  };

  return (
    <div className="supervise-content">
      <div className="supervise-hint">
        <InfoCircleOutlined className="supervise-icon" />
        <div>
          <p>指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到{vehicleType}数量。</p>
          {tabsKey === 3 ? (
            <p>
              图表（左）说明：A车企区域监测{vehicleType}数占比=（管理员管辖区域内的定点信息采集设备监测到A车企
              {vehicleType}数量 / 管理员管辖区域内的定点信息采集设备监测到{vehicleType}数量）*100%
            </p>
          ) : (
            <p>
              图表（左）说明：A车企区域监测{vehicleType}数占比=（监测到的A车企{vehicleType}数/监测到的全部{vehicleType}
              数）*100%
            </p>
          )}
        </div>
      </div>
      <div className="target-table">
        <p>车企区域监测{vehicleType}数占比</p>
        <div className="supervise-charts">
          <div className="pie-charts">
            <PieCharts data={pieData} legend={true} title={`车企区域监测${vehicleType}数占比`}></PieCharts>
          </div>
          <div className="supervise-card">
            {cardData?.map((item) => {
              return ratio(item.bikeEnterprise, item.bikeEnterpriseProp, item.bikeEnterprise);
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
Supervise.propTypes = {
  pieData: PropTypes.array.isRequired,
  cardData: PropTypes.array,
  vehicleType: PropTypes.string,
  tabsKey: PropTypes.number,
};

export default Supervise;
