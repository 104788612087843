import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
  Cascader, Col, DatePicker,
  Divider, Form, Input, Modal, Radio,
  Row, Select, Space, Spin, Table, Tabs, Tooltip,
} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {getApiListData, getAreaTreeFilter, getAreaTreeFilter2,
  getTableListData, getBikeAreaTree} from '../../api';
import {exportExcel} from '../excal/excel';
import {getColumnSearchProps, getColumnSearchTimeProps} from './ColumnSearch';
import {useLocation} from 'react-router-dom';
import {storage} from '../../utils/storage';
import UploadFile from './UploadFile';
import {areaStreetTreeData} from '../forms/AreaTreeFuc';
import {ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
const OptionsData = {};
const CustomTable = ({
  addFuc,
  columns,
  InitApi,
  columns2,
  InitApi2,
  buttonGroup,
  updateList,
  expandable,
  resType,
  exportFileName,
  uploadUrl,
  uploadUrl2,
  formList,
  tabOption,
  toIndex,
  scroll,
  changeOptions,
  changeTabs,
  tabs,
  resetData,
  tabsKey,
  updateBikeItems,
  setUpdateBikeItems,
  customTitle,
  rowSelection,
  batchFunction,
  firm,
  tip,
  importType,
  hidePageTitle,
  changeListForTabKey,
  chartTime, // 趋势图与列表联动所需时间
  statistics, // 判断列表是否需要展示入库时间，为标题
  apiType, // 列表其他必传参数
  tableTypename,
  dataElseItemStructure, // 区分表格返回的是否是items数据结构
  noPageSize, // 列表没有返回分页的情况,
  noPageSizeComponent, // 列表不需要分页的情况
  getArea, // 区域
  exportTime, // 导出列表拼接时间
  cantExportAction,
  customTabs,
  otherParam,
  customRowSelection,
}) => {
  const {t} = useTranslation();
  const l = useLocation();
  const [searchForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportTotalCount, setExportTotalCount] = useState();
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
    size: 'small',
    showSizeChanger: true,
  });
  const [filters, setFilters] = useState({});
  const [selectOptions, setSelectOptions] = useState({});
  const [tableType, setTableType] = useState(1);
  const [typeValue, setTypeValue] = useState(null);
  const [typeValueOfDate, setTypeValueOfDate] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [company, setCompany]=useState([]);
  const [incomeDate, setIncomeDate]=useState();
  // 区域选择
  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      // console.log(res.data);
      const type = 2;
      const data = res.data.map((item, index) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}&nbsp;&nbsp;
                <span className="text-light"> {sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type === 4 && sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {aff.managerName || ''}</span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  // disabled: type===4&&aff.managerName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
      if (getArea) {
        getArea(data);
      }
    });
  };
  // const findParentIds=(data, targetId)=>{
  //   for (const obj of data) {
  //     if (obj.id === targetId) {
  //       return [obj.id];
  //     }
  //     if (obj.children !== null) {
  //       const parentIds = findParentIds(obj.children, targetId);
  //       if (parentIds.length > 0) {
  //         return parentIds.concat(obj.id);
  //       }
  //     }
  //   }
  //   return [];
  // };
  // console.log(findParentIds(areaOptions, 25), '?????????????');
  const getAreaGroup2 = () => {
    getAreaTreeFilter2({}, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type = 2;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span className="text-light"> {sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              // disabled: type===4&&sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {aff.managerName || ''}</span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  // disabled: type===4&&aff.managerName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };
  const getAreaGroupByBike = () => {
    getBikeAreaTree(2).then((res) => {
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          label: (<span>
            {item.areaName}
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
              </span>),
              value: sub.id,
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };
  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return (
            <span key={option.value}>
              {option.areaName}
            </span>
          );
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  const displayRender2 = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return (
            <span key={option.value}>
              {option.areaName}
            </span>
          );
        }
        return <span key={option.value}>{label.props.children} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  // search Data format
  const toTrim = (obj) => {
    const data = {
      pageNum: 1,
      pageSize: pagination.pageSize,
    };
    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] || obj[key] === 0) {
          if (typeof (obj[key]) === 'string') {
            data[key] = obj[key].trim();
          } else if (typeof (obj[key]) === 'object') {
            if (obj[key].length > 0) {
              data[key] = obj[key];
            }
          } else {
            data[key] = obj[key];
          }
        }
      });
      return data;
    }
    return {};
  };

  // table title
  const headerTitle = () => {
    const menuTree = storage.getData('userInfo')?.menuTree;
    const linkArr = l.pathname.split('/');
    if (menuTree) {
      const pID = location.pathname.indexOf('/bigScreen') !== -1? linkArr[2]:linkArr[1];
      const sID = location.pathname.indexOf('/bigScreen') !== -1? (linkArr[3] || null):(linkArr[2] || null);
      if (menuTree) {
        const pItem = menuTree.children.find(({menuType}) => menuType === pID);
        if (pItem) {
          if (sID) {
            const sItem = pItem.children.find(({menuType}) => menuType === sID);
            return (sItem.cnName);
          }
          return (pItem.cnName);
        }
        return '';
      }
    }
    return '';
  };
  headerTitle();
  // get select API Data
  const getSelectOptions = (o) => {
    getApiListData(o.url, o.params || null, o.resType, t('flag')).then((res) => {
      OptionsData[o.name] = o.dataKey ? res.data[o.dataKey] : res.data;
      setSelectOptions({
        ...selectOptions,
        ...OptionsData,
      });
    });
  };

  // add search input and other plugin
  const newColumns = (column) => {
    if (company.length&&firm) {
      const newColumns=[...column];
      newColumns.splice(firm[0], 3);
      for (const index of company) {
        const res=column.find((i) => i.company === index);
        if (res) {
          newColumns.splice(newColumns.length-firm[1], 0, res);
        }
      }
      column=[...newColumns];
      // 原始数据修改
      columns=[...newColumns];
    }
    return column.map((item) => {
      let c = {
        ...item,
      };
      if (item?.search) {
        c = {...c, ...getColumnSearchProps()};
      }
      if (item?.searchDate) {
        c = {...c, ...getColumnSearchTimeProps()};
      }
      return c;
    });
  };

  // get table Data
  const getTableList = (data) => {
    setLoading(true);
    const url = tableType === 1 ? InitApi : InitApi2;
    const Data = changeListForTabKey? {...data, ...changeListForTabKey} : {...otherParam, ...data};
    getTableListData(url, Data, t('flag'), resType || 'POST').then((res) => {
      if (statistics) {
        const resDate=res.data;
        setIncomeDate({count: resDate?.totalCount||0, statisticsDate: resDate?.items.statisticsDate||'暂无数据'});
        localStorage.setItem('statisticsDate', resDate?.items.statisticsDate||'暂无数据');
      }
      if (res.data.bikeEnterprise) {
        setCompany(res.data.bikeEnterprise);
      }
      setTableData([]);
      if (toIndex) {
        const listDate=statistics?res.data.items?.list:res.data.items;
        setTableData(listDate?.map((item, index) => (
          {
            ...item,
            index: (res.data.pageNum - 1) * res.data.pageSize + 1 + index,
          }
        )));
      } else if (dataElseItemStructure) {
        setTableData(res.data[dataElseItemStructure]);
      } else {
        setTableData(res.data.items);
      }
      if (noPageSize) {
        setExportTotalCount(res.data.totalCount);
        setPagination({
          ...pagination,
          total: res.data.count,
          // pageSize: res.data.pageSize,
          current: res.data.pageNum,
          // total: res.data.totalPage * res.data.pageSize,
          showSizeChanger: false,
        });
      } else {
        setExportTotalCount(res.data.totalCount);
        setPagination({
          ...pagination,
          pageSize: res.data.pageSize,
          current: res.data.pageNum,
          total: res.data.totalPage * res.data.pageSize,
        });
      }
    }).finally(() => setLoading(false));
  };
  const onTableChange = (pages, filter, sorter) => {
    console.log(sorter, 'sorter');
    const data = {
      ...pagination,
      ...filters,
      ...apiType,
      pageNum: pages.current,
      pageSize: pages.pageSize,
    };
    // if (!apiType) delete data.apiType;
    if (sorter.order) {
      if (sorter.columnKey==='startTime'&&sorter.column.sorterKey==='startTime1') {
        data.startTimeOrder = sorter.order === 'ascend' ? 0 : 1;
      } else if (sorter.columnKey==='endTime'&&sorter.column.sorterKey==='endTime1') {
        data.endTimeOrder = sorter.order === 'ascend' ? 0 : 1;
      } else if (sorter.columnKey==='createTime'&&sorter.column.sorterType===1) {
        data.order = sorter.order === 'ascend' ? 2 : 1;
        data.orderType =1;
      } else if (sorter.columnKey==='endTime'&&sorter.column.sorterType===2) {
        data.order = sorter.order === 'ascend' ? 2 : 1;
        data.orderType =2;
      } else {
        data.order = sorter.order === 'ascend' ? 2 : 1;
      }
    }
    getTableList(data);
  };

  // export file
  const onExportExcel = () => {
    const data = {
      ...otherParam,
      ...filters,
      ...apiType,
      pageSize: pagination?.total?pagination?.total:'100000',
      pageNum: 1,
      ...changeListForTabKey,
    };
    // if (!apiType) delete data.apiType;
    setExportLoading(true);
    const url = tableType === 1 ? InitApi : InitApi2;
    const newColumns = tableType === 1 ? columns : columns2;
    const exportColumns = newColumns.filter((item) => item.onExport);
    // 导出列表拼接入库时间
    if (exportTime) {
      exportColumns.push(
          {title: '入库时间',
            dataIndex: 'statisticsDate',
            key: 'statisticsDate',
            onExport: true,
          },
      );
    }
    getTableListData(url, data, t('flag'), resType || 'POST').then((res) => {
      const dateList=(apiType||statistics)?res.data.items.list:res.data.items;
      // 导出列表拼接入库时间
      if (exportTime) {
        dateList.forEach((item)=>{
          Object.assign(item, {statisticsDate: res.data.items.statisticsDate});
        });
      }
      if (toIndex) {
        // console.log('toIndex导出');
        if (dateList[0].statistic) {
          exportExcel(exportFileName, exportColumns, dateList.map((item, index) => (
            {
              ...item,
              index: (res.data.pageNum - 1) * res.data.pageSize + 1 + index,
              mt: item.statistic[0],
              hl: item.statistic[1],
              qj: item.statistic[2],
            }
          )));
        } else {
          // console.log('toIndex导出');
          console.log(dateList, exportColumns, 'toIndex导出');
          exportExcel(exportFileName, exportColumns, dateList.map((item, index) => (
            {
              ...item,
              index: (res.data.pageNum - 1) * res.data.pageSize + 1 + index,
            }
          )));
        }
      } else {
        exportExcel(exportFileName, exportColumns, dateList);
      }
    }).catch((err) => {
      exportExcel(exportFileName, exportColumns, []);
    }).finally(() => setExportLoading(false));
  };

  // 搜索
  const onSearch = (values) => {
    console.log(values, 'values');
    let data = {};
    Object.keys(values).forEach((key) => {
      if (key === 'composite') {
        const allValuesNotEmpty = Object.values(values.composite).every((value) => {
          return value !== '' && value !== null && value !== undefined;
        });
        if (allValuesNotEmpty) {
          data = {...data, ...values.composite};
          console.log(data, 'data');
        }
      } else if (key === 'datePicker') {
        if (values.datePicker) {
          data.startTime = moment(values.datePicker[0]).format('YYYY-MM-DD HH:mm:ss');
          data.endTime = moment(values.datePicker[1]).format('YYYY-MM-DD HH:mm:ss');
        }
      } else if (key === 'datePicker2') {
        if (values.datePicker2) {
          data.startTime = moment(values.datePicker2[0]).format('YYYY-MM-DD HH:mm:00');
          data.endTime = moment(values.datePicker2[1]).format('YYYY-MM-DD HH:mm:59');
        }
      } else if (key === 'datePicker3') {
        if (values.datePicker3) {
          data.managerStartTime = moment(values.datePicker3[0]).format('YYYY-MM-DD HH:mm:ss');
          data.managerEndTime = moment(values.datePicker3[1]).format('YYYY-MM-DD HH:mm:ss');
        }
      } else if (key === 'yearPicker') {
        if (values.yearPicker) {
          data.entryDate = moment(values[key]).format('YYYY-MM-DD');
        }
      } else if (key === 'areaCascaderMul') {
        data.areaId = areaStreetTreeData(
            values.areaCascaderMul,
            areaOptions,
            'search');
      } else if (key === 'areaCascaderMul2') {
        data.areaId = areaStreetTreeData(
            values.areaCascaderMul2,
            areaOptions,
            'searchAll');
      } else if (key === 'streetId') {
        data.streetId = areaStreetTreeData(
            values.streetId,
            areaOptions,
            'search');
      } else if (key === 'areaBike') {
        data.areaId = areaStreetTreeData(
            values.areaBike,
            areaOptions,
            'searchAll');
      } else if (key === 'bikeArea') {
        data.bikeArea = areaStreetTreeData(
            values.bikeArea,
            areaOptions,
            'searchAll');
      } else if (key === 'jurisdiction') {
        data.jurisdiction = areaStreetTreeData(
            values.jurisdiction,
            areaOptions,
            'search');
      } else if (key === 'areaList') {
        data.areaList = areaStreetTreeData(
            values.areaList,
            areaOptions,
            'search');
      } else {
        data[key] = values[key];
      }
    });
    data = toTrim(data);
    if (chartTime) {
      getTableList({time: chartTime, ...data});
      setFilters({time: chartTime, ...data});
    } else {
      getTableList({...apiType, ...data});
      setFilters({...apiType, ...data});
    }
  };

  const disabledDate = (current) => {
    return current < moment().subtract(3, 'month') ||
      current > moment().subtract(0, 'month');
  };
  const disabledYear = (current) => {
    return current > moment().endOf('year');
  };
  const formItem = (item) => {
    switch (item.type) {
      case 'input':
        return (
          <Input maxLength={50} placeholder={t('inputMsg')} />
        );
      case 'input2':
        return (
          <Input maxLength={50} placeholder={t('inputMsg')} defaultValue={item.defaultValue}/>
        );
      case 'select':
        return (
          <Select mode={item.mode || null}
            placeholder={item.placeholder || t('selectMsg')}
            allowClear
            defaultValue={item.defaultValue}
            maxTagCount="responsive">
            {item.options.map((option) => (
              <Select.Option key={option.label + option.value} value={option.value} hidden={option.hidden}>
                {option.label}
              </Select.Option>
            ))}
          </Select>);
      case 'selectApi':
        return (
          <Select mode={item.mode || null} disabled={item.disabled}
            placeholder={item.placeholder || t('selectMsg')} allowClear>
            {selectOptions[item.name] && selectOptions[item.name].map((option) => (
              <Select.Option
                key={option[item.valueKey] + option[item.textKey]}
                value={option[item.valueKey]}
              >
                {option[item.textKey]}
              </Select.Option>
            ))}
          </Select>);
      case 'datePicker':
        return (<DatePicker.RangePicker
          allowClear
          defaultValue={item.defaultValue?item.defaultValue:null}
          disabledDate={disabledDate}
          showTime={item.showTime}
          format={item.format}
          style={{width: '100%'}}
        />);
      case 'datePickerCompact':
        return (<Input.Group compact>
          <Form.Item
            name={['composite', item.typeName]}
            noStyle
          >
            <Select
              placeholder={t('selectMsg')}
              style={{
                width: '25%',
              }}
              onChange={(value) => {
                setTypeValueOfDate(value?.toString() || null);
              }}
              allowClear
            >
              {item.typeOptions.map(({label, value}) => (
                <Select.Option
                  key={label + value}
                  value={value}>{label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={item.valueName}
          >
            <DatePicker.RangePicker
              disabled={!typeValueOfDate}
              allowClear
              disabledDate={disabledDate}
              showTime={item.showTime}
              format={item.format}
              style={{width: '115%'}}
            />
          </Form.Item>
        </Input.Group>);
      case 'datePicker2':
        return (<DatePicker.RangePicker
          allowClear
          disabledDate={disabledDate}
          showTime={item.showTime}
          format={item.format}
          style={{width: '100%'}}
        />);
      case 'datePicker3':
        return (<DatePicker.RangePicker
          allowClear
          disabledDate={disabledDate}
          showTime={item.showTime}
          format={item.format}
          style={{width: '100%'}}
        />);
      case 'yearPicker':
        return (<DatePicker
          allowClear
          disabledDate={disabledYear}
          picker="year"
          style={{width: '100%'}}
        />);
      case 'selectInput':
        return (
          <Input.Group compact>
            <Form.Item
              name={['composite', item.typeName]}
              noStyle
            >
              <Select
                placeholder={t('selectMsg')}
                style={{
                  width: '35%',
                }}
                onChange={(value) => {
                  setTypeValue(value || null);
                }}
                allowClear
              >
                {item.typeOptions.map(({label, value}) => (
                  <Select.Option
                    key={label + value}
                    value={value}>{label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['composite', item.valueName]}
              // noStylepagination
            >
              <Input
                disabled={!typeValue}
                placeholder={t('inputMsg')}
                onChange={(e)=>{
                  if (item.valueName==='searchContent') {
                    localStorage.setItem('searchContents', e.target.value);
                  }
                }}
              />
            </Form.Item>
          </Input.Group>);
      case 'selectInput2':
        return (
          <Input.Group compact>
            <Form.Item
              name={item.typeName}
              noStyle
            >
              <Select
                placeholder={t('selectMsg')}
                style={{
                  width: '35%',
                }}
                onChange={(value) => {
                  setTypeValue(value || null);
                  console.log('selectInput2');
                }}
                allowClear
              >
                {item.typeOptions.map(({label, value}) => (
                  <Select.Option
                    key={label + value}
                    value={value}>{label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={item.valueName}
            // noStylepagination
            >
              <Input
                disabled={!typeValue}
                placeholder={t('inputMsg')}
              />
            </Form.Item>
          </Input.Group>);
      case 'areaCascaderMul':
        return (<Cascader
          allowClear={true}
          multiple
          // defaultValue={[findParentIds(areaOptions, item.defaultValue)]}
          displayRender={displayRender}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'areaCascaderMul3':
        return (<Cascader
          allowClear={true}
          multiple
          displayRender={displayRender}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'jurisdiction':
        return (<Cascader
          allowClear={true}
          multiple
          displayRender={displayRender}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'areaCascaderMul2':
        return (<Cascader
          allowClear={true}
          multiple
          displayRender={displayRender}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'primaryCascaderMu':
        return (<Cascader
          allowClear={true}
          multiple
          displayRender={displayRender}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'areaBike':
        return (<Cascader
          allowClear={true}
          multiple
          displayRender={displayRender2}
          placeholder="请选择（可多选）"
          options={areaOptions}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      default:
        return <div />;
    }
  };


  useEffect(() => {
    if (formList) {
      const S = formList.filter(({type}) => type === 'selectApi');
      const A = formList.find(({type}) => type === 'areaCascaderMul'||type==='jurisdiction');
      const A2 = formList.find(({type}) => type === 'areaCascaderMul2');
      const B = formList.find(({type}) => type === 'areaBike');
      if (S) {
        S.forEach((item, index) => {
          setTimeout(() => {
            getSelectOptions(item);
          }, index * 1000);
        });
      }
      if (A) {
        getAreaGroup(A.params);
      }

      if (A2) {
        getAreaGroup2(A2.params);
      }
      if (B) {
        getAreaGroupByBike();
      }
    }
  }, []);

  // useEffect(()=>{
  //   console.log(pagination, 'pagination');
  // }, [pagination]);

  useEffect(() => {
    searchForm.resetFields();
    setTypeValue(null);
    setTableData(null);
    setFilters({});
    getTableList({
      ...apiType,
      pageNum: 1,
      // ...otherParam,
      pageSize: pagination.pageSize,
    });
  }, [tableType]);
  useEffect(() => {
    if (resetData) {
      searchForm.resetFields();
      setFilters({});
      setTypeValue(null);
      setTableData([]);
      getTableList({
        ...apiType,
        // ...otherParam,
        pageNum: 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [resetData]);

  // 与折线图联动
  useEffect(() => {
    if (chartTime) {
      searchForm.resetFields();
      setFilters({});
      setTypeValue(null);
      getTableList({
        ...apiType,
        time: chartTime,
        pageNum: 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [chartTime]);
  useEffect(() => {
    if (updateList) {
      getTableList({
        ...filters,
        // ...otherParam,
        ...apiType,
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  }, [updateList]);

  useEffect(() => {
    setSelectKeys([]);
  }, [tableData]);
  return (
    <div className="contact-bg">
      <Spin spinning={exportLoading} tip={tip?tip:'日志导出中...'}>
        {/* 此处添加title的hide功能 */}
        {!hidePageTitle&&<Row className="margin-b-m">
          <Col span={12}>
            {tabs ? <Tabs defaultActiveKey={tabsKey} onChange={(key) => {
              changeTabs(key);
            }}>
              <Tabs.TabPane tab={'单车' + headerTitle()} key={1}>
              </Tabs.TabPane>
              <Tabs.TabPane tab={'电单车' + headerTitle()} key={2}>
              </Tabs.TabPane>
            </Tabs> : <h2>{customTitle ? customTitle : headerTitle()}</h2>}
          </Col>
          <Col span={12} className="text-right">
            <Space>
              {buttonGroup && buttonGroup()}
              {addFuc && (<Button
                type="primary"
                onClick={() => addFuc()}
              >
                {t('add')}
              </Button>)}
              {uploadUrl && (<UploadFile
                updateBikeItems={updateBikeItems} setUpdateBikeItems={setUpdateBikeItems}
                uploadUrl={uploadUrl}
                uploadUrl2={uploadUrl2}
                type={importType}
                onFinish={() => {
                  getTableList({
                    ...filters,
                    ...apiType,
                    pageNum: 1,
                    pageSize: pagination.pageSize,
                  });
                }} />)}
              {exportFileName && <Button
                onClick={onExportExcel}
                type="primary"
              >
                {t('export')}
              </Button>}
            </Space>
          </Col>
        </Row>}
        <Card>
          {tableTypename&&<div className="table-type-name">{tableTypename}</div>}
          {formList && (<Form layout="vertical" className="theme-form" onFinish={onSearch} form={searchForm}

          >
            <Row>
              {formList && formList.map((item) => (
                <Col span={item.col} key={item.name} className="padding-l-s padding-r-s">
                  {/* label名称右边含有QuestionIcon的情形 */}
                  <Form.Item label={item.concatIcon?
                  <span>{item.label}
                    <Tooltip placement="topLeft" color={'white'}
                      overlayInnerStyle={{color: 'black'}} title={item.hoverText}>
                      <QuestionCircleOutlined className="question-icon"/>
                    </Tooltip></span>:
                  item.label} name={item.name}>
                    {formItem(item)}
                  </Form.Item>
                </Col>
              ))}

              <Col span={4} className="padding-l-s padding-r-s"> <Form.Item label=" ">
                <Space>
                  <Button onClick={() => {
                    setTypeValue(null);
                    searchForm.resetFields();
                    // searchForm.setFieldsValue({
                    //   datePicker: null,
                    //   company: [],
                    //   searchContent: null,
                    // });
                  }}>
                    重置
                  </Button>
                  <Button htmlType="submit" type="primary">
                    搜索
                  </Button>
                </Space>
              </Form.Item></Col>
            </Row>
          </Form>)}
          {!statistics&&formList && (<Divider />)}
          {InitApi2 && (<Radio.Group
            className="margin-b-m"
            options={tabOption}
            onChange={(e) => {
              setTableType(e.target.value);
              changeOptions(e.target.value);
            }}
            value={tableType}
            optionType="button"
          />)}
          {customTabs&&(<Radio.Group
            className="margin-b-m"
            onChange={(e) => {
              setPagination({
                pageSize: 10,
                current: 1,
                total: 0,
                size: 'small',
                showSizeChanger: true,
              });
              changeTabs(e.target.value);
            }}
            options={tabOption}
            defaultValue={tabsKey}
            optionType="button"
          />)}
          {/* 数据统计需要展示数据 */}
          {hidePageTitle&&!cantExportAction&&<div className="export-config"><span>共检索到{exportTotalCount||'0'}条数据</span>
            <Button onClick={onExportExcel} type="primary">{t('export')}</Button></div>}
          {statistics&&
          <p className="statistics-table">
            {statistics}：{incomeDate?.count} ｜  列表入库时间：{incomeDate?.statisticsDate}</p>}
          <Table
            rowSelection={rowSelection ? {
              type: 'checkbox',
              onChange: (selectedRowKeys) => {
                setSelectKeys(selectedRowKeys);
              },
              selectedRowKeys: selectKeys,
              getCheckboxProps: customRowSelection?(record) => ({
                disabled: record.status === 1,
                status: record.status,
              }):()=>{},
            } : null}
            loading={loading}
            dataSource={tableData}
            pagination={noPageSizeComponent?false:pagination}
            onChange={onTableChange}
            columns={newColumns(tableType === 1 ? columns : columns2)}
            rowKey={(record) => record.id || record.index}
            scroll={scroll ? {
              x: 1200,
            } : {}}
            expandable={expandable} />
          {rowSelection && (<div className="bar-space" />)}
          {rowSelection && (<div className={`batch-bar ${location.pathname.indexOf('/bigScreen') !== -1? 'big-screen-bar':''}`}>
            <Row>
              <Col span={8}>
                已选择 <span className="bar-number">{selectKeys.length}</span> 项
              </Col>
              <Col span={16} className="text-right">
                <Space>
                  <Button
                    disabled={selectKeys.length === 0}
                    onClick={() => {
                      setSelectKeys([]);
                    }}>
                    取消选择
                  </Button>

                  <Button type="primary"
                    disabled={selectKeys.length === 0}
                    onClick={() => {
                      customRowSelection?Modal.confirm({
                        title: `${customRowSelection.modal}`,
                        icon: <ExclamationCircleOutlined />,
                        // content: `删除人员信息后不可恢复，是否确认删除${selectKeys.length}条人员信息`,
                        cancelText: '确认',
                        okText: '取消',
                        autoFocusButton: 'cancel',
                        okButtonProps: {
                          type: 'default',
                        },
                        cancelButtonProps: {
                          type: 'primary',
                          danger: true,
                        },
                        onCancel: ()=>{
                          batchFunction(selectKeys);
                        },
                      }):Modal.confirm({
                        title: '确认批量删除人员',
                        icon: <ExclamationCircleOutlined />,
                        content: `删除人员信息后不可恢复，是否确认删除${selectKeys.length}条人员信息`,
                        cancelText: '确认',
                        okText: '取消',
                        autoFocusButton: 'cancel',
                        okButtonProps: {
                          type: 'default',
                        },
                        cancelButtonProps: {
                          type: 'primary',
                          danger: true,
                        },
                        onCancel: ()=>{
                          batchFunction(selectKeys);
                        },
                      });
                    }}>
                    {customRowSelection?customRowSelection.button: '批量删除'}
                  </Button>

                </Space>
              </Col>
            </Row>
          </div>)}
        </Card>
      </Spin>
    </div>
  );
};

CustomTable.propTypes = {
  resType: PropTypes.string,
  InitApi: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  InitApi2: PropTypes.string,
  columns2: PropTypes.arrayOf(PropTypes.object),
  addFuc: PropTypes.func,
  buttonGroup: PropTypes.func,
  exportFileName: PropTypes.string,
  updateList: PropTypes.number,
  uploadUrl: PropTypes.string,
  uploadUrl2: PropTypes.string,
  formList: PropTypes.arrayOf(PropTypes.object),
  expandable: PropTypes.object,
  tabOption: PropTypes.arrayOf(PropTypes.object),
  hiddenHeader: PropTypes.bool,
  toIndex: PropTypes.bool,
  scroll: PropTypes.bool,
  changeOptions: PropTypes.func,
  changeTabs: PropTypes.func,
  tabs: PropTypes.bool,
  rowSelection: PropTypes.bool,
  resetData: PropTypes.number,
  tabsKey: PropTypes.number,
  updateBikeItems: PropTypes.number,
  setUpdateBikeItems: PropTypes.func,
  customTitle: PropTypes.string,
  batchFunction: PropTypes.any,
  firm: PropTypes.any,
  tip: PropTypes.string,
  importType: PropTypes.string,
  hidePageTitle: PropTypes.bool,
  chartTime: PropTypes.string,
  changeListForTabKey: PropTypes.object,
  statistics: PropTypes.string,
  apiType: PropTypes.any,
  tableTypename: PropTypes.string,
  dataElseItemStructure: PropTypes.string,
  noPageSize: PropTypes.bool,
  noPageSizeComponent: PropTypes.bool,
  getArea: PropTypes.any,
  exportTime: PropTypes.bool,
  cantExportAction: PropTypes.bool,
  customTabs: PropTypes.bool,
  otherParam: PropTypes.object,
  customRowSelection: PropTypes.object,
};

export default CustomTable;
