import React, {useRef, useState} from 'react';
import {Button, Divider, Drawer, message, Modal, Popconfirm, Space, Spin, Tooltip} from 'antd';
import {addAreaToUser, disabledUser, resetPwd, saveUser, updateUser, enabledUser} from '../../../api';
// import Base64 from 'base-64';
import './style.css';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {GETROLELIST, GETUSERLIST} from '../../../api/Urls';
import EditForms from '../../../components/forms/EditForms';
import SetAreaId from './components/setAreaId';
import {Link} from 'react-router-dom';
import {areaNameShow, getEnterprise} from '../../../components/statusCollection';
import {InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import SetStreetId from './components/SetStreetId';
import {connect} from 'react-redux';
import {updateMyInfo} from '../../../store/actions';
import PropTypes from 'prop-types';

const Users = ({myInfoCount, setMyInfoCount}) => {
  const sign = useRef;
  const {t} = useTranslation();
  const [updateList, setUpdateList] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editData, setEditData] = useState({});

  const formList = [
    {
      label: '管辖区域',
      key: 'areaCascaderMul2',
      col: 8,
      name: 'areaCascaderMul2',
      type: 'areaCascaderMul2',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [
        {label: '姓名', value: 1},
        {label: '用户名', value: 2},
      ],
    },
  ];

  const handleUser = (values) => {
    setLoading(true);
    // 缓存账户管辖企业
    const personal=JSON.parse(localStorage.getItem('bikeEnterprise'));
    if (values.username===personal.userName) {
      const data={
        userName: values.username,
        bikeEnterprise: values.bikeEnterprise,
      };
      localStorage.setItem('bikeEnterprise', JSON.stringify(data));
    }
    if (modalType === 'add') {
      values.roleName = values.role.label;
      values.roleId = values.role.value;
      // values.password = Base64.encode(`${values.password}hcy_shared_bikes@2022`);
      saveUser(values, t('flag')).then((res) => {
        setUpdateList(updateList + 1);
        message.success(res.msg);
        setModalVisible(false);
        setModalShow(true);
        values.userName = values.username;
        setEditData({
          ...values,
          areaCascaderMul2: [],
          areaList: [],
          userId: res.data.userId,
        });
      }).finally(() => setLoading(false));
    } else {
      values.roleId = typeof values.role == 'object' ? values.role.value : values.role;
      updateUser(values, t('flag')).then((res) => {
        setUpdateList(updateList + 1);
        message.success(res.msg);
        setModalVisible(false);
        setMyInfoCount(myInfoCount+1);
      }).finally(() => setLoading(false));
    }
  };

  const handleResetPassword = (id) => {
    setLoading(true);
    resetPwd(id, t('flag')).then((res) => {
      setUpdateList(updateList + 1);
      message.success(res.msg);
    }).finally(() => setLoading(false));
  };
  const onDisabled = (id) => {
    const data = {
      status: 0,
      userId: id,
    };
    disabledUser(data, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    });
  };
  const onEnabled = (id) => {
    enabledUser(id, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    });
  };
  const onUnlocked = (id) => {
    enabledUser(id, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    });
  };

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      fixed: 'left',
      // eslint-disable-next-line
      render: (name, record) => <span className={parseInt(record.status) !== 1 ? 'text-disabled' : ''}>{name}</span>,
    },
    {
      title: t('userName'),
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      // eslint-disable-next-line
      render: (userName, record) => (
        <span className={parseInt(record.status) !== 1 ? 'text-disabled' : ''}>{userName}</span>
      ),
    },
    {
      title: t('role'),
      dataIndex: 'roleName',
      key: 'roleName',
      width: 160,
      // eslint-disable-next-line
      render: (roleName, record) => (
        <span className={parseInt(record.status) !== 1 ? 'text-disabled' : ''}>{roleName}</span>
      ),
    },
    {
      title: '用户状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      // eslint-disable-next-line
      render: (status) => (
        <span className={parseInt(status) !== 1 ? 'text-disabled' : ''}>
          {parseInt(status) === 1 ? '正常' : parseInt(status) === 0 ? '禁用' : '锁定'}
        </span>
      ),
    },
    {
      title: '管辖企业',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      width: 160,
      // eslint-disable-next-line
      render: (bikeEnterprise, record) => {
        return (
          <span className={parseInt(record.status) !== 1 ? 'text-disabled' : ''}>{getEnterprise(bikeEnterprise)}</span>
        );
      },
    },
    {
      title: '管辖区域',
      width: 300,
      dataIndex: 'areaName',
      key: 'areaName',
      // eslint-disable-next-line
      render: (areaName, record) => (
        <span className={parseInt(record.status) !== 1 ? 'text-disabled' : ''}>
          {areaNameShow(areaName, '管辖区域') || '无区域'}
        </span>
      ),
    },
    {
      title: t('action'),
      key: 'action',
      width: 380,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          {record.roleId !== 1 && parseInt(record.status) === 1 && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                setModalShow(true);
                setModalShow((value) => {
                  sign.current = value;
                  return value;
                });
                if (record.areaList?.length > 0) {
                  setEditData({
                    ...record,
                    cityId: parseInt(record.areaList[0].split(',')[0]),
                    areaId: record.areaList.map((item) => {
                      const arr = item.split(',');
                      return parseInt(arr[1]);
                    }),
                  });
                } else {
                  setEditData({
                    ...record,
                  });
                }
              }}>
              管理区域
            </Button>
          )}

          {parseInt(record.status) === 1 && (
            <Popconfirm
              title={
                <div style={{width: 300}}>
                  重置后密码为88888888，是否确认重置的密码？
                  <div>姓名：{record.name}</div>
                  <div>用户名：{record.userName}</div>
                </div>
              }
              onConfirm={() => handleResetPassword(record.userId)}
            >
              <Button type="link" size="small">
                {t('resetPassword')}
              </Button>
            </Popconfirm>
          )}
          <Button type="link" size="small">
            <Link to={`details/${record.userId}`}>详情</Link>
          </Button>
          {parseInt(record.status) === 1 && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                setEditData({
                  ...record,
                  username: record.userName,
                  role: record.roleId,
                  bikeEnterprise: record.bikeEnterprise ? record.bikeEnterprise?.split(',').map(Number) : [],
                });
                setModalType('edit');
                setModalVisible(true);
              }}
            >
              {t('edit')}
            </Button>
          )}
          {parseInt(record.status) === 1 && parseInt(record.roleId) !== 1 ? (
            <Popconfirm
              placement="topRight"
              title={
                <div>
                  禁用后，该用户将无法登录此管理平台同时，
                  <br />
                  其管辖区域将自动变为“无区域”，是否确认
                  <br />
                  禁用以下用户？
                  <div>姓名：{record.name}</div>
                  <div>用户名：{record.userName}</div>
                </div>
              }
              okButtonProps={{
                type: 'default',
              }}
              onCancel={() => onDisabled(record.userId)}
              cancelButtonProps={{
                danger: true,
                type: 'primary',
              }}
              cancelText="禁用"
              okText="取消"
            >
              <Button type="link" size="small">
                禁用
              </Button>
            </Popconfirm>
          ) : parseInt(record.status) === 2 ? (
            <Popconfirm
              placement="topRight"
              title={
                <div style={{width: 300}}>
                  是否解锁此用户？
                  <div>姓名：{record.name}</div>
                  <div>用户名：{record.userName}</div>
                </div>
              }
              onConfirm={() => onUnlocked(record.userId)}
              okText="解锁"
            >
              <Button type="link" size="small">
                解锁
              </Button>
            </Popconfirm>
          ) : (
            parseInt(record.roleId) !== 1 && (
              <Popconfirm
                placement="topRight"
                title={
                  <div style={{width: 300}}>
                    是否启用此用户？
                    <div>姓名：{record.name}</div>
                    <div>用户名：{record.userName}</div>
                  </div>
                }
                onConfirm={() => onEnabled(record.userId)}
                okText="启用"
              >
                <Button type="link" size="small">
                  启用
                </Button>
              </Popconfirm>
            )
          )}
        </Space>
      ),
    },
  ];
  const editForm = [
    {
      label: ' ',
      name: 'userId',
      type: 'input',
      message: ' ',
      message2: ' ',
      required: modalType !== 'add',
      hidden: true,
      disabled: modalType === 'add',
    },
    {
      label: '姓名',
      name: 'name',
      type: 'input',
      message: t('nickNameMsg'),
      message2: '请输入姓名',
      required: true,
      placeholder: '请输入',
      maxLength: 10,
    },
    {
      label: '联系电话',
      name: 'mobile',
      type: 'input',
      placeholder: '请输入',
      maxLength: 11,
      rules: [
        {
          message: '请输入11位数字电话号码',
          required: true,
        },
        {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的电话号',
        },
      ],
    },
    {
      label: t('role'),
      name: 'role',
      type: 'selectApi',
      placeholder: '请选择',
      message: t('rolesMsg'),
      message2: '请选择角色',
      required: true,
      resType: 'post',
      dataKey: 'items',
      areaTip: modalType !== 'add' ? 2 : 0,
      params: {
        pageNum: 1,
        pageSize: 9999,
      },
      url: GETROLELIST,
      valueKey: 'roleId',
      textKey: 'roleName',
      labelInValue: true,
      disabled: editData.roleId == 1,
    },
    {
      label: t('userName'),
      name: 'username',
      type: 'input',
      placeholder: '请输入',
      message: t('userNameMsg'),
      message2: '请输入用户名',
      maxLength: 10,
      required: modalType === 'add',
      disabled: modalType !== 'add',
    },
    {
      label: '管辖企业',
      name: 'bikeEnterprise',
      type: 'select',
      mode: 'multiple',
      disabled: editData.roleId == 1,
      rules: [
        {
          message: '请选择管辖企业',
          required: true,
        },
      ],
      placeholder: '请选择（多选）',
      options: [
        {
          value: 1,
          label1: 1,
          label2: '美团',
        },
        {
          value: 2,
          label1: 2,
          label2: '青桔',
        },
        {
          value: 3,
          label1: 3,
          label2: '哈啰',
        },
        {
          value: 4,
          label1: 4,
          label2: '电单车企业1',
        },
        {
          value: 5,
          label1: 5,
          label2: '电单车企业2',
        },
        {
          value: 6,
          label1: 6,
          label2: '电单车企业3',
        },
        {
          value: 7,
          label1: 7,
          label2: '其他',
        },
      ],
    },
    {
      label: '备注',
      name: 'remark',
      type: 'textArea',
      message: 'Please input !',
      message2: '请输入',
      placeholder: '请输入',
      maxLength: 200,
    },
  ];

  const tooltip = () => {
    return (
      <div style={{color: 'black'}}>
        <div>
          <InfoCircleFilled style={{color: '#FAAD14', marginRight: 9}} />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        {editData.roleId === 4 && <p className="tipInfo">街道管理用户可管理同个区域的多个街道，管辖区域不可重复。</p>}
        {editData.roleId === 3 && <p className="tipInfo">区域管理用户可管理同一个市的多个区域，管辖区域不可重复。</p>}
        {editData.roleId !== 3 && editData.roleId !== 4 && (
          <p className="tipInfo">用户可管理同一个市的多个区域，管辖区域可重复。</p>
        )}
      </div>
    );
  };
  const setAreaFun = (value) => {
    value.areaId = value.areaId === '' ? [] : value.areaId;
    delete value.cityId;
    addAreaToUser(value, 2).then((res) => {
      message.success(res.msg);
      setModalShow(false);
      setEditData({});
      setUpdateList(updateList + 1);
      setMyInfoCount(myInfoCount+1);
    });
  };
  return (
    <div>
      <Spin spinning={loading}>
        <CustomTable
          InitApi={GETUSERLIST}
          updateList={updateList}
          columns={columns}
          resType="POST"
          scroll={true}
          addFuc={() => {
            setModalVisible(true);
            setEditData({});
            setModalType('add');
          }}
          formList={formList}
          hiddenExport={true}
        />

        <Modal
          title="设置管辖区域"
          footer={null}
          visible={modalShow}
          destroyOnClose
          maskClosable={false}
          onCancel={() => {
            setModalShow(false);
            setEditData({});
          }}
        >
          <div className="padding-r-l">姓名：{editData.name}</div>
          <div className="padding-r-l">用户名：{editData.userName}</div>
          <div>角色：{editData.roleName}</div>
          <div className="margin-b-s margin-t-m">
            管理区域
            <Tooltip title={tooltip()} color="white">
              <InfoCircleOutlined style={{color: '#8c8c8c'}} className="margin-l-s" />
            </Tooltip>
          </div>
          {editData.roleId === 4 ? (
            <SetStreetId
              params={{
                roleId: editData.roleId,
              }}
              initData={editData}
              onFinished={setAreaFun}
              onCancel={() => {
                setModalShow(false);
                setEditData({});
              }}
            />
          ) : (
            <SetAreaId
              columnsForm={[
                {
                  label: ' ',
                  name: 'userId',
                  type: 'input',
                  message: ' ',
                  message2: ' ',
                  hidden: true,
                  disabled: true,
                },
                {
                  label: ' ',
                  name: 'roleId',
                  type: 'input',
                  message: ' ',
                  message2: ' ',
                  hidden: true,
                  disabled: true,
                },
                {
                  name: 'cityId',
                  type: 'selectArea1',
                  params: {
                    roleId: editData.roleId,
                  },
                },
                {
                  // label: ' ',
                  name: 'areaId',
                  type: 'selectArea2',
                  params: {
                    roleId: editData.roleId,
                  },
                  // areaTip: 1,
                },
              ]}
              onFinish={setAreaFun}
              initData={editData}
              pageLayout="vertical"
              loading={false}
              buttonStyle="modal-button"
              onCancel={() => {
                setModalShow(false);
                setEditData({});
              }}
            />
          )}
        </Modal>
        <Drawer
          title={t(modalType)}
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
          maskClosable={false}
          width={482}
          footer={null}
          destroyOnClose
        >
          <EditForms
            onCancel={() => {
              setModalVisible(false);
              setEditData({});
            }}
            pageLayout="vertical"
            loading={false}
            buttonStyle="modal-button"
            initData={editData}
            columnsForm={editForm}
            onFinish={handleUser}
          />
        </Drawer>
      </Spin>
    </div>
  );
};

Users.propTypes = {
  myInfoCount: PropTypes.number.isRequired,
  setMyInfoCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    myInfoCount: state.myInfo.myInfoCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMyInfoCount: (n) => dispatch(updateMyInfo(n)),
  };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Users);
