import React, {useState, useRef, useEffect} from 'react';
import {Pie, measureTextWidth} from '@ant-design/plots';
import PropTypes from 'prop-types';
import {toThousands} from '../../../../../utils/dataFormat';
import {Button} from 'antd';
import html2canvas from 'html2canvas';

const DemoPie = ({data, isExportOpen, id, title, dataType}) => {
  const [total, setTotal] = useState();
  const [urlGet, setUrlGet] = useState();

  const renderStatistic = (containerWidth, text, style) => {
    const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
    const R = containerWidth / 2;

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  };

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.55,
    color: (d) => {
      switch (d.type) {
        case '美团':
          return '#E89E0D';
        case '青桔':
          return '#13BEC6';
        case '哈啰':
          return '#1D39C4';
        default:
          return '#000000';
      }
    },
    // 图例
    // legend: legend ?
    //   false :
    //   {
    //     layout: 'vertical',
    //     position: 'right',
    //   },
    meta: {
      value: {
        formatter: (v) => {
          return `${v} `;
        },
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: (data) => {
        return dataType !== 'percent' ? toThousands(data.value) : data.persent;
      },
      // content: `${dataType !== 'percent' ? '{value}' : '{percentage}'}`,
      // formatter: (data) => {
      //   console.log(data);
      //   return dataType !== 'percent' ? toThousands(data.value) : 12;
      // },
    },
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontSize: '18px',
        },
        customHtml: (container, view, datum) => {
          const {width, height} = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : '总计';
          return !isExportOpen ?
            renderStatistic(d, text, {
              fontSize: 12,
            }) :
            text;
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '22px',
        },
        customHtml: (container, view, datum, data) => {
          setTotal(toThousands(data.reduce((r, d) => r + d.value, 0)));
          const {width} = container.getBoundingClientRect();
          // const text = datum ?
          //   dataType === 'percent' ?
          //     ` ${datum.value}` :
          //     ` ${datum.persent}` :dataType === 'percent' ?` ${(data.reduce((r, d) =>
          //       r + Number(d.persent.split('%')[0]), 0)).toFixed(2)}%`:
          //   ` ${toThousands(data.reduce((r, d) => r + d.value, 0))}`;
          const text = datum ?
            dataType === 'percent' ?
              ` ${datum.value}` :
              ` ${datum.persent}` :
            ` ${toThousands(data.reduce((r, d) => r + d.value, 0))}`;
          return !isExportOpen ?
            renderStatistic(width, text, {
              fontSize: 16,
            }) :
            text;
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  const ref = useRef();

  // 获取图表 base64 数据及下载图表
  const toDataURL = () => {
    // const windowWidth = window.screen.availWidth;
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = ref.current?.toDataURL();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.font = '24px sans-serif';
      context.fillText(`总计`, image.width / 2.54, image.height / 2 - 20);
      context.fillText(total, image.width / 2.54, image.height / 2 + 20);

      context.drawImage(image, 0, 0, image.width, image.height);
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.download = `${title}环图.png`;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    };
  };

  useEffect(() => {
    if (isExportOpen) {
      setTimeout(() => {
        const node = document.getElementById(id);
        html2canvas(node, {backgroundColor: '#ffffff'}).then((canvas) => {
          setUrlGet(canvas.toDataURL());
        });
      }, 1500);
    }
  }, []);

  return (
    <div>
      {!isExportOpen && (
        <Button type="primary" onClick={toDataURL}>
          下载
        </Button>
      )}
      {urlGet ? (
        <img src={urlGet} alt="" />
      ) : (
        <div id={id}>
          <Pie
            className="pie-export"
            {...config}
            onReady={(plot) => {
              ref.current = plot;
            }}
          />
        </div>
      )}
    </div>
  );
};

DemoPie.propTypes = {
  data: PropTypes.array,
  legend: PropTypes.bool,
  isExportOpen: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  dataType: PropTypes.string,
};

export default DemoPie;
