import {combineReducers} from 'redux';
import {storage} from '@/utils/storage';

// import {initGlobalState} from 'qiankun';


// const qiankunStateForSlave = ({
//   state,
// }, action) => {
//   const initialState = {
//     user: {
//       name: 'qiankun',
//     },
//   };

//   switch (action.type) {
//     case 'update':
//       return {
//         ...initialState,
//         ...action.payload,
//       };
//     case 'init':
//       return initGlobalState(initialState);
//     default:
//       return state;
//   }
// };

const toggleHttpLoading = (state = {loading: false}, action) => {
  switch (action.type) {
    case 'http_loading':
      return {loading: action.loading};
    default:
      return state;
  }
};

const collapsedMenus= (state = {collapsed: false}, action) => {
  switch (action.type) {
    case 'side':
      return {collapsed: action.collapsed};
    default:
      return state;
  }
};

const toggleLogin = (state = {logged: false}, action) => {
  switch (action.type) {
    case 'login':
      return {logged: action.logged};
    default:
      return state;
  }
};

const getUserInfo = (state = {info: storage.getData('userInfo')}, action) => {
  switch (action.type) {
    case 'user_info':
      return {info: action.info};
    default:
      return state;
  }
};

const toggleAdvancedSearch = (state = {can: false}, action) => {
  switch (action.type) {
    case 'can_do_advanced_search':
      return {can: action.can};
    default:
      return state;
  }
};
const menus=(state={activeKey: 'home',
  openKeys: 'home'}, action)=> {
  switch (action.type) {
    case 'layout/menus-path-data':
      return {
        activeKey: (action.activeKey !== null) ? action.activeKey : state.activeKey,
        openKeys: (action.openKeys===state.openKeys&&action.clickType===1)?'':action.openKeys,
      };
    default:
      return state;
  }
};
const tim = (state = {messageList: []}, action) => {
  switch (action.type) {
    case 'update_msg':
      return {messageList: [
        ...state.messageList,
        action.message]};
    case 'init_msg':
      return {messageList: []};
    default:
      return state;
  }
};


const myInfo = (state = {myInfoCount: 0}, action) => {
  const {type, myInfoCount}=action;
  switch (type) {
    case 'my_info':
      return {myInfoCount};
    default:
      return state;
  }
};

const websocketInfo = (state = {noticeInfo: {}}, action) => {
  const {type, noticeInfo}=action;
  switch (type) {
    case 'notice_info':
      return {noticeInfo};
    default:
      return state;
  }
};

const importType = (state = {count: 0}, action) => {
  const {type, count}=action;
  switch (type) {
    case 'import_type':
      return {count};
    default:
      return state;
  }
};

const reducer = combineReducers({
  toggleHttpLoading,
  collapsedMenus,
  toggleLogin,
  getUserInfo,
  toggleAdvancedSearch,
  menus,
  tim,
  myInfo,
  websocketInfo,
  importType,
  // qiankunStateForSlave,
});

export default reducer;
