import React, {useEffect, useState} from 'react';
import {Table, Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons/lib/icons';
import PropTypes from 'prop-types';
import Gold from './../../../../../asset/jiangbei- 1.png';
import Silver from './../../../../../asset/jiangbei- 2.png';
import Cuprum from './../../../../../asset/jiangbei- 3.png';
import {twoHours} from '../../../../../components/statusCollection';

const Target = ({rankingData, vehicleType, exportTable}) => {
  const [rankingList, setRankList] = useState();
  // 名次卡片
  const ranking = (name, ranking, num) => {
    return (
      <Tooltip
        title={
          <div className="report-tooltip">
            <div>{name}总分</div>
            <div>{Number(num).toFixed(4)}</div>
          </div>
        }
        placement="bottom"
        key={ranking}
      >
        <div className="ranking">
          <img src={ranking === 1 ? Gold : ranking === 2 ? Silver : Cuprum} alt="" />
          <label className={ranking === 1 ? 'gold' : ranking === 2 ? 'silver' : 'cuprum'}>NO.{ranking + name}</label>
          <div className="grade">
            <span>{twoHours(num)}</span>
            <span>分</span>
          </div>
        </div>
      </Tooltip>
    );
  };
  // 分数Hover效果
  const gradeHover = (firm, number, target) => {
    return (
      <Tooltip
        title={
          <div>
            <div>
              {firm}
              {targetType(target)}得分
            </div>
            <div>{Number(number).toFixed(4)}</div>
          </div>
        }
        placement="right"
        key={ranking}
      >
        <span>{twoHours(number)}</span>
      </Tooltip>
    );
  };
  const targetType = (target) => {
    switch (target) {
      case 1:
        return `${vehicleType}秩序人员出勤`;
      case 2:
        return `${vehicleType}调运车辆出勤`;
      case 3:
        return `${vehicleType}秩序人员在线`;
      case 4:
        return `${vehicleType}调运车辆在线`;
      case 6:
        return `${vehicleType}未备案`;
      case 7:
        return `${vehicleType}跨区域`;
      default:
        return '--';
    }
  };
  // 考评数据
  const columns = [
    {
      title: '指标名称',
      dataIndex: 'target',
      key: 'target',
      render: (target) => {
        // 统计类型 1:人员出勤率 2:车辆出勤率 3:车辆未备案率
        return targetType(target) + '率';
      },
    },
    {
      title: '指标权重',
      dataIndex: 'weight',
      key: 'weight',
      render: (weight) => {
        return <span>{weight}%</span>;
      },
    },
    {
      title: '美团得分',
      dataIndex: 'meituan',
      key: 'meituan',
      render: (_, record) =>
        // bikeEnterprise 1:美团 2:青桔 3:哈罗
        record.bikeEnterpriseList.map((item, index) => {
          if (item.bikeEnterprise === 1) {
            return <div key={index}>{gradeHover('美团', item.bikeEnterpriseScore, record.target)}</div>;
          }
        }),
    },
    {
      title: '青桔得分',
      dataIndex: 'qingju',
      key: 'qingju',
      render: (_, record) =>
        record.bikeEnterpriseList.map((item, index) => {
          if (item.bikeEnterprise === 2) {
            return <div key={index}>{gradeHover('青桔', item.bikeEnterpriseScore, record.target)}</div>;
          }
        }),
    },
    {
      title: '哈啰得分',
      dataIndex: 'haluo',
      key: 'haluo',
      render: (_, record) =>
        record.bikeEnterpriseList.map((item, index) => {
          if (item.bikeEnterprise === 3) {
            return <div key={index}>{gradeHover('哈啰', item.bikeEnterpriseScore, record.target)}</div>;
          }
        }),
    },
  ];
  useEffect(() => {
    if (rankingData && !exportTable) {
      const list = rankingData?.vehicleTotalScoreVOList?.sort((a, b) => b.totalScore - a.totalScore);
      if (rankingData.vehicleTotalScoreVOList) {
        setRankList([...list]);
      }
    }
  }, [rankingData]);

  return (
    <div className={!exportTable ? 'target-content' : 'target-content-export '}>
      {!exportTable && (
        <div className="ranking-list">
          {/* 车企类型 1:美团 2:青桔 3:哈罗 */}
          {rankingList?.map((item, index) => {
            return ranking(
              item.bikeEnterprise === 1 ? '美团' : item.bikeEnterprise === 2 ? '青桔' : '哈啰',
              index + 1,
              item.totalScore,
            );
          })}
        </div>
      )}
      {!exportTable && (
        <div className="target-hint">
          <InfoCircleOutlined />
          <span className="margin-l-s">
            指标说明：基于管理员辖区内的全部定点信息采集设备和{vehicleType}
            运维人员的监测数据，统计指标，并按照指标权重计算考评得分
          </span>
        </div>
      )}
      <div className="target-table">
        {!exportTable && <div className="car">车企各指标得分</div>}
        <Table
          columns={columns}
          dataSource={rankingData?.statisticsCompanyVOList.filter(
              (obj) => obj.target !== 0 && obj.target !== 5 && obj.target !== 8,
          )}
          rowKey={(data) => data.target}
          pagination={false}
        />
      </div>
    </div>
  );
};

Target.propTypes = {
  rankingData: PropTypes.object.isRequired,
  vehicleType: PropTypes.string,
  exportTable: PropTypes.bool,
};

export default Target;
