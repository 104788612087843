import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Slice from '../../../../asset/title-icon.png';
import {Col, Row} from 'antd';
// import Slice from '../../../../asset/Slice 19.png';
import {personDataTitle} from '../../../../components/statusCollection';

const Statistics = ({peopleCount, carCount, guaranteeCount, queryType}) => {
  const [dataTitle, setDataTitle]=useState('');
  useEffect(() => {
    const areaName=JSON.parse(localStorage.getItem('myInfo'))?.areaName || '无区域';
    setDataTitle(personDataTitle(areaName));
  }, []);
  return (
    <div className={`total-card ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-show-h':''}`}>

      <div className="card-title">
        <img src={Slice} alt="title" className="margin-r-s margin-l-s" />
        {/* 数据统计 */}
        {dataTitle}
      </div>
      <div className="padding-l-s ">
        {/* 调运车辆/秩序车辆 */}
        <Row gutter={[16, 16]} >
          <Col span={6} className="padding-t-s">
            <div className="statistics-title">
              {queryType===2&&'电'}单车秩序人员
            </div>
            <div className="statistics-unit">单位：人</div>
          </Col>
          <Col span={6} className="padding-t-s">
            <div className="statistics-data-title">规定应配：</div>
            <div className="statistics-data statistics-person">
              {peopleCount?peopleCount.totalCount:'--'}
            </div>
          </Col>
          <Col span={6} className="padding-t-s">
            <div className="statistics-data-title">实时在线：</div>
            <div className="statistics-data statistics-person">
              {peopleCount?peopleCount.count:'--'}
            </div>
          </Col>
          <Col span={6} className="padding-t-s">
            <div className="statistics-data-title">其中保障人员：</div>
            <div className="statistics-data statistics-person">
              {peopleCount?peopleCount.guaranteeCount:'--'}
            </div>
          </Col>

          <Col span={6}>
            <div className="statistics-title">
              {queryType===2&&'电'}单车调运车辆
            </div>
            <div className="statistics-unit">单位：辆</div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">规定应配：</div>
            <div className="statistics-data statistics-car">
              {carCount?carCount.totalCount:'--'}
            </div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">实时在线：</div>
            <div className="statistics-data statistics-car">
              {carCount?carCount.count:'--'}
            </div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">其中保障车辆：</div>
            <div className="statistics-data statistics-car">
              {carCount?carCount.guaranteeCount:'--'}
            </div>
          </Col>

          <Col span={6}>
            <div className="statistics-title">
            普通保障区域
            </div>
            <div className="statistics-unit">单位：个</div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">总数：</div>
            <div className="statistics-data statistics-zone">
              {guaranteeCount?guaranteeCount.count:'--'}
            </div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">进行中：</div>
            <div className="statistics-data statistics-zone">
              {guaranteeCount?guaranteeCount.progress:'--'}
            </div>
          </Col>
          <Col span={6}>
            <div className="statistics-data-title">未开始：</div>
            <div className="statistics-data statistics-zone">
              {guaranteeCount?guaranteeCount.notStarted:'--'}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Statistics.propTypes = {
  peopleCount: PropTypes.object,
  guaranteeCount: PropTypes.object,
  carCount: PropTypes.object,
  queryType: PropTypes.number.isRequired,
};

export default Statistics;

