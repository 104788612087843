import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Form, Input, Space, Spin, message} from 'antd';
import PropTypes from 'prop-types';
import editIcon from '../../../asset/edit.png';
import forbidIcon from '../../../asset/forbid-edit.png';
import {InfoCircleOutlined} from '@ant-design/icons';
import ExitEdit from './ExitEdit';
import {updateRound} from '../../../api';

const OffLine = ({systemInfo, onFinished, open, changeOpen, redact, setRedact}) => {
  const [edit, setEdit] = useState(false);
  const [visible, setVisible]= useState(false);
  const [loading, setLoading] = useState(false);
  const [systemValue, setSystemValue]=useState();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    setRedact(values);
    updateRound(values, 2).then((resp) => {
      setLoading(false);
      setEdit(false);
      message.success('操作成功');
      setRedact('');
      onFinished();
      setSystemValue(null);
    }) .catch(() => {
      setLoading(false);
      message.error('操作失败');
    });
  };
  // 校正规则
  const checked = (rule, value) => {
    const reg = /\S/g;
    const regname = /^\+?[1-9][0-9]*$/;
    if (!reg.test(value) || value === undefined) {
      setSystemValue(null);
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (value >=720||!regname.test(value)) {
      setSystemValue(null);
      setRedact(null);
      return Promise.reject(new Error('数值填写不符合规范，请填写0~720范围内的整数'));
    } else {
      setSystemValue(value);
      setRedact(value);
      return Promise.resolve();
    }
  };

  // 确认退出编辑
  const confirm=()=>{
    setEdit(false);
    setVisible(false);
    setSystemValue(null);
    setRedact('');
  };

  useEffect(() => {
    if (systemInfo && edit) {
      form.setFieldsValue({
        heartBeatRound: systemInfo,
      });
    }
  }, [systemInfo, edit]);

  useEffect(() => {
    if (open!=='off') {
      setEdit(false);
    }
  }, [open]);
  return (
    <div >
      <h2>设备相关设置</h2>
      <h3>
        设备离线判断规则
        <span onClick={() => {
          if (redact==='') {
            changeOpen();
            setEdit(true);
          }
        }} className="padding-l-m point">
          <img src={edit?editIcon:redact===''?editIcon:forbidIcon} alt="edit" />
        </span>
      </h3>
      <div className="hint-system">
        <InfoCircleOutlined className="off-icon" />
        <p className="details-h6">设备心跳默认2分钟每轮，可设置连续X轮未收到数据包以及心跳包的蓝牙嗅探设备将显示为离线，修改后立即生效。</p>
      </div>
      {edit ? (
        <Spin spinning={loading}>
          <Form name="base" form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={[16]} className="off-lines">
              <Col>
                <Form.Item label="离线判断条件:" name="heartBeatRound" rules={[{required: true, validator: checked}]}>
                  <div className="off-set">
                    <label className="continuous">连续</label>
                    <Input maxLength={10} className="off-input" defaultValue={systemInfo}/>
                    <label>轮未收到心跳包</label>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <div className="text-right">
              <Space>
                <Button
                  onClick={() => {
                    if (systemValue) {
                      setVisible(true);
                    } else {
                      setEdit(false);
                      setRedact('');
                    }
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Space>
            </div>
          </Form>
        </Spin>
      ) : (
        <div className="details-info margin-t-l">
          <Row className="margin-b-l">
            <Col span={12}>
              <span className="padding-r-m">离线判断条件: </span>
              <span>连续{systemInfo}轮未收到心跳包</span>
            </Col>
          </Row>
        </div>
      )}
      {/* 退出编辑 */}
      {visible&&<ExitEdit visible={visible} setVisible={()=>setVisible(false)} confirm={confirm}/>}
    </div>
  );
};

OffLine.propTypes = {
  systemInfo: PropTypes.number,
  onFinished: PropTypes.func,
  open: PropTypes.string,
  changeOpen: PropTypes.func,
  redact: PropTypes.any,
  setRedact: PropTypes.func,
};

export default OffLine;
