import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import './mapStyle.css';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';
import {zoomLevel} from '../statusCollection';
import {Spin} from 'antd';
import {
  getMapZoomCenter,
} from '../../api';
import {toThousands} from '../../utils/dataFormat';
let AMapAPI;
let infoWindow;
let isClosed=false;

/**
 * 设备所在位置地图组件
 * @author ziwei.Dong
 * @date 2022-12-22
 * {
 * @param {array} points 设备所在点
 * @param {number} reload 重置中心点
 * @param {number} bikeType 1 单车 2 电单车
 * @param {any} onMakerClick 点击
 * }
 * @return {JSXDom}
 */
const DeviceMakers = ({points = [],
  reload, bikeType, onMakerClick}) => {
  const mapRef = useRef();
  const [mapLoading, setMapLoading] = useState(true);
  const [zoom, setZoom] = useState(13);
  const [center, setCenter] = useState([104.065861, 30.657401]);
  const openInfo = (data, position) => {
    // 构建信息窗体中显示的内容

    const info = [];
    info.push(`<div
          class="input-card content-window-card">
          <div>
          </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">
            <div class="amap-info-title">
            ${data.equipmentName}</div>`);
    info.push(`<div class="amap-info-box"><div class='amap-info-item'>IMEI：${data.imei}</div>`);
    info.push(`<div class='amap-info-item'>位置编号：${data.locationNo?.replace(/\//g, ',')||'暂无数据'}</div>`);
    info.push(`<div class='amap-info-item'>监测${bikeType === 2 ? '电' : ''}单车数：${toThousands(data.count)}</div>`);
    info.push(`</div></div>`);


    infoWindow = new AMapAPI.InfoWindow({
      offset: new AMapAPI.Pixel(-4, -28),
      autoMove: true,
      content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
    });
    infoWindow.on('open', () => {
      // setTimeout(() => {
      // if (points[0]) {
      // if (infoWindow.getIsOpen()) {
      // if (data.id!==points[0].id) {
      onMakerClick(data);
      // } else {
      //   onMakerClick(points[0]);
      // }
      // }
      // }, 100);
    });
    infoWindow.on('close', () => {
      if (!isClosed&&data.id!==points[0].id) {
        isClosed=true;
        setTimeout(()=>{
          isClosed=false;
        }, 500);
      }
    });
    infoWindow.open(mapRef.current.map, position);
    mapRef.current.map.setCenter(position);
  };


  /**
   * 描述 设备所在点等级，通过判断确定marker class
   * @author ziwei.Dong
   * @date 2022-12-22
   * @param {number} bikeCount 设备监测到的车辆数
   * @param {number} bikeDispatchNum 调运值
   * @param {number} bikeAlertNum 预警值
   * @param {number} bikeStandardNum 标准值
   * @return {string}
   * hight --调运值：bikeCount>调运值；
   * alarm --预警值：预警值<bikeCount=<调运值
   * normal --标准值：标准值<bikeCount=<预警值
   * low --低于标准值：bikeCount=<标准值
   */
  const markerLevel = (bikeCount, bikeDispatchNum, bikeAlertNum, bikeStandardNum) => {
    if (bikeCount > bikeDispatchNum) {
      return 'high';
    } else if (bikeCount > bikeAlertNum) {
      return 'alarm';
    } else if (bikeCount > bikeStandardNum) {
      return 'normal';
    } else {
      return 'low';
    }
  };

  const markerContent = (point) => {
    const makerClass = markerLevel(point.count,
        point.bikeDispatchNum,
        point.bikeAlertNum,
        point.bikeStandardNum);
    const wive = ` <div >
    <div class="level">
      <div class="level-box level-box-${makerClass}">
        <div class="pulse-b"/>
        <div class="pulse-m"/>
        <div class="pulse-t"/>
      </div>
    </div>
    </div>
    <div class='level-text'>
      ${toThousands(point.count)}
    </div>
    `;
    return wive;
  };

  const addMarker = (points) => {
    const overlays = mapRef.current?.map?.getAllOverlays('marker');
    if (overlays?.length > 0) {
      mapRef.current.map.remove(overlays);
    }
    points.forEach((point) => {
      const marker = new AMapAPI.Marker({
        position: point.latitudeLongitude.split(','),
        // 将 html 传给 content
        content: markerContent(point),
        // 以 icon 的 [center bottom] 为原点
        offset: new AMapAPI.Pixel(-13, -30),
        clickable: true,
      });
      // 将 markers 添加到地图
      mapRef.current.map.add(marker);
      marker.on('click', (e) => {
        openInfo(point, e.target.getPosition());
      });
    });
  };

  const mapLoader = (center, zoom) => {
    AMapLoader.load({
      ...AMapKey,
      plugins: [
        'AMap.Marker',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      mapRef.current.map = new AMap.Map('map', {
        resizeEnable: true,
        center: center,
        // pitch: 40,
        showLabel: true,
        zoom: zoom,
        mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      AMapAPI = AMap;
      setTimeout(() => {
        setMapLoading(false);
      }, 5000);
    });
  };

  const mapInit = () => {
    getMapZoomCenter(2).then((res) => {
      const center = res.data.latitudeLongitude ?
        res.data.latitudeLongitude.split(',') :
        [104.065861, 30.657401];
      const zoom = zoomLevel(res.data.level);
      mapLoader(center, zoom);
      setCenter(center);
      setZoom(zoom);
    }).catch(() => {
      mapLoader([104.065861, 30.657401], 13);
    });
  };


  useEffect(() => {
    mapInit();
  }, [bikeType]);
  useEffect(()=>{
    if (isClosed&&points.length>0) {
      onMakerClick(points[points.length-1]);
    }
  }, [isClosed]);

  useEffect(() => {
    if (AMapAPI) {
      if (infoWindow) {
        infoWindow.close();
      }
      // areaId=null;
      if (points.length>0) {
        onMakerClick(points[points.length-1]);
      }
      addMarker(points);
      // addGuaranteeArea(markRangeList);
    }
  }, [points, mapLoading]);

  useEffect(() => {
    if (reload > 0) {
      mapRef.current.amap.setZoomAndCenter(zoom, center);
    }
  }, [reload, bikeType]);

  return (
    <Spin
      spinning={mapLoading}
      // delay={3000}
      tip="地图组件加载中..."
      size="large"
      wrapperClassName="map-loading"
    >
      <div
        ref={mapRef}
        id="map"
        className="device-maker-map"
      />
    </Spin>
  );
};
DeviceMakers.propTypes = {
  bikeType: PropTypes.number.isRequired,
  title: PropTypes.string,
  onMakerClick: PropTypes.func.isRequired,
  reload: PropTypes.number.isRequired,
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
};


export default DeviceMakers;
