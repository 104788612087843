import React, {useEffect, useRef, useState} from 'react';
import {Col, Layout, Menu, Row, Space, Button, Dropdown, Modal, message, notification} from 'antd';
import './style.css';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {storage} from '../../utils/storage';
import CustomMenus from './sider/CustomMenus';
// import CustomRouters from '../routers';
import {Link, Outlet, useNavigate, useLocation} from 'react-router-dom';
import EditForms from '../forms/EditForms';
import Base64 from 'base-64';
import logo from '../../asset/logo.png';
import {sendNoticeInfo, side, updateMyInfo, changeImportType} from '../../store/actions';
import {getEnterprise} from '../../components/statusCollection';
import {updatePhone, revisePwd, userLogout, getUserInfo} from '../../api';
import WebSocketClient from '../../api/webSocket';
import {WEBSOCKETURL} from '../../api/Urls';

const {Header, Content, Sider} = Layout;

const CustomLayout = ({collapsed, setCollapsedMenus, myInfoCount, setMyInfoCount
  , setNoticeInfo, importTypeCount, setImportType}) => {
  const ng = useNavigate();
  const [l] = useState(useLocation());
  const userInfo = storage.getData('userInfo') ?? null;
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle]= useState('我的信息');
  const [myInfo, setMyInfo]=useState({});
  const websocketRef = useRef(null);
  // const [myInfoCount, setMyInfoCount] = useState(0);
  const openNotificationWithIcon = (type, msg) => {
    const key = `open${Date.now()}`;
    const btn =<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>;
    api[type]({
      message: type==='success'?'单车批量导入成功':'单车批量导入失败',
      description: msg,
      key,
      btn,
      duration: 0,
    });
  };

  const handleLogoutButton = () => {
    userLogout().then((res)=>{});
    storage.clearData('local', 'alarmType');
    storage.clearData('local', 'userInfo');
    storage.clearData('session', 'isShowKeyArea');
    storage.clearData('session', 'isShowMessage');
    ng('/login');
  };
  const pwdForms=[
    {
      label: '旧密码',
      name: 'oldPwd',
      type: 'pwdInput',
      message: '请输入旧密码',
      message2: '请输入旧密码',
      placeholder: '请输入旧密码',
      required: true,
    }, {
      label: '新密码',
      name: 'password',
      type: 'pwdInput',
      message: '请输入新密码',
      message2: '请输入新密码',
      placeholder: '请输入新密码',
      required: true,
      rules: [
        {
          message: '请输入8-12位数字和字母',
          required: true,
        },
        ({getFieldValue}) => ({
          validator(_, value) {
            if (new RegExp('[`~!@#$^&*()=|{}\':;\',\\[\\].<>《》/?~!@#￥……&*（）——|{}【】‘；：”“\'。，、？ ]').test(value)==false ) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('不可包含特殊字符或空格'));
          },
        }),
        {
          pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,12}$/,
          message: '请输入8-12位数字和字母',
        },
      ],
    },
    {
      label: ' ',
      name: 'username',
      type: 'input',
      message: ' ',
      message2: ' ',
      hidden: true,
    },
    {
      label: ' ',
      name: 'id',
      type: 'input',
      message: ' ',
      message2: ' ',
      required: true,
      hidden: true,
    },
  ];
  const profileForms=[
    {
      label: '联系方式',
      name: 'mobile',
      type: 'input',
      rules: [
        {
          message: '请输入电话号',
          required: true,
        }, {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的电话号',
        }],
    }, {
      label: ' ',
      name: 'id',
      type: 'input',
      message: ' ',
      message2: ' ',
      hidden: true,
      required: true,
    }];
  const onChangePWD=(values)=>{
    const data= {
      oldPwd: Base64.encode(`${values.oldPwd}hcy_shared_bikes@2022`),
      password: Base64.encode(`${values.password}hcy_shared_bikes@2022`),
    };
    revisePwd(data, 2).then((res)=>{
      message.success('密码修改成功');
      storage.clearData('local', 'alarmType');
      storage.clearData('local', 'userInfo');
      storage.clearData('session', 'isShowKeyArea');
      storage.clearData('session', 'isShowMessage');
      ng('/login');
    });
  };

  const onChangeProfile=(values)=>{
    delete values.userId;
    updatePhone(values).then((res)=>{
      message.success('联系方式修改成功');
      setIsModalVisible(false);
    });
  };

  useEffect(() => {
    // if (userInfo) {
    getUserInfo(2).then((res) => {
      if (Reflect.ownKeys(res.data).length > 0) {
        setMyInfo(res.data);
        const data={
          userName: res.data.userName,
          bikeEnterprise: res.data.bikeEnterprise,
          areaName: res.data.areaName,
        };
        localStorage.setItem('bikeEnterprise', JSON.stringify(data));
        localStorage.setItem('myInfo', JSON.stringify(res.data));
      }
    });
    // }
  }, [myInfoCount]);

  useEffect(()=>{
    const login=storage.getData('userInfo') ?? false;
    if (!login) {
      ng('/login');
    } else {
      if (l.pathname==='/') {
        ng('/home');
      }
      setCollapsedMenus(false);
    }
  }, [l]);
  // const url = 'ws://47.108.232.235:9000/websocket';
  // const url = 'wss://tips.smartecycle.cn:9110/websocket';

  /**
   * 初始化websocket
   */
  function initWebSocket() {
    const token = storage.getData('userInfo')?.token?.split('Bearer ')[1] || '';
    websocketRef.current = new WebSocketClient(`${WEBSOCKETURL}?token=${token}`);
    websocketRef.current.onMessage((res) => {
      // console.log(res);
      // console.log(JSON.parse(res.data), new Date());
      const data=JSON.parse(res.data);
      if (data.type === 'message.web.count') {
        if (window.location.pathname === '/screen/bikeManage') {
          setNoticeInfo(JSON.parse(res.data));
        } else {
          setNoticeInfo({});
        }
      } else if (data.type === 'message.web.bike-upload') {
        if (data.message.code === 200) {
          openNotificationWithIcon('success', data.message.msg);
          setImportType(importTypeCount + 1);
        } else {
          openNotificationWithIcon('error', data.message.msg);
        }
      }
    });
    websocketRef.current.connect(); // connect to the WebSocket server
    // openNotificationWithIcon('error', null, 1);
  }
  useEffect(() => {
    initWebSocket();
    return () => {
      websocketRef.current.disconnect(); // disconnect from the WebSocket server
    };
  }, []);
  return (
    <Layout>
      {contextHolder}
      {!collapsed&&<Header>
        <Row className="theme-header">
          <Col span={12} className="header-title">
            <img src={logo} alt="logo" width="32" height="32" className="margin-r-m"/>
            成都市共享单车智慧监管系统
          </Col>
          <Col span={12} className="text-right padding-r-m">
            <Space>
              <Dropdown
                trigger={['click']}
                arrow
                overlay={(<Menu>
                  <Menu.Item key="profile">
                    <Button
                      type="text"
                      onClick={() => {
                        setModalTitle('我的信息');
                        setIsModalVisible(true);
                        setMyInfoCount(myInfoCount+1);
                      }}>我的信息</Button>
                  </Menu.Item>
                  <Menu.Item key="setting">
                    <Button
                      type="text"
                      onClick={()=>{
                        setModalTitle('修改联系方式');
                        setIsModalVisible(true);
                        setMyInfoCount(myInfoCount+1);
                      }}>修改联系方式</Button>
                  </Menu.Item>
                  <Menu.Item key="changePwd">
                    <Button
                      type="text"
                      onClick={()=>{
                        setModalTitle('修改密码');
                        setIsModalVisible(true);
                      }}>修改密码</Button>
                  </Menu.Item>
                </Menu>
                )} >
                <Button type="link" className="text-white">
                  {/* {userInfo ? userInfo.roleName + '：' + userInfo.nickName : 'Admin'} */}
                  {JSON.stringify(myInfo) !== '{}' ? myInfo.roleName+'：'+myInfo.name : 'Admin'}
                </Button>
              </Dropdown>
              <Button type="link" className="text-white" >
                <Link to="/login" onClick={handleLogoutButton}>
                退出登录</Link>
              </Button>
            </Space>
          </Col>
        </Row>
        <Modal
          title={modalTitle}
          visible={isModalVisible}
          onCancel={()=> {
            setIsModalVisible(false);
            setModalTitle('修改密码');
          }}
          maskClosable={false}
          destroyOnClose
          footer={null}
        >
          {modalTitle==='我的信息'&&(<div>
            <p>姓名：<span className="modal-myInfo">{myInfo.name ||'暂无数据'}</span></p>
            <p>用户名：<span className="modal-myInfo">{myInfo.userName ||'暂无数据'}</span></p>
            <p>联系方式：<span className="modal-myInfo">{myInfo.phone || '暂无数据'}</span></p>
            <p>角色名：<span className="modal-myInfo">{myInfo.roleName|| '暂无数据'}</span></p>
            <p>管辖区域：<span className="modal-myInfo">{myInfo.areaName||'无区域'}</span></p>
            <p>管辖企业：<span className="modal-myInfo">{getEnterprise(myInfo.bikeEnterprise)}</span></p>
          </div>)}
          {modalTitle==='修改密码'&&(<EditForms
            initData={{id: userInfo.userId, username: userInfo.userName}}
            columnsForm={pwdForms}
            loading={false}
            onCancel={()=>setIsModalVisible(false)}
            buttonStyle="modal-button"
            onFinish={onChangePWD}/>)}
          {modalTitle==='修改联系方式'&&(<EditForms
            columnsForm={profileForms}
            initData={{id: userInfo.userId, mobile: myInfo.phone}}
            loading={false}
            buttonStyle="modal-button"
            onCancel={()=>setIsModalVisible(false)}
            onFinish={onChangeProfile}/>)}
        </Modal>
      </Header>}
      <Layout className={!collapsed?'custom-layout':''}>
        {!collapsed&&<Sider width={240}>
          <CustomMenus/>
        </Sider>}
        <Layout>
          <Content className={!collapsed?'custom-content':''}>
            <Outlet/>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

CustomLayout.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsedMenus: PropTypes.func.isRequired,
  myInfoCount: PropTypes.number.isRequired,
  setMyInfoCount: PropTypes.func.isRequired,
  setNoticeInfo: PropTypes.func.isRequired,
  importTypeCount: PropTypes.number.isRequired,
  setImportType: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    collapsed: state.collapsedMenus.collapsed,
    myInfoCount: state.myInfo.myInfoCount,
    importTypeCount: state.importType.count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCollapsedMenus: (f) => dispatch(side(f)),
    setMyInfoCount: (n) => dispatch(updateMyInfo(n)),
    setNoticeInfo: (obj) => dispatch(sendNoticeInfo(obj)),
    setImportType: (n)=>dispatch(changeImportType(n)),
  };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomLayout);


