import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Line, G2} from '@ant-design/charts';
import customTheme from './g2-theme.json';
import {isMockData} from '../../utils/judge';

// eslint-disable-next-line react/display-name
const LineCharts = React.memo(({data}) => {
  const [lineData, setLineData] = useState([]);
  const isChangeData = isMockData();
  // const [chartWidth, setChartWidth]=useState('100%');
  const {registerTheme} = G2;

  registerTheme('custom-theme', customTheme);
  const config = {
    theme: 'custom-theme',
    data: lineData,
    xField: 'dateTime',
    yField: 'value',
    xAxis: {
      tickCount: 2,
    },
    yAxis: {
      label: isChangeData?null:{},
      tickCount: 4,
    },
    legend: {
      position: 'top-right',
    },
    slider: {
      start: 0,
      end: 1,
      smooth: true,
    },
    autoFit: true,
    color: '#85A5FF',
    tooltip: {
      customContent: (title, items) => {
        if (items[0]) {
          return `
        <div style="padding: 8px; width: 140px">  
        <div style="line-height:24px">${title}<div>
        <div style="display: flex; justify-content: space-between;">
          <span>
          <span 
          style="display:inline-block;
          width: 8px;
           height: 8px;
            border-radius: 50%; 
            background-color: #EB2F96; margin-right: 10px;"
            ></span>
          应配：
          </span>
          <span>${items[0].data.arrive || 0}</span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <span>
          <span 
          style="display:inline-block;
          width: 8px;
           height: 8px;
            border-radius: 50%; 
            background-color: #85A5FF; margin-right: 10px;"
            ></span>
          到岗：
          </span>
          <span>${items[0].data.value || 0}</span>
        </div>
        </div>
        `;
        }
        return '';
      },
    },
  };
  useEffect(() => {
    if (lineData !== data) {
      setLineData(data);
    }
  }, [data]);

  return <Line {...config} />;
});

LineCharts.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LineCharts;

