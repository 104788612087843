import React, {useEffect, useState} from 'react';
import {getAttendanceSimpleRule} from '../../api';
import {Skeleton} from 'antd';
import PropTypes from 'prop-types';
import {attendanceDateStep} from '../statusCollection';
// import PropTypes from 'prop-types';

const AttendanceInfoSimple =({attendId}) => {
  const [ruleData, setRuleData]= useState(null);
  useEffect(()=>{
    getAttendanceSimpleRule(attendId, '2').then((res)=>{
      setRuleData(res.data);
    });
  }, []);
  return (
    <div style={{minWidth: 240}}>{ruleData?(<div>
      <div className="margin-b-m">
        {attendanceDateStep(ruleData.attendanceCommonRule.days.split(','))}，
        节假日{ruleData.attendanceCommonRule.isExcludeHolidays ? '不' : ''}打卡
      </div>
      {ruleData.attendanceRule && ruleData.attendanceRule.map((item, index) => (
        <div key={`Rule${index}`}>
          <span className="padding-r-s">【{index + 1}】</span>
          {item.startTime}-{item.endTime}
        </div>
      ))}
    </div>):(<div>
      <Skeleton active />
    </div>)}
    </div>
  );
};

AttendanceInfoSimple.propTypes = {
  attendId: PropTypes.number.isRequired,
};

export default AttendanceInfoSimple;
