/* eslint-disable react/react-in-jsx-scope */
import nation from './nation.json';
import politics from './politics.json';
import emergencyRelation from './emergencyRelation.json';
import degree from './degree.json';
import health from './health.json';
import maritalStatus from './maritalStatus.json';
import {Tooltip, Divider} from 'antd';
import {ExclamationCircleOutlined, ExclamationCircleFilled} from '@ant-design/icons';

const tooltip=(text)=>{
  return (
    <div style={{color: 'black'}}>
      <div>
        <ExclamationCircleFilled style={{color: '#FAAD14', marginRight: 9}}/>
        <span>提示说明</span>
      </div>
      <Divider className="margin-t-s margin-b-s"/>
      <p className="tipInfo">{text}</p>
    </div>
  );
};
const addForm=[
  {
    label: '基础信息',
    type: 'title',
  },
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    message: 'Please input !',
    message2: '请输入姓名',
    required: true,
    col: 12,
    placeholder: '请输入',
    maxLength: 10,
  },
  {
    label: '性别',
    name: 'sex',
    type: 'select',
    message: 'Please select send type!',
    placeholder: '请选择',
    // required: true,
    col: 12,
    options: [
      {
        label1: '男',
        label2: '男',
        value: 0,
      },
      {
        label1: '女',
        label2: '女',
        value: 1,
      },
    ],
  },
  {
    type: 'box',
    children: [{
      label: '联系电话',
      name: 'phoneNumber',
      type: 'input',
      rules: [
        {
          message: '请输入电话号',
          required: true,
        }, {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的电话号',
        }],
      placeholder: '请输入',
    },
    {
      label: '身份证号',
      name: 'idCard',
      type: 'input',
      onChangeKey: 'age',
      onChangeRule: 'age',
      rules: [
        {
          pattern: /(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '请输入正确的身份证号',
        }],
      placeholder: '请输入',
      maxLength: 18,
    },
    ],
    col: 12,
  },
  {
    label: '照片',
    name: 'file',
    type: 'image',
    message: 'Please select send type!',
    message2: '请上传照片',
    col: 12,
  },
  {
    label: '年龄(自动计算)',
    name: 'age',
    type: 'input',
    message: 'Please input !',
    message2: '请输入',
    col: 12,
    disabled: true,
  },
  {
    label: '婚姻状况',
    name: 'maritalStatus',
    key: 'select Single1',
    type: 'select',
    col: 12,
    placeholder: '请选择',
    options: maritalStatus,
  },
  {
    label: '学历',
    name: 'degree',
    key: 'select Single2',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: degree,
  },
  {
    label: '民族',
    name: 'nation',
    key: 'select Single3',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: nation,
  },
  {
    label: '健康状况',
    name: 'health',
    key: 'select Single4',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: health,
  },
  {
    label: '政治面貌',
    name: 'politics',
    key: 'select Single5',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: politics,
  },
  {
    label: '紧急联系人姓名',
    name: 'emergencyName',
    type: 'input',
    message: 'Please input !',
    message2: '请输入紧急联系人姓名',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '紧急联系人电话',
    name: 'emergencyPhone',
    type: 'input',
    message: 'Please input !',
    message2: '请输入紧急联系人电话',
    rules: [
      {
        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        message: '请输入正确的电话号',
      }],
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '与紧急联系人的关系',
    name: 'emergencyRelation',
    key: 'select Single6',
    col: 12,
    type: 'select',
    placeholder: '请输入',
    options: emergencyRelation,
  },
  {label: ' ',
    type: '1',
    col: 12,
  },
  {
    label: '家庭地址',
    name: 'homeAddress',
    type: 'textArea',
    message: 'Please input !',
    message2: '请输入家庭地址',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '联系地址',
    name: 'contactAddress',
    type: 'textArea',
    message: 'Please input !',
    message2: '请输入联系地址',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '职务信息',
    type: 'title',
  },
  {
    label: <span><span>所属区域</span> <Tooltip title={tooltip(
        '所属区域非必填，默认不填写显示‘无区域。’修改该运维人员所属区域，人员将同步从原考勤组中移除。')} color="white">
      <ExclamationCircleOutlined style={{color: '#8C8C8C', marginRight: 9}}/>
    </Tooltip></span>,
    name: 'areaCascader',
    key: 'areaCascader',
    message2: '请选择所属区域',
    required: false,
    col: 12,
    type: 'areaCascader',
    placeholder: '请选择',
  },
  {
    label: '城管企业',
    name: 'manageEnterprise',
    key: 'manageEnterprise',
    col: 12,
    type: 'select',
    rules: [{
      message: '请选择城管企业',
      required: true,
    }],
    placeholder: '请选择',
    options: [
      {
        value: 1,
        label1: '市容环卫中心',
        label2: '市容环卫中心',
      },
      {
        value: 2,
        label1: '其他环卫中心',
        label2: '其他环卫中心',
      },
    ],
  },
  {
    label: '入职日期',
    name: 'entryDate',
    key: 'entryDate',
    type: 'date',
    // rules: [{
    //   message: '请选择入职日期',
    //   // required: true,
    // }],
    onChangeKey: 'workingYears',
    onChangeRule: 'workYear',
    col: 12,
  },
  {
    label: '工龄(自动计算)',
    name: 'workingYears',
    type: 'input',
    key: 'workingYears',
    message: 'Please input !',
    message2: '请输入',
    disabled: true,
    col: 12,
  },
  {
    name: 'streetId',
    key: 'streetId',
    type: 'input',
    col: 0,
    hidden: true,
  },
  {
    name: 'id',
    type: 'input',
    col: 0,
    hidden: true,
  },
];
export default addForm;
