import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../../components/detailsHeader';
import {Button, Card, Modal, Row, Col, message, Skeleton} from 'antd';
import {deleteRole, roleDetails} from '../../../../api';
import {useNavigate, useParams} from 'react-router-dom';
// import PropTypes from 'prop-types';

const RoleDetails = () => {
  const [infoData, setInfoData] = useState(null);
  const [menus, setMenus] = useState(null);
  const ng = useNavigate();
  const d = useParams();
  const deleteRoleBtn = () => {
    Modal.confirm({
      title: '确认删除角色',
      content: (
        <div>
          <p>删除角色后不可恢复，是否确认删除角色？
          </p>
        </div>
      ),
      cancelButtonProps: {
        className: 'dangerous',
      },
      onCancel: ()=>{
        deleteRole(d.roleId, '2').then((res)=>{
          message.success(res.msg);
          ng('/accessControl/roles');
        });
      },
      cancelText: '删除',
      okText: '取消',
      okType: 'default',
    });
  };
  const getUserInfo=()=> {
    roleDetails(d.roleId, 2).then((res) => {
      setInfoData(res.data);
      const menus = [];
      res.data.roleMenu.children.forEach(({cnName, children}) => {
        menus.push(cnName);
        children.forEach(({cnName}) => {
          menus.push(cnName);
        });
      });
      setMenus(menus);
    });
  };
  useEffect(()=>{
    getUserInfo();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="角色详情"
        buttonGroup={() =>{
          return parseInt(d.roleId)>4?(
            <Button type="primary" onClick={deleteRoleBtn}>
          删除角色
            </Button>):null;
        }}/>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <div>
          <h3 className="details-title">
            角色信息
          </h3>
          {infoData ? (<div className="details-info margin-t-l">
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>
                角色名：
                </span>
                <span className="text-gray">
                  {infoData.roleInfo.roleName}
                </span>
              </Col>
              <Col span={7}>
                <span>
                关联用户：
                </span>
                <span className="text-gray">
                  {infoData.roleInfo.relevanceUser}
                </span>
              </Col>
              <Col span={10}>
                <span>创建时间：</span>
                <span className="text-gray">
                  {infoData.roleInfo.gmtCreate || '暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>备注：</span>
                <span className="text-gray">
                  {infoData.roleInfo.remark || '暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>页面权限：</span>
                <span className="text-gray">{menus && menus.join('，')}</span>
              </Col>
            </Row>
          </div>) : (<Skeleton active/>)}
        </div>
      </Card>
    </div>
  );
};

RoleDetails.propTypes = {};

export default RoleDetails;
