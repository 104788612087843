import React, {useState, useMemo, useEffect} from 'react';
import {weightSave, weightFind, getSummaryStatistics} from '../../../../../api';
import {LeftOutlined, RightOutlined} from '@ant-design/icons/lib/icons';
import {Modal, Form, InputNumber, message, Spin} from 'antd';
import SubTitle from './../../../../../components/subtitle/SubTitle';
import {useForm} from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import Target from './Target';
import Supervise from './Supervise';
import DataStatistics from './DataStatistics';
import noData from '../../../../../asset/noData.png';
import ExportDocument from './ExportDocument';
// import dateDemo from './date.json'; // 数据模拟

let clicks = -1;
const Statement = ({bikeType, vehicleType}) => {
  const [weightFrom] = useForm();
  const [tabsKey, setTabsKey] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rankingData, setRankingData] = useState();
  const [loading, setLoading] = useState(false);

  const [monitorData, setMonitorData] = useState();
  const [monitorCardData, setMonitorCardData] = useState();
  const [staffArrivalPieData, setStaffArrivalPieData] = useState();
  const [staffArrivalBarData, setStaffArrivalBarData] = useState();
  const [carArrivalPieData, setCarArrivalPieData] = useState();
  const [carArrivalBarData, setCarArrivalBarData] = useState();
  const [carNotRecordlPieData, setCarNotRecordlPieData] = useState();
  const [carNotRecordlBarData, setCarNotRecordlBarData] = useState();
  const [staffOnLinePieData, setStaffOnLinePieData] = useState();
  const [staffOnLineBarData, setStaffOnLineBarData] = useState();
  const [carOnLinePieData, setCarOnLinePieData] = useState();
  const [carOnLineBarData, setCarOnLineBarData] = useState();
  const [crossPieData, setCrossPieData] = useState();
  const [crossBarData, setCrossBarData] = useState();
  const [reportPieData, setReportPieData]= useState();
  const [reportBarData, setReportBarData]= useState();
  const [clicksTime, setClicksTime] = useState(clicks);
  const [btnDisableR, setBtnDisableR] = useState(false);
  const [btnDisableL, setBtnDisableL] = useState(false);
  const [isDataTarget, setIsDataTarget] = useState(false);
  const [modalType, setModalType] = useState();
  const [reportType, setReportType] = useState();
  const [dailyTime, setDailyTime] = useState();

  const [viewData] = useState({
    attendanceUserWeight: '20',
    attendanceBikeWeight: '15',
    onlineUserWeight: '30',
    onlineBikeWeight: '5',
    unregisteredWeight: '10',
    crossRegionWeight: '20',
  });
  const weightTarget = [
    {
      label: `【${vehicleType}秩序人员出勤率】指标权重`,
      name: 'attendanceUserWeight',
      key: 'attendanceUserWeight',
    },
    {
      label: `【${vehicleType}调运车辆出勤率】指标权重`,
      name: 'attendanceBikeWeight',
      key: 'attendanceBikeWeight',
    },
    {
      label: `【${vehicleType}人员在线率】指标权重`,
      name: 'onlineUserWeight',
      key: 'onlineUserWeight',
    },
    {
      label: `【${vehicleType}调运车辆在线率】指标权重`,
      name: 'onlineBikeWeight',
      key: 'onlineBikeWeight',
    },
    {
      label: `【${vehicleType}未备案率】指标权重`,
      name: 'unregisteredWeight',
      key: 'unregisteredWeight',
    },
    {
      label: `【${vehicleType}跨区域率】指标权重`,
      name: 'crossRegionWeight',
      key: 'crossRegionWeight',
    },
  ];
  // 汇总报表全部接口
  const getTargetEvaluation = (key, time) => {
    const data = {
      bikeType: bikeType,
      statisticsType: key,
      statisticsDate: time,
    };
    if (key !== 1) delete data.statisticsDate;

    getSummaryStatistics(data, 2)
        .then((resp) => {
          if (tabsKey===1) {
            setDailyTime(resp.data?.statisticsDate?.split(';')[0]);
          } else {
            const time=getNextDate(getTime(), -1);
            setDailyTime(time);
          }
          // console.log(resp.data, '接口');
          setRankingData(resp.data);
          const demoDate = resp.data.statisticsCompanyVOList;
          const monitorData = demoDate?.find((item) => item.target === 0);
          // 0-监测数；1-出勤率(运维人员)；2-出勤率(调运车辆)；3-在线率(运维人员)；4-在线率(调运车辆)；6-未备案；7-跨区域
          // 2.区域监测数
          setMonitorData([...pieDataSet(demoDate, 0, 'pie')]);
          setMonitorCardData(monitorData?.bikeEnterpriseList);
          // 3.人员出勤数
          setStaffArrivalPieData([...pieDataSet(demoDate, 1, 'pie')]);
          setStaffArrivalBarData([...pieDataSet(demoDate, 1, 'bar')]);
          // 4:车辆出勤数
          setCarArrivalPieData([...pieDataSet(demoDate, 2, 'pie')]);
          setCarArrivalBarData([...pieDataSet(demoDate, 2, 'bar')]);
          // 5.人员在线数据
          setStaffOnLinePieData([...pieDataSet(demoDate, 3, 'pie')]);
          setStaffOnLineBarData([...pieDataSet(demoDate, 3, 'bar')]);
          // 6.车辆在线数据
          setCarOnLinePieData([...pieDataSet(demoDate, 4, 'pie')]);
          setCarOnLineBarData([...pieDataSet(demoDate, 4, 'bar')]);
          // 7:车辆未备案数
          setCarNotRecordlPieData([...pieDataSet(demoDate, 6, 'pie')]);
          setCarNotRecordlBarData([...pieDataSet(demoDate, 6, 'bar')]);
          // 8. 跨区域
          setCrossPieData([...pieDataSet(demoDate, 7, 'pie')]);
          setCrossBarData([...pieDataSet(demoDate, 7, 'bar')]);
          // 9. 告警数据
          setReportPieData([...pieDataSet(demoDate, 8, 'pie')]);
          setReportBarData([...pieDataSet(demoDate, 8, 'bar')]);
        })
        .finally(() => {
          setIsDataTarget(true);
        });
  };
  // 获取权重信息
  const weightInfoGet = () => {
    weightFind({bikeType: 1}, 2)
        .then((resp) => {
          console.log(resp.data);
          weightFrom.setFieldsValue({
            ...resp.data,
          });
        })
        .catch(() => {
          weightFrom.setFieldsValue(...viewData);
        });
  };

  // 饼图/柱状图数据设置
  const pieDataSet = (data, target, way) => {
    const monitorData = data.find((item) => item.target === target);
    const monitorPieData = [];
    // 排序
    monitorData?.bikeEnterpriseList.sort((a, b) => a.bikeEnterprise - b.bikeEnterprise);
    monitorData?.bikeEnterpriseList.forEach((item) => {
      monitorPieData.push({
        type: item.bikeEnterprise === 1 ? '美团' : item.bikeEnterprise === 2 ? '青桔' : '哈啰',
        value: item.statisticsCount,
        persent: way === 'pie' ? item.bikeEnterpriseProp : item.bikeEnterpriseRate,
        persentValue:
          way === 'pie' ?
            Number(item.bikeEnterpriseProp.toString()?.split('%')[0]) :
            Number(item.bikeEnterpriseRate.toString()?.split('%')[0]),
      });
    });
    // console.log(monitorPieData);
    return monitorPieData;
  };
  // 权重设置
  const showModal = () => {
    setModalType('weight');
    setIsModalOpen(true);
    weightInfoGet();
  };
  // 点击ok触发
  const handleOk = () => {
    if (modalType === 'weight') {
      weightFrom.submit();
    }
  };
  // 关闭权重弹框
  const handleCancel = () => {
    setIsModalOpen(false);
    weightFrom.resetFields();
  };
  // 指标权重设置
  const weight = useMemo(() => {
    // 权重设置校验
    const checkPrice = (rule, value, callback) => {
      const reg = /^[0-9]{1,2}$/;
      if (value > 100) {
        return Promise.reject(new Error('请输入0-100之间的整数'));
      }
      if (!reg.test(value) && value !== 100) {
        return Promise.reject(new Error('请输入整数'));
      } else {
        return Promise.resolve();
      }
    };
    return (
      <div>
        <div className="weight-hint">
          以下全部指标之和应为100%。修改后将影响汇总报表“车企指标考评”的车企分数和排名。修改成功后立即生效。
        </div>
        <Form
          name="dlightControlForm"
          form={weightFrom}
          initialValues={viewData}
          onFinish={(value) => {
            console.log(value);
            if (
              Number(value.attendanceUserWeight) +
                Number(value.attendanceBikeWeight) +
                Number(value.onlineUserWeight) +
                Number(value.onlineBikeWeight) +
                Number(value.unregisteredWeight) +
                Number(value.crossRegionWeight) ===
              100
            ) {
              setIsModalOpen(false);
              setLoading(true);
              setIsDataTarget(false);
              weightFrom.resetFields();
              weightSave({...value, bikeType: bikeType})
                  .then((resp) => {
                    message.success('指标权重修改成功');
                    getTargetEvaluation(tabsKey, getNextDate(getTime(), clicksTime));
                  })
                  .catch(() => {
                    message.error('指标权重修改失败');
                  });
            } else {
              message.error('多项指标之和应等于100%');
            }
          }}
          className="weight-from"
        >
          {weightTarget.map((item) => {
            return (
              <div className="label-input" key={item.key}>
                <label htmlFor="">{item.label}</label>
                <Form.Item name={item.name} label="" rules={[{validator: checkPrice}]}>
                  <InputNumber controls={false} addonAfter="%" />
                </Form.Item>
              </div>
            );
          })}
        </Form>
      </div>
    );
  }, [weightFrom, viewData, tabsKey, clicksTime, bikeType]);
  // 当前时间
  const getTime = () => {
    const curTime = new Date();
    const addMinute = new Date(curTime.setMinutes(curTime.getMinutes()));
    const data = new Date(addMinute);
    return dayTime(data);
  };
  // 前n天时间
  const getNextDate = (date, day) => {
    const dateNew = new Date(date);
    dateNew.setDate(dateNew.getDate() + day);
    return dayTime(dateNew);
  };
  // 时间解析
  const dayTime = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const dates = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${dates}`;
  };
  // 左右切换时间
  const liftRight = (type) => {
    // console.log(type);
    const time = getNextDate(getTime(), clicks);
    if (getNextDate(getTime(), -1) === time && type === 'right') {
      return;
    }
    if (getNextDate(getTime(), -7) === time && type === 'left') {
      return;
    } else {
      if (type === 'left') {
        clicks = clicks - 1;
      } else {
        clicks = clicks + 1;
      }
      setClicksTime(clicks);
      setBtnDisableR(false);
      setBtnDisableL(false);
    }
  };
  // 选择下载报表类型
  const onExport = (value) => {
    setModalType('export');
    const type = value === 1 ? '日' : value === 2 ? '周' : '月';
    setReportType(type);
  };
  useEffect(() => {
    return () => {
      clicks = -1;
    };
  }, []);
  useEffect(() => {
    getTargetEvaluation(tabsKey, getNextDate(getTime(), clicksTime));
    // arrvalDataGet(tabsKey, getNextDate(getTime(), clicksTime));
    setLoading(true);
    // setIsDataArrval(false);
    setIsDataTarget(false);
    const time = getNextDate(getTime(), clicksTime);
    if (getNextDate(getTime(), -7) === time) {
      setBtnDisableL(true);
    }
    if (getNextDate(getTime(), -1) === time) {
      setBtnDisableR(true);
    }
  }, [tabsKey, clicksTime]);
  useEffect(() => {
    if (isDataTarget) {
      setLoading(false);
    }
  }, [isDataTarget]);
  return (
    <Spin
      spinning={loading}
      className="spin-loading"
      wrapperClassName="map-loading"
      size="large"
      tip={
        <div className="tip-loading">
          <p className="spin-tip">该页面数据量较大，</p>
          <p className="spin-tip">请稍作等待</p>
        </div>
      }
    >
      {
        <div>
          {/* 标题切换 */}
          <SubTitle
            title={`${vehicleType}汇总报表`}
            buttonName={'权重设置'}
            exportBtn={'报表下载'}
            tab={[`${vehicleType}日报`, `${vehicleType}周报`, `${vehicleType}月报`]}
            onChangeTab={(key) => {
              setTabsKey(Number(key) + 1);
              setClicksTime(-1);
              // setIsDataArrval(false);
              setIsDataTarget(false);
              clicks = -1;
            }}
            onShow={() => showModal()}
            onExport={(event) => onExport(event)}
          />
          {rankingData?.statisticsDate && monitorCardData && staffArrivalBarData ?
            isDataTarget && (
              <div>
                {/* 左右切换 */}
                {rankingData?.statisticsDate && (
                  <div className="statement-time-change">
                    {tabsKey === 1 && (
                      <div
                        className={btnDisableL ? 'time-icon-disable' : 'time-icons'}
                        onClick={() => liftRight('left')}
                      >
                        <LeftOutlined />
                      </div>
                    )}
                    <div className="statement-time-text">
                      <p>
                        {tabsKey === 1 ?
                            `${rankingData.statisticsDate?.split(';')[0]} ${vehicleType}日报` :
                            tabsKey === 2 ?
                            `${vehicleType}周报` :
                            `${vehicleType}月报`}
                      </p>
                      <span>
                        {tabsKey === 1 ?
                            `可左右切换查看过去一周的每日${vehicleType}日报` :
                            tabsKey === 2 ?
                            `可查看过去一个自然周的${vehicleType}周报，此周报统计时间为${
                              rankingData.statisticsDate?.split(';')[0]
                            }至${rankingData.statisticsDate?.split(';')[1]}` :
                            `可查看过去一个月的月报，此月报统计时间为${rankingData.statisticsDate?.split(';')[0]}至${
                              rankingData.statisticsDate?.split(';')[1]
                            }`}
                      </span>
                    </div>
                    {tabsKey === 1 && (
                      <div
                        className={btnDisableR ? 'time-icon-disable' : 'time-icons'}
                        onClick={() => liftRight('right')}
                      >
                        <RightOutlined />
                      </div>
                    )}
                  </div>
                )}
                {/* 1. 车企指标考评 */}
                {rankingData?.statisticsDate && (
                  <div className="statement-module">
                    <h3>1.车企指标考评</h3>
                    <Target rankingData={rankingData} vehicleType={vehicleType}></Target>
                  </div>
                )}
                {/* 2.区域监测数 */}
                {tabsKey === 1 && monitorData && monitorCardData && (
                  <div className="statement-module">
                    <h3>2.区域{vehicleType}监测数</h3>
                    <Supervise
                      pieData={monitorData}
                      cardData={monitorCardData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 3.人员出勤数据 */}
                {staffArrivalPieData && staffArrivalBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 3 : 2}.{vehicleType}秩序人员出勤数据
                    </h3>
                    <DataStatistics
                      title={'人员出勤'}
                      pieData={staffArrivalPieData}
                      barData={staffArrivalBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 4.调运车辆出勤数据 */}
                {carArrivalPieData && carArrivalBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 4 : 3}.{vehicleType}调运车辆出勤数据
                    </h3>
                    <DataStatistics
                      title={'车辆出勤'}
                      pieData={carArrivalPieData}
                      barData={carArrivalBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 5.人员在线数据 */}
                {staffOnLinePieData && staffOnLineBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 5 : 4}.{vehicleType}秩序人员在线数据
                    </h3>
                    <DataStatistics
                      title={'人员在线'}
                      pieData={staffOnLinePieData}
                      barData={staffOnLineBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 6.调运车辆在线数据 */}
                {carOnLinePieData && carOnLineBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 6 : 5}.{vehicleType}调运车辆在线数据
                    </h3>
                    <DataStatistics
                      title={'车辆在线'}
                      pieData={carOnLinePieData}
                      barData={carOnLineBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 7.未备案数据 */}
                {carNotRecordlPieData && carNotRecordlBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 7 : 6}.未备案{vehicleType}数据
                    </h3>
                    <DataStatistics
                      title={'未备案'}
                      pieData={carNotRecordlPieData}
                      barData={carNotRecordlBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 8.跨区域数据 */}
                {crossPieData && crossBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 8 : 7}. 跨区域{vehicleType}数据
                    </h3>
                    <DataStatistics
                      title={'跨区域'}
                      pieData={crossPieData}
                      barData={crossBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
                {/* 9. 重点工作区域保障告警数据 */}
                {reportPieData && reportBarData && (
                  <div className="statement-module">
                    <h3>
                      {tabsKey === 1 ? 9 : 8}. 重点工作区域保障告警数据
                    </h3>
                    <DataStatistics
                      title={'重点工作区域保障'}
                      pieData={reportPieData}
                      barData={reportBarData}
                      vehicleType={vehicleType}
                      tabsKey={tabsKey}
                    />
                  </div>
                )}
              </div>
            ) :
            !loading && (
              <div className="no-data">
                <img src={noData} />
              </div>
            )}
          {/* 指标权重设置 */}
          <Modal
            className="weight-content"
            title={'指标权重设置'}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={'保存'}
            width={520}
          >
            {weight}
          </Modal>
          {modalType === 'export' && (
            <ExportDocument
              bikeType={bikeType}
              reportType={reportType}
              dailyTime={dailyTime}
              vehicleType={vehicleType}
              modalType={modalType}
              isExportOpen={() => setModalType('')}
            />
          )}
        </div>
      }
    </Spin>
  );
};
Statement.propTypes = {
  bikeType: PropTypes.number.isRequired,
  vehicleType: PropTypes.string,
};
export default Statement;
