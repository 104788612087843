import React, {useEffect, useState} from 'react';
import SubTitle from '../../../components/subtitle/SubTitle';
import {Col} from 'antd';
import LineGraph from '../components/LineGraph';
import ListDetail from '../components/ListDetail';
import {getTrend} from '../../../api/index';
import {GUARANTESTSTISTIC} from '../../../api/Urls';
import {GUARANTESTSTISTICLIST} from '../../../api/Urls';
import {useNavigate} from 'react-router-dom';
import '../style.css';

const BikePersonDataStatistics = () => {
  const ng = useNavigate();
  const [menuTab, setMenuTab] = useState(1);
  const roleName = JSON.parse(localStorage.getItem('userInfo'))?.roleName;
  const [filedData, setFiledData] = useState();
  const [incomeDate, setIncomeDate] = useState();
  const bikeEnterprise = JSON.parse(localStorage.getItem('bikeEnterprise')).bikeEnterprise;

  const formList = [
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [{label: '重点工作区域保障名称', value: 1}],
    },

    {
      label: '保障区域所属区域',
      key: 'areaList',
      col: 6,
      name: 'streetId',
      type: 'areaCascaderMul',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
  ];

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
    },
    {
      title: '名称',
      dataIndex: 'guaranteeName',
      key: 'guaranteeName',
      onExport: true,
    },
    {
      title: '所属区域',
      dataIndex: 'guaranteeAreaName',
      key: 'guaranteeAreaName',
      onExport: true,
      render: (guaranteeAreaName) => <span>{guaranteeAreaName || '无区域'}</span>,
      exportRender: (render) => render.guaranteeAreaName || '无区域',
    },
    {
      title: '告警总数',
      dataIndex: 'bikeEnterpriseTotal',
      key: 'bikeEnterpriseTotal',
      onExport: true,
      render: (bikeEnterpriseTotal, render) => {
        return (
          <span
            className="span-color"
            onClick={() => {
              // const searchContents = localStorage.getItem('searchContents');
              const statisticsDate = localStorage.getItem('statisticsDate');
              ng(`/messageCenter/keySecurityAreaMessage`, {
                state: {
                  type: null,
                  searchContent: render.guaranteeName,
                  guaranteeId: render.guaranteeId,
                  datePicker: statisticsDate,
                },
              });
            }}
          >
            {bikeEnterpriseTotal}
          </span>
        );
      },
    },
    {
      title: '美团告警数',
      dataIndex: 'bikeEnterpriseQuantity1',
      key: 'bikeEnterpriseQuantity1',
      onExport: true,
      render: (bikeEnterpriseQuantity1, render) => {
        return (
          <span
            className="span-color"
            onClick={() => {
              // const searchContents = localStorage.getItem('searchContents');
              const statisticsDate = localStorage.getItem('statisticsDate');
              ng(`/messageCenter/keySecurityAreaMessage`, {
                state: {
                  type: null,
                  searchContent: render.guaranteeName,
                  company: 1,
                  // streetId: render.guaranteeId,
                  guaranteeId: render.guaranteeId,
                  datePicker: statisticsDate,
                },
              });
            }}
          >
            {bikeEnterpriseQuantity1}
          </span>
        );
      },
    },
    {
      title: '青桔告警数',
      dataIndex: 'bikeEnterpriseQuantity2',
      key: 'bikeEnterpriseQuantity2',
      onExport: true,
      render: (bikeEnterpriseQuantity2, render) => {
        return (
          <span
            className="span-color"
            onClick={() => {
              // const searchContents = localStorage.getItem('searchContents');
              const statisticsDate = localStorage.getItem('statisticsDate');
              ng(`/messageCenter/keySecurityAreaMessage`, {
                state: {
                  type: null,
                  company: 2,
                  // streetId: render.guaranteeId,
                  guaranteeId: render.guaranteeId,
                  datePicker: statisticsDate,
                  searchContent: render.guaranteeName,
                },
              });
            }}
          >
            {bikeEnterpriseQuantity2}
          </span>
        );
      },
    },
    {
      title: '哈啰告警数',
      dataIndex: 'bikeEnterpriseQuantity3',
      key: 'bikeEnterpriseQuantity3',
      onExport: true,
      render: (bikeEnterpriseQuantity3, render) => {
        return (
          <span
            className="span-color"
            onClick={() => {
              // const searchContents = localStorage.getItem('searchContents');
              const statisticsDate = localStorage.getItem('statisticsDate');
              ng(`/messageCenter/keySecurityAreaMessage`, {
                state: {
                  type: null,
                  company: 3,
                  // streetId: render.guaranteeId,
                  guaranteeId: render.guaranteeId,
                  datePicker: statisticsDate,
                  searchContent: render.guaranteeName,
                },
              });
            }}
          >
            {bikeEnterpriseQuantity3}
          </span>
        );
      },
    },
  ];
  // 调api接口数据数据处理
  const disposeApiData = (tab, date) => {
    const obj = {
      apiType: tab,
    };
    if (date) {
      obj.startDate = date.startTime;
      obj.endDate = date.endTime;
    }
    return obj;
  };
  const bikeType = (type) => {
    switch (type) {
      case 0:
        return '全部';
      case 1:
        return '美团';
      case 2:
        return '青桔';
      case 3:
        return '哈啰';
      default:
        return '_';
    }
  };
  // data管辖区域处理
  const statisticData = (value) => {
    let temp = [];
    if (bikeEnterprise.indexOf(1) !== -1 && bikeEnterprise.indexOf(2) !== -1 && bikeEnterprise.indexOf(3) !== -1) {
      const res = value.filter((i) => i.bikeEnterprise < 4);
      temp = [...res];
    } else {
      for (const index of bikeEnterprise) {
        const res = value.find((i) => i.bikeEnterprise === index && i.bikeEnterprise < 4);
        if (res) temp.push(res);
      }
    }
    return temp;
  };
  // 折线图数据处理
  const disposeDate = (data) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const type = bikeType(data[i].bikeEnterprise);
      data[i]?.trend.map((item, index) => {
        arr.push(Object.assign(item, {type: type}));
      });
    }
    return arr;
  };
  // 出勤数趋势
  const attendanceTrend = (tab, data) => {
    setFiledData([]);
    const date = disposeApiData(tab, data);
    getTrend(`${GUARANTESTSTISTIC}`, date, 2).then((res) => {
      const resp = statisticData(res.data);
      const result = disposeDate(resp);
      setFiledData(result);
      const income = result[result.length - 1]?.date;
      setIncomeDate(income);
    });
  };

  useEffect(() => {
    attendanceTrend(menuTab);
    return () => {
      localStorage.removeItem('searchContents');
      localStorage.removeItem('statisticsDate');
    };
  }, []);

  return (
    <div>
      {/* 标题切换 */}
      <SubTitle
        title={`单车重点工作区域保障数据统计`}
        tab={[`告警数`]}
        onChangeTab={(key) => {
          const tab = Number(key) + 1;
          setMenuTab(tab);
          attendanceTrend(tab);
        }}
      />
      <div className="entirety-person-statistics">
        {/* 告警数趋势 */}
        <Col>
          <LineGraph
            filedData={filedData}
            promptTitle={menuTab === 1 ? '告警数趋势' : ''}
            timeReset={() => attendanceTrend(menuTab)} // 时间重置
            timeSearch={(value) => {
              attendanceTrend(menuTab, value);
            }} // 时间搜索
            menuTab={menuTab}
            incomeDate={incomeDate}
          />
        </Col>

        {/* 最近一日告警数统计 */}
        {(roleName === '超级管理员' || roleName === '超级管理') && (
          <Col>
            <ListDetail
              initApi={`${GUARANTESTSTISTICLIST}`}
              formList={formList}
              columns={columns}
              listTitle={'最近一日告警数统计'}
              menuTab={menuTab}
              statistics={'列表告警数'}
              exportTime={true}
            />
          </Col>
        )}
      </div>
    </div>
  );
};
export default BikePersonDataStatistics;
