import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import {useTranslation} from 'react-i18next';
import {InfoCircleFilled} from '@ant-design/icons';
import {getAreaTreeFilter2} from '../../../../api';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};


const setAreaId = ({columnsForm, onFinish, loading, initData, onCancel,
  buttonStyle, pageLayout, buttonText, isSelected, selected}) => {
  const {t} = useTranslation();
  const [settingForm] = Form.useForm();
  const [areaOptions, setAreaOptions] = useState([]);
  const [district, setDistrict]=useState([]);
  const [districtIsAble, setDistrictIsAble]=useState(true);
  const getUserAreaGroup = (params) => {
    getAreaTreeFilter2(params, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type=!params?1:
      params.roleId===2?2:
      params.roleId===3?3:
      params.roleId===4?4: 0;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={(type===3&&sub.managerName)?'text-light-disabled':'text-light'}
                > &nbsp;&nbsp;{sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName&&sub.userName!==initData.userName,
            };
          }) : [],
        };
      });
      setAreaOptions(data);
      settingForm.setFieldsValue({cityId: initData.cityId});
      if (settingForm.getFieldValue('cityId')) {
        setDistrict(data.find((item)=> item.value===settingForm.getFieldValue('cityId')).children);
        setDistrictIsAble(false);
      }
      settingForm.setFieldsValue({
        roleId: initData.roleId,
        userId: initData.userId,
        areaId: initData.areaId,
      });
    });
  };
  const changeArea=(value)=>{
    if (value) {
      setDistrictIsAble(false);
      setDistrict(areaOptions.find((item)=>item.value===value).children);
      settingForm.resetFields(['areaId']);
    } else {
      setDistrictIsAble(true);
      settingForm.resetFields(['areaId']);
    }
  };
  const onSubmit = (values) => {
    onFinish(values);
  };
  const formItem = (item) => {
    switch (item.type) {
      case 'input':
        return (
          <Input
            maxLength={item.maxLength||20}
            placeholder={item.placeholder||''}
            disabled={item.disabled}
          />
        );
      case 'selectArea1':
        return (
          <Select
            allowClear
            disabled={item.disabled}
            options={areaOptions}
            onChange={changeArea}
            placeholder="请选择市(单选)"
          >
          </Select>);
      case 'selectArea2':
        return (
          <Select
            allowClear
            mode={'multiple'}
            disabled={districtIsAble}
            options={district}
            placeholder="请选择区(多选)"
            maxTagCount="responsive"
          >
          </Select>);


      default:
        return null;
    }
  };
  useEffect(()=>{
    const A3= columnsForm.find(({type})=>type==='selectArea1');
    if (A3) {
      getUserAreaGroup(A3.params);
    }
  }, [columnsForm]);
  return (
    <div>
      <Spin spinning={loading} >
        <Form
          layout={pageLayout||layout}
          name="cameraForm"
          form={settingForm}
          onFinish={onSubmit}
        >
          <Row>
            {columnsForm.map((item) => (
              <Col
                span={item.col||24}
                key={item.name + item.type}
                className={item.col?'padding-r-m':''}
              >
                {item.type==='box'||item.type==='title'?(
                  <div>
                    {item.type==='title'?(
                      <h3 className="form-title">{item.label}</h3>
                    ):(
                      <>
                        {item.children.map((subItem)=>(
                          <Form.Item
                            key={`subItem${subItem.name}`}
                            name={subItem.name}
                            label={subItem.label}
                            hidden={subItem.hidden}
                            disabled={subItem.disabled}
                            rules={subItem.rules? subItem.rules:[{
                              required: subItem.required,
                              message: subItem['message2'],
                            }]}>
                            {formItem(subItem)}
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </div>
                ):(
                  <Form.Item
                    noStyle={item.areaTip===1}
                    name={item.name}
                    label={item.label}
                    hidden={item.hidden}
                    disabled={item.disabled}
                    tooltip={item.tooltip||null}
                    rules={item.rules? item.rules:[{
                      required: item.required,
                      message: item['message' + t('flag')],
                    }]}>
                    {formItem(item)}
                  </Form.Item>
                )}
                <div style={item.areaTip===2?{
                  marginTop: ' -16px',
                }:null}>
                  {item.areaTip===1?(<div className="margin-b-m margin-t-s text-light">
                    <InfoCircleFilled style={{color: '#FAAD14'}} className="margin-r-s"/>
                  管辖区域非必填，未选择区域则将自动显示为“无区域”
                  </div>):
                item.areaTip===2?(<div className="margin-b-m margin-t-s text-light">
                  修改管理员用户角色后，其原管辖区域将变为“无区域”
                </div>):null
                  }
                </div>
              </Col>
            ))}
          </Row>
          <Form.Item noStyle>
            <div className={buttonStyle||'text-center'}>
              <div>
                <Space size="large">
                  <Button onClick={() => {
                    settingForm.resetFields();
                    onCancel();
                  }} type="primary" ghost>{t('cancel')}</Button>
                  <Button type="primary" htmlType="submit">
                    {buttonText ||t('submit')}
                  </Button>
                </Space>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

setAreaId.propTypes = {
  columnsForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initData: PropTypes.object,
  loading: PropTypes.bool,
  buttonStyle: PropTypes.string,
  pageLayout: PropTypes.string,
  buttonText: PropTypes.string,
  isSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export default setAreaId;

