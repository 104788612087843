import React from 'react';
import {Modal, Row, Col} from 'antd';
// import SimpleTable from '../../../components/customTable/SimpleTable';
import CustomTable from '../../../components/customTable';
import PropTypes from 'prop-types';
import {PERSONSONTABLE} from '../../../api/Urls';
import {bikeCompany, staffStatus, positionLevel, isInWork, staffStatusText} from '../../../components/statusCollection';
import './style.css';

const PersonNumberKey = ({numberModalKey, setNumberModalKey, statistics}) => {
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      onExport: true,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 210,
      onExport: true,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      width: 100,
      onExport: true,
      // eslint-disable-next-line
      render: (record, item) => bikeCompany(record),
      exportRender: (render) =>bikeCompany(render.bikeEnterprise),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 140,
      onExport: true,
    },
    statistics.requireType===2&&{
      title: '是否到岗',
      dataIndex: 'status',
      width: 100,
      key: 'status',
      hideInTable: true,
      render: (record, item) => (
        <>
          <span>{record===0?'未到岗':'到岗'}</span>
        </>
      ),
      onExport: true,
      exportRender: (render) =>isInWork(render.status),
    },
    statistics.requireType===2&&{
      title: '职务等级',
      dataIndex: 'positionLevel',
      key: 'positionLevel',
      width: 110,
      render: (record, item) => positionLevel(record),
      exportRender: (render) =>positionLevel(render.positionLevel),
      onExport: true,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      width: 170,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{staffStatus(record)}</span>
      ),
      exportRender: (render) =>staffStatusText(render.jobType),
      onExport: true,
    },
  ].filter(Boolean);

  return (
    <div>
      <Modal
        title="到岗统计-人员子表"
        visible={numberModalKey}
        maskClosable={true}
        width={950}
        footer={null}
        onCancel={() => {
          setNumberModalKey(false);
        }}
        destroyOnClose
      >
        <>
          <div>
            <div>时间：{statistics.statisticTime}</div>
            <div className="details-info margin-t-l">
              <Row gutter={[16, 0]} className="margin-b-l">
                {statistics.statistic &&
                  statistics.statistic.map((item, index) => {
                    return (
                      <Col key={item.bikeEnterprise + index}>
                        <div className="number-children-item" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                          <div className="number-item-title">{bikeCompany(item.bikeEnterprise)}</div>
                          <div style={{marginTop: '5px'}}>
                            应到人数：{item.requireCount}
                            <span className="text-gray"></span>
                          </div>
                          <div>
                            实到人数：{item.actualCount}
                            <span className="text-gray"></span>
                          </div>
                          <div>
                            出勤率：
                            {/* {(item.actualCount / item.requireCount).toFixed(2) * 100 || 0}% */}
                            {item.actualCount===0&&item.requireCount===0?'100%':
                            item.actualCount!==0&&item.requireCount===0?'-':
                            (Math.round(item.actualCount/item.requireCount * 10000)/100.00)
                                .toFixed(0)+'%'}
                            {/* <span className="text-gray"></span> */}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
          {/* <SimpleTable
            InitApi={`${PERSONSONTABLE}`}
            toIndex={true}
            columns={columns}
            resType={'POST'}
            params={{markRangeId: statistics.markRangeId,
              requireType: statistics.requireType, statisticTime: statistics.statisticTime}}
          /> */}
          <CustomTable
            InitApi={PERSONSONTABLE}
            toIndex={true}
            columns={columns}
            tip="到岗统计导出中..."
            exportFileName={'到岗统计-人员子表'}
            otherParam={{markRangeId: statistics.markRangeId,
              requireType: statistics.requireType, statisticTime: statistics.statisticTime}}
          />
        </>
      </Modal>
    </div>
  );
};

PersonNumberKey.propTypes = {
  numberModalKey: PropTypes.any,
  setNumberModalKey: PropTypes.func,
  statistics: PropTypes.object,
};

export default PersonNumberKey;
