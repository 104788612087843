import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Ticker from 'react-ticker';
import './NoticeBoard.css';
import {getNoticeMsgCount} from '../../../../api';
// import {storage} from '../../../../utils/storage';
// import WebSocketClient from '../../../../api/webSocket';
// import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {sendNoticeInfo} from '../../../../store/actions';


let messageList = [];

const NoticeBoard = ({onMsgChange, isShowMessage, noticeInfo, setNoticeInfo}) => {
  const ng = useNavigate();

  const [noticeList, setNoticeList] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [tickerKey, setTickerKey] = useState(0);
  // const token = storage.getData('userInfo')?.token?.split('Bearer ')[1] || '';
  const initMsg=()=>{
    getNoticeMsgCount().then((res)=>{
      setNoticeList(res.data.items||[]);
      messageList=res.data.items||[];
      onMsgChange(res.data.count||0);
      if (res.data.count>0) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    });
  };
  // const url = 'ws://47.108.232.235:9000/websocket';
  // // const url = 'wss://ips.smartecycle.cn:9110';
  // const ws = new WebSocketClient(`${url}?token=${token}`);
  // // const maxReconnectCount = 3;
  // // const reconnectCount = 0;
  // let intervalId;

  // useEffect(() => {
  //   // 初始化消息列表
  //   initMsg();
  //   // 链接websocket
  //   ws.onOpen(() => {
  //     console.log('open');
  //     // do something when the WebSocket connection is opened
  //     intervalId = setInterval(() => {
  //       ws.onHeartbeat();
  //     }, 3*60*1000);
  //   });

  //   ws.onMessage((res) => {
  //     console.log(res.data);
  //     console.log(JSON.parse(res.data));
  //     console.log(JSON.parse( JSON.parse(res.data)));
  //     let newList=messageList;
  //     if (res.data!=='PONG') {
  //       const data =JSON.parse( JSON.parse(res.data));
  //       // console.log('data', data, data.counts, data.msg, data.noRead);
  //       onMsgChange(data.counts||0);
  //       // data中返回的onRead是已读消息的id数组, 通过这些id去删除已读消息
  //       const {noRead} = data;
  //       // console.log('noRead', noRead);
  //       if (noRead && noRead.length > 0) {
  //         // console.log('onRead', noRead);
  //         newList = messageList.filter((item) =>!noRead.includes(item.id));
  //       }
  //       // data中返回的msg是新消息,插入到消息列表的最前面

  //       if (data.msg) {
  //         newList=[
  //           {
  //             ...data.msg,
  //           },
  //           ...newList,
  //         ];
  //       }
  //       console.log('newList', newList);
  //       if (newList.length>0) {
  //         setIsShow(true);
  //       } else {
  //         setIsShow(false);
  //       }
  //       messageList=newList;
  //       setNoticeList([]);
  //       setNoticeList(newList);
  //     }
  //     // sendSocketMessage(msg)
  //   });

  //   ws.connect(); // connect to the WebSocket server

  //   return () => {
  //     clearInterval(intervalId);
  //     ws.disconnect(); // disconnect from the WebSocket server
  //   };
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      initMsg();
    }, 1000);
    return () => {
      setNoticeInfo({});
    };
  }, []);
  useEffect(() => {
    // console.log(noticeInfo);
    if (Reflect.ownKeys(noticeInfo).length !==0) {
      let newList = messageList;
      const data = {...noticeInfo.message};
      // console.log('data', data, data.counts, data.msg, data.noRead);
      onMsgChange(data.counts || 0);
      // data中返回的onRead是已读消息的id数组, 通过这些id去删除已读消息
      const {noRead} = data;
      // console.log('noRead', noRead);
      if (noRead && noRead.length > 0) {
        // console.log('onRead', noRead);
        newList = messageList.filter((item) => !noRead.includes(item.id));
      }
      // data中返回的msg是新消息,插入到消息列表的最前面
      if (data.msg) {
        //如果有新的消息进来需要重头开始轮播，那么就要刷新一下ticker组件
        newList = [
          {
            ...data.msg,
          },
          ...newList,
        ];
        setTickerKey(tickerKey + 1);
      }
      // console.log('newList', newList);
      if (newList.length > 0) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
      messageList = newList;
      setNoticeList([]);
      setNoticeList(newList);
    }
  }, [noticeInfo]);

  return (
    // <Link to={`/messageCenter/keySecurityAreaMessage`} state={0}>
    <>
      {isShowMessage&&isShow&&( <div className="map-msg-bar" onClick={()=>{
        ng(`${location.pathname.indexOf('/bigScreen') !== -1? '/bigScreen':''}/messageCenter/keySecurityAreaMessage`, {
          state: {type: 2},
        });
      }}>
        {noticeList.length>0&& <Ticker offset='run-in' key={tickerKey} speed={12} direction="toLeft" >
          {({index}) => {
            //ticker有更优雅的用法，但是为了不大改之前的逻辑而出现bug，就在这上面微调吧
            let notice = noticeList[index%noticeList.length];
            return <>
            <span key={notice.id} className="notice-item">
                <span className="map-msg-icon"/>
                {notice.content}
              </span>
          </>
          }
          }
        </Ticker>}
      </div>)}
    </>
    // </Link>
  );
};

NoticeBoard.propTypes = {
  onMsgChange: PropTypes.func.isRequired,
  isShowMessage: PropTypes.bool.isRequired,
  noticeInfo: PropTypes.object.isRequired,
  setNoticeInfo: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    noticeInfo: state.websocketInfo.noticeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setNoticeInfo: (obj)=>dispatch(sendNoticeInfo(obj)),
  };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( NoticeBoard);
