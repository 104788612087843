import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../../components/detailsHeader';
import {Card, Col, Divider, Row, Skeleton, Image, Space} from 'antd';
import {useParams} from 'react-router-dom';
import StaffInfo from '../../../../components/baseInfo/StaffInfo';
import {dispatchStatus} from '../../../../components/statusCollection';
import CustomImage from '../../../../components/customImage';
import {getMaintainDetails} from '../../../../api';
const MaintainDetails = () => {
  const d = useParams();
  const [loading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState({});

  const getInfo = (value) => {
    setLoading(true);
    getMaintainDetails(d.id, 2).then((res)=>{
      setInfoData(res.data);
    }).catch(()=>{
      setInfoData({status: 1});
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="定点运维记录详情"/>

      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <StaffInfo userID={d.operationId}/>
        <Divider/>
        <div>
          <h3 className="details-title">
            定点运维详情
          </h3>
          {loading?(<Skeleton active />):( <div className="details-info margin-t-l">
            <div className="margin-b-l">
              <span >
                  运维任务状态：
              </span>
              <span className="text-gray">{dispatchStatus(infoData.taskStatus)}</span>
            </div>
            <Image.PreviewGroup>

              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                  运维前图片：
                  </span>
                  <div className="padding-t-m">
                    <Space size={'large'}>{
                      infoData.firstGuaranteeImage&&infoData.firstGuaranteeImage.split(';').length>0?
                      infoData.firstGuaranteeImage.split(';').map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                    }
                    </Space>
                  </div>
                </Col>

              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                 运维前创建地点：
                  </span>
                  <span className="text-gray">{infoData.startTaskAddress||'暂无数据'}</span>
                </Col>

              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                 运维任务创建时间：
                  </span>
                  <span className="text-gray">{infoData.createTime||'暂无数据'}</span>
                </Col>

              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                运维前备注：
                  </span>
                  <span className="text-gray">{infoData.firstGuaranteeRemark||'暂无数据'}</span>
                </Col>

              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span className="padding-r-m ">
                  运维后图片：
                  </span>
                  <div className="padding-t-m">
                    <Space size={'large'}>
                      {
                        infoData.lastGuaranteeImage&&infoData.lastGuaranteeImage.split(';').length>0?
                      infoData.lastGuaranteeImage.split(';').map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                      }
                    </Space>
                  </div>
                </Col>
              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                  运维后完成地点：
                  </span>
                  <span className="text-gray">{infoData.endTaskAddress||'暂无数据'}</span>
                </Col></Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                 运维任务完成时间：
                  </span>
                  <span className="text-gray">{infoData.completeTime||'暂无数据'}</span>
                </Col>
              </Row>
              <Row className="margin-b-l" gutter={24}>
                <Col span={12}>
                  <span >
                  运维后备注：
                  </span>
                  <span className="text-gray">{infoData.lastGuaranteeRemark||'暂无数据'}</span>
                </Col>
              </Row>
            </Image.PreviewGroup>
          </div>)}
        </div>
      </Card>
    </div>
  );
};

MaintainDetails.propTypes = {};

export default MaintainDetails;
