import React, {useState} from 'react';

import CustomTable from '../../../components/customTable';
// import PropTypes from 'prop-types';
import {GETLOGINLIST} from '../../../api/Urls';

const LoginLog = () => {
  const [updateList] = useState(0);
  const formList= [
    {
      type: 'datePicker',
      key: 'datePicker',
      name: 'datePicker',
      col: 8,
      label: '登录日期',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      typeOptions: [
        {label: '管理员姓名', value: 1},
        {label: '用户名', value: 2},
      ],
      valueName: 'name',
    },
  ];

  const columns = [
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
      onExport: true,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
    },
    {
      title: 'IP地址',
      dataIndex: 'loginIp',
      key: 'loginIp',
      onExport: true,
    },
    {
      title: '登录时间',
      dataIndex: 'loginTime',
      key: 'loginTime',
      onExport: true,
    },
    {
      title: '登出时间',
      dataIndex: 'loginOutTime',
      key: 'loginOutTime',
      onExport: true,
    },
  ];
  return (
    <div>
      <CustomTable
        InitApi={GETLOGINLIST}
        formList={formList}
        updateList={updateList}
        columns={columns}
        resType="POST"
        exportFileName="登录日志"
      />
    </div>
  );
};

LoginLog.propTypes = {};

export default LoginLog;
