import React, {useRef} from 'react';
import {Column} from '@ant-design/plots';
import PropTypes from 'prop-types';
import {Button} from 'antd';

const DemoColumn = ({data}) => {
  const config = {
    data,
    xField: 'date',
    yField: 'count',
    color: '#08979C',
    innerHeight: '100%',
    meta: {
      value: {
        alias: '出勤',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    tooltip: {
      customContent: (data, all) => {
        return `
        <h5>${data}</h5>
            <h6>
            新增单车数:${all[0]?.data.count}
            </h6>`;
      },
    },
    legend: {
      position: 'bottom',
    },
    minBarWidth: 5,
    maxBarWidth: 20,
  };
  const ref = useRef();

  // 导出图片
  const downloadImage = (name) => {
    ref.current?.downloadImage(name);
  };
  return (
    <>
      <Button type="primary" onClick={() => downloadImage('日新增单车数趋势柱状图')} className="btn-export">
        下载
      </Button>
      <Column
        {...config}
        onReady={(plot) => {
          ref.current = plot;
        }}
      />
    </>
  );
};

DemoColumn.propTypes = {
  data: PropTypes.array,
};
export default DemoColumn;
