import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Button, Space, Popconfirm, Drawer, Row, Col} from 'antd';
import CustomTable from '../../components/customTable';
import './style.css';
import PropTypes from 'prop-types';
import {guaranteeAreaOptions, guaranteeAreaStatus} from '../../components/statusCollection';
import PolyShow from '../../components/aMap/PolyShow';
import {GETGUARANTEEAREALIST} from '../../api/Urls';
import {deleteGuarantArea, updateGuarantAreaStatus, getGuarantAreaDetails, getMapZoomCenter} from '../../api';
import {timeFormat} from '../../components/baseTime';

const GuaranteeArea = ({queryType}) => {
  const [updateList, setUpdateList] = useState(0);
  const [drawerVisible, setDrawerVisible]=useState(false);
  const [areaInfo, setAreaInfo]=useState({});
  const [markRange, setMarkRange]=useState([]);
  // const [center, setCenter] = useState([104.065861, 30.657401]);
  // const [zoom, setZoom] = useState(13);
  // const [areaId, setAreaId]=useState(null);
  const [areaStatus, setAreaStatus]=useState(null);
  const [resetOptions, setResetOptions] = useState(0);
  const isMounted = useRef(false);

  const formList = [
    {
      label: '保障区域状态',
      key: 'securityAreaStatus',
      col: 8,
      name: 'securityAreaStatus',
      type: 'select',
      options: guaranteeAreaOptions(),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      typeOptions: [
        {label: '创建人', value: 1},
        {label: '保障区域名称', value: 2},
      ],
      valueName: 'name',
    },
  ];

  const columns = [
    {
      title: '名称',
      dataIndex: 'guaranteeName',
      key: 'guaranteeName',
    },
    {
      title: '状态',
      dataIndex: 'guaranteeStatus',
      key: 'guaranteeStatus',
      render: (guaranteeStatus)=>guaranteeAreaStatus(guaranteeStatus),
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime)=>timeFormat(startTime),
    },
    {
      title: '创建人',
      dataIndex: 'createName',
      key: 'createName',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      render: (createTime)=>timeFormat(createTime),
    },
    {
      title: '操作',
      key: 'action',
      // eslint-disable-next-line
      render: (record, item) => (
        <Space size="small">
          <Button type="link" ><Link to={`details/${item.id}`}>详情</Link></Button>

          {<Button type="link" onClick={() => {
            setDrawerVisible(true);
            // setAreaId(item.id);
            getGuarantAreaDetails(item.id, 2).then((res)=>{
              if (res.data) {
                setAreaInfo(res.data);
                setMarkRange(JSON.parse(res.data.markRange));
              }
            });
            setAreaStatus(item.guaranteeStatus);
          }}>地图</Button>}

          {(item.editStatus&&item.guaranteeStatus===0) &&<Popconfirm placement="topRight"
            title={
              <div style={{width: 316}} >
                <div className="padding-b-s">是否确认开始该区域？</div>
                <div className="padding-b-s">{item.guaranteeName}保障区域</div>
              </div>
            }
            onConfirm={()=>{
              updateGuarantAreaStatus({id: item.id, securityAreaStatus: 1}).then((res)=>{
                setUpdateList(updateList+1);
              });
            }}
            okText="确认"
            cancelText="取消"
          ><Button type="link">开始</Button>
          </Popconfirm>}

          {(item.editStatus&&item.guaranteeStatus===1) &&<Popconfirm placement="topRight"
            title={
              <div style={{width: 316}} >
                <div className="padding-b-s">是否确认结束该区域？</div>
                <div className="padding-b-s">{item.guaranteeName}保障区域</div>
              </div>
            }
            onConfirm={()=>{
              updateGuarantAreaStatus({id: item.id, securityAreaStatus: 2}).then((res)=>{
                setUpdateList(updateList+1);
              });
            }}
            okText="确认"
            cancelText="取消"
          ><Button type="link">结束</Button>
          </Popconfirm>}

          {(item.editStatus&&item.guaranteeStatus===0) &&<Popconfirm placement="topRight"
            title={
              <div style={{width: 316}} >
                <div className="padding-b-s">是否确认删除该区域？</div>
                <div className="padding-b-s">{item.guaranteeName}保障区域</div>
              </div>
            }
            // onConfirm={cancel}
            onCancel={()=>{
              deleteGuarantArea(item.id, 2).then((res)=>{
                setUpdateList(updateList+1);
              });
            }}
            okText="取消"
            cancelText="删除"
            cancelButtonProps={{type: 'danger'}}
            okButtonProps={{type: 'default'}}
          >
            <Button type="link">删除</Button>
          </Popconfirm>}
        </Space>
      ),
    },
  ];
  const mapInfo=(
    <div className="details-info margin-t12">
      <Row gutter={24} className="margin-b-l">
        <Col span={8}>
          保障区域名称：<span className="text-gray">{areaInfo.guaranteeName}</span>
        </Col>
        <Col span={8}>
          状态：<span className="text-gray">{guaranteeAreaStatus(areaInfo.guaranteeStatus)}</span>
        </Col>
        <Col span={8}>
          创建人：<span className="text-gray">{areaInfo.createName}</span>
        </Col>
      </Row>
      <Row gutter={24} className="margin-b-l">
        <Col span={8}>
          开始时间：<span className="text-gray">{timeFormat(areaInfo.startTime)||'暂无数据'}</span>
        </Col>
        <Col span={8}>
          结束时间：<span className="text-gray">{timeFormat(areaInfo.endTime) || '暂无数据'}</span>
        </Col>
        <Col span={8}>
          创建时间：<span className="text-gray">{timeFormat(areaInfo.createTime)|| '暂无数据'}</span>
        </Col>
      </Row>
      <PolyShow polyPath={markRange} userCenter={markRange[0]} userZoom={9} status={areaStatus}/>
    </div>
  );
  useEffect(()=>{
    getMapZoomCenter(2).then((res) => {
      // setCenter(res.data.latitudeLongitude?res.data.latitudeLongitude.split(','):[104.065861, 30.657401]);
      // setZoom(zoomLevel(res.data.level));
    });
  }, []);
  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, [queryType]);
  return (
    <div style={{position:'relative'}}>
      <CustomTable
        InitApi={`${GETGUARANTEEAREALIST}${queryType}`}
        formList={formList}
        toIndex={true}
        columns={columns}
        updateList={updateList}
        resetData={resetOptions}
        buttonGroup={() => (
          <Button type="primary">
            <Link to={`add/edit`} state={{operationType: 'add'}}>新增</Link>
          </Button>
        )}
      />

      <Drawer
        title="保障区域地图"
        placement="right"
        destroyOnClose
        width={768}
        onClose={() =>setDrawerVisible(false)}
        visible={drawerVisible}
        size="large"
        maskClosable={false}
        style={{position: 'absolute'}}
      >
        {mapInfo}
      </Drawer>
    </div>
  );
};

GuaranteeArea.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default GuaranteeArea;
