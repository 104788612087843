/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
  Form,
  Table,
  InputNumber,
  // Popconfirm,
  Typography,
  message,
} from 'antd';
// import editIcon from '../../../asset/edit.png';
// import forbidIcon from '../../../asset/forbid-edit.png';
import {InfoCircleOutlined} from '@ant-design/icons';
import {getRequiredQuantity, updateRequiredQuantity} from '../../../api';
// import {bikeCompany} from '../../../components/statusCollection';
import PropTypes from 'prop-types';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入`,
            },
            {
              min: 0,
              max: 9999,
              type: 'number',
              message: `请输入0-9999之间的整数`,
            },
          ]}
        >
          {<InputNumber size="small" style={{width: '80px'}}/>}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const PersonneSetting = ({open, changeOpen}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [reload, setReload] = useState(0);
  const [loading, setLoading]=useState(false);
  const isEditing = (record) => record.key === editingKey;
  const editCount = (record) => {
    form.setFieldsValue({...record});
    setEditingKey(record.key);
  };
  const cancel = () => setEditingKey('');
  const save = async (record) => {
    try {
      console.log(record);
      const row = await form.validateFields();
      const rowData = {...record, ...row};
      const list = [];
      Object.keys(row).forEach((item) => {
        const obj = {
          bikeAreaId: rowData.bikeAreaId,
          areaId: rowData.areaId,
          bikeType: rowData.bikeType,
          bikeEnterprise: parseInt(item.slice(-1)),
          requiredCount: row[item],
        };
        const typeStr=item.slice(0, item.length - 1);
        if (typeStr === 'personNum') list.push({jobType: 1, ...obj});
        else if (typeStr === 'bikeNum') list.push({jobType: 2, ...obj});
      });
      console.log(list);
      updateRequiredQuantity({list}).then((res) => {
        setReload(reload + 1);
        message.success('操作成功');
      }).finally(() => {
        setEditingKey('');
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '区域',
      dataIndex: 'areaName',
    },
    {
      title: '美团',
      dataIndex: 'qj',
      children: [
        {
          title: '人员',
          dataIndex: 'personNum1',
        },
        {
          title: '车辆',
          dataIndex: 'bikeNum1',
        },
      ],
    },
    {
      title: '青桔',
      dataIndex: 'qj',
      children: [
        {
          title: '人员',
          dataIndex: 'personNum2',
        },
        {
          title: '车辆',
          dataIndex: 'bikeNum2',
        },
      ],
    },
    {
      title: '哈啰',
      dataIndex: 'hl',
      children: [
        {
          title: '人员',
          dataIndex: 'personNum3',
        },
        {
          title: '车辆',
          dataIndex: 'bikeNum3',
        },
      ],
    },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{marginRight: 8}}
            >
              保存
            </Typography.Link>
            {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}> */}
            <a onClick={cancel}>取消</a>
            {/* </Popconfirm> */}
          </span>
        ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => {
              editCount(record);
              changeOpen();
            }}>
            编辑
            </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.children) {
      col.align='center';
      return col;
    } else {
      col.children.forEach((_, i)=> {
        col.children[i] = {
          ...col.children[i],
          align: 'center',
          onCell: (record) => ({
            record,
            inputType: 'number',
            dataIndex: col.children[i].dataIndex,
            title: col.children[i].title,
            editing: isEditing(record),
          })};
      });
      return col;
    }
  });
  useEffect(() => {
    setLoading(true);
    getRequiredQuantity(2).then((res) => {
      const arr=[...res.data];
      const setCount = (item, n) => {
        switch (parseInt(item.jobType)) {
          case 1:
            item[`personNum${n}`] = item.requireCount;
            break;
          case 2:
            item[`bikeNum${n}`] = item.requireCount;
            break;
          default:
            return;
        }
      };
      // 设置每行count
      const countArr=arr.map((item) => {
        switch (parseInt(item.bikeEnterprise)) {
          case 1:
            setCount(item, 1);
            break;
          case 2:
            setCount(item, 2);
            break;
          case 3:
            setCount(item, 3);
            break;
          default:
            return;
        }
        return item;
      });
      // 规整相同区域数据
      let dataArr = [];
      const nameArr=[];
      countArr.forEach((item) => {
        if (nameArr.indexOf(item.areaName) === -1) {
          dataArr.push(item);
          nameArr.push(item.areaName);
        } else {
          dataArr.forEach((sub, index) => {
            if (sub.areaName === item.areaName) {
              dataArr[index] = {...dataArr[index], ...item};
            }
          });
        }
      });
      dataArr = dataArr.map((item, index) => {
        item.key='area'+index;
        item.index = index + 1;
        Object.keys(item).forEach((i) => {
          if (i.includes('personNum') || i.includes('bikeNum')) {
            item[i]=item[i] || 0;
          }
        });
        return item;
      });
      setData(dataArr);
    }).finally(()=>setLoading(false));
  }, [reload]);
  useEffect(() => {
    if (open!=='personneNew') {
      setEditingKey('');
    }
  }, [open]);
  return (
    <div>
      <h2>人员相关设置</h2>
      <h3>
        规定车企应配人员数
      </h3>
      <div className="hint-system">
        <InfoCircleOutlined className="off-icon" />
        <p className="details-h6">
          设置车企单车人员和单车调运车辆数，该项设置将影响前往大屏、数据统计、汇总报表，共计3个模块的“规定应配、在线率、出勤率”相关数据。汇总报表、数据统计设置后次日生效；前往大屏设置后约6分钟生效（受到缓存影响）。
        </p>
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
};
PersonneSetting.propTypes = {
  open: PropTypes.string,
  changeOpen: PropTypes.func,
};
export default PersonneSetting;
