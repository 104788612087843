import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Button, Space, Popconfirm} from 'antd';
import CustomTable from '../../../components/customTable';
import '.././style.css';
// import PropTypes from 'prop-types';
import {guaranteeAreaOptions, guaranteeAreaStatus} from '../../../components/statusCollection';
import {GETKEYSECURITYAREA} from '../../../api/Urls';
import {getMapZoomCenter, secureFinishButton} from '../../../api';
import {timeFormat} from '../../../components/baseTime';

const KeySecurityArea = () => {
  const [updateList, setUpdateList] = useState(0);
  // const [center, setCenter] = useState([104.065861, 30.657401]);
  // const [zoom, setZoom] = useState(13);
  // const [areaId, setAreaId]=useState(null);
  const [resetOptions, setResetOptions] = useState(0);
  const isMounted = useRef(false);

  const formList = [
    {
      col: 8,
      key: 'guaranteeName',
      name: 'guaranteeName',
      type: 'input',
      label: '搜索重点工作区域保障名称',
    },
    {
      col: 8,
      key: 'createName',
      name: 'createName',
      type: 'input',
      label: '创建人用户名',
    },
    {
      label: '保障区域状态',
      key: 'guaranteeStatus',
      col: 8,
      name: 'guaranteeStatus',
      type: 'select',
      options: guaranteeAreaOptions(),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '保障区域所属区域',
      key: 'areaList',
      col: 8,
      name: 'areaList',
      type: 'areaCascaderMul',
      placeholder: '请选择(可多选)',
    },
  ];

  const columns = [
    {
      title: '名称',
      dataIndex: 'guaranteeName',
      key: 'guaranteeName',
    },
    {
      title: '状态',
      dataIndex: 'guaranteeStatus',
      key: 'guaranteeStatus',
      render: (guaranteeStatus)=>guaranteeAreaStatus(guaranteeStatus),
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
    },
    {
      title: '应配人数',
      dataIndex: 'requireNum',
    },
    {
      title: '保障时段数',
      dataIndex: 'guaranteeTimeNum',
    },
    {
      title: '创建人',
      dataIndex: 'createName',
      key: 'createName',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      sorterType: 1,
      render: (createTime)=>timeFormat(createTime),
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: 'endTime',
      sorter: true,
      sorterType: 2,
      render: (endTime)=>timeFormat(endTime),
    },
    {
      title: '操作',
      key: 'action',
      // eslint-disable-next-line
      render: (record, item) => (
        <Space size="small">
          {<Button type="link" ><Link to={`details/${item.id}` }
            state={{editStatus: item.editStatus}}>详情</Link></Button>}
          {item.guaranteeStatus!==2&&<Popconfirm placement="topRight"
            title={
              <div style={{width: 316}} >
                <div className="padding-b-s">是否确认结束该区域？</div>
                <div className="padding-b-s">{item.guaranteeName}保障区域</div>
              </div>
            }
            disabled={!item.editStatus}
            onConfirm={()=>{
              secureFinishButton(item.id).then((res)=>{
                setUpdateList(updateList+1);
              });
            }}
            okText="确认"
            cancelText="取消"
          >{item.editStatus===true&&<Button disabled={!item.editStatus} type="link">结束</Button>}
          </Popconfirm>}
        </Space>
      ),
    },
  ];

  useEffect(()=>{
    getMapZoomCenter(2).then((res) => {
      // setCenter(res.data.latitudeLongitude?res.data.latitudeLongitude.split(','):[104.065861, 30.657401]);
      // setZoom(zoomLevel(res.data.level));
    });
  }, []);
  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, []);
  return (
    <div >
      <CustomTable
        InitApi={GETKEYSECURITYAREA}
        formList={formList}
        toIndex={true}
        columns={columns}
        updateList={updateList}
        resetData={resetOptions}
        buttonGroup={() => (
          <Button type="primary">
            <Link to={`add/edit`} state={{operationType: 'add'}}>新增</Link>
          </Button>
        )}
      />

    </div>
  );
};


export default KeySecurityArea;
