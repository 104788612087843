import React from 'react';
import {Column, G2} from '@ant-design/plots';
import customTheme from './g2-theme.json';
import PropTypes from 'prop-types';
import {isMockData} from '../../utils/judge';
const ColumnChart = ({securityAreaData, title}) => {
  if (!securityAreaData) {
    return null; // 没有值，返回 null，不渲染组件
  }
  const {registerTheme} = G2;
  const isChangeData =isMockData();
  const onlineRate=(online, sum, present)=>{
    if (online===0&&sum===0) {
      return '100%';
    } else {
      if (sum===0) {
        return '-';
      } else if (online===0) {
        return '0%';
      } else {
        return `${present.toFixed(2)}%`;
      }
    }
  };
  registerTheme('custom-theme', customTheme);
  const config = {
    data: securityAreaData||[],
    theme: 'custom-theme',
    isGroup: true,
    autoFit: true,
    xField: 'type',
    yField: 'value',
    seriesField: 'name',
    legend: {
    //   layout: 'horizontal ',
      position: 'top-right',
    },
    yAxis: {
      label: isChangeData? null:{},
    },

    color: ({name})=>{
      return name==='哈啰'?'#1D39C4':
                name==='美团'?'#D48806':
                name==='青桔'?'#08979C':
                name==='电单车企业1'?'#ff229C':
                name==='电单车企业2'?'#339911':
                name==='电单车企业3'?'#e232ff':'#e23211';
    },
    // conversionTag: {},
    /** 设置间距 */
    appendPadding: [32, 6, 6, 0],
    marginRatio: 0.3,
    tooltip: {
      formatter: (datum) => {
        const all=securityAreaData.find((item)=>item.value === datum.value&&
        item.name===datum.name&&
        item.type===datum.type);

        const present=all.total?(datum.value/all.total*100):
        all.total===0?0:100;
        return {name: datum.name,
          value: `${title}\n${datum.value}/${all.total}&nbsp;&nbsp;&nbsp;&nbsp;${title}率
          ${onlineRate(datum.value, all.total, present)}`};
      },
    },
    label: {
    //   type: 'outer',
      // 可手动配置 label 数据标签位置
      position: 'top',
      formatter: (value)=>{
        // console.log(value);
        return `${title}\n${value.value}/${value.total}`;
      },
      // 'top', 'middle', 'bottom'
      // 可配置附加的布局方法
    //   layout: [
    //     // 柱形图数据标签位置自动调整
    //     {
    //       type: 'interval-adjust-position',
    //     }, // 数据标签防遮挡
    //     {
    //       type: 'interval-hide-overlap',
    //     }, // 数据标签文颜色自动调整
    //     {
    //       type: 'adjust-color',
    //     },
    //   ],
    },
    style: {
      height: 'calc(50vh - 238px)',
      minHeight: 112,
    },
  };
  return <Column {...config} />;
};
ColumnChart.propTypes = {
  securityAreaData: PropTypes.array,
  title: PropTypes.string.isRequired,
};
export default ColumnChart;
