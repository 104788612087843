import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getAttendanceRule} from '../../api';
import {Skeleton} from 'antd';
import {attendanceDateStatus} from '../statusCollection';
import {clockFormat} from '../baseTime';

const AttendanceInfo = ({attendId, type}) => {
  const [ruleData, setRuleData]= useState(null);
  useEffect(()=>{
    getAttendanceRule(attendId, '2').then((res)=>{
      setRuleData(res.data);
    });
  }, []);
  return (
    <div>{ruleData?(<div>
      <div className="margin-b-m">
        <div className="padding-b-m">
          <span>规则名称：</span>
          通用规则
        </div>
        {/* <div className="padding-b-m">
          <span>
            每日时间点设置：
          </span>
          <span className="text-gray">
              每日{clockFormat(ruleData.attendanceCommonRule?.attendanceDayInterval)}后为下个考勤日
          </span>
        </div> */}
        <div className="padding-b-m">
          <span>考勤日：</span>
          <span className="text-gray">
            {attendanceDateStatus(ruleData.attendanceCommonRule?.attendanceDate)}，
          节假日{ruleData.attendanceCommonRule?.isExcludeHolidays ? '不' : ''}打卡
          </span>
        </div>
      </div>
      {ruleData.attendanceBasicRule && ruleData.attendanceBasicRule.map((item, index) => (
        <div key={`Rule${index}`}>
          <div className="padding-b-m">
            <span>规则名称：</span>
            考勤时段
            {/* {index + 1} */}
          </div>
          <div className="padding-b-m">
            <span>考勤时段：</span>
            <span className="text-gray">
              {clockFormat(item.startTime)}-{clockFormat(item.endTime)}
            </span>
          </div>
          <div className="padding-b-m">
            <span>
              打卡地点有效半径：
            </span>
            <span className="text-gray">
              {item.radius}m 以内
            </span>
          </div>
          <div className="padding-b-m">
            <span>
              打卡地点：
            </span>
            <span className="text-gray">
              {item.address}
            </span>
          </div>
          <div className="padding-b-m">
            <span>
              打卡地点坐标：
            </span>
            <span className="text-gray">
              {item.latitudeLongitude}
            </span>
          </div>
          <div className="padding-b-m">
            <span>
              上班打卡时段：
            </span>
            <span className="text-gray">
              {clockFormat(item.earliestTime)}-{clockFormat(item.startTime)}
            </span>
          </div>
          <div className="padding-b-m">
            <span>
              下班打卡时段：
            </span>
            <span className="text-gray">
              {clockFormat(item.endTime)}-{clockFormat(item.latestTime)}
            </span>
          </div>
        </div>
      ))}
    </div>):(<div>
      <Skeleton active />
      <Skeleton active />
    </div>)}
    </div>
  );
};

AttendanceInfo.propTypes = {
  attendId: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default AttendanceInfo;
