import React, {useState} from 'react';
import {Button, Divider, message, Modal, Space, Tooltip} from 'antd';
import CustomTable from '../../../components/customTable';
import {Link} from 'react-router-dom';
import {attendanceStatus, attendanceStatusText, workStatus, workStatusText,
  bikeCompanyOptions, bikeCompany, staffStatus, staffStatusText,
} from '../../../components/statusCollection';
import EditForms from '../../../components/forms/EditForms';
import {GETATTENDLOGLIST} from '../../../api/Urls';
import {clockFormat, timeFormat} from '../../../components/baseTime';
import {updateAttendanceLogRemark} from '../../../api';
import {InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';

const AttendanceRecord = () => {
  const [updateList, setUpdateList] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const formList = [
    {
      label: '运维人员所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      type: 'select',
      key: 'company',
      name: 'company',
      col: 8,
      label: '运维人员所属企业',
      options: bikeCompanyOptions(true),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '考勤更新时间',
      key: 'datePicker',
      col: 8,
      name: 'datePicker2',
      type: 'datePicker2',
      showTime: true,
      format: 'YYYY-MM-DD HH:mm:ss',
    },
    // {
    //   label: ' ',
    //   key: 'col',
    //   col: 8,
    // },
    {
      label: '职务类型',
      name: 'jobType',
      key: 'jobType',
      col: 8,
      type: 'select',
      options: [
        {
          value: 1,
          label: '单车人员',
        },
        {
          value: 2,
          label: '单车调运车辆',
        },
        {
          value: 3,
          label: '核验人员',
        },
        {
          value: 4,
          label: '其他',
        },
        {
          value: 5,
          label: '电单车人员',
        },
        {
          value: 6,
          label: '电单车调运车辆',
        },
        {
          value: 7,
          label: '安装人员',
        },
      ],
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [
        {label: '姓名', value: 1},
        {label: '考勤组名', value: 2},
      ],
    },
    // {
    //   label: '运维人员姓名',
    //   key: 'staff',
    //   col: 8,
    //   name: 'name',
    //   type: 'input',
    // },
  ];
  const onSubmit=(value)=>{
    updateAttendanceLogRemark(value, 2).then((res)=>{
      message.success(res.msg);
      setModalVisible(false);
      setUpdateList(updateList + 1);
    });
  };
  const commitForm=[
    {
      label: ' ',
      name: 'id',
      type: 'input',
      message: ' ',
      message2: ' ',
      hidden: true,
    },
    {
      label: ' ',
      name: 'content',
      type: 'textArea',
      message: 'Please input !',
      message2: '请输入考勤备注',
      placeholder: '请输入（200字以内）',
      required: true,
    }];
  const tooltip = () => {
    return (
      <div style={{color: 'black'}}>
        <div>
          <InfoCircleFilled style={{color: '#FAAD14', marginRight: 9}}/>
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s"/>
        <p className="tipInfo">考勤点工作状态指最近一次更新考勤记录时的运维人员工作状态。</p>
      </div>
    );
  };
  const columns = [
    {
      title: '运维人员',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
      width: 100,
      fixed: 'left',
    },
    {
      title: (<Space>考勤点工作状态<Tooltip title={tooltip()} color="white">
        <InfoCircleOutlined style={{color: '#8c8c8c'}}/>
      </Tooltip></Space>),
      dataIndex: 'jobStatus',
      key: 'jobStatus',
      width: 160,
      render: (jobStatus) => workStatus(jobStatus),
      exportRender: (render) => workStatusText(render.jobStatus),
      onExport: true,
    },
    {
      title: '考勤时段',
      key: 'startTime',
      dataIndex: 'startTime',
      onExport: true,
      width: 170,
      render: (startTime, record) => `${clockFormat(record.startTime)}--${clockFormat(record.endTime)}`,
      exportRender: (startTime) => `${clockFormat(startTime.startTime)}--${clockFormat(startTime.endTime)}`,
    },
    {
      title: '考勤结果',
      key: 'clockInStatus',
      dataIndex: 'clockInStatus',
      onExport: true,
      width: 200,
      // eslint-disable-next-line
      render: (clockInStatus, record) => (<span>
        上班 {attendanceStatus(record.clockInStatus, 1)}
        下班 {attendanceStatus(record.clockOutStatus)}
      </span>),
      exportRender: (clockInStatus) =>
        `上班：${attendanceStatusText(clockInStatus.clockInStatus, 1)}
         下班：${attendanceStatusText(clockInStatus.clockOutStatus)}`,
    },
    {
      title: '考勤组名',
      dataIndex: 'groupName',
      key: 'groupName',
      onExport: true,
      width: 150,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      onExport: true,
      // eslint-disable-next-line
      render: (record, item) =><span>{staffStatus(record)}</span>,
      width: 120,
      exportRender: (record)=>staffStatusText(record.jobType),
    },
    {
      title: '人员所属区域',
      dataIndex: 'area',
      key: 'area',
      onExport: true,
      width: 150,
    },
    {
      title: '人员所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record)=>bikeCompany(record.company),
    },


    {
      title: '最近更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      // sorter: true,
      width: 180,
      render: (updateTime)=>timeFormat(updateTime),
      exportRender: (record) =>timeFormat(record.updateTime),
      onExport: true,
      sorter: true,
    },
    {
      title: '操作',
      key: 'action',
      width: 210,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          <Button type="link" size="small">
            <Link to={`details/${record.id}/${record.operationId}`}>详情</Link>
          </Button>
          <Button type="link"
            size="small"
            onClick={() => {
              setEditData({
                ...record,
                content: record.remark,
              });
              setModalVisible(true);
            }}>备注</Button>
          {record.jobType!==7&&<Button type="link" size="small">
            <Link to={`position/${record.id}/${record.operationId}`}
              state={{updateTime: record.updateTime}}>定位与轨迹</Link>
          </Button>}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        InitApi={GETATTENDLOGLIST}
        formList={formList}
        updateList={updateList}
        columns={columns}
        resType="POST"
        scroll={true}
        tip="考勤记录导出中..."
        exportFileName="考勤记录"
      />
      <Modal
        title="考勤备注"
        className="no-label"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditData(null);
        }}
        maskClosable={false}
        width={482}
        footer={null}
        destroyOnClose>
        <p>
          {editData&&(<Space size="large">
            <span>{editData.name}</span>
            <span>{clockFormat(editData.startTime)}-{clockFormat(editData.endTime)}</span>
            <span>
              上班：[{attendanceStatusText(editData.clockInStatus, 1)}]
              ，下班：[{attendanceStatusText(editData.clockOutStatus)}]
            </span>
          </Space>)}
        </p>
        <EditForms
          onCancel={() => {
            setModalVisible(false);
            setEditData(null);
          }}
          pageLayout="vertical"
          loading={false}
          buttonStyle="modal-button"
          initData={editData}
          columnsForm={commitForm}
          onFinish={onSubmit}
        />
      </Modal>
    </div>
  );
};

AttendanceRecord.propTypes = {};

export default AttendanceRecord;
