import {Spin} from 'antd';
import PropTypes from 'prop-types';
import React, {useRef, useState, useEffect} from 'react';
import {AMapKey} from './aMapAPI';
import './mapStyle.css';
import AMapLoader from '@amap/amap-jsapi-loader';

let AMapAPI;

/**
 * 设备列表展示设备所在位置地图组件
 * @author ziwei.Dong
 * @date 2022-12-22
 * @param {any} {longitude
 * @param {any} latitude}
 * @return {JSXDom}
 */
const DevicePosition =({longitude, latitude}) =>{
  const mapRef = useRef();
  const [mapLoading, setMapLoading] = useState(true);
  const mapInit = () => {
    AMapLoader.load({
      ...AMapKey,
      plugins: [
        'AMap.Geolocation',
        'AMap.Scale',
        'AMap.Polygon',
        'AMap.MapType',
        'AMap.Marker',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      mapRef.current.map = new AMap.Map('map', {
        resizeEnable: true,
        center: [104.065861, 30.657401],
        // pitch: 40,
        showLabel: true,
        zoom: 14,
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      AMapAPI=AMap;
      addMaker(longitude, latitude);
      setMapLoading(false);
    });
  };

  const addMaker=(longitude, latitude) => {
    const overlays = mapRef.current?.map?. getAllOverlays('marker');
    if (overlays?.length>0) {
      mapRef.current.map.remove(overlays);
    }
    if (longitude && latitude) {
      const marker = new AMapAPI.Marker({
        position: [longitude, latitude],
      });
      // 将 markers 添加到地图
      if (mapRef.current.map) {
        mapRef.current.map.add(marker);
        mapRef.current.map.setCenter([longitude, latitude]);
      }
    }
  };

  useEffect(()=>{
    mapInit();
  }, []);
  useEffect(()=>{
    if (AMapAPI) {
      addMaker(longitude, latitude);
    }
  }, [longitude, latitude]);

  return (
    <Spin spinning={mapLoading}>
      <div
        ref={mapRef}
        id="map"
        className="device-position-map"
      />
    </Spin>
  );
};
DevicePosition.propTypes = {
  longitude: PropTypes.string,
  latitude: PropTypes.string,
};
export default DevicePosition;
