import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Menu} from 'antd';
import {storage} from '../../../utils/storage';


const CustomMenus = () => {
  const l = useLocation().pathname.split('/');
  const [currentMenu] = useState(storage.getData( 'userInfo')?.menuTree?.children||null);

  const [selectedKeys, setSelectedKeys]=useState(null);

  const [openKeys, setOpenKeys] = useState(null);

  const onOpen=(value)=>{
    setOpenKeys([value[value.length-1]]);
  };
  const onClick=(value)=>{
    setSelectedKeys(value.key);
  };
  const ICONS = {
    'home1': <span/>,
    /* 'workManagement': <span/>,
    'attendanceManagement': <span/>,
    'bicycleManagement': <span/>,
    'staffManagement': <span/>,
    'accessControl': <span/>,
    'logManagement': <span/>,*/
  };


  useEffect(() => {
    if (l&&l.length>2&&l[2]!=='details'&&l[2]!=='add'&&l[2]!=='position') {
      setOpenKeys([l[1]]);
      setSelectedKeys(l[2]);
    } else {
      setOpenKeys([l[1]]||['home']);
      setSelectedKeys(l[1]||'home');
    }
  }, [useLocation()]);
  return (
    <div className="theme-menu">
      <Menu
        className="menus"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onClick={onClick}
        onOpenChange={onOpen}
        mode="inline"
        theme="dark"
        multiple="false"

      >
        {currentMenu&&currentMenu.length>0?currentMenu.map((menu) => {
          if (menu.children&&menu.children.length>0) {
            return (
              <Menu.SubMenu
                icon={ICONS[menu.menuType]}
                key={menu.menuType}
                title={menu.cnName}>
                {menu.children.map((menuItem) => {
                  return (
                    <Menu.Item key={menuItem.menuType}>
                      <Link to={`/${menu.menuType}/${menuItem.menuType}`}>
                        {menuItem.cnName}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item
                key={menu.menuType}
                icon={ICONS[menu.menuType]}
              >
                <Link to={`/${menu.menuType}`} target={menu.menuType === 'bigScreen/cockpitScreen'? '_blank':''}>
                  {menu.cnName}
                </Link>
              </Menu.Item>
            );
          }
        },
        ):null}

      </Menu>
    </div>
  );
};

export default CustomMenus;
