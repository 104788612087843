
import {Button, Result} from 'antd';
import React from 'react';

const NoPermission =()=>{
  return (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权访问该页面。"
      extra={<Button type="primary" href="/home">返回主页</Button>}
    />
  );
};

export default NoPermission;
