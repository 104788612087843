import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {staffStatus, bikeCompanyCollect} from '../../../components/statusCollection';
import {
  Button,
  Card,
  Form,
  Result,
  Space,
  Steps,
  Spin,
  Input,
  Row,
  Col,
  Cascader,
  Radio,
  InputNumber,
  Divider,
  Checkbox,
  TimePicker,
  Table,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';
import './style.css';
import moment from 'moment/moment';
import PolyEdit from '../../../components/aMap/PolyEdit';
import {
  getAreaNumStatus,
  securityAreaEdit,
  getAreaTreeFilter,
  specialWorkerList,
  securityAreaAdd,
  getUserInfo,
} from '../../../api';

const KeyOperateArea = ({queryType}) => {
  const {state} = useLocation();
  const urlSrceen = location.pathname.indexOf('/bigScreen') === -1? '':'/bigScreen';
  const [current, setCurrent] = useState(0);
  const [companyArr, setCompanyArr] = useState([
    {value: 1, label: '美团', status: 1},
    {value: 2, label: '青桔', status: 1},
    {value: 3, label: '哈啰', status: 1},
  ]);
  const [formSearch] = Form.useForm();
  const [basicInfo] = Form.useForm();
  const [settingTimeForm] = Form.useForm();
  const [finished, setFinished] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapInfo, setMapInfo] = useState({});
  const [polyPath, setPolyPath] = useState([]);
  const [areaId, setAreaId] = useState(0);
  const [areaOptions, setAreaOptions] = useState([]);
  const enterprise = JSON.parse(localStorage.getItem('bikeEnterprise'))?.bikeEnterprise;
  const [valueRadio, setValueRadio] = useState(
    state?.operationType === 'edit' ? state.baseInfo.baseInfo.requireType : 1,
  );
  // 表格数据
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 车企表格添加列表
  const [table, setTable] = useState([]);
  const [MTTable, setMTTable] = useState([]);
  const [QJTable, setQJTable] = useState([]);
  const [HLTable, setHLTable] = useState([]);
  const [selectMTRow, setSelectMTRow] = useState([]);
  const [selectQJRow, setSelectQJRow] = useState([]);
  const [selectHLRow, setSelectHLRow] = useState([]);
  const [bikeCompany, setBikeCompany] = useState(1);
  const [stepTwo, setStepTwo] = useState({});
  const [disableAdd, setDisableAdd] = useState(state?.operationType === 'edit' ? false : true);
  // 设置保障时段的时间间隔
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  // 保存时间段
  const [allTime, setAllTime] = useState(
    state?.operationType === 'edit' ? state?.baseInfo?.baseInfo?.guaranteeTimeList : [],
  );
  // 分页设置
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [dataChangeWarn, setDataChangeWarn] = useState(false);
  // 获取保障区域
  const getSpecialListAddTable = async (value, filter) => {
    const res = await specialWorkerList({bikeEnterprise: value, ...filter});
    if (res.code === 200) {
      setTable(res.data);
      setIsModalOpen(true);
      setPagination({current: 1, pageSize: 10});
    } else {
      setTable([]);
      setIsModalOpen(true);
    }
  };
  const options = [
    {
      label: '周一',
      value: '1',
    },
    {
      label: '周二',
      value: '2',
    },
    {
      label: '周三',
      value: '3',
    },
    {
      label: '周四',
      value: '4',
    },
    {
      label: '周五',
      value: '5',
    },
    {
      label: '周六',
      value: '6',
    },
    {
      label: '周日',
      value: '7',
    },
  ];
  // 获取个人信息
  const getOnlineUserInfo = async () => {
    const res = await getUserInfo();
    if (res.code === 200) {
      setUserInfo(res.data);
      // 更新输入框数据
      if (!isInArray(1, res.data?.bikeEnterprise)) {
        basicInfo.setFieldsValue({
          bikeMT: 0,
          requireCountMT: 0,
        });
        setMTTable([]);
        setSelectMTRow([]);
      }
      if (!isInArray(2, res.data?.bikeEnterprise)) {
        basicInfo.setFieldsValue({
          bikeQJ: 0,
          requireCountQJ: 0,
        });
        setQJTable([]);
        setSelectQJRow([]);
      }
      if (!isInArray(3, res.data?.bikeEnterprise)) {
        basicInfo.setFieldsValue({
          bikeHL: 0,
          requireCountHL: 0,
        });
        setHLTable([]);
        setSelectHLRow([]);
      }
    }
  };

  const onResetModal = () => {
    formSearch.resetFields();
  };
  const ValuesSearch = () => {
    const searchInfo = formSearch.getFieldsValue();
    searchInfo.phoneNumber = searchInfo?.phoneNumber?.toString();
    getSpecialListAddTable(bikeCompany, searchInfo);
  };
  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      const type = 2;
      const data = res.data.map((item, index) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (
            <span>
              {item.areaName}&nbsp;&nbsp;
              <span className="text-light"> {item.managerName || ''}</span>
            </span>
          ),
          children: item.children ?
            item.children.map((sub) => {
              return {
                ...sub,
                label: (
                  <span>
                    {sub.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {sub.managerName || ''}</span>
                  </span>
                ),
                value: sub.id,
                streetManagerName: sub.managerName || '',
                disabled: type === 4 && sub.managerName,
                children: sub.children ?
                    sub.children.map((aff) => {
                      return {
                        ...aff,
                        label: (
                          <span>
                            {aff.areaName}&nbsp;&nbsp;
                            <span className="text-light"> {aff.managerName || ''}</span>
                          </span>
                        ),
                        value: aff.id,
                        streetManagerName: aff.managerName || '',
                        // disabled: type===4&&aff.managerName,
                      };
                    }) :
                    [],
              };
            }) :
            [],
        };
      });
      setAreaOptions(data);
    });
  };

  const isInArray = (num, array) => array?.includes(num);
  useEffect(() => {
    getAreaGroup();
    getOnlineUserInfo();
  }, []);

  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{option.areaName}</span>;
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  const columns = [
    {
      title: 'NO',
      key: 'index',
      render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      render: (text) => text || '--',
      width: 280,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      render: (text) => bikeCompanyCollect(text) || '--',
      width: 150,
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      render: (text) => (text === 1 ? '普通运维' : text === 2 ? '负责人' : '--'),
      width: 180,
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (text) => staffStatus(text) || '--',
      width: 210,
    },
  ];
  const columnsStaticMT = [
    {
      title: 'NO',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      render: (text, index) => bikeCompanyCollect(text) || '--',
      width: 120,
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      render: (text) => (text === 1 ? '普通运维' : text === 2 ? '负责人' : '--'),
      width: 180,
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      width: 180,
      render: (text) => staffStatus(text) || '--',
    },
    {
      title: '操作',
      key: 'option',
      width: 100,
      render: (_, record) => [
        <a
          key="space"
          onClick={() => {
            const num = MTTable?.filter((item) => item.id !== record.id);
            setMTTable(num);
            setSelectMTRow(num);
          }}
        >
          移除
        </a>,
      ],
    },
  ];
  const columnsStaticQJ = [
    {
      title: 'NO',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      render: (text, index) => bikeCompanyCollect(text) || '--',
      width: 120,
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      render: (text) => (text === 1 ? '普通运维' : text === 2 ? '负责人' : '--'),
      width: 180,
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (text) => staffStatus(text) || '--',
      width: 180,
    },
    {
      title: '操作',
      key: 'option',
      width: 100,
      render: (_, record) => [
        <a
          key="space"
          onClick={() => {
            const num = QJTable?.filter((item) => item.id !== record.id);
            setQJTable(num);
            setSelectQJRow(num);
          }}
        >
          移除
        </a>,
      ],
    },
  ];
  const columnsStaticHL = [
    {
      title: 'NO',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      render: (text, index) => bikeCompanyCollect(text) || '--',
      width: 120,
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      render: (text) => (text === 1 ? '普通运维' : text === 2 ? '负责人' : '--'),
      width: 180,
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      render: (text) => text || '--',
      width: 200,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (text) => staffStatus(text) || '--',
      width: 180,
    },
    {
      title: '操作',
      key: 'option',
      width: 100,
      render: (_, record) => [
        <a
          key="space"
          onClick={() => {
            const num = HLTable?.filter((item) => item.id !== record.id);
            setHLTable(num);
            setSelectHLRow(num);
          }}
        >
          移除
        </a>,
      ],
    },
  ];
  const validateTimeDifference = (field, currentIndex) => {
    const isGap = allTime.reduce((prev, curr, index) => {
      if (index === currentIndex) {
        // 获取当前对象的第一个键值和前一个对象的第二个键值
        const start = moment(curr.startTime, 'HH:mm:ss');
        const end = moment(curr.endTime, 'HH:mm:ss');
        const diff = moment(end).diff(start, 'minutes');
        // 检查当前对象的第一个键值是否大于前一个对象的第二个键值, 并且不能够等于
        if (diff < 30) {
          return false;
        } else {
          return prev;
        }
      } else {
        return prev;
      }
    }, true);
    const isValid = allTime.reduce((prev, curr, index) => {
      if (index === currentIndex) {
        // 获取当前对象的第一个键值和前一个对象的第二个键值
        const currFirstKey = moment(curr.startTime, 'HH:mm:ss');
        const prevSecondKey = index > 0 ? moment(allTime[index - 1].endTime, 'HH:mm:ss') : null;
        // 检查当前对象的第一个键值是否大于前一个对象的第二个键值, 并且不能够等于
        if ((prevSecondKey !== null && currFirstKey.isBefore(prevSecondKey)) || currFirstKey.isSame(prevSecondKey)) {
          return false;
        } else {
          return prev;
        }
      } else {
        return prev;
      }
    }, true);
    if (!isValid) {
      // eslint-disable-next-line
      return Promise.reject('所选的时间段不能够重叠');
    }
    if (!isGap) {
      // eslint-disable-next-line
      return Promise.reject('保障时段应大于等于30分钟');
    }
    return Promise.resolve();
  };
  const bikeCompanyFilter = (item, number) => {
    const num = item ? item.filter((item) => item.bikeEnterprise === number)?.[0] : [];
    return num;
  };
  const handleTimeChange = (mom, value, field) => {
    // 获取所有的时间
    const timeList = settingTimeForm.getFieldValue('guaranteeTimeList');
    const AllTimeArray = timeList.map((obj) => {
      return {
        startTime: moment(obj?.text[0]).format('HH:mm:ss'),
        endTime: moment(obj?.text[1]).format('HH:mm:ss'),
      };
    });
    setAllTime(AllTimeArray);
    settingTimeForm.validateFields();
    // 获取所有表单项的值
    const allFields = settingTimeForm.getFieldValue('guaranteeTimeList');
    // 对除了当前更改的表单项以外的其他表单项进行校验
    allFields.forEach((item, index) => {
      if (index !== field) {
        // 使用validateTimeDifference函数进行校验
        const result = validateTimeDifference(item);
        if (result instanceof Promise) {
          result.catch((err) => {
            // 如果校验失败，可以在这里处理错误
            console.error(err);
          });
        }
      }
    });
    if (value) {
      setDisableAdd(false);
    }
  };
  const onRadioChange = (e) => {
    setValueRadio(e.target.value);
  };
  // 校正规则
  const checked = (rule, value) => {
    const reg = /\S/g;
    const regName = /^([1-4]\d{3}|[1-9]\d{0,2}|0)$/; // 非负整数
    if (!reg.test(value) || value === undefined) {
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (!regName.test(value)) {
      return Promise.reject(new Error('数值填写不符合规范，请填写0~5000范围内的整数'));
    } else {
      return Promise.resolve();
    }
  };
  // 校正规则 所输入的值必须小于所选的列表项的数组长度
  const checkedQJ = (rule, value) => {
    const reg = /\S/g;
    const regName = /^([1-4]\d{3}|[1-9]\d{0,2}|0)$/;
    if (!reg.test(value) || value === undefined) {
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (value > QJTable?.length || !regName.test(value)) {
      return Promise.reject(new Error('请填写0~5000范围内的整数且应配人数需小于或等于指定人员总数。'));
    } else {
      return Promise.resolve();
    }
  };
  const checkedHL = (rule, value) => {
    const reg = /\S/g;
    const regName = /^([1-4]\d{3}|[1-9]\d{0,2}|0)$/;
    if (!reg.test(value) || value === undefined) {
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (value > HLTable?.length || !regName.test(value)) {
      return Promise.reject(new Error('请填写0~5000范围内的整数且应配人数需小于或等于指定人员总数'));
    } else {
      return Promise.resolve();
    }
  };
  const checkedMT = (rule, value) => {
    const reg = /\S/g;
    const regName = /^([1-4]\d{3}|[1-9]\d{0,2}|0)$/;
    if (!reg.test(value) || value === undefined) {
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (value > MTTable?.length || !regName.test(value)) {
      return Promise.reject(new Error('请填写0~5000范围内的整数且应配人数需小于或等于指定人员总数'));
    } else {
      return Promise.resolve();
    }
  };
  const onSave = (value) => {
    setMapInfo(value);
    setCurrent(current + 1);
  };

  // 去重
  const arrayUniqueItem = (arr) => {
    if (arr) {
      const uniqueArr = arr.reduce((acc, curr) => {
        if (!acc.find((item) => item.id === curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      return uniqueArr;
    }
  };
  // 选择人员数据处理;
  const handleSelectAll = (selected, selectedRows, changeRows, setData, data) => {
    if (selected) {
      const newData = [...data, ...selectedRows].filter(Boolean);
      const uniqueData = arrayUniqueItem(newData);
      setData([...uniqueData]);
    } else {
      const filteredArray = data.filter((bigObj) => !changeRows.some((smallObj) => smallObj.id === bigObj.id));
      setData([...filteredArray]);
    }
  };
  // 选择人员数据处理;
  const handleSelect = (record, selected, data, setData) => {
    if (selected) {
      if (!data.some((item) => item.id === record.id)) {
        setData([...data, record]);
      }
    } else {
      const updatedData = data.filter((item) => item.id !== record.id);
      setData(updatedData);
    }
  };
  const guaranteeAreaForm = (
    <>
      <Form
        name="settingForm"
        form={basicInfo}
        layout="vertical"
        initialValues={
          state?.operationType === 'edit' ?
            {
              guaranteeName: state.baseInfo.baseInfo.guaranteeName,
              areaId: state.baseInfo.baseInfo.areaId.split(',').map((item) => parseInt(item)),
              requireType: state.baseInfo.baseInfo.requireType,
              bikeMT:
                  state.baseInfo.baseInfo.requireType === 1 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 1)?.requireCount :
                    '',
              bikeQJ:
                  state.baseInfo?.baseInfo.requireType === 1 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 2)?.requireCount :
                    '',
              bikeHL:
                  state.baseInfo?.baseInfo.requireType === 1 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 3)?.requireCount :
                    '',
              requireCountMT:
                  state.baseInfo.baseInfo.requireType === 2 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 1)?.requireCount :
                    0,
              requireCountQJ:
                  state.baseInfo.baseInfo.requireType === 2 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 2)?.requireCount :
                    0,
              requireCountHL:
                  state.baseInfo.baseInfo.requireType === 2 ?
                    bikeCompanyFilter(state.baseInfo.baseInfo?.requireNumList, 3)?.requireCount :
                    0,
            } :
            {isExcludeHolidays: []}
        }
      >
        <Row>
          <Col span={24}>
            <div className="base-form-title">{'保障区域基础信息'}</div>
          </Col>
          <Col className="padding-r-m">
            <Form.Item
              label="保障区域名称"
              name="guaranteeName"
              rules={[
                {
                  required: true,
                  max: 20,
                },
              ]}
            >
              <Input
                placeholder="请输入（20字）"
                style={{
                  width: '322px',
                }}
              />
            </Form.Item>
          </Col>
          <Col className="padding-r-m">
            <Form.Item
              label="保障区域所属区域"
              name="areaId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cascader
                allowClear={true}
                // multiple
                displayRender={displayRender}
                placeholder="请选择"
                options={areaOptions}
                style={{
                  width: '322px',
                }}
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={24}>
            <div className="base-form-title">{'应配人数类型'}</div>
          </Col>
          <Form.Item name="requireType">
            <Radio.Group onChange={onRadioChange} value={valueRadio} defaultValue={1}>
              <Radio value={1}>各企业应配人数</Radio>
              <Radio value={2}>各企业应配人数（指定人员）</Radio>
            </Radio.Group>
          </Form.Item>
          <Col span={24}>
            <div className="base-form-title">{'各车企应配人数'}</div>
          </Col>
          {valueRadio === 1 && (
            <Col span={24}>
              <Form.Item rules={[{required: true, validator: checked}]} label={'美团应配人数'} name="bikeMT">
                <InputNumber min={0} className="width-322" disabled={!isInArray(1, userInfo?.bikeEnterprise)} />
              </Form.Item>
              <Form.Item rules={[{required: true, validator: checked}]} label={'青桔应配人数'} name="bikeQJ">
                <InputNumber min={0} className="width-322" disabled={!isInArray(2, userInfo?.bikeEnterprise)} />
              </Form.Item>
              <Form.Item rules={[{required: true, validator: checked}]} label={'哈啰应配人数'} name="bikeHL">
                <InputNumber min={0} className="width-322" disabled={!isInArray(3, userInfo?.bikeEnterprise)} />
              </Form.Item>
            </Col>
          )}
          {valueRadio === 2 && (
            <Col span={24}>
              <Row>
                <Modal
                  title="选择人员"
                  visible={isModalOpen}
                  maskClosable={false}
                  destroyOnClose
                  closable={false}
                  zIndex={999}
                  width={820}
                  onCancel={() => {
                    setIsModalOpen(false);
                    setPagination({current: 1, pageSize: 10});
                    formSearch.resetFields();
                    setSelectMTRow([...MTTable]);
                    setSelectQJRow([...QJTable]);
                    setSelectHLRow([...HLTable]);
                  }}
                  onOk={() => {
                    formSearch.resetFields();
                    if (bikeCompany === 1) {
                      setMTTable(selectMTRow);
                    } else if (bikeCompany === 2) {
                      setQJTable(selectQJRow);
                    } else if (bikeCompany === 3) {
                      setHLTable(selectHLRow);
                    }
                    setIsModalOpen(false);
                    setPagination({current: 1, pageSize: 10});
                  }}
                >
                  <div>
                    <Form form={formSearch} name="control-hooks" layout="inline" style={{marginBottom: 20}}>
                      <Form.Item name="name" label="姓名">
                        <Input />
                      </Form.Item>
                      <Form.Item name="phoneNumber" label="电话">
                        <Input />
                      </Form.Item>
                      <Form.Item>
                        <Space>
                          <Button htmlType="button" onClick={onResetModal}>
                            重置
                          </Button>
                          <Button type="primary" onClick={ValuesSearch}>
                            搜索
                          </Button>
                        </Space>
                      </Form.Item>
                    </Form>
                  </div>
                  <Table
                    style={{marginTop: 12}}
                    onChange={(pagination) => setPagination(pagination)}
                    pagination={pagination}
                    columns={columns}
                    key={'id'}
                    dataSource={table}
                    rowKey={'id'}
                    rowSelection={{
                      // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                      defaultSelectedRowKeys:
                        bikeCompany === 1 ?
                          MTTable?.map((item) => item.id) :
                          bikeCompany === 2 ?
                          QJTable?.map((item) => item.id) :
                          bikeCompany === 3 ?
                          HLTable?.map((item) => item.id) :
                          [],
                      selectedRowKeys:
                        bikeCompany === 1 ?
                          selectMTRow.map((item) => item.id) :
                          bikeCompany === 2 ?
                          selectQJRow.map((item) => item.id) :
                          bikeCompany === 3 ?
                          selectHLRow.map((item) => item.id) :
                          [],
                      onSelectAll: (selected, selectedRows, changeRows) => {
                        // console.log(selected, selectedRows, changeRows);
                        bikeCompany === 1 ?
                          handleSelectAll(selected, selectedRows, changeRows, setSelectMTRow, selectMTRow) :
                          bikeCompany === 2 ?
                          handleSelectAll(selected, selectedRows, changeRows, setSelectQJRow, selectQJRow) :
                          bikeCompany === 3 ?
                          handleSelectAll(selected, selectedRows, changeRows, setSelectHLRow, selectHLRow) :
                          null;
                      },
                      onSelect: (record, selected) => {
                        bikeCompany === 1 ?
                          handleSelect(record, selected, selectMTRow, setSelectMTRow) :
                          bikeCompany === 2 ?
                          handleSelect(record, selected, selectQJRow, setSelectQJRow) :
                          bikeCompany === 3 ?
                          handleSelect(record, selected, selectHLRow, setSelectHLRow) :
                          null;
                      },
                    }}
                  ></Table>
                </Modal>
                <Col span={24}>
                  <div className="base-form-title-c">{'美团'}</div>
                </Col>
                <Col className="padding-r-m">
                  <Form.Item name="worker" required>
                    <Space>
                      <span className="span-type">*</span>美团指定人员:<span>{MTTable?.length}</span>
                      <Button
                        disabled={!isInArray(1, userInfo?.bikeEnterprise)}
                        onClick={() => {
                          formSearch.resetFields();
                          setBikeCompany(1);
                          getSpecialListAddTable(1, {});
                        }}
                      >
                        添加
                      </Button>
                    </Space>
                    <Table
                      className="table-size"
                      columns={columnsStaticMT}
                      key={'id'}
                      dataSource={MTTable}
                      pagination={false}
                    ></Table>
                  </Form.Item>
                  <Form.Item
                    label={'美团应配人数'}
                    name="requireCountMT"
                    rules={[{required: true, validator: checkedMT}]}
                  >
                    <InputNumber min={0} className="width-322" disabled={!isInArray(1, userInfo?.bikeEnterprise)} />
                  </Form.Item>
                </Col>
                <Divider />

                <Col span={24}>
                  <div className="base-form-title-c">{'青桔'}</div>
                </Col>
                <Col className="padding-r-m">
                  <Form.Item name="worker" required>
                    <Space>
                      <span className="span-type">*</span>青桔指定人员:<span>{QJTable?.length}</span>
                      <Button
                        disabled={!isInArray(2, userInfo?.bikeEnterprise)}
                        onClick={() => {
                          formSearch.resetFields();
                          setBikeCompany(2);
                          getSpecialListAddTable(2, {});
                          // setIsModalOpen(true);
                        }}
                      >
                        添加
                      </Button>
                    </Space>
                    <Table
                      className="table-size"
                      columns={columnsStaticQJ}
                      key={'id'}
                      dataSource={QJTable}
                      pagination={false}
                    ></Table>
                  </Form.Item>
                  <Form.Item
                    label={'青桔应配人数'}
                    name="requireCountQJ"
                    rules={[{required: true, validator: checkedQJ}]}
                  >
                    <InputNumber min={0} className="width-322" disabled={!isInArray(2, userInfo?.bikeEnterprise)} />
                  </Form.Item>
                </Col>
                <Divider />

                <Col span={24}>
                  <div className="base-form-title-c">{'哈啰'}</div>
                </Col>
                <Col className="padding-r-m">
                  <Form.Item name="worker" required>
                    <Space>
                      <span className="span-type">*</span>哈啰指定人员:<span>{HLTable?.length}</span>
                      <Button
                        onClick={() => {
                          formSearch.resetFields();
                          setBikeCompany(3);
                          getSpecialListAddTable(3, {});
                          // setIsModalOpen(true);
                        }}
                        disabled={!isInArray(3, userInfo?.bikeEnterprise)}
                      >
                        添加
                      </Button>
                    </Space>
                    <Table
                      className="table-size"
                      columns={columnsStaticHL}
                      key={'id'}
                      dataSource={HLTable}
                      pagination={false}
                    ></Table>
                  </Form.Item>
                  <Form.Item
                    label={'哈啰应配人数'}
                    name="requireCountHL"
                    rules={[{required: true, validator: checkedHL}]}
                  >
                    <InputNumber min={0} className="width-322" disabled={!isInArray(3, userInfo?.bikeEnterprise)} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
  const guaranteePeriod = (
    <>
      <Form
        name="settingTime"
        form={settingTimeForm}
        layout="horizontal"
        initialValues={
          state.operationType === 'edit' ?
            {
              isExcludeHolidays:
                  state.baseInfo.baseInfo.isExcludeHolidays === 0 ? [state.baseInfo.baseInfo.isExcludeHolidays] : [],
              guaranteeDay: state.baseInfo.baseInfo.guaranteeDay.split(','),
              guaranteeTimeList:
                  state.operationType === 'edit' ?
                    state.baseInfo.baseInfo.guaranteeTimeList.map((item) => {
                      if (item && item.startTime !== null && item.endTime !== null) {
                        return {text: [moment(item.startTime, 'HH:mm:ss'), moment(item.endTime, 'HH:mm:ss')]};
                      } else {
                        return {text: []};
                      }
                    }) :
                    [],
            } :
            {isExcludeHolidays: [], guaranteeTimeList: [{}]}
        }
      >
        <Row>
          <Col span={24}>
            <Space>
              <div className="base-form-title">{'通用规则'}</div>
              <div>规则适用于该保障区域全部时段</div>
            </Space>
          </Col>
          <Col span={24}>
            <Form.Item name="isExcludeHolidays">
              <Checkbox.Group options={[{label: '法定节假日自动排休', value: 0}]} />
            </Form.Item>
            <Form.Item
              label="考勤日:"
              name="guaranteeDay"
              rules={[
                {
                  required: true,
                  message: '请选择考勤日！',
                },
              ]}
            >
              <Checkbox.Group options={options} />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <div className="base-form-title">{'保障时段'}</div>
              <div>请按照时间顺序填写保障时段，注意各时段时间不可重叠</div>
            </Space>
          </Col>
          <Col span={24}>
            <Form.List
              name="guaranteeTimeList"
              label={`保障时段`}
              rules={[
                {
                  required: true,
                  message: '请添加考勤时段！',
                },
              ]}
            >
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({key, name, ...restField}, index) => (
                    <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                      <Form.Item
                        {...restField}
                        label={`保障时段${index + 1}`}
                        name={[name, 'text']}
                        // validateFirst={true}
                        rules={[
                          {required: true, message: '请添加保障时段！'},
                          {validator: (rule, value) => validateTimeDifference(value, index)},
                        ]}
                        // {validator: validateTimeDifference}
                      >
                        <TimePicker.RangePicker
                          style={{width: '360px'}}
                          allowClear={false}
                          // onChange={handleTimeChange}
                          onChange={(time, timeString) => handleTimeChange(time, timeString, index)}
                        />
                      </Form.Item>
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                            const timeListRemove = settingTimeForm.getFieldValue('guaranteeTimeList');
                            timeListRemove.map((obj) => {
                              if (!obj?.text) {
                                setDisableAdd(true);
                                return true;
                              } else {
                                setDisableAdd(false);
                                return false;
                              }
                            });
                          }}
                        />
                      )}
                    </Space>
                  ))}
                  <Form.Item>
                    {fields.length < 10 && (
                      <Button
                        disabled={disableAdd}
                        type="dashed"
                        size="large"
                        onClick={() => {
                          add();
                          setDisableAdd(true);
                        }}
                        icon={<PlusOutlined />}
                      >
                        新增时段
                      </Button>
                    )}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </>
  );
  useEffect(() => {
    const temp = [];
    for (const index of enterprise) {
      const res = companyArr.find((i) => i.value === index);
      if (res) temp.push(res);
    }
    getAreaNumStatus(queryType, 2).then((res) => {
      if (res.data) {
        res.data.forEach((item) => {
          temp.forEach((element) => {
            if (item.bikeType === element.value) {
              if (item.jobType === 1 || item.jobType === 5) {
                element.disabledPeo = true;
              }
              if (item.jobType === 2 || item.jobType === 6) {
                element.disabledCar = true;
              }
            }
          });
        });
        temp.forEach((item) => {
          if (item.disabledPeo !== true) {
            basicInfo.setFieldsValue({company1: {jobType1: 0, jobType5: 0}});
          }
          if (item.disabledCar !== true) {
            basicInfo.setFieldsValue({company1: {jobType2: 0, jobType6: 0}});
          }
        });
        setCompanyArr([...temp]);
      }
    });
  }, []);
  useEffect(() => {
    if (state.operationType === 'edit') {
      setPolyPath(state.baseInfo?.baseInfo?.markRange ? JSON.parse(state.baseInfo.baseInfo.markRange) : []);
    }
  }, []);

  return (
    <div>
      <DetailsHeader
        pageName={(state.operationType === 'add' ? '新建' : '编辑') + '单车重点工作' + '保障区域'}
        buttonGroup={() => (
          <Button type="primary" hidden={state.operationType === 'add'}>
            <Link to={`${urlSrceen}/guaranteeArea/keySecurityArea/details/${state.baseInfo && state.baseInfo.id}`}>
              返回保障区域详情
            </Link>
          </Button>
        )}
      />
      <Card>
        <Steps type="navigation" current={current} className="site-navigation-steps">
          <Steps.Step title="绘制保障区域" />
          <Steps.Step title="设置保障时段" />
          <Steps.Step title="填写保障区域信息" />
        </Steps>
        {finished ? (
          <div className="margin200">
            <Result
              status="success"
              title={'恭喜你，' + (state.operationType === 'add' ? '新建' : '编辑') + '单车' + '重点保障区域成功'}
              subTitle="保障区域全部管理员可见，仅创建者可编辑"
              extra={
                state.operationType === 'add' ?
                  [
                    <Button key="buy">
                      <Link to={`${urlSrceen}/guaranteeArea/keySecurityArea/details/${areaId}`}>查看保障区域详情</Link>
                    </Button>,
                    <Button type="primary" key="console">
                      <Link to={`${urlSrceen}/guaranteeArea/keySecurityArea`}>返回</Link>
                    </Button>,
                  ] :
                  [
                    <Button key="buy">
                      <Link to={`${urlSrceen}/guaranteeArea/keySecurityArea/details/${state.baseInfo && state.baseInfo.id}`}>
                          查看保障区域详情
                      </Link>
                    </Button>,
                  ]
              }
            />
          </div>
        ) : (
          <Spin spinning={loading}>
            <div>
              {current === 0 && (
                <div className="margin-t32">
                  {state.operationType === 'add' ? (
                    <PolyEdit onSave={onSave} polyPath={mapInfo.path} initArea={mapInfo.adName} />
                  ) : (
                    <PolyEdit
                      onSave={onSave}
                      userCenter={polyPath[0]}
                      initCenter={polyPath[0]}
                      userZoom={12}
                      polyPath={polyPath}
                      hiddenMaker={true}
                      // initArea={initArea}
                    />
                  )}
                </div>
              )}
              {current === 1 && <div>{guaranteePeriod}</div>}
              {current === 2 && <div className="bg-area-gray">{guaranteeAreaForm}</div>}
            </div>
            <div className="text-center margin-t-l">
              <Space>
                {current !== 0 && (
                  <Button
                    onClick={() => {
                      setCurrent(current - 1);
                    }}
                  >
                    上一步
                  </Button>
                )}
                {current !== 2 && current !== 0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (current === 1) {
                        settingTimeForm.validateFields().then((values) => {
                          if (values.isExcludeHolidays.length > 0) {
                            values.isExcludeHolidays = values.isExcludeHolidays[0];
                          } else {
                            values.isExcludeHolidays = 1;
                          }
                          const weekDay = values.guaranteeDay;
                          if (weekDay.length > 0) {
                            values.guaranteeDay = weekDay.toString();
                          }
                          const arr = values.guaranteeTimeList;
                          if (arr.length > 0) {
                            const newArr = arr.map((obj) => {
                              return {
                                startTime: moment(obj.text[0]).format('HH:mm:ss'),
                                endTime: moment(obj.text[1]).format('HH:mm:ss'),
                              };
                            });
                            values.guaranteeTimeList = newArr;
                          }
                          setStepTwo(values);
                          setCurrent(current + 1);
                          const operationListData = state.baseInfo?.baseInfo?.operationList;

                          const MTdata = bikeCompanyFilter(operationListData, 1);
                          const QJdata = bikeCompanyFilter(operationListData, 2);
                          const HLdata = bikeCompanyFilter(operationListData, 3);

                          setMTTable(MTdata?.operationInfo || []);
                          setSelectMTRow(MTdata?.operationInfo || []);
                          setQJTable(QJdata?.operationInfo || []);
                          setSelectQJRow(QJdata?.operationInfo || []);
                          setHLTable(HLdata?.operationInfo || []);
                          setSelectHLRow(HLdata?.operationInfo || []);

                          if (!isInArray(1, userInfo?.bikeEnterprise)) {
                            basicInfo.setFieldsValue({
                              bikeMT: 0,
                              requireCountMT: 0,
                            });
                            setMTTable([]);
                            setSelectMTRow([]);
                          }
                          if (!isInArray(2, userInfo?.bikeEnterprise)) {
                            basicInfo.setFieldsValue({
                              bikeQJ: 0,
                              requireCountQJ: 0,
                            });
                            setQJTable([]);
                            setSelectQJRow([]);
                          }
                          if (!isInArray(3, userInfo?.bikeEnterprise)) {
                            basicInfo.setFieldsValue({
                              bikeHL: 0,
                              requireCountHL: 0,
                            });
                            setHLTable([]);
                            setSelectHLRow([]);
                          }
                        });
                      }
                    }}
                  >
                    下一步
                  </Button>
                )}
                {current === 2 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      basicInfo.validateFields().then(() => {
                        setLoading(true);
                        const info = basicInfo.getFieldsValue();
                        // 一阶段的值 mapInfo
                        const guaranteeCommonRule = {
                          isExcludeHolidays: stepTwo.isExcludeHolidays,
                          guaranteeDay: stepTwo.guaranteeDay,
                          guaranteeTimeList: stepTwo.guaranteeTimeList,
                        };
                        const guaranteeInfo = {
                          guaranteeName: info.guaranteeName,
                          areaId: info.areaId[info.areaId.length - 1],
                          markRange: JSON.stringify(mapInfo.path),
                          requireType: valueRadio,
                          // guaranteeArea: mapInfo?.adName? mapInfo.adName.toString():'青羊区',
                          guaranteeArea:
                          // eslint-disable-next-line max-len
                            '锦江区,青羊区,金牛区,武侯区,成华区,高新区,天府新区,郫都区,青白江区,温江区,龙泉驿区,新都区,双流区,成都东部新区,新津区,简阳市,都江堰市,彭州市,邛崃市,崇州市,金堂县,大邑县,蒲江县',
                        };
                        const requireNumList = [
                          {
                            bikeEnterprise: 1,
                            requireCount: info.requireType === 2 ? info.requireCountMT : info.bikeMT,
                            appointCount: info.requireType === 2 ? MTTable?.length : 0,
                          },
                          {
                            bikeEnterprise: 2,
                            requireCount: info.requireType === 2 ? info.requireCountQJ : info.bikeQJ,
                            appointCount: info.requireType === 2 ? QJTable?.length : 0,
                          },
                          {
                            bikeEnterprise: 3,
                            requireCount: info.requireType === 2 ? info.requireCountHL : info.bikeHL,
                            appointCount: info.requireType === 2 ? HLTable?.length : 0,
                          },
                        ];
                        const arrayMT = MTTable ?
                          MTTable?.map((obj) => ({
                            operationId: obj.id,
                            bikeEnterprise: 1,
                            jobType: obj.jobType,
                            areaId: obj.areaId,
                            // positionLevel: obj.positionLevel,
                          })) :
                          [];
                        const arrayQJ = QJTable ?
                          QJTable?.map((obj) => ({
                            operationId: obj.id,
                            bikeEnterprise: 2,
                            jobType: obj.jobType,
                            areaId: obj.areaId,
                            // positionLevel: obj.positionLevel,
                          })) :
                          [];
                        const arrayHL = HLTable ?
                          HLTable?.map((obj) => ({
                            operationId: obj.id,
                            bikeEnterprise: 3,
                            jobType: obj.jobType,
                            areaId: obj.areaId,
                            // positionLevel: obj.positionLevel,
                          })) :
                          [];
                        const operationList = [...arrayMT, ...arrayQJ, ...arrayHL];
                        // userInfo新值
                        const manageEnterprise = userInfo?.bikeEnterprise.filter((item) => parseInt(item) < 4);
                        const areaName = userInfo?.areaName;
                        const formAdd =
                          info.requireType === 2 ?
                            {
                              guaranteeCommonRule,
                              guaranteeInfo,
                              requireNumList,
                              operationList,
                              manageEnterprise,
                              areaName,
                            } :
                            {
                              guaranteeCommonRule,
                              guaranteeInfo,
                              requireNumList,
                              // operationList,
                              manageEnterprise,
                              areaName,
                            };
                        if (state.operationType === 'add') {
                          securityAreaAdd(formAdd, 2)
                              .then((res) => {
                                if (res.code == 200) {
                                  setFinished(true);
                                  setAreaId(res.data);
                                } else if (res.code === 604 || res.code === 605) {
                                  setMTTable([]);
                                  setSelectMTRow([]);
                                  setQJTable([]);
                                  setSelectQJRow([]);
                                  setHLTable([]);
                                  setSelectHLRow([]);
                                  basicInfo.setFieldsValue({
                                    areaId: [],
                                    bikeMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                  });
                                  setDataChangeWarn(true);
                                  getOnlineUserInfo();
                                } else if (res.code === 606) {
                                  setMTTable([]);
                                  setSelectMTRow([]);
                                  setQJTable([]);
                                  setSelectQJRow([]);
                                  setHLTable([]);
                                  setSelectHLRow([]);
                                  basicInfo.setFieldsValue({
                                    areaId: [],
                                    bikeMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                  });
                                  setDataChangeWarn(true);
                                  getOnlineUserInfo();
                                }
                              })
                              .finally(() => {
                                setLoading(false);
                              });
                        } else {
                          securityAreaEdit({guaranteeId: parseInt(state.baseInfo.id), ...formAdd}, 2)
                              .then((res) => {
                              // setFinished(true);
                                setAreaId(res.data);
                                if (res.code == 200) {
                                  setFinished(true);
                                  setAreaId(res.data);
                                } else if (res.code === 604 || res.code === 605 || res.code === 606) {
                                  setMTTable([]);
                                  setSelectMTRow([]);
                                  setQJTable([]);
                                  setSelectQJRow([]);
                                  setHLTable([]);
                                  setSelectHLRow([]);
                                  basicInfo.setFieldsValue({
                                    areaId: [],
                                    bikeMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    bikeHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountMT: isInArray(1, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountQJ: isInArray(2, userInfo?.bikeEnterprise) ? null : 0,
                                    requireCountHL: isInArray(3, userInfo?.bikeEnterprise) ? null : 0,
                                  });
                                  setDataChangeWarn(true);
                                  getOnlineUserInfo();
                                }
                              })
                              .finally(() => {
                                setLoading(false);
                              });
                        }
                      });
                    }}
                  >
                    完成
                  </Button>
                )}
              </Space>
              <Modal
                title="信息变更"
                width={400}
                mask={false}
                closable={false}
                visible={dataChangeWarn}
                destroyOnClose
                footer={
                  <Button onClick={() => setDataChangeWarn(false)} htmlType="submit" type={'primary'}>
                    确认
                  </Button>
                }
              >
                检测到管理人员信息变更，或指定人员信息变更，将自动清空已设置的“所属区域”和“指定人员”，请重新设置。
              </Modal>
            </div>
          </Spin>
        )}
      </Card>
    </div>
  );
};

KeyOperateArea.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default KeyOperateArea;
