export const getModelHtml = (mhtml, style) => {
  return `
        Content-Type: text/html; charset="utf-8"
            <!DOCTYPE html>
            <html>
            <head>
            <style>
                ${style}
            </style>
            </head>
            <body>
                ${mhtml}
            </body>
            </html>
        `;
};

export const getStyle = () => {
  return `.statement-title {
        display: flex;
        justify-content: space-between;
    }

    table td,th{
        font-size: 14px;
        padding: 1px 1px;
        border-width: 1px;
        border-style: solid;
        border-color: #d0d0d0;
        word-break: keep-all;
        white-space: nowrap;
    }

    .statement-Tabs {
        height: 52px;
        background-color: #fff;
        padding: 12px 24px 0px;
        margin: 5px 0 24px;
    }

    .statement-Tabs .ant-tabs-nav-wrap {
        height: 40px;
    }

    .statement-time-change {
        height: 72px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #595959;
        margin-bottom: 32px;
    }

    .time-icon {
        width: 32px;
        height: 32px;
        border: 1px solid #D9D9D9;
        text-align: center;
        line-height: 32px;
    }

    .time-icon-disable {
        width: 32px;
        height: 32px;
        border: 1px solid #D9D9D9;
        text-align: center;
        line-height: 32px;
        color: #D9D9D9;
    }


    .statement-time-text {
        text-align: center;
        margin: 0 32px;
    }

    .statement-time-text p {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        color: #000;
    }

    .statement-time-text span {
        font-weight: 400;
        font-size: 12px;
        color: #8C8C8C;
    }

    .statement-module {
        margin-bottom: 24px;
    }

    .statement-module h3 {
        margin-bottom: 16px;
    }

    .target-content {
        height: 532px;
        background-color: #fff;
        padding: 32px 60px;
        box-sizing: border-box;
        text-align: center;
    }

    .ranking-list {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    .ranking-list .ranking {
        width: 115px;
        height: 126px;
        border-radius: 4px;
        box-shadow: 0 0 5px #D9D9D9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 16px;
    }

    .ranking-list-export {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .ranking-list-export .ranking {
        display: inline-block;
        width: 115px;
        height: 126px;
        border-radius: 4px;
        box-shadow: 0 0 5px #D9D9D9;
        margin: 0 16px;
    }

    .ranking-list-export img {
        display: block;
        margin: 0 auto;
        margin-top: 16px;
    }



    .gold {
        color: #FFC53D;
        margin: 5px 0;
    }

    .silver {
        color: #8C8C8C;
        margin: 5px 0;
    }

    .cuprum {
        color: #D46B08;
        margin: 5px 0;
    }

    .grade :nth-child(1) {
        font-weight: 600;
        font-size: 20px;
        color: #262626;
        margin-right: 3px;
    }

    .grade :nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        color: #8C8C8C;
    }

    .target-hint {
        background-color: #F5F5F5;
        text-align: left;
        line-height: 36px;
        color: #8C8C8C;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 16px;
    }

    .target-table {
        text-align: left;
    }

    .target-table .car {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        margin-bottom: 16px;
    }

    .target-table p {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        margin-bottom: 32px;
    }

    .supervise-content {
        height: 477px;
        background-color: #fff;
        padding: 32px 60px;
        box-sizing: border-box;
        text-align: center;
    }

    .supervise-hint {
        background-color: #F5F5F5;
        text-align: left;
        color: #8C8C8C;
        padding: 8px 10px;
        box-sizing: border-box;
        margin-bottom: 16px;
        display: flex;
    }

    .supervise-icon {
        margin-top: 5px;
    }

    .supervise-hint p {
        margin: 0 8px;
    }

    .supervise-charts {
        display: flex;
        justify-content: space-between;
    }

    .supervise-charts .pie-charts {
        width: 35%;
        height: 300px;
    }

    .supervise-card {
        width: 60%;
        display: flex;
        gap: 32px;
        align-items: center;
    }

    .card-content {
        width: 30%;
        height: 108px;
        border: 1px solid #D9D9D9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .supervise-card-title {
        display: flex;
        margin-bottom: 16px;
    }

    .supervise-card-title span {
        color: #8C8C8C;
    }

    .card-content p {
        margin: 0;
    }

    .card-dot {
        width: 5px;
        height: 5px;
        background-color: #E89E0D;
        border-radius: 50%;
        margin-right: 4px;
        margin-top: 8px;
    }

    .card-dot-M {
        background-color: #E89E0D;
    }

    .card-dot-H {
        background-color: #1D39C4;
    }

    .card-dot-Q {
        background-color: #13BEC6;
    }

    .dataStatistics-content {
        height: 527px;
        background-color: #fff;
        padding: 32px 60px;
        box-sizing: border-box;
        text-align: center;
    }

    .dataStatistics-hint {
        background-color: #F5F5F5;
        text-align: left;
        color: #8C8C8C;
        padding: 8px 10px;
        box-sizing: border-box;
        margin-bottom: 16px;
        display: flex;
    }

    .dataStatistics-icon {
        margin-top: 5px;
    }

    .dataStatistics-hint p {
        margin: 0 8px;
    }

    .dataStatistics-charts {
        margin: 0 auto;
        height: 370px;
    }

    .dataStatistics-charts .pie-charts {
    }

    .dataStatistics-charts .pie-charts .pie {
        height: 300px;
    }

    .dataStatistics-target-table {
        text-align: left;
    }

    .dataStatistics-target-table p {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        margin-bottom: 32px;
    }

    .bar-charts {
    }

    .bar-graph {
        height: 225px;
    }

    .dot-chart {
        display: flex;
        justify-content: right;
        margin-bottom: 24px;
    }

    .dot-title {
        margin-left: 24px;
        color: #8C8C8C;
        display: flex;
    }

    .bar-mes-content {
        width: 134px !important;
        height: 109px !important;
        padding: 8px !important;
    }

    h5 {
        margin: 10px !important;
        color: #595959 !important;
        font-size: 12px !important;
    }

    h6 {
        margin: 10px !important;
        color: #8C8C8C !important;
        font-size: 12px !important;
    }

    h5 img {
        margin-right: 8px;
    }

    .weight-hint {
        color: #8C8C8C;
        background-color: #F5F5F5;
        padding: 5px;
        margin-bottom: 32px;
    }

    .label-input {
        width: 48%;
    }

    .weight-from {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .bar-export {
        margin-top: -45px;
    }

    .bar-column {
        height: 250px !important;
    }

    .pie-export {
        height: 250px !important;
    }

    .btn-export {
        margin-bottom: 24px;
    }
    #mhtml {
        padding: 4px;
    }
    
    .export-title {
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        margin-bottom: 12px;
        text-align: center;
    }
    
    .secondary-heading {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: rgba(51, 51, 51, 0.8);
    }
    
    .secondary-heading-two {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: rgba(51, 51, 51, 0.8);
        margin-bottom: 12px;
    }
    
    .secondary-heading-three {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: rgba(51, 51, 51, 0.8);
        margin-bottom: 40px;
    }
    
    .margin-t-m {
        margin-top: 16px;
    }
    
    
    .export-heading {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        margin-bottom: 12px;
    }
    
    .area-monitor {
        margin-top: 40px;
    }
    
    .area-monitor p {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
    }
    
    .area-monitor .gray-color {
        color: rgba(51, 51, 51, 0.8) !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin: 12px 0px;
    }
    
    .area-monitor .export-heading {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        margin-bottom: 12px;
    }
    
    .chart-width {
        width: 450px;
    }
    
    .export-described {
        color: rgba(51, 51, 51, 0.8);
        font-weight: 400;
        font-size: 14px;
        margin-top: 18px;
    }
    .pie-sum {
        margin-top: 8px;
    }
    
    .no-data-export {
        text-align: center;
    }`;
};
