
import React from 'react';
import {Line, G2} from '@ant-design/plots';
import PropTypes from 'prop-types';
import customTheme from './g2-theme-cockpit.json';
import {toBigChartNumUnit, toThousands} from '../../utils/dataFormat';
import {timeFormatDateAndMin, timeFormatMin} from '../baseTime';


/**
 * 描述
 * @author ziwei.Dong
 * @date 2022-12-26
 * {
 * @param {any} chartData
 * @param {any} themeColor
 * @param {any} title
 * @param {any} sliderEnd
 * }
 * @return {any}
 */

const PointsLineChart =({chartData, themeColor, title, sliderStart, log, average, min})=> {
  const {registerTheme} = G2;
  registerTheme('custom-theme', customTheme);
  const config = {
    data: (chartData||[]).map((item)=>({
      count: log?Math.pow(item.count, 2)/average-min:item.count,
      date: timeFormatMin(item.date),
    })),
    scrollbars: {},
    appendPadding: [16, 16, 0, 8],
    theme: 'custom-theme',
    xField: 'date',
    yField: 'count',
    // label: {
    //   formatter: (v)=>toThousands(v.count),
    // },
    slider: {
      start: sliderStart,
      end: 1,
      formatter: (v)=>null,
    },
    // animation: false,
    point: {
      size: 2,
      shape: 'circle',
      style: {
        fill: themeColor,
        stroke: themeColor,
        // lineWidth: 2,
      },
    },
    smooth: true,
    yAxis: {
      nice: true,
      // minLimit: min,
      // min,
      label: {
        // 数值格式化为千分位
        formatter: (v) => toBigChartNumUnit(log?( Math.sqrt((parseInt(v)+min)*average)||0).toString():v),
      },
    },
    xAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => timeFormatDateAndMin(v),
      },
    },
    tooltip: {
      showMarkers: false,
      formatter: (datum)=>{
        // window.hoverData=datum;
        return {
          name: title,
          value: toThousands(log?parseInt(Math.sqrt( (datum.count+min)*average)||0):datum.count),
        };
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    color: themeColor,
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    style: {
      width: '100%',
      height: '100%',
    },
  };
  return (
    <Line {...config}
    // onReady={((plot)=>{
    //   plot.on('element:click', ()=>{
    //     console.log( window.hoverData);
    //   });
    // })}
    />
  );
};
PointsLineChart.propTypes={
  chartData: PropTypes.arrayOf(PropTypes.object),
  themeColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sliderStart: PropTypes.number.isRequired,
  log: PropTypes.bool,
  average: PropTypes.number,
  min: PropTypes.number,
};

export default PointsLineChart;
