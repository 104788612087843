import React, {useState, useMemo} from 'react';
import {Button, Col, Row, Space, Tooltip, Divider} from 'antd';
import PropTypes from 'prop-types';
import {InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import DemoTime from './DemoTime';
import LineChart from './lineChart';

const LineGraph = ({promptTitle, tab, filedData, timeReset, timeSearch, chartTime, menuTab, incomeDate}) => {
  const [NotRecord, setNotRecord] = useState();

  const lineChart = useMemo(
      () => (
        <LineChart data={filedData} chartTime={(val) => chartTime && chartTime(val.date)} title={promptTitle} />
      ),
      [filedData],
  );
  // 提示
  const tooltip = (text) => {
    return (
      <div style={{color: 'black'}}>
        <div>
          <InfoCircleFilled style={{color: '#FAAD14', marginRight: 9}} />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        <p className="tipInfo">{text}</p>
      </div>
    );
  };
  const tooltips = () => {
    let tip;
    if (tab === 1) {
      tip = '所选开始与结束两个时间的最长跨度不得超过7*24h。';
    } else if (tab === 2 || tab === 4) {
      tip = '所选开始与结束两个时间的最长跨度不得超过24h。';
    } else {
      tip = '所选开始与结束两个时间的最长跨度不得超过30天。';
    }
    return (
      <Tooltip title={tooltip(tip)} className="margin-r-s margin-l-s" color="white">
        <InfoCircleOutlined style={{color: '#8c8c8c'}} />
      </Tooltip>
    );
  };

  return (
    <div>
      <div className="car-column padding-m">
        <Row className="justify-content">
          <Col className={tab ? 'car-title padding-m' : 'car-title'}>{promptTitle}</Col>
          <Col className="align-center">
            <span>时间区间 </span>
            {tooltips()}
            <div className="margin-r-s margin-l-s  padding-r-s">
              <DemoTime
                setNotRecord={(e) => setNotRecord(e)}
                reset={() => {
                  timeReset();
                }}
                tab={tab}
                menuTab={menuTab}
                incomeDate={incomeDate}
              />
            </div>

            <Space>
              <Button
                htmlType="submit"
                type="primary"
                className="margin-r-m"
                onClick={() => {
                  timeSearch(NotRecord);
                }}
              >
                搜索
              </Button>
            </Space>
          </Col>
        </Row>
        <div className="data-linechart">{filedData && lineChart}</div>

      </div>
    </div>
  );
};
LineGraph.propTypes = {
  promptTitle: PropTypes.string,
  tab: PropTypes.number,
  filedData: PropTypes.any,
  timeReset: PropTypes.func,
  timeSearch: PropTypes.func,
  chartTime: PropTypes.any, // 列表与趋势图交互
  menuTab: PropTypes.number,
  incomeDate: PropTypes.any,
};
export default LineGraph;
