import React, {useState, useEffect, useRef} from 'react';
import CustomTable from '../../../components/customTable';
import {bikeCompany, bikeErrorText, staffStatus, staffStatusText
  , bikeCompanyOptions} from '../../../components/statusCollection';
import {timeFormat} from '../../../components/baseTime';
import {Link, useLocation} from 'react-router-dom';
import {GETVERIFICATIONLIST} from '../../../api/Urls';
// import CustomImageMin from '../../../components/customImage/CustomImageMin';
import {Image} from 'antd';
import errorImg from '../../../asset/error_min.png';

const UnusualRecord = () => {
  const [updateList, setUpdateList] = useState(0);
  const [tabsKey, setTabsKey]=useState(useLocation().state||1);

  const isMounted = useRef(false);
  const formList = [
    {
      type: 'select',
      key: 'company',
      name: 'company',
      col: 8,
      label: '运维人员所属企业',
      options: bikeCompanyOptions(true),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '车辆所属区域',
      key: 'areaBike',
      col: 8,
      name: 'bikeArea',
      type: 'areaBike',
    },
    {
      type: 'select',
      key: 'bikeType',
      name: 'bikeType',
      col: 8,
      label: '车辆所属车企',
      options: bikeCompanyOptions('bikeCompany'),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '核验异常类型',
      key: 'bikeErrorType',
      col: 8,
      name: 'bikeErrorType',
      type: 'select',
      options: [
        {
          value: 1,
          label: '信息错误',
        }, {
          value: 2,
          label: 'MAC地址不存在',
        }, {
          value: 3,
          label: '车锁号不存在',
        },
        {
          value: 4,
          label: '跨区域运营',
        },
      ],
    },
    {
      label: '职务类型',
      name: 'jobType',
      key: 'jobType',
      col: 8,
      type: 'select',
      options: [
        {
          value: 1,
          label: '单车人员',
        },
        {
          value: 2,
          label: '单车调运车辆',
        },
        {
          value: 3,
          label: '核验人员',
        },
        {
          value: 4,
          label: '其他',
        },
        {
          value: 5,
          label: '电单车人员',
        },
        {
          value: 6,
          label: '电单车调运车辆',
        },
        {
          value: 7,
          label: '安装人员',
        },
      ],
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '核验记录提交日期',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [
        {label: '车锁号', value: 1},
        {label: '实体车牌号', value: 2},
        {label: '蓝牙MAC地址', value: 3},
        {label: '地点', value: 4},
        {label: '姓名', value: 5},
        {label: '工号', value: 6},
      ],
    },
  ];


  const columns = [
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      onExport: true,
      fixed: 'left',
      width: 100,
    },
    {
      title: '姓名',
      dataIndex: 'operationName',
      key: 'operationName',
      onExport: true,
      width: 100,
    },
    {
      title: '人员所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record) => bikeCompany(record.company),
      width: 120,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      render: (jobType) => staffStatus(jobType),
      exportRender: (record) => staffStatusText(record.jobType),
      onExport: true,
      width: 140,
    },
    {
      title: '异常类型',
      dataIndex: 'bikeErrorType',
      key: 'bikeErrorType',
      render: (bikeErrorType) => bikeErrorText(bikeErrorType),
      exportRender: (record) => bikeErrorText(record.bikeErrorType),
      onExport: true,
      width: 140,
    },
    {
      title: '车辆所属车企',
      dataIndex: 'bikeType',
      key: 'bikeType',
      render: (bikeType) => bikeCompany(bikeType),
      exportRender: (record) => bikeCompany(record.bikeType),
      onExport: true,
      width: 120,
    },
    {
      title: '车辆所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      width: 150,
    },
    {
      title: '核验照片',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      // eslint-disable-next-line
      render: (imageUrl) => {
        return <Image.PreviewGroup
        >
          {
            imageUrl?imageUrl.split(';').map((item, index)=>{
              return <div key={item} style={index!==0?{display: 'none'}:{display: 'block'}}><Image
                width={72} height={40} fallback={errorImg}
                src={item||errorImg} /></div>;
            }):<Image fallback={errorImg}src={''} />
          }
        </Image.PreviewGroup>;
      },
      onExport: true,
      width: 130,
    },
    {
      title: '提交位置',
      dataIndex: 'address',
      key: 'address',
      width: 300,
      onExport: true,
    },
    {
      title: '提交时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 180,
      sorter: true,
      render: (createTime) => timeFormat(createTime),
      exportRender: (record) => timeFormat(record.createTime),
      onExport: true,
    },
    {
      title: '操作',
      key: 'action',
      width: 80,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record) => (
        <Link to={`details/${record.id}/${record.operationsStaffId}/${tabsKey}`}>详情</Link>
      ),
    },
  ];
  // 切换选项卡
  const changeTabs=(e)=>{
    setTabsKey(e);
  };
  useEffect(()=>{
    if (isMounted.current) {
      setUpdateList(updateList+1);
    } else {
      isMounted.current = true;
    }
  }, [tabsKey]);
  return (
    <CustomTable
      InitApi={`${GETVERIFICATIONLIST}${tabsKey}`}
      formList={formList}
      scroll={true}
      resetData={updateList}
      columns={columns}
      exportFileName={parseInt(tabsKey)===1?'单车核验异常记录':'电单车核验异常记录'}
      tabs={true}
      changeTabs={changeTabs}
      tabsKey={tabsKey}
      tip="核验异常记录导出中..."
    />
  );
};

UnusualRecord.propTypes = {};

export default UnusualRecord;
