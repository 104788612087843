
/**
 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
 *
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */
const SDK_APP_ID = 1400795540;
/**
 * Attention: 请不要将如下代码发布到您的线上正式版本的 App 中，原因如下：
 *
 *  本文件中的代码虽然能够正确计算出 UserSig，但仅适合快速调通 SDK 的基本功能，不适合线上产品，
 *  这是因为客户端代码中的 SECRET_KEY 很容易被反编译逆向破解，尤其是 Web 端的代码被破解的难度几乎为零。
 *  一旦您的密钥泄露，攻击者就可以计算出正确的 UserSig 来盗用您的腾讯云流量。
 *
 *  正确的做法是将 UserSig 的计算代码和加密密钥放在您的业务服务器上，然后由 App 按需向您的服务器获取实时算出的 UserSig。
 *  由于破解服务器的成本要高于破解客户端 App，所以服务器计算的方案能够更好地保护您的加密密钥。
 *  文档：https://cloud.tencent.com/document/product/647/17275#Server
 */


// a soft reminder to guide developer to configure sdkAppId/secretKey
if (SDK_APP_ID === '') {
  alert('请先配置好您的账号信息： SDK_APP_ID 及 SECRET_KEY ' +
    '\r\n\r\nPlease configure your SDK_APP_ID/SECRET_KEY in src/app/config.js');
}

// /**
//  * 获取 userSig 和 privateMapKey
//  * @param {string} userID 用户名
//  */
export const getLatestUserSig=(userId, userSig)=> {
  return {
    userSig,
    sdkAppId: SDK_APP_ID,
    userId,
    mode: 'rtc',
    useStringRoomId: true,
    privateMapKey: 255,
  };
};


export const createIMChat=()=> {
  return {SDKAppID: SDK_APP_ID};
};
