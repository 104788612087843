import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import Slice from '../../../../asset/Slice 19.png';
import ColumnChart from '../../../../components/echarts/ColumnChart';
import {getAreaBarChart, getKeyAreaBarChart} from '../../../../api';
import {bikeCompany} from '../../../../components/statusCollection';


const EchartsSunburst = ({data, queryType, areaId, isKeyArea, areaName}) => {
  const [securityAreaData, setSecurityAreaData] = useState(null);

  const getAreaBarChartData=()=>{
    if (isKeyArea) {
      getKeyAreaBarChart(queryType, areaId, 2).then((res)=>{
        const list =[];

        res.data.forEach((item)=>{
          list.push({
            name: bikeCompany(item.company),
            type: bikeCompany(item.company),
            value: item.actualArrival,
            total: item.arrive,
          });
        });
        setSecurityAreaData(list||[]);
      });
    } else {
      getAreaBarChart(queryType, areaId, 2).then((res)=>{
        const list =[];
        res.data.chart.forEach((item)=>{
          list.push({
            name: bikeCompany(item.company),
            type: queryType===1?'单车人员到岗':'电单车人员到岗',
            value: item.peopleOnline,
            total: item.peopleTotal,
          });
          list.push({
            name: bikeCompany(item.company),
            type: queryType===1?'单车调运车辆到岗':'电单车调运车辆到岗',
            value: item.carOnline,
            total: item.carTotal,
          });
        });

        setSecurityAreaData(list||[]);
      });
    }
  };
  // useEffect(() => {
  //   data.map((item)=>{
  //     if (item.name == '单车人员' || item.name == '电单车人员') {
  //       setDataPiePeople([
  //         {
  //           type: '在线',
  //           value: item.children[0].value,
  //         },
  //         {
  //           type: '离线',
  //           value: item.children[1].value,
  //         },
  //       ]);
  //     } else if (item.name == '单车调度车辆' || item.name == '电单车调度车辆') {
  //       setDataPieCar([
  //         {
  //           type: '在线',
  //           value: item.children[0].value,
  //         },
  //         {
  //           type: '离线',
  //           value: item.children[1].value,
  //         },
  //       ]);
  //     }
  //   });
  // }, [data]);

  useEffect(()=>{
    if (areaId) {
      getAreaBarChartData();
    } else {
      setSecurityAreaData(null);
    }
  }, [areaId]);
  useEffect(() => {
    setSecurityAreaData(null);
  }, [queryType, data]);
  return (

    <div className={`card-charts ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-show-h':''}`}>

      <Row className="card-title">
        <Col span={24}> <img src={Slice} className="card-title-img" />
          {areaName?`【${areaName}】${isKeyArea?'重点工作区域保障':''}到岗率`:'在线率'}</Col>
      </Row>
      {}

      <div className="chart-content">
        {areaName?( <ColumnChart
          securityAreaData={securityAreaData}
          title="到岗"
        />
     ):
       ( <ColumnChart securityAreaData={data} title="在线"/>)}
      </div>

    </div>

  );
};

EchartsSunburst.propTypes = {
  data: PropTypes.array.isRequired,
  areaId: PropTypes.number,
  queryType: PropTypes.number.isRequired,
  isKeyArea: PropTypes.bool,
  areaName: PropTypes.string,
};

export default EchartsSunburst;

