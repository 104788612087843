import React, {useEffect, useState} from 'react';

import Base64 from 'base-64';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Form, Input, Row, Spin, Modal} from 'antd';
import cop from '../../asset/cop.png';
import {storage} from '@/utils/storage';
import {getVerify, userLogin} from '../../api';
import {useTranslation} from 'react-i18next';
import './style.css';
import logo from '../../asset/logo-login.png';
import app from '../../asset/downloadApp.png';


const Login = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeImageUrl, setCodeImageUrl]= useState(null);
  const [captchaKey, setCaptchaKey] = useState('');
  const [visible, setVisible]=useState(false);
  const ng = useNavigate();
  const handleLogin = (values)=>{
    setLoading(true);
    userLogin({
      ...values,
      captchaKey,
      password: Base64.encode(`${values.password}hcy_shared_bikes@2022`),
    }, t('flag')).then((res)=>{
      storage.saveData('local', 'userInfo', res.data);
      ng('/home');
    }).catch((error)=>{
      getCordImage();
    }).finally(()=>setLoading(false));
  };
  const getCordImage=()=>{
    setCodeLoading(true);
    getVerify( t('flag')).then((res)=>{
      setCodeImageUrl(res.data.captcha);
      setCaptchaKey(res.data.captchaKey);
    }).finally(()=>setCodeLoading(false));
  };

  useEffect(()=>{
    getCordImage();
  }, []);

  return (
    <div className="content-login">
      <Spin spinning={loading}>
        <div className="login">
          <div className="logo-header">
            <Row>
              <Col flex="60px">
                <img src={logo} alt="logo" width="56" height="56" className="margin-r-m"/>
              </Col>
              <Col flex="auto" className="logo-header-info">
                成都市共享单车<br/>
                智慧监管系统
              </Col>
            </Row>
          </div>
          <Row>
            <Col flex="640px" className="login-left">
              <div className="warp-left">
                <div>
                  <div className="text-white">欢迎使用<br/>
                  成都市共享单车智慧监管系统
                  </div>
                </div>
              </div>
            </Col>
            <Col flex="auto">
              <div className="warp">
                <div className="login-box">
                  <div className="login-box-title">
                    账号密码登录
                  </div>
                  <Form
                    name="loginForm"
                    className="login-form"
                    layout="vertical"
                    onFinish={(values) => handleLogin(values)}
                    form={form}
                  >
                    <Row gutter={[0, 0]}>
                      <Col span={24}>
                        <Form.Item
                          label="用户名"
                          name="username"
                          rules={[{required: true, message: t('usernameMessage')}]}
                        >
                          <Input
                            size="large"
                            placeholder="请输入"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          className="pwd"
                          label={(<Row>
                            <Col span={8}>密码</Col>
                            <Col span={16} className="text-right pwd-info">忘记密码请联系管理员</Col>
                          </Row>)}
                          name="password"
                          rules={[{required: true, message: t('passwordMessage')}]}
                        >
                          <Input.Password
                            placeholder="请输入"
                            size="large"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          size="large"
                          name="captcha"
                          label="验证码"
                          rules={[{required: true, message: t('codeMessage')}]}
                        >
                          <Input
                            size="large"
                            placeholder="请输入"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="code-image">
                        <Form.Item size="large">
                          <Button size="large"
                            block
                            style={{padding: 0}}
                            onClick={getCordImage}>
                            <Spin spinning={codeLoading}>
                              <img
                                src={`${codeImageUrl}`}
                                alt="code"
                                width="100%"
                                height="100%"
                              />
                            </Spin>
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col span={24} className="margin-t-l">
                        <Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            block
                          >
                            {t('loginButton')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <div className="login-box-footer">{t('loginFooter')}</div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="download-app" onClick={()=>setVisible(true)}>下载单车运维APP</div>
        </div>
        <div className="download-app" onClick={()=>setVisible(true)}>下载单车运维APP</div>
      </Spin>
      <div
        className="custom-footer fixed"
      >
        <a target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?
                spm=a2c4g.11186623.J_9220772140.109.74cfe7c2mlNh13"
          rel="noreferrer">
          <img src={cop}/>
          <p>
                 川公网安备 51019002005306号
          </p >
        </a>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.110.74cfe7c2mlNh13#/Integrated/index"
          rel="noreferrer"
        >
          {/* <p>蜀ICP备 2021001698号</p> */}
          <p>蜀ICP备2022028011号-3</p>
        </a>
        <a>
          <p> V1.1.8</p>
        </a>
      </div>
      <Modal
        title="APP下载"
        visible={visible}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setVisible(false);
        }}
        width={624}
        footer={null}
      >
        <img src={app} alt="" />
      </Modal>
    </div>

  );
};


Login.propTypes = {
};

export default Login;
