import React, {useState, useEffect} from 'react';
import SubTitle from '../../../components/subtitle/SubTitle';
import CustomTable from '../../../components/customTable';
import {bikeCompany} from '../../../components/statusCollection';
import {timeFormat} from '../../../components/baseTime';
import noData from '../../../asset/noData.png';
import moment from 'moment';
import {bikeCompanyOptions, staffOptions, accessStatus, staffStatusText,
  staffStatus, cityManageCompany} from '../../../components/statusCollection';
import {GETONLINELOGLIST} from '../../../api/Urls';
import './style.css';
const UserAccessRecord = () => {
  const [tab, setTab] = useState(0);
  const [updateList, setUpdateList]=useState(0);
  const error = false;
  const operationPersonnelFormList=[
    {
      col: 8,
      key: 'address',
      name: 'composite2',
      type: 'selectInput2',
      valueName: 'name',
      label: '搜索运维人员姓名/联系电话',
      typeName: 'type',
      typeOptions: [
        {label: '姓名', value: 1},
        {label: '联系电话', value: 2},
      ],
    },
    {
      label: '访问状态',
      key: 'accessStatus',
      col: 8,
      name: 'accessStatus',
      type: 'select',
      // mode: 'multiple',
      options: [
        {
          value: 0,
          label: '访问中',
        },
        {
          value: 1,
          label: '访问结束',
        },
      ],
      placeholder: '请选择',
    },
    {
      label: '人员所属企业',
      key: 'bikeEnterprises',
      col: 8,
      name: 'bikeEnterprises',
      type: 'select',
      mode: 'multiple',
      options: bikeCompanyOptions(true),
      placeholder: '请选择',
    },
    {
      label: '人员职务类型',
      name: 'jobType',
      key: 'jobType',
      col: 8,
      type: 'select',
      options: staffOptions,
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      col: 12,
      key: 'datePickerCompact',
      name: 'datePicker',
      type: 'datePickerCompact',
      label: '访问时间区间',
      typeName: 'continueAccess',
      valueName: 'datePicker',
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
      concatIcon: true,
      hoverText: '可筛选指定时段内持续访问，或非持续访问的APP已登录人员。',
      typeOptions: [
        {label: '全部', value: 2},
        {label: '持续访问', value: 1},
        {label: '非持续访问', value: 0},
      ],
      format: 'YYYY-MM-DD HH:mm:ss',
    },
  ];
  const cityManagerFormList=[
    {
      col: 8,
      key: 'address',
      name: 'composite2',
      type: 'selectInput2',
      label: '搜索运维人员姓名/联系电话',
      typeName: 'type',
      valueName: 'name',
      typeOptions: [
        {label: '姓名', value: 1},
        {label: '联系电话', value: 2},
      ],
    },
    {
      label: '访问状态',
      key: 'accessStatus',
      col: 8,
      name: 'accessStatus',
      type: 'select',
      // mode: 'multiple',
      options: [
        {
          value: 0,
          label: '访问中',
        },
        {
          value: 1,
          label: '访问结束',
        },
      ],
      placeholder: '请选择',
    },
    {
      type: 'select',
      key: 'manageEnterprises',
      name: 'manageEnterprises',
      col: 8,
      label: '人员城管企业',
      options: [
        {
          value: 1,
          label: '市环卫中心',
        },
        {
          value: 2,
          label: '其他环卫中心',
        },
      ],
      mode: 'multiple',
      placeholder: '请选择',
    },
    {
      col: 12,
      key: 'datePickerCompact',
      name: 'datePicker',
      type: 'datePickerCompact',
      label: '访问时间区间',
      typeName: 'continueAccess',
      concatIcon: true,
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
      hoverText: '可筛选指定时段内持续访问，或非持续访问的APP已登录人员。',
      valueName: 'datePicker',
      typeOptions: [
        {label: '全部', value: 2},
        {label: '持续访问', value: 1},
        {label: '非持续访问', value: 0},
      ],
      format: 'YYYY-MM-DD HH:mm:ss',
    },
  ];
  const carCompanyDataSource = [];
  for (let i = 0; i < 100; i++) {
    carCompanyDataSource.push({
      key: i+1,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
    });
  }
  const workOrderDataSource = [];
  for (let i = 0; i < 100; i++) {
    workOrderDataSource.push({
      key: i+1,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
      children: [
        {
          key: 11,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
        {
          key: 12,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
        {
          key: 13,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
        {
          key: 14,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
      ],
    });
  }
  useEffect(()=>{
    setUpdateList(updateList+1);
  }, []);
  const operationPersonnelColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      width: 100,
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      onExport: true,
      width: 260,
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 200,
      onExport: true,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      width: 180,
      // sorter: true,
      onExport: true,
      render: (record) => bikeCompany(record),
      exportRender: (record)=>bikeCompany(record.bikeEnterprise),
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      width: 160,
      onExport: true,
      render: (record)=>staffStatus(record),
      exportRender: (record)=>staffStatusText(record.jobType),
    },
    {
      title: '访问状态',
      dataIndex: 'accessStatus',
      width: 160,
      key: 'accessStatus',
      onExport: true,
      render: (record)=>accessStatus(record),
      exportRender: (record)=>accessStatus(record.accessStatus),
    },
    {
      title: '进入时间',
      dataIndex: 'startTime',
      key: 'startTime',
      sorterKey: 'startTime1',
      // fixed: 'right',
      sorter: true,
      width: 260,
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
      exportRender: (record) =>timeFormat(record.startTime),
    },
    {
      title: '离开时间',
      dataIndex: 'endTime',
      key: 'endTime',
      sorterKey: 'endTime1',
      // fixed: 'right',
      sorter: true,
      width: 260,
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
      exportRender: (record) =>timeFormat(record.endTime),
    },
  ];
  const cityManageColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      width: 120,
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      onExport: true,
      width: 260,
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 260,
      onExport: true,
    },
    {
      title: '城管企业',
      dataIndex: 'manageEnterprise',
      key: 'manageEnterprise',
      width: 180,
      // sorter: true,
      onExport: true,
      render: (record)=>cityManageCompany(record),
      exportRender: (record)=>cityManageCompany(record.manageEnterprise),
    },
    {
      title: '访问状态',
      dataIndex: 'accessStatus',
      key: 'accessStatus',
      width: 160,
      onExport: true,
      render: (record)=>accessStatus(record),
      exportRender: (record)=>accessStatus(record.accessStatus),
    },
    {
      title: '进入时间',
      dataIndex: 'startTime',
      key: 'startTime',
      sorterKey: 'startTime1',
      // fixed: 'right',
      width: 200,
      sorter: true,
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
      exportRender: (record) =>timeFormat(record.startTime),
    },
    {
      title: '离开时间',
      dataIndex: 'endTime',
      key: 'endTime',
      sorterKey: 'endTime1',
      // fixed: 'right',
      width: 200,
      sorter: true,
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
      exportRender: (record) =>timeFormat(record.endTime),
    },
  ];
  const Tab = [
    <div key={'1'}>
      <CustomTable
        toIndex={true}
        InitApi={GETONLINELOGLIST}
        scroll={true}
        formList={operationPersonnelFormList}
        changeListForTabKey={{queryType: tab+1}}
        hidePageTitle={true}
        resetData={updateList}
        columns={operationPersonnelColumns}
        tip="运维人员数据导出中..."
        exportFileName="运维人员数据"
      />
    </div>,
    <div key="2" className="data-tab">
      <CustomTable
        toIndex={true}
        InitApi={GETONLINELOGLIST}
        scroll={true}
        formList={cityManagerFormList}
        changeListForTabKey={{queryType: tab+1}}
        hidePageTitle={true}
        resetData={updateList}
        columns={cityManageColumns}
        tip="城市管理者数据导出中..."
        exportFileName="城市管理者数据"
      />
    </div>,
  ];
  return (
    <>
      {!error ? (
        <div>
          <div>
            <SubTitle
              title="用户访问记录"
              tab={['运维人员', '城市管理者']}
              onChangeTab={(e) => {
                setTab(Number(e));
                setUpdateList(updateList+1);
              }}
            />
            {Tab[tab]}
          </div>
        </div>
      ) : (
        <div className="no-data">
          <img src={noData}></img>
        </div>
      )}
    </>
  );
};

export default UserAccessRecord;
