import {Button, Tabs, Dropdown, Menu} from 'antd';
import {React, useState} from 'react';
import PropTypes from 'prop-types';

// 这部分是常用的subtitle组件 是依据了Tab的布局格式
// title:页面标题
// buttonName: 是否有右上角的按钮
// Tab名称 => 包含三个字符串的数组
// onShow:点击按钮触发的函数
const SubTitle = ({title, buttonName, tab, onChangeTab, onShow, exportBtn, onExport}) => {
  const [tabsKey, setTabsKey] = useState(2);
  return (
    <div>
      <div className="statement-title">
        <h2>{title}</h2>
        <div>
          {buttonName && (
            <Button type="primary" onClick={() => onShow()}>
              {buttonName}
            </Button>
          )}
          {exportBtn && (
            <Dropdown
              trigger={['click']}
              arrow
              overlay={
                <Menu>
                  <Menu.Item key="profile">
                    <Button type="text" onClick={() => onExport(1)}>
                      下载日报Word文件
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="setting">
                    <Button type="text" onClick={() => onExport(2)}>
                      下载周报Word文件
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="changePwd">
                    <Button type="text" onClick={() => onExport(3)}>
                      下载月报Word文件
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary" className="margin-l-m">
                {exportBtn}
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="statement-Tabs">
        <Tabs
          defaultActiveKey={tabsKey}
          onChange={(key) => {
            onChangeTab(key);
            setTabsKey(key);
          }}
        >
          {tab.map((item, index)=>{
            return <Tabs.TabPane tab={item} key={index}></Tabs.TabPane>;
          })}
        </Tabs>
      </div>
    </div>
  );
};
SubTitle.propTypes = {
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  tab: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  exportBtn: PropTypes.string,
  onExport: PropTypes.func,
};

export default SubTitle;
