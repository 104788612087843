import React, {useEffect, useState} from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {Spin} from 'antd';
import {getLoginTreeMenu} from '../../api/index';
import {storage} from '@/utils/storage';

const contentStyle = {
  height: '100vh',
};
const content = <div style={contentStyle} />;
const Login = () => {
  const {token} = useParams();
  // console.log(token);
  const ng = useNavigate();
  const [loading]=useState(true);
  useEffect(() => {
    storage.saveData('local', 'userInfo', {token});
    getLoginTreeMenu().then((res) => {
      console.log(res);
      storage.saveData('local', 'userInfo', res.data);
      // setLoading(false);
      // setTimeout(()=>ng('/home'), 500);
      setTimeout(()=>ng('/bigScreen/cockpitScreen'), 500)
    });
    // setTimeout(() => {
    //   // storage.saveData('local', 'userInfo', res.data);
    //   // ng('/home');
    //   setLoading(false);
    // }, 500);
  }, []);

  return (
    <Spin spinning={loading} tip="Loading" size="large">
      {content}
    </Spin>
  );
};


Login.propTypes = {
};

export default Login;
