import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import Statistics from './components/StatisticsData';
import EchartsSunburst from './components/ColumnChart';
import EchartsLine from './components/LineChart';
import {Button, Col, Row} from 'antd';

import {connect} from 'react-redux';
import {side} from '../../../store/actions';

import {getScreenQueryMap, getLineEcharts, getPieEcharts, getStatisticEcharts, getSecurityAreaData} from '../../../api';

import './style.css';

import titleIcon from './../../../asset/title-icon.png';
import ScreenMap from './components/ScreenMap';
import {bikeCompany} from '../../../components/statusCollection';
import {bikeManageData, isMockData, looseEqual} from '../../../utils/judge';
import {staffMockData} from '../mockData';


// bigMap 0 - All show
// bigMap 1 - Only click menu header disappear button
// bigMap 2 - All click
// bigMap 3 - Only click charts disappear button
let bigMap = 0;
// base refreshTime
const refreshTime = 3 * 60 * 1000;
// 获取是否开发环境
const isMock = false;
// const isMock = false||process.env.NODE_ENV === 'development';

const BikeManager =({collapsed, setCollapsedMenus, queryType})=>{
  const totalTimer = useRef();
  const [fold, setFold]=useState(false);
  const [screenCode, setScreenCode]= useState(0);
  const [mapData, setMapData]=useState({
    operationInfoVOList: [],
    markRangeList: [],
  });
  const [peopleCount, setPeopleCount] = useState(null);
  const [carCount, setCarCount] = useState(null);
  const [guaranteeCount, setGuaranteeCount] = useState(null);
  const [guaranteeData, setGuaranteeData] = useState(null);
  const isChangeData=isMockData();
  // const [isKeyArea, setIsKeyArea] = useState(false);
  // 饼图所有企业数据
  const [columnData, setColumnData] = useState([]);
  const [lineData, setLineData] = useState([]);
  // 地图中心点变更
  const [center, setCenter] = useState(null);

  // style name for the screen
  const mapTitle='big-screen-header text-right'+(queryType === 2?' e-bike-header':'');


  const StaffPieChartRender=useMemo(()=>(<EchartsSunburst
    data={columnData}
    queryType={queryType}
    isKeyArea={guaranteeData?.isKeyArea||null}
    areaName={guaranteeData?.areaName||null}
    areaId={guaranteeData?.areaId||null}
  />), [columnData, guaranteeData]);
  const StaffLineChartRender=useMemo(()=>(<EchartsLine
    data={lineData}
    bigMap={bigMap}
    queryType={queryType}
    areaName={guaranteeData?.areaName||null}
    isKeyArea={guaranteeData?.isKeyArea||null}
    areaId={guaranteeData?.areaId||null}
  />), [lineData, guaranteeData]);
    // 点击保障区域
  const onAreaClick=(value)=>{
    // 保障区域
    setGuaranteeData({
      areaId: value?.id,
      areaName: value?.guaranteeName,
      isKeyArea: value?.type===2,
    }||null);
    // setIsKeyArea(value?.type!==2||false);
  };
  // 全屏初始化
  const onFoldInit=()=>{
    setCollapsedMenus(false);
    bigMap = 0;
    setScreenCode(0);
    setFold(false);
  };

  // 全屏功能
  const onFoldCharts=()=>{
    setFold(!fold);
    if (bigMap===0) {
      setCollapsedMenus(true);
      setScreenCode(3);
      bigMap=3;
    } else if (bigMap===1) {
      bigMap=2;
      setScreenCode(2);
    } else if (bigMap===2) {
      bigMap=1;
      setScreenCode(1);
    } else if (bigMap===3) {
      setCollapsedMenus(false);
      bigMap=0;
      setScreenCode(0);
    }
  };
  // 统计数据
  const getStatisticsData = () => {
    // if (isMockData()) {
    const data=bikeManageData();
    //   setPeopleCount(data.peopleCount);
    //   setCarCount(data.carCount);
    // } else {
    getStatisticEcharts({type: queryType}, 2)
        .then((res) => {
          if (res.data) {
            res.data.map((item)=>{
              if (item.jobType == 1 || item.jobType == 5) {
                setPeopleCount(isChangeData?{
                  ...item,
                  ...data.people,
                }:{
                  ...item,

                });
              } else {
                setCarCount(isChangeData?{
                  ...item,
                  ...data.car,
                }:{
                  ...item,
                });
              }
            });
          }
        });
    // }
    getSecurityAreaData(queryType, 2).then((res)=>{
      setGuaranteeCount(res.data);
    });
  };
  // 柱状图数据
  const getPieChartData = () => {
    getPieEcharts({type: queryType}, 2)
        .then((res) => {
          const list=[];

          const mockData=bikeManageData();

          res.data.forEach((item)=>{
            if (isChangeData) {
              const data=mockData.companyList.find(({company})=>company===item.company);
              list.push({
                name: bikeCompany(item.company),
                type: queryType===1?'单车人员在线':'电单车人员在线',
                value: data.peopleOnline,
                total: item.peopleCount,
              });
              list.push({
                name: bikeCompany(item.company),
                type: queryType===1?'单车调运车辆在线':'电单车调运车辆在线',
                value: data.carOnline,
                total: item.carCount,
              });
            } else {
              list.push({
                name: bikeCompany(item.company),
                type: queryType===1?'单车人员在线':'电单车人员在线',
                value: item.peopleOnline,
                total: item.peopleCount,
              });
              list.push({
                name: bikeCompany(item.company),
                type: queryType===1?'单车调运车辆在线':'电单车调运车辆在线',
                value: item.carOnline,
                total: item.carCount,
              });
            }
          });

          if (!looseEqual(columnData, list)) {
            setColumnData(list);
          }
        });
  };
  // 折线图数据
  const getLineChartData = () => {
    getLineEcharts(queryType, {}, 2)
        .then((res) => {
          const list =[];
          res.data.chart.forEach((item)=>{
            list.push({
              dateTime: item.date,
              value: item.car,
              name: queryType == 1 ? '单车调运车辆':'电单车调运车辆',
            });
            list.push({
              dateTime: item.date,
              value: item.people,
              name: queryType == 1 ? '单车人员':'电单车人员',
            });
          });
          if (!looseEqual(list, lineData)) {
            setLineData(list);
          }
        });
  };


  // 地图筛选
  const onSearch = ( values, runTimer) => {
    const data=values||{};
    getScreenQueryMap({
      ...data,
      queryType: queryType,
    }).then((res)=>{
      const data=isMock?staffMockData():res.data;
      if (values&&values.type&&values.content) {
        if (values.type!==4&&values.type!==6) {
          if (res.data.extData.length>0) {
            setCenter(res.data.extData[0].position);
          }
        } else if (values.type===4) {
          if (res.data.markRangeList.length>0) {
            setCenter(JSON.parse(res.data.markRangeList[0].markRange)[0]);
          }
        } else {
          if (res.data.emphasisMarkRangeList.length>0) {
            setCenter(JSON.parse(res.data.emphasisMarkRangeList[0].markRange)[0]);
          }
        }
      }
      setMapData(isMock?{...data,
        'markRangeList': [
          {
            'guaranteeName': 'mock保障区域重点开始',
            'guaranteeStatus': '1',
            'id': 243,
            'markRange': '[[104.071869,30.708407],[104.03903,30.675868],[104.081899,30.667326]]',
            'startTime': '2023-06-21 16:47',
            'type': 2,
          },
          {
            'guaranteeName': 'mock保障区域普通开始',
            'guaranteeStatus': '1',
            'id': 242,
            'markRange': '[[104.038653,30.693647],[104.042564,30.648075],[103.989576,30.645958]]',
            'startTime': '2023-06-2 16:47',
            'type': 1,
          },
          {
            'guaranteeName': 'mock保障区域重点未开始',
            'guaranteeStatus': '0',
            'id': 239,
            'markRange': '[[104.271869,30.708407],[104.23903,30.675868],[104.281899,30.667326]]',
            'startTime': '2023-06-29 16:47',
            'type': 2,
          },
          {
            'guaranteeName': 'mock保障区域普通未开始',
            'guaranteeStatus': '0',
            'id': 241,
            'markRange': '[[104.058622,30.691524],'+
            '[104.043164,30.696805],[104.053116,30.700264],[104.06328,30.699172],' +
            '[104.06561,30.693891],[104.064127,30.692981],[104.061586,30.688246]]',
            'startTime': '2023-06-29 16:47',
            'type': 1,
          },
        ],
      }:{
        ...data,
        markRangeList: [
          ...data.markRangeList,
          ...data.emphasisMarkRangeList,
        ],
      });
    });

    // 点击筛选时
    if (!runTimer) {
      // 初始化定时器
      initTimer(values);
    }
  };

  // 线程初始化
  const initTimer= (filters) => {
    clearInterval(totalTimer.current);
    getStatisticsData();
    getPieChartData();
    getLineChartData();
    if (!filters) {
      onSearch(null, true);
    }
    totalTimer.current = setInterval(() => {
      getStatisticsData();
      getPieChartData();
      getLineChartData();
      setGuaranteeData(null);
      onSearch(filters, true);
    }, refreshTime);
  };

  // 大屏初始化
  const initMap=()=>{
    // 初始化线程
    initTimer(null);
  };


  // useEffect(() => {

  //   // asyncFetch2();
  // }, [queryType]);

  useEffect(() => {
    localStorage.setItem('change', true);
    localStorage.setItem('select', 1);
    initMap();
    return ()=>{
      clearInterval(totalTimer.current);
    };
  }, [queryType]);


  useEffect(()=>{
    window.addEventListener('keydown', (e)=>{
      if (e.keyCode===27) {
        // bigMap 0 - All show
        // bigMap 1 - Only click menu header disappear button
        // bigMap 2 - All click
        // bigMap 3 - Only click charts disappear button
        switch (bigMap) {
          case 1: {
            setCollapsedMenus(false);
            bigMap = 0;
            setScreenCode(0);
            return null;
          }
          case 2: {
            setFold(false);
            bigMap = 1;
            setScreenCode(1);
            return null;
          }
          case 3: {
            setFold(false);
            setCollapsedMenus(false);
            bigMap=0;
            setScreenCode(0);
            return null;
          }
          default: {
            return null;
          }
        }
      }
    });
    return onFoldInit;
  }, []);
  return (
    <div className={screenCode===1? 
      'middle-screen':screenCode===0?
       `min-screen ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-pad':''}`:'big-screen'} 
      //  
      style={{position:'relative'}}>
      {collapsed&&<div className={!fold? mapTitle:'full '+ mapTitle}>
        {!fold&&<Button type="link"
          className="big-screen-btn"
          onClick={()=>{
            if (bigMap===0) {
              bigMap=1;
              setScreenCode(1);
              setCollapsedMenus(true);
            } else if (bigMap===1) {
              bigMap=0;
              setScreenCode(0);
              setCollapsedMenus(false);
            }
          }}>退出页面全屏</Button>}
      </div>}
      <Row 
        gutter={[16, 16]} 
        className={location.pathname.indexOf('/bigScreen') !== -1 && bigMap===0? 'screen-show-h':''}
      >
        <Col 
          className={location.pathname.indexOf('/bigScreen') !== -1 && bigMap===0? 'screen-show-h':''} 
          xs={fold?0:bigMap===1?10:12} xl={fold?0:bigMap===1?10:12} xxl={fold?0:bigMap===1?9:10}
        >
          <Row gutter={[24, 8]} className={location.pathname.indexOf('/bigScreen') !== -1 && bigMap===0? 'screen-show-h':''} >
            <Col span={24} >
              <Statistics
                queryType={queryType}
                carCount={carCount}
                peopleCount={peopleCount}
                guaranteeCount={guaranteeCount}
              />
            </Col>
            <Col span={24} className="padding-r-24">
              {StaffPieChartRender}
            </Col>
            <Col span={24} className="padding-r-24">
              {StaffLineChartRender}
            </Col>
          </Row>
        </Col>
        <Col
          xs={fold?24:bigMap===1?14:12}
          xl={fold?24:bigMap===1?14:12}
          xxl={fold?24:bigMap===1?15:14}
          className={`map-container ${location.pathname.indexOf('/bigScreen') !== -1 && bigMap===0? 'screen-show-h screen-show-video':''}`}
        >
          {!fold&&<div className="map-header">
            <Row>
              <Col span={12}>
                <img src={titleIcon} alt="title" className="margin-r-s margin-l-s"/>
                运维人员位置分布
              </Col>
              <Col span={12} className="text-right">
                {!fold&&!collapsed&&<Button type="link" onClick={()=>{
                  if (bigMap===0) {
                    bigMap=1;
                    setScreenCode(1);
                    setCollapsedMenus(true);
                  } else if (bigMap===1) {
                    bigMap=0;
                    setScreenCode(0);
                    setCollapsedMenus(false);
                  }
                }}>页面全屏</Button>}
              </Col>
            </Row>

          </div>}
          <ScreenMap
            onFilterFunc={onSearch}
            initTimer={initTimer}
            fold={fold}
            staffData={mapData}
            queryType={queryType}
            onFoldCharts={onFoldCharts}
            bigMap={bigMap}
            onAreaClick={onAreaClick}
            searchCenter={center}
          />
        </Col>
      </Row>
      <div>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.collapsedMenus.collapsed,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setCollapsedMenus: (f)=>dispatch(side(f)),

  };
};
BikeManager.propTypes={
  collapsed: PropTypes.bool,
  queryType: PropTypes.number.isRequired,
  setCollapsedMenus: PropTypes.func.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BikeManager);
