const microApps = [
  {
    name: 'react',
    entry: '//localhost:3015/',
    activeRule: '/react',
    container: '#subapp-viewport',
  },
];

const apps = microApps.map((item) => {
  return {
    ...item,
    props: {
      base: item.activeRule,
      // routerBase: item.activeRule,
      getGlobalState: {
        name: 'test',
      },
    },
  };
});

export default apps;
