import React, {useEffect, useState} from 'react';
import {Anchor, Card, Col, Divider, Row, Skeleton} from 'antd';
import OffLine from './components/OffLine';
import WorkOrder from './components/WorkOrder';
import KeyAreaSetting from './components/KeyAreaSetting';
import CarRelativeSetting from './components/CarRelativeSetting';
import AppSettingSwitch from './components/AppSettingSwitch';
import PlatFormSwitch from './components/PlatFormSwitch';
// import PersonneSetting from './components/PersonneSetting';
import PersonneSettingNew from './components/PersonneSetting_new';
import {
  getRound, getOrderExpireTime,
  // getRequiredNumber,
  appAndWebPlatformStatus,
  getAreaRate,
} from '../../api';
import './style.css';

const System = () => {
  const [systemInfo, setSystemInfo] = useState();
  const [orderInfo, setOrderInfo] = useState();
  // const [personneInfo, setPersonneInfo] = useState([]);
  const [open, setOpen] = useState('');// 切换
  const [redact, setRedact] = useState('');// 编辑状态切换
  const [loading, setLoading] = useState(true);
  const [update, setUpDate] = useState(0);
  const [switchStatus, setSwitchStatus] = useState({});
  const [areaRate, setAreaRate] = useState({});


  // 获取设备离线规则
  const getDetails = () => {
    getRound(2).then((resp) => {
      setSystemInfo(resp.data.heartBeatRound);
    }).catch(() => {
      setSystemInfo(0);
      setLoading(false);
    });
  };
  // 获取工单过期时间
  const orderTime = () => {
    getOrderExpireTime(2).then((resp) => {
      setOrderInfo(resp.data?.workOrderExpireTime);
    }).catch(() => {
      setOrderInfo(10);
      setLoading(false);
    });
  };
  // 获取车企应配人数
  const requiredNumber = () => {
    // getRequiredNumber(2).then((resp) => {
    //   setPersonneInfo(resp.data?.cacheEnterpriseRequiredNumber);
    // }).catch(() => {
    // setPersonneInfo([]);
    // setLoading(false);
    // });
  };
  // 获取平台开关状态
  const getPlatFormSwitch = () => {
    appAndWebPlatformStatus(2).then((resp) => {
      setSwitchStatus(resp.data);
      // console.log(switchStatus);
    }).catch(() => {
      // setPersonneInfo({});
      setLoading(false);
    });
  };
  // 获取车辆管理区域设置
  const getCarAreaSetting = () => {
    getAreaRate(2).then((resp) => {
      setAreaRate(resp.data);
      // console.log(resp.data, 'car车辆管理resp.data');
    }).catch(() => {
      // setPersonneInfo({});
      setLoading(false);
    });
  };

  useEffect(() => {
    getDetails();
    // requiredNumber();
    orderTime();
    getPlatFormSwitch();
    getCarAreaSetting();
  }, []);

  useEffect(() => {
    // && personneInfo.length
    if ((systemInfo || systemInfo === 0) && orderInfo ) {
      setLoading(false);
    }
  }, [systemInfo, orderInfo]);

  useEffect(() => {
    switch (open) {
      case 'off':
        getDetails();
        break;
      case 'order':
        orderTime();
        getPlatFormSwitch();
        getCarAreaSetting();
        break;
      case 'personne':
        requiredNumber();
        break;
      case 'personneNew':
        break;
      case 'car':
        getCarAreaSetting();
        break;
      case 'keyRules':
        getPlatFormSwitch();
        break;
      case 'keySwicth':
        getPlatFormSwitch();
        break;
      case 'app':
        getPlatFormSwitch();
        break;
      default:
        break;
    }
  }, [update]);


  return (
    <div >
      <h2>系统设置</h2>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s margin-b-l margin-t-m">
        <Row>
          <Col flex="auto" className="padding-r-l" span={23}>
            <div id="base-info" className="off-line-bg offs">
              {loading ? (
                <Skeleton active />
              ) : (
                <OffLine
                  systemInfo={systemInfo}
                  onFinished={() => setUpDate(update + 1)}
                  open={open}
                  redact={redact}
                  setRedact={(e) => setRedact(e)}
                  changeOpen={() => setOpen('off')}
                />
              )}
            </div>
            <Divider />
            <div id="work-order" className="off-line-bg">
              {loading ? (
                <Skeleton active />
              ) : (
                <WorkOrder
                  orderInfo={orderInfo}
                  onFinished={() => setUpDate(update + 1)}
                  redact={redact}
                  setRedact={(e) => setRedact(e)}
                  open={open}
                  changeOpen={() => setOpen('order')}
                />
              )}
            </div>
            <Divider />
            <div id="personne-setting" className="off-line-bg">
              {loading ? (
                <Skeleton active />
              ) : (
                // <PersonneSetting
                //   personneInfo={personneInfo}
                //   onFinished={() => setUpDate(update + 1)}
                //   open={open}
                //   changeOpen={() => {
                //     setOpen('personne');
                //   }}
                //   redact={redact}
                //   setRedact={(e) => setRedact(e)}
                // />
                  <PersonneSettingNew open={open } changeOpen={()=>setOpen('personneNew')} />
              )}
            </div>
            {<>
              <Divider />
              <div id="car-setting" className="off-line-bg">
                {loading ? (
                  <Skeleton active />
                ) : (
                  <CarRelativeSetting
                    orderInfo={areaRate}
                    onFinished={() => setUpDate(update + 1)}
                    redact={redact}
                    setRedact={(e) => setRedact(e)}
                    open={open}
                    changeOpen={() => setOpen('car')}
                  />
                )}
              </div>
            </>}
            <Divider />
            <div id="work-order-key" className="off-line-bg">
              {loading ? (
                <Skeleton active />
              ) : (<>
                <KeyAreaSetting
                  orderInfo={switchStatus?.rounds}
                  onFinished={() => setUpDate(update + 1)}
                  redact={redact}
                  setRedact={(e) => setRedact(e)}
                  open={open}
                  changeOpen={() => setOpen('keyRules')}
                />
                <Divider/>
                <PlatFormSwitch
                  orderInfo={switchStatus}
                  onFinished={() => setUpDate(update + 1)}
                  redact={redact}
                  setRedact={(e) => setRedact(e)}
                  open={open}
                  changeOpen={() => setOpen('keySwicth')}
                />
                <Divider/>
                <AppSettingSwitch
                  orderInfo={switchStatus}
                  onFinished={() => setUpDate(update + 1)}
                  redact={redact}
                  setRedact={(e) => setRedact(e)}
                  open={open}
                  changeOpen={() => setOpen('app')}
                />
              </>)}
            </div>
          </Col>
        </Row>
      </Card>

      <Anchor className="anchor-other">
        <Anchor.Link href="#base-info" title="设备设置" />
        <Anchor.Link href="#work-order" title="工单设置" />
        <Anchor.Link href="#personne-setting" title="人员设置" />
        <Anchor.Link href="#car-setting" title="车辆设置" />
        <Anchor.Link href="#work-order-key" title="保障设置" />
      </Anchor>
    </div>
  );
};

System.propTypes = {};

export default System;
