import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Row, Col, message} from 'antd';
import {TRTCMsg} from './IMMessage';
import {createIMChat, getLatestUserSig} from './api/index';
import {storage} from '../../utils/storage';
import {connect} from 'react-redux';
import {getUserSig} from '../../api';
import {updateMessageList, initMessageList} from '../../store/actions';
import TRTC from 'trtc-js-sdk';
import TIM from 'tim-js-sdk';
import './style.less';
import {CloseOutlined} from '@ant-design/icons';
import ringing from './ringing.mp3';
import moment from 'moment';

let networkError=0;
let client;
let localStream;
let tim;
// 通话时间
let chatTime=0;
let timeCounts=0;
// 房间号
let roomId;

// 0 无通话： 通话结束或拨号等待结束
// 1 拨打中
// 2 通话中
let TRTCStatus=0;
// /let messages;
const ringAudio=new Audio(ringing);

let duringTRTCTime;
let callingWaitingTime;

const BasicTIM=({
  updateMsg,
  messageList,
  initMsg,
  userId,
  onFinished,
  chartName,
  chartCompany,
  TRTCModal,
  queryType,
  seeChat})=> {
  const userName=storage.getData( 'userInfo')?.userId + 'admin';
  const userNickName=storage.getData( 'userInfo')?.nickName;
  // ios旋转 0 原始 1 左转 2 右转
  const [rotate, setRotate]= useState(0);
  // const userId=storage.getData( 'userInfo').userId;
  const [imForm]= Form.useForm();
  // 是否打开聊天框
  const [onChart, setOnChart]= useState(false);
  const [isNewMsg, setIsNewMsg]= useState(false);
  // 0 无通话： 通话结束或拨号等待结束
  // 1 拨打中
  // 2 通话中
  const [chatStatus, setChatStatus] = useState(0);
  const [callingTime, setCallingTime] = useState(0);

  // 通话质量
  const [uplinkNetworkQuality, setUplinkNetworkQuality] = useState(0);
  const [downlinkNetworkQuality, setDownlinkNetworkQuality] = useState(0);

  // 静音
  const [isMute, setIsMute] = useState(false);

  // init Client 拨通视频时 初始化
  const initClient=(userSig) => {
    client= TRTC.createClient(getLatestUserSig(userNickName, userSig));
    client.on('stream-subscribed', (event) => {
      const remoteStream = event.stream;
      // 远端流订阅成功，播放远端音视频流
      remoteStream.play('remote-stream', {
        objectFit: 'contain',
      });
    });
    // 监听远端流增加事件
    client.on('stream-added', (event) => {
      ringAudio.pause();
      const remoteStream = event.stream;
      // 订阅远端音频和视频流
      client.subscribe(remoteStream, {
        audio: true,
        video: true,
      }).catch((e) => {
        console.error('failed to subscribe remoteStream');
      });
      remoteStream.on('error', (error) => {
        const errorCode = error.getCode();
        if (errorCode === 0x4043) {
          // Autoplay restrictions. Display a window for users to click to trigger
          //  the callback function and call the `stream.resume`
          // API in the callback function to resume audio/video playback
          // Reference: https://web.sdk.qcloud.com/trtc/webrtc/doc/en/module-ErrorCode.html#.PLAY_NOT_ALLOWED
        } else if (errorCode === 0x4044) {
          // Failed to automatically resume after the media device is unplugged.
          //  Reference: https://web.sdk.qcloud.com/trtc/webrtc/doc/en/module-ErrorCode.html#.DEVICE_AUTO_RECOVER_FAILED
        }
      });
      TRTCStatus=2;
      setChatStatus(2);
      clearTimeout(callingWaitingTime);
      duringTRTCTime=setInterval(()=>{
        // if (chatTime===0) {
        //   chatTime=moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf();
        // }
        timeCounts+=1;
        chatTime+=1000;
        setCallingTime(chatTime);
      }, 1000);
    });
    client.on('stream-removed', (event) => {
      client.leave().then(() => {
        leaveRTC('error', 'TRTCBroke');
        sendCustomMsg(userId, 'networkError');
      }).catch((error) => {
        console.error('leaving room failed: ' + error);
      });
    });
    // 网络质量监听
    client.on('network-quality', (event) => {
      if (event.uplinkNetworkQuality===6&&event.downlinkNetworkQuality===6) {
        networkError++;

        if (networkError>4&&(TRTCStatus!==0|| chatStatus!==0||TRTCModal!==0)) {
          leaveRTC('error', 'networkError');
        }
      } else {
        if (!networkError) {
          networkError=0;
        }
      }
      setDownlinkNetworkQuality(event.downlinkNetworkQuality);
      setUplinkNetworkQuality(event.uplinkNetworkQuality);
    });
    // 错误
    client.on('error', (error) => {
      console.error('client error observed: ' + error);
      const errorCode = error.getCode();
      console.log('client error: ' + errorCode);
      leaveRTC('error', 'clientError');
      // 请根据错误码列表(https://cloud.tencent.com/document/product/647/34342)查看错误
      // 当出现客户端错误后，请调用 Client.leave() 退房并尝试通过 Client.join() 重新进房恢复通话。
    });
  };

  // 呼叫对方
  const joinRTC=(userId, roomId)=>{
    // parseInt(userId.toString()+ moment().getV())

    if (TRTCModal===0) {
      return null;
    }
    client.join({roomId}).then(async () => {
      localStream = TRTC.createStream({userId: userNickName, audio: true, video: false});
      // localStream.setVideoProfile('480p');
      localStream.setAudioProfile('high');
      await localStream.initialize();
      await client.publish(localStream);
      try {
        await localStream.setVideoProfile('480p');
      } catch (error) {
        if (error.name === 'OverconstrainedError') {
          console.error('当前摄像头不支持该 profile');
          // 设置失败，当前摄像头已停止采集，需要恢复采集
          const stream = TRTC.createStream({video: false, audio: true});
          await stream.initialize();
          localStream.replaceTrack(stream.getVideoTrack());
        } else {
          console.error('当前浏览器不支持动态调用该接口');
        }
      }
      localStream.play('local-stream');
      // setIsInitial(false);
      // 推流后更新码率
      // client.publish(localStream).then(() => {
      //   localStream.setVideoProfile({bitrate: 1500});
      // });
      // 获取实际采集的分辨率和帧率
      // const videoTrack = localStream.getVideoTrack();
      // if (videoTrack) {
      //   const settings = videoTrack.getSettings();
      //   console.log(`分辨率：${settings.width} * ${settings.height}, 帧率：${settings.frameRate}`);
      // }
      // setIsInitial(false);
      // 拨号等待60S
      callingWaitingTime=setTimeout(()=>{
        // 如果60s没应答，表示呼叫失败
        // 拨号等待中
        if (TRTCStatus===1||chatStatus===1) {
          TRTCStatus=0;
          setChatStatus(0);
          ringAudio.pause();
          const messageCancel = tim.createCustomMessage({
            to: userId,
            conversationType: 'C2C',
            payload: {
              data: 'timeout',
              description: roomId,
              extension: roomId,
            },
          });
          const promiseCancel = tim.sendMessage(messageCancel);
          promiseCancel.then(()=>{
            leaveRTC('error', 'missedCall');
          }).catch((imError) =>{
            // 发送失败
            console.warn('sendMessage error:', imError);
          });
        } else {
          return clearTimeout(callingWaitingTime);
        }
      },
      60*1000);
    }).catch((error)=>{
      console.log(error);
      // setIsInitial(false);
    });

    // setIsInitial(false);
  };

  // 视频中断、挂断、停止呼叫
  const leaveRTC=(type, msg)=>{
    // if (type==='success') {
    //   message.success(TRTCMsg[type][msg]);
    // } else if (type=='error') {
    //   message.error(TRTCMsg[type][msg]);
    // } else if (type==='warning') {
    //   message.warning(TRTCMsg[type][msg]);
    // }
    message[type]?.(TRTCMsg[type]?.[msg] ?? '');
    initStatus();
  };

  // 接受到消息时
  const updateMessageList=(msg, type)=>{
    updateMsg({
      message: {text: msg},
      type: type,
    });

    setIsNewMsg(true);
  };
  // 接受到自定义消息时
  const trtcResult=(value)=>{
    // console.log(value, TRTCModal, chatStatus, TRTCStatus);
    if (TRTCModal!==0||TRTCStatus!==0) {
      switch (value.data) {
      // app主动拒绝
        case 'reject':
          return leaveRTC('error', 'refuseInvitation');
        case 'other reject':
          return leaveRTC('error', 'accessError');
          // 网络断开
        case 'networkError':
          return leaveRTC('error', 'networkError');
          // app通话中：
        case 'on call':
          return leaveRTC('warning', 'otherDuringTRTC');
          // app自动挂断情况:
        case 'other hang up':
          return leaveRTC('error', 'TRTCBroke');
        case 'rotate90':
          return setRotate(1);
        case 'rotate270':
          return setRotate(2);
        case 'rotate0':
          return setRotate(0);
        default:
          return null;
      }
    }
  };
  // 发送邀请消息
  const sendTRTCInvite=(userId)=>{
    // 播放铃声
    ringAudio.play();
    roomId=userName+userId+ new Date().getTime().toString();
    const msg = tim.createCustomMessage({
      to: userId,
      conversationType: 'C2C',
      payload: {
        data: roomId,
        description: '房间号',
        extension: new Date().getTime().toString(),
      },
    });
    const promise = tim.sendMessage(msg);
    promise.then((imResponse)=>{
      // 发送成功
      TRTCStatus=1;
      setChatStatus(1);
      // setIsInitial(true);
      // 接入trtc
      joinRTC(userId, roomId);
    }).catch((imError) =>{
      // 发送失败
      console.log(imError?.code);
      setTimeout(()=>{
        leaveRTC('error', 'invalidError');
      }, 1000);

      console.log('sendMessage error:', imError);
    });
  };
  // 发送自定义消息
  const sendCustomMsg=(userId, type)=>{
    const message = tim.createCustomMessage({
      to: userId,
      conversationType: 'C2C',
      payload: {
        data: type,
        description: roomId,
        extension: roomId,
      },
    });
    const promise = tim.sendMessage(message);
    promise.then((imResponse)=>{

    }).catch((imError) =>{
      // 发送失败
      console.warn('sendMessage error:', imError);
    });
  };

  // 发送文本消息
  const sendMessage=(values)=>{
    const message = tim.createTextMessage({
      to: userId,
      conversationType: 'C2C',
      payload: {
        text: values.content,
      },
    });
    updateMessageList(values.content, 'send');

    const promise = tim.sendMessage(message);
    promise.then(function(imResponse) {
      // 发送成功
      imForm.resetFields();
    }).catch(function(imError) {
      // 发送失败
      console.warn('sendMessage error:', imError);
    });
  };

  // 初始化IM服务
  const initSever=()=>{
    tim=TIM.create(createIMChat());
    tim.setLogLevel(4);
    // 设置 SDK 日志输出级别，详细分级请参见
    //  <a href="https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel">setLogLevel 接口的说明</a>

    getUserSig(encodeURIComponent(userNickName)).then((res)=>{
      const userSig=res.data;
      startChat(userSig);
      initClient(userSig);
    }).catch((error)=>{
      console.log('getUserSig error');
    });
  };
  // 初始化IM
  const startChat=(userSig)=>{
    tim.login({userSig, userID: userNickName}).then((imResponse)=> {
      window.IMSever=true;
      // console.log(imResponse.data); // 登录成功
      if (imResponse.data.repeatLogin === true) {
        const onSdkReady = (event)=> {
          // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
          // const message = tim.createTextMessage({
          //   to: 'xsx',
          //   conversationType: 'C2C',
          //   payload: {text: 'Hello world!'},
          // });
          // tim.sendMessage(message);
        };
        tim.on(TIM.EVENT.SDK_READY, onSdkReady);
      }
      const onMessageReceived =(event)=> {
        // event.data - 存储 Message 对象的数组 - [Message]
        const msgList = event.data;
        msgList.forEach((message) => {
          if (message.type === TIM.TYPES.MSG_TEXT) {
            // console.log(message);
            updateMessageList(message.payload.text, 'receive');
          } else if (message.type === TIM.TYPES.MSG_CUSTOM) {
            // 自定义消息
            // console.log(message.payload);
            trtcResult(message.payload);
          }
        });
      };
      tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
      // 网络发生变化
      tim.on(TIM.EVENT.NET_STATE_CHANGE, (event)=>{
        if (event.data.state===TIM.TYPES.NET_STATE_DISCONNECTED) {
          if (TRTCStatus===1|| chatStatus===1||TRTCModal===1) {
            leaveRTC('error', 'networkError');
          }
          return window.IMSever=false;
        }
        if (event.data.state===TIM.TYPES.NET_STATE_CONNECTED) {
          return window.IMSever=true;
        }
      });
    }).catch((imError)=> {
      console.warn('login error:', imError); // 登录失败的相关信息
      console.log(imError?.code);
      window.IMSever=false;
    });
  };

  // 离开大屏页面
  const endChat=()=>{
    // 视频通话中切换
    const onMessageReceived = function(event) {
      // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
      // event.name - TIM.EVENT.MESSAGE_RECEIVED
      // event.data - 存储 Message 对象的数组 - [Message]
    };
    tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    tim.destroy().then(() => {
      console.log('sdk destroyed');
    }).catch((err) => {
      console.log(err);
    });
    const promise = tim.logout();

    promise.then(function(imResponse) {
      console.log(imResponse.data); // 登出成功
      tim=null;
    }).catch(function(imError) {
      console.warn('logout error:', imError);
      tim=null;
    });
  };

  // 当通话挂断后初始化参数
  const initStatus=()=>{
    // 铃声
    ringAudio.pause();
    // 关闭通话框
    onFinished();
    // 关闭初始化
    // setIsInitial(false);
    // 通话状态
    TRTCStatus=0;
    setChatStatus(0);
    setIsMute(false);
    // 画面旋转初始化
    setRotate(0);
    // 聊天框
    setOnChart(false);
    setIsNewMsg(false);
    initMsg();
    imForm.resetFields();

    // 通话时间
    if (duringTRTCTime) {
      clearInterval(duringTRTCTime);
      duringTRTCTime=null;
      // 本地视频流
      client.leave().then(() => {
        // leaving room success
        localStream.stop();
        localStream.close();
        localStream = null;
      }).catch((error) => {
        console.error('leaving room failed: ' + error);
      });
    } else {
      // 拨号计时
      clearTimeout(callingWaitingTime);
      // 本地视频流
      setTimeout(()=>{
        if (localStream) {
          client.leave().then(() => {
            // leaving room success
            localStream.stop();
            localStream.close();
          }).catch((error) => {
            console.error('leaving room failed: ' + error);
          });
        }
        localStream = null;
      }, 1500);
    }
    chatTime=0;
    timeCounts=0;
    setCallingTime(0);
    window.TRTCUserID=null;
  };


  useEffect(()=>{
    initSever();
    ringAudio.load();
    ringAudio.loop=true;
    return ()=>{
      if (TRTCStatus!==0|| chatStatus!==0||TRTCModal!==0) {
        if (TRTCStatus===1||chatStatus===1) {
          sendCustomMsg( window.TRTCUserID, 'cancel call');
        }
        leaveRTC();
        alert('视频通话中断：切换菜单导致通话自动中断');
      }
      endChat();
    };
  }, []);

  useEffect(()=>{
    if (TRTCStatus!==0|| chatStatus!==0||TRTCModal!==0) {
      if (TRTCStatus===1||chatStatus===1) {
        sendCustomMsg( window.TRTCUserID, 'cancel call');
      }
      leaveRTC();
      alert('视频通话中断：切换菜单导致通话自动中断');
    }
  }, [queryType]);

  useEffect(()=>{
    if (userId) {
      if (!window.IMSever) {
        message.error(TRTCMsg.error.networkError);
        setTimeout(()=>{
          onFinished();
        }, 1000);
      } else {
        sendTRTCInvite(userId);
        initMsg();
      }
      window.TRTCUserID=userId;
    }
  }, [userId]);
  useEffect(()=>{
    if (TRTCModal===2) {
      setOnChart(false);
    }
  }, [TRTCModal]);
  return (

    <div className="chat-box" >
      <Row>
        <Col span={onChart?16:24} className="call-kit-container">
          <div className={TRTCStatus===1||chatStatus===1?'calling tui-call-kit':'unCalling tui-call-kit'}>
            <div className="status-wrapper">
              <h2 className="text-l text-white">{chartName}【{chartCompany}】</h2>
              {(TRTCStatus===2||chatStatus===2)&&(
                <div className="status-info">
                  {uplinkNetworkQuality<4&&downlinkNetworkQuality<4?
                  (<><span className="status-good" />已接通 </>):
                  (<><span className="status-poor" />
                    {uplinkNetworkQuality===6||downlinkNetworkQuality===6?'网络连接已断开 ':'当前网络不佳 '}
                  </>)
                  }

                  {moment(callingTime).format(timeCounts<3600?'mm:ss':'HH:mm:ss')}
                </div>)}
            </div>
            <div
              className={rotate===1?(onChart?'calling-wrapper rotate90s':'calling-wrapper rotate90'):
              rotate===2?(onChart?'rotate270s calling-wrapper':'rotate270 calling-wrapper'):
              'calling-wrapper'}
            >
              <div id="local-stream"/>
              <div id="remote-stream"
                // className="rotate90"
              //
              />
            </div>
            <div className="control-wrapper">
              <div className="panel-button-area">
                {(TRTCStatus===2||chatStatus===2)&&( <div className="control-item"
                  onClick={()=>{
                    if (isMute) {
                      localStream.unmuteAudio();
                      message.success(TRTCMsg.success['micOn']);
                    } else {
                      localStream.muteAudio();
                      message.success(TRTCMsg.success['micOff']);
                    }
                    setIsMute(!isMute);
                  }}
                >
                  <div className="control-item-icon">
                    {isMute?<span className="mic-off"/>:(<span className="mic-on"/>)}
                  </div>
                  {TRTCModal!==2&& <div className="control-item-text">静音</div>}
                </div>)}


                <div className="control-item" onClick={()=>{
                  // if (isInitial) {
                  //   message.info('初始化中，请稍后！');
                  // } else {
                  if (TRTCStatus===1||chatStatus===1) {
                    leaveRTC('success', 'cancelCall');
                    sendCustomMsg(userId, 'cancel call');
                  } else {
                    leaveRTC('success', 'TRTCOff');
                  }
                  // }
                }}>
                  <div className="control-item-icon">
                    <span className="hang-up"/>
                  </div>
                  {TRTCModal!==2&& <div className="control-item-text">挂断</div>}
                </div>
                {(TRTCStatus===2||chatStatus===2)&&(
                  <div className="control-item" onClick={()=>{
                    setOnChart(!onChart);
                    if (isNewMsg) {
                      setIsNewMsg(false);
                    }
                    if (TRTCModal===2) {
                      seeChat();
                    }
                  }}>
                    <div className="control-item-icon">
                      {isNewMsg&&!onChart? <span className="chat-off-new"/> :<span className="chat-on"/>}
                    </div>
                    {TRTCModal!==2&&<div className="control-item-text">{onChart?'收起会话':'会话'}</div>}
                  </div>)}
              </div>
            </div>

          </div>
        </Col>
        <Col span={onChart?8:0} className="chat-board">
          <Row className="chat-board-title">
            <Col span={20} className="padding-l-m text-ellipsis">
            与{chartName}【{chartCompany}】会话中
            </Col>
            <Col span={4} className="text-right padding-r-s">
              <Button
                onClick={()=>{
                  setOnChart(false);
                  if (isNewMsg) {
                    setIsNewMsg(false);
                  }
                }}
                type="text"
                icon={<CloseOutlined className="text-gray"/>}/>
            </Col>
          </Row>
          <div className="chat-board-record">
            {messageList&&messageList.map((msg, index)=>{
              if (msg.type==='send') {
                return (<div key={index} className="sent-msg">
                  <Row wrap={false} gutter={16}>
                    <Col flex="36px"/>
                    <Col flex="auto" className="text-right">
                      <span className="msg">{msg.message.text}</span>
                    </Col>
                    <Col flex="36px" className="avatar"/>
                  </Row>
                </div>);
              } else {
                return (<div key={index} className="receive-msg">
                  <Row gutter={12}>
                    <Col flex="36px" className="avatar"/>
                    <Col flex="auto">
                      <span className="msg">{msg.message.text}</span>
                    </Col>
                    <Col flex="36px" />
                  </Row>
                  {/* <span className="msg">{msg.message.text}</span> */}

                </div>);
              }
            })}
          </div>
          <div className="msg-box">
            <Form
              layout="inline"
              form={imForm}
              onFinish={sendMessage}>
              <Row>
                <Col flex="auto">
                  <Form.Item name="content">
                    <Input.TextArea
                      onPressEnter={()=>sendMessage(imForm.getFieldsValue())}
                      placeholder="请输入信息"
                      className="msg-box-input"
                      bordered={false}
                      autoSize={false}
                    />
                  </Form.Item>
                </Col>
                <Col flex="40px">
                  <Form.Item>
                    <Button htmlType="submit" type="link" >发送</Button>
                  </Form.Item>
                </Col>
              </Row>


            </Form>
          </div>
        </Col>
      </Row>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    messageList: state.tim.messageList,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    initMsg: ()=>dispatch(initMessageList()),
    updateMsg: (msg)=>dispatch(updateMessageList(msg)),
  };
};
BasicTIM.propTypes={
  messageList: PropTypes.any.isRequired,
  updateMsg: PropTypes.func.isRequired,
  initMsg: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  userId: PropTypes.string,
  chartName: PropTypes.string,
  chartCompany: PropTypes.string,
  TRTCModal: PropTypes.number.isRequired,
  seeChat: PropTypes.func.isRequired,
  queryType: PropTypes.number.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)( BasicTIM);

