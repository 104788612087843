import React, {useState, useEffect, useRef} from 'react';
import CustomTable from '../../../components/customTable';
// import {InfoCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
const ListDetail = ({listTitle, menuTab, tab, formList, columns, initApi, apiType, exportTime, statistics}) => {
  const [updateList] = useState(0);
  const [resetOptions, setResetOptions] = useState(0);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, [tab, menuTab]);
  return (
    <div className="list-detail">
      <p className="car-titles">{listTitle}</p>
      <div className="list-detail-table">
        <CustomTable
          InitApi={initApi}
          customTitle={' '}
          formList={formList}
          updateList={updateList}
          columns={columns}
          resetData={resetOptions}
          resType="POST"
          scroll={true}
          exportFileName={listTitle}
          toIndex={true}
          tip="导出中..."
          statistics={statistics}
          apiType={apiType}
          exportTime={exportTime}
        />
      </div>
    </div>
  );
};
ListDetail.propTypes = {
  chartTime: PropTypes.string,
  listTitle: PropTypes.string,
  menuTab: PropTypes.number,
  tab: PropTypes.number,
  formList: PropTypes.any,
  columns: PropTypes.any,
  initApi: PropTypes.string,
  apiType: PropTypes.any,
  exportTime: PropTypes.bool,
  statistics: PropTypes.string,
};
export default ListDetail;
