import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Slice from '../../../../asset/Slice 19.png';
import DemoDualAxes from '../../../../components/echarts/DemoDualAxes';
import {getKeyAreaLineChart, getLineEcharts} from '../../../../api';
import LineCharts from '../../../../components/echarts/LineCharts';

const EchartsLine = ({data, queryType, bigMap, areaId, isKeyArea, areaName}) => {
  const [chartWidth, setChartWidth]=useState('100%');
  const [line, setLine] = useState(null);

  const getChartsData = () => {
    if (isKeyArea) {
      getKeyAreaLineChart(queryType, {markRangeId: areaId}, 2)
          .then((res) => {
            const list =[];
            res.data.forEach((item)=>{
              list.push({
                dateTime: item.date,
                // 实到
                value: item.actualArrival,
                // 应到
                arrive: item.arrive,
              });
            });
            setLine([...list]);
          });
    } else {
      getLineEcharts(queryType, {markRangeId: areaId}, 2)
          .then((res) => {
            const list =[];
            res.data.chart.forEach((item)=>{
              list.push({
                dateTime: item.date,
                value: item.car,
                name: queryType == 1 ? '单车调运车辆':'电单车调运车辆',
              });
              list.push({
                dateTime: item.date,
                value: item.people,
                name: queryType == 1 ? '单车人员':'电单车人员',
              });
            });
            setLine([...list]);
          });
    }
  };


  useEffect(()=>{
    switch (parseInt(bigMap)) {
      case 1:
        return setChartWidth('100%');
      case 2:
        return setChartWidth('99.7%');
      case 3:
        return setChartWidth('99.5%');
      default:
        return setChartWidth('99.6%');
    }
  }, [bigMap]);

  useEffect(()=>{
    if (areaId) {
      getChartsData();
    } else {
      setLine(null);
    }
  }, [areaId]);
  return (

    <div className={`card-charts ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-show-h':''}`}>

      <div className="card-title">
        <img src={Slice} className="card-title-img" />
        {areaName?`【${areaName}】${isKeyArea?'重点工作区域保障':''}近12小时到岗数趋势`:'近12小时在线趋势'}
      </div>
      <div style={{height: '89%', minHeight:location.pathname.indexOf('/bigScreen') !== -1? '248px':'0', width: chartWidth}}>
        {isKeyArea?<LineCharts data={line||[]} />:<DemoDualAxes data={line||data} areaName={areaName}/>}
      </div>
    </div>

  );
};

EchartsLine.propTypes = {
  data: PropTypes.array.isRequired,
  queryType: PropTypes.number.isRequired,
  bigMap: PropTypes.number.isRequired,
  areaId: PropTypes.number||PropTypes.string,
  isKeyArea: PropTypes.bool,
  areaName: PropTypes.string,
};

export default EchartsLine;

