/* eslint-disable no-debugger */
import React, {useEffect, useState} from 'react';
import CustomTable from '../../../components/customTable';
import {GETMONITORLIST} from '../../../api/Urls';
import {Link} from 'react-router-dom';
// import DetailsHeader from '../../components/detailsHeader';
import {locationNumber} from '../../../components/statusCollection';
import './style.css';
const DeviceManage = () => {
  const [updateList, setUpdateList] = useState(0);
  const formList = [
    {
      label: '设备所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'name',
      typeOptions: [
        {label: '设备IMEI', value: 1},
        {label: '设备名称', value: 2},
        {label: '设备位置编号', value: 4},
      ],
    },
  ];
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
    },
    {
      title: '设备名称',
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      fixed: 'left',
    },
    {
      title: '设备IMEI',
      dataIndex: 'mobileEquipmentIdentity',
      key: 'mobileEquipmentIdentity',
      fixed: 'left',
    },
    {
      title: '位置编号',
      dataIndex: 'locationNo',
      key: 'locationNo',
      // eslint-disable-next-line
      render: (locationNo) => {
        return locationNo ? locationNumber(locationNo) : <span>-</span>;
      },
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: '实时数',
      key: 'realTimeCount',
      dataIndex: 'realTimeCount',
      // eslint-disable-next-line
      render: (realTimeCount, render) => {
        return (
          <Link
            onClick={() => localStorage.setItem('detail', JSON.stringify(render))}
            className="blue-text"
            to={`/deviceManagement/bikeEquipmentMonitor/details/1/${render.equipmentBatchNo}/${realTimeCount}`}
          >
            {realTimeCount}
          </Link>
        );
      },
    },
    {
      title: '跨区域数',
      key: 'crossRegionCount',
      dataIndex: 'crossRegionCount',
      // eslint-disable-next-line
      render: (crossRegionCount, render) => {
        return (
          <Link
            onClick={() => localStorage.setItem('detail', JSON.stringify(render))}
            className="blue-text"
            to={`/deviceManagement/bikeEquipmentMonitor/details/2/${render.equipmentBatchNo}/${crossRegionCount}`}
          >
            {crossRegionCount}
          </Link>
        );
      },
    },
    {
      title: '未备案数',
      dataIndex: 'notFiledCount',
      key: 'notFiledCount',
      // eslint-disable-next-line
      render: (notFiledCount, render) => {
        return (
          <Link
            onClick={() => localStorage.setItem('detail', JSON.stringify(render))}
            className="blue-text"
            to={`/deviceManagement/bikeEquipmentMonitor/details/3/${render.equipmentBatchNo}/${notFiledCount}`}
          >
            {notFiledCount}
          </Link>
        );
      },
    },
    {
      title: '闲置数',
      dataIndex: 'bikeIdleCount',
      key: 'bikeIdleCount',
    },
    // {
    //   title: '废弃数',
    //   dataIndex: 'abandonmentCount',
    //   key: 'abandonmentCount',
    // },
    // {
    //   title: '历史累计数',
    //   dataIndex: 'historyCount',
    //   key: 'historyCount',
    // },
  ];

  useEffect(() => {
    // 2分钟刷新设备列表
    const timeNow = setInterval(() => {
      setUpdateList(updateList + 1);
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(timeNow);
    };
  }, [updateList]);

  return (
    <div>
      <CustomTable
        InitApi={GETMONITORLIST}
        toIndex={true}
        formList={formList}
        updateList={updateList}
        scroll={true}
        columns={columns}
      />
    </div>
  );
};
export default DeviceManage;
