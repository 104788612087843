import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import startIcon from './../../asset/start.png';
import endIcon from './../../asset/end.png';
import location from './../../asset/location.png';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';

let AMaps;
let marker;
let line;
let startMarker;
let endMarker;

const Trace = ({
  traceData,
  position,
}) => {
  const [loading, setLoading]=useState(true);
  const mapRef= useRef();

  const traceDraw=()=>{
    if (line) {
      mapRef.current.map.remove(line);
      mapRef.current.map.remove(startMarker);
      mapRef.current.map.remove(endMarker);
    }
    const size=new AMaps.Size(48, 48); // 图标尺寸
    const imageSize=new AMaps.Size(48, 48);// 根据所设置的大小拉伸或压缩图片
    const offset=new AMaps.Pixel(-24, -40);
    const icon1 = new AMaps.Icon({
      size,
      imageSize,
      image: endIcon, // Icon的图像
    });
    const icon2 = new AMaps.Icon({
      size,
      imageSize,
      image: startIcon, // Icon的图像
    });
    if (traceData?.length>0) {
      const list =traceData.map(({x, y})=>{
        return [x, y];
      });
      const endDot=list[list.length-1];
      const startDot=list[0];
      line = new AMaps.Polyline({
        path: list,
        strokeWeight: 8,
        strokeOpacity: .8,
        strokeColor: '#88B4FF',
        showDir: true,
      });
      mapRef.current.map.add(line);
      mapRef.current.map.setFitView();
      startMarker = new AMaps.Marker({
        position: new AMaps.LngLat(startDot[0], startDot[1]),
        icon: icon1,
        offset,
      });
      mapRef.current.map.add(startMarker);
      endMarker = new AMaps.Marker({
        position: new AMaps.LngLat(endDot[0], endDot[1]),
        icon: icon2,
        offset,
      });
      mapRef.current.map.add(endMarker);
    }
  };
  useEffect(()=>{
    AMapLoader.load({
      ...AMapKey,
      plugins: ['AMap.GraspRoad', 'AMap.ToolBar'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap)=> {
      mapRef.current.map = new AMap.Map('container', { // 设置地图容器id
        resizeEnable: true,
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      AMaps = AMap;
      setLoading(false);
    });
  }, []);

  useEffect(()=>{
    if (!loading&&AMaps) {
      traceDraw();
      if (marker) {
        mapRef.current.map.remove(marker);
      }
      if (position) {
        const size=new AMaps.Size(48, 48); // 图标尺寸
        const imageSize=new AMaps.Size(48, 48);// 根据所设置的大小拉伸或压缩图片
        const offset=new AMaps.Pixel(-24, -40);
        const icon3 = new AMaps.Icon({
          size,
          imageSize,
          image: location, // Icon的图像
        });
        marker = new AMaps.Marker({
          position: new AMaps.LngLat(position[0], position[1]),
          icon: icon3,
          offset,
        });
        mapRef.current.map.add(marker);
      }
    }
  }, [traceData, loading, position]);

  return (
    <div ref={mapRef} id="container" className="map" style={{height: 600, width: '100%'}} />
  );
};

Trace.propTypes = {
  traceData: PropTypes.arrayOf(PropTypes.object),
  position: PropTypes.array,
};

export default Trace;
