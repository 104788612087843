import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Form, Input, Space, message, Spin} from 'antd';
import PropTypes from 'prop-types';
import {timeFormat} from '../../../components/baseTime';
import editIcon from '../../../asset/edit.png';
import {updateGroupBasicInfo} from '../../../api';
const BaseInfo = ({baseInfo, id, onFinished}) => {
  // const [areaOptions, setAreaOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form]= Form.useForm();
  const onFinish=(values)=>{
    const data= {
      areaId: values.areaId,
      groupName: values.groupName,
      streetId: values.streetId,
      id,
    };
    setLoading(true);
    updateGroupBasicInfo(data, 2).then((res)=>{
      message.success(res.msg);
      onFinished();
      setEdit(false);
    }).finally(()=>{
      setLoading(false);
    });
  };
  // const getAreaGroup = () => {
  //   getAreaTree({}, 2).then((res) => {
  //     const data = res.data.map((item) => {
  //       return {
  //         ...item,
  //         value: item.id,
  //         disabled: !item.managerName,
  //         areaManagerName: item.managerName || '',
  //         label: (<span>
  //           {item.areaName}
  //           <span className="text-gray"> {item.managerName || ''}</span>
  //         </span>),
  //         children: item.children ? item.children.map((sub) => {
  //           return {
  //             ...sub,
  //             label: (<span>
  //               {sub.areaName}
  //               <span className="text-gray"> {sub.managerName || ''}</span>
  //               <span className="text-gray"> {sub.groupName || '无考勤组'}</span>
  //             </span>),
  //             value: sub.id,
  //             streetManagerName: sub.managerName || '',
  //             disabled: !sub.managerName || sub.groupName,
  //           };
  //         }) : [],
  //       };
  //     });
  //     setAreaOptions(data);
  //   });
  // };
  // const onChange = (value, selectedOptions) => {
  //   form.setFieldsValue({
  //     areaName: selectedOptions[0].managerName,
  //     streetName: selectedOptions[1].managerName,
  //     areaId: selectedOptions[0].id,
  //     streetId: selectedOptions[1].id,
  //   });
  // };
  // const displayRender = (labels, selectedOptions) => {
  //   return labels.map((label, i) => {
  //     const option = selectedOptions[i];
  //     if (i === labels.length - 1) {
  //       return (
  //         <span key={option.value}>
  //           {option.areaName}
  //         </span>
  //       );
  //     }
  //     return <span key={option.value}>{label.props.children[0]} / </span>;
  //   });
  // };
  useEffect(()=>{
    // getAreaGroup();
  }, []);

  useEffect(()=>{
    if (baseInfo&&edit) {
      form.setFieldsValue({
        ...baseInfo,
        areaName: baseInfo.areaManagerName,
        streetName: baseInfo.streetManagerName,
        area: [baseInfo.areaId, baseInfo.streetId],
      });
    }
  }, [baseInfo, edit]);
  return (
    <div>
      <h3 className="details-title">
        基础信息
        <span onClick={()=>setEdit(true)} className="padding-l-m point" >
          <img src={editIcon} alt="edit"/>
        </span>
      </h3>
      {edit?(<Spin spinning={loading}><Form
        className="padding-l-m padding-r-m"
        name="base"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: '请输入',
                },
              ]}
              label="考勤组名："
              name="groupName">
              <Input maxLength={10} placeholder=" 请选择"/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="所属区域："
            >
              <Input
                value={`${baseInfo.areaName}`}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="区域管理员姓名（自动关联）：" name="areaName">
              <Input disabled/>
            </Form.Item>
            <Form.Item hidden name="areaId">
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="街道管理员姓名（自动关联）：" name="streetName">
              <Input disabled/>
            </Form.Item>
            <Form.Item hidden name="streetId">
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>
        <div className="text-right">
          <Space>
            <Button onClick={()=>{
              setEdit(false);
            }}>取消</Button>
            <Button type="primary" htmlType="submit">保存</Button>
          </Space>
        </div>
      </Form>
      </Spin>

      ):(<div className="details-info margin-t-l">
        <Row className="margin-b-l">
          <Col span={7}>
            <span className="padding-r-m">
              考勤组名称：
            </span>
            <span className="text-gray">{baseInfo.groupName || '暂无数据'}</span>
          </Col>
          <Col span={7}>
            <span className="padding-r-m">
              区域：
            </span>
            <span className="text-gray">{baseInfo.areaName || '无区域'}</span>
          </Col>
          <Col span={7}>
            <span className="padding-r-m">
             创建时间：
            </span>
            {timeFormat(baseInfo.createTime)}
          </Col>
        </Row>
        <Row className="margin-b-l">
          <Col span={7}>
            <span className="padding-r-m">
              区域管理员：
            </span>
            <span className="text-gray">{baseInfo.areaManagerName ||'暂无数据'} </span>
          </Col>
          <Col span={15}>
            <span className="padding-r-m">
              区域管理员联系方式：
            </span>
            <span className="text-gray">{baseInfo.areaManagerPhone ||'暂无数据'} </span>
          </Col>
        </Row>
        <Row className="margin-b-l">
          <Col span={7}>
            <span className="padding-r-m">
              街道管理员：
            </span>
            <span className="text-gray">{baseInfo.streetManagerName ||'暂无数据'} </span>
          </Col>
          <Col span={15}>
            <span className="padding-r-m">
              街道管理员联系方式：
            </span>
            <span className="text-gray">{baseInfo.streetManagerPhone ||'暂无数据'} </span>
          </Col>
        </Row>
      </div>)}
    </div>
  );
};

BaseInfo.propTypes = {
  baseInfo: PropTypes.object,
  onFinished: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default BaseInfo;
