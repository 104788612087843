import React, {useState, useMemo, useEffect} from 'react';
import {Button, Col, DatePicker, Divider, Radio, Row, Space, Spin, Tooltip, Skeleton} from 'antd';
import {getStatisticInfo, getMonitorPercent, getRealTimeTrend, getBikeStatis, getTrend} from '../../../api';
import {TRENDBYDAY, TRENDBYMINUTE} from '../../../api/Urls';
import {InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import PieChart from '../../../components/echarts/PieChart';
import SubTitle from '../../../components/subtitle/SubTitle';
import BarChart from '../components/ColumnChart';
import LineChart from '../components/lineChart';
import {toThousands} from '../../../utils/dataFormat';
import moment from 'moment';
import noData from '../../../asset/noData.png';
import PolylineSearch from '../components/PolylineSearch';
import '../style.css';

const DataStatistics = (bikeType) => {
  const [tab, setTab] = useState(0);
  const [updateList, setUpdateList] = useState(0);
  const {RangePicker} = DatePicker;
  const [monitorNumber, setMonitorNumber] = useState();
  const [columnData, setColumnData] = useState();
  const [realTimeData, setRealTimeData] = useState();
  const [notFiledData, setNotFieldData] = useState();
  const [data, setData] = useState();
  const [dates, setDates] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [filedLoading, setFiledLoading] = useState(false);
  const [dataKey, setDataKey] = useState('');
  const [radioType, setRadioType] = useState();
  const [loading, setLoading] = useState(true);
  const [carTitle] = useState([
    {bikeEnterprise: 1, title: '美团区域监测单车数'},
    {bikeEnterprise: 2, title: '青桔区域监测单车数'},
    {bikeEnterprise: 3, title: '哈啰区域监测单车数'},
  ]);
  const [statisticNumber, setStatisticNumber] = useState({
    全市备案单车总数: 0,
    区域监测单车数: 0,
    区域备案单车数: 0,
    设备点位数: 0,
    管辖企业数量: 0,
  });
  const [error, setError] = useState(false);
  const [promptTitle, setPromptTitle] = useState();
  const [incomeDate, setIncomeDate] = useState();
  const bikeEnterprised = JSON.parse(localStorage.getItem('bikeEnterprise')).bikeEnterprise;
  // 数据模拟

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
    const currentDate = moment();
    const isAfterCurrentDate = current.isAfter(currentDate, 'day');
    return (
      !!tooEarly ||
      !!tooLate ||
      isAfterCurrentDate ||
      current < currentDate.subtract(1, 'months') ||
      current > moment().add(1, 'months')
    );
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const tooltip = (text) => {
    return (
      <div style={{color: 'black'}}>
        <div>
          <InfoCircleFilled style={{color: '#FAAD14', marginRight: 9}} />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        <p className="tipInfo">{text}</p>
      </div>
    );
  };

  // 单车数据统计展示接口
  const getStatisticNumber = () => {
    getStatisticInfo('1', 2).then((res) => {
      const data = {
        全市备案单车总数: res.data.allFiledNum,
        区域监测单车数: res.data.areaMonitorNum,
        区域备案单车数: res.data.filedNum,
        设备点位数: res.data.equipmentNum,
        管辖企业数量: res.data.companyNum,
      };
      setStatisticNumber({...data});
    });
  };
  // 车企区域监测单车数占比
  const getMonitorPercentInfo = () => {
    let arr = [];
    getMonitorPercent('1', 2)
        .then((res) => {
          arr = res.data.sort(function(a, b) {
            return a.bikeEnterprise - b.bikeEnterprise;
          });
          setMonitorNumber([...arr]);
        })
        .catch((error) => {
          console.log(error);
          if (error === '管理员无管辖区域，该页面数据为空') {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  // 柱状图数据
  const getColumnData = (type) => {
    getBikeStatis('1', type, 2).then((res) => {
      setColumnData(res.data);
    });
  };

  // 折线图数据处理
  const disposeDate = (data) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      data[i]?.trend.map((item, index) => {
        const type = data[i].bikeEnterprise;
        arr.push(Object.assign(item, {type: type === 0 ? '全部' : type === 1 ? '美团' : type === 2 ? '青桔' : '哈啰'}));
      });
    }
    return arr;
  };

  // 实时数据
  const getRealTime = (data) => {
    setChartLoading(true);
    getRealTimeTrend('1', data ? data : {}, 2)
        .then((res) => {
          setChartLoading(false);
          const result = disposeDate(res.data);
          setRealTimeData(result);
        })
        .catch((error) => {
          setChartLoading(false);
        });
  };
  // data管辖区域处理
  const statisticData = (value) => {
    let temp = [];
    if (bikeEnterprised.indexOf(1) !== -1 && bikeEnterprised.indexOf(2) !== -1 && bikeEnterprised.indexOf(3) !== -1) {
      const res = value.filter((i) => i.bikeEnterprise < 4);
      temp=[...res];
    } else {
      for (const index of bikeEnterprised) {
        const res = value.find((i) => i.bikeEnterprise === index && i.bikeEnterprise < 4);
        if (res) temp.push(res);
      }
    }
    return temp;
  };

  // 调api接口数据数据处理
  const disposeApiData = (tab, date) => {
    const obj = {
      apiType: tab === 2 || tab === 3 ? 1 : 2,
    };
    if (date && (tab === 3 || tab === 5)) {
      obj.startDate = date.startTime;
      obj.endDate = date.endTime;
    } else if (date && (tab === 2 || tab === 4)) {
      obj.startTime = date.startTime;
      obj.endTime = date.endTime;
    }
    return obj;
  };
  // 未备案、跨区域 单车趋势api
  const tendency = (tab, date) => {
    setNotFieldData([]);
    setFiledLoading(true);
    const data = disposeApiData(tab, date);
    const url = tab === 2 || tab === 4 ? TRENDBYMINUTE : tab === 3 || tab === 5 ? TRENDBYDAY : null;

    getTrend(`${url}1`, {...data}, 2)
        .then((res) => {
          const resp = statisticData(res.data);
          const result = disposeDate(resp);
          setNotFieldData(result);
          const income = result[result.length - 1]?.date;
          setIncomeDate(income);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setFiledLoading(false);
        });
  };

  const carColor = (value) => {
    switch (value) {
      case 1:
        return '#E89E0D';
      case 2:
        return '#13BEC6';
      case 3:
        return '#1D39C4';
      default:
        break;
    }
  };
  const style = {
    background: '#fff',
  };
  const tooltips = () => {
    let tip;
    if (tab === 1) {
      tip = '所选开始与结束两个时间的最长跨度不得超过7*24h。';
    } else if (tab === 2 || tab === 4) {
      tip = '所选开始与结束两个时间的最长跨度不得超过24h。';
    } else if (tab === 3 || tab === 5) {
      tip = '所选开始与结束两个时间的最长跨度不得超过30天。';
    }
    return (
      <Tooltip title={tooltip(tip)} className="margin-r-s margin-l-s" color="white">
        <InfoCircleOutlined style={{color: '#8c8c8c'}} />
      </Tooltip>
    );
  };

  const PieChartRender = useMemo(
      () => (monitorNumber ? <PieChart data={monitorNumber} dataType="percent" /> : null),
      [monitorNumber],
  );
  const LineRender = useMemo(() => <LineChart data={realTimeData} title={promptTitle} />, [realTimeData]);
  const ColumnChartRender = useMemo(() => <BarChart data={columnData} />, [columnData]);

  // 具体内容
  const Tabs = [
    {
      content: (
        <div key={0}>
          <div className="data-statistics">
            <Row gutter={24}>
              {Object.keys(statisticNumber).map((key, index) => (
                <Col className="gutter-row" key={index}>
                  <div style={style} className="number-box">
                    <div> {key} </div>
                    <div className="number-style">{toThousands(statisticNumber[key]) || 0}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="car-area data-statistics">
            <div className="car-title padding-m">车企区域监测单车数占比</div>
            <div className="car-chart-num">
              <div className="car-pie">
                {/* <PieChart /> */}
                {PieChartRender}
              </div>
              {loading ? (
                <Skeleton active paragraph={{rows: 1}} className="bike-skeleton" />
              ) : (
                <div className="car-num">
                  {monitorNumber &&
                    carTitle.map((item, index) => (
                      <div className="car-content padding-m" key={item.bikeEnterprise}>
                        <div className="num-top text-grey">
                          <div
                            className="circle-shape"
                            style={{backgroundColor: `${carColor(item.bikeEnterprise)}`}}
                          ></div>
                          {item.title}
                          <div className="margin-t-m text-left text-black bike-statistics">
                            {toThousands(monitorNumber[index]?.count ? monitorNumber[index]?.count : 0)}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="car-column data-statistics">
            <div className="car-title padding-m">
              <div>日新增单车数趋势</div>
              <Radio.Group
                defaultValue={radioType ? radioType : '3'}
                size="small"
                style={{marginTop: 16}}
                onChange={(e) => {
                  getColumnData(e.target.value.toString());
                  setRadioType(e.target.value.toString());
                }}
              >
                <Radio.Button value="1">近7天</Radio.Button>
                <Radio.Button value="2">近15天</Radio.Button>
                <Radio.Button value="3">近30天</Radio.Button>
              </Radio.Group>
            </div>
            <div className="padding-m data-linechart">
              <div className="chart-container">{columnData && ColumnChartRender}</div>
            </div>
          </div>
        </div>
      ),
      key: 0,
    },
    {
      content: (
        <div key={1} className="data-tab">
          <div className="car-column padding-m">
            <Spin
              spinning={chartLoading}
              size="large"
              wrapperClassName="map-loading"
              tip={
                <div>
                  <p className="spin-tip">该页面数据量较大，</p>
                  <p className="spin-tip">请稍作等待</p>
                </div>
              }
            >
              <Row className="justify-content">
                <Col className="car-title padding-m">实时单车数趋势</Col>
                <Col className="align-center">
                  <span className="time-text">时间区间 </span>
                  {tooltips()}
                  <RangePicker
                    allowClear
                    showTime={{format: 'HH'}}
                    className="margin-r-s margin-l-s  padding-r-s"
                    format="YYYY-MM-DD HH"
                    size="middle"
                    style={{width: '100%'}}
                    key={dataKey}
                    onOpenChange={onOpenChange}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => {
                      setDates(val);
                    }}
                    onChange={(e) => {
                      if (e) {
                        setData({
                          startTime: moment(e[0]).format('YYYY-MM-DD  HH:mm:ss'),
                          endTime: moment(e[1]).format('YYYY-MM-DD  HH:mm:ss'),
                        });
                      }
                    }}
                  />
                  <Space>
                    <Button
                      onClick={() => {
                        getRealTime();
                        setData({startTime: '', endTime: ''});
                        setDates(null);
                        setDataKey(new Date());
                      }}
                    >
                      重置
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="margin-r-m"
                      onClick={() => {
                        getRealTime(data);
                      }}
                    >
                      搜索
                    </Button>
                  </Space>
                </Col>
              </Row>

              <div className="padding-m data-linechart">{realTimeData && LineRender}</div>
            </Spin>
          </div>
        </div>
      ),
      key: 1,
    },
    {
      content: (
        <Spin
          spinning={filedLoading}
          size="large"
          wrapperClassName="map-loading"
          tip={
            <div>
              <p className="spin-tip">该页面数据量较大，</p>
              <p className="spin-tip">请稍作等待</p>
            </div>
          }
          key={3}
        >
          <PolylineSearch
            tab={tab}
            incomeDate={incomeDate}
            filedData={notFiledData}
            promptTitle={promptTitle}
            timeReset={() => tendency(tab)} // 时间重置
            timeSearch={(event) => {
              // 时间搜索
              tendency(tab, event);
            }}
          />
        </Spin>
      ),
      key: 2,
    },
  ];

  useEffect(() => {
    // 6分钟刷新设备列表
    const timeNow = setInterval(() => {
      setUpdateList(updateList + 1);
      switch (tab) {
        case 0:
          getStatisticNumber('1');
          getMonitorPercentInfo('1');
          break;

        default:
          break;
      }
    }, 6 * 60 * 1000);
    return () => {
      clearInterval(timeNow);
    };
  }, [updateList, data, radioType, tab]);

  useEffect(() => {
    getStatisticNumber('1');
    getMonitorPercentInfo('1');
    getColumnData('3');
    setRadioType();
  }, []);

  useEffect(() => {
    switch (tab) {
      case 1:
        setPromptTitle('实时单车数趋势');
        break;
      case 2:
        setPromptTitle('未备案单车数趋势（2分钟时段）');
        break;
      case 3:
        setPromptTitle('未备案单车数趋势（每日）');
        break;
      case 4:
        setPromptTitle('跨区域单车数趋势（2分钟时段）');
        break;
      case 5:
        setPromptTitle('跨区域单车数（每日）');
        break;
      default:
        break;
    }
  }, [tab]);

  return (
    <>
      {!error ? (
        <div>
          <SubTitle
            title="单车数据统计"
            tab={['统计', '实时', '未备案（时段）', '未备案（日）', '跨区域（时段）', '跨区域（日）']}
            onChangeTab={(event) => {
              const value = Number(event);
              setTab(value);
              switch (value) {
                case 0:
                  getStatisticNumber('1');
                  getMonitorPercentInfo('1');
                  getColumnData('3');
                  break;
                case 1:
                  getRealTime();
                  break;
                default:
                  tendency(value);
                  break;
              }
            }}
          />
          {Tabs.map((item) => {
            if (item.key === tab && tab <= 2) {
              return item.content;
            } else if (tab > 2) {
              if (item.key == 2) {
                return item.content;
              }
            }
          })}
        </div>
      ) : (
        <div className="no-data">
          <img src={noData}></img>
        </div>
      )}
    </>
  );
};
export default DataStatistics;
