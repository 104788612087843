import React from 'react';
import {useParams} from 'react-router-dom';
import DetailsHeader from '../../../../components/detailsHeader';
import {Anchor, Card, Col, Collapse, Divider, Row} from 'antd';
import StaffInfo from '../../../../components/baseInfo/StaffInfo';
import BicycleInfo from '../../../../components/baseInfo/BicycleInfo';
import TaskInfo from '../../../../components/baseInfo/TaskInfo';
import SimpleTable from '../../../../components/customTable/SimpleTable';
import DispatchPosition from './DispatchPosition';
import {GETTASKBIKELIST} from '../../../../api/Urls';
import {bikeCompany, bikeType} from '../../../../components/statusCollection';

const DispatchDetails = () => {
  const d = useParams();

  const columns = [
    {
      title: '序列',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '所属车企',
      dataIndex: 'bikeType',
      key: 'bikeType',
      render: (bikeType)=>bikeCompany(bikeType),
    },
    {
      title: '车辆类型',
      dataIndex: 'type',
      key: 'type',
      render: (record)=>bikeType(record),
    },
    {
      title: '实体车牌号',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      render: (record)=>record?record:'-',
    },
    {
      title: '车锁号',
      dataIndex: 'lockNumber',
      key: 'lockNumber',
      render: (record)=>record?record:'-',
    },
    {
      title: '蓝牙MAC地址',
      dataIndex: 'bluetoothMacAddress',
      key: 'bluetoothMacAddress',
      render: (record)=>record?record:'-',
    },
    {
      title: '车牌号',
      dataIndex: 'frameNumber',
      key: 'frameNumber',
      render: (record)=>record?record:'-',
    },
  ];
  return (
    <div>
      <DetailsHeader pageName={`调度记录详情（${d.type}）`} tabsKey={d.key}/>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <Row>
          <Col flex="auto">
            <StaffInfo userID={d.operationId} carCard={true} tabsKey={d.key}/>
            <Divider/>
            <TaskInfo id={d.id} type={d.type} tabsKey={d.key}/>
            <Divider/>
            <DispatchPosition title="调度轨迹"/>
            <Divider/>
            {d.type === '按车辆查看' &&
            (<Collapse defaultActiveKey={['3']} ghost>
              <Collapse.Panel
                key="3"
                header={(
                  <h3 className="details-title">
                    车辆信息
                  </h3>)}>
                <BicycleInfo id={d.lockId} tabsKey={d.key}/>
              </Collapse.Panel>
            </Collapse>)}
            {d.type === '按地点查看' && (<div id="bicycle-list">
              <Collapse defaultActiveKey={['2']} ghost>
                <Collapse.Panel
                  key="2"
                  header={(
                    <h3 className="details-title">
                      车辆列表
                    </h3>)}>
                  <SimpleTable
                    columns={columns}
                    InitApi={`${GETTASKBIKELIST}${d.key}`}
                    id={d.id}
                    resType="POST"
                    toIndex={true}/>
                </Collapse.Panel>
              </Collapse>
            </div>)}
          </Col>
        </Row>
      </Card>
      {/* <Col flex="80px"> */}
      <Anchor className="anchor">
        <Anchor.Link href="#staff-info" title="人员信息"/>
        <Anchor.Link href="#task-info" title="调度详情"/>
        <Anchor.Link href="#trace-info" title="调度轨迹"/>
        {d.type === '按车辆查看' && <Anchor.Link href="#bicycle-info" title="车辆信息"/>}
        {d.type === '按地点查看' && <Anchor.Link href="#bicycle-list" title="车辆列表"/>}
      </Anchor>
      {/* </Col> */}
    </div>
  );
};

DispatchDetails.propTypes = {};

export default DispatchDetails;
