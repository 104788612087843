import {Badge, Tag, Popover, Tooltip, Divider} from 'antd';
import React from 'react';
import staff1 from '../../asset/staff1.png';
import staff2 from '../../asset/staff2.png';
// import staff3 from '../../asset/staff3.png';
import staff4 from '../../asset/staff4.png';
import electricBicycle from '../../asset/electricBicycle.png';
import install from '../../asset/install.png';
import cityManage from '../../asset/cityManage.png';
import {QuestionCircleOutlined} from '@ant-design/icons';

export const attendanceStatus = (code, type) => {
  switch (parseInt(code)) {
    case 1:
      return <Tag color="processing">正常</Tag>;
    case 2:
      return <Tag color="error">异常</Tag>;
    case 3:
      return type ? <Tag color="warning">迟到</Tag> : <Tag color="warning">早退</Tag>;
    default:
      return <span className="text-gray">--</span>;
  }
};
export const attendanceStatusText = (code, type) => {
  switch (parseInt(code)) {
    case 1:
      return '正常';
    case 2:
      return '异常';
    case 3:
      return type ? '迟到' : '早退';
    default:
      return '--';
  }
};

export const onlineStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return <Badge color="#595959" text="在线" />;
    case 2:
      return <Badge color="#999999" text={<span className="text-gray">离线</span>} />;
    default:
      return <span className="text-gray">-</span>;
  }
};
export const onlineStatusOptions = [
  {value: 1, label: '在线'},
  {value: 2, label: '离线'},
];

export const onlineStatusText = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '在线';
    case 2:
      return '离线';
    default:
      return '--';
  }
};

export const workStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return <Badge color="#595959" text="正常" />;
    case 2:
      return <Badge color="#595959" text="休假" />;
    case 3:
      return <Badge color="#EB2F96" text={<span style={{color: '#EB2F96'}}>保障</span>} />;
    case 4:
      return <Badge color="#999999" text={<span className="text-gray">离职</span>} />;
    default:
      return <span className="text-gray">--</span>;
  }
};
export const workStatusText = (code) => {
  // 工作状态：1:正常 2:休假 3:紧急支援 4:离职
  switch (parseInt(code)) {
    case 1:
      return '正常';
    case 2:
      return '休假';
    case 3:
      return '保障';
    case 4:
      return '离职';
    default:
      return '--';
  }
};
export const workStatusOptions = (type) => {
  const options = [
    {value: 1, label: '正常'},
    {value: 2, label: '休假'},
    {value: 3, label: '保障', disabled: true},
  ];

  return type ? [...options, {value: 4, label: '离职'}] : options;
};

export const rankOptions = () => {
  const options = [
    {value: 1, label: '普通'},
    {value: 2, label: '负责人'},
  ];

  return options;
};

export const workOptions = () => {
  const options = [
    {value: 1, label: '正常'},
    {value: 2, label: '休假'},
    {value: 4, label: '离职'},
  ];

  return options;
};

export const dispatchStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '已完成';
    case 0:
      return '未完成';
    default:
      return '--';
  }
};

export const maintainStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '已完成';
    case 0:
      return '未完成';
    default:
      return '--';
  }
};
export const accessStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '访问结束';
    case 0:
      return '访问中';
    default:
      return '--';
  }
};
export const cityManageCompany = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '市环卫中心';
    case 2:
      return '其他环卫中心';
    default:
      return '--';
  }
};
export const staffStatus = (code) => {
  // 职务类型：1：保障 2:调度 3:核验 4:其他
  switch (parseInt(code)) {
    case 1:
      return (
        <span>
          <img src={staff2} alt="icon" className="margin-r-s" />
          单车人员
        </span>
      );
    case 2:
      return (
        <span>
          <img src={electricBicycle} alt="icon" className="margin-r-s" />
          单车调运车辆
        </span>
      );
    case 3:
      return (
        <span>
          <img src={staff1} alt="icon" className="margin-r-s" />
          核验人员
        </span>
      );
    case 4:
      return (
        <span>
          <img src={staff4} alt="icon" className="margin-r-s" />
          其他
        </span>
      );
    case 5:
      return (
        <span>
          <img src={staff2} alt="icon" className="margin-r-s" />
          电单车人员
        </span>
      );
    case 6:
      return (
        <span>
          <img src={electricBicycle} alt="icon" className="margin-r-s" />
          电单车调运车辆
        </span>
      );
    case 7:
      return (
        <span>
          <img src={install} alt="icon" className="margin-r-s" />
          安装人员
        </span>
      );
    case 8:
      return (
        <span>
          <img src={cityManage} alt="icon" className="margin-r-s" />
          城市管理者
        </span>
      );
    default:
      return <span className="text-gray">--</span>;
  }
};
export const staffStatusText = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '单车人员';
    case 2:
      return '单车调运车辆';
    case 3:
      return '核验人员';
    case 4:
      return '其他';
    case 5:
      return '电单车人员';
    case 6:
      return '电单车调运车辆';
    case 7:
      return '安装人员';
    default:
      return '--';
  }
};

export const staffOptions = [
  {
    value: 1,
    label: '单车人员',
  },
  {
    value: 2,
    label: '单车调运车辆',
  },
  {
    value: 3,
    label: '核验人员',
  },
  {
    value: 4,
    label: '其他',
  },
  {
    value: 5,
    label: '电单车人员',
  },
  {
    value: 6,
    label: '电单车调运车辆',
  },
  {
    value: 7,
    label: '安装人员',
  },
];
export const carCompanyOrderStatusOption = [
  {
    value: 1,
    label: '待接受',
  },
  {
    value: 2,
    label: '待派发',
  },
  {
    value: 3,
    label: '待完成',
  },
  {
    value: 4,
    label: '已完成',
  },
  {
    value: 5,
    label: '已过期',
  },
  {
    value: 6,
    label: '部分已接受',
  },
  {
    value: 7,
    label: '部分已派发',
  },
  {
    value: 8,
    label: '部分已完成',
  },
];
export const companyOrderStatusOption = [
  {
    value: 1,
    label: '待接受',
  },
  {
    value: 2,
    label: '待派发',
  },
  {
    value: 3,
    label: '待完成',
  },
  {
    value: 4,
    label: '已完成',
  },
];
export const carCompanyOrderStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '待接受';
    case 2:
      return '待派发';
    case 3:
      return '待完成';
    case 4:
      return '已完成';
    case 5:
      return '已过期';
    case 6:
      return '部分已接受';
    case 7:
      return '部分已派发';
    case 8:
      return '部分已完成';
    default:
      return '--';
  }
};
export const bikeErrorText = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '信息错误';
    case 2:
      return 'MAC地址不存在';
    case 3:
      return '车锁号不存在';
    case 4:
      return '跨区域运营';
    default:
      return '--';
  }
};

export const bikeCompany = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '美团';
    case 2:
      return '青桔';
    case 3:
      return '哈啰';
    case 4:
      return '电单车企业1';
    case 5:
      return '电单车企业2';
    case 6:
      return '电单车企业3';
    case 7:
      return '其他';
    default:
      return '--';
  }
};
export const bikeCompanyCollect = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '美团';
    case 2:
      return '青桔';
    case 3:
      return '哈啰';
    case 4:
      return '电单车企业1';
    case 5:
      return '电单车企业2';
    case 6:
      return '电单车企业3';
    case 7:
      return '其他';
    default:
      return '--';
  }
};
export const isContractArea = (code) => {
  switch (parseInt(code)) {
    case 0:
      return '否';
    case 1:
      return '是';
    default:
      return '--';
  }
};
export const bikeCompanyOptions = (type) => {
  switch (type) {
    case 'bike':
      return [
        {value: 1, label: '美团'},
        {value: 2, label: '青桔'},
        {value: 3, label: '哈啰'},
      ];
    case 'electricBike':
      return [
        {value: 1, label: '美团'},
        {value: 2, label: '青桔'},
        {value: 3, label: '哈啰'},
      ];
    case 'bikeCompany':
      return [
        {value: 1, label: '美团'},
        {value: 2, label: '青桔'},
        {value: 3, label: '哈啰'},
        {value: 4, label: '电单车企业1'},
        {value: 5, label: '电单车企业2'},
        {value: 6, label: '电单车企业3'},
      ];

    case 'cityManagement':
      return [
        {value: 1, label: '市容环卫中心'},
        {value: 2, label: '其他环卫中心'},
      ];
    default:
      return [
        {value: 1, label: '美团'},
        {value: 2, label: '青桔'},
        {value: 3, label: '哈啰'},
        {value: 4, label: '电单车企业1'},
        {value: 5, label: '电单车企业2'},
        {value: 6, label: '电单车企业3'},
        {value: 7, label: '其他'},
      ];
  }
};

export const ruleSwitchs = (code) => {
  switch (parseInt(code)) {
    case 1:
      return <Badge color="#36CFC9" text="开" />;
    default:
      return <Badge color="#BFBFBF" text={<span style={{color: '#BFBFBF'}}>关</span>} />;
  }
};

export const trackFrequencyStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '3分钟/次';
    case 2:
      return '15分钟/次';
    case 3:
      return '30分钟/次';
    default:
      return <span className="text-gray">暂无数据</span>;
  }
};
export const trackFrequencyOptions = [
  {value: 1, label: '3分钟/次'},
  {value: 2, label: '15分钟/次'},
  {value: 3, label: '30分钟/次'},
];

const weekText = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '周一';
    case 2:
      return '周二';
    case 3:
      return '周三';
    case 4:
      return '周四';
    case 5:
      return '周五';
    case 6:
      return '周六';
    default:
      return '周日';
  }
};
export const attendanceDateStatus = (arr = []) => {
  return arr.map((item) => weekText(item)).join('、');
};
export const attendanceDateStep = (arr = []) => {
  if (arr.length > 1) {
    return [weekText(arr[0]), weekText(arr[arr.length - 1])].join('至');
  }
  return weekText(arr[0]);
};

export const areaNameShow = (areaName, title) => {
  const LargeSize = 20;
  if (areaName && areaName.length > LargeSize) {
    return (
      <Popover
        content={
          <p style={{width: 400}} className="tipInfo">
            {areaName}
          </p>
        }
        title={title}
        trigger="hover"
      >
        {areaName.slice(0, LargeSize)}…
      </Popover>
    );
  }
  return areaName;
};


export const bikeType = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '单车';
    case 2:
      return '电单车';
    default:
      return '--';
  }
};

export const zoomLevel = (code) => {
  // 层级 1:市 2:区域 3:街道
  switch (parseInt(code)) {
    case 1:
      return 12;
    case 2:
      return 14;
    case 3:
      return 16;
    default:
      return 12;
  }
};

export const guaranteeAreaStatus = (code) => {
  switch (parseInt(code)) {
    case 0:
      return <Badge color="#BFBFBF" text="未开始" />;
    case 1:
      return <Badge color="#36CFC9" text="进行中" />;
    case 2:
      return <Badge color="#595959" text="已结束" />;
    default:
      return '--';
  }
};
export const messageReadStatus = (code) => {
  switch (parseInt(code)) {
    case 0:
      return <Badge color="#595959" text="未读" />;
    case 1:
      return <Badge color="#36CFC9" text="已读" />;
    // case 2:
    //   return <Badge color="#595959" text="已结束" />;
    default:
      return '--';
  }
};
export const messageReadStatusExport = (code) => {
  switch (parseInt(code)) {
    case 0:
      return '未读';
    case 1:
      return '已读';
    // case 2:
    //   return <Badge color="#595959" text="已结束" />;
    default:
      return '--';
  }
};
export const isInWork = (code) => {
  switch (parseInt(code)) {
    case 0:
      return '未到岗';
    case 1:
      return '到岗';
    // case 2:
    //   return <Badge color="#595959" text="已结束" />;
    default:
      return '--';
  }
};
export const guaranteeAreaOptions = (type) => {
  switch (type) {
    default:
      return [
        {value: 0, label: '未开始'},
        {value: 1, label: '进行中'},
        {value: 2, label: '已结束'},
      ];
  }
};

export const onlineStatusTextlist = (code) => {
  switch (parseInt(code)) {
    case 0:
      return '离线';
    case 1:
      return '在线';
    case 2:
      return '异常';
    default:
      return '-';
  }
};

export const onDotType = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '普通';
    default:
      return '普通';
  }
};

export const onThresholdValuet = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '单车：开';
    default:
      return '单车：关';
  }
};

export const placeholders = (value, unit) => {
  if (value) {
    return value + unit;
  } else {
    return '-';
  }
};
// 管辖企业
export const getEnterprise = (value) => {
  const enterprise = typeof value === 'string' ? value?.split(',').map(Number) : value;
  return enterprise ?
    enterprise.length === 7 ?
      '全部企业' :
      enterprise?.map((item, index) => {
        return index === enterprise.length - 1 ? bikeCompany(item) : bikeCompany(item) + '/';
      }) :
    '无企业';
};

// 位置编号
export const locationNumber = (locationNo) => {
  return (
    <Tooltip
      title={
        locationNo.split('/').length > 1 ? (
          <div className="tool-tip-color">
            <div>
              <span>全部编号</span>
            </div>
            <Divider className="margin-t-s margin-b-s" />
            {locationNo?.replace(/\//g, ',')}
          </div>
        ) : null
      }
      color="white"
    >
      <span>{locationNo.split('/').length > 1 ? locationNo.split('/')[0] + '...' : locationNo.split('/')[0]}</span>
    </Tooltip>
  );
};

// 管辖区域
export const administerArea = (area) => {
  return (
    <Tooltip
      title={
        area.split('/').length > 1 ? (
          <div className="tool-tip-color">
            <div>
              <span>管辖区域</span>
            </div>
            <Divider className="margin-t-s margin-b-s" />
            {area}
          </div>
        ) : null
      }
      color="white"
    >
      <span>{area.split('/').length > 1 ? area.split('/')[0] + '...' : area.split('/')[0]}</span>
    </Tooltip>
  );
};

// 职务等级
export const jobRank = (value, state) => {
  // 1负责人 2 普通
  switch (parseInt(value)) {
    case 1:
      return <div className={state === 1 ? 'rank-general' : 'rank-general rank-state'}>普通</div>;
    case 2:
      return <div className={state === 1 ? 'rank-principal' : 'rank-principal rank-state'}>负责人</div>;
    default:
      return '--';
  }
};

// 职务等级
export const positionLevel = (value) => {
  // 1负责人 2 普通
  switch (parseInt(value)) {
    case 1:
      return '普通';
    case 2:
      return '负责人';
    default:
      return '暂无数据';
  }
};

// 截取小数点后两位
export const twoHours = (num) => {
  if (Number(num) === 0 || Number(num) === 0.0) {
    return Number(num).toFixed(2);
  } else {
    const data = num.toString();
    const index = data.lastIndexOf('.');
    if (index !== -1) {
      return data.substring(0, index + 3);
    } else {
      return Number(num).toFixed(2);
    }
  }
};

export const manageEnterprise = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '市环卫中心';
    default:
      return '其他环卫中心';
  }
};
export const requireTypeStatus = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '指定人数';
    case 2:
      return '指定人员';
    default:
      return '--';
  }
};

export const importContent = (code) => {
  switch (parseInt(code)) {
    case 1:
      return '车企全部区域单车';
    case 2:
      return '车企部分区域单车';
    default:
      return '--';
  }
};
export const importType = (code, errMsg) => {
  switch (parseInt(code)) {
    case 1:
      return <Badge color="#595959" text="成功" />;
    case 0:
      return <><Badge color="#BFBFBF" text={'失败'} />
        <Tooltip placement="topLeft" color={'white'}
          overlayInnerStyle={{color: 'black'}} title={errMsg}>
          <QuestionCircleOutlined className="question-icon"/>
        </Tooltip></>;
    case -1:
      return <Badge color="#36CFC9" text="审核中" />;
    default:
      return '--';
  }
};

export const importErrorInfo = (code, number) => {
  switch (parseInt(code)) {
    case 1:
      return '其他用户正在导入同车企车辆，为避免错误，本次导入自动取消。本次导入失败不消耗今日导入次数。';
    case 2:
      return '导入次数达到上限，单车批量导入失败。可前往【单车管理->导入记录】查看详情。';
    case 3:
      return `单车批量导入失败，检测到错误车辆数据共计${number}条。请前往【单车管理->导入记录】下载导入失败数据，请修改后，将信息复制到导入模版，重新导入。`;
    default:
      return '';
  }
};

export const personDataTitle = (str) => {
  // str='成都市成华区/锦江区/青羊区/金牛区/武侯区/天府新区/高新区';
  // str='成都市ss镇';
  // str='无区域';
  if (str === '全部区' || str ==='成都市全部区') return '成都市';
  else if (str === '无区域') return '区域统计数据';
  else if (str.indexOf('街道') !== -1 || str.indexOf('镇') !==-1) {
    const city = str.slice(str.indexOf('市') + 1, str.indexOf('区') + 1) ||
      str.slice(str.indexOf('市') + 1, str.indexOf('县') + 1) ||
      str.slice(str.indexOf('市') + 1, str.lastIndexOf('市') + 1) || '区域统计数据';
    return city;
  } else {
    let flag=true;
    const mainArea=['成华区', '锦江区', '青羊区', '金牛区', '武侯区', '天府新区', '高新区'];
    const cityList = str.slice(str.indexOf('市') + 1).split('/');
    if (cityList.length === 1) return cityList[0];
    else if (cityList.length === 7) {
      mainArea.forEach((item) => {
        if (!cityList.includes(item)) flag=false;
      });
      return flag?'主城区':'区域统计数据';
    } else {
      return '区域统计数据';
    }
  }
};

export const filingsDataTitle = (str) => {
  // str='成都市成华区/锦江区/青羊区/金牛区/武侯区/天府新区/高新区';
  // str='成都市成华市kj街道';
  // str='锦江区';
  const mainArea=['成华区', '锦江区', '青羊区', '金牛区', '武侯区', '天府新区', '高新区'];
  if (str === '无区域') return '区域备案数';
  else if (str.indexOf('街道') !== -1 || str.indexOf('镇') !==-1) {
    const city = str.slice(str.indexOf('市') + 1, str.indexOf('区') + 1) ||
      str.slice(str.indexOf('市') + 1, str.indexOf('县') + 1) ||
      str.slice(str.indexOf('市') + 1, str.lastIndexOf('市') + 1) || '区域备案数';
    return city !== '区域备案数' ? (mainArea.includes(city)?'主城区':city)+'备案数' :city;
  } else {
    let flag = true;
    let flagOne=false;
    const cityList = str.slice(str.indexOf('市') + 1).split('/');
    if (cityList.length === 1) {
      for (let i = 0; i < mainArea.length; i++) {
        if (cityList.includes(mainArea[i])) {
          flagOne = true;
          break;
        }
      }
      console.log(flagOne);
      return (flagOne ? '主城区':cityList[0])+'备案数';
    } else if (cityList.length === 7) {
      mainArea.forEach((item) => {
        if (!cityList.includes(item)) flag=false;
      });
      return (flag?'主城区':'区域') + '备案数';
    } else {
      return '区域备案数';
    }
  }
};

export const bikeDataTitle = (str) => {
  // str='成都市成华区/锦江区/青羊区/金牛区/武侯区/天府新区/高新区';
  // str='成都市ss区ss镇';
  // str='无区域';
  if (str === '全部区' || str ==='成都市全部区') return '成都市';
  else if (str === '无区域') return '区域统计数据';
  else if (str.indexOf('街道') !== -1 || str.indexOf('镇') !==-1) {
    return '区域统计数据';
  } else {
    let flag=true;
    const mainArea=['成华区', '锦江区', '青羊区', '金牛区', '武侯区', '天府新区', '高新区'];
    const cityList = str.slice(str.indexOf('市') + 1).split('/');
    if (cityList.length === 1) return cityList[0];
    else if (cityList.length === 7) {
      mainArea.forEach((item) => {
        if (!cityList.includes(item)) flag=false;
      });
      return flag?'主城区':'区域统计数据';
    } else {
      return '区域统计数据';
    }
  }
};
