import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Line, G2} from '@ant-design/charts';
import customTheme from './g2-theme.json';
import {isMockData} from '../../utils/judge';

// eslint-disable-next-line react/display-name
const DemoDualAxes = React.memo(({data, areaName}) => {
  const [lineData, setLineData]=useState([]);
  // const [chartWidth, setChartWidth]=useState('100%');
  const {registerTheme} = G2;

  registerTheme('custom-theme', customTheme);
  const config = {
    theme: 'custom-theme',
    // animation: false,
    data: lineData,
    xField: 'dateTime',
    yField: 'value',
    seriesField: 'name',
    xAxis: {
      tickCount: 2,
    },
    yAxis: {
      tickCount: 4,
      label: isMockData()?null:{},
    },
    legend: {
      position: 'top-right',
    },
    slider: {
      start: 0,
      end: 1,
      smooth: true,
    },
    autoFit: true,
    color: ['#EB2F96', '#85A5FF'],
    tooltip: isMockData()&&!areaName?false:{
      formatter: (datum) => {
        let value;
        if (datum.name.indexOf('人员')!==-1) {
          value= datum.value+'人';
        } else {
          value= datum.value+'辆';
        }
        return {name: datum.name, value: value};
      },
    },
  };
  useEffect(()=>{
    if (lineData!==data) {
      setLineData(data);
    }
  }, [data]);

  return <Line {...config}/>;
});

DemoDualAxes.propTypes = {
  data: PropTypes.array.isRequired,
  areaName: PropTypes.string,
};

export default DemoDualAxes;

