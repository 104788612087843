import React, {useEffect, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button, Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  TimePicker,
  Upload,
} from 'antd';
import {useTranslation} from 'react-i18next';
import {InfoCircleFilled, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {getApiListData, getAreaTreeFilter, getAreaTreeFilter2} from '../../api';
import {areaStreetTreeData} from './AreaTreeFuc';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const MAXSIZE = 5 * 1024 * 1024;

const EditForms = ({columnsForm, onFinish, loading, initData, onCancel,
  buttonStyle, pageLayout, buttonText, isSelected, selected, visible}) => {
  const {t} = useTranslation();
  const [settingForm] = Form.useForm();
  const [imgLoading, setImgLoading] = useState(false);
  const [fileFuc, setFileFuc] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectOptions, setSelectOptions] = useState({});
  const [areaOptions, setAreaOptions] = useState([]);
  const [cardIsAble, setCardIsAble]=useState(initData&&initData.jobType);
  const [district, setDistrict]=useState([]);
  const [districtIsAble, setDistrictIsAble]=useState(true);
  const [jobRank, setJobRank]=useState(1);

  // 区域选择
  const getAreaGroup = (params) => {
    getAreaTreeFilter(params, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type=!params?1:
      params.roleId===2?2:
      params.roleId===3?3:
      params.roleId===4?4: 0;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={(type===3&&sub.managerName)?'text-light-disabled':'text-light'}>
                  &nbsp;&nbsp;{sub.managerName || ''}
                </span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}
                    <span
                      className={(type===4&&aff.managerName)?'text-light-disabled':'text-light'}>
                      &nbsp;&nbsp;{aff.managerName || ''}
                    </span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  disabled: type===4&&aff.managerName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
      localStorage.setItem('areaOptions', JSON.stringify(data));
    });
  };
  const getAreaGroup2 = (params) => {
    getAreaTreeFilter2(params, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type=!params?1:
      params.roleId===2?2:
      params.roleId===3?3:
      params.roleId===4?4: 0;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={(type===4&&sub.managerName)?'text-light-disabled':'text-light'}
                > &nbsp;&nbsp;{sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}
                    <span
                      className={(type===4&&aff.managerName)?'text-light-disabled':'text-light'}>
                      &nbsp;&nbsp;{aff.managerName || ''}
                    </span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  disabled: type===4&&aff.managerName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };
  const getUserAreaGroup = (params) => {
    getAreaTreeFilter2(params, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type=!params?1:
      params.roleId===2?2:
      params.roleId===3?3:
      params.roleId===4?4: 0;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={(type===3&&sub.managerName)?'text-light-disabled':'text-light'}
                > &nbsp;&nbsp;{sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName&&sub.managerName!==initData.name,
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };
  const changeArea=(value)=>{
    if (value) {
      setDistrictIsAble(false);
      setDistrict(areaOptions.find((item)=>item.value===value).children);
      settingForm.resetFields(['areaId']);
    } else {
      setDistrictIsAble(true);
      settingForm.resetFields(['areaId']);
    }
  };
  const onChange = (value, selectedOptions) => {
    // console.log(selectedOptions);
    if (selectedOptions) {
      settingForm.setFieldsValue({
        areaManager: selectedOptions[1].managerName,
        streetManager: selectedOptions[2].managerName,
        areaId: selectedOptions[1].id,
        streetId: selectedOptions[2].id,
      });
    } else {
      settingForm.setFieldsValue({
        areaManager: '',
        streetManager: '',
        areaId: '',
        streetId: '',
      });
    }
  };
  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return (
            <span key={option.value}>
              {option.areaName}
            </span>
          );
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };

  const onSubmit = (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      if (key!=='areaManager'&&key!=='areaCascader') {
        const c=columnsForm.find(({name})=>(name===key));
        if (c) {
          if (typeof (values[key]) === 'object') {
            if (c.type === 'image') {
              data[key] = fileFuc;
            } else if (c.type === 'timePicker' || c.type === 'datePicker') {
              data[c.dataName[0]] = moment(values[key][0]).valueOf();
              data[c.dataName[1]] = moment(values[key][1]).valueOf();
            } else if (c.type==='date') {
              data[key] = moment(values[key]).format('YYYY-MM-DD');
            } else if (c.type==='areaCascaderMul') {
              data.areaId=areaStreetTreeData(
                  values.areaCascaderMul,
                  areaOptions,
                c.params.roleId===4?'street':'area');
            } else if (c.type==='areaCascaderMul2') {
              data.areaId=areaStreetTreeData(
                  values.areaCascaderMul2,
                  areaOptions,
                c.params.roleId===4?'street':'area');
            } else if (c.type==='jurisdiction') {
              data.jurisdiction=areaStreetTreeData(
                  values.jurisdiction,
                  areaOptions,
                  'street');
            } else {
              data[key] = values[key];
            }
          } else {
            if (c.type !== 'image') {
              data[key] = values[key] || values[key]==0 ? values[key] : '';
            } if (c.type === 'image') {
              data[key] = fileFuc;
            }
          }
        } else {
          data[key] = values[key] || values[key]==0 ? values[key] : '';
        }
      }
    });
    onFinish(data);
  };

  const uploadButton = (
    <div>
      {imgLoading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('upload')}
      </div>
    </div>
  );
  const disabledDate = (current) => {
    return current < moment().subtract(3, 'month') || current > moment().subtract(0, 'month');
  };
  const disabledYear = (current) => {
    return current > moment().endOf('day');
  };
  const ageFuc=(IdCard)=>{
    if (IdCard.length===18) {
      const birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14);
      const age= parseInt(moment(birthday).fromNow());
      return age||'身份证号输入有误，请确认！';
    }
    return '';
  };

  const workYear=(value)=>{
    if (value) {
      const year=moment(moment(value).format('YYYY-MM-DD')).fromNow();
      if (year.indexOf('年前')!== -1) {
        return parseInt(year);
      }
      return 0;
    }
    return '';
  };
  const fileProps = {
    multiple: false,
    showUploadList: false,
    listType: 'picture-card',
    accept: 'image/*',
    customRequest: (info) => {
      if (info.file.size < MAXSIZE) {
        setFileFuc(info.file);
        setImgLoading(true);
        getBase64(info.file, (imgUrl) => {
          setImageUrl(imgUrl);
          setImgLoading(false);
        });
      } else {
        message.warn(t('overSizeMsg'));
      }
    },
  };

  const formItem = (item) => {
    switch (item.type) {
      case 'input':
        return (
          <Input
            maxLength={item.maxLength||20}
            placeholder={item.placeholder||''}
            disabled={item.disabled}
            onBlur={(e)=>{
              if (item.onChangeKey) {
                const value=item.onChangeRule==='age'?(ageFuc(e.target.value)):e.target.value;
                settingForm.setFieldsValue({
                  [item.onChangeKey]: value,
                });
              }
            }}
          />
        );
      case 'inputCard':
        return (
          <Input
            maxLength={item.maxLength||20}
            placeholder={item.placeholder||''}
            disabled={cardIsAble==2 || cardIsAble==6?false:true}
            onBlur={(e)=>{
              if (item.onChangeKey) {
                const value=item.onChangeRule==='age'?(ageFuc(e.target.value)):e.target.value;
                settingForm.setFieldsValue({
                  [item.onChangeKey]: value,
                });
              }
            }}
          />
        );
      case 'pwdInput':
        return (
          <Input.Password
            maxLength={12}
            placeholder={item.placeholder||''}
            disabled={item.disabled}
          />
        );
      case 'select':
        return (
          <Select
            allowClear
            mode={item.mode || null}
            disabled={item.disabled}
            placeholder={item.placeholder || ''}
          >
            {item.options.map((option) => (
              <Select.Option key={option.label1 + option.value} value={option.value}>
                {option['label' + t('flag')]}
              </Select.Option>
            ))}
          </Select>);
      case 'selectRank':
        return (
          <Select
            mode={item.mode || null}
            placeholder={item.placeholder || ''}
            onChange={(value)=>{
              setJobRank(value);
              if (value===1) {
                settingForm.setFieldsValue({jurisdiction: null});
              }
            }}
          >
            {item.options.map((option) => (
              <Select.Option key={option.label1 + option.value} value={option.value}>
                {option['label' + t('flag')]}
              </Select.Option>
            ))}
          </Select>);
      case 'selectStaff':
        return (
          <Select
            allowClear
            mode={item.mode || null}
            disabled={item.disabled}
            placeholder={item.placeholder || ''}
            onChange={(value)=>{
              setCardIsAble(value);
              settingForm.setFieldsValue({licensePlate: ''});
            }}
          >
            {item.options.map((option) => (
              <Select.Option key={option.label1 + option.value} value={option.value}>
                {option['label' + t('flag')]}
              </Select.Option>
            ))}
          </Select>);
      case 'selectArea1':
        return (
          <Select
            allowClear
            disabled={item.disabled}
            options={areaOptions}
            onChange={changeArea}
            placeholder="请选择市(单选)"
          >
            {/* {item.options.map((option) => (
              <Select.Option key={option.label1 + option.value} value={option.value}>
                {option['label' + t('flag')]}
              </Select.Option>
            ))} */}
          </Select>);
      case 'selectArea2':
        return (
          <Select
            allowClear
            mode={'multiple'}
            disabled={districtIsAble}
            options={district}
            placeholder="请选择区(多选)"
            maxTagCount="responsive"
          >
            {/* {item.options.map((option) => (
              <Select.Option key={option.label1 + option.value} value={option.value}>
                {option['label' + t('flag')]}
              </Select.Option>
            ))} */}
          </Select>);
      case 'selectApi':
        return (
          <Select
            allowClear
            labelInValue={item.labelInValue || false}
            mode={item.mode || null}
            disabled={item.disabled}
            placeholder={item.placeholder || ''}
          >
            {selectOptions[item.name] && selectOptions[item.name].map((option) => (
              <Select.Option
                key={option[item.valueKey] + option[item.textKey]}
                value={option[item.valueKey]}
                hidden={option[item.valueKey]==1}
              >
                {option[item.textKey]}
              </Select.Option>
            ))}
          </Select>);
      case 'textArea':
        return (<Input.TextArea maxLength={200} placeholder={item.placeholder||''} disabled={item.disabled}/>);
      case 'number':
        return (<InputNumber
          min={item.min}
          max={item.max}
          placeholder={item.placeholder||''}
          step={item.step || 1}
          addonAfter={t(item.unit) || ''}
          style={{width: '100%'}}
          formatter={item.formatter ? (value) => parseInt(value) || '' : null}
          parser={item.parser ? (value) => parseInt(value || 0) || '' : null}
          disabled={item.disabled}
        />);
      case 'radio':
        return (<Radio.Group disabled={item.disabled}>
          {item.value.map((subItem) => (
            <Radio value={subItem.value} key={subItem.key}>{t(subItem.key)}</Radio>
          ))}
        </Radio.Group>);
      case 'checkbox':
        return (<Checkbox.Group disabled={item.disabled}>
          {item.value.map((subItem) => (
            <Checkbox value={subItem.value} key={subItem.key}>{t(subItem.key)}</Checkbox>
          ))}
        </Checkbox.Group>);
      case 'timePicker':
        return (<TimePicker.RangePicker disabled={item.disabled} style={{width: '100%'}} allowClear/>);
      case 'datePicker':
        return (<DatePicker.RangePicker
          disabledDate={disabledDate}
          disabled={item.disabled}
          style={{width: '100%'}}
          allowClear/>);
      case 'date':
        return (<DatePicker
          allowClear
          disabled={item.disabled}
          disabledDate={disabledYear}
          style={{width: '100%'}} onChange={(value)=>{
            if (item.onChangeKey) {
              const values=item.onChangeRule==='workYear'?(workYear(value)):value;
              settingForm.setFieldsValue({
                [item.onChangeKey]: values,
              });
            }
          }}/>);
      case 'image':
        return (<Upload
          name="avatar"
          {...fileProps}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>);
      case 'areaCascader':
        return (<Cascader
          placeholder="无区域"
          disabled={selected}
          allowClear={true}
          onChange={onChange}
          onClick={isSelected}
          options={areaOptions}
          displayRender={displayRender}
          style={{
            width: '100%',
          }}
        />);
      case 'areaCascaderMul':
        return (<Cascader
          placeholder="无区域"
          allowClear={false}
          multiple
          options={areaOptions}
          style={{
            width: '100%',
          }}
        />);
      case 'jurisdiction':
        return (<Cascader
          placeholder="无区域"
          allowClear={true}
          multiple
          disabled={jobRank!==2?true:false}
          options={jobRank===2?areaOptions:[]}
          style={{
            width: '100%',
          }}
          maxTagCount="responsive"
        />);
      case 'areaCascaderMul2':
        return (<Cascader
          placeholder="无区域"
          allowClear={false}
          multiple
          options={areaOptions}
          style={{
            width: '100%',
          }}
        />);
      default:
        return null;
    }
  };

  const getSelectOptions=(o)=>{
    getApiListData(o.url, o.params||null, o.resType, t('flag')).then((res)=>{
      // res.data.items.splice(res.data.items.findIndex((item) => item.roleId === 1), 1);
      setSelectOptions({
        ...selectOptions,
        [o.name]: res.data.items,
      });
    });
  };

  useEffect(()=>{
    if (columnsForm) {
      const S= columnsForm.filter(({type})=> type==='selectApi');
      const A= columnsForm.find(({type})=>type==='areaCascader'||type==='areaCascaderMul'||type==='jurisdiction');
      const A2= columnsForm.find(({type})=>type==='areaCascaderMul2');
      const A3= columnsForm.find(({type})=>type==='selectArea1');
      if (S) {
        S.forEach((item, index)=>{
          setTimeout(()=>{
            getSelectOptions(item);
          }, index*1000);
        });
      }
      if (A) {
        getAreaGroup(A.params);
      }
      if (A2) {
        getAreaGroup2(A2.params);
      }
      if (A3) {
        getUserAreaGroup(A3.params);
      }
    }
  }, []);
  useEffect(()=>{
    if (settingForm.getFieldValue('cityId') && areaOptions.length>0) {
      setDistrict(areaOptions.find((item)=> item.value===settingForm.getFieldValue('cityId')).children);
      setDistrictIsAble(false);
    }
  }, [settingForm.getFieldValue('cityId')]);

  useEffect(() => {
    if (initData) {
      // 数据回显
      settingForm.setFieldsValue(initData);
      const img= columnsForm.find(({type})=> type==='image');
      if (img&&initData[img.name]) {
        setImageUrl(initData[img.name]);
      }
      if (initData.positionLevel===2) {
        const result = initData.jurisdictionList?.map((str) => str.split(',').map((item) => parseInt(item)));
        settingForm.setFieldsValue({jurisdiction: result});
      }
      setCardIsAble(initData.jobType);
      setJobRank(initData?.positionLevel);
    } else {
      settingForm.resetFields();
    }
  }, [initData]);
  return (
    <div>
      <Spin spinning={loading} >
        <Form
          layout={pageLayout||layout}
          name="cameraForm"
          form={settingForm}
          onFinish={onSubmit}
        >
          <Row>
            {columnsForm.map((item, index) => (
              <Col
                span={item.col||24}
                key={item.name + item.type+index}
                className={item.col?'padding-r-m':''}
              >
                {item.type==='box'||item.type==='title'?(
                  <div>
                    {item.type==='title'?(
                      <h3 className="form-title">{item.label}</h3>
                    ):(
                      <>
                        {item.children.map((subItem)=>(
                          <Form.Item
                            key={`subItem${subItem.name}`}
                            name={subItem.name}
                            label={subItem.label}
                            hidden={subItem.hidden}
                            disabled={subItem.disabled}
                            rules={subItem.rules? subItem.rules:[{
                              required: subItem.required,
                              message: subItem['message2'],
                            }]}>
                            {formItem(subItem)}
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </div>
                ):(item.type==='inputCard'?
                  <Form.Item
                    noStyle={item.areaTip===1}
                    name={item.name}
                    label={item.label}
                    hidden={item.hidden}
                    disabled={item.disabled}
                    tooltip={item.tooltip||null}
                    rules={item.rules? item.rules:[{
                      required: cardIsAble==2 || cardIsAble==6?true:false,
                      message: item['message' + t('flag')],
                    }]}
                  >
                    {formItem(item)}
                  </Form.Item>:
                  item.type==='image'?
                  <Form.Item
                    noStyle={item.areaTip===1}
                    name={item.name}
                    label={item.label}
                    hidden={item.hidden}
                    disabled={item.disabled}
                    valuePropName="imageUrl"
                    getValueFromEvent={(e) => {}}
                    rules={item.rules? item.rules:[{
                      required: item.required,
                      message: item['message2'],
                    }]}
                  >
                    {formItem(item)}
                  </Form.Item>:
                  <Form.Item
                    noStyle={item.areaTip===1}
                    name={item.name}
                    label={item.label}
                    hidden={item.hidden}
                    disabled={item.disabled}
                    tooltip={item.tooltip||null}
                    rules={item.rules? item.rules:[{
                      required: item.required,
                      message: item['message' + t('flag')],
                    }]}>
                    {formItem(item)}
                  </Form.Item>
                )}
                <div style={item.areaTip===2?{
                  marginTop: ' -16px',
                }:null}>
                  {item.areaTip===1?(<div className="margin-b-m margin-t-s text-light">
                    <InfoCircleFilled style={{color: '#FAAD14'}} className="margin-r-s"/>
                  管辖区域非必填，未选择区域则将自动显示为“无区域”
                  </div>):
                item.areaTip===2?(<div className="margin-b-m margin-t-s text-light">
                  修改管理员用户角色后，其原管辖区域将变为“无区域”
                </div>):null
                  }
                </div>
              </Col>
            ))}
          </Row>
          <Form.Item noStyle>
            <div className={buttonStyle||'text-center'}>
              <div>
                <Space size="large">
                  <Button onClick={() => {
                    settingForm.resetFields();
                    setFileFuc(null);
                    setImageUrl(null);
                    onCancel();
                  }} type="primary" ghost>{t('cancel')}</Button>
                  <Button type="primary" htmlType="submit">
                    {buttonText ||t('submit')}
                  </Button>
                </Space>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

EditForms.propTypes = {
  columnsForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initData: PropTypes.object,
  loading: PropTypes.bool,
  buttonStyle: PropTypes.string,
  pageLayout: PropTypes.string,
  buttonText: PropTypes.string,
  isSelected: PropTypes.func,
  selected: PropTypes.bool,
  visible: PropTypes.bool,
};

export default EditForms;

