import React, {useState, useEffect} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {Card, Row, Col, Divider, Image, Space, Collapse, Table} from 'antd';
import {bikeCompany, carCompanyOrderStatus, staffStatus} from '../../../components/statusCollection';
import '../style.css';
import {useParams, useLocation} from 'react-router-dom';
import {Anchor} from 'antd';
const {Link} = Anchor;
import {getOrderDetail} from '../../../api';
const {Panel} = Collapse;
const workOrderDetails = () => {
  const [cityManagerMessage, setCityManagerMessage] = useState({});
  const [completeMessage, setCompleteMessage] = useState({});
  const [workOrderMessage, setWorkOrderMessage] = useState({});
  // const [tabsKey, setTabsKey] = useState(0);
  const paramKey = useParams();
  // const tabLabel=['', '工单信息', '工单完成信息'];
  useEffect(()=>{
    console.log(paramKey, 'paramKey');
    getOrderDetail({workOrderId: paramKey.id}, 2).then((res)=>{
      setCompleteMessage(res.data?.completeMessage);
      setCityManagerMessage(res.data?.managerMessage);
      setWorkOrderMessage(res.data?.workOrderMessage);
    });
  }, []);
  const proPagationRecordColumns=[
    {
      title: '姓名',
      dataIndex: 'operationName',
      key: 'operationName',
      onExport: true,
      width: 200,
      render: (_, record) =>record.completeStatus===1?record.operationName+` [完成人]`:record.operationName,
      exportRender: (record) => record.operationName,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      onExport: true,
      width: 160,
      render: (record, item) => staffStatus(record),
      exportRender: (record) => staffStatus(record.jobType),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 260,
      onExport: true,
    },
    {
      title: '个人工单状态',
      dataIndex: 'workOrderStatus',
      key: 'workOrderStatus',
      width: 180,
      // sorter: true,
      onExport: true,
      render: (record, item) => carCompanyOrderStatus(record),
      exportRender: (record) => carCompanyOrderStatus(record.workOrderStatus),
    },
    {
      title: '指派时间',
      dataIndex: 'distributeTime',
      key: 'distributeTime',
      width: 260,
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
    },
    {
      title: '完成时间',
      dataIndex: 'completeTime',
      width: 180,
      key: 'completeTime',
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
    },
    {
      title: '过期时间',
      dataIndex: 'expireTime',
      width: 180,
      key: 'expireTime',
      onExport: true,
      // render: (record)=>{
      //   return <>
      //     <span>{record||'--'}</span>
      //   </>;
      // },
      render: (_, record)=>{
        return <>
          {record.workOrderStatus===4?<>{'--'}</>:<span>{record.expireTime||'--'}</span>}
        </>;
      },
    },
    {
      title: '过期原因',
      dataIndex: 'expireReason',
      width: 180,
      key: 'expireReason',
      onExport: true,
      render: (record)=>{
        return <>
          <span>{record||'--'}</span>
        </>;
      },
    },
  ];

  const textMT = <div className="container-collapse"><div>
    <h3 >
      工单完成信息
    </h3>
    {<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>车企工单状态: </span>
          <span className="text-gray">
            {carCompanyOrderStatus(completeMessage?.meiTuanMessage?.completeMsg?.workOrderStatus)||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>接受时间：</span>
          <span className="text-gray">
            {completeMessage?.meiTuanMessage?.completeMsg?.acceptTime||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>完成时间：</span>
          <span className="text-gray">{completeMessage?.meiTuanMessage?.completeMsg?.completeTime||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>是否包片：</span>
          <span className="text-gray"> {completeMessage?.meiTuanMessage?.completeMsg?.contractArea===1?
          '是':'否'||'暂无数据'}</span>
        </Col>
        <Col span={17}>
          <span>工单完成地点：</span>
          <span className="text-gray">{completeMessage?.meiTuanMessage?.completeMsg?.completeAddress||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>工单完成照片: </span>
          {!completeMessage?.meiTuanMessage?.completeMsg?.completeImg?
          <span className="text-gray">{'暂无数据'}</span>:<div></div>}
        </Col>
        {completeMessage?.meiTuanMessage?.completeMsg?.completeImg?<Col span={24}>
          <Image.PreviewGroup className="preview-group">
            {<Space wrap >
              {completeMessage?.meiTuanMessage?.completeMsg?.completeImg?.split(';').map((item, index) => (
                <div className="image-item-container" key={index}
                >
                  <Image className="image-item-container-item" preview={true} key={index} src={item} />
                </div>
              ))
              }
            </Space>}
          </Image.PreviewGroup>
        </Col>:<span></span>}
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>备注:  </span>
          <span className="text-gray">
            {completeMessage?.meiTuanMessage?.completeMsg?.remark||'暂无数据'}
          </span>
        </Col>
      </Row>
    </div>
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维负责人信息
    </h3>
    {(<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>
              姓名：
          </span>
          <span className="text-gray">
            {completeMessage?.meiTuanMessage?.responsibleMsg?.responsibleName||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>所属企业：</span>
          <span className="text-gray">
            {bikeCompany(completeMessage?.meiTuanMessage?.responsibleMsg?.company)||'暂无数据'}
          </span>
        </Col>
        <Col span={10}>
          <span>负责人联系电话：</span>
          <span className="text-gray">
            {completeMessage?.meiTuanMessage?.responsibleMsg?.phoneNumber||'暂无数据'}
          </span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>管辖区域：</span>
          <span className="text-gray">{completeMessage?.meiTuanMessage?.responsibleMsg?.areaName||'暂无数据'}</span>
        </Col>
      </Row>
    </div>)
      // : (<Skeleton active/>)
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维人员派发记录
    </h3>
    <Table
      columns={proPagationRecordColumns}
      dataSource={completeMessage?.meiTuanMessage?.operationMsg}
    />
  </div>
  </div>;
  const textQG = <div className="container-collapse"><div>
    <h3>
      工单完成信息
    </h3>
    {<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>车企工单状态: </span>
          <span className="text-gray">
            {carCompanyOrderStatus(completeMessage?.qingJuMessage?.completeMsg?.workOrderStatus)||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>接受时间：</span>
          <span className="text-gray">
            {completeMessage?.qingJuMessage?.completeMsg?.acceptTime||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>完成时间：</span>
          <span className="text-gray">{completeMessage?.qingJuMessage?.completeMsg?.completeTime||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>是否包片：</span>
          <span className="text-gray"> {completeMessage?.qingJuMessage?.completeMsg?.contractArea===1?
          '是':'否'||'暂无数据'}</span>
        </Col>
        <Col span={17}>
          <span>工单完成地点：</span>
          <span className="text-gray">{completeMessage?.qingJuMessage?.completeMsg?.completeAddress||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>工单完成照片: </span>
          {!completeMessage?.qingJuMessage?.completeMsg?.completeImg?
          <span className="text-gray">{'暂无数据'}</span>:<div></div>}
        </Col>
        {completeMessage?.qingJuMessage?.completeMsg?.completeImg?<Col span={24}>
          <Image.PreviewGroup className="preview-group">
            {<Space wrap >
              {completeMessage?.qingJuMessage?.completeMsg?.completeImg?.split(';').map((item, index) => (
                <div className="image-item-container" key={index}
                >
                  <Image className="image-item-container-item" preview={true} key={index} src={item} />
                </div>
              ))
              }
            </Space>}
          </Image.PreviewGroup>
        </Col>:<span></span>}
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>备注:  </span>
          <span className="text-gray">
            {completeMessage?.qingJuMessage?.completeMsg?.remark||'暂无数据'}
          </span>
        </Col>
      </Row>
    </div>
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维负责人信息
    </h3>
    {(<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>
              姓名：
          </span>
          <span className="text-gray">
            {completeMessage?.qingJuMessage?.responsibleMsg?.responsibleName||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>所属企业：</span>
          <span className="text-gray">
            {bikeCompany(completeMessage?.qingJuMessage?.responsibleMsg?.company)||'暂无数据'}
          </span>
        </Col>
        <Col span={10}>
          <span>负责人联系电话：</span>
          <span className="text-gray">
            {completeMessage?.qingJuMessage?.responsibleMsg?.phoneNumber||'暂无数据'}
          </span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>管辖区域：</span>
          <span className="text-gray">{completeMessage?.qingJuMessage?.responsibleMsg?.areaName||'暂无数据'}</span>
        </Col>
      </Row>
    </div>)
      // : (<Skeleton active/>)
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维人员派发记录
    </h3>
    <Table
      columns={proPagationRecordColumns}
      dataSource={completeMessage?.qingJuMessage?.operationMsg}
    />
  </div>
  </div>;
  const textHL = <div className="container-collapse"><div>
    <h3>
      工单完成信息
    </h3>
    {<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>车企工单状态: </span>
          <span className="text-gray">
            {carCompanyOrderStatus(completeMessage?.haLuoMessage?.completeMsg?.workOrderStatus)||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>接受时间：</span>
          <span className="text-gray">
            {completeMessage?.haLuoMessage?.completeMsg?.acceptTime||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>完成时间：</span>
          <span className="text-gray">{completeMessage?.haLuoMessage?.completeMsg?.completeTime||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>是否包片：</span>
          <span className="text-gray"> {completeMessage?.haLuoMessage?.completeMsg?.contractArea===1?
          '是':'否'||'暂无数据'}</span>
        </Col>
        <Col span={17}>
          <span>工单完成地点：</span>
          <span className="text-gray">{completeMessage?.haLuoMessage?.completeMsg?.completeAddress||'暂无数据'}</span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>工单完成照片: </span>
          {!completeMessage?.haLuoMessage?.completeMsg?.completeImg?
          <span className="text-gray">{'暂无数据'}</span>:<div></div>}
        </Col>
        {completeMessage?.haLuoMessage?.completeMsg?.completeImg?<Col span={24}>
          <Image.PreviewGroup className="preview-group">
            {<Space wrap >
              {completeMessage?.haLuoMessage?.completeMsg?.completeImg?.split(';').map((item, index) => (
                <div className="image-item-container" key={index}
                >
                  <Image className="image-item-container-item" preview={true} key={index} src={item} />
                </div>
              ))
              }
            </Space>}
          </Image.PreviewGroup>
        </Col>:<span></span>}
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>备注:  </span>
          <span className="text-gray">
            {completeMessage?.haLuoMessage?.completeMsg?.remark||'暂无数据'}
          </span>
        </Col>
      </Row>
    </div>
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维负责人信息
    </h3>
    {(<div className="details-info margin-t-l">
      <Row className="margin-b-l" gutter={24}>
        <Col span={7}>
          <span>
              姓名：
          </span>
          <span className="text-gray">
            {completeMessage?.haLuoMessage?.responsibleMsg?.responsibleName||'暂无数据'}
          </span>
        </Col>
        <Col span={7}>
          <span>所属企业：</span>
          <span className="text-gray">
            {bikeCompany(completeMessage?.haLuoMessage?.responsibleMsg?.company)||'暂无数据'}
          </span>
        </Col>
        <Col span={10}>
          <span>负责人联系电话：</span>
          <span className="text-gray">
            {completeMessage?.haLuoMessage?.responsibleMsg?.phoneNumber||'暂无数据'}
          </span>
        </Col>
      </Row>
      <Row className="margin-b-l" gutter={24}>
        <Col span={24}>
          <span>管辖区域：</span>
          <span className="text-gray">{completeMessage?.haLuoMessage?.responsibleMsg?.areaName||'暂无数据'}</span>
        </Col>
      </Row>
    </div>)
      // : (<Skeleton active/>)
    }
  </div>
  <Divider />
  <div>
    <h3>
    运维人员派发记录
    </h3>
    <Table
      columns={proPagationRecordColumns}
      dataSource={completeMessage?.haLuoMessage?.operationMsg}
    />
  </div>
  </div>;

  const bikeCollapseName =['美团', '哈啰', '青桔'];
  const Tab = [
    <div key={'1'} >
      <Card bordered={false} className="padding-r-s padding-l-s padding-t-s padding-b-s" >
        <div>
          <Row className="margin-b-l" gutter={24}>
            <Col flex="auto">
              <h3 className="details-title" id="components-anchor-demo-basic">
                城市管理者
              </h3>
              {(<div className="details-info margin-t-l">
                <Row className="margin-b-l" gutter={24}>
                  <Col span={7}>
                    <span>
                      姓名：
                    </span>
                    <span className="text-gray">
                      {cityManagerMessage?.managerName || '暂无数据'}
                    </span>
                  </Col>
                  <Col span={7}>
                    <span>
                      工号：
                    </span>
                    <span className="text-gray">
                      {cityManagerMessage?.jobNumber || '暂无数据'}
                    </span>
                  </Col>
                  <Col span={10}>
                    <span>联系电话：</span>
                    <span className="text-gray">
                      {cityManagerMessage?.phoneNumber || '暂无数据'}
                    </span>
                  </Col>
                </Row>
                <Row className="margin-b-l" gutter={24}>
                  <Col span={7}>
                    <span>所属企业：</span>
                    <span className="text-gray">
                      {cityManagerMessage?.manageEnterprise === 1 ? '市环卫中心' : '其他环卫中心' || '暂无数据'}
                    </span>
                  </Col>
                  <Col span={7}>
                    <span>职务类型：</span>
                    <span className="text-gray">{cityManagerMessage?.personType === 1 ? '普通运维':
                     '城市管理者' || '暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l" gutter={24}>
                  <Col span={24}>
                    <span>所属区域：</span>
                    <span className="text-gray">{cityManagerMessage?.areaName || '暂无数据'}</span>
                  </Col>
                </Row>
              </div>)
              }
            </Col>
            <Col flex="80px">
              <Anchor affix={false} >
                <Link href="#components-anchor-demo-basic" title="城市管理者" />
                <Link href="#components-anchor-demo-static" title="工单信息" />
                <Link href="#components-anchor-demo-end" title="工单完成信息" />
              </Anchor>
              {/* <Anchor
                items={[
                  {
                    key: 'part-1',
                    href: '#components-anchor-demo-basic',
                    title: '城市管理者',
                  },
                  {
                    key: 'part-2',
                    href: '#components-anchor-demo-static',
                    title: '工单信息',
                  },
                  {
                    key: 'part-3',
                    href: '#components-anchor-demo-end',
                    title: '工单完成信息',
                  },
                ]}
              /> */}
            </Col>
          </Row>
          <Divider />
          <h3 className="details-title" id="components-anchor-demo-static">
          工单信息
          </h3>
          {<div className="details-info margin-t-l">
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>工单编号: </span>
                <span className="text-gray">
                  {workOrderMessage?.workOrderNumber||'暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>所属区域：</span>
                <span className="text-gray">
                  {workOrderMessage?.areaName||'暂无数据'}
                </span>
              </Col>
              <Col span={7}>
                <span>所属企业：</span>
                <span className="text-gray">{workOrderMessage?.workOrderEnterprise?.map((item, index)=>{
                  return bikeCompany(item)+` `;
                })||'暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>工单状态：</span>
                <span className="text-gray">{carCompanyOrderStatus(workOrderMessage?.workOrderStatus)||'暂无数据'}</span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>创建时间: </span>
                <span className="text-gray">
                  {workOrderMessage?.createTime||'暂无数据'}
                </span>
              </Col>
              <Col span={7}>
                <span>是否包片：</span>
                <span className="text-gray">{workOrderMessage?.contractArea===1?'是':'否'||'暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>包片企业：</span>
                <span className="text-gray">
                  {bikeCompany(workOrderMessage?.contractCompany)||'暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>工单地点：</span>
                <span className="text-gray">{workOrderMessage?.createAddress||'暂无数据'}</span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>工单照片 </span>
                {!workOrderMessage?.createImg?<span className="text-gray">{'暂无数据'}</span>:<span></span>}
              </Col>
              {workOrderMessage?.createImg&&<Col span={24}>
                <Image.PreviewGroup className="preview-group">
                  <Space wrap>
                    {workOrderMessage?.createImg?.split(';').map((item, index) => (
                      <div className="image-item-container" key={index}
                      >
                        <Image className="image-item-container-item" preview={true} key={index} src={item} />
                      </div>
                    ))
                    }
                  </Space>
                </Image.PreviewGroup>
              </Col>}
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>备注:  </span>
                <span className="text-gray">
                  {workOrderMessage?.remark}
                </span>
              </Col>
            </Row>
          </div>
          }
          <Divider />
          <h3 className="details-title" id="components-anchor-demo-end">
            工单完成信息
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l" gutter={24} accordion={true}>
              <Col span={24}>
                <Collapse bordered={false} expandIconPosition={'end'}>
                  <Panel header={bikeCollapseName[0] + `完成信息`} key={0}>
                    <p>{textMT}</p>
                  </Panel>
                  <Panel header={bikeCollapseName[1] + `完成信息`} key={1}>
                    <p>{textHL}</p>
                  </Panel>
                  <Panel header={bikeCollapseName[2] + `完成信息`} key={2}>
                    <p>{textQG}</p>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>,
  ];
  return (
    <div>
      <DetailsHeader pageName="秩序及调运工单详情" breadHead={useLocation().state}/>
      <div className="vertical-Tabs">
        {Tab[0]}
      </div>
    </div>
  );
};

export default workOrderDetails;

