import { Radar, G2 } from '@ant-design/charts';
import PropTypes from 'prop-types';
import React from 'react';
import { toThousands } from '../../utils/dataFormat';
import { bikeCompany } from '../statusCollection';
import customTheme from './g2-theme-cockpit';
const themeColor = '#75DDF5';

const RadarChart = ({ chartData }) => {
  const { registerTheme } = G2;

  registerTheme('custom-theme', customTheme);

  const data = chartData.map((d) => ({
    name: `${bikeCompany(d.bikeEnterprise)}（${toThousands(d.count)}）`,
    value: d.count,
  }));
  const config = {
    data,
    theme: 'custom-theme',
    xField: 'name',
    yField: 'value',
    padding: [16, 70, 16, 70],
    appendPadding: [16, 16, 16, 16],
    // animation: false,
    meta: {
      value: {
        alias: '备案单车数量',
        min: 0,
        nice: true,
        formatter: (v) => toThousands(v),
      },
    },
    tooltip: {
      title: (text) => text.split('（')[0],
      domStyles: {
        'g2-tooltip': {
          backgroundColor: 'rgba(0,81,165,0.6)',
          borderRadius: '8px',
          color: '#fff',
        },
      },
    },
    xAxis: {
      // line: null,
      // tickLine: null,
      // title: {
      //   style: {
      //     fontSize: '18px',
      //   },
      // },
      line: null,
      tickLine: null,
      grid: {
        line: {
          style: {
            lineDash: null,
            stroke:'rgba(255,255,255,0.6)'
          },
        },
      },
      label: {
        style:{
          fontSize: 16,
          fill:'#46F8FF'
        }
      }
    },
    yAxis: {
      label: false,
      line: {
        stroke: 'red',
      },
      grid: {
        alternateColor: 'rgba(255, 255, 255, 0.08)',
        line: {
          style: { stroke: '#50CBFF' }
        }
      },
    },
    color: themeColor,
    area: {
      color: themeColor,
    },
    lineStyle: () => {
      return {
        stroke: themeColor,
        lineWidth: 4,
        shadowColor: '#00D3FF',
        shadowBlur: 25,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
      };
    },
    style: {
      width: '100%',
      height: '100%',
    },
  };
  return (
    <Radar {...config} />
  );
};
RadarChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
};

export default RadarChart;
