/* eslint-disable no-debugger */
import React, {useState} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {Card, Col, Divider, Row, Skeleton, Spin, Table} from 'antd';
import {getMonitorDetails} from '../../../api';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import downLoad from '../../../asset/Upload.png';
import {exportExcel} from '../../../components/excal/excel';
import {bikeType as typeBike, bikeCompany} from '../../../components/statusCollection';

const MonitorDetails = () => {
  const [tableData, setTableData] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailType, setDetailType] = useState();
  const [detailInfo, setDetailInfo] = useState();
  const location = useLocation();
  const [notFiledCount, setNotFiledCount] = useState();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
    size: 'small',
    showSizeChanger: true,
  });

  const tableDate = () => {
    const equipmentNo = location.pathname.split('/')[5];
    const count = location.pathname.split('/')[6];
    const date = {
      pageNum: 1,
      pageSize: 10,
      bikeType: 1,
      detailType: parseInt(location.pathname.split('/')[4]),
      equipmentBatchNo: equipmentNo === 'null' ? null : equipmentNo,
      mobileEquipmentIdentity: JSON.parse(localStorage.getItem('detail')).mobileEquipmentIdentity,
      count: count,
    };
    return date;
  };

  // get table Data
  const getTableList = (data) => {
    setLoading(true);
    getMonitorDetails(data, 2)
        .then((res) => {
          const pageNum = res.data.pageNum;
          const pageSize = res.data.pageSize;

          if (res.data?.items?.detailsList) {
            setTableData(
                res.data.items.detailsList.map((item, index) => ({
                  ...item,
                  index: (res.data.pageNum - 1) * res.data.pageSize + 1 + index,
                })),
            );

            setTableInfo(res.data?.items);
            setPagination({
              ...pagination,
              pageSize: pageSize,
              current: pageNum,
              total: res.data.totalPage * res.data.pageSize,
            });
          }
        })
        .finally(() => setLoading(false));
  };

  const onTableChange = (pages) => {
    const date = tableDate();
    (date.pageNum = pages.current), (date.pageSize = pages.pageSize), getTableList(date);
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      exportRender: (render) => render.index,
    },

    {
      title: '车辆类型',
      dataIndex: 'bikeType',
      key: 'bikeType',
      onExport: true,
      // eslint-disable-next-line
      render: (bikeType) => (bikeType === 1 ? '单车' : '电单车'),
      exportRender: (bikeType) => typeBike(bikeType.bikeType),
    },
    {
      title: '所属车企 ',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      onExport: true,
      // eslint-disable-next-line
      render: (bikeEnterprise) => (bikeEnterprise === 1 ? '美团' : bikeEnterprise === 2 ? '青桔' : '哈啰'),
      exportRender: (bikeEnterprise) => bikeCompany(bikeEnterprise.bikeEnterprise),
    },
    {
      title: '实体车牌号',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      // eslint-disable-next-line
      render: (licensePlate) => (licensePlate ? licensePlate : '-'),
      type: 'notRecord',
      onExport: true,
    },
    {
      title: '车锁号',
      dataIndex: 'lockNumber',
      key: 'lockNumber',
      // eslint-disable-next-line
      type: 'notRecord',
      render: (lockNumber) => (lockNumber ? lockNumber : '-'),
      onExport: true,
    },
    {
      title: '蓝牙MAC地址',
      key: 'bluetoothMacAddress',
      dataIndex: 'bluetoothMacAddress',
      onExport: true,
    },
    {
      title: '车架号',
      key: 'frameNumber',
      dataIndex: 'frameNumber',
      type: 'notRecord',
      render: (frameNumber) => (frameNumber ? frameNumber : '-'),
      onExport: true,
    },
    {
      title: '所属区域',
      key: 'bikeName',
      dataIndex: 'bikeName',
      type: 'notRecord',
      render: (bikeName) => (bikeName ? bikeName : '-'),
      onExport: true,
    },
    {
      title: 'RSSI',
      key: 'signalStrengthIndicator',
      dataIndex: 'signalStrengthIndicator',
      onExport: true,
    },
  ];

  // 未备案列表处理
  const notRecordColumns = (detailType) => {
    if (detailType === 3) {
      // eslint-disable-next-line no-prototype-builtins
      const data = columns.filter((item) => !item.hasOwnProperty('type'));
      setNotFiledCount([...data]);
    }
  };

  const onExportExcel = () => {
    const arr = [];
    const exportColumns = detailType === 3 ? [...notFiledCount] : [...columns];
    exportColumns.push({title: '最近更新时间', dataIndex: 'time', key: 'time', onExport: true});
    const data = tableDate();
    (data.pageSize = 100000),
    getMonitorDetails(data, 2).then((res) => {
      res.data.items.detailsList.map((item, index) => {
        arr.push(Object.assign(item, {time: res.data.items.lastUpdateTime}));
      });
      exportExcel(
          `${detailType === 1 ? '实时' : detailType === 2 ? '跨区域' : '未备案'}车辆列表`,
          exportColumns,
          res.data.items.detailsList.map((item, index) => ({
            ...item,
            index: (1 - 1) * 10 + 1 + index,
          })),
      );
    });

    setExportLoading(false);
  };

  useEffect(() => {
    setDetailInfo(JSON.parse(localStorage.getItem('detail')));
    const date = tableDate();
    getTableList(date);
    setDetailType(parseInt(location.pathname.split('/')[4]));
    notRecordColumns(parseInt(location.pathname.split('/')[4]));
  }, []);
  return (
    <div>
      <Spin spinning={exportLoading} tip="列表导出中...">
        <DetailsHeader
          pageName={detailType === 1 ? '实时单车数详情' : detailType === 2 ? '跨区域单车数详情' : '未备案单车数详情'}
        />
        <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
          <div>
            <h3 className="details-title" id="basic-information">
              设备信息
            </h3>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="details-info margin-t-l">
                <Row gutter={24} className="margin-b-l">
                  <Col span={8}>
                    设备IMEI：<span>{detailInfo?.mobileEquipmentIdentity || '-'}</span>
                  </Col>
                  <Col span={8}>
                    设备名称：<span>{detailInfo?.equipmentName || '-'}</span>
                  </Col>
                  <Col span={8}>
                    所属区域：<span>{detailInfo?.areaName || '-'}</span>
                  </Col>
                </Row>
                <Row gutter={24} className="margin-b-l">
                  <Col span={8}>
                    MAC地址：<span>{detailInfo?.equipmentMac || '-'}</span>
                  </Col>
                  <Col span={12}>
                    位置编号：<span>{detailInfo?.locationNo?.replace(/\//g, ',') || '-'}</span>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <Divider />
          <div style={{marginRight: '70px'}}>
            <div id="person-setting"></div>
            <div className="margin-t-l">
              <span className="text-20 margin-b-l margin-r-s" style={{display: 'inline-block'}}>
                车辆列表
              </span>
              {tableData && <img src={downLoad} onClick={() => onExportExcel()} />}
              <Row className="margin-b-m">
                <Col span={8}>最近更新时间：{tableInfo?.lastUpdateTime || '-'}</Col>
                <Col>
                  {detailType === 1 ? '实时单车数：' : detailType === 2 ? '跨区域单车数' : '未备案单车数：'}
                  {tableInfo?.count || '-' || '-'}
                </Col>
              </Row>
              <Table
                loading={loading}
                dataSource={tableData || []}
                pagination={pagination}
                columns={detailType === 3 ? notFiledCount : columns}
                onChange={onTableChange}
                rowKey={(record) => record.index}
                scroll={{x: 1200}}
              />
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};
export default MonitorDetails;
