import {isMockData} from '../../../../utils/judge';

// 演示修改统计数据
const randomNum = () => {
  const min = 629785;
  const max = 629985;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const mockBikeScreenStatistics = (oldData, type) => {
  const isChangeData = isMockData();
  let data = oldData || {};

  if (!isChangeData) {
    return data;
  }

  switch (type) {
    case '方案一':
      // 1.全市备案单车总数：固定数据，计算方式为“当前真实区域备案单车数 + 311100”
      // 2.区域实时监测单车数：629785-629985区间内波动；
      data = {
        ...oldData,
        allFiledNum: oldData.filedNum + 311100,
        areaMonitorNum: randomNum(),
      };
      return data;
    case '方案二':
      // 1.全市备案单车数991060，主城区备案单车数679960；
      // 2.区域实时监测单车数：629785-629985区间内波动；
      data = {
        ...oldData,
        allFiledNum: 991060,
        filedNum: 679960,
        areaMonitorNum: randomNum(),
      };
      return data;
    default:
      return data;
  }
};

// 演示修改雷达图数据
export const mockBikeScreenMapRadar = (oldData, type) => {
  const isChangeData = isMockData();
  let data = oldData || [];

  if (!isChangeData) {
    return data;
  }
  switch (type) {
    case '方案二':
      // 2）车企备案数雷达图表：美团246986、青桔单车281984、哈啰单车150990。
      return data = [
        {
          'bikeEnterprise': 1,
          'count': 246986,
        },
        {
          'bikeEnterprise': 2,
          'count': 281984,
        },
        {
          'bikeEnterprise': 3,
          'count': 150990,
        },
      ];
    default:
      return data;
  }
};

export const mockBikeScreenLine = [{
  'date': '2023/11/28 23:30',
  'count': 1223,
}, {
  'date': '2023/11/28 23:34',
  'count': 1234,
}, {
  'date': '2023/11/28 23:38',
  'count': 1235,
}, {
  'date': '2023/11/28 23:42',
  'count': 1300,
}, {
  'date': '2023/11/28 23:46',
  'count': 1301,
}, {
  'date': '2023/11/28 23:50',
  'count': 1409,
}, {
  'date': '2023/11/28 23:54',
  'count': 0,
}, {
  'date': '2023/11/28 23:58',
  'count': 0,
}, {
  'date': '2023/11/29 00:02',
  'count': 0,
}, {
  'date': '2023/11/29 00:06',
  'count': 0,
}, {
  'date': '2023/11/29 00:10',
  'count': 0,
}, {
  'date': '2023/11/29 00:14',
  'count': 0,
}, {
  'date': '2023/11/29 00:18',
  'count': 0,
}, {
  'date': '2023/11/29 00:22',
  'count': 0,
}, {
  'date': '2023/11/29 00:26',
  'count': 0,
}, {
  'date': '2023/11/29 00:30',
  'count': 0,
}, {
  'date': '2023/11/29 00:34',
  'count': 0,
}, {
  'date': '2023/11/29 00:38',
  'count': 0,
}, {
  'date': '2023/11/29 00:42',
  'count': 0,
}, {
  'date': '2023/11/29 00:46',
  'count': 0,
}, {
  'date': '2023/11/29 00:50',
  'count': 0,
}, {
  'date': '2023/11/29 00:54',
  'count': 0,
}, {
  'date': '2023/11/29 00:58',
  'count': 0,
}, {
  'date': '2023/11/29 01:02',
  'count': 0,
}, {
  'date': '2023/11/29 01:06',
  'count': 0,
}, {
  'date': '2023/11/29 01:10',
  'count': 0,
}, {
  'date': '2023/11/29 01:14',
  'count': 0,
}, {
  'date': '2023/11/29 01:18',
  'count': 0,
}, {
  'date': '2023/11/29 01:22',
  'count': 0,
}, {
  'date': '2023/11/29 01:26',
  'count': 0,
}, {
  'date': '2023/11/29 01:30',
  'count': 0,
}, {
  'date': '2023/11/29 01:34',
  'count': 0,
}, {
  'date': '2023/11/29 01:38',
  'count': 0,
}, {
  'date': '2023/11/29 01:42',
  'count': 0,
}, {
  'date': '2023/11/29 01:46',
  'count': 0,
}, {
  'date': '2023/11/29 01:50',
  'count': 0,
}, {
  'date': '2023/11/29 01:54',
  'count': 0,
}, {
  'date': '2023/11/29 01:58',
  'count': 0,
}, {
  'date': '2023/11/29 02:02',
  'count': 0,
}, {
  'date': '2023/11/29 02:06',
  'count': 0,
}, {
  'date': '2023/11/29 02:10',
  'count': 0,
}, {
  'date': '2023/11/29 02:14',
  'count': 0,
}, {
  'date': '2023/11/29 02:18',
  'count': 0,
}, {
  'date': '2023/11/29 02:22',
  'count': 0,
}, {
  'date': '2023/11/29 02:26',
  'count': 0,
}, {
  'date': '2023/11/29 02:30',
  'count': 0,
}, {
  'date': '2023/11/29 02:34',
  'count': 0,
}, {
  'date': '2023/11/29 02:38',
  'count': 0,
}, {
  'date': '2023/11/29 02:42',
  'count': 0,
}, {
  'date': '2023/11/29 02:46',
  'count': 0,
}, {
  'date': '2023/11/29 02:50',
  'count': 0,
}, {
  'date': '2023/11/29 02:54',
  'count': 0,
}, {
  'date': '2023/11/29 02:58',
  'count': 0,
}, {
  'date': '2023/11/29 03:02',
  'count': 0,
}, {
  'date': '2023/11/29 03:06',
  'count': 0,
}, {
  'date': '2023/11/29 03:10',
  'count': 0,
}, {
  'date': '2023/11/29 03:14',
  'count': 0,
}, {
  'date': '2023/11/29 03:18',
  'count': 0,
}, {
  'date': '2023/11/29 03:22',
  'count': 0,
}, {
  'date': '2023/11/29 03:26',
  'count': 0,
}, {
  'date': '2023/11/29 03:30',
  'count': 0,
}, {
  'date': '2023/11/29 03:34',
  'count': 0,
}, {
  'date': '2023/11/29 03:38',
  'count': 0,
}, {
  'date': '2023/11/29 03:42',
  'count': 0,
}, {
  'date': '2023/11/29 03:46',
  'count': 0,
}, {
  'date': '2023/11/29 03:50',
  'count': 0,
}, {
  'date': '2023/11/29 03:54',
  'count': 0,
}, {
  'date': '2023/11/29 03:58',
  'count': 0,
}, {
  'date': '2023/11/29 04:02',
  'count': 0,
}, {
  'date': '2023/11/29 04:06',
  'count': 0,
}, {
  'date': '2023/11/29 04:10',
  'count': 0,
}, {
  'date': '2023/11/29 04:14',
  'count': 0,
}, {
  'date': '2023/11/29 04:18',
  'count': 0,
}, {
  'date': '2023/11/29 04:22',
  'count': 0,
}, {
  'date': '2023/11/29 04:26',
  'count': 0,
}, {
  'date': '2023/11/29 04:30',
  'count': 0,
}, {
  'date': '2023/11/29 04:34',
  'count': 0,
}, {
  'date': '2023/11/29 04:38',
  'count': 0,
}, {
  'date': '2023/11/29 04:42',
  'count': 0,
}, {
  'date': '2023/11/29 04:46',
  'count': 0,
}, {
  'date': '2023/11/29 04:50',
  'count': 0,
}, {
  'date': '2023/11/29 04:54',
  'count': 0,
}, {
  'date': '2023/11/29 04:58',
  'count': 0,
}, {
  'date': '2023/11/29 05:02',
  'count': 0,
}, {
  'date': '2023/11/29 05:06',
  'count': 0,
}, {
  'date': '2023/11/29 05:10',
  'count': 0,
}, {
  'date': '2023/11/29 05:14',
  'count': 0,
}, {
  'date': '2023/11/29 05:18',
  'count': 0,
}, {
  'date': '2023/11/29 05:22',
  'count': 0,
}, {
  'date': '2023/11/29 05:26',
  'count': 0,
}, {
  'date': '2023/11/29 05:30',
  'count': 0,
}, {
  'date': '2023/11/29 05:34',
  'count': 0,
}, {
  'date': '2023/11/29 05:38',
  'count': 0,
}, {
  'date': '2023/11/29 05:42',
  'count': 0,
}, {
  'date': '2023/11/29 05:46',
  'count': 0,
}, {
  'date': '2023/11/29 05:50',
  'count': 0,
}, {
  'date': '2023/11/29 05:54',
  'count': 0,
}, {
  'date': '2023/11/29 05:58',
  'count': 0,
}, {
  'date': '2023/11/29 06:02',
  'count': 0,
}, {
  'date': '2023/11/29 06:06',
  'count': 0,
}, {
  'date': '2023/11/29 06:10',
  'count': 0,
}, {
  'date': '2023/11/29 06:14',
  'count': 0,
}, {
  'date': '2023/11/29 06:18',
  'count': 0,
}, {
  'date': '2023/11/29 06:22',
  'count': 0,
}, {
  'date': '2023/11/29 06:26',
  'count': 0,
}, {
  'date': '2023/11/29 06:30',
  'count': 0,
}, {
  'date': '2023/11/29 06:34',
  'count': 0,
}, {
  'date': '2023/11/29 06:38',
  'count': 0,
}, {
  'date': '2023/11/29 06:42',
  'count': 0,
}, {
  'date': '2023/11/29 06:46',
  'count': 0,
}, {
  'date': '2023/11/29 06:50',
  'count': 0,
}, {
  'date': '2023/11/29 06:54',
  'count': 0,
}, {
  'date': '2023/11/29 06:58',
  'count': 0,
}, {
  'date': '2023/11/29 07:02',
  'count': 0,
}, {
  'date': '2023/11/29 07:06',
  'count': 0,
}, {
  'date': '2023/11/29 07:10',
  'count': 0,
}, {
  'date': '2023/11/29 07:14',
  'count': 0,
}, {
  'date': '2023/11/29 07:18',
  'count': 0,
}, {
  'date': '2023/11/29 07:22',
  'count': 0,
}, {
  'date': '2023/11/29 07:26',
  'count': 0,
}, {
  'date': '2023/11/29 07:30',
  'count': 0,
}, {
  'date': '2023/11/29 07:34',
  'count': 0,
}, {
  'date': '2023/11/29 07:38',
  'count': 0,
}, {
  'date': '2023/11/29 07:42',
  'count': 0,
}, {
  'date': '2023/11/29 07:46',
  'count': 0,
}, {
  'date': '2023/11/29 07:50',
  'count': 0,
}, {
  'date': '2023/11/29 07:54',
  'count': 0,
}, {
  'date': '2023/11/29 07:58',
  'count': 0,
}, {
  'date': '2023/11/29 08:02',
  'count': 0,
}, {
  'date': '2023/11/29 08:06',
  'count': 0,
}, {
  'date': '2023/11/29 08:10',
  'count': 0,
}, {
  'date': '2023/11/29 08:14',
  'count': 0,
}, {
  'date': '2023/11/29 08:18',
  'count': 0,
}, {
  'date': '2023/11/29 08:22',
  'count': 0,
}, {
  'date': '2023/11/29 08:26',
  'count': 0,
}, {
  'date': '2023/11/29 08:30',
  'count': 0,
}, {
  'date': '2023/11/29 08:34',
  'count': 0,
}, {
  'date': '2023/11/29 08:38',
  'count': 0,
}, {
  'date': '2023/11/29 08:42',
  'count': 0,
}, {
  'date': '2023/11/29 08:46',
  'count': 0,
}, {
  'date': '2023/11/29 08:50',
  'count': 0,
}, {
  'date': '2023/11/29 08:54',
  'count': 14261,
}, {
  'date': '2023/11/29 08:58',
  'count': 13261,
}, {
  'date': '2023/11/29 09:02',
  'count': 23144,
}, {
  'date': '2023/11/29 09:06',
  'count': 24561,
}, {
  'date': '2023/11/29 09:10',
  'count': 26811,
}, {
  'date': '2023/11/29 09:14',
  'count': 10546,
}, {
  'date': '2023/11/29 09:18',
  'count': 546,
}, {
  'date': '2023/11/29 09:22',
  'count': 1546,
}, {
  'date': '2023/11/29 09:26',
  'count': 1646,
}];
