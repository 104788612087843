import React, {useEffect, useState} from 'react';
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom';
import DetailsHeader from '../../../components/detailsHeader';
import FormOfDrawer from './FormOfDrawer';
import {Anchor, Button, Card, Col, Divider, Image, message, Modal, Row, Skeleton, Space, Form} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {deleteStaff, getStaffDetails, quickSetting, changeStaffStatus, getCityDetails} from '../../../api';
import politics from '../configs/politics.json';
import emergencyRelation from '../configs/emergencyRelation.json';
import health from '../configs/health.json';
import maritalStatus from '../configs/maritalStatus.json';
import degree from '../configs/degree.json';
import moment from 'moment';
import QuickSetting from '../../../components/quickSetting/QuickSetting';
import {
  staffStatus,
  trackFrequencyStatus,
  workStatus,
  bikeCompany,
  onlineStatus,
  positionLevel,
  manageEnterprise,
} from '../../../components/statusCollection';
import photo from '../../../asset/photo.png';
import PropTypes from 'prop-types';
import '../style.css';

const StaffDetails = ({menuType}) => {
  const [form] = Form.useForm();
  const ng = useNavigate();
  const l = useLocation().pathname.split('/');
  const d = useParams();
  const link = l.length > 4 ? `/${l[1]}/${l[2]}/position/${d.id}` : `/${l[1]}/position/${d.id}`;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState('quick');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [formType, setFormType] = useState('');
  const [updateList, setUpdateList] = useState(null);
  const [selected, setSelected] = useState(false);
  const [refuseModal, setRefuseModal] = useState(false);
  const [refuseModalText, setRefuseModalText] = useState('');
  const [detailsList, setDetailsList] = useState(
    menuType === 'staff' ?
      {
        jobInfoVo: {},
        operationInfoVo: {},
        personnelSettingsVo: {},
      } :
      {
        jobInfoVo: {},
        operationInfoVo: {},
        cityPersonSettingsVO: {},
      },
  );
  const [editData, setEditData] = useState({});
  const [attendanceRuleList, setAttendanceRuleList] = useState([]);
  const [quickMessage, setQuickMessage] = useState();
  const [fromValue, setfromValue] = useState();

  const update = (e) => {
    setUpdateList(e);
  };
  // 禁用启动接口
  const isAble = (operationId, type, toast) => {
    changeStaffStatus({operationId, type}, 2)
        .then((res) => {
          if (res.data) {
            setRefuseModalText(res.data);
            setRefuseModal(true);
          } else {
            message.success(toast);
            setUpdateList(updateList + 1);
          }
        })
        .finally(() => {
          setVisible(false);
        });
  };
  // 删除人员接口
  const delStaff = () => {
    deleteStaff(l[4], 2)
        .then((res) => {
          if (res.data) {
            setRefuseModalText(res.data);
            setRefuseModal(true);
          } else {
            message.success(menuType === 'staff' ? '运维人员删除成功' : '城市管理者删除成功');
            if (menuType === 'staff') {
              ng('/personnelManagement/staffManagement');
            } else if (menuType === 'city') {
              ng('/personnelManagement/urbanManagement');
            }
          }
        })
        .finally(() => {
          setVisible(false);
        });
  };
  // 删除禁用
  const confirmDisable = (
    <>
      <div className="disable-model">
        <ExclamationCircleOutlined className="disable-model-icon" />
        <div>
          <p className="disable-title">
            {(modalType === 'delete' && '确认删除人员') || (modalType === 'forbidden' && '确认禁用人员')}
          </p>
          <div>
            {(modalType === 'delete' && '删除人员信息后不可恢复，是否确认删除以下人员信息。') ||
              (modalType === 'forbidden' &&
                `禁用后该人员将无法登录单车运维APP，禁用人员的工作状态将变为离职${menuType==='staff'?'，并被自动移出当前考勤组':''}。`)}
          </div>
          <div className="details-del">
            <div className="del-staffManage">
              姓名：
              {(detailsList.operationInfoVo && detailsList.operationInfoVo.name) || (
                <span className="text-gray">暂无数据</span>
              )}
            </div>
            <div>
              联系电话：
              {(detailsList.operationInfoVo && detailsList.operationInfoVo.phoneNumber) || '暂无数据'}
            </div>
          </div>
          <div style={{textAlign: 'right'}}>
            <Space size="small">
              <Button
                danger
                type="primary"
                onClick={() => {
                  if (modalType === 'delete') {
                    delStaff();
                  } else if (modalType === 'forbidden') {
                    isAble(
                        detailsList?.operationInfoVo.id,
                        0,
                      menuType === 'staff' ? '运维人员禁用成功' : '城市管理者禁用成功',
                    );
                  }
                }}
              >
                {(modalType === 'delete' && '删除') || (modalType === 'forbidden' && '禁用')}
              </Button>
              <Button onClick={() => setVisible(false)}>取消</Button>
            </Space>
          </div>
        </div>
      </div>
    </>
  );
  const display = (arr, value) => {
    if (Reflect.ownKeys(detailsList.operationInfoVo).length !== 0) {
      for (let i = 0; i < arr.length; i++) {
        if (value == arr[i].value) {
          return arr[i].label1;
        }
      }
    } else {
      return <span className="text-gray">暂无数据</span>;
    }
  };
  const ageFuc = (IdCard) => {
    if (IdCard) {
      if (IdCard.length === 18) {
        const birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14);
        const age = parseInt(moment(birthday).fromNow());
        return age || '身份证号输入有误，请确认！';
      }
      return '';
    }
  };
  const workYear = (value) => {
    if (value) {
      const year = moment(value).fromNow();
      if (year.indexOf('年前') !== -1) {
        return parseInt(year);
      }
      return 0;
    }
    return '';
  };
  // 编辑运维人员数据
  const handleEditData = () => {
    const obj =
      menuType === 'staff' ?
        {
          ...detailsList.jobInfoVo,
          ...detailsList.operationInfoVo,
          ...detailsList.personnelSettingsVo,
        } :
        {
          ...detailsList.jobInfoVo,
          ...detailsList.operationInfoVo,
          ...detailsList.cityPersonSettingsVO,
        };
    obj.entryDate = obj.entryDate ? moment(obj.entryDate) : null;
    obj.age = ageFuc(obj.idCard);
    obj.workingYears = obj.entryDate ? workYear(obj.entryDate) : null;
    obj.sex = obj.sex!==0? obj.sex|| null:obj.sex;
    obj.nation = obj.nation || null;
    if (obj.areaId && obj.streetId) {
      obj.areaCascader = [obj.cityId, obj.areaId, obj.streetId];
    }
    obj.file = obj.imageUrl;
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        // obj[key]='';
        delete obj[key];
      }
    });
    // 编辑数据
    setEditData(obj);
    setAttendanceRuleList(
        (detailsList.personnelSettingsVo && detailsList.personnelSettingsVo.attendanceRuleList) || [],
        // [{earliestTime: '13:32:26', latestTime: '18:00:00'}],
    );
  };

  // 快捷设置
  const SubmitQuickSettings = () => {
    const obj = {...fromValue};
    delete obj.name;
    delete obj.trackFrequency;
    quickSetting(obj, 2)
        .then((res) => {
          if (res.data) {
            setRefuseModalText(res.data);
            setRefuseModal(true);
          } else {
            message.success('快捷设置成功');
            setUpdateList(updateList + 1);
          }
        })
        .finally(() => {
          setVisible(false);
        });
  };

  const staffDetailsMes = () => {
    // 运维人员详情接口
    getStaffDetails(l[4], 2)
        .then((res) => {
          setDetailsList({
            ...res.data,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const cityDetailsMes = () => {
    // 城市管理详情接口
    getCityDetails(l[4], 2)
        .then((res) => {
          setDetailsList({
            ...res.data,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    setLoading(true);
    if (menuType === 'staff') {
      staffDetailsMes();
    } else {
      cityDetailsMes();
    }
  }, [updateList]);

  return (
    <div>
      <DetailsHeader
        pageName={menuType === 'staff' ? '运维人员管理详情' : '城市管理者详情'}
        buttonGroup={() =>
          loading ? (
            <Skeleton active paragraph={{rows: 0}} />
          ) : (
            <Space>
              {detailsList.jobInfoVo.jobType !== 7 &&
                (menuType === 'staff' ?
                  detailsList?.personnelSettingsVo.jobStatus !== 4 :
                  detailsList?.cityPersonSettingsVO.jobStatus !== 4) && (
                <Button
                  onClick={() => {
                    setModalType('quick');
                    setVisible(true);
                    setQuickMessage({
                      operationId: detailsList?.operationInfoVo.id,
                      name: detailsList?.operationInfoVo.name,
                      phoneNumber: detailsList?.operationInfoVo.phoneNumber,
                      trackFrequency: '3分钟/次',
                      jobStatus:
                          menuType === 'staff' ?
                            detailsList?.personnelSettingsVo.jobStatus :
                            detailsList?.cityPersonSettingsVO.jobStatus,
                    });
                  }}
                >
                    快捷设置
                </Button>
              )}
              {(menuType === 'staff' ?
                detailsList.personnelSettingsVo.jobStatus !== 4 :
                detailsList?.cityPersonSettingsVO.jobStatus !== 4) && (
                <Button
                  onClick={() => {
                    setModalType('forbidden');
                    setVisible(true);
                  }}
                >
                  禁用
                </Button>
              )}
              {(menuType === 'staff' ?
                detailsList.personnelSettingsVo.jobStatus === 4 :
                detailsList?.cityPersonSettingsVO.jobStatus == 4) && (
                <Button
                  onClick={() => {
                    isAble(
                        detailsList?.operationInfoVo.id,
                        1,
                      menuType === 'staff' ? '运维人员启用成功' : '城市管理者启用成功',
                    );
                  }}
                >
                  启用
                </Button>
              )}
              <Button
                onClick={() => {
                  setModalType('delete');
                  setVisible(true);
                }}
              >
                删除
              </Button>
              <Button
                onClick={() => {
                  handleEditData();
                  setFormType('edit');
                  setDrawerVisible(true);
                }}
              >
                编辑
              </Button>
              {menuType === 'staff' && detailsList.jobInfoVo.jobType !== 7 && (
                <Button type="primary">
                  <Link to={link}>定位与轨迹</Link>
                </Button>
              )}
            </Space>
          )
        }
      />
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <div>
          <h3 className="details-title" id="basic-information">
            基础信息
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  姓名：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.name) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  性别：
                  <span className="text-gray">
                    {(((detailsList.operationInfoVo && detailsList.operationInfoVo.sex) ||
                      detailsList.operationInfoVo.sex == 0) &&
                      (detailsList.operationInfoVo.sex == 0 ? '男' : '女')) ||
                      '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  <Image
                    src={(detailsList.operationInfoVo && detailsList.operationInfoVo.imageUrl) || photo}
                    width={80}
                    height={80}
                  />
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l" style={{marginTop: -60}}>
                <Col span={8}>
                  联系电话：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.phoneNumber) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  年龄：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.age) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}></Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  身份证号：<span className="text-gray">{detailsList?.operationInfoVo?.idCard || '无'}</span>
                </Col>
                <Col span={8}>
                  婚姻状况：
                  <span className="text-gray">
                    {display(maritalStatus, detailsList.operationInfoVo && detailsList.operationInfoVo.maritalStatus) ||
                      '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  学历：
                  <span className="text-gray">
                    {display(degree, detailsList.operationInfoVo && detailsList.operationInfoVo.degree) || '暂无数据'}
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  民族：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.nation) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  政治面貌：
                  <span className="text-gray">
                    {display(politics, detailsList.operationInfoVo && detailsList.operationInfoVo.politics) ||
                      '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  健康状况：
                  <span className="text-gray">
                    {display(health, detailsList.operationInfoVo && detailsList.operationInfoVo.health) || '暂无数据'}
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  紧急联系人姓名：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.emergencyName) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  紧急联系人电话：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.emergencyPhone) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  与紧急联系人的关系：
                  <span className="text-gray">
                    {display(
                        emergencyRelation,
                        detailsList.operationInfoVo && detailsList.operationInfoVo.emergencyRelation,
                    ) || '暂无数据'}
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  家庭地址：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.homeAddress) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  联系地址：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.contactAddress) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  新增时间：
                  <span className="text-gray">
                    {(detailsList.operationInfoVo && detailsList.operationInfoVo.createTime) || '暂无数据'}
                  </span>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="job-information">
            职务信息
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  工号：
                  <span className="text-gray">
                    {(detailsList.jobInfoVo && detailsList.jobInfoVo.jobNumber) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  所属区域：
                  <span className="text-gray">
                    {(detailsList.jobInfoVo && detailsList.jobInfoVo.areaName) || '无区域'}
                  </span>
                </Col>
                {menuType === 'staff' ? (
                  <Col span={8}>
                    考勤组名：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.jobName) || '暂无数据'}
                    </span>
                  </Col>
                ) : (
                  <Col span={8}>
                    城管企业：
                    <span className="text-gray">
                      {manageEnterprise(detailsList?.jobInfoVo?.manageEnterprise) || '无区域'}
                    </span>
                  </Col>
                )}
              </Row>
              <Row gutter={24} className="margin-b-l">
                {menuType === 'staff' ? (
                  <Col span={8}>
                    所属企业：
                    <span className="text-gray">{bikeCompany(detailsList?.jobInfoVo?.company)}</span>
                  </Col>
                ) : (
                  <Col span={8}>
                    职务类型：
                    <span className="text-gray">城市管理者</span>
                  </Col>
                )}
                <Col span={8}>
                  入职日期：
                  <span className="text-gray">
                    {(detailsList.jobInfoVo && detailsList.jobInfoVo.entryDate) || '暂无数据'}
                  </span>
                </Col>
                <Col span={8}>
                  工龄：
                  <span className="text-gray">
                    {workYear(moment(detailsList.jobInfoVo && detailsList.jobInfoVo.entryDate))}
                    <span>年</span>
                  </span>
                </Col>
              </Row>
              {menuType === 'staff' && (
                <Row gutter={24} className="margin-b-l">
                  <Col span={8}>
                    职务等级：
                    <span className="text-gray">{positionLevel(detailsList.jobInfoVo.positionLevel)}</span>
                  </Col>
                  <Col span={8}>
                    职务类型：
                    <span className="text-gray">{staffStatus(detailsList.jobInfoVo.jobType)}</span>
                  </Col>
                  <Col span={detailsList.jobInfoVo.jobType === 2 || detailsList.jobInfoVo.jobType === 6 ? 8 : 0}>
                    车牌号：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.licensePlate) || '暂无数据'}
                    </span>
                  </Col>
                </Row>
              )}
              {menuType === 'staff' && (
                <Row gutter={24} className="margin-b-l">
                  <Col span={8}>
                    区域管理员：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.areaManager) || '暂无数据'}
                    </span>
                  </Col>
                  <Col span={8}>
                    区域管理员联系方式：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.phoneNumber) || '暂无数据'}
                    </span>
                  </Col>
                </Row>
              )}
              {menuType === 'staff' && (
                <Row gutter={24} className="margin-b-l">
                  <Col span={8}>
                    街道管理员：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.streetManager) || '暂无数据'}
                    </span>
                  </Col>
                  <Col span={8}>
                    街道管理员联系方式：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.mobileNumber) || '暂无数据'}
                    </span>
                  </Col>
                </Row>
              )}
              {menuType === 'staff' &&detailsList.jobInfoVo.positionLevel===2&& (
                <Row gutter={24} className="margin-b-l">
                  <Col span={24}>
                    管辖区域：
                    <span className="text-gray">
                      {(detailsList.jobInfoVo && detailsList.jobInfoVo.jurisdictionName) || '暂无数据'}
                    </span>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="person-setting">
            人员设置
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={[24, 24]} className="margin-b-l">
                {menuType === 'staff' && detailsList.jobInfoVo.jobType !== 7 && (
                  <Col span={8}>
                    轨迹上传频率：
                    <span className="text-gray">
                      {trackFrequencyStatus(
                          detailsList.personnelSettingsVo && detailsList.personnelSettingsVo.trackFrequency,
                      ) || '暂无数据'}
                    </span>
                  </Col>
                )}
                {menuType === 'staff' && (
                  <Col span={8}>
                    考勤规则：
                    <span className="text-gray">
                      {detailsList?.personnelSettingsVo?.attendanceRuleList?.length > 0 &&
                      detailsList?.personnelSettingsVo?.jobStatus === 1 ?
                        '开' :
                        '关'}
                    </span>
                  </Col>
                )}
                <Col span={8}>
                  工作状态：
                  <span className="text-gray">
                    {workStatus(
                      menuType === 'staff' ?
                        detailsList?.personnelSettingsVo?.jobStatus :
                        detailsList?.cityPersonSettingsVO.jobStatus,
                    ) || '暂无数据'}
                  </span>
                </Col>
                {menuType === 'staff' && (
                  <Col span={8}>
                    在线状态：
                    <span className="text-gray">
                      {onlineStatus(detailsList?.personnelSettingsVo?.onlineStatus) || '暂无数据'}
                    </span>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
      </Card>

      <Modal
        title={modalType == 'quick' ? '运维人员快捷设置' : ''}
        visible={visible}
        maskClosable={false}
        destroyOnClose
        closable={modalType == 'quick' ? true : false}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
          setSelected(false);
        }}
        width={modalType == 'quick' ? 520 : 400}
        footer={
          modalType == 'quick' ?
            [
              <Button
                key="1"
                onClick={() => {
                  setVisible(false);
                  setSelected(false);
                }}
              >
                  取消
              </Button>,
              <Button key="2" type="primary" onClick={SubmitQuickSettings}>
                  确认
              </Button>,
            ] :
            null
        }
      >
        {modalType == 'quick' ? (
          <QuickSetting
            visible={visible}
            selected={selected}
            attendanceRuleList={attendanceRuleList}
            setSelected={setSelected}
            ruleSet={
              menuType === 'staff' ?
                detailsList?.personnelSettingsVo.jobStatus :
                detailsList?.cityPersonSettingsVO.jobStatus
            }
            detailsList={quickMessage}
            fromValue={setfromValue}
          />
        ) : (
          confirmDisable
        )}
      </Modal>
      <Modal
        title={'无法操作'}
        visible={refuseModal}
        maskClosable={false}
        destroyOnClose
        closable={modalType == 'quick' ? true : false}
        width={400}
        footer={
          [
            <Button key="211" onClick={()=>{
              setRefuseModal(false);
            }}>
              确认
            </Button>,
          ]
        }
      >
        <p>该运维人员是“进行中”或“未开始”状态的重点工作区域保障指定人员，不可删除，禁用，编辑，修改工作状态。请将人员从区域移除后再编辑。</p>
        <span>重点工作区域保障名称：</span><br/>
        {/* {refuseModalText?.split(',').map((item, index)=><p key={index}>{item}</p>)} */}
        {refuseModalText && refuseModalText?.map((item, index) => (
          <><span key={index}>{item}</span><br/></>
        ))}
      </Modal>
      <FormOfDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        title={'编辑人员信息'}
        type={formType}
        detailsList={editData}
        menuType={menuType}
        update={update}
        attendanceRuleList={attendanceRuleList}
      />
      <Anchor className="anchor">
        <Anchor.Link href="#basic-information" title="基础信息" />
        <Anchor.Link href="#job-information" title="职务信息" />
        <Anchor.Link href="#person-setting" title="人员设置" />
      </Anchor>
    </div>
  );
};

StaffDetails.propTypes = {
  menuType: PropTypes.string,
};

export default StaffDetails;
