import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Form, Switch, Space, Spin, message} from 'antd';
import PropTypes from 'prop-types';
import editIcon from '../../../asset/edit.png';
import forbidIcon from '../../../asset/forbid-edit.png';
import {InfoCircleOutlined} from '@ant-design/icons';
import ExitEdit from './ExitEdit';
import {appAndWebPlatformSwitch} from '../../../api';
const PlatFormSwitch = ({orderInfo, onFinished, open, changeOpen, redact, setRedact}) => {
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [systemValue, setSystemValue] = useState();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log(values, 'SWITCH的value');
    setRedact(values);
    appAndWebPlatformSwitch({type: 1, onOff: values.status===true?1:0}, 2).then((resp) => {
      setEdit(false);
      message.success('操作成功');
      onFinished();
      setRedact('');
      setSystemValue(null);
    }).catch(() => {
      message.error('操作失败');
    }).finally(()=>{
      setLoading(false);
    });
  };
    // 确认退出编辑
  const confirm=()=>{
    setEdit(false);
    setVisible(false);
    setSystemValue(null);
    setRedact('');
  };

  useEffect(() => {
    if (orderInfo && edit) {
      form.setFieldsValue({
        status: orderInfo?.webSwitch===1?true:false,
      });
    }
  }, [orderInfo, edit]);

  useEffect(() => {
    if (open !== 'keySwicth') {
      setEdit(false);
    }
  }, [open]);

  return (
    <div>
      {/* <h2>重点工作区域保障相关设置</h2> */}
      <h3>
      2.重点工作区域保障告警开关（单车运维管理平台）
        <span
          onClick={() => {
            if (redact==='') {
              changeOpen();
              setEdit(true);
            }
          }}
          className="padding-l-m point"
        >
          <img src={edit?editIcon:redact===''?editIcon:forbidIcon} alt="edit" />
        </span>
      </h3>
      <div className="hint-system">
        <InfoCircleOutlined className="off-icon" />
        <p className="details-h6">
        告警开关开启后，进行中的“重点工作区域保障”，在满足告警生成规则条件时，将自动生成告警消息，并推送给管理后台的相关管理人员。关闭此开关，将暂停生成和推送告警。
        </p>
      </div>
      {edit ? (
        <Spin spinning={loading}>
          <Form name="base" form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={[16]} className="off-lines">
              <Col>
                <Form.Item
                  label="单车运维管理平台告警:"
                  name="status"
                  rules={[{required: true}]}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <div className="text-right">
              <Space>
                <Button
                  onClick={() => {
                    if (systemValue) {
                      setVisible(true);
                    } else {
                      setEdit(false);
                      setRedact('');
                    }
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Space>
            </div>
          </Form>
        </Spin>
      ) : (
        <div className="details-info margin-t-l">
          <Row className="margin-b-l">
            <Col span={12}>
              {/* <span className="padding-r-m">工单过期判断条件: </span> */}
              <span>单车运维管理平台告警:{orderInfo?.webSwitch===1?' 开启':' 关闭'}</span>
            </Col>
          </Row>
        </div>
      )}
      {/* 退出编辑 */}
      {visible&&<ExitEdit visible={visible} setVisible={()=>setVisible(false)} confirm={confirm}/>}
    </div>
  );
};

PlatFormSwitch.propTypes = {
  orderInfo: PropTypes.any,
  onFinished: PropTypes.func,
  open: PropTypes.string,
  changeOpen: PropTypes.func,
  redact: PropTypes.any,
  setRedact: PropTypes.func,
};

export default PlatFormSwitch;
