import {storage} from './storage';
import data from './mock.json';
import moment from 'moment';

/**
 * 判断两个参数是否相等
 * @author ziwei.Dong
 * @date 2023-01-03
 * @param {Object} a
 * @param {Object} b
 * @return {boolean}
 */
export const looseEqual = (a, b) => {
  if (a === b) {
    return true;
  }

  const isObjectA = isObject(a);
  const isObjectB = isObject(b);
  if (isObjectA && isObjectB) {
    try {
      const isArrayA = Array.isArray(a);
      const isArrayB = Array.isArray(b);
      if (isArrayA && isArrayB) {// 如果都是数组
        if (a.length === b.length) {// 如果长度相等
          return a.every(function(e, i) {// 用every和递归来比对a数组和b数组的每个元素，并返回
            return looseEqual(e, b[i]);
          });
        }
        // 长度都不等直接返回false
        return false;
      } else if (a instanceof Date && b instanceof Date) {// 如果是Date 则直接getTime 比较
        return a.getTime() === b.getTime();
      } else if (!isArrayA && !isArrayB) {// 对象的比较
        // 拿到两个对象的key
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        if (keysA.length === keysB.length) {// 如果keys相等
          return keysA.every(function(key) {// 用every和递归来比对a对象和b对象的每个元素值，并返回
            return looseEqual(a[key], b[key]);
          });
        }
        // 长度都不等直接返回false
        return false;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else if (!isObjectA && !isObjectB) {// 如果都不是对象则按String来处理
    return String(a) === String(b);
  } else {
    return false;
  }
};

const isObject = (obj) => {
  return obj !== null && typeof obj === 'object';
};

export const isMockData = () => {
  // 获取当前href
  const pathname = window.location.pathname;
  if (pathname.split('/').pop() === 'electricBikeManage') {
    return false;
  } else {
    const roleName=storage.getData('userInfo')?.roleName;
    return roleName === '城市管理'||roleName === '用户';
  }
};

export const bikeManageData = () => {
  // 获取当前的时间的分钟数,使用moment
  const nowDateMinute = moment().minute();
  // 当前的分钟数除6，向下取整
  const nowDateMinuteDivide6 = Math.floor(nowDateMinute / 6);
  // 在data 中找到当前时间的数据
  const dataItem = data[nowDateMinuteDivide6];

  return (dataItem);
};
