import React, {} from 'react';
import PropTypes from 'prop-types';
import {Image} from 'antd';
import errorImg from '../../asset/error.png';

const CustomImage = ({src, width, height}) => {
  return (
    <Image src={src||errorImg} fallback={errorImg} preview={!!src} width={width||'100%'} height={height} />
  );
};

CustomImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
};

export default CustomImage;
