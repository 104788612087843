import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {storage} from '../../utils/storage';
import {Breadcrumb, Col, Row} from 'antd';
import PropTypes from 'prop-types';
import './style.css';

const DetailsHeader = ({buttonGroup, pageName, tabsKey, breadHead}) => {
  const l=useLocation().pathname.split('/');
  const url1=location.pathname.indexOf('/bigScreen') !== -1? 'bigScreen/'+l[2]:l[1];
  const url2=location.pathname.indexOf('/bigScreen') !== -1? l[3]:l[2];
  const [headerText, setHeaderText]=useState('');
  const menuTree =storage.getData( 'userInfo')?.menuTree;
  const orderManageTab=['', '工单记录', '车企记录'];
  const headerTitle = ()=>{
    const pID= location.pathname.indexOf('/bigScreen') !== -1? l[2]:l[1];

    const sID=url2;
    if (menuTree) {
      const pItem=menuTree?.children.find(({menuType})=>menuType===pID);
      if (pItem?.children?.length>0) {
        const sItem=pItem?.children.find(({menuType})=>menuType===sID);
        setHeaderText(sItem.cnName);
      } else {
        setHeaderText(pItem.cnName);
      }
    }
  };
  useEffect(()=>{
    headerTitle();
  }, []);
  return (
    <div className="margin-b-m">
      <Breadcrumb>
        {!breadHead&&<Breadcrumb.Item>
          <Link to={l.length> (location.pathname.indexOf('/bigScreen') !== -1? 5:4)?`/${url1}/${url2}`:`/${url1}`} state={tabsKey}>{tabsKey?(parseInt(tabsKey)===1?
          '单车'+headerText:'电单车'+headerText):headerText}</Link>
        </Breadcrumb.Item>}
        {breadHead&&<Breadcrumb.Item>
          <Link to={`/${url1}/${url2}`} state={breadHead}>{orderManageTab[breadHead]}</Link>
        </Breadcrumb.Item>}
        <Breadcrumb.Item>{pageName}</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="margin-t-m">
        <Col span={12} className="details-header">
          {pageName}
        </Col>
        <Col span={12} className="text-right">
          {buttonGroup&&buttonGroup()}
        </Col>
      </Row>
    </div>
  );
};

DetailsHeader.propTypes = {
  buttonGroup: PropTypes.func,
  pageName: PropTypes.string.isRequired,
  tabsKey: PropTypes.number,
  breadHead: PropTypes.number,
};

export default DetailsHeader;
