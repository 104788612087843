import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import './mapStyle.css';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';
import {
  bikeCompany,
  zoomLevel} from '../statusCollection';
// import {getMarkRange} from '../../api';
import staff1 from './../../asset/mapIcon/staff01.png';
import staff2 from './../../asset/mapIcon/staff02.png';

import {Spin} from 'antd';
import {timeFormatMin} from '../baseTime';
import {getAreaBarChart, getKeyAreaBarChart, getMapZoomCenter} from '../../api';

let AMapAPI=null;
let infoWindow;
let areaId;
let cluster;

/**
 * 描述
 * @author ziwei.Dong
 * @date 2022-12-22
 *  * {
 * @param {array} points 人员坐在位置数据集合
 * @param {number} fullScreen 全屏等级 0 小屏 1 大屏 2 全屏
 * @param {number} reload 重置中心点
 * @param {number} queryType  1 单车 2 电单车
 * @param {array} markRangeList 保障区域数据合集
 * @param {function} onAreaClick 点击保障区域触发事件
 * }
 * @return {JSXDom}
 */
const StaffMarkerCluster =({
  points = [],
  fullScreen,
  reload,
  queryType,
  markRangeList,
  onAreaClick,
  searchCenter,
  isShowKeyArea,
}) =>{
  const mapRef = useRef();
  const [mapLoading, setMapLoading] = useState(true);
  const [zoom, setZoom] =useState(13);
  const [center, setCenter]= useState([104.065861, 30.657401]);
  // 人员图标点击信息窗体
  const openInfo = (data, position) => {
    // 构建信息窗体中显示的内容
    areaId=null;
    onAreaClick(null);
    const info = [];
    // 街道层级 infoWindow
    info.push(`<div
          class="input-card content-window-card">
          <div>
          </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">
            <div class="amap-info-title">
            ${data.jobType === 1 ? '单车人员' :
                data.jobType === 2 ? '单车调运车辆' :
                  data.jobType === 5 ? '电单车人员' :
                    data.jobType === 6 ? '电单车调运车辆' : ''}</div>`);
    info.push(`<div class="amap-info-box"><div class='amap-info-item'>姓名：${data.name}</div>`);
    info.push(`<div class='amap-info-item'>工号：${data.jobNumber}</div>`);
    info.push(`<div class='amap-info-item'>联系电话：${data.phoneNumber}</div>`);
    if (data.jobType === 2 || data.jobType === 6) {
      info.push(`<div class='amap-info-item'>车牌号：${data.licensePlate || '暂无'}</div>`);
    }
    info.push(`<div class='amap-info-item'>所属企业：${bikeCompany(data.company)}</div>`);
    if (data.entryTime&&data.jobStatus===3) {
      info.push(`<div class='amap-info-item'>进入时间：${timeFormatMin(data.entryTime)}</div>`);
    }
    info.push(`<div class='amap-info-item'>
            <a href='${location.pathname.indexOf('/bigScreen') !== -1? '/bigScreen':''}/screen/${queryType === 2 ? 'electricBikeManage' :
    'bikeManage'}/staffDetails/${data.operationId}'
            class='amap-info-item'>查看轨迹 &gt;</a>
            </div></div></div>`);


    infoWindow=new AMapAPI.InfoWindow({
      offset: new AMapAPI.Pixel(0, -12),
      content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
    });
    infoWindow.open(mapRef.current.map, position);
    mapRef.current.map.setCenter(position);
  };

  const getTotal=(value, isKeyArea)=>{
    if (isKeyArea) {
      const keyAreaData= value|| [{
        'actualArrival': 0,
      }];
      let arriveCounts=0;
      keyAreaData.forEach(({actualArrival})=>{
        arriveCounts+=actualArrival;
      });
      return arriveCounts;
    }
    const data= value||[{
      'carTotal': 0,
      'peopleTotal': 0,
    }];
    let peopleCounts=0;
    let carCounts=0;
    data.forEach(({carOnline, peopleOnline})=>{
      peopleCounts+=peopleOnline;
      carCounts+= carOnline;
    });
    return {
      peopleCounts,
      carCounts,
    };
  };

  // 区域信息窗体
  const openAreaInfo = (data, position) => {
    // 构建信息窗体中显示的内容
    const info = [];
    const status=data.guaranteeStatus==='1'?
    `<span><span class="status-progress"></span>进行中</span>`:
    `<span><span class="status-default"></span>未开始</span>`;
    // 街道层级 infoWindow
    info.push(`<div
          class="input-card content-window-card">
          <div>
          </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">

            <div class="amap-info-title">
            ${data.guaranteeName} 
            </div>`);
    info.push(`<div class="amap-info-box">
          <div class='amap-info-item'>状态：${status}
          </div>`);
    if (data.type===1) {
      info.push(`<div class='amap-info-item'>开始时间：${timeFormatMin(data.startTime)}</div>`);
    }
    if (data.guaranteeStatus==='1') {
      if (data.type===1) {
        getAreaBarChart(queryType, data.id, 2).then((res)=>{
          const sum=getTotal(res.data.chart);
          info.push(`<div class='amap-info-item'>到岗人员：${sum.peopleCounts||0}</div>`);
          info.push(`<div class='amap-info-item'>到岗车辆：${sum.carCounts || 0}</div>`);
          info.push(`</div></div>`);

          const infoWindow=new window.AMap.InfoWindow({
          // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(mapRef.current.map, position);
          infoWindow.on('close', ()=>{
            setTimeout(()=>{
              if (!infoWindow.getIsOpen()) {
                areaId=null;
                onAreaClick(null);
              }
            }, 100);
          });
        }).catch(()=>{
          info.push(`<div class='amap-info-item'>到岗人员：暂无数据</div>`);
          info.push(`<div class='amap-info-item'>到岗车辆：暂无数据</div>`);
          info.push(`</div></div>`);

          const infoWindow=new window.AMap.InfoWindow({
          // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(mapRef.current.map, position);
          infoWindow.on('close', ()=>{
            setTimeout(()=>{
              if (!infoWindow.getIsOpen()) {
                areaId=null;
                onAreaClick(null);
              }
            }, 100);
          });
        });
      } else {
        getKeyAreaBarChart(queryType, data.id, 2).then((res)=>{
          const sum=getTotal(res.data, true);
          info.push(`<div class='amap-info-item'>到岗数：${sum||0}</div>`);
          info.push(`</div></div>`);

          const infoWindow=new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(mapRef.current.map, position);
          infoWindow.on('close', ()=>{
            setTimeout(()=>{
              if (!infoWindow.getIsOpen()) {
                areaId=null;
                onAreaClick(null);
              }
            }, 100);
          });
        }).catch(()=>{
          const sum=getTotal();
          info.push(`<div class='amap-info-item'>到岗数：${sum.peopleCounts||0}</div>`);
          info.push(`</div></div>`);

          const infoWindow=new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(mapRef.current.map, position);
          infoWindow.on('close', ()=>{
            setTimeout(()=>{
              if (!infoWindow.getIsOpen()) {
                areaId=null;
                onAreaClick(null);
              }
            }, 100);
          });
        });
      }
    } else {
      info.push(`</div></div>`);

      const infoWindow=new window.AMap.InfoWindow({
        // offset: new AMap.Pixel(0, -24),
        content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
      });
      infoWindow.open(mapRef.current.map, position);
      infoWindow.on('close', ()=>{
        setTimeout(()=>{
          if (!infoWindow.getIsOpen()) {
            areaId=null;
            onAreaClick(null);
          }
        }, 100);
      });
    }
  };


  const markerContent=(point)=>{
    const wive = ` <div >
                    <div class="feature">
                      <div class="feature-box feature-box${point.company}">
                        <div class="pulse"/>
                        <div class="pulse1 "/>
                        <div class="pulse2"/>
                      </div>
                    </div>
                    <div class='wave'>
                      <span class="${point.jobStatus === 3 && 'guarantee'}"></span>
                      <div>
                      <img
                      src="${(point.jobType === 1 || point.jobType === 5) ?
                         staff1 : staff2}"
                      alt="icon" width="16" height="16"
                    />
                      </div>

                    </div>
                    </div>`;
    return wive;
  };

  const addMarker=(points)=>{
    if (cluster) {
      cluster.setMap(null);
    }

    const count = points.length;
    const _renderClusterMarker = (context)=> {
      const factor = Math.pow(context.count / count, 1 / 18);
      const div = document.createElement('div');
      const Hue = 180 - factor * 180;
      const bgColor = 'hsla(' + Hue + ',100%,40%,0.7)';
      const fontColor = 'hsla(' + Hue + ',100%,90%,1)';
      const borderColor = 'hsla(' + Hue + ',100%,40%,1)';
      const shadowColor = 'hsla(' + Hue + ',100%,90%,1)';
      div.style.backgroundColor = bgColor;
      const size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
      div.style.width = div.style.height = size + 'px';
      div.style.border = 'solid 1px ' + borderColor;
      div.style.borderRadius = size / 2 + 'px';
      div.style.boxShadow = '0 0 5px ' + shadowColor;
      div.innerHTML = context.count;
      div.style.lineHeight = size + 'px';
      div.style.color = fontColor;
      div.style.fontSize = '14px';
      div.style.textAlign = 'center';
      context.marker.setOffset(new AMapAPI.Pixel(-size / 2, -size / 2));
      context.marker.setContent(div);
      context.marker.on('click', function(e) {
        let curZoom = mapRef.current.amap.getZoom();
        if (curZoom < 20) {
          curZoom += 1;
        }

        mapRef.current.amap.setZoomAndCenter(curZoom, e.lnglat);
      });
    };
    const _renderMarker = (context) =>{
      const content = markerContent(context.data[0]);
      const offset = new AMapAPI.Pixel(-9, -9);
      context.marker.setContent(content);
      context.marker.setOffset(offset);
      context.marker.on('click', function(e) {
        openInfo(context.data[0], e.target.getPosition());
      });
    };
    // points.forEach((point) =>{
    cluster = new AMapAPI.MarkerCluster(
        mapRef.current.map,
        points.map((point) => ({
          ...point,
          'lnglat': point.position,
        })),
        {
          gridSize: 80,
          renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
          renderMarker: _renderMarker, // 自定义非聚合点样式
        });
  };

  const addGuaranteeArea = (list) => {
    if (!mapRef.current.map) {
      return;
    }
    const polygonsList = list.filter((item)=>isShowKeyArea?item.type===1||item.type===2:item.type===1);
    const polygons = polygonsList.map((item) => {
      const fillColor = item.guaranteeStatus === '1' ?
      item.type===1?'#13C2C2':'#FA8C16' : '#bfbfbf';
      const borderColor = item.guaranteeStatus === '1' ?
      (item.type===1?'#13C2C2':'#FA8C16') : (item.type===1?'#bfbfbf':'#FA8C16');

      const fillOpacity = item.guaranteeStatus === '1' ?
      (item.type===1?0.2:0.4) : 0.2;
      const strokeOpacity = item.guaranteeStatus === '1' ?
      (item.type===1?0.2:0.6) : (item.type===1? 0.2:0.5);
      const polygon = new window.AMap.Polygon({
        path: JSON.parse(item.markRange),
        fillColor,
        strokeOpacity,
        fillOpacity,
        strokeWeight: 1,
        bubble: true,
        strokeColor: borderColor,
        extData: {...item},
      });
      if (item.type===1||(item.type===2&&item.guaranteeStatus === '1')) {
        polygon.on('rightclick', (e) => {
          const data = e.target.getExtData();
          if (data.guaranteeStatus === '1') {
            if (areaId!==data.id) {
              areaId=data.id;
              onAreaClick(data);
            }
          } else {
            onAreaClick(null);
          } openAreaInfo(data, [e.lnglat.lng, e.lnglat.lat]);
        });
      }
      return polygon;
    });

    mapRef.current.map.add(polygons);
  };


  const mapLoader=(center, zoom)=>{
    AMapLoader.load({
      ...AMapKey,
      plugins: [
        'AMap.Polygon',
        'AMap.MarkerClusterer',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      mapRef.current.map = new AMap.Map('map', {
        resizeEnable: true,
        center: center,
        // pitch: 40,
        showLabel: true,
        zoom: zoom,
        mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      AMapAPI=AMap;
      setTimeout(() => {
        setMapLoading(false);
      }, 1500);
    });
  };

  const mapInit = () => {
    getMapZoomCenter(2).then((res) => {
      const center=res.data.latitudeLongitude?res.data.latitudeLongitude.split(','):[104.065861, 30.657401];
      const zoom = zoomLevel(res.data.level);
      mapLoader(center, zoom);
      setCenter(center);
      setZoom(zoom);
    }).catch(()=>{
      mapLoader([104.065861, 30.657401], 13);
    });
  };


  useEffect(()=>{
    mapInit();
  }, [queryType]);

  useEffect(()=>{
    if (AMapAPI) {
      if (infoWindow) {
        infoWindow.close();
      }
      areaId=null;
      onAreaClick(null);
      addMarker(points);
      addGuaranteeArea(markRangeList);
    }
  }, [points, markRangeList, mapLoading]);

  useEffect(()=>{
    if (AMapAPI&&searchCenter) {
      AMapAPI.setCenter(searchCenter);
    }
  }, [searchCenter]);

  useEffect(() => {
    if (reload > 0) {
      mapRef.current.amap.setZoomAndCenter(zoom, center);
    }
  }, [reload, queryType]);

  return (
    <Spin
      spinning={mapLoading}
      size="large"
      // wrapperClassName="map-loading"
      wrapperClassName={`map-loading ${location.pathname.indexOf('/bigScreen') !== -1 && fullScreen === 0? 'screen-show-h':''}`}
    >
      <div
        ref={mapRef}
        id="map"
        className={
          fullScreen === 0 ? `screen-small-map ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-show-map':''}` :
            fullScreen === 1 ? `screen-middle-map` :
              `screen-large-map`
        }
      />
    </Spin>
  );
};
StaffMarkerCluster.propTypes = {
  queryType: PropTypes.number.isRequired,
  title: PropTypes.string,
  onAreaClick: PropTypes.func.isRequired,
  fullScreen: PropTypes.number.isRequired,
  reload: PropTypes.number.isRequired,
  markRangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  points: PropTypes.arrayOf(PropTypes.object),
  searchCenter: PropTypes.array,
  isShowKeyArea: PropTypes.bool.isRequired,
};


export default StaffMarkerCluster;
