import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Spin, Button, Image } from 'antd';

import { connect } from 'react-redux';
import { side } from '../../../store/actions';

import {
  // getBikeScreenDevice,
  getBikeScreenLineChart,
  getBikeScreenMap,
  getBikeScreenRadar,
  getBikeScreenStatistics, minutesPieChart,
} from '../../../api';

import './style.less';

import Statistics from './components/StatisticsData';
import titleIcon from './../../../asset/title-icon.png';
import ScreenMap from './components/ScreenMap';
import RadarChart from '../../../components/echarts/RadarChartPilot';
import PointsLineChart from '../../../components/echarts/PointsLineChartPilot';
import PieChart from '../../../components/echarts/PieChartPilot';
import { isMockData, looseEqual } from '../../../utils/judge';
import { bikeMockData } from '../mockData';
import { bikeDataTitle } from '../../../components/statusCollection';
import { mockBikeScreenMapRadar, mockBikeScreenStatistics } from './demoData/demoData';
import CockpitBox from '../../../components/cockpitBox';
import Icon1 from '../../../asset/cockpitIcon/icon1.png';
import Icon2 from '../../../asset/cockpitIcon/icon2.png'
import Icon3 from '../../../asset/cockpitIcon/icon3.png'
import Icon4 from '../../../asset/cockpitIcon/icon4.png'
import Icon5 from '../../../asset/cockpitIcon/icon5.png'
import useResizeObserver from './useResizeObserver.jsx';
import { getSize,getHeightSize } from '../../../utils/dataFormat.js';

const refreshTime = 6 * 60 * 1000;
const isMock = false;

let bigMap = 0;
const PilotScreen = ({ collapsed, setCollapsedMenus, bikeType }) => {
  const totalTimer = useRef();
  const [mapData, setMapData] = useState([]);
  const [deviceLineData, setDeviceLineData] = useState([]);
  const [devicePieData, setDevicePieData] = useState(null);
  const [deviceName, setDeviceName] = useState('暂无');
  const [statisticsData, setStatisticsData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [radarChartData, setRadarChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [lineAverage, setLineAverage] = useState(1);
  const [lineMin, setLineMin] = useState(0);
  // const [IMEI, setDeviceID] = useState('');

  const [screenCode, setScreenCode] = useState(0);
  const [fold, setFold] = useState(false);
  const [dataTitle, setDataTitle] = useState('');
  const [nameHeight,setNameHeight]=useState(25)

  const mapTitle = 'big-screen-header text-right bike-full-header';
  // 全屏初始化
  const onFoldInit = () => {
    setCollapsedMenus(false);
    bigMap = 0;
    setScreenCode(0);
    setFold(false);
  };

  // 全屏功能
  const onFoldCharts = () => {
    setFold(!fold);
    if (bigMap === 0) {
      setCollapsedMenus(true);
      setScreenCode(3);
      bigMap = 3;
    } else if (bigMap === 1) {
      bigMap = 2;
      setScreenCode(2);
    } else if (bigMap === 2) {
      bigMap = 1;
      setScreenCode(1);
    } else if (bigMap === 3) {
      setCollapsedMenus(false);
      bigMap = 0;
      setScreenCode(0);
    }
  };


  const LineChartRender = useMemo(() =>
  (<PointsLineChart
    themeColor="#37EAFF"
    title={bikeType === 1 ? '移动单车数' : '移动电单车数'}
    chartData={lineChartData}
    log={true}
    average={lineAverage}
    min={lineMin}
    sliderStart={0.9}
  />), [lineChartData, lineAverage, lineMin]);
  const RadarChartRender = useMemo(() => (
    <RadarChart chartData={radarChartData} />
  ), [radarChartData]);

  const DeviceLineChartRender = useMemo(() =>
  (<PointsLineChart
    themeColor="#37EAFF"
    title={bikeType === 1 ? '监测单车数' : '监测电单车数'}
    chartData={deviceLineData}
    sliderStart={0}
  />), [deviceLineData]);

  const DevicePieChartRender = useMemo(() =>
    devicePieData ? (<PieChart data={devicePieData} isCustomMode />) : null, [devicePieData]);


  const onMakerClick = (value) => {
    console.log(value);
    // if (value) {
    getDeviceData(value.imei, value.count, value);
    setDeviceName(value?.equipmentName || '');
    // } else {
    //   setChartLoading(false);
    // }
  };

  const getDeviceData = (imei, count, value) => {
    setChartLoading(true);
    // 饼图接口
    minutesPieChart(
      {
        bikeType: 1,
        mobileEquipmentIdentity: imei,
        endTimeInTwoMinutes: value.endTimeInTwoMinutes,
      },
    ).then((res) => {
      console.log(res);
      setDeviceLineData(res.data.lineChart || []);
      setDevicePieData(res.data.pieChart || []);
    }).finally(() => {
      setChartLoading(false);
    });
    // getBikeScreenDevice(bikeType, imei).then((res) => {
    //   if (!looseEqual(devicePieData, res.data?.pieChart)) {
    //     if (res.data?.lineChart?.length>0) {
    //       const isUseNewValue=res.data.lineChart[res.data.lineChart.length-1].count===count;
    //       // console.log(isUseNewValue);
    //       if (isUseNewValue) {
    //         setDeviceLineData(res.data.lineChart);
    //         setDevicePieData(res.data.pieChart);
    //       } else {
    //         setDeviceLineData(res.data.lineChart.slice(0, -1));
    //         setDevicePieData(res.data.oldPieChart);
    //       }
    //     } else {
    //       setDeviceLineData([]);
    //       setDevicePieData([]);
    //     }
    //   }
    // }).finally(()=>{
    //   setChartLoading(false);
    // });
  };

  const getStatisticsData = () => {
    getBikeScreenStatistics(bikeType).then((res) => {
      const data = mockBikeScreenStatistics(res.data, '方案二');
      setStatisticsData(data);
    });
  };

  const getLineChartData = () => {
    getBikeScreenLineChart(bikeType).then((res) => {
      const data = res.data || [];
      if (!looseEqual(lineChartData, data)) {
        // setLineChartData(res.data || []);
        setLineChartData(data);
        setLineMin(data.reduce((min, item) => item.count < min ? item.count : min, data[0]?.count));
        setLineAverage(data.reduce((total, item) => total + item.count, 0) / data.length);
      }
    });
  };
  const getRadarChartData = () => {
    getBikeScreenRadar(bikeType).then((res) => {
      const data = mockBikeScreenMapRadar(res.data, '方案二');
      if (!looseEqual(radarChartData, data)) {
        setRadarChartData(data || []);
      }
    });
  };

  // 地图筛选
  const onSearch = (values, runTimer) => {
    const d = values || {};

    getBikeScreenMap(bikeType, d).then((res) => {
      // const data = res.data || [];
      // console.log(res.data, '地图');
      const data = isMock ? bikeMockData() : res.data;
      // const data=mockData;

      setMapData(data);
      // setMapData(data);

      if (data.length === 0) {
        setDeviceLineData([]);
        setDeviceName('暂无');
        setDevicePieData([]);
        setChartLoading(false);
      }
    }).catch(() => {
      setDeviceLineData([]);
      setDeviceName('暂无');
      setDevicePieData([]);
      setChartLoading(false);
    });

    // 点击筛选时
    if (!runTimer) {
      // 初始化定时器
      initTimer(values);
    }
  };

  // 线程初始化
  const initTimer = (filters) => {
    clearInterval(totalTimer.current);
    getStatisticsData();
    getRadarChartData();
    getLineChartData();
    if (!filters) {
      onSearch(null, true);
    }
    totalTimer.current = setInterval(() => {
      getStatisticsData();
      getRadarChartData();
      getLineChartData();
      onSearch(filters, true);
    }, refreshTime);
  };

  // 大屏初始化
  const initMap = () => {
    // 初始化线程
    initTimer(null);
  };

  const titleIcon = (url, text) => {
    return <div className='title-icon-box'>{<img src={url} style={{width:getHeightSize(20),height:getHeightSize(20)}}/>}{text}</div>
  }

  const handleResize = (rect) => {
    // console.log(rect.height);
    setNameHeight((rect?.height || 25)/devicePixelRatio)
  };
  const ref = useResizeObserver(handleResize);

  useEffect(() => {
    localStorage.setItem('change', true);
    initMap();
    return () => {
      clearInterval(totalTimer.current);
    };
  }, [bikeType]);

  useEffect(() => {
    const areaName = JSON.parse(localStorage.getItem('myInfo'))?.areaName || '无区域';
    setDataTitle(bikeDataTitle(areaName));
  }, []);

  return (
    <div className={screenCode === 1 ? 'middle-screen' : screenCode === 0 ? 'min-screen-pilot' : 'big-screen'}>
      {/* {collapsed && <div className={!fold ? mapTitle : 'full ' + mapTitle}>
        {!fold && <Button type="link"
          className="big-screen-btn"
          onClick={() => {
            if (bigMap === 0) {
              bigMap = 1;
              setScreenCode(1);
              setCollapsedMenus(true);
            } else if (bigMap === 1) {
              bigMap = 0;
              setScreenCode(0);
              setCollapsedMenus(false);
            }
          }}>退出页面全屏</Button>}
      </div>} */}
      <Row wrap={false} gutter={fold ? [] : [8, 8]}>
        <Col flex={fold ? '0px' : '666px'} style={{ marginTop: getHeightSize(22) }}>
          <Row className={fold ? 'device-big-map-hide' : ''} gutter={[0, getHeightSize(20)]}>
            <Col span={24}>
              <CockpitBox title={titleIcon(Icon1, dataTitle)} height={getHeightSize(230)}>
                <Statistics
                  bikeType={bikeType}
                  statisticsData={statisticsData}
                />
              </CockpitBox>
            </Col>
            <Col span={24}>
              <CockpitBox title={titleIcon(Icon2, `车企已备案${bikeType === 2 ? '电' : ''}单车数${isMockData() ? '（主城区）' : ''}`)
              } height={getHeightSize(336)}>
                <div className="device-radar-chart-pilot">
                  {RadarChartRender}
                </div>
              </CockpitBox>
            </Col>
            <Col span={24}>
              <CockpitBox title={titleIcon(Icon3, `近10小时移动${bikeType === 2 ? '电' : ''}单车数趋势`)} height={getHeightSize(336)}>
                <div className="device-footer-charts-pilot">
                  {LineChartRender}
                </div>
              </CockpitBox>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">

          <div span={24} className={fold ? 'device-border-full' : ''} style={{ height: '100%' }} id='mapId'>
            {/* {!fold && <div className="map-header">
              <Row>
                <Col span={12}>
                  <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
                  设备位置分布
                </Col>
                <Col span={12} className="text-right">
                  {!fold && !collapsed && <Button type="link" onClick={() => {
                    if (bigMap === 0) {
                      bigMap = 1;
                      setScreenCode(1);
                      setCollapsedMenus(true);
                    } else if (bigMap === 1) {
                      bigMap = 0;
                      setScreenCode(0);
                      setCollapsedMenus(false);
                    }
                  }}>页面全屏</Button>}
                </Col>
              </Row>
            </div>} */}
            <ScreenMap
              onFilterFunc={onSearch}
              initTimer={initTimer}
              fold={fold}
              mapData={mapData}
              bikeType={bikeType}
              onMakerClick={onMakerClick}
              bigMap={bigMap}
              onFoldCharts={onFoldCharts}
            />
          </div>
          {/* <div className={fold ? 'device-big-map-hide' : 'device-border margin-t-s '}>
            <div className="card-title">
              <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
              【{deviceName || '暂无设备'}】实时监测{bikeType === 2 && '电'}单车数占比与趋势
            </div>
            <Spin spinning={chartLoading} wrapperClassName="map-loading">
              <Row wrap={false}>
                <Col flex="360px" className="device-footer-charts">
                  {DevicePieChartRender}
                </Col>
                <Col flex="auto" className="device-footer-charts">
                  {DeviceLineChartRender}
                </Col>
              </Row>

            </Spin>
          </div> */}
        </Col>
        <Col flex={fold ? '0px' : '525px'} style={{ marginTop: getHeightSize(22) }}>
          <Row className={fold ? 'device-big-map-hide' : ''} gutter={[0, getHeightSize(20)]}>
            <Col span={24}>
              <CockpitBox title={titleIcon(Icon4, '图例说明')} height={getHeightSize(279)} size={'small'}>
                <div className="legend-box">
                  <div className='legend-title'>点位状态</div>
                  <div className='legend-content'><div className='legend-dot dot1' />当前车辆数 ≤【标准值】</div>
                  <div className='legend-content'><div className='legend-dot dot2' />{'【标准值】< 当前车辆数 ≤【预警值】'}</div>
                  <div className='legend-content'><div className='legend-dot dot3' />{'【预警值】< 当前车辆数 ≤【调运值】'}</div>
                  <div className='legend-content'><div className='legend-dot dot4' />{'当前车辆数 >【调运值】'}</div>
                </div>
              </CockpitBox>
            </Col>
            <Col span={24}>
              <CockpitBox title={titleIcon(Icon5, `实时监测${bikeType === 2 ? '电' : ''}单车数占比与趋势`)} height={getHeightSize(643)} size={'small'}>
                <Spin spinning={chartLoading} wrapperClassName="map-loading">
                  <div className='device-name' ref={ref}>{`【${deviceName || '暂无设备'}】`}</div>
                  <Row >
                    <Col flex={24} className="device-footer-charts-pilot1">
                      {DevicePieChartRender}
                    </Col>
                  </Row>
                  <Row>
                    <Col flex={24} className="device-footer-charts-pilot2" style={{height:`${((getHeightSize(311))-nameHeight)}px`}}>
                      {DeviceLineChartRender}
                    </Col>
                  </Row>
                </Spin>
              </CockpitBox>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>

      </div>
    </div>


  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.collapsedMenus.collapsed,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setCollapsedMenus: (f) => dispatch(side(f)),

  };
};
PilotScreen.propTypes = {
  collapsed: PropTypes.bool,
  bikeType: PropTypes.number.isRequired,
  setCollapsedMenus: PropTypes.func.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PilotScreen);
