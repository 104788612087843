import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './mapStyle.css';
import AMapLoader from '@amap/amap-jsapi-loader';
import { AMapKey } from './aMapAPI';
import { zoomLevel } from '../statusCollection';
import { Spin } from 'antd';
import {
  getMapZoomCenter,
} from '../../api';

import { toThousands } from '../../utils/dataFormat';

import lowLevelIcon from '../../asset/mapIcon/breath_blue.png';
import normalLevelIcon from '../../asset/mapIcon/breath_green.png';
import alarmLevelIcon from '../../asset/mapIcon/breath_yellow.png';
import highLevelIcon from '../../asset/mapIcon/breath_red.png';

let isClosed;
let loca;
let layer;

/**
 * 设备所在位置地图组件
 * @author ziwei.Dong
 * @date 2022-12-22
 * {
 * @param {array} points 设备所在点
 * @param {number} reload 重置中心点
 * @param {number} bikeType 1 单车 2 电单车
 * @param {any} onMakerClick 点击
 * }
 * @return {JSXDom}
 */
const LocaScatterLayer = ({ points = [],
  reload, bikeType, onMakerClick, fold }) => {
  const [map, setMap] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [zoom, setZoom] = useState(13);
  const [infoClosed, setInfoClosed] = useState(false);
  const [center, setCenter] = useState([104.065861, 30.657401]);

  const openInfo = (data) => {
    // 构建信息窗体中显示的内容
    console.log(data);

    const info = [];
    info.push(`<div
            class="input-card content-window-card">
            <div>
            </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">
              <div class="amap-info-title">
              ${data.equipmentName}</div>`);
    info.push(`<div class="amap-info-box"><div class='amap-info-item'>IMEI：${data.imei}</div>`);
    info.push(`<div class='amap-info-item'>位置编号：${data.locationNo || '暂无数据'}</div>`);
    info.push(`<div class='amap-info-item'>监测${bikeType === 2 ? '电' : ''}单车数：${toThousands(data.count)}</div>`);
    info.push(`<div class='amap-info-item'>停放上限：${toThousands(data.bikeDispatchNum)}</div>`);
    if (data.count > data.bikeDispatchNum) {
      info.push(`<div class='amap-info-item text-red'>通知：该点位单车数已超量，系统已自动创建工单。</div>`);
    }

    info.push(`</div></div>`);


    const infoWindow = new window.AMap.InfoWindow({
      offset: new window.AMap.Pixel(0, 0),
      autoMove: true,
      // 使用默认信息窗体框样式，显示信息内容
      content: info.join(''),
    });
    infoWindow.on('open', () => {
      onMakerClick(data);
    });
    infoWindow.on('close', () => {
      if (!isClosed && data.id !== points[0].id) {
        isClosed = true;
        setInfoClosed(true);
        setTimeout(() => {
          isClosed = false;
          setInfoClosed(false);
        }, 1000);
      }
    });
    infoWindow.open(map, data.position);
    map.setCenter(data.position);
  };

  const mapLoader = (center, zoom) => {
    AMapLoader.load({
      ...AMapKey,
      Loca: {
        version: '2.0',
      },
      plugins: [
        // 'AMap.Geolocation',
        // 'AMap.Scale',
        // 'AMap.Polygon',
        // 'AMap.MapType',
        // 'AMap.Marker',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      const map = new AMap.Map('map', {
        resizeEnable: true,
        center: center,
        showLabel: true,
        zoom: zoom,
        mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
      });
      setMap(map);
      map.on('complete', function () {
        // 地图图块加载完成后触发
        map.addControl(new AMap.ToolBar());
        setTimeout(() => {
          setMapLoading(false);
        }, 1000);
      });
      return map;
    });
  };

  const mapInit = () => {
    getMapZoomCenter(2).then((res) => {
      const center = res.data.latitudeLongitude ?
        res.data.latitudeLongitude.split(',') :
        [104.065861, 30.657401];
      const zoom = zoomLevel(res.data.level);
      setCenter(center);
      setZoom(zoom);
      openInfo({
        equipmentName: 2121,
        imei: 21312312,
        locationNo: 214321,
        cout: 32432,
        bikeDispatchNum: 324234,
        position: '104.065861, 30.657401'
      });
      return mapLoader(center, zoom);
    }).catch(() => {
      return mapLoader([104.065861, 30.657401], 13);
    });
  };

  const addLabel = (points) => {
    layer = new window.AMap.LabelsLayer({
      zooms: [1, 24],
      zIndex: 1000,
      collision: false,
      allowCollision: false,
    });
    map.add(layer);
    const LabelsData = points.map((item) => ({
      position: item.latitudeLongitude.split(','),
      opacity: 1,
      zIndex: 10,
      zooms: [8, 20],
      cullface: 'back',
      text: {
        // 要展示的文字内容
        content: toThousands(item.count),
        // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
        direction: 'center',
        // 在 direction 基础上的偏移量
        offset: [0, 22],
        // 文字样式
        style: {
          // 字体大小
          fontSize: 12,
          // 字体颜色
          fillColor: '#fff',
          //
          strokeColor: 'rgba(255,255,255,0)',
          strokeWidth: 1,
        },
      },
    }));
    // 初始化 labelMarker
    const markers = [];
    LabelsData.forEach((curData) => {
      const labelMarker = new window.AMap.LabelMarker(curData);
      markers.push(labelMarker);
    });
    // 将 marker 添加到图层
    if (markers) {
      layer.add(markers);
    }
  };

  const locaStyle = (lowLevelIcon) => ({
    unit: 'px',
    size: [40, 40],
    texture: lowLevelIcon,
    borderWidth: 1,
    animate: true,
    duration: 1600,
  });

  /**
   * 设备所在点等级，通过判断确定marker type
   * hight --调运值：bikeCount>调运值；
   * alarm --预警值：预警值<bikeCount=<调运值
   * normal --标准值：标准值<bikeCount=<预警值
   * low --低于标准值：bikeCount=<标准值
   * @author ziwei.Dong
   * @date 2023-02-03
   * @param {any} points
   */
  const addLoca = (points) => {
    const layerOptions = [
      { condition: (item) => item.count > item.bikeDispatchNum, data: [], icon: highLevelIcon },
      { condition: (item) => item.count > item.bikeAlertNum, data: [], icon: alarmLevelIcon },
      { condition: (item) => item.count > item.bikeStandardNum, data: [], icon: normalLevelIcon },
      { condition: (item) => true, data: [], icon: lowLevelIcon },
    ];

    points.forEach((item) => {
      const { latitudeLongitude, ...props } = item;
      const feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: latitudeLongitude.split(','),
        },
        properties: {
          ...props,
          position: latitudeLongitude.split(','),
        },
      };
      const { data } = layerOptions.find(({ condition }) => condition(item));
      data.push(feature);
    });

    loca = new window.Loca.Container({
      map: map,
    });

    layerOptions.forEach(({ data, icon }) => {
      const layer = new window.Loca.ScatterLayer({
        zIndex: 121,
        zooms: [5, 30],
        loca,
      });
      const dataSource = new window.Loca.GeoJSONSource({
        data: {
          type: 'FeatureCollection',
          features: data,
        },
      });
      layer.setSource(dataSource);
      layer.setStyle(locaStyle(icon));
      map.on('click', (e) => {
        // console.log(e);
        const feat = layer.queryFeature([e.pixel.x, e.pixel.y]);
        if (feat) {
          openInfo(feat.properties);
        }
      });
      dataSource.destroy();
    });
    loca.animate.start();
  };

  useEffect(() => {
    const maps = mapInit();
    return () => {
      if (maps) {
        maps.destroy();
      }
      isClosed = null;
      loca = null;
      layer = null;
    };
  }, []);

  useEffect(() => {
    if (infoClosed && points.length > 0) {
      // console.log(points[points.length-1], 'points1');
      onMakerClick(points[points.length - 1]);
    }
  }, [infoClosed]);

  useEffect(() => {
    if (window.AMap && !mapLoading) {
      map.clearMap();
      if (loca) {
        loca.destroy();
        layer.destroy();
      }
      if (points.length > 0) {
        // console.log(points[points.length-1], 'points');
        onMakerClick(points[points.length - 1]);
      }
      addLabel(points);
      addLoca(points);
    }
  }, [points, mapLoading]);

  useEffect(() => {
    if (reload > 0) {
      map.setZoomAndCenter(zoom, center);
    }
  }, [reload, bikeType]);


  return (
    <Spin
      spinning={mapLoading}
      tip="该页面数据量较大，请稍作等待"
      wrapperClassName={'map-loading-pilot'}
    >
      <div
        id="map"
        className={fold ? 'device-maker-map-full' : 'device-maker-map-pilot'}
      />
    </Spin>
  );
};
LocaScatterLayer.propTypes = {
  bikeType: PropTypes.number.isRequired,
  title: PropTypes.string,
  onMakerClick: PropTypes.func.isRequired,
  reload: PropTypes.number.isRequired,
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  fold: PropTypes.bool,
};


export default LocaScatterLayer;
