import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getTaskDetails} from '../../api';
import {Col, Row, Skeleton, Space, Image} from 'antd';
import {dispatchStatus} from '../statusCollection';
import CustomImage from '../customImage';

const TaskInfo = ({id, type, tabsKey}) => {
  const [baseInfo, setBaseInfo]= useState({});
  const [loading, setLoading] = useState(false);
  const getInitData=()=>{
    setLoading(true);
    getTaskDetails(id, 2).then((res)=>{
      setBaseInfo(res.data);
    }).finally(()=>{
      setLoading(false);
    });
  };
  useEffect(()=>{
    getInitData();
  }, []);
  return (
    <div id="task-info">
      <h3 className="details-title">
        调度详情
      </h3>
      {loading?( <Skeleton active />):(<div className="details-info margin-t-l">
        {type==='按地点查看'?(<div>
          <Image.PreviewGroup>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>调度任务状态：</span>
                <span className="text-gray">{dispatchStatus(baseInfo.taskStatus)}</span>
              </Col>
              <Col span={7}>
                <span>
              调度起点：
                </span>
                <span className="text-gray">{baseInfo.firstDispatchAddress || '暂无数据'}</span>
              </Col>
              <Col span={10}>
                <span>
              调度终点：
                </span>
                <span className="text-gray">{baseInfo.lastDispatchAddress || '暂无数据'}</span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>
            开始时间：
                </span>
                <span className="text-gray">{baseInfo.startTime || '暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>
              结束时间：
                </span>
                <span className="text-gray">{baseInfo.endTime || '暂无数据'}</span>
              </Col>
              <Col span={10}>
                <span>
              调度数量：
                </span>
                <span className="text-gray">{baseInfo.count || '暂无数据'}</span>
              </Col>
              <Col span={10}>
                {/* <span>
            调度距离：
              </span>
              <span className='text-gray'>{baseInfo.distance || '暂无数据'}</span> */}
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7} >
                <div className="margin-b-m">调度起点图片：</div>
                <div className="padding-r-m">
                  <Space size={'large'}>
                    {
                      baseInfo.firstDispatchAddressImg&&baseInfo.firstDispatchAddressImg.length>0?
                      baseInfo.firstDispatchAddressImg.map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                    }
                  </Space>
                </div>
              </Col>

            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <div className="margin-b-m">调度终点图片：</div>
                <div className="padding-r-m">
                  <Space size={'large'}>
                    {
                      baseInfo.lastDispatchAddressImg&&baseInfo.lastDispatchAddressImg.length>0?
                      baseInfo.lastDispatchAddressImg.map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                    }
                  </Space>
                </div>
              </Col>
            </Row>
          </Image.PreviewGroup>
        </div>):<div>
          <Image.PreviewGroup>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>调度任务状态：</span>
                <span className="text-gray">{dispatchStatus(baseInfo.taskStatus)}</span>
              </Col>
              <Col span={7}>
                {/* <span>
            调度距离：
              </span>
              <span className='text-gray'>{baseInfo.distance || '暂无数据'}</span> */}
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>
              调度起点：
                </span>
                <span className="text-gray">{baseInfo.firstDispatchAddress || '暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>
              调度终点：
                </span>
                <span className="text-gray">{baseInfo.lastDispatchAddress || '暂无数据'}</span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>
            开始时间：
                </span>
                <span className="text-gray">{baseInfo.startTime || '暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>
              结束时间：
                </span>
                <span className="text-gray">{baseInfo.endTime || '暂无数据'}</span>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7} >
                <div className="margin-b-m">调度起点图片：</div>
                <div className="padding-r-m">
                  <Space size={'large'} >
                    {
                      baseInfo.firstDispatchAddressImg&&baseInfo.firstDispatchAddressImg.length>0?
                      baseInfo.firstDispatchAddressImg.map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                    }
                  </Space>
                </div>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <div className="margin-b-m">调度终点图片：</div>
                <div className="padding-r-m">
                  <Space size={'large'}>
                    {
                      baseInfo.lastDispatchAddressImg&&baseInfo.lastDispatchAddressImg.length>0?
                      baseInfo.lastDispatchAddressImg.map((item)=>{
                        return <CustomImage key={item} src={item} width={276} height={259}/>;
                      }):<CustomImage src={''} width={276} height={259}/>
                    }
                  </Space>
                </div>
              </Col>
            </Row>
          </Image.PreviewGroup>
        </div>}
      </div>)}
    </div>
  );
};

TaskInfo.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  tabsKey: PropTypes.str,
};

export default TaskInfo;
