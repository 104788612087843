import React from 'react';
import {Button, Modal} from 'antd';
import PropTypes from 'prop-types';
import {ExclamationCircleOutlined} from '@ant-design/icons';

const ExitEdit=({visible, setVisible, confirm})=> {
  return (
    <Modal title={null} visible={visible} width={400} maskClosable={false} footer={null} closable={false}>
      <div className="off-module">
        <ExclamationCircleOutlined className="off-icon-c"/>
        <div>
          <p>确认退出编辑</p>
          <p>你正在编辑设置，离开当前页面，编辑内容将丢失，是否确认离开？</p>
          <div className="off-btns">
            <Button className="margin-r-m" onClick={()=>setVisible()}>取消</Button>
            <Button type="primary" onClick={()=>confirm()}>确认</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
ExitEdit.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  confirm: PropTypes.func,
};
export default ExitEdit;
