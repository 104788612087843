import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Card, Col, Divider, message, Modal, Row} from 'antd';
import {deleteUser, getUserDetails} from '../../../../api';
import DetailsHeader from '../../../../components/detailsHeader';
import {getEnterprise} from '../../../../components/statusCollection';
import {timeFormat} from '../../../../components/baseTime';
// import PropTypes from 'prop-types';

const UserDetails = () => {
  const [infoData, setInfoData]=useState({});
  const [menus, setMenus]=useState([]);
  const [loading, setLoading] = useState(false);
  const ng = useNavigate();
  const d=useParams();
  const deleteRoleBtn=()=>{
    Modal.confirm({
      title: '确认删除用户信息',
      content: (
        <div>
          <p>删除用户不可恢复，是否确认删除？
          </p>
          <Row>
            <Col span={24}>姓名：{infoData.name}</Col>
            <Col span={24}>用户名：{infoData.username}</Col>
          </Row>
        </div>
      ),
      cancelButtonProps: {
        className: 'dangerous',
      },
      onCancel: ()=>{
        deleteUser(d.userId, '2').then((res)=>{
          message.success(res.msg);
          ng('/accessControl/users');
        });
      },
      cancelText: '删除',
      okText: '取消',
      okType: 'default',
    });
  };
  const getUserInfo=()=>{
    setLoading(true);
    getUserDetails(d.userId, '2').then((res)=>{
      setInfoData(res.data.userInfo);
      const menus = [];
      res.data.roleInfo.children.forEach(({cnName, children}) => {
        menus.push(cnName);
        children.forEach(({cnName}) => {
          menus.push(cnName);
        });
      });
      setMenus(menus);
    }).finally(()=>{
      setLoading(false);
    });
  };
  useEffect(()=>{
    getUserInfo();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="用户详情" buttonGroup={() => (
        infoData.roleName !='超级管理员'&&<Button type="primary" onClick={deleteRoleBtn}>
          删除用户
        </Button>)}/>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s" loading={loading}>
        <div>
          <h3 className="details-title">
            用户信息
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l">
              <Col span={7}>
                <span>
                姓名：
                </span>
                <span className="text-gray">
                  {infoData.name||'暂无数据'}
                </span>
              </Col>
              <Col span={7}>
                <span>用户名：</span>
                <span className="text-gray">
                  {infoData.username||'暂无数据'}
                </span>
              </Col>
              <Col span={10}>
                <span>管辖区域：</span>
                <span className="text-gray">
                  {infoData.areaName||'无区域'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l">
              <Col span={7}>
                <span>管辖企业：</span>
                <span className="text-gray">
                  {getEnterprise(infoData.bikeEnterprise)}
                </span>
              </Col>
              <Col span={7}>
                <span>联系方式：</span>
                <span className="text-gray">
                  {infoData.mobile||'暂无数据'}
                </span>
              </Col>
              <Col span={10}>
                <span>用户状态：</span>
                <span className="text-gray">
                  {parseInt(infoData.status) === 1 ? '正常' :
                  parseInt(infoData.status) === 0 ? '禁用':
                  parseInt(infoData.status) === 2? '锁定':'暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l">
              <Col span={7}>
                <span>创建时间：</span>
                <span className="text-gray">
                  {timeFormat(infoData.gmtCreate)||'暂无数据'}
                </span>
              </Col>
            </Row>
            <Row className="margin-b-l">
              <Col span={17}>
                <span>备注：</span>
                <span className="text-gray">
                  {infoData.remark||'暂无数据'}
                </span>
              </Col>
            </Row>
          </div>
          <Divider />
          <h3 className="details-title">
            关联角色信息
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l">
              <Col span={7}>
                <span>角色名：</span>
                <span className="text-gray">
                  {infoData.roleName||'暂无数据'}
                </span>
              </Col>
              <Col span={17}>
                <span>页面权限：</span>
                {menus.join('，')||'暂无数据'}
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  );
};

UserDetails.propTypes = {};

export default UserDetails;
