import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {
  Button,
  Form,
  Select,
  Input, Space, Cascader, message, Row, Col, Modal, Badge, Switch, Tooltip, AutoComplete} from 'antd';
import BasicTIM from '../../../../components/rtc/IM';
import {bikeCompanyOptions} from '../../../../components/statusCollection';
// import Makers from '../../../../components/aMap/Makers';
import {getAreaTreeFilter, screenSearchLenovo} from '../../../../api';
import {areaStreetTreeData} from '../../../../components/forms/AreaTreeFuc';
import StaffLocaScatterLayer from '../../../../components/aMap/StaffLocaScatterLayer';
import {
  ArrowsAltOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  QuestionCircleOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
// import {CloseOutlined} from '@ant-design/icons';
import {TRTCMsg} from '../../../../components/rtc/IMMessage';
import legend from '../../../../asset/mapIcon/legend-bg.png';
import legendE from '../../../../asset/mapIcon/legend-e-bg.png';
import {storage} from '../../../../utils/storage';
import StaffMarkerCluster from '../../../../components/aMap/StaffMarkerCluster';
import NoticeBoard from './Notice';
// const {Option} = AutoComplete;


const ScreenMap = ({
  onFilterFunc,
  fold,
  staffData,
  queryType,
  initTimer,
  onFoldCharts,
  bigMap,
  onAreaClick,
  searchCenter,
}) => {
  const userInfo = storage.getData('userInfo')?.roleName || null;
  // const [notices, setNotices] = useState([]);
  const [searchForm] = Form.useForm();
  const [msgCounts, setMsgCounts] = useState(0);
  const [areaOptions, setAreaOptions] = useState([]);
  const [disabledFilter, setDisabledFilter] = useState(true);
  const [enableFilter, setEnableFilter] = useState(false);
  const [reload, setReload] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [typeValue, setTypeValue] = useState(1);
  const [chartName, setChartName] = useState('');
  const [chartCompany, setChartCompany] = useState('');
  const [noticeModal, setNoticeModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [isShowMessage, setIsShowMessage] = useState(
      sessionStorage.getItem('isShowMessage') === 'true'||
  !sessionStorage.getItem('isShowMessage'));
  const [isShowKeyArea, setIsShowKeyArea] = useState( sessionStorage.getItem('isShowKeyArea') === 'true' || false);

  // TRTC
  const [TRTCId, setTRTCId] = useState(null);
  // 0： 框隐
  // 1： 中屏
  // 2： 全屏
  // 3： 小屏
  const [TRTCModal, setTRTCModal] = useState(0);

  const [isOnChart, setIsOnChart] = useState(false);
  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return (
            <span key={option.value}>
              {option.areaName}
            </span>
          );
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  // 模糊搜索
  const getPanelValue = async (searchText) =>{
    setOptions([]);
    const res = await screenSearchLenovo(
        {type: typeValue, content: searchText, queryType: queryType});
    if (res.code===200) {
      console.log(res.data, 'res.data');
      const newArr = [...new Set(res.data)];
      const numArray= newArr.map((item)=>{
        return {
          label: item,
          value: item,
        };
      });
      const cun = [...new Set(numArray)];
      setOptions(cun);
    }
  };
  // const onSearchChange=(value)=>{
  //   console.log(value, 'value');
  //   // setInputValue(value);
  //   getPanelValue(value);
  //   // getPanelValue();
  // };
  const handleSearch = (value) => {
    // 向后端发送请求，获取数据
    getPanelValue(value);
  };
  //
  const onSearch = (values) => {
    const data = values ? {
      ...values.composite,
      type: typeValue,
      companyList: values.companyList,
      jobTypeList: values.jobTypeList,
      emphasisGuaranteeStatus: values.emphasisGuaranteeStatus,
      securityAreaStatus: values.securityAreaStatus,
      streetIds: areaStreetTreeData(
          values.streetIds,
          areaOptions,
          'search'),
      emphasisGuaranteeStreetIds: areaStreetTreeData(
          values.emphasisGuaranteeStreetIds,
          areaOptions,
          'search'),
    } : {};
    setEnableFilter(true);
    onFilterFunc(data);
    setShowFilter(false);
  };


  //  地图筛选项变化
  const onFieldsChange = (changedFields, allFields) => {
    let isNull = true;
    allFields.forEach((field) => {
      if (field.value && field.value.length > 0) {
        isNull = false;
      }
    });
    setDisabledFilter(isNull);
    // 搜索内容为空
    if (isNull) {
      // 关闭筛选按钮
      if (enableFilter) {
        initTimer(null);
      }
      setEnableFilter(false);
    }
  };


  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type = 2;
      const data = res.data.map((item, index) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}&nbsp;&nbsp;
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}&nbsp;&nbsp;
                <span className="text-light"> {sub.managerName || ''}</span>
              </span>),
              value: sub.id,
              streetManagerName: sub.managerName || '',
              disabled: type === 4 && sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {aff.managerName || ''}</span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  // disabled: type===4&&aff.managerName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };

  const getUserMapConfig = () => {
    // getMapZoomCenter(2).then((res) => {
    //   setCenter(res.data.latitudeLongitude?res.data.latitudeLongitude.split(','):[104.065861, 30.657401]);
    //   setZoom(zoomLevel(res.data.level));
    // });
  };
  // 大屏初始化
  const initMap = () => {
    // getUserMapConfig
    getUserMapConfig();
    // 取消高亮筛选状态
    setEnableFilter(false);
    // 获取区域信息
    getAreaGroup();
    //  关闭搜索框
    setShowFilter(false);
    setDisabledFilter(true);
    //  关闭图例
    setShowLegend(false);
    //  form重置
    setTypeValue(3);
    // 地图筛选按钮置灰
    setEnableFilter(false);
  };
  const TRTCFuc = (userId, name, company, tel) => {
    if (!isOnChart) {
      Modal.confirm({
        title: '确认向该人员发起视频通话？',
        icon: <ExclamationCircleOutlined />,
        content: (<Row>
          <Col span={14} className="text-ellipsis">姓名：{name}</Col>
          <Col span={10}>所属企业：{company}</Col>
          <Col span={24}>联系方式：{tel}</Col>
        </Row>),
        okText: '取消',
        cancelText: '确定',
        wrapClassName: "model-map",
        getContainer: () => {
          const el = document.getElementById('new-body');
          if (el) {
            return el;
          }
          return document.body;

        },
        onCancel() {
          setTRTCId(userId);
          setChartName(name);
          setChartCompany(company);
          setTRTCModal(1);
          setIsOnChart(true);
        },
      });
    } else {
      message.error(userId === TRTCId ?
        TRTCMsg['warning']['bothDuringTRTC'] :
        TRTCMsg['warning']['duringTRTC']);
    }
    // console.log(userId);
  };

  useEffect(() => {
    initMap();
  }, [queryType]);

  useEffect(() => {
    if (isShowMessage) {
      setIsShowMessage(false);
      setTimeout(() => {
        setIsShowMessage(true);
      }, 10);
    }
  }, [bigMap]);


  return (
    <>
      {/* 工具栏 */}
      <div className={queryType==1?'map-toolbar height-540':'map-toolbar'}
      >
        <div
          onClick={onFoldCharts}
          className={'map-toolbar-btn ' + (fold ? 'small-icon' : 'full-icon')}>
          {fold ? '退出' : '地图'}全屏
        </div>
        <div className="map-toolbar-btn reload-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setReload(reload + 1);
          }}>
          恢复默认
        </div>
        <div className={'map-toolbar-btn ' + (enableFilter ? 'filter-icon-on' : 'filter-icon')}
          onClick={() => {
            setShowFilter(true);
          }}
        >
          选项筛选
        </div>

        <div className="map-toolbar-btn legend-icon"
          onClick={() => {
            setShowLegend(true);
          }}
        >
          图例说明
        </div>
        {queryType===1&&
        <div className="map-toolbar-btn warnning-icon"
          onClick={() => {
            setNoticeModal(true);
          }}
        >
          <Badge count={msgCounts} offset={ [8, -34]}>
            告警
          </Badge>
        </div>
        }
        {queryType===1&&<div className="map-toolbar-btn setting-icon"
          onClick={() => {
            setSettingModal(true);
          }}
        >
          设置
        </div>}
      </div>
      {/* 消息条 */}
      {queryType===1&&<NoticeBoard onMsgChange={setMsgCounts} isShowMessage={isShowMessage} />}
      {/* 地图 */}
      <div className={`map-box ${location.pathname.indexOf('/bigScreen') !== -1 && bigMap===0? 'screen-show-h':''}`}>
        {userInfo !== '城市管理员' ?
          <StaffLocaScatterLayer
            searchCenter={searchCenter}
            linkTRTC={TRTCFuc}
            points={staffData || {}}
            isShowKeyArea={isShowKeyArea}
            fullScreen={bigMap}
            reload={reload}
            onAreaClick={onAreaClick}
            queryType={queryType}
          /> : <StaffMarkerCluster
            points={staffData?.extData}
            isShowKeyArea={isShowKeyArea}
            markRangeList={staffData?.markRangeList || []}
            fullScreen={bigMap}
            reload={reload}
            onAreaClick={onAreaClick}
            queryType={queryType}
            searchCenter={searchCenter}
          />}
      </div>
      {/* 选项筛选 */}
      <Modal
        className="map-modal"
        title="选项筛选"
        width={400}
        mask={false}
        visible={showFilter}
        destroyOnClose
        onCancel={() => {
          setShowFilter(false);
          searchForm.resetFields();
        }}
        // getContainer={() => document.querySelector('#parent-container')}
        style={{
          position: 'absolute',
          right: 150,
          top: 190,
          // top: 190,
          // right: -390,
        }}
        wrapClassName="model-map"
        footer={null}>
        <Form
          layout="vertical"
          onFinish={onSearch}
          form={searchForm}
          onFieldsChange={onFieldsChange}
          className="padding-t-m padding-l-m padding-r-m"
        >
          <div className="map-search-body">
            <Form.Item label="搜索运维人员/保障区域/重点工作区域保障" className="margin-b0">
              <Input.Group compact>
                <Form.Item
                  name={['composite', 'type']}
                  noStyle
                  initialValue={3}
                >
                  <Select
                    placeholder="请选择"
                    style={{
                      width: '50%',
                    }}
                    // defaultValue={3}
                    onChange={(value) => {
                      setTypeValue(value || null);
                      searchForm.setFieldsValue({
                        composite: {content: null},
                      });
                      setOptions([]);
                    }}
                  >
                    {/* <Select.Option value={1}>
                    运维工号
                  </Select.Option> */}
                    <Select.Option value={3}>
                      运维人员联系电话
                    </Select.Option>
                    <Select.Option value={2}>
                      运维人员姓名
                    </Select.Option>
                    <Select.Option value={4}>
                      保障区域名称
                    </Select.Option>
                    <Select.Option value={6} disabled={!isShowKeyArea}>
                      重点工作区域保障名称
                    </Select.Option>
                    {/* <Select.Option value={5}>
                    保障区域创建人
                  </Select.Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '50%',
                  }}
                  name={['composite', 'content']}
                >
                  {/* <Input
                    disabled={!typeValue}
                    placeholder="请输入"
                  /> */}
                  <AutoComplete
                    options={options}
                    onSearch={handleSearch}
                    onFocus={()=> getPanelValue('')}
                  >
                    <Input placeholder="请输入搜索内容" />
                  </AutoComplete>
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="人员职务类型" name="jobTypeList">
              <Select placeholder="请选择（多选）" mode="multiple">
                {queryType === 1 && (<Select.Option value={1}>单车人员</Select.Option>)}
                {queryType === 1 && (<Select.Option value={2}>单车调运车辆</Select.Option>)}
                {queryType === 2 && (<Select.Option value={5}>电单车人员</Select.Option>)}
                {queryType === 2 && (<Select.Option value={6}>电单车调运车辆</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item label="人员所属企业" name="companyList">
              <Select placeholder="请选择（多选）" mode="multiple" options={bikeCompanyOptions('bike')} />
            </Form.Item>
            <Form.Item label="人员所属区域" name="streetIds">
              <Cascader
                allowClear={false}
                multiple
                maxTagCount="responsive"
                placement="bottomRight"
                displayRender={displayRender}
                placeholder="请选择（可多选）"
                options={areaOptions}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item label="保障区域状态" name="securityAreaStatus">
              <Select placeholder="请选择（多选）" mode="multiple">
                <Select.Option value={0}>未开始</Select.Option>
                <Select.Option value={1}>进行中</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="重点工作区域保障状态" name="emphasisGuaranteeStatus">
              <Select placeholder="请选择（多选）" mode="multiple">
                <Select.Option value={0}>未开始</Select.Option>
                <Select.Option value={1}>进行中</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{marginBottom: 16}}
              label="重点工作区域保障所属区域" name="emphasisGuaranteeStreetIds">
              <Cascader
                allowClear={false}
                multiple
                maxTagCount="responsive"
                placement="bottomRight"
                displayRender={displayRender}
                placeholder="请选择（可多选）"
                options={areaOptions}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </div>

          <div className="text-center padding-t-s padding-b-m">
            <Space>
              <Button
                disabled={disabledFilter}
                onClick={() => {
                  // 取消高亮筛选状态
                  setEnableFilter(false);
                  // 按钮置灰
                  setDisabledFilter(true);
                  // 清空搜索内容
                  setTypeValue(1);
                  searchForm.resetFields();
                  if (enableFilter) {
                    initTimer(null);
                  }
                }}>
                  重置
              </Button>
              <Button
                disabled={disabledFilter}
                htmlType="submit"
                type={disabledFilter ? 'default' : 'primary'}
              >
                  搜索
              </Button>
            </Space>
          </div>

        </Form>
      </Modal>
      {/* 图例说明 */}
      <Modal
        className="map-modal"
        title="图例说明"
        width={300}
        mask={false}
        visible={showLegend}
        onCancel={() => {
          setShowLegend(false);
        }}
        style={{
          top: 400,
          position: 'absolute',
          right: 150,
        }}
        wrapClassName="model-map"
        footer={null}>
        <img src={queryType === 1 ? legend : legendE} alt="legend" height={313} />
      </Modal>
      {/* 告警设置 */}
      <Modal
        className="map-modal"
        title="告警设置"
        width={270}
        mask={false}
        visible={noticeModal}
        onCancel={() => {
          setNoticeModal(false);
        }}
        style={{
          top: 502,
          position: 'absolute',
          right: 150,
        }}
        wrapClassName="model-map"
        footer={null}>
        <Row>
          <Col span={14}>

            告警<Tooltip

              color="#262626"
              title={<>
                <div>进行中的重点工作区域，应配人数大于0的企业，连续指定轮次到岗率低于100%，生成告警消息。</div>
                <div>点击按钮，可显示或隐藏告警消息。</div>
              </>}
            >
              <QuestionCircleOutlined className="margin-l-s" />
            </Tooltip>

          </Col>
          <Col span={10} className="text-right">
            <Switch size="large"
              onChange={(checked) => {
                storage.saveData('session', 'isShowMessage', checked.toString());
                setIsShowMessage(checked);
              }}
              defaultChecked={isShowMessage} />
          </Col>
        </Row>
      </Modal>
      {/* 地图设置 */}
      <Modal
        className="map-modal"
        title="地图设置"
        width={270}
        mask={false}
        visible={settingModal}
        onCancel={() => {
          setSettingModal(false);
        }}
        style={{
          top: 582,
          position: 'absolute',
          right: 150,
        }}
        wrapClassName="model-map"
        footer={null}>
        <Row>
          <Col span={20}>

            显示重点工作区域保障

          </Col>
          <Col span={4} className="text-right">
            <Switch size="large"
              onChange={(checked) => {
                setIsShowKeyArea(checked);
                storage.saveData('session', 'isShowKeyArea', checked.toString());
              }}
              defaultChecked={isShowKeyArea} />
          </Col>
        </Row>
      </Modal>
      {/* 视频通话 */}
      {userInfo !== '城市管理员' ?
        <div className={TRTCModal === 0 ? 'chat-box-hide' :
          TRTCModal === 1 ? `chat-box-m` :
            TRTCModal === 2 ? `chat-box-s ${location.pathname.indexOf('/bigScreen') !== -1? 'postion-sticky':''}` :
              'chat-box-l'}>
          <Row className="chat-box-title">
            <Col span={12} className={TRTCModal !== 2 ? 'text-xl padding-l-m' : 'padding-l-m'}>
              视频通话
            </Col>
            <Col span={12} className="text-right">
              <Space>
                {TRTCModal === 1 && <Button
                  type="link"
                  icon={<FullscreenOutlined />}
                  onClick={() => {
                    setTRTCModal(3);
                  }}>全屏</Button>}
                {TRTCModal !== 1 && <Button
                  type="link"
                  icon={TRTCModal === 2 ? <ArrowsAltOutlined /> : <FullscreenExitOutlined />}
                  onClick={() => {
                    setTRTCModal(1);
                  }}>{TRTCModal === 2 ? '放大' : '退出全屏'}</Button>}
                {TRTCModal !== 2 && <Button
                  type="link"
                  icon={<ShrinkOutlined />}
                  onClick={() => {
                    setTRTCModal(2);
                  }}>最小化</Button>}
              </Space>
            </Col>
          </Row>
          <BasicTIM
            userId={TRTCId}
            chartName={chartName}
            TRTCModal={TRTCModal}
            chartCompany={chartCompany}
            seeChat={() => {
              setTRTCModal(1);
            }}
            queryType={queryType}
            onFinished={() => {
              setTRTCModal(0);
              setChartCompany('');
              setChartName('');
              setTRTCId(null);
              setIsOnChart(false);
            }} />
        </div> :
        null}
    </>
  );
};
ScreenMap.propTypes = {
  onFoldCharts: PropTypes.func.isRequired,
  bigMap: PropTypes.number.isRequired,
  initTimer: PropTypes.func.isRequired,
  onFilterFunc: PropTypes.func.isRequired,
  fold: PropTypes.bool.isRequired,
  staffData: PropTypes.object,
  queryType: PropTypes.number.isRequired,
  onAreaClick: PropTypes.func.isRequired,
  searchCenter: PropTypes.array,
};
export default ScreenMap;
