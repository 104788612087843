import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {Anchor, Button, Card, Col, Divider, message, Modal, Row, Skeleton} from 'antd';
import BaseInfo from './BaseInfo';
import MemberInfo from './MemberInfo';
import RuleInfo from './RuleInfo';
import {useNavigate, useParams} from 'react-router-dom';
import {deleteGroup, getAttendanceDetail} from '../../../api';
import './style.css';

const AttendanceDetails = () => {
  const [baseInfo, setBaseInfo] = useState({});
  const [ruleInfo, setRuleInfo] = useState({});
  const [memberInfo, setMemberInfo] = useState({});
  const [streetId, setStreetId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [update, setUpDate] = useState(0);
  const [updateInfo, setUpdateInfo]= useState(null);
  const d=useParams();
  const ng = useNavigate();
  const getDetails=()=>{
    setLoading(true);
    getAttendanceDetail(d.id, 2 ).then((res)=>{
      if (res.data) {
        setBaseInfo(res.data.attendanceDetailBasicInfoVO||{});
        setMemberInfo({
          groupMemberInfoList: res.data.groupMemberInfoList,
          ...res.data.groupMemberStatusCount,
        });
        setRuleInfo({
          ...res.data.attendanceCommonRule,
          attendanceBasicRule: res.data.attendanceBasicRule||[],
          attendanceCount: res.data.attendanceCount||0,
        });
        setUpdateInfo(res.data.updateInfo||null);
        setStreetId(res.data.attendanceDetailBasicInfoVO?.streetId||null);
      }
    }).finally(()=>{
      setLoading(false);
    });
  };
  const deleteGroupBtn=()=>{
    Modal.confirm({
      title: '确认删除考勤组',
      content: (
        <div>
          <p>删除考勤组后，所有组员将被自动从组内移除，从而运维人员无法进行考勤打卡，是否确认删除考勤组?
          </p>
        </div>
      ),
      cancelButtonProps: {
        className: 'dangerous',
      },
      onCancel: ()=>{
        deleteGroup(d.id, '2').then((res)=>{
          message.success(res.msg);
          ng('/attendanceManagement');
        });
      },
      cancelText: '删除',
      okText: '取消',
      okType: 'default',
    });
  };

  useEffect(()=>{
    getDetails();
  }, [update]);

  return (
    <div>
      <DetailsHeader
        pageName="考勤组详情"
        buttonGroup={()=>(<Button type="primary" onClick={deleteGroupBtn}> 删除考勤组</Button>)} />
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <Row>
          <Col flex="auto" className="padding-r-l">
            <div id="base-info" >
              {loading?(<Skeleton active />):(<BaseInfo
                baseInfo={baseInfo}
                id={d.id}
                onFinished={() => {
                  setUpDate(update + 1);
                }}/>)}
            </div>

            <Divider />
            <div id="rule-info" >
              {loading?(<Skeleton active />):(<RuleInfo
                baseInfo={ruleInfo}
                id={d.id}
                updateInfo={updateInfo}
                onFinished={()=>{
                  setUpDate(update+1);
                }}/>)}
            </div>
            <Divider />
            <div id="member-info">
              {loading?(<Skeleton active />):(<MemberInfo
                baseInfo={memberInfo}
                streetId={streetId}
                id={d.id}
                finished={()=>{
                  setUpDate(update+1);
                }}/>)}
            </div>
          </Col>
          <Col flex="80px">
            <Anchor>
              <Anchor.Link href="#base-info" title="基础信息"/>
              <Anchor.Link href="#rule-info" title="考勤规则"/>
              <Anchor.Link href="#member-info" title="组员信息"/>
            </Anchor>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

AttendanceDetails.propTypes = {};

export default AttendanceDetails;
