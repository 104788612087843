const ExportJsonExcel = require('js-export-excel');

export const saveExcel=(filename, sheetHeader, sheetFilter, sheetData)=> {
  const options = {
    fileName: filename,
    datas: [{
      sheetHeader,
      sheetFilter,
      sheetData,
    }],
  };

  const excel = new ExportJsonExcel(options);

  excel.saveExcel();
};

export const exportExcel=(filename, columns, data)=> {
  const sheetHeader = columns.map((column) => typeof (column.title)==='object'?'考勤点工作状态':column.title);
  const sheetFilter = columns.map((column) => column.key);
  const sheetData = data.map((rowInput) => {
    const rowOutput = {};
    columns.forEach(({dataIndex, render, exportRender, onExport}) => {
      if (onExport) {
        rowOutput[dataIndex] = (exportRender) ? exportRender(rowInput) : rowInput[dataIndex];
      }
    });
    return rowOutput;
  });

  saveExcel(filename, sheetHeader, sheetFilter, sheetData);
};
