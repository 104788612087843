import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import BarGraph from './BarGraph';
import PieCharts from './PieCharts';
import {moduleTitle, exportDescription, typeExport, pieTitle, barTitle} from './IndicatorDescription';

const ExportPieBar = ({pieData, barData, vehicleType, module, pieId, barId, statisticsType, total}) => {
  // 3-出勤率(运维人员)；4-出勤率(调运车辆)；5-在线率(运维人员)；6-在线率(调运车辆)；7-未备案；8-跨区域
  const timeTab = statisticsType === 1 ? '日' : statisticsType === 2 ? '周' : '月';
  // 总数
  const aggregate = (key) => {
    switch (key) {
      case 3:
        return `当${timeTab}出勤${vehicleType}秩序人员总数：${total[1] || 0}人。`;
      case 4:
        return `当${timeTab}出勤${vehicleType}调运车辆总数：${total[2] || 0}辆。`;
      case 5:
        return `当${timeTab}在线${vehicleType}秩序人员总数：${total[3] || 0}人。`;
      case 6:
        return `当${timeTab}在线${vehicleType}调运车辆总数：${total[4] || 0}辆。`;
      case 7:
        return `当${timeTab}未备案${vehicleType}总数：${total[6] || 0}辆。`;
      case 8:
        return `当${timeTab}跨区域${vehicleType}总数：${total[7] || 0}辆。`;
      case 9:
        return `当${timeTab}重点工作区域保障告警总数：${total[8] || 0}个。`;
      default:
        break;
    }
  };
  // 环图数据解析
  const pieDataDetail = (key) => {
    switch (key) {
      case 3:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}秩序人员：实际出勤{item.value}人，占出勤人员总数的{item.persent}；
            </span>
          );
        });
      case 4:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}调运车辆：实际出勤{item.value}辆，占出勤车辆总数的{item.persent}；
            </span>
          );
        });
      case 5:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}秩序人员：实际在线{item.value}人，占在线人员总数的{item.persent}；
            </span>
          );
        });
      case 6:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}调运车辆：实际在线{item.value}辆，占在线车辆总数的{item.persent}；
            </span>
          );
        });
      case 7:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}未备案{vehicleType}：{item.value}辆，占未备案{vehicleType}总数的{item.persent}；
            </span>
          );
        });
      case 8:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}跨区域{vehicleType}：{item.value}辆，占跨区域{vehicleType}总数的{item.persent}；
            </span>
          );
        });
      case 9:
        return pieData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}告警数：{item.value}个，占告警总数的{item.persent}；
            </span>
          );
        });
      default:
        break;
    }
  };

  // 柱状图数据解析
  const barDataDetail = (key) => {
    switch (key) {
      case 3:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}秩序人员：
              {/* 实际出勤{item.value}人， */}
              出勤率{item.persent}；
            </span>
          );
        });
      case 4:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}调运车辆：
              {/* 实际出勤{item.value}辆， */}
              出勤率{item.persent}；
            </span>
          );
        });
      case 5:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}秩序人员：
              {/* 实际在线{item.value}人， */}
              在线率{item.persent}；
            </span>
          );
        });
      case 6:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}
              {vehicleType}调运车辆：
              {/* 实际在线{item.value}辆， */}
              在线率{item.persent}；
            </span>
          );
        });
      case 7:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}未备案{vehicleType}：{/* {item.value}辆， */}
              未备案率{item.persent}；
            </span>
          );
        });
      case 8:
        return barData.map((item) => {
          return (
            <span key={item.type + item.value}>
              {item.type}跨区域{vehicleType}：{/* {item.value}辆， */}
              跨区域率{item.persent}；
            </span>
          );
        });
      default:
        break;
    }
  };

  const PieChartsData = useMemo(
      () => <PieCharts data={pieData} isExportOpen={true} id={pieId} dataType={module === 9 ? 'percent' : 'value'} />,
      [pieData],
  );

  const BarChartsData = useMemo(
      () => <BarGraph data={barData} isExportOpen={true} id={barId} dataType={module === 9 ? 'value':'percent'} />,
      [barData],
  );

  return (
    <div className="area-monitor">
      <p className="export-heading">{moduleTitle(module, vehicleType, statisticsType)}</p>
      <p>{aggregate(module)}</p>
      {(statisticsType === 1 || module === 9) && <p>其中，{pieDataDetail(module)}</p>}
      {(statisticsType === 1 || module === 9) && (
        <p className="gray-color">图表（1）{pieTitle(typeExport(module), vehicleType)}</p>
      )}
      {(statisticsType === 1 || module === 9) && pieData && <div className="chart-width">{PieChartsData}</div>}
      {module !== 9 && (
        <p>
          {statisticsType === 1 ? '当' + timeTab + '，':'其中，'}
          {barDataDetail(module)}
        </p>
      )}
      <p className="gray-color">
        图表{(statisticsType === 1 || module === 9) && '（2）'}
        {barTitle(typeExport(module), vehicleType)}
      </p>
      {barData && <div className="chart-width">{BarChartsData}</div>}
      {exportDescription(module, vehicleType, statisticsType)}
    </div>
  );
};
ExportPieBar.propTypes = {
  pieData: PropTypes.any,
  barData: PropTypes.any,
  vehicleType: PropTypes.string,
  module: PropTypes.number,
  pieId: PropTypes.string,
  barId: PropTypes.string,
  statisticsType: PropTypes.number,
  total: PropTypes.object,
};
export default ExportPieBar;
