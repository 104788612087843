import React, {} from 'react';
// import PropTypes from 'prop-types';
import CustomTable from '../../../components/customTable';
import {importType, bikeCompany, importContent, areaNameShow} from '../../../components/statusCollection';
import {Button, Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {UPLOADLOG} from '../../../api/Urls';

const ImportRecords = () => {
  const columns = [
    {
      title: '导入内容',
      dataIndex: 'importContent',
      key: 'importContent',
      render: (record)=>importContent(record),
    },
    {
      title: '导入状态',
      dataIndex: 'importStatus',
      key: 'importStatus',
      render: (record, item) => importType(record, item.errMsg),
    },
    {
      title: '所属车企',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      render: (record, item) => item.importStatus===0?'-':bikeCompany(record),
    },
    {
      title: '所属区域',
      dataIndex: 'bikeName',
      key: 'bikeName',
      width: 300,
      render: (areaName) => (areaNameShow(areaName, '所属区域') || '-'),
    },
    {
      title: '导入数量',
      dataIndex: 'importNumber',
      key: 'importNumber',
      render: (record) => record || '-',
    },
    {
      title: '操作人',
      dataIndex: 'createName',
      key: 'createName',
    },
    {
      title: '导入时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      key: 'option',
      render: (_, item) => (item.importStatus === 0 && item.downloadUrl) &&
        <Button type="link" href={item.downloadUrl}
          download="错误数据.xlsx">下载错误数据</Button>,
    },
  ];
  return (
    <div>
      <Breadcrumb>
        {<Breadcrumb.Item>
          <Link to={`/bicycleManagement/bikeManagement`} >单车管理</Link>
        </Breadcrumb.Item>}
        <Breadcrumb.Item>导入记录</Breadcrumb.Item>
      </Breadcrumb>
      <div className="margin-t-m" />
      <CustomTable
        InitApi={UPLOADLOG}
        toIndex={true}
        columns={columns}
        customTitle={'导入记录'}
      />
    </div>
  );
};

ImportRecords.propTypes = {};

export default ImportRecords;
