import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Divider, Space, Form, Input, Select, Tooltip, message} from 'antd';
import {InfoCircleOutlined, InfoCircleFilled} from '@ant-design/icons';
import moment from 'moment';
import {ruleSwitchs, workStatusOptions} from '../statusCollection';
import './style.css';

const workInfo = '选择休假/保障状态时将关闭考勤规则开关，运维人员可在任何时间地点完成考勤打卡。';

const QuickSetting =({
  attendanceRuleList,
  detailsList,
  selected,
  setSelected,
  ruleSet,
  visible,
  fromValue,
}) => {
  const [form] = Form.useForm();
  const [rule, setRule] = useState(ruleSet);
  // 快捷设置部分
  const tooltip = (text) => {
    return (
      <div className="tooltip-color">
        <div>
          <InfoCircleFilled className="quick-tooltip"/>
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        <p className="tipInfo">{text}</p>
      </div>
    );
  };
  const isSelected = () => {
    const nowDate = new Date().toLocaleTimeString();
    if (
      attendanceRuleList.some((item) => {
        return (
          nowDate >= moment(moment('2020-11-12 ' + item.earliestTime).valueOf() - 1000 * 5 * 60).format('HH:mm:ss') &&
          nowDate <= item.latestTime
        );
      })
    ) {
      message.warning('考勤时段内不可修改工作状态。');
      setSelected(true);
    }
  };
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...detailsList,
      });
      fromValue(form.getFieldsValue());
    }
  }, []);
  return (
    <>
      <div className="quick-entirety">
        <div className="text-gray">运维人员快捷设置保存修改后，立即生效</div>
      </div>
      <Form
        name="settingForm"
        form={form}
        onFieldsChange={(e) => {
          setRule(e[0].value);
        }}
        initialValues={{
          jobStatus: detailsList?.jobStatus,
        }}
      >
        <Form.Item name="operationId" label="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="姓名" name="name">
          <Input bordered={false} disabled className="quick-entirety-color" />
        </Form.Item>
        <Form.Item label="联系电话" name="phoneNumber">
          <Input bordered={false} disabled className="quick-entirety-color" />
        </Form.Item>
        <Form.Item name="trackFrequency" label="轨迹上传频率">
          <Input bordered={false} disabled className="quick-entirety-color"/>
        </Form.Item>
        <Form.Item
          label={
            <Space>
              工作状态
              <Tooltip title={tooltip(workInfo)} color="white">
                <InfoCircleOutlined className="quick-entirety-icon" />
              </Tooltip>
            </Space>
          }
          name="jobStatus"
        >
          <Select
            className="quick-select"
            placeholder="请选择"
            options={workStatusOptions(false)}
            onClick={() => {
              isSelected();
            }}
            onChange={()=>{
              fromValue(form.getFieldsValue());
            }}
            disabled={selected || detailsList?.jobStatus === 3}
          />
          {/* </div> */}
        </Form.Item>
        <Form.Item label={<Space>考勤规则开关</Space>}>{ruleSwitchs(rule)}</Form.Item>
      </Form>
    </>
  );
};

QuickSetting.propTypes = {
  attendanceRuleList: PropTypes.any,
  detailsList: PropTypes.any,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  ruleSet: PropTypes.number,
  visible: PropTypes.bool,
  fromValue: PropTypes.func,
};
export default QuickSetting;
