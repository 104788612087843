import React, {} from 'react';
// import AccessEdit from './AccessEdit';
import './style.css';

import text from '../../asset/logo1.png';


const Home = () => {
  return (
  // <div>
  //   {/* <AccessEdit
  //     accessSettingFuc={(value)=>{
  //       console.log(value);
  //     }}
  //     accessJson={[{
  //       title: '前往大屏',
  //       key: 'screen',
  //       id: 'screen',
  //       children: [
  //         {
  //           title: '单车人员大屏',
  //           key: 'person',
  //           id: 'person',
  //           checkKey: [],
  //           accessKey: 'accessNo',
  //           accessNo: [],
  //           accessView: [
  //             {
  //               title: '查看轨迹',
  //               id: 'track',
  //               key: 'track',
  //               info: '查看近62日内运维人员轨迹',
  //               children: [{
  //                 title: '查看详情',
  //                 id: 'track2',
  //                 key: 'track2',
  //                 info: '查看近62日内运维人员轨迹',
  //               },
  //               ],
  //             },
  //             {
  //               title: '视频通话',
  //               id: 'trtc',
  //               key: 'trtc',
  //               info: '',
  //             },

  //           ],
  //           accessAll: [
  //             {
  //               title: '查看轨迹',
  //               id: 'track',
  //               key: 'track',
  //               info: '查看近62日内运维人员轨迹',
  //               children: [{
  //                 title: '导出轨迹',
  //                 id: 'track22e',
  //                 key: 'track22e',
  //                 info: '查看近62日内运维人员轨迹',
  //               },
  //               ],
  //             },
  //             {
  //               title: '视频通话',
  //               id: 'trtc',
  //               key: 'trtc',
  //               info: '',
  //               children: [{
  //                 title: '导出聊天',
  //                 id: 'track22',
  //                 key: 'track22',
  //                 info: '查看近62日内运维人员轨迹',
  //               },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           title: '电单车人员大屏',
  //           key: 'eperson',
  //           id: 'eperson',
  //           checkKey: [],
  //           accessKey: 'accessView',
  //           accessNo: [],
  //           accessAll: [
  //             {
  //               title: '查看轨迹',
  //               id: 'etrack',
  //               key: 'track',
  //               info: '查看近62日内e运维人员轨迹',
  //             },
  //             {
  //               title: '视频通话',
  //               id: 'etrtc',
  //               key: 'etrtc',
  //               info: '',
  //             }],
  //           //     },
  //           // ],
  //           accessView: [
  //             {
  //               title: '查看轨迹',
  //               id: 'etrack',
  //               key: 'track',
  //               info: '查看近62日内e运维人员轨迹',
  //             },
  //           ],

  //         },
  //       ],
  //     },
  //     {
  //       title: '考勤管理',
  //       key: 'Attendance',
  //       id: 'Attendance',
  //       accessKey: 'accessAll',
  //       checkKey: [],
  //       accessNo: [],
  //       accessView: [

    //         {
    //           title: '查看（考勤组）',
    //           id: 'add',
    //           key: 'add',
    //           info: '可查看该项数据，并点击进入详情页面。',
    //         },
    //       ],
    //       accessAll: [
    //         {
    //           title: '新增（考勤组）',
    //           id: 'add',
    //           key: 'add',
    //           info: '仅限创建人可操作',
    //         },
    //         {
    //           title: '编辑',
    //           id: 'edit',
    //           key: 'edit',
    //           info: '',
    //           children: [
    //             {
    //               title: '聊天',
    //               id: 'chart',
    //               key: 'chart',
    //               info: '查看聊天记录',
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     ]}/> */}
    // {/* </div> */}
    <div className="home-page">

      <img src={text} width={923} height={521} alt="text" />
    </div>
  );
};

Home.propTypes = {};

export default Home;
