import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'antd';
import {toThousands} from '../../../../utils/dataFormat';
import {filingsDataTitle} from '../../../../components/statusCollection';


const Statistics = ({statisticsData, bikeType}) => {
  const [filingsTitle, setFilingsTitle] = useState('');
  const [isAreaName, setIsAreaName] = useState('');
  const [cityLength, setCityLength]=useState('');
  useEffect(() => {
    const areaName = JSON.parse(localStorage.getItem('myInfo'))?.areaName || '无区域';
    // const areaName = '成都市成华区测试街道/德源街道';
    if (areaName !== '全部区' && areaName !== '成都市全部区') {
      if (areaName.indexOf('街道') !== -1 || areaName.indexOf('镇') !== -1) setCityLength(1);
      else {
        const cityList = areaName.slice(areaName.indexOf('市') + 1).split('/');
        setCityLength(cityList.length);
      }
    }
    setIsAreaName(areaName);
    setFilingsTitle(filingsDataTitle(areaName));
  }, []);
  // console.log(statisticsData);
  return (

    <div className="padding-l-m">
      <Row gutter={[4, 16]} >
        {cityLength !==1 && <Col span={6} className="">
          <div className="device-statistics-title">
            {/* 全市备案{bikeType === 2 && '电'}单车总数 */}
            全市备案总数
          </div>
          <div className="statistics-data">
            {toThousands(statisticsData?statisticsData.allFiledNum:'--')}
          </div>
        </Col>}
        {(isAreaName!=='全部区' && isAreaName!=='成都市全部区') &&<Col span={6} className="">
          <div className="device-statistics-title">
            {/* 区域备案{bikeType===2&&'电'}单车数 */}
            {filingsTitle}
          </div>
          <div className="statistics-data">
            {toThousands(statisticsData?statisticsData.filedNum:'--')}
          </div>
        </Col>}
        <Col span={6} className="">
          <div className="device-statistics-title">
            区域监测数
          </div>
          <div className="statistics-data">
            {toThousands(statisticsData?statisticsData.areaMonitorNum:'--')}

          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title">
            监测未备案数
          </div>
          <div className="statistics-data">
            {toThousands(statisticsData?statisticsData.monitorNotFiledNum:'--')}
          </div>
        </Col>

        <Col span={6} className="">
          <div className="device-statistics-title">
            监测跨区域数
          </div>
          <div className="statistics-data">
            {toThousands(statisticsData?statisticsData.monitorCrossAreaNum:'--')}
          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title">
            跨区域率
          </div>
          <div className="statistics-data">
            {statisticsData?statisticsData.crossRegionalPercent:'--'}
          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title">
            备案率
          </div>
          <div className="statistics-data">
            {statisticsData?statisticsData.filedPercent:'--'}
          </div>
        </Col>
      </Row>
    </div>

  );
};

Statistics.propTypes = {
  statisticsData: PropTypes.object,
  bikeType: PropTypes.number.isRequired,
};

export default Statistics;

