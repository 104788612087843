import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {uploadFile, uploadFile2, getUploadType} from '../../api';
import {
  Button, message, Modal, Upload, Spin, Badge, Statistic, Space, Skeleton, Radio, Popconfirm, Select,
  Form,
  // , Popover,
} from 'antd';
import {useTranslation} from 'react-i18next';
import './style.css';
import {connect} from 'react-redux';
// import axios from 'axios';
// import {storage} from '../../utils/storage';
import {UPLOADURL} from '../../api/Urls';
import {bikeCompanyOptions} from '../../components/statusCollection';

const MAXSIZE = 6 * 1024 * 1024 * 3000;

const UploadFile = ({uploadUrl, uploadUrl2, onFinish, updateBikeItems
  , setUpdateBikeItems, type, count}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const myInfo = JSON.parse(localStorage.getItem('myInfo'));
  const [file, setFile] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const [affirmModal, setAffirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState('文件检测中，请稍后...');
  const [result, setResult] = useState({});
  const [key, setKey] = useState(null);
  const [radio, setRadio] = useState(2);
  const [radioDis, setRadioDis] = useState({area: false, company: false});
  const [otherImport, setOtherImport] = useState();
  const [nextType, setNextType] = useState(false);
  const [uploadBikeInfo, setUploadBikeInfo] = useState('');
  const [uploadStatus, setUploadStatus] = useState(0);
  // const [open, setOpen] = useState(true);
  // const hide = () => {
  //   console.log(1);
  //   setOpen(false);
  // };
  // const handleOpenChange = (newOpen) => {
  //   console.log(newOpen);
  //   setOpen(newOpen);
  // };
  const fileProps = {
    multiple: false,
    showUploadList: false,
    accept: '.xls, .xlsx',
    customRequest: (info) => {
      if (info.file.size < MAXSIZE) {
        const formData = new FormData();
        formData.append('file', info.file);
        setFile(info.file);
        setAffirmModal(false);
        setFileModal(true);
        setLoading(true);
        setTips('文件检测中，请稍后...');
        setKey(null);
        uploadFile(uploadUrl, formData, t('flag'))
            .then((res) => {
              console.log(res);
              // setShouldModified(true);
              const arr = [];
              Object.keys(res.data).forEach((key) => {
                if (key !== '1') {
                  arr.push(key);
                }
              });
              if (uploadUrl2) {
                if (arr.length > 0) {
                  setKey(null);
                } else {
                  setKey(2);
                }
              } else {
                setKey(res.data.key);
              }
              setResult(res.data || {});
            })
            .catch((error) => {
              // setShouldModified(true);
              setResult(error.data || {});
            })
            .finally(() => {
              setLoading(false);
            });
      } else {
        message.warn(t('overSizeMsg'));
      }
    },
  };
  // const menusId = (v) => {
  //   if (v && v.menuTree) {
  //     const menuTree = v.menuTree.children;
  //     const l = window.location.pathname.split('/');
  //     if (l.length < 2) {
  //       return '';
  //     }
  //     const pID = menuTree?.find(({menuType}) => menuType === l[1]);
  //     if (pID) {
  //       if (pID.children && pID.children.length > 0) {
  //         const cID = pID.children.find(({menuType}) => menuType === l[2]);
  //         return cID ? cID.id : '';
  //       }
  //       return pID.id;
  //     }
  //   }
  //   return '';
  // };
  // const userInfo = storage.getData('userInfo');
  // const MID = userInfo ? menusId(userInfo) : null;
  // const token = userInfo ? userInfo.token : null;
  const filePropsNewBike = {
    multiple: false,
    showUploadList: false,
    accept: '.xlsx',
    customRequest: (info) => {
      const MAXSIZE = 25 * 1024 * 1024;
      if (info.file.size < MAXSIZE && info.file.name.slice(-4)==='xlsx') {
        // const formDataOss = new FormData();
        const uploadData = form.getFieldsValue();
        console.log(uploadData);
        const formData = new FormData();
        // formDataOss.append('file', info.file);
        formData.append('file', info.file);
        formData.append('bikeEnterprise', uploadData.bikeEnterprise);
        formData.append('uploadType', uploadData.uploadType);
        setFile(info.file);
        setAffirmModal(false);
        setFileModal(true);
        setLoading(true);
        setTips('文件上传中');
        setKey(null);
        // 绝对路径
        // axios(`http://upload.test.cd-jyxx.com/api/upload/importBikeInfo/${radio}`, formData, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'multipart/form-data', // multipart/form-data application/form-data
        //     'Client-Type': 'web',
        //     'Authorization': token,
        //     'menuId': 271,
        //   },
        // }).then((response) => console.log(res));
        // 代理
        // fetch(`${uploadUrl}/${radio}`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'multipart/form-data', // multipart/form-data application/form-data
        //     'Client-Type': 'web',
        //     'Authorization': token,
        //     'menuId': 271,
        //   },
        //   mode: 'cors',
        //   body: formData,
        // }).then((response) => response.json()).then((res) => {
        //   console.log(res);
        // });
        // fetch(`http://172.16.10.217:57235/AIapi/deletSource`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({fileId: 1, userId: 1}),
        // })
        //     .then((response) => response.json())
        //     .then(() => {
        //       console.log(1);
        //     })
        //     .catch(() => {});
        // 绝对路径
        // https://upload.smartecycle.cn
        // http://upload.test.cd-jyxx.com
        // const domain='http://upload.test.cd-jyxx.com';
        // const domain='https://upload.smartecycle.cn'
        // fetch(`${domain}${uploadUrl}/${radio}`, {
        //   method: 'POST',
        //   headers: {
        //     // 'Content-Type': 'multipart/form-data', // multipart/form-data application/form-data
        //     'Client-Type': 'web',
        //     'Authorization': token,
        //     'menuId': MID,
        //   },
        //   mode: 'cors',
        //   body: formData,
        // }).then((response) => response.json())
        //     .then((res) => {
        //       console.log(res);
        //       // 处理获得的数据
        //       if (res.status === true) {
        //         setUploadBikeInfo('文件上传成功，正在进行校验。校验结果将稍后通过弹窗提示您'+
        //         '，或前往【单车管理 -> 导入记录】查看导入结果，建议校验完成前，不要退出系统。');
        //       } else {
        //         setUploadBikeInfo('您有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。');
        //       }
        //     })
        //     .catch((error) => {
        //       // 处理错误
        //       setUploadBikeInfo('请重新导入');
        //     }).finally(()=>setLoading(false));
        // // // 阿里
        // fetch(`https://www.mocky.io/v2/5cc8019d300000980a055e76`,
        //     {
        //       body: formDataOss,
        //       headers: {
        //         authorization: 'authorization-text',
        //       },
        //       mode: 'cors',
        //       method: 'post',
        //     }).then((response) => response.json())
        //     .then((res) => {
        //       console.log(res);
        //       if (res.status === true) {
        //         setUploadBikeInfo('文件上传成功，正在进行校验。校验结果将稍后通过弹窗提示您'+
        //           '，或前往【单车管理 -> 导入记录】查看导入结果。校验完成前，'+
        //         '建议不要关闭页面 / 退出系统，或频繁手动刷新，可能导致无法收到校验结果弹窗。');
        //       } else {
        //         setUploadBikeInfo('您有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。');
        //       }
        //     })
        //     .catch((error) => {
        //       setResult(error.data || {});
        //       setUploadBikeInfo('请重新导入');
        //     })
        //     .finally(() => {
        //       setLoading(false);
        //     });
        uploadFile(`${UPLOADURL}${uploadUrl}`, formData, t('flag'))
            .then((res) => {
              if (res.status === true) {
                setUploadBikeInfo('文件上传成功，正在进行校验。校验结果将稍后通过弹窗提示你'+
                  '，或前往【单车管理 -> 导入记录】查看导入结果。校验完成前，'+
                '建议不要关闭页面 / 退出系统，或频繁手动刷新，可能导致无法收到校验结果弹窗。');
              } else if (res.status === false) {
                // '您有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。'
                setUploadBikeInfo(res.msg || '你有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。');
              }
            })
            .catch((error) => {
              setResult(error.data || {});
              setUploadBikeInfo('请重新导入');
            })
            .finally(() => {
              setLoading(false);
            });
        // uploadFile(`${uploadUrl}/${radio}`, formData, t('flag'))
        //     .then((res) => {
        //       console.log(res);
        //       if (res.status === true) {
        //         setUploadBikeInfo('文件上传成功，正在进行校验。校验结果将稍后通过弹窗提示您'+
        //           '，或前往【单车管理 -> 导入记录】查看导入结果。校验完成前，'+
        //         '建议不要关闭页面 / 退出系统，或频繁手动刷新，可能导致无法收到校验结果弹窗。');
        //       } else {
        //         setUploadBikeInfo('您有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。');
        //       }
        //     })
        //     .catch((error) => {
        //       setResult(error.data || {});
        //       setUploadBikeInfo('请重新导入');
        //     })
        //     .finally(() => {
        //       setLoading(false);
        //     });
      } else {
        // message.warn(t('overSizeMsg'));
        setAffirmModal(false);
        setFileModal(true);
        setLoading(true);
        setTips('文件上传中');
        setKey(null);
        setUploadBikeInfo('文件上传失败。批量导入文件应为平台指定模版，文件格式为xlsx，文件大小在25M以内。');
        setLoading(false);
      }
    },
  };
  const handleOk = () => {
    const formData = new FormData();
    formData.append('file', file);
    setTips('Excel文件正在上传...');
    setLoading(true);
    if (uploadUrl2) {
      uploadFile2(`${uploadUrl2}/${radio}`)
          .then((res) => {
            message.success(res.msg);
            setFileModal(false);
            onFinish();
            setUpdateBikeItems(updateBikeItems + 1);
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
          // console.log(err);
            setOtherImport(err); // 其他同时导入
          });
    } else {
      formData.append('key', key);
      uploadFile(uploadUrl, formData, t('flag'))
          .then((res) => {
            message.success(res.msg);
            setFileModal(false);
            onFinish();
            setUpdateBikeItems(updateBikeItems + 1);
          })
          .finally(() => {
            setLoading(false);
          });
    }
  };

  const handleCancel = () => {
    setFileModal(false);
    setOtherImport();
    setFile(null);
    setUploadBikeInfo('');
  };
  const handleAffirmCancel = () => {
    form.resetFields();
    setAffirmModal(false);
  };
  const isUpload = () => {
    form.validateFields().then((res) => {
      const element = document.querySelector('.ant-upload').childNodes[0].childNodes[0];
      if (element) {
        element.click();
      }
    }).catch((err)=>console.log(err));
  };
  const showUploadStatus = () => {
    setAffirmModal(true);
    switch (uploadStatus) {
      case 0:
        setRadioDis({area: false, company: false});
        setNextType(false);
        form.setFieldsValue({uploadType: 2});
        break;
      case 1:
        setRadioDis({area: true, company: false});
        setRadio(1);
        setNextType(true);
        form.setFieldsValue({uploadType: 1});
        break;
      case 2:
        setRadioDis({area: false, company: true});
        setRadio(2);
        setNextType(true);
        form.setFieldsValue({uploadType: 2});
        break;
      default:
        return;
    }
    switch (parseInt(myInfo.bikeEnterprise[0])) {
      case 3:
        form.setFieldsValue({bikeEnterprise: 3});
        break;
      case 1:
        form.setFieldsValue({bikeEnterprise: 1});
        break;
      case 2:
        form.setFieldsValue({bikeEnterprise: 2});
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    getUploadType().then((res) => {
      setUploadStatus(parseInt(res.data?.status));
      // switch (parseInt(res.data?.status)) {
      //   case 0:
      //     setRadioDis({area: false, company: false});
      //     setNextType(false);
      //     form.setFieldsValue({uploadType: 2});
      //     break;
      //   case 1:
      //     setRadioDis({area: true, company: false});
      //     setRadio(1);
      //     setNextType(true);
      //     form.setFieldsValue({uploadType: 1});
      //     break;
      //   case 2:
      //     setRadioDis({area: false, company: true});
      //     setRadio(2);
      //     setNextType(true);
      //     form.setFieldsValue({uploadType: 2});
      //     break;
      //   default:
      //     return;
      // }
    });
  }, [count]);
  return (
    <>
      {type === 'car' ? (
        <Button type="primary" onClick={() => showUploadStatus()}>
          批量导入
        </Button>
      ) : (
        <Upload {...fileProps}>
          <Button type="primary">
            批量导入
          </Button>
        </Upload>
      )}

      {/* 确认导入内容modal->车辆管理 */}
      {type === 'car' && (
        <Modal
          title="批量导入"
          visible={affirmModal}
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="cancel" onClick={handleAffirmCancel} className="margin-r-s">
              取消
            </Button>,
            nextType ? <><Button type="primary" onClick={() => isUpload()}>下一步</Button>
              <Upload {...filePropsNewBike}>
                <Button style={{display: 'none'}} />
              </Upload></>:
            // <Popover
            //   key="next"
            //   overlayInnerStyle={{textAlign: 'right'}}
            //   content={<Space>
            //     <Button size="small" onClick={() => hide()}>取消</Button>
            //     {/* <Upload {...fileProps}> */}
            //     <Button type="primary" size="small" onClick={() => {
            //       const element = document.querySelector(
            //           '.ant-upload',
            //       ).childNodes[0].childNodes[0];
            //       if (element) {
            //         element.click();
            //       }
            //     }}>确认</Button>
            //     {/* </Upload> */}
            //     <Upload {...fileProps}>
            //       <Button
            //         style={{display: 'none'}}
            //       ></Button>
            //     </Upload>
            //   </Space>}
            //   title={`是否确认选择${radio===2?'“按区域导入”':'“按车企导入”'}？`}
            //   trigger="click"
            //   open={open}
            //   onOpenChange={handleOpenChange}
            // >
            //   <Button type="primary">下一步</Button>
            // </Popover>,
            <Popconfirm
              key="next"
              title={`是否确认选择${radio===2?'“按区域导入”':'“按车企导入”'}？`}
              trigger="click"
              onConfirm={() => isUpload()}
            >
              <Button type="primary">下一步</Button>
              <Upload {...filePropsNewBike}>
                <Button style={{display: 'none'}} />
              </Upload>
            </Popconfirm>,
          ]}
          onCancel={handleAffirmCancel}
        >
          <p className="import-title">请选择导入方式：</p>
          <p className="import-title import-explain">按区域导入和按车企导入，当日选择其中一种方式导入成功后，另一种方式不可选择。</p>
          <p className="import-title">导入车企</p>
          <Form form={form}>
            <Form.Item rules={[
              {
                required: true,
                message: '此项不可为空。',
              },
            ]}
            name="bikeEnterprise"
            >
              <Select
                options={bikeCompanyOptions('bike')}
                style={{width: '40%'}}
                placeholder="请选择车企(单选)"
                disabled={myInfo.roleName !=='超级管理' && myInfo.roleName !=='超级管理员'}
              />
            </Form.Item>
            <Form.Item style={{marginBottom: 0}} name="uploadType">
              <Radio.Group
                // defaultValue={radio}
                onChange={(e) => {
                  setRadio(e.target.value);
                }}
              >
                <Radio value={2} disabled={radioDis.area}>
              按区域导入：导入单个车企，部分区域的车辆
                  <p className="import-explain">
                说明：批量导入用户上传的导入文件中的车辆信息，并删除系统中以前导入的，同车企下所属区域相同的但不存在于本次导入文件的其他车辆信息。每日各车企的每个所属区域的车辆最多可导入10次（含导入失败）。
                  </p>
                </Radio>
                <Radio value={1} disabled={radioDis.company}>
              按车企导入：导入单个车企，全部区域的车辆
                  <p className="import-explain">
                说明：批量导入用户上传的导入文件中的车辆信息，并删除系统中以前导入的，同车企下但不存在于本次导入文件中的，该车企的全部车辆信息。每日各车企车辆最多可导入10次（含导入失败）。
                  </p>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Modal
        title="批量导入"
        visible={fileModal}
        closable={false}
        maskClosable={false}
        destroyOnClose
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{maxHeight: 505, minHeight: 120, overflowY: 'auto', overflowX: 'hidden'}}
      >
        <Spin spinning={loading} size="large" tip={tips}>
          <div className="padding-l-m">
            {!loading ? (
              !otherImport ? (
                (type === 'car' ? <div>
                  {/* <p>您有进行中的车辆信息导入任务，请当前导入任务结束后，再操作导入。</p> */}
                  {/* <p>文件上传成功，正在进行校验。校验结果将稍后通过弹窗提示您，或前往【单车管理-&gt;
                    导入记录】查看导入结果，建议校验完成前，不要退出系统。</p> */}
                  <p>{uploadBikeInfo}</p>
                  <div className="text-right">
                    <Space>
                      <Button onClick={handleCancel} type="primary">
                          确认
                      </Button>
                    </Space>
                  </div>
                </div> : <>
                  <h3>Excel文件检测完成，检测到：</h3>
                  {(result['1'] === 0 || result['1']) && (
                    <div>
                      <Badge
                        className="margin-b-s"
                        status="default"
                        text={
                          <Statistic
                            prefix={<span>正确信息:</span>}
                            value={result['1'] || 0}
                            valueStyle={{fontSize: 14}}
                            suffix={
                              <span>
                                条
                                {((result['2'] && result['2'].msg) || (result['3'] && result['3'].msg)) && (
                                  <span className="text-light">（包含重复）</span>
                                )}
                              </span>
                            }
                          />
                        }
                      />
                    </div>
                  )}
                  {type==='staff'&&result['2'] && result['2'].count !== 0 && !key && (
                    <div>
                      <Badge
                        className="margin-b-s"
                        status="default"
                        text={
                          <Statistic
                            prefix={<span>正确信息中包含，数据库重复信息</span>}
                            value={result['2'].count}
                            valueStyle={{fontSize: 14}}
                            suffix={` 条`}
                          />
                        }
                      />
                      <p className="text-light padding-l-m">{result['2'].msg}</p>
                    </div>
                  )}
                  {result['3'] && result['3'].count !== 0 && !key && (
                    <div>
                      <Badge
                        className="margin-b-s"
                        status="default"
                        text={
                          <Statistic
                            prefix={<span>正确信息中包含，表内重复信息:</span>}
                            value={result['3'].count}
                            valueStyle={{fontSize: 14}}
                            suffix={` 条`}
                          />
                        }
                      />
                      <p className="text-light padding-l-m upload-file">{result['3'].msg.replace(/\s*$/g, ' ')}</p>
                    </div>
                  )}
                  {result['4'] && result['4'].count !== 0 && !key && (
                    <div>
                      <Badge
                        className="margin-b-s"
                        status="default"
                        text={
                          <Statistic
                            prefix={<span>必填项不完整信息:</span>}
                            value={result['4'].count}
                            valueStyle={{fontSize: 14}}
                            suffix={` 条`}
                          />
                        }
                      />
                      <p className="text-light padding-l-m">{result['4'].msg}</p>
                    </div>
                  )}
                  {result['5'] && result['5'].count !== 0 && !key && (
                    <div>
                      <Badge
                        className="margin-b-s"
                        status="default"
                        text={
                          <Statistic
                            prefix={<span>错误的信息:</span>}
                            value={result['5'].count}
                            valueStyle={{fontSize: 14}}
                            suffix={` 条`}
                          />
                        }
                      />
                      <p className="text-light padding-l-m">{result['5'].msg}</p>
                    </div>
                  )}
                  {result['0'] === '' && type === 'car' && (
                    <div>导入列表中只应包含一家车企的车辆数据，请修正后再导入。</div>
                  )}

                  {key ? (
                    <p>点击确认导入全部信息。</p>
                  ) : result['0'] === '' ? (
                    ''
                  ) : (
                    <p className="text-red">存在错误，重复或者必填项的不完整的信息，请修正后再导入。</p>
                  )}
                  {key ? (
                    <div className="text-right">
                      <Space>
                        <Button onClick={handleCancel}>取消</Button>
                        <Button onClick={handleOk} type="primary">
                          确认导入
                        </Button>
                      </Space>
                    </div>
                  ) : (
                    <div className="text-right">
                      <Space>
                        <Button onClick={handleCancel} type="primary">
                          确认
                        </Button>
                      </Space>
                    </div>
                  )}
                </>)
              ) : (
                type === 'car' && (
                  <div>
                    其他用户正在导入相同车企的车辆信息，为避免发生错误，本次导入已取消。请稍后重新导入。
                    <div className="text-right">
                      <Space>
                        <Button onClick={handleCancel} type="primary">
                          确认
                        </Button>
                      </Space>
                    </div>
                  </div>
                )
              )
            ) : (
              <Skeleton />
            )}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

UploadFile.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  uploadUrl2: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  updateBikeItems: PropTypes.number,
  setUpdateBikeItems: PropTypes.func,
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    count: state.importType.count,
  };
};
export default connect(
    mapStateToProps,
)(UploadFile);
