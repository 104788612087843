import React, {useEffect, useState} from 'react';
import {getBikeInfo} from '../../api';
import PropTypes from 'prop-types';
import {Col, Row, Skeleton} from 'antd';
import {bikeCompany, bikeType} from '../statusCollection';

const BicycleInfo = ({id, tabsKey}) => {
  const [baseInfo, setBaseInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const getInitData = () => {
    setLoading(true);
    getBikeInfo(id, 2).then((res) => {
      setBaseInfo(res.data);
    }).finally(() => {
      setLoading(false);
    });
  };
  useEffect(()=>{
    getInitData();
  }, []);
  return (
    <div id="bicycle-info">
      {loading?<Skeleton/>:(<div className="details-info margin-t-l">
        <Row className="margin-b-l">
          <Col span={7}>
            <span className="padding-r-m">所属车企：</span>
            <span className="text-gray">{bikeCompany(baseInfo.bikeType)}</span>
          </Col>
          <Col span={7}>
            <span className="padding-r-m">车辆类型：</span>
            <span className="text-gray">{bikeType(baseInfo.type)}</span>
          </Col>
          <Col span={7}>
            <span className="padding-r-m">车锁号：</span>
            <span className="text-gray"> {baseInfo.lockNumber || '暂无数据'}</span>
          </Col>
        </Row>
        <Row className="margin-b-l">
          <Col span={7}>
            <span className="padding-r-m">车架号：</span>
            <span className="text-gray"> {baseInfo.frameNumber|| '暂无数据'}</span>
          </Col>
          <Col span={7}>
            <span className="padding-r-m">实体车牌号：</span>
            <span className="text-gray">{baseInfo.licensePlate|| '暂无数据'}</span>
          </Col>
          <Col span={10}>
            <span className="padding-r-m">蓝牙Mac地址：</span>
            <span className="text-gray"> {baseInfo.bluetoothMacAddress || ('暂无数据')}</span>
          </Col>
        </Row>
      </div>)}
    </div>
  );
};

BicycleInfo.propTypes = {
  id: PropTypes.number.isRequired,
  tabsKey: PropTypes.str,
};

export default BicycleInfo;
