import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Col, Row, Spin, Button} from 'antd';

import {connect} from 'react-redux';
import {side} from '../../../store/actions';

import {
  // getBikeScreenDevice,
  getBikeScreenLineChart,
  getBikeScreenMap,
  getBikeScreenRadar,
  getBikeScreenStatistics, minutesPieChart,
} from '../../../api';

import './style.css';

import Statistics from './components/StatisticsData';
import titleIcon from './../../../asset/title-icon.png';
import ScreenMap from './components/ScreenMap';
import RadarChart from '../../../components/echarts/RadarChart';
import PointsLineChart from '../../../components/echarts/PointsLineChart';
import PieChart from '../../../components/echarts/PieChart';
import {isMockData, looseEqual} from '../../../utils/judge';
import {bikeMockData} from '../mockData';
import {bikeDataTitle} from '../../../components/statusCollection';
import {mockBikeScreenMapRadar, mockBikeScreenStatistics} from './demoData/demoData';

const refreshTime = 6 * 60 * 1000;
const isMock = false;

let bigMap = 0;
const BikeScreen = ({collapsed, setCollapsedMenus, bikeType}) => {
  const totalTimer = useRef();
  const [mapData, setMapData] = useState([]);
  const [deviceLineData, setDeviceLineData] = useState([]);
  const [devicePieData, setDevicePieData] = useState(null);
  const [deviceName, setDeviceName] = useState('暂无');
  const [statisticsData, setStatisticsData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [radarChartData, setRadarChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [lineAverage, setLineAverage] = useState(1);
  const [lineMin, setLineMin] = useState(0);
  // const [IMEI, setDeviceID] = useState('');

  const [screenCode, setScreenCode] = useState(0);
  const [fold, setFold] = useState(false);
  const [dataTitle, setDataTitle] = useState('');


  const mapTitle = 'big-screen-header text-right bike-full-header';
  // 全屏初始化
  const onFoldInit = () => {
    setCollapsedMenus(false);
    bigMap = 0;
    setScreenCode(0);
    setFold(false);
  };

  // 全屏功能
  const onFoldCharts = () => {
    setFold(!fold);
    if (bigMap === 0) {
      setCollapsedMenus(true);
      setScreenCode(3);
      bigMap = 3;
    } else if (bigMap === 1) {
      bigMap = 2;
      setScreenCode(2);
    } else if (bigMap === 2) {
      bigMap = 1;
      setScreenCode(1);
    } else if (bigMap === 3) {
      setCollapsedMenus(false);
      bigMap = 0;
      setScreenCode(0);
    }
  };


  const LineChartRender = useMemo(() =>
    (<PointsLineChart
      themeColor="#12A6FA"
      title={bikeType === 1 ? '移动单车数' : '移动电单车数'}
      chartData={lineChartData}
      log={true}
      average={lineAverage}
      min= {lineMin}
      sliderStart={0.9}
    />), [lineChartData, lineAverage, lineMin]);
  const RadarChartRender = useMemo(() => (
    <RadarChart chartData={radarChartData} />
  ), [radarChartData]);

  const DeviceLineChartRender = useMemo(() =>
    (<PointsLineChart
      themeColor="#EB2F96"
      title={bikeType === 1 ? '监测单车数' : '监测电单车数'}
      chartData={deviceLineData}
      sliderStart={0}
    />), [deviceLineData]);

  const DevicePieChartRender = useMemo(() =>
    devicePieData ? (<PieChart data={devicePieData} isCustomMode />) : null, [devicePieData]);


  const onMakerClick = (value) => {
    console.log(value);
    // if (value) {
    getDeviceData(value.imei, value.count, value);
    setDeviceName(value?.equipmentName || '');
    // } else {
    //   setChartLoading(false);
    // }
  };

  const getDeviceData = (imei, count, value) => {
    setChartLoading(true);
    // 饼图接口
    minutesPieChart(
        {
          bikeType: 1,
          mobileEquipmentIdentity: imei,
          endTimeInTwoMinutes: value.endTimeInTwoMinutes,
        },
    ).then((res) => {
      // console.log(res);
      setDeviceLineData(res.data.lineChart || []);
      setDevicePieData(res.data.pieChart || []);
    }).finally(() => {
      setChartLoading(false);
    });
    // getBikeScreenDevice(bikeType, imei).then((res) => {
    //   if (!looseEqual(devicePieData, res.data?.pieChart)) {
    //     if (res.data?.lineChart?.length>0) {
    //       const isUseNewValue=res.data.lineChart[res.data.lineChart.length-1].count===count;
    //       // console.log(isUseNewValue);
    //       if (isUseNewValue) {
    //         setDeviceLineData(res.data.lineChart);
    //         setDevicePieData(res.data.pieChart);
    //       } else {
    //         setDeviceLineData(res.data.lineChart.slice(0, -1));
    //         setDevicePieData(res.data.oldPieChart);
    //       }
    //     } else {
    //       setDeviceLineData([]);
    //       setDevicePieData([]);
    //     }
    //   }
    // }).finally(()=>{
    //   setChartLoading(false);
    // });
  };

  const getStatisticsData = () => {
    getBikeScreenStatistics(bikeType).then((res) => {
      const data = mockBikeScreenStatistics(res.data, '方案二');
      setStatisticsData(data);
    });
  };

  const getLineChartData = () => {
    getBikeScreenLineChart(bikeType).then((res) => {
      const data=res.data || [];
      if (!looseEqual(lineChartData, data)) {
        // setLineChartData(res.data || []);
        setLineChartData(data);
        setLineMin(data.reduce((min, item) => item.count < min ? item.count : min, data[0]?.count));
        setLineAverage(data.reduce((total, item) => total + item.count, 0) / data.length);
      }
    });
  };
  const getRadarChartData = () => {
    getBikeScreenRadar(bikeType).then((res) => {
      const data=mockBikeScreenMapRadar(res.data, '方案二');
      if (!looseEqual(radarChartData, data)) {
        setRadarChartData(data || []);
      }
    });
  };

  // 地图筛选
  const onSearch = (values, runTimer) => {
    const d = values || {};

    getBikeScreenMap(bikeType, d).then((res) => {
      // const data = res.data || [];
      // console.log(res.data, '地图');
      const data = isMock ? bikeMockData() : res.data;
      // const data=mockData;

      setMapData(data);
      // setMapData(data);

      if (data.length === 0) {
        setDeviceLineData([]);
        setDeviceName('暂无');
        setDevicePieData([]);
        setChartLoading(false);
      }
    }).catch(() => {
      setDeviceLineData([]);
      setDeviceName('暂无');
      setDevicePieData([]);
      setChartLoading(false);
    });

    // 点击筛选时
    if (!runTimer) {
      // 初始化定时器
      initTimer(values);
    }
  };

  // 线程初始化
  const initTimer = (filters) => {
    clearInterval(totalTimer.current);
    getStatisticsData();
    getRadarChartData();
    getLineChartData();
    if (!filters) {
      onSearch(null, true);
    }
    totalTimer.current = setInterval(() => {
      getStatisticsData();
      getRadarChartData();
      getLineChartData();
      onSearch(filters, true);
    }, refreshTime);
  };

  // 大屏初始化
  const initMap = () => {
    // 初始化线程
    initTimer(null);
  };


  useEffect(() => {
    localStorage.setItem('change', true);
    initMap();
    return () => {
      clearInterval(totalTimer.current);
    };
  }, [bikeType]);


  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        // bigMap 0 - All show
        // bigMap 1 - Only click menu header disappear button
        // bigMap 2 - All click
        // bigMap 3 - Only click charts disappear button
        switch (bigMap) {
          case 1: {
            setCollapsedMenus(false);
            bigMap = 0;
            setScreenCode(0);
            return null;
          }
          case 2: {
            setFold(false);
            bigMap = 1;
            setScreenCode(1);
            return null;
          }
          case 3: {
            setFold(false);
            setCollapsedMenus(false);
            bigMap = 0;
            setScreenCode(0);
            return null;
          }
          default: {
            return null;
          }
        }
      }
    });
    return onFoldInit;
  }, []);
  useEffect(() => {
    const areaName = JSON.parse(localStorage.getItem('myInfo'))?.areaName || '无区域';
    setDataTitle(bikeDataTitle(areaName));
  }, []);
  return (
    <div className={screenCode === 1 ? 'middle-screen' : screenCode === 0 ? 'min-screen' : 'big-screen'}>
      {collapsed && <div className={!fold ? mapTitle : 'full ' + mapTitle}>
        {!fold && <Button type="link"
          className="big-screen-btn"
          onClick={() => {
            if (bigMap === 0) {
              bigMap = 1;
              setScreenCode(1);
              setCollapsedMenus(true);
            } else if (bigMap === 1) {
              bigMap = 0;
              setScreenCode(0);
              setCollapsedMenus(false);
            }
          }}>退出页面全屏</Button>}
      </div>}
      <Row wrap={false} gutter={fold ? [] : [8, 8]}>
        <Col flex={fold ? '0px' : '460px'}>
          <Row className={fold ? 'device-big-map-hide' : ''}>
            <Col span={24} className="device-border device-statistics">
              <div className="card-title">
                <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
                {/* 统计数据（辆） */}
                {dataTitle}
              </div>
              <Statistics
                bikeType={bikeType}
                statisticsData={statisticsData}
              />
            </Col>
            <Col span={24} className="device-border margin-t-s ">
              <div className="card-title">
                <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
                车企已备案{bikeType === 2 && '电'}单车数{isMockData()?`（主城区）`:''}
              </div>
              <div className="device-radar-chart">
                {RadarChartRender}
              </div>
            </Col>
            <Col span={24} className="device-border margin-t-s ">
              <div className="card-title">
                <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
                近10小时移动{bikeType === 2 && '电'}单车数趋势
              </div>
              <div className="device-footer-charts">
                {LineChartRender}
              </div>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">

          <div span={24} className={fold ? 'device-border-full' : 'device-border'}>
            {!fold && <div className="map-header">
              <Row>
                <Col span={12}>
                  <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
                  设备位置分布
                </Col>
                <Col span={12} className="text-right">
                  {!fold && !collapsed && <Button type="link" onClick={() => {
                    if (bigMap === 0) {
                      bigMap = 1;
                      setScreenCode(1);
                      setCollapsedMenus(true);
                    } else if (bigMap === 1) {
                      bigMap = 0;
                      setScreenCode(0);
                      setCollapsedMenus(false);
                    }
                  }}>页面全屏</Button>}
                </Col>
              </Row>

            </div>}
            <ScreenMap
              onFilterFunc={onSearch}
              initTimer={initTimer}
              fold={fold}
              mapData={mapData}
              bikeType={bikeType}
              onMakerClick={onMakerClick}
              bigMap={bigMap}
              onFoldCharts={onFoldCharts}
            />
          </div>
          <div className={fold ? 'device-big-map-hide' : 'device-border margin-t-s '}>
            <div className="card-title">
              <img src={titleIcon} alt="title" className="margin-r-s margin-l-s" />
              【{deviceName || '暂无设备'}】实时监测{bikeType === 2 && '电'}单车数占比与趋势
            </div>
            <Spin spinning={chartLoading} wrapperClassName="map-loading">
              <Row wrap={false}>
                <Col flex="360px" className="device-footer-charts">
                  {DevicePieChartRender}
                </Col>
                <Col flex="auto" className="device-footer-charts">
                  {DeviceLineChartRender}
                </Col>
              </Row>
            </Spin>
          </div>
        </Col>
      </Row>
      <div>

      </div>
    </div>


  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.collapsedMenus.collapsed,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setCollapsedMenus: (f) => dispatch(side(f)),

  };
};
BikeScreen.propTypes = {
  collapsed: PropTypes.bool,
  bikeType: PropTypes.number.isRequired,
  setCollapsedMenus: PropTypes.func.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BikeScreen);
