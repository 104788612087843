import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import store from './store';
import {registerMicroApps, start} from 'qiankun';
import apps from './apps';

const render=()=>{
  const container = document.getElementById('main-root');
  ReactDOM.render(
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>,
      container,
  );
};
render();

const microApps = apps.map(((app) => ({
  ...app,
})));

registerMicroApps(microApps, {
  beforeLoad: (app) => {
    console.log('before load app.name=====>>>>>', app.name);
  },
  beforeMount: [
    (app) => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
    },
  ],
  afterMount: [
    (app) => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
    },
  ],
  afterUnmount: [
    (app) => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
    },
  ],
});

// setDefaultMountApp('/react');

start();
