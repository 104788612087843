import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, Spin} from 'antd';
import Target from './Target';
import {getSummaryStatistics, getUserInfo} from '../../../../../api';
import {bikeCompany} from '../../../../../components/statusCollection';
import PieCharts from './PieCharts';
import {exportWord} from 'mhtml-to-word';
import {getModelHtml, getStyle} from './Export';
import ExportPieBar from './ExportPieBar';
// import dateDemo from './date.json';// 数据模拟
import noData from '../../../../../asset/noData.png';

const ExportDocument = ({modalType, vehicleType, reportType, dailyTime, bikeType, isExportOpen}) => {
  const statisticsType = reportType === '日' ? 1 : reportType === '周' ? 2 : 3;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [grade, setGrade] = useState();
  const [rankingData, setRankingData] = useState();
  const [monitorData, setMonitorData] = useState();
  const [total, setTotal] = useState();

  const [staffArrivalPieData, setStaffArrivalPieData] = useState();
  const [staffArrivalBarData, setStaffArrivalBarData] = useState();
  const [carArrivalPieData, setCarArrivalPieData] = useState();
  const [carArrivalBarData, setCarArrivalBarData] = useState();
  const [carNotRecordlPieData, setCarNotRecordlPieData] = useState();
  const [carNotRecordlBarData, setCarNotRecordlBarData] = useState();
  const [staffOnLinePieData, setStaffOnLinePieData] = useState();
  const [staffOnLineBarData, setStaffOnLineBarData] = useState();
  const [carOnLinePieData, setCarOnLinePieData] = useState();
  const [carOnLineBarData, setCarOnLineBarData] = useState();
  const [crossPieData, setCrossPieData] = useState();
  const [crossBarData, setCrossBarData] = useState();
  const [reportPieData, setReportPieData]= useState();
  const [reportBarData, setReportBarData]= useState();
  const [basicMes, setBasicMes] = useState();
  const [loading, setLoading] = useState(true);
  const [dataNo, setDataNo] = useState(false);

  const useInfo = () => {
    getUserInfo(2).then((res) => {
      if (Reflect.ownKeys(res.data).length > 0) {
        setBasicMes(res.data);
      }
    });
  };

  // 汇总的数据
  const arrvalDataGet = (key) => {
    const data = {
      bikeType: bikeType,
      statisticsType: key,
      statisticsDate: dailyTime,
    };
    if (key !== 1) delete data.statisticsDate;
    getSummaryStatistics(data, 2)
        .then((resp) => {
        // console.log(resp.data);
        // 0-监测数；1-出勤率(运维人员)；2-出勤率(调运车辆)；3-在线率(运维人员)；4-在线率(调运车辆)；6-未备案；7-跨区域
          const demoDate = resp.data.statisticsCompanyVOList;
          setRankingData(resp.data);
          setGrade([...resp.data.vehicleTotalScoreVOList]);
          // 2.区域监测数
          setMonitorData([...pieDataSet(demoDate, 0, 'pie')]);
          // 3.人员出勤数
          setStaffArrivalPieData([...pieDataSet(demoDate, 1, 'pie')]);
          setStaffArrivalBarData([...pieDataSet(demoDate, 1, 'bar')]);
          // 4:车辆出勤数
          setCarArrivalPieData([...pieDataSet(demoDate, 2, 'pie')]);
          setCarArrivalBarData([...pieDataSet(demoDate, 2, 'bar')]);
          // 5.人员在线数据
          setStaffOnLinePieData([...pieDataSet(demoDate, 3, 'pie')]);
          setStaffOnLineBarData([...pieDataSet(demoDate, 3, 'bar')]);
          // 6.车辆在线数据
          setCarOnLinePieData([...pieDataSet(demoDate, 4, 'pie')]);
          setCarOnLineBarData([...pieDataSet(demoDate, 4, 'bar')]);
          // 7:车辆未备案数
          setCarNotRecordlPieData([...pieDataSet(demoDate, 6, 'pie')]);
          setCarNotRecordlBarData([...pieDataSet(demoDate, 6, 'bar')]);
          // 8. 跨区域
          setCrossPieData([...pieDataSet(demoDate, 7, 'pie')]);
          setCrossBarData([...pieDataSet(demoDate, 7, 'bar')]);
          // 8. 告警数据
          setReportPieData([...pieDataSet(demoDate, 8, 'pie')]);
          setReportBarData([...pieDataSet(demoDate, 8, 'bar')]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((e) => {
          console.log('错误', e);
          setTimeout(() => {
            setLoading(false);
            setDataNo(true);
          }, 1500);
        });
  };
  // 饼图/柱状图数据设置
  const pieDataSet = (data, target, way) => {
    const monitorData = data.find((item) => item.target === target);
    // console.log(monitorData);
    const monitorPieData = [];
    // 总数
    const result = {};
    data.forEach((element) => {
      result[element.target] = element.total;
    });
    setTotal({...result});
    // 排序
    monitorData?.bikeEnterpriseList.sort((a, b) => a.bikeEnterprise - b.bikeEnterprise);
    monitorData?.bikeEnterpriseList.forEach((item) => {
      monitorPieData.push({
        type: item.bikeEnterprise === 1 ? '美团' : item.bikeEnterprise === 2 ? '青桔' : '哈啰',
        value: item.statisticsCount,
        persent: way === 'pie' ? item.bikeEnterpriseProp : item.bikeEnterpriseRate,
        persentValue:
          way === 'pie' ?
            Number(item.bikeEnterpriseProp.toString()?.split('%')[0]) :
            Number(item.bikeEnterpriseRate.toString()?.split('%')[0]),
      });
    });
    // console.log(monitorPieData);
    return monitorPieData;
  };

  const resetState = () => {
    setGrade('');
    setRankingData('');
    setMonitorData('');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    isExportOpen();
    resetState();
  };
  const handleOk = () => {
    // 导出word
    const documents = document.getElementById('mhtml');
    exportWord({
      mhtml: getModelHtml(documents, getStyle()),
      // mhtml: documents,
      filename: `单车${reportType}报`,
      selector: '#mhtml',
      type: 'Content-Type: text/html; charset="utf-8" ',
      style: getStyle(),
    });
    handleCancel();
  };

  const PieChartsData = useMemo(
      () => <PieCharts data={monitorData} isExportOpen={true} id={'monitor'} />,
      [monitorData],
  );

  useEffect(() => {
    if (reportType && modalType === 'export') {
      arrvalDataGet(statisticsType);
      useInfo();
    }
  }, [reportType]);

  useEffect(() => {
    if (modalType === 'export') {
      setIsModalOpen(true);
    }
  }, [modalType]);

  return (
    <Modal
      className="weight-content"
      title={`${vehicleType}${reportType}报导出预览`}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={'确认导出'}
      width={950}
      height={500}
    >
      <Spin
        spinning={loading}
        className="spin-loading-export"
        size="large"
        tip={
          <div className="tip-loading">
            <p className="spin-tip">该页面数据量较大，</p>
            <p className="spin-tip">请稍作等待</p>
          </div>
        }
      >
        <div id="mhtml" >
          {!dataNo && (
            <div>
              <div>
                <p className="export-title">
                  {reportType === '日' && dailyTime} 单车{reportType}报
                </p>
                {reportType !== '日' && rankingData && (
                  <p className="secondary-heading-two">
                    此{reportType}报统计时间为{rankingData?.statisticsDate?.split(';')[0]}至
                    {rankingData?.statisticsDate?.split(';')[1]}
                  </p>
                )}
                <p className="secondary-heading-three">
                  {basicMes?.userName} ｜ {basicMes?.areaName}
                </p>
              </div>
              {/* 1. 车企指标考评 */}
              {rankingData && (
                <div>
                  <p className="export-heading">1.车企指标考评</p>
                  {grade &&
                    grade.map((item, index) => {
                      return (
                        <span key={item.bikeEnterprise} className="secondary-heading">
                          {bikeCompany(item.bikeEnterprise)}：{Number(item.totalScore).toFixed(4)}分
                          {index !== grade?.length - 1 && ' ｜'}
                        </span>
                      );
                    })}
                  <div className="margin-t-m">
                    {rankingData && <Target exportTable={true} rankingData={rankingData} vehicleType={vehicleType} />}
                  </div>
                </div>
              )}
              {/* 2.区域监测单车数据 */}
              {statisticsType === 1 && monitorData && (
                <div className="area-monitor">
                  <p className="export-heading">2. 区域监测单车数据</p>
                  <p>区域监测单车总数：{total && total[0]}辆。</p>
                  <p>
                    其中，
                    {monitorData &&
                      monitorData.map((item) => {
                        return (
                          <span key={item.type}>
                            {item.type}
                            {vehicleType}：{item.value}辆，占监测{vehicleType}总数的{item.persent}；
                          </span>
                        );
                      })}
                  </p>
                  <p className="gray-color">图表（1）车企区域监测单车数占比</p>
                  {monitorData && <div className="chart-width">{PieChartsData}</div>}
                  <div className="export-described">
                    <p className="gray-color">
                      指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到单车数量。
                    </p>
                    <p className="gray-color">
                      图表说明（1）：A车企区域监测单车数占比=（ 监测到的A车企单车数/监测到的全部单车数）*100%
                    </p>
                  </div>
                </div>
              )}
              {/* 3. 单车秩序人员出勤数据 */}
              {staffArrivalPieData && staffArrivalBarData && (
                <ExportPieBar
                  pieData={staffArrivalPieData}
                  barData={staffArrivalBarData}
                  vehicleType={vehicleType}
                  pieId="staff-attendance"
                  barId="staff-attendance-bar"
                  statisticsType={statisticsType}
                  module={3}
                  total={total}
                />
              )}
              {/* 4. 单车调运车辆出勤数据 */}
              {carArrivalPieData && carArrivalBarData && (
                <ExportPieBar
                  pieData={carArrivalPieData}
                  barData={carArrivalBarData}
                  vehicleType={vehicleType}
                  module={4}
                  pieId="car-attendance"
                  barId="car-attendance-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
              {/* 5. 单车人员在线数据 */}
              {staffOnLinePieData && staffOnLineBarData && (
                <ExportPieBar
                  pieData={staffOnLinePieData}
                  barData={staffOnLineBarData}
                  vehicleType={vehicleType}
                  module={5}
                  pieId="personnel-online"
                  barId="personnel-online-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
              {/* 6. 单车调运车辆在线数据 */}
              {carOnLinePieData && carOnLineBarData && (
                <ExportPieBar
                  pieData={carOnLinePieData}
                  barData={carOnLineBarData}
                  vehicleType={vehicleType}
                  module={6}
                  pieId="car-online"
                  barId="car-online-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
              {/* 7. 未备案单车数据 */}
              {carNotRecordlPieData && carNotRecordlBarData && (
                <ExportPieBar
                  pieData={carNotRecordlPieData}
                  barData={carNotRecordlBarData}
                  vehicleType={vehicleType}
                  module={7}
                  pieId="not-record"
                  barId="not-record-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
              {/* 8. 跨区域单车数据 */}
              {crossPieData && crossBarData && (
                <ExportPieBar
                  pieData={crossPieData}
                  barData={crossBarData}
                  vehicleType={vehicleType}
                  module={8}
                  pieId="cross-regional"
                  barId="cross-regional-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
              {/* 9. 重点工作区域保障告警数据 */}
              {reportPieData && reportBarData && (
                <ExportPieBar
                  pieData={reportPieData}
                  barData={reportBarData}
                  vehicleType={vehicleType}
                  module={9}
                  pieId="security-area"
                  barId="security-area-bar"
                  statisticsType={statisticsType}
                  total={total}
                />
              )}
            </div>
          )}
          {dataNo && (
            <div className="no-data-export">
              <img src={noData} />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

ExportDocument.propTypes = {
  modalType: PropTypes.string,
  vehicleType: PropTypes.string,
  reportType: PropTypes.string.isRequired,
  dailyTime: PropTypes.string,
  bikeType: PropTypes.number,
  isExportOpen: PropTypes.func,
};
export default ExportDocument;
