import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import './mapStyle.css';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';
import {bikeCompany, zoomLevel} from '../statusCollection';
// import transIcon from '../../asset/mapIcon/bg-trans.png';
// import {getMarkRange} from '../../api';
import meituanStaff from '../../asset/mapIcon/staffBreath/meituan/staff.png';
import meituanStaffGuard from '../../asset/mapIcon/staffBreath/meituan/staff-guard.png';
import meituanCar from '../../asset/mapIcon/staffBreath/meituan/car.png';
import meituanCarGuard from '../../asset/mapIcon/staffBreath/meituan/car-guard.png';

import haluoStaff from '../../asset/mapIcon/staffBreath/haluo/staff.png';
import haluoStaffGuard from '../../asset/mapIcon/staffBreath/haluo/staff-guard.png';
import haluoCar from '../../asset/mapIcon/staffBreath/haluo/car.png';
import haluoCarGuard from '../../asset/mapIcon/staffBreath/haluo/car-guard.png';

import qingjuStaff from '../../asset/mapIcon/staffBreath/qingju/staff.png';
import qingjuStaffGuard from '../../asset/mapIcon/staffBreath/qingju/staff-guard.png';
import qingjuCar from '../../asset/mapIcon/staffBreath/qingju/car.png';
import qingjuCarGuard from '../../asset/mapIcon/staffBreath/qingju/car-guard.png';

import {Spin} from 'antd';
import {timeFormatMin} from '../baseTime';
import {getAreaBarChart, getKeyAreaBarChart, getMapZoomCenter} from '../../api';
import {isMockData} from '../../utils/judge';
// import moment from 'moment';
// import {exportRaw} from '../../pages/screen/bikeManage/components/mock';

let areaId;
let loca;

/**
 * 描述
 * @author ziwei.Dong
 * @date 2023-2-6
 *  * {
 * @param {array} points 人员坐在位置数据集合
 * @param {number} fullScreen 全屏等级 0 小屏 1 大屏 2 全屏
 * @param {number} reload 重置中心点
 * @param {number} queryType  1 单车 2 电单车
 * @param {array} markRangeList 保障区域数据合集
 * @param {function} onAreaClick 点击保障区域触发事件
 * }
 * @return {JSXDom}
 */

const StaffLocaScatterLayer = ({
  points,
  fullScreen,
  linkTRTC,
  reload,
  queryType,
  onAreaClick,
  searchCenter,
  isShowKeyArea,
}) => {
  window.onFaceTime = linkTRTC;
  // const mapRef = useRef();
  const [staffMap, setStaffMap] = useState(null);
  const [staffMapLoading, setStaffMapLoading] = useState(true);
  const [zoom, setZoom] = useState(13);
  const [center, setCenter] = useState([104.065861, 30.657401]);
  const isChangeData = isMockData();
  const workTimeOne = '07:30:00-12:00:00';
  const workTimeTwo= '13:30:00-17:30:00';
  // 人员图标点击信息窗体
  const openInfo = (data) => {
    // 构建信息窗体中显示的内容
    areaId = null;
    onAreaClick(null);
    const info = [];
    // 街道层级 infoWindow
    info.push(`<div
          class="input-card content-window-card">
          <div>
          </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">
            <div class="amap-info-title">
            ${data.jobType === 1 ? '单车人员' :
        data.jobType === 2 ? '单车调运车辆' :
          data.jobType === 5 ? '电单车人员' :
            data.jobType === 6 ? '电单车调运车辆' : ''}</div>`);
    info.push(`<div class="amap-info-box"><div class='amap-info-item'>姓名：${data.name}</div>`);
    info.push(`<div class='amap-info-item'>工号：${data.jobNumber}</div>`);
    info.push(`<div class='amap-info-item'>联系电话：${data.phoneNumber}</div>`);
    if (data.jobType === 2 || data.jobType === 6) {
      info.push(`<div class='amap-info-item'>车牌号：${data.licensePlate || '暂无'}</div>`);
    }
    info.push(`<div class='amap-info-item'>所属企业：${bikeCompany(data.company)}</div>`);
    if (data.entryTime && data.jobStatus === 3) {
      info.push(`<div class='amap-info-item'>进入时间：${timeFormatMin(data.entryTime)}</div>`);
    }
    const d = `'${data.operationId}','${data.name}','${bikeCompany(data.company)}','${data.phoneNumber}'`;
    info.push(`</div><div class='amap-button'>
            <a href='${location.pathname.indexOf('/bigScreen') !== -1? '/bigScreen':''}/screen/${queryType === 2 ? 'electricBikeManage' :
        'bikeManage'}/staffDetails/${data.operationId}'
            class='border-right'>查看轨迹</a>`);
    if (window.IMSever) {
      info.push(`<a onclick="window.onFaceTime(${d})">视频通话</a>
      </div></div>`);
    } else {
      info.push(`<a disabled style="color:#bbbbbb" >视频通话</a>
      </div></div>`);
    }


    const infoWindow = new window.AMap.InfoWindow({
      offset: new window.AMap.Pixel(0, -12),
      content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
    });
    infoWindow.open(staffMap, data.position);
    staffMap.setCenter(data.position);
  };

  const locaIcon = {
    'meituanStaff': meituanStaff,
    'meituanStaffGuard': meituanStaffGuard,
    'meituanCar': meituanCar,
    'meituanCarGuard': meituanCarGuard,
    'haluoStaff': haluoStaff,
    'haluoStaffGuard': haluoStaffGuard,
    'haluoCar': haluoCar,
    'haluoCarGuard': haluoCarGuard,
    'qingjuStaff': qingjuStaff,
    'qingjuStaffGuard': qingjuStaffGuard,
    'qingjuCar': qingjuCar,
    'qingjuCarGuard': qingjuCarGuard,
  };
  const locaStyle = (type) => ({
    unit: 'px',
    size: [50, 50],
    texture: locaIcon[type],
    borderWidth: 0,
    animate: true,
    duration: 1600,
  });

  const locaData = (type, data) => {
    const LocaScale = new window.Loca.ScatterLayer({
      zIndex: 121,
      zooms: [8, 20],
      loca,
    });
    const dataSource = new window.Loca.GeoJSONSource({
      data: {
        type: 'FeatureCollection',
        features: data,
      },
    });
    LocaScale.setSource(dataSource);
    LocaScale.setStyle(locaStyle(type));

    staffMap.on('click', (e) => {
      const feat = LocaScale.queryFeature([e.pixel.x, e.pixel.y]);
      if (feat) {
        const find = points.extData.find((obj) => {
          return obj.position[0] === feat.coordinates[0] && obj.position[1] === feat.coordinates[1];
        });
        openInfo(find);
      }
    });
    dataSource.destroy();
  };

  const markerType = (staffPoints) => {
    loca = new window.Loca.Container({
      map: staffMap,
    });

    Object.keys(staffPoints).forEach((key) => {
      if (key === 'markRangeList' || key === 'extData') {
        return null;
      }
      if (staffPoints[key].length === 0) {
        return null;
      }
      locaData(key, staffPoints[key]);
    });
    loca.animate.start();
  };

  const getTotal = (value, isKeyArea) => {
    if (isKeyArea) {
      const keyAreaData = value || [{
        'actualArrival': 0,
      }];
      let arriveCounts = 0;
      keyAreaData.forEach(({actualArrival}) => {
        arriveCounts += actualArrival;
      });
      return arriveCounts;
    }
    const data = value || [{
      'carTotal': 0,
      'peopleTotal': 0,
    }];
    let peopleCounts = 0;
    let carCounts = 0;
    data.forEach(({carOnline, peopleOnline}) => {
      peopleCounts += peopleOnline;
      carCounts += carOnline;
    });
    return {
      peopleCounts,
      carCounts,
    };
  };

  const getArriveTotal = (value) => {
    const keyAreaData = value || [{
      'arrive': 0,
    }];
    let arriveCounts = 0;
    let mT=0;
    let qJ=0;
    let hl=0;
    keyAreaData.forEach((item) => {
      arriveCounts += item.arrive;
      switch (item.company) {
        case 1:
          mT+=item.arrive||0;
          break;
        case 2:
          qJ+=item.arrive||0;
          break;
        case 3:
          hl+=item.arrive||0;
          break;
        default:
          break;
      }
    });

    return `${arriveCounts}（美团${mT}，青桔${qJ}，哈啰${hl}）`;
  };

  // 区域信息窗体
  const openAreaInfo = (data, position) => {
    // 构建信息窗体中显示的内容
    const info = [];
    const status = data.guaranteeStatus === '1' ?
      `<span><span class="status-progress"></span>进行中</span>` :
      `<span><span class="status-default"></span>未开始</span>`;
    // 街道层级 infoWindow
    info.push(`<div
          class="input-card content-window-card">
          <div>
          </div> `);
    info.push(`<div style="margin-top:-6px; padding: 0;">

            <div class="amap-info-title">
            ${data.guaranteeName} 
            </div>`);
    info.push(`<div class="amap-info-box">
          <div class='amap-info-item'>状态：${status}
          </div>`);
    if (data.type === 1) {
      info.push(`<div class='amap-info-item'>开始时间：${timeFormatMin(data.startTime)}</div>`);
    }
    if (data.guaranteeStatus === '1') {
      if (data.type === 1) {
        getAreaBarChart(queryType, data.id, 2).then((res) => {
          const sum = getTotal(res.data.chart);
          info.push(`<div class='amap-info-item'>到岗人员：${sum.peopleCounts || 0}</div>`);
          info.push(`<div class='amap-info-item'>到岗车辆：${sum.carCounts || 0}</div>`);
          info.push(`</div></div>`);

          const infoWindow = new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(staffMap, position);
          infoWindow.on('close', () => {
            setTimeout(() => {
              if (!infoWindow.getIsOpen()) {
                areaId = null;
                onAreaClick(null);
              }
            }, 100);
          });
        }).catch(() => {
          info.push(`<div class='amap-info-item'>到岗人员：暂无数据</div>`);
          info.push(`<div class='amap-info-item'>到岗车辆：暂无数据</div>`);
          info.push(`</div></div>`);

          const infoWindow = new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(staffMap, position);
          infoWindow.on('close', () => {
            setTimeout(() => {
              if (!infoWindow.getIsOpen()) {
                areaId = null;
                onAreaClick(null);
              }
            }, 100);
          });
        });
      } else {
        getKeyAreaBarChart(queryType, data.id, 2).then((res) => {
          const sum = getTotal(res.data, true);
          const arriveSum = getArriveTotal(res.data);
          info.push(`<div class='amap-info-item'>到岗数：${sum || 0}</div>`);
          info.push(`<div class='amap-info-item'>应配数：${arriveSum}</div>`);
          if (isChangeData) {
            info.push(`<div class='amap-info-item'>工作时段1：${workTimeOne}</div>`);
            info.push(`<div class='amap-info-item'>工作时段2：${workTimeTwo}</div>`);
          }
          info.push(`</div></div>`);

          const infoWindow = new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(staffMap, position);
          infoWindow.on('close', () => {
            setTimeout(() => {
              if (!infoWindow.getIsOpen()) {
                areaId = null;
                onAreaClick(null);
              }
            }, 100);
          });
        }).catch(() => {
          const sum = getTotal();
          const arriveSum = getArriveTotal([]);
          info.push(`<div class='amap-info-item'>到岗数：${sum.peopleCounts || 0}</div>`);
          info.push(`<div class='amap-info-item'>应配数：${arriveSum}</div>`);
          if (isChangeData) {
            info.push(`<div class='amap-info-item'>工作时段1：${workTimeOne}</div>`);
            info.push(`<div class='amap-info-item'>工作时段2：${workTimeTwo}</div>`);
          }
          info.push(`</div></div>`);

          const infoWindow = new window.AMap.InfoWindow({
            // offset: new AMap.Pixel(0, -24),
            content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(staffMap, position);
          infoWindow.on('close', () => {
            setTimeout(() => {
              if (!infoWindow.getIsOpen()) {
                areaId = null;
                onAreaClick(null);
              }
            }, 100);
          });
        });
      }
    } else {
      info.push(`</div></div>`);

      const infoWindow = new window.AMap.InfoWindow({
        // offset: new AMap.Pixel(0, -24),
        content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
      });
      infoWindow.open(staffMap, position);
      infoWindow.on('close', () => {
        setTimeout(() => {
          if (!infoWindow.getIsOpen()) {
            areaId = null;
            onAreaClick(null);
          }
        }, 100);
      });
    }
  };


  const addGuaranteeArea = (list) => {
    if (!staffMap) {
      return;
    }
    const polygonsList = list.filter((item) => isShowKeyArea ? item.type === 1 || item.type === 2 : item.type === 1);
    const polygons = polygonsList.map((item) => {
      const fillColor = item.guaranteeStatus === '1' ?
        item.type === 1 ? '#13C2C2' : '#FA8C16' : '#bfbfbf';
      const borderColor = item.guaranteeStatus === '1' ?
        (item.type === 1 ? '#13C2C2' : '#FA8C16') : (item.type === 1 ? '#bfbfbf' : '#FA8C16');

      const fillOpacity = item.guaranteeStatus === '1' ?
        (item.type === 1 ? 0.4 : 0.4) : 0.4;
      const strokeOpacity = item.guaranteeStatus === '1' ?
        (item.type === 1 ? 0.6 : 0.6) : (item.type === 1 ? 0.6 : 0.5);
      const polygon = new window.AMap.Polygon({
        path: JSON.parse(item.markRange),
        fillColor,
        strokeOpacity,
        fillOpacity,
        strokeWeight: 1,
        bubble: true,
        strokeColor: borderColor,
        extData: {...item},
      });
      if (item.type === 1 || (item.type === 2 && item.guaranteeStatus === '1')) {
        polygon.on('rightclick', (e) => {
          const data = e.target.getExtData();
          if (data.guaranteeStatus === '1') {
            if (areaId !== data.id) {
              areaId = data.id;
              onAreaClick(data);
            }
          } else {
            onAreaClick(null);
          } openAreaInfo(data, [e.lnglat.lng, e.lnglat.lat]);
        });
      }
      return polygon;
    });

    staffMap.add(polygons);
  };

  const mapLoader = (center, zoom) => {
    AMapLoader.load({
      ...AMapKey,
      Loca: {
        version: '2.0',
      },
      plugins: [
        'AMap.Polygon',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      const map = new AMap.Map('staffMap', {
        resizeEnable: true,
        center: center,
        // pitch: 40,
        showLabel: true,
        zoom: zoom,
        mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
      });
      setStaffMap(map);
      // map.on('complete', function() {
      //   // 地图图块加载完成后触发
      map.addControl(new AMap.ToolBar());
      setTimeout(() => {
        setStaffMapLoading(false);
      }, 1000);

      // });
    });
  };

  const mapInit = () => {
    setStaffMapLoading(true);
    if (staffMap) {
      setStaffMapLoading(false);

      return;
    }
    getMapZoomCenter(2).then((res) => {
      const center = res.data.latitudeLongitude ? res.data.latitudeLongitude.split(',') : [104.065861, 30.657401];
      const zoom = zoomLevel(res.data.level);
      setCenter(center);
      setZoom(zoom);
      return mapLoader(center, zoom);
    }).catch(() => {
      mapLoader([104.065861, 30.657401], 13);
    });
  };

  const clearAll = () => {
    if (loca) {
      if (areaId) {
        areaId = null;
        onAreaClick(null);
      }
      staffMap.clearMap();
      loca.destroy();
      staffMap.clearEvents('click');
    }
  };

  useEffect(() => {
    return () => {
      if (staffMap) {
        staffMap.destroy();
      }
      areaId = null;
      loca = null;
    };
  }, []);

  useEffect(() => {
    if (staffMap) {
      staffMap.setZoomAndCenter(zoom, center);
    }
    if (reload === 0) {
      mapInit();
    }
  }, [queryType, reload]);
  useEffect(() => {
    if (staffMap) {
      staffMap.setCenter(searchCenter);
    }
  }, [searchCenter]);

  useEffect(() => {
    if (staffMap) {
      clearAll();
    }
    if (!staffMapLoading && staffMap !== null) {
      markerType(points);
      if (points.markRangeList) {
        // console.log(points.markRangeList);
        addGuaranteeArea(points.markRangeList);
      }
    }
  }, [points, staffMapLoading, isShowKeyArea]);


  return (
    <Spin
      spinning={staffMapLoading}
      // wrapperClassName="map-loading"
      tip="该页面数据量较大，请稍作等待"
      delay={500}
      wrapperClassName={`map-loading ${location.pathname.indexOf('/bigScreen') !== -1 && fullScreen === 0? 'screen-show-h':''}`}
    >
      <div
        id="staffMap"
        className={
          fullScreen === 0 ? `screen-small-map ${location.pathname.indexOf('/bigScreen') !== -1? 'screen-show-map':''}` :
            fullScreen === 1 ? `screen-middle-map` :
              `screen-large-map`
        }
      />
    </Spin>
  );
};
StaffLocaScatterLayer.propTypes = {
  queryType: PropTypes.number.isRequired,
  title: PropTypes.string,
  onAreaClick: PropTypes.func.isRequired,
  fullScreen: PropTypes.number.isRequired,
  reload: PropTypes.number.isRequired,
  points: PropTypes.object,
  linkTRTC: PropTypes.func,
  searchCenter: PropTypes.array,
  isShowKeyArea: PropTypes.bool.isRequired,
};
export default StaffLocaScatterLayer;
