export const TRTCMsg={
  error: {
    micError: '设备异常，未检测到可用麦克风',
    networkError: '网络异常，视频通话已中断',
    TRTCBroke: '视频通话已中断',
    refuseInvitation: '对方已拒绝视频通话',
    missedCall: '视频通话超时未接听',
    severError: '视频通话欠费，当前通话已中断',
    accessError: '对方未开启系统视频通话功能所需权限，导致视频通话中断，请稍后再试',
    clientError: '视频服务出错，请刷新页面重试',
    invalidError: '对方APP版本滞后无法使用视频通话功能',
  },
  warning: {

    duringTRTC: '你正在视频通话中，结束当前通话后才可发起新通话',
    bothDuringTRTC: '你与对方正在视频通话中',
    otherDuringTRTC: '对方正在视频通话中，请稍后',
  },
  success: {
    micOn: '麦克风已开启',
    micOff: '麦克风已关闭',
    TRTCOff: '视频通话已结束',
    cancelCall: '视频通话已取消',
  },
};
