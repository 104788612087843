import React, {useState, useEffect} from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Space,
  Row,
  Col,
  Cascader,
  Select,
  Badge,
  Image,
  Popconfirm,
  message,
  Tooltip,
  Divider,
  Upload,
  Modal,
  Switch,
} from 'antd';
import CustomTable from '../../../components/customTable';
import {GETEQUIPMENTLIST} from '../../../api/Urls';
import {getAreaTreeFilter, equipmentDelete, uploadFile} from '../../../api';
import errorImg from '../../../asset/error_min.png';
import DevicePosition from '../../../components/aMap/DevicePosition';
import {InfoCircleFilled, InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {
  onlineStatusTextlist,
  onThresholdValuet,
  onDotType,
  placeholders,
  locationNumber,
} from '../../../components/statusCollection';
import {Link} from 'react-router-dom';
import './style.css';

let clickLoading = false;
const EquipmentList = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCheck, setIsCheck] = useState(false);
  const [isLatitudeLongitude, setIsLatitudeLongitude] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [areaOptions, setAreaOptions] = useState([]);
  const [updateList, setUpdateList] = useState(0);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [type, setType] = useState();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [equipmentId, setEquipmentId] = useState();
  const [threshold, setThreshold] = useState();
  const [initialValue] = useState({bikeDispatchNum: '1000', bikeAlertNum: '800', bikeStandardNum: '500'});
  const [initialValueOther] = useState({bikeDispatchNum: '120', bikeAlertNum: '80', bikeStandardNum: '15'});

  //  搜索信息
  const formList = [
    {
      label: '设备所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      col: 7,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'name',
      typeOptions: [
        {label: '设备IMEI', value: 1},
        {label: '设备名称', value: 2},
        {label: '设备位置编号', value: 4},
      ],
    },
    {
      label: '设备心跳状态',
      name: 'isHeartbeatOnline',
      key: 'isHeartbeatOnline',
      type: 'select',
      col: 5,
      options: [
        {
          value: 0,
          label: '离线',
        },
        {
          value: 1,
          label: '在线',
        },
        {
          value: 2,
          label: '异常',
        },
      ],
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
  ];
  // 设备列表信息
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      onExport: true,
      fixed: 'left',
    },
    {
      title: '心跳',
      dataIndex: 'isHeartbeatOnline',
      key: 'isHeartbeatOnline',
      width: 120,
      onExport: true,
      fixed: 'left',
      // eslint-disable-next-line
      render: (isHeartbeatOnline) => {
        if (isHeartbeatOnline === 1) return <Badge color="#595959" text="在线" />;
        else if (isHeartbeatOnline === 2) return <Badge color="#F5222D" text="异常" />;
        return <Badge color="#BFBFBF" text={<span className="equipment-color">离线</span>} />;
      },
      exportRender: (isHeartbeatOnline) => onlineStatusTextlist(isHeartbeatOnline.isHeartbeatOnline),
    },
    {
      title: '设备名称',
      width: 150,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      onExport: true,
      fixed: 'left',
    },
    {
      title: '设备IMEI',
      dataIndex: 'mobileEquipmentIdentity',
      key: 'mobileEquipmentIdentity',
      onExport: true,
      width: 180,
    },
    {
      title: '位置编号',
      dataIndex: 'locationNo',
      key: 'locationNo',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (locationNo) => {
        return locationNo ?locationNumber(locationNo):<span>-</span>;
      },
      exportRender: (locationNo) => placeholders(locationNo.locationNo?.replace(/\//g, ','), ''),
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      width: 200,
    },
    {
      title: '自定义阈值开关',
      dataIndex: 'bikeThresholdStatus',
      key: 'bikeThresholdStatus',
      onExport: true,
      width: 150,
      // eslint-disable-next-line
      render: (bikeThresholdStatus) => {
        if (bikeThresholdStatus === 1) {
          return <div>单车：<Badge color="#595959" text="开" /> </div>;
        }
        return (
          <div className="equipment-color">
            单车：
            <Badge color="#BFBFBF" text={<span className="equipment-color">关</span>} />
          </div>
        );
      },
      exportRender: (bikeThresholdStatus) => onThresholdValuet(bikeThresholdStatus.bikeThresholdStatus),
    },
    {
      title: '电池电压',
      dataIndex: 'batteryVoltage',
      key: 'batteryVoltage',
      width: 120,
      onExport: true,
      // eslint-disable-next-line
      render: (batteryVoltage) => {
        return batteryVoltage ? <span>{batteryVoltage}V</span> : <span>-</span>;
      },
      exportRender: (batteryVoltage) => placeholders(batteryVoltage.batteryVoltage, 'v'),
    },
    {
      title: '点位类型',
      dataIndex: 'pointType',
      key: 'pointType',
      onExport: true,
      width: 120,
      render: (pointType) => {
        if (pointType === 1) return <span>普通</span>;
      },
      exportRender: (pointType) => onDotType(pointType.pointType),
    },
    {
      title: '点位照片',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 120,
      onExport: true,
      // eslint-disable-next-line
      render: (imageUrl) => {
        return (
          <Image.PreviewGroup>
            {imageUrl ? (
              imageUrl.split(';').map((item, index) => {
                return (
                  <div key={item + index} style={index !== 0 ? {display: 'none'} : {display: 'block'}}>
                    <Image width={72} height={40} fallback={errorImg} src={item || errorImg} />
                  </div>
                );
              })
            ) : (
              <Image fallback={errorImg} src={''} />
            )}
          </Image.PreviewGroup>
        );
      },
    },
    {
      title: '安装人员',
      dataIndex: 'createName',
      key: 'createName',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (createName) => {
        return createName ?createName:<span>-</span>;
      },
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      onExport: true,
      width: 150,
      // eslint-disable-next-line
         render: (phoneNumber) => {
        return phoneNumber ?phoneNumber:<span>-</span>;
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 170,
      fixed: 'right',
      // eslint-disable-next-line
      render: (_, record) => (
        <div>
          <Link className="equipment-link" to={`details/${record.id}/${record.type}`}>详情</Link>
          <Button type="link" size="small" onClick={() => editEquipment(record)}>
            编辑
          </Button>
          <Popconfirm
            title={
              <div className="equipment-pop">
                <span>是否确认删除该设备？</span>
                <p>IMEI：{record.mobileEquipmentIdentity}</p>
                <p>设备名称：{record.equipmentName}</p>
              </div>
            }
            okText="确认"
            cancelText="取消"
            onConfirm={() => deleteEquipment(record.id)}
          >
            <Button type="link" size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  // 所属区域接口
  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      // 没有id的是区域人员id ===2是 系统管理 不限制 id===3 是 区域 不能重名id===4 是 街道
      const type = 2;
      const data = res.data.map((item, index) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (
            <span>
              {item.areaName}&nbsp;&nbsp;
              <span className="text-light"> {item.managerName || ''}</span>
            </span>
          ),
          children: item.children ?
            item.children.map((sub) => {
              return {
                ...sub,
                label: (
                  <span>
                    {sub.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {sub.managerName || ''}</span>
                  </span>
                ),
                value: sub.id,
                streetManagerName: sub.managerName || '',
                disabled: type === 4 && sub.managerName,
                children: sub.children ?
                    sub.children.map((aff) => {
                      return {
                        ...aff,
                        label: (
                          <span>
                            {aff.areaName}&nbsp;&nbsp;
                            <span className="text-light"> {aff.managerName || ''}</span>
                          </span>
                        ),
                        value: aff.id,
                        streetManagerName: aff.managerName || '',
                      };
                    }) : [],
              };
            }) :[],
        };
      });
      setAreaOptions(data);
    });
  };
  // 所属区域
  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions && selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{option.areaName}</span>;
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  // 提示说明
  const tooltip = (value) => {
    return (
      <div className="tool-tip-color">
        <div>
          <InfoCircleFilled className="tool-tip" />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        {value === 'longitude' ? (
          <p className="tip-info">
            经纬度所在位置应与设备所属区域相符。输入设备所在经度与纬度信息后，可在地图查看该点位置。
          </p>
        ) : value === 'number' ? (
          <p className="tip-info">填写多个用“/”隔开。</p>
        ) : (
          <p className="tip-info">关闭自定义阈值默认统一使用预设数值，不可更改。开启自定义阈值，需手动设置。</p>
        )}
      </div>
    );
  };
  // 取消关闭抽屉
  const handCancel = () => {
    setIsVisible(false);
    form.resetFields();
    setIsLatitudeLongitude(false);
    setLatitude(null);
    setLongitude(null);
    setFileList([]);
    setIsCheck(false);
    setDefaultFileList([]);
    setThreshold();
  };
  // 图片
  const getBase64 = (file) =>new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // 上传照片前
  const beforeUpload = (file) => {
    const isPNG = file.type;
    if (!(isPNG === 'image/png' || isPNG === 'image/jpeg' || isPNG === 'image/jpg')) {
      message.error(`${file.name} 不是 JPG/JPEG/PNG 格式`);
      return false || Upload.LIST_IGNORE;
    }
    if (Number(file.size) >= 5242880) {
      message.error('照片大小不超过5M');
      return false || Upload.LIST_IGNORE;
    } else {
      setFileList([...fileList, file]);
      return false;
    }
  };
  // 移除照片
  const onRemove = (file) => {
    if (!defaultFileList.length) {
      const index = fileList.map((x) => {
        return x.uid;
      }).indexOf(file.uid);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    } else {
      const deIndex = defaultFileList.map((x) => {
        return x.url;
      }).indexOf(file.url);
      const delFileList = defaultFileList.slice();
      delFileList.splice(deIndex, 1);
      setDefaultFileList(delFileList);
    }
  };
  // 校验
  const checkLongitude = (rule, value) => {
    setLongitude(null);
    setIsLatitudeLongitude(false);
    const reg = /^-?((([1-9]\d?|1[0-7]\d)(\.\d{1,9})?)|180)$/;
    if (!reg.test(value)) {
      setIsLatitudeLongitude(false);
      return Promise.reject(new Error('请输入数字,经度应输入-180到+180,最多9位小数'));
    } else {
      setLongitude(value);
      return Promise.resolve();
    }
  };
  const checkLatitude = (rule, value) => {
    setLatitude(null);
    setIsLatitudeLongitude(false);
    const reg = /^-?(([1-8]\d?(\.\d{1,9})?)|90)$/;
    if (!reg.test(value)) {
      setIsLatitudeLongitude(false);
      return Promise.reject(new Error('请输入数字,纬度应输入-90到+90,最多9位小数'));
    } else {
      setLatitude(value);
      return Promise.resolve();
    }
  };
  const checkIMEI = (rule, value) => {
    const reg = /^[0-9]{15}$/;
    if (!reg.test(value)) {
      return Promise.reject(new Error('请输入15位数字'));
    } else {
      return Promise.resolve();
    }
  };
  const checkName = (rule, value) => {
    const reg = /\S/g;
    if (!reg.test(value) || value === undefined) {
      return Promise.reject(new Error('请输入设备名称'));
    } else {
      return Promise.resolve();
    }
  };
  // 编辑设备
  const editEquipment = (record) => {
    setIsVisible(true);
    setType('edit');
    form.setFieldsValue({
      ...record,
      community: record.community ? record.community : '',
      areaId: record.menuId?.split(',').map(Number),
      longitude: record.latitudeLongitude?.split(',')[0],
      latitude: record.latitudeLongitude?.split(',')[1],
    });
    setIsCheck(record.bikeThresholdStatus === 1 ? true : false);
    setLongitude(record.latitudeLongitude?.split(',')[0]);
    setLatitude(record.latitudeLongitude?.split(',')[1]);
    setEquipmentId(record.id);
    setIsLatitudeLongitude(true);
    setThreshold(record.menuId?.split(',').map(Number));
    const img = record.imageUrl && record.imageUrl.split(';');
    if (img && img.length) {
      const list = [];
      img.forEach((item, index) => {
        list.push({
          uid: index,
          name: `图${index + 1}.png`,
          status: 'done',
          url: item,
        });
      });
      setDefaultFileList([...list]);
    }
  };
  // 删除设备
  const deleteEquipment = (id) => {
    equipmentDelete(id, '2').then((resp) => {
      message.success('删除成功！');
      setUpdateList(updateList + 1);
    });
  };
  // 新增/编辑
  const handleEquipment = (value) => {
    const reg = /(^[1-9]\d*$)/;
    if (
      !(
        reg.test(Number(value.bikeDispatchNum)) &&
        reg.test(Number(value.bikeAlertNum)) &&
        reg.test(Number(value.bikeStandardNum))
      )
    ) {
      message.error('阙值设置不符合规范。调运值,预警值,标准值均为正整数');
      return;
    }
    if (
      !(
        Number(value.bikeDispatchNum) > Number(value.bikeAlertNum) &&
        Number(value.bikeAlertNum) > Number(value.bikeStandardNum)
      )
    ) {
      message.error('阙值设置不符合规范。调运值>预警值>标准值');
      return;
    }
    if (clickLoading) return false;
    clickLoading = true;
    const oldImg = [];
    defaultFileList?.forEach((item) => {
      oldImg.push(item.url);
    });
    const uploadData = {
      ...value,
      bikeThresholdStatus: isCheck ? 1 : 2,
      latitudeLongitude: `${value.longitude},${value.latitude}`,
      areaId: Number(value.areaId.slice(-1).join()),
      bikeDispatchNum: Number(value.bikeDispatchNum),
      bikeAlertNum: Number(value.bikeAlertNum),
      bikeStandardNum: Number(value.bikeStandardNum),
      pointType: Number(value.pointType),
      oldImageUrl: oldImg?.join(';'),
      id: equipmentId,
      equipmentMac: !value.equipmentMac ? '' : value.equipmentMac,
      locationNo: !value.locationNo ? '' : value.locationNo,
      community: !value.community ? '' : value.community,
    };
    delete uploadData.longitude;
    delete uploadData.latitude;
    if (type === 'add') {
      delete uploadData.oldImageUrl;
      delete uploadData.id;
    }
    const formData = new FormData();
    fileList.forEach((_, index) => {
      formData.append('files', fileList[index]);
    });
    Object.entries(uploadData).forEach(([k, v]) => {
      formData.append(k, v);
    });
    uploadFile(type === 'add' ? '/api/equipment/add' : '/api/equipment/update', formData, '2') .then((resp) => {
      message.success(type === 'add' ? '新增设备成功！' : '编辑设备成功！');
      handCancel();
      setUpdateList(updateList + 1);
      clickLoading = false;
    }).catch(() => {
      clickLoading = false;
    });
  };
  // 阙值设置
  const thresholdValue = (value) => {
    if (!isCheck) {
      setFromValue(value);
    }
  };
  const checkValue = (checked) => {
    if (!checked) {
      setFromValue(threshold);
    }
  };
  const setFromValue = (value) => {
    if (value && value[1] === 6) {
      form.setFieldsValue({...initialValue});
    } else {
      form.setFieldsValue({...initialValueOther});
    }
  };
  //  新增设备信息/编辑设备信息
  const editForm = (
    <Form
      layout="vertical"
      name="settingForm"
      onFinish={handleEquipment}
      form={form}
      initialValues={
        type === 'add' && {
          ...initialValueOther,
          pointType: 1,
          bikeThresholdStatus: false,
        }
      }
    >
      <p htmlFor="" className="equipment-title">
        基础信息
      </p>
      <Row gutter={25} className="equipment-row">
        <Col span={12}>
          <Form.Item
            name="mobileEquipmentIdentity"
            label={'设备IMEI：'}
            rules={[{required: true, validator: checkIMEI}]}
          >
            <Input placeholder="请输入15位数字" maxLength={15} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="equipmentName"
            label={'设备名称：'}
            rules={[{required: true, max: 50, validator: checkName}]}
          >
            <Input placeholder="请输入50字内" maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25} className="equipment-row">
        <Col span={12}>
          <Form.Item label="所属区域" name="areaId" rules={[{required: true, message: '请选择所属区域'}]}>
            <Cascader
              maxTagCount="responsive"
              placement="bottomRight"
              displayRender={displayRender}
              placeholder="请选择 (对应到街道)"
              options={areaOptions}
              className="example-img "
              onChange={(value) => {
                thresholdValue(value);
                setThreshold(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="pointType" label={'点位类型：'} rules={[{required: true, message: '请选择点位类型'}]}>
            <Select disabled options={[{value: 1, label: '普通'}]} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25} className="equipment-row">
        <Col span={12}>
          <Form.Item name="equipmentMac" label={'设备MAC地址：'}>
            <Input autoComplete="off" placeholder="请输入" maxLength={10} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="locationNo"
            label={
              <Space>
                设备位置编号
                <Tooltip placement="topLeft" title={tooltip('number')} color="white">
                  <InfoCircleOutlined className="icon-color" />
                </Tooltip>
              </Space>
            }
          >
            <Input placeholder="请输入" maxLength={100} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25} className="equipment-row">
        <Col span={12}>
          <Form.Item
            name="longitude"
            label={
              <Space>
                经纬度
                <Tooltip placement="topLeft" title={tooltip('longitude')} color="white">
                  <InfoCircleOutlined className="icon-color" />
                </Tooltip>
              </Space>
            }
            rules={[{required: true, validator: checkLongitude}]}
          >
            <Input placeholder="请输入经度" maxLength={15} />
          </Form.Item>
        </Col>
        <Col span={12} className="equipment-longitude">
          <Form.Item name="latitude" rules={[{validator: checkLatitude}]}>
            <Input placeholder="请输入纬度" maxLength={15} />
          </Form.Item>
        </Col>
      </Row>
      <div className="equipment-btn">
        <Button type="primary" disabled={isDisabled} onClick={() => setIsLatitudeLongitude(true)}>
          地图定位该经纬度
        </Button>
      </div>
      {/* 地图 */}
      {isLatitudeLongitude ? <DevicePosition latitude={latitude} longitude={longitude} /> : <DevicePosition />}
      <Form.Item label="点位照片" valuePropName="fileList" getValueFromEvent={(e) => {}} className="equipment-upload">
        <Upload
          listType="picture-card"
          onPreview={handlePreview}
          beforeUpload={(file) => beforeUpload(file)}
          onRemove={(file) => onRemove(file)}
          defaultFileList={defaultFileList}
        >
          {Number(fileList.length) + Number(defaultFileList.length) >= 3 ? null : (
            <div>
              <PlusOutlined />
              <p>添加照片</p>
            </div>
          )}
        </Upload>
        <div>
          <p>1.最多上传3张</p>
          <p>2.照片大小不超过5M</p>
        </div>
      </Form.Item>
      <Col span={10}>
        <Form.Item name="community" label="备注">
          <Input.TextArea rows={4} placeholder="请输入200字以内" maxLength={200} />
        </Form.Item>
      </Col>
      <p htmlFor="" className="equipment-title">
        阈值设置
      </p>
      <Form.Item
        label={
          <Space>
            单车自定义阈值
            <Tooltip placement="topLeft" title={tooltip('threshold')} color="white">
              <InfoCircleOutlined className="icon-color" />
            </Tooltip>
          </Space>
        }
        valuePropName="checked"
        name="bikeThresholdStatus"
        rules={[{required: true}]}
      >
        <div className="equipment-switch">
          <Switch
            onChange={(checked) => {
              setIsCheck(checked);
              checkValue(checked);
            }}
            checked={isCheck}
          />
        </div>
      </Form.Item>
      <Row gutter={24} className="switch-change">
        <Col span={8}>
          <Form.Item name="bikeStandardNum" label={(isCheck?'自定义':'默认')+'标注值'} shouldUpdate>
            <Input placeholder="请输入数字" maxLength={10} disabled={!isCheck} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="bikeAlertNum" label={(isCheck?'自定义':'默认')+'预警值'} shouldUpdate>
            <Input placeholder="请输入数字" maxLength={10} disabled={!isCheck} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="bikeDispatchNum" label={(isCheck?'自定义':'默认')+'调运值'} shouldUpdate>
            <Input placeholder="请输入数字" maxLength={10} disabled={!isCheck} />
          </Form.Item>
        </Col>
      </Row>
      <div className="padding-t-l padding-b-l" />
      <div className="modal-button">
        <div>
          <Space>
            <Button type="primary" ghost onClick={() => handCancel()}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </div>
      </div>
    </Form>
  );
  useEffect(() => {
    getAreaGroup();
  }, []);
  useEffect(() => {
    // 2分钟刷新设备列表
    const timeNow = setInterval(() => {
      setUpdateList(updateList + 1);
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(timeNow);
    };
  }, [updateList]);
  useEffect(() => {
    if (latitude && longitude) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [latitude, longitude]);
  return (
    <div style={{position: 'relative'}}>
      {/* 设备列表 */}
      <CustomTable
        InitApi={GETEQUIPMENTLIST}
        formList={formList}
        updateList={updateList}
        columns={columns}
        resType="POST"
        scroll={true}
        exportFileName="设备列表"
        toIndex={true}
        tip="设备列表导出中..."
        addFuc={() => {
          setIsVisible(true);
          setType('add');
          // getAreaGroup();
        }}
      />
      {/* 新增/编辑设备 */}
      <Drawer
        title={type === 'add' ? '新增设备信息' : '编辑设备信息'}
        visible={isVisible}
        maskClosable={false}
        onClose={() => handCancel()}
        width={768}
        footer={null}
        destroyOnClose
        style={{position: 'absolute'}}
        // getContainer={false}
      >
        {editForm}
      </Drawer>
      {/* 图片弹框 */}
      <Modal
        style={{position: 'absolute'}}
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}>
        <img alt="example" className="example-img " src={previewImage} />
      </Modal>
    </div>
  );
};
EquipmentList.propTypes = {};
export default EquipmentList;
