import React, {useState, useEffect, useRef} from 'react';
import {Button, Space, Image} from 'antd';
import CustomTable from '../../../components/customTable';
import {Link, useLocation} from 'react-router-dom';
import {GETMOVINGRECORDLISTBYADDRESS, GETMOVINGRECORDLISTBYBIKE} from '../../../api/Urls';
import {dispatchStatus, bikeCompanyOptions, bikeCompany} from '../../../components/statusCollection';
import {timeFormat} from '../../../components/baseTime';
// import CustomImageMin from '../../../components/customImage/CustomImageMin';
import errorImg from '../../../asset/error_min.png';


const DispatchRecord = () => {
  const [optionsType, setOptionsType]=useState(1);
  const [tabsKey, setTabsKey]=useState(useLocation().state||1);
  const [updateList, setUpdateList]=useState(0);
  const isMounted = useRef(false);
  const formList= optionsType==1? [
    {
      label: '运维人员所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      type: 'select',
      key: 'company',
      name: 'company',
      col: 8,
      label: '运维人员所属企业',
      options: bikeCompanyOptions(true),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      type: 'select',
      key: 'bikeType',
      name: 'bikeType',
      col: 8,
      label: '车辆所属车企',
      options: bikeCompanyOptions('bikeCompany'),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '任务完成状态',
      key: 'status',
      col: 4,
      name: 'status',
      type: 'select',
      options: [
        {
          value: 1,
          label: '已完成',
          label2: '完成',
        },
        {
          value: 0,
          label: '未完成',
          label2: '待完成',
        },
      ],
    },
    {
      label: '调度任务开始日期',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: optionsType==1? [
        {label: '运维人员', value: 1},
        {label: '车辆', value: 2},
        {label: '地点', value: 3},
      ]:[{label: '运维人员', value: 1}],
    },
  ]:[
    {
      label: '运维人员所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      type: 'select',
      key: 'company',
      name: 'company',
      col: 8,
      label: '运维人员所属企业',
      options: bikeCompanyOptions(true),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '任务完成状态',
      key: 'status',
      col: 8,
      name: 'status',
      type: 'select',
      options: [
        {
          value: 1,
          label: '已完成',
          label2: '完成',
        },
        {
          value: 0,
          label: '未完成',
          label2: '待完成',
        },
      ],
    },
    {
      label: '调度任务开始日期',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: optionsType==1? [
        {label: '运维人员', value: 1},
        {label: '车辆', value: 2},
        {label: '地点', value: 3},
      ]:[{label: '运维人员', value: 1}],
    },
  ];
  const columns = [
    {
      title: '车锁号',
      dataIndex: 'lockNumber',
      key: 'lockNumber',
      onExport: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: '车辆所属车企',
      dataIndex: 'bikeType',
      key: 'bikeType',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record)=>bikeCompany(record.bikeType),
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      onExport: true,
      width: 120,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
      width: 120,
    },
    {
      title: '人员所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record)=>bikeCompany(record.company),
    },
    {
      title: '任务状态',
      dataIndex: 'taskStatus',
      key: 'taskStatus',
      render: (taskStatus)=>dispatchStatus(taskStatus),
      exportRender: (record)=>dispatchStatus(record.taskStatus),
      onExport: true,
      width: 120,
    },
    {
      title: '调度起点地址',
      dataIndex: 'firstDispatchAddress',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 180,
      sorter: true,
      onExport: true,
      render: (startTime)=>timeFormat(startTime),
      exportRender: (record) =>timeFormat(record.startTime),
    },
    {
      title: '调度终点地址',
      dataIndex: 'lastDispatchAddress',
      key: 'lastDispatchAddress',
      width: 260,
      onExport: true,
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 180,
      key: 'endTime',
      onExport: true,
      render: (endTime)=>timeFormat(endTime),
      exportRender: (record) =>timeFormat(record.endTime),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 160,
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          <Button type="link" size="small">
            <Link to={`position/${record.id}/按车辆查看/${record.operationId}/${record.lockNumber}/${tabsKey}`}>详情与轨迹</Link>
          </Button>
        </Space>
      ),
    },
  ];
  const columns2 = [
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      width: 120,
      fixed: 'left',
      onExport: true,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
    },
    {
      title: '人员所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record)=>bikeCompany(record.company),
    },
    {
      title: '任务状态',
      dataIndex: 'taskStatus',
      key: 'taskStatus',
      render: (taskStatus)=>dispatchStatus(taskStatus),
      exportRender: (record)=>dispatchStatus(record.taskStatus),
      onExport: true,
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 180,
      onExport: true,
      sorter: true,
      render: (startTime)=>timeFormat(startTime),
      exportRender: (record) =>timeFormat(record.startTime),
    },
    {
      title: '调度起点图片',
      dataIndex: 'firstDispatchAddressImg',
      key: 'firstDispatchAddressImg',
      // eslint-disable-next-line
      render: (firstDispatchAddressImg)=>{
        return <>
          <Image.PreviewGroup
          >
            {
              firstDispatchAddressImg&&firstDispatchAddressImg.length>0?firstDispatchAddressImg.map((item, index)=>{
                return <div key={item+index} style={index!==0?{display: 'none'}:{display: 'block'}}><Image
                  width={72} height={40} fallback={errorImg}
                  src={item || errorImg} /></div>;
              }):<Image fallback={errorImg}src={''} width={72} height={40}/>
            }
            {/* {
                ['https://jykj-sources-test.oss-cn-chengdu.aliyuncs.com/20220923/1663914570596.jpg',
                  'https://jykj-sources-test.oss-cn-chengdu.aliyuncs.com/20220923/1663914570636.jpg'].map((item)=>{
                  return <Image key={item} src={item} />;
                })
              } */}
          </Image.PreviewGroup>

        </>;
      },
      onExport: true,
    },
    {
      title: '结束时间',
      width: 180,
      dataIndex: 'endTime',
      key: 'endTime',
      onExport: true,
      render: (endTime)=>timeFormat(endTime),
      exportRender: (record) =>timeFormat(record.endTime),
    },
    {
      title: '调度终点图片',
      dataIndex: 'lastDispatchAddressImg',
      key: 'lastDispatchAddressImg',
      // eslint-disable-next-line
      render: (lastDispatchAddressImg)=> {
        return <>
          <Image.PreviewGroup
          >
            {
              lastDispatchAddressImg&&lastDispatchAddressImg.length>0?lastDispatchAddressImg.map((item, index)=>{
                return <div key={item+index} style={index!==0?{display: 'none'}:{display: 'block'}}><Image
                  width={72} height={40} fallback={errorImg}
                  src={item||errorImg} /></div>;
              }):<Image fallback={errorImg}src={''} width={72} height={40}/>
            }
          </Image.PreviewGroup>
        </>;
      },
      onExport: true,
    },
    {
      title: '数量',
      dataIndex: 'count',
      key: 'count',
      onExport: true,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 160,
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          <Button type="link">
            <Link to={`position/${record.id}/按地点查看/${record.operationId}/${tabsKey}`}>详情与轨迹</Link>
          </Button>
        </Space>
      ),
    },
  ];
  // 按地点(车辆)
  const changeOptions=(e)=>{
    setOptionsType(e);
  };
  // 切换选项卡
  const changeTabs=(e)=>{
    setTabsKey(e);
  };
  useEffect(()=>{
    if (isMounted.current) {
      setUpdateList(updateList+1);
    } else {
      isMounted.current = true;
    }
  }, [tabsKey]);
  return (
    <CustomTable
      InitApi={`${GETMOVINGRECORDLISTBYBIKE}${tabsKey}`}
      scroll={true}
      InitApi2={`${GETMOVINGRECORDLISTBYADDRESS}${tabsKey}`}
      tabOption={[{
        label: '按车辆查看',
        value: 1,
      }, {
        label: '按地点查看',
        value: 2,
      },
      ]}
      formList={formList}
      resetData={updateList}
      columns={columns}
      columns2={columns2}
      exportFileName={parseInt(tabsKey)===1?'单车调度记录':'电单车调度记录'}
      changeOptions={changeOptions}
      changeTabs={changeTabs}
      tabs={true}
      tabsKey={tabsKey}
      tip="调度记录导出中..."
    />
  );
};

DispatchRecord.propTypes = {};

export default DispatchRecord;
