const baseUrl =process.env.NODE_ENV === 'development'?'/all': '';
console.log(process.env.NODE_ENV);
const uploadUrl=process.env.NODE_ENV === 'development'?'': '';

// login
export const LOCALSIGNIN =baseUrl+ '/api/user/login';
export const GETVERIFY =baseUrl+ '/api/user/getVerify';
export const LOGOUT =baseUrl+ '/api/user/logout';

// Roles
export const GETROLELIST =baseUrl+ '/api/role/rolesList';
export const GETMENULIST =baseUrl+ '/api/permission/menuList';
export const ADDROLE =baseUrl+ '/api/role/addRole';
export const REMOVEROLE =baseUrl+ '/api/role/deleteRole/';
export const UPDATEROLE =baseUrl+ '/api/role/updateRole';
export const GETMENUBYID =baseUrl+ '/api/role/menus/';
export const ROLEDETAILS =baseUrl+ '/api/role/roleDetails/';
// users
export const GETUSERLIST =baseUrl+ '/api/user/getUserList';
export const GETUSERDETAILS =baseUrl+ '/api/user/details/';
export const REMOVEUSER =baseUrl+ '/api/user/deleteUser/';
export const ADDUSER =baseUrl+ '/api/user/saveUser';
export const UPDATEUSER =baseUrl+ '/api/user/updateUser';
export const RESETPASSWORD =baseUrl+ '/api/user/resetPwd/';
export const REVISEPWD =baseUrl+ '/api/user/revisePwd';
export const UPDATEPHONE =baseUrl+ '/api/user/updatePhone';
export const ADDAREATOUSER =baseUrl+ '/api/user/addUser';
export const DISABLEDUSER =baseUrl+ '/api/user/userStatus';
export const ENABLEUSER =baseUrl+ '/api/user/unLock/';
export const GETUSERINFO =baseUrl+ '/api/user/userInfo';

// 人员详情
export const GETSTAFFINFO =baseUrl+ '/api/attendanceLog/staffInfo/';
// 轨迹详情
export const GETTRACKDETAIL =baseUrl+ '/api/attendanceLog/trackDetail/';
// 调度轨迹详情
export const GETTASKTRACKDETAIL =baseUrl+ '/api/attendanceLog/trackDetail/';
// 调度详情
export const GETTASKDETAILS =baseUrl+ '/api/attendanceLog/movingInfo/';
// 车辆信息详情
export const GETBIKEINFO =baseUrl+ '/api/attendanceLog/bikeInfo/';
// 定点保障详情
export const GETMAINTAINDETAILS =baseUrl+ '/api/guarantee/details/';
// 核验异常记录详情
export const GETUNUSUALDETAILS =baseUrl+ '/api/verification/details/';

// bicycleManagement
export const BATCHSCRAPBIKE =baseUrl+ '/api/bikeInfo/batchScrap/';
export const GETBIKECOUNT =baseUrl+ '/api/bikeInfo/bikeAreaNum/';
// export const IMPORTEXCELBIKE =baseUrl+ '/api/bikeInfo/importExcel/';
export const IMPORTEXCELBIKE =baseUrl+ '/api/uploadExcel/importBikeInfo/';
// export const IMPORTEXCELBIKE2 =baseUrl+ '/api/bikeInfo/batchSave/';
export const IMPORTEXCELBIKE2 =baseUrl+ '/api/uploadExcel/saveBikeInfo/';
export const GETBIKELIST =baseUrl+ '/api/bikeInfo/list/';
export const BIKEAREANUM =baseUrl+ '/api/bikeInfo/bikeAreaNum';
export const BIKESTATUS =baseUrl+ '/api/bikeInfo/bikeStatus/';
export const UPLOADLOG =baseUrl+ '/api/bikeInfo/uploadLog';
export const GETUPLOADLOGTYPE =baseUrl+ '/api/bikeInfo/checkUploadType';
export const IMPORTBIKE =uploadUrl+ '/api/upload/importBikeInfo';


// logManagement
export const GETLOGINLIST =baseUrl+ '/api/logManager/loginLog';
export const GETMODULELIST =baseUrl+ '/api/permission/menuList';
export const GETOPERATIONLIST =baseUrl+ '/api/logManager/operationLog';

// staffManagement
export const GETSTAFFLIST =baseUrl+ '/api/operationManager/list';
export const IMPORTEXCELSTAFF =baseUrl+ '/api/operationManager/importExcel';
export const GETSTAFFLISTDETAILS =baseUrl+ '/api/operationManager/details/';
export const ADDSTAFF =baseUrl+ '/api/operationManager/add';
export const DELETESTAFF =baseUrl+ '/api/operationManager/delete/';
export const CHANGESTAFFSTATUS =baseUrl+ '/api/operationManager/operationStatus';
export const QUICKSETTING =baseUrl+ '/api/operationManager/quickSetting';
export const RESETSTAFFPWD =baseUrl+ '/api/operationManager/resetPwd/';
export const UPDATESTAFF =baseUrl+ '/api/operationManager/update';
export const BATCHDELETESTAFFS =baseUrl+ '/api/operationManager/batchDelete';
export const ADDJURISDICTION=baseUrl+'/api/operationManager/addJurisdiction';
// 城市管理者
export const IMPORTEXCELCITY =baseUrl+ '/api/operationManager/city/importExcel';
export const GETCITYLISTDETAILS=baseUrl+'/api/operationManager/city/queryDetails/';
export const GETCITYLIST=baseUrl+'/api/operationManager/city/list';
export const ADDCITY =baseUrl+ '/api/operationManager/city/add';
export const UPDATECITY =baseUrl+ '/api/operationManager/city/update';

// 获取区域
export const GETATTENDANCEGROUPAREALIST =baseUrl+ '/api/area/menuTree';
export const GETALLAREALIST =baseUrl+ '/api/area/userAreaTree';
export const GETAREATREE =baseUrl+ '/api/operationManager/getAreaTree';
export const GETBIKEAREATREE =baseUrl+ '/api/bikeInfo/bikeAreaTree';

// 工作管理
// 考勤时段详情
export const GETATTENDANCERULE =baseUrl+ '/api/attendanceLog/ruleDetail/';
export const GETATTENDANCESIMPLERULE =baseUrl+ '/api/attendance/attendanceTime/';
// 考勤记录
export const GETATTENDLOGLIST =baseUrl+ '/api/attendanceLog/attendanceLogManageList';
export const GETATTENDLOGDETAILS =baseUrl+ '/api/attendanceLog/attendanceLogDetail/';
export const GETGROUPLIST =baseUrl+ '/api/attendanceLog/groupList';
export const ATTENDANCELOGREMARK =baseUrl+ '/api/attendanceLog/remark';

// 调度管理
export const GETMOVINGRECORDLISTBYADDRESS =baseUrl+ '/api/attendanceLog/movingRecordListByAddress/';
export const GETMOVINGRECORDLISTBYBIKE =baseUrl+ '/api/attendanceLog/movingRecordListByBike/';
export const GETTASKBIKELIST =baseUrl+ '/api/attendanceLog/bikeList/';

// 定点保障
export const GETGUARANTEELIST =baseUrl+ '/api/guarantee/list';

// 考勤管理
export const GETATTENDANCEMANAGELIST =baseUrl+ '/api/attendance/attendanceManageList';
export const GETADDGROUPMEMBERLIST =baseUrl+ '/api/attendance/addGroupMember/';
export const ADDATTENDANCEGROUP =baseUrl+ ' /api/attendance/addAttendanceGroup';
export const GETATTENDANCEDETAIL =baseUrl+ '/api/attendance/attendanceDetail/';
export const REMOVEATTENDANCEGROUP =baseUrl+ '/api/attendance/removeAttendanceGroup/';
export const UPDATEGROUPATTENDANCERULES =baseUrl+ '/api/attendance/updateGroupAttendanceRules/';
export const UPDATEGROUPBASICINFO =baseUrl+ '/api/attendance/updateGroupBasicInfo';
export const ADDGROUPMEMBER =baseUrl+ '/api/attendance/addGroupMember/';
export const REMOVEGROUPMEMBER =baseUrl+ '/api/attendance/removeGroupMember/';

// 异常核验
export const GETVERIFICATIONLIST =baseUrl+ '/api/verification/list/';

// Staff Screen
export const SCREENQUERYMAP =baseUrl+ '/api/screen/queryMapUpdate';
export const MAPZOOMCENTER =baseUrl+ '/api/screen/mapInit';
export const NOTICEMSGCOUNT =baseUrl+ '/api/screen/queryNoticeMsgCount';

// Staff charts
export const LINECHART =baseUrl+ '/api/screen/lineChart';
export const PIECHART =baseUrl+ '/api/screen/pieChart';
export const STATISTICS =baseUrl+ '/api/screen/statistics';
export const SECURITYAREASTATISTICS =baseUrl+ '/api/screen/securityAreaStatistics/';
export const GETAREABARCHARTDATA =baseUrl+ '/api/screen/barChart/';

export const KEYAREALINECHART =baseUrl+ '/api/screen/emphasisLineChart';
export const GETKEYAREABARCHARTDATA =baseUrl+ '/api/screen/emphasisBarChart/';

export const GETMARKRANGE =baseUrl+ '/api/guarantee/getMarkRange';
export const SETMARKRANGE =baseUrl+ '/api/guarantee/setMarkRange';

// Bike Screen
export const BIKESCREENMAP =baseUrl+ '/api/bike/screen/map/';
export const BIKE_SCREEN_STATISTICS =baseUrl+ '/api/bike/screen/statisticsDataInfo/';

// Bike Screen Charts
export const GETBIKESCREENDEVICE =baseUrl+ '/api/bike/screen/pieChart/';
export const BIKE_SCREEN_RADAR =baseUrl+ '/api/bike/screen/radarChart/';
export const BIKE_SCREEN_LINE =baseUrl+ '/api/bike/screen/lineChart/';
export const MONITORING_QUANTITY=baseUrl+'/api/bike/screen/every-two-minutes/monitoring-quantity';

// 保障区域
export const ADDGUARANTEEAREA =baseUrl+ '/api/securityArea/add/';
export const UPDATEGUARANTEEAREA =baseUrl+ '/api/securityArea/update';
export const GETGUARANTEEAREALIST =baseUrl+ '/api/securityArea/list/';
export const UPDATEGUARANTEEAREASTATUS =baseUrl+ '/api/securityArea/updateSecurityAreaStatus';
export const DELETEGUARANTEEAREA =baseUrl+ '/api/securityArea/delete/';
export const GUARANTEEAREADETAILS =baseUrl+ '/api/securityArea/details/';
export const GETGUARANTEESTATISTICSLIST =baseUrl+ '/api/securityArea/guaranteeStatisticsList/';
export const GETGUARANTEESTATISTICSLISTKEY =baseUrl+ '/api/keySecurityArea/guaranteeStatisticsList/';
export const GETGUARANTEEPERSONLIST =baseUrl+ '/api/securityArea/guaranteePersonList';
export const GETAREANUMSTATUS =baseUrl+ '/api/securityArea/addSecurityAreaNumStatus/';
export const HIDEAREA=baseUrl+'/api/securityArea/hideArea';

// 汇总报表
export const GETINDEXEVALUATION =baseUrl+ '/api/summaryStatistics/getIndexEvaluation';
export const GETWEIGHTINFO =baseUrl+ '/api/weight/getWeightInfo/';
export const SETWEIGHTINFO =baseUrl+ '/api/weight/setWeightInfo';
export const GETARRIVALDATA =baseUrl+ '/api/summaryStatistics/getArrivalData';
export const SUMMARYSTATISTIC=baseUrl+'/api/summaryStatistics/list';
export const WEIGHTFIND =baseUrl+'/api/weight/find';
export const WEIGHTSAVE =baseUrl+'/api/weight/save';

// 设备管理
export const GETEQUIPMENTLIST =baseUrl+ '/api/equipment/list';
export const DELETEEQUIPMENT =baseUrl+ '/api/equipment/delete/';
export const GETMONITORLIST =baseUrl+ '/api/equipment/monitor/1';
export const GETMONITORDETAILS =baseUrl+ '/api/equipment/monitorDetails';
export const EQUIPMENTDETAIL=baseUrl+'/api/equipment/detail';

// 数据统计->单车
export const GETSTATISTIC =baseUrl+ '/api/bike/statistics/statisticsInfo/';
export const GETMONITORPERCENT =baseUrl+ '/api/bike/statistics/monitorPercent/';
export const GETREALTIMETREND =baseUrl+ '/api/bike/statistics/realTimeTrend/';
export const GETBIKESTATISTIC =baseUrl+ '/api/bike/statistics/dailyTrend/';
export const GETNOTFILEDTREND =baseUrl+ '/api/bike/statistics/notFiledTrend/';
export const CROSSREGIONTREND=baseUrl+'/api/bike/statistics/crossRegionTrend/';
export const CROSSREGIONTRENDBYMINUTE=baseUrl+'/api/bike/statistics/crossRegionTrendByTwoMinute/';
export const NOTFILEDTRENDBYMINUTE=baseUrl+'/api/bike/statistics/notFiledTrendByTwoMinute/';
export const TRENDBYDAY=baseUrl+'/api/bike/statistics/trendByDay/';
export const TRENDBYMINUTE=baseUrl+'/api/bike/statistics/trendByTwoMinute/';
export const LISTBIKE=baseUrl+'/api/bike/statistics/lastDayBikeList/';

// 数据统计->单车人员
export const ATTENDANCESTATISTIC=baseUrl+'/api/bike/statistics/attendanceStatistics/';
export const ATTENDANCETREND=baseUrl+'/api/bike/statistics/attendanceTrend/';
export const PERSONNELLIST=baseUrl+'/api/bike/statistics/attendanceList/';

// 数据统计->告警
export const GUARANTESTSTISTIC=baseUrl+'/api/bike/statistics/keyGuaranteeStatistics';
export const GUARANTESTSTISTICLIST=baseUrl+'/api/bike/statistics/keyGuaranteeStatisticsList';

// 系统设置
export const GETROUND =baseUrl+ '/api/systemSettings/getRound';
export const UPDATEROUND =baseUrl+ '/api/systemSettings/updateRound';
export const GRTREQUIREDNUMBER=baseUrl+'/api/systemSettings/getRequiredNumber';
export const UPDATEREQUIREDNUMBER=baseUrl+'/api/systemSettings/updateRequiredNumber';
export const UPDATEORDEREXPIRETIME=baseUrl+'/api/systemSettings/updateOrderExpireTime';
export const GRTORDEREXPIRETIME=baseUrl+'/api/systemSettings/getOrderExpireTime';
// app和web开关
export const APPSWITCHCHANGE=baseUrl+'/api/systemSettings/noticeSwitch';
// app和web默认开启状态
export const DEFALTESTATUS=baseUrl+'/api/systemSettings/getEmphasisAreaInfo';
export const GETREQUIREDQUANTITY =baseUrl+ '/api/systemSettings/getRequiredQuantity';
export const UPDATEREQUIREDQUANTITY=baseUrl+'/api/systemSettings/updateRequiredQuantity';


// TRTC IM
export const GET_USER_SIG=baseUrl+ '/api/UserSig/getUserSig/';

// 工单管理
export const GETWORKORDERMANGAGELIST=baseUrl+ '/api/workOrder/statistic';
// 工单记录
export const GETWORKORDERRECORDLIST=baseUrl+ '/api/workOrder/managerList';
// 根据工单ID获取字列表
export const GETWORKORDERCHILDLISTBYID=baseUrl+ '/api/workOrder/getDetailById';
// 根据工单获取详情
export const GETWORKORDERDETAILBYID=baseUrl+ '/api/workOrder/detail';
export const GETWORKORDERDETAIL=baseUrl+ '/api/workOrder/getDetailById';
// 获取所有的工单所属区域信息
export const GETWORKORDERAREA=baseUrl+ '/api/area/allAreaTree';
// 在线日志
export const GETONLINELOGLIST=baseUrl+ '/api/logManager/operationAccessLog';
// 保障区域
export const GETKEYSECURITYAREA=baseUrl+ '/api/keySecurityArea/list';
// 消息中心
export const GETMESSAGECENTERLIST=baseUrl+ '/api/alarmMessage/list';
// 批量已读
export const READMESSAGEBATCH=baseUrl+ '/api/alarmMessage/batchRead';

// 单车重点保障区域结束按钮
export const FINISHAREABUTTON=baseUrl+ '/api/keySecurityArea/updateStatus/';
// 单车添加指定人员列表
export const ADDSPECIALLIST=baseUrl+ '/api/keySecurityArea/addOperationList';
// 重点保障区域详情接口
export const KEYAREADETAILS=baseUrl+ '/api/keySecurityArea/details/';
// 重点保障区域添加接口
export const KEYAREAADD=baseUrl+ '/api/keySecurityArea/add';
// 重点保障区域编辑接口
export const KEYAREAEDIT=baseUrl+ '/api/keySecurityArea/update';
// 人员统计
export const GETALLWORKERMESSAGE=baseUrl+'/api/keySecurityArea/guaranteeStatisticsList';
// 运维人员管理
// export const GETALLWORKERMESSAGE=baseUrl+'/api/keySecurityArea/guaranteeStatisticsList';
// 重点保障区规则生成
export const KEYRULEDETAIL=baseUrl+'/api/systemSettings/updateNoticeRule';
// 重点保障區域人員子表接口
export const PERSONSONTABLE=baseUrl+'/api/keySecurityArea/guaranteePersonList';
// 大屏模糊搜索
export const SCREENSEARCH=baseUrl+'/api/screen/search';
// 获取成华区的车辆管理比率
export const GETCHENGHUARATE=baseUrl+'/api/systemSettings/getUserAreaRate';
// 设置成华区的车辆管理比率
export const SETCHENGHUARATE = baseUrl + '/api/systemSettings/saveUserAreaRate';
// SSO登录用户获取登录信息
export const GETLOGINTREEMENU = baseUrl + '/api/user/getLoginTreeMenu';

// export const WEBSOCKETURL = 'ws://47.108.232.235:9000/websocket'; // 测试
// export const WEBSOCKETURL = 'ws://shared-bikes.hcytech.io/websocket'; // 内网
export const WEBSOCKETURL='wss://tips.smartecycle.cn:9110/websocket'; // 正式


// export const UPLOADURL ='http://shared-bikes.hcytech.io'; // k8s

// export const UPLOADURL ='http://upload.test.cd-jyxx.com'; // 测试
export const UPLOADURL ='https://upload.smartecycle.cn'; // 正式
