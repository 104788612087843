import React from 'react';

export const description = (title, vehicleType, tabsKey) => {
  const timeTab = tabsKey === 1 ? '日' : tabsKey === 2 ? '周' : '月';
  const date = tabsKey === 1 ? '当日00:00-23:59' : tabsKey === 2 ? '一个自然周' : '一个月';
  switch (title) {
    case '未备案':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到的未备案车辆（不含跨区域）情况，统计时间为
            {date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企未备案{vehicleType}数占比= （当{timeTab}监测到的A车企未备案{vehicleType}数/当
              {timeTab}监测到的全部车企未备案{vehicleType}数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：车企{vehicleType}未备案率= （当{timeTab}监测到的A车企未备案
            {vehicleType}数/（当{timeTab}
            监测到的A车企未备案{vehicleType}数+当{timeTab}监测到的A车企跨区域{vehicleType}数+当{timeTab}
            统计时刻辖区下A车企备案{vehicleType}数））*100%
          </p>
        </div>
      );
    case '车辆出勤':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}调运车辆出勤情况，统计时间为{date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企{vehicleType}调运车辆出勤数占比=（当{timeTab}A车企{vehicleType}调运车辆出勤数/当
              {timeTab}全部
              {vehicleType}调运车辆出勤数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：A车企{vehicleType}调运车辆出勤率=（当{timeTab}A车企正常出勤的
            {vehicleType}
            调运车辆数/当{timeTab}A车企应出勤的{vehicleType}调运车辆数）*100%
          </p>
        </div>
      );
    case '人员出勤':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}秩序人员出勤情况，统计时间为{date}。{vehicleType}
            秩序人员指职务类型为“{vehicleType}人员和{vehicleType}调运车辆”的人员。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企{vehicleType}秩序人员出勤数占比=（当{timeTab}A车企{vehicleType}秩序人员实际出勤数/当
              {timeTab}
              全部车企
              {vehicleType}秩序人员实际出勤数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：A车企{vehicleType}秩序人员出勤率=（当{timeTab}A车企实际出勤
            {vehicleType}秩序人员数/当
            {timeTab}
            A车企规定应配置
            {vehicleType}秩序人员数）*100%
          </p>
        </div>
      );
    case '人员在线':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}秩序人员在线情况，统计时间为{date}。{vehicleType}
            秩序人员指职务类型为“{vehicleType}人员和{vehicleType}调运车辆”的人员。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企{vehicleType}秩序人员在线数占比= （当{timeTab}A车企{vehicleType}
              秩序人员实际在线数/当{timeTab}全部车企{vehicleType}秩序人员实际在线数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：A车企{vehicleType}秩序人员在线率= （当{timeTab}A车企实际在线
            {vehicleType}
            秩序人员数/当{timeTab}A车企规定应配{vehicleType}秩序人员数）*100%
          </p>
        </div>
      );
    case '车辆在线':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}调运车辆在线情况，统计时间为{date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企{vehicleType}调运车辆在线数占比= （当{timeTab}A车企{vehicleType}调运车辆实际在线数/当
              {timeTab}
              全部{vehicleType}调运车辆实际在线数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：A车企{vehicleType}调运车辆在线率= （当{timeTab}A车企实际在线
            {vehicleType}调运车辆数/当
            {timeTab}
            A车企应在线{vehicleType}调运车辆数）*100%
          </p>
        </div>
      );
    case '跨区域':
      return (
        <div>
          <p>
            指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到的未备案车辆（不含跨区域）情况，统计时间为
            {date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（左）说明：A车企跨区域{vehicleType}数占比= （当{timeTab}监测到的A车企跨区域{vehicleType}
              数/当{timeTab}监测到的全部车企跨区域{vehicleType}数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（右）'}说明：A车企{vehicleType}跨区域率= （当{timeTab}监测到的A车企跨区域
            {vehicleType}
            数/（当{timeTab}监测到的A车企未备案{vehicleType}数+当{timeTab}监测到的A车企跨区域{vehicleType}
            数+当{timeTab}统计时刻辖区下A车企备案{vehicleType}数））*100%
          </p>
        </div>
      );
    case '重点工作区域保障':
      return (
        <div>
          <p>指标说明：该项指标统计管理员管辖区域内的重点工作区域保障产生的告警数量，统计时间为{date}。</p>
          <p>
            图表（左）说明：A车企重点工作区域保障告警数量占比= （当{timeTab}A车企重点工作区域保障告警数量/当{timeTab}
            全部车企重点工作区域保障告警数量）*100%
          </p>
          <p>图表（右）说明：各车企重点工作区域保障告警数量</p>
        </div>
      );
    default:
      return;
  }
};

export const pieTitle = (title, vehicleType) => {
  switch (title) {
    case '人员出勤':
      return `车企${vehicleType}秩序人员出勤数占比`;
    case '车辆出勤':
      return `车企${vehicleType}调运车辆出勤数占比`;
    case '未备案':
      return `车企未备案${vehicleType}数占比`;
    case '人员在线':
      return `车企${vehicleType}秩序人员在线数占比`;
    case '车辆在线':
      return `车企${vehicleType}调运车辆在线数占比`;
    case '跨区域':
      return `车企跨区域${vehicleType}数占比`;
    case '重点工作区域保障':
      return `车企告警数占比`;
    default:
      break;
  }
};

export const barTitle = (title, vehicleType) => {
  switch (title) {
    case '人员出勤':
      return `车企${vehicleType}秩序人员出勤率`;
    case '车辆出勤':
      return `车企${vehicleType}调运车辆出勤率`;
    case '未备案':
      return `车企${vehicleType}未备案率`;
    case '人员在线':
      return `车企${vehicleType}秩序人员在线率`;
    case '车辆在线':
      return `车企${vehicleType}调运车辆在线率`;
    case '跨区域':
      return `车企${vehicleType}跨区域率`;
    case '重点工作区域保障':
      return `车企告警数`;
    default:
      break;
  }
};

export const moduleTitle = (title, vehicleType, statisticsType) => {
  switch (title) {
    case 3:
      return `${statisticsType === 1 ? 3 : 2}. ${vehicleType}秩序人员出勤数据`;
    case 4:
      return `${statisticsType === 1 ? 4 : 3}. ${vehicleType}调运车辆出勤数据`;
    case 5:
      return `${statisticsType === 1 ? 5 : 4}.${vehicleType}秩序人员在线数据`;
    case 6:
      return `${statisticsType === 1 ? 6 : 5}. ${vehicleType}调运车辆在线数据`;
    case 7:
      return `${statisticsType === 1 ? 7 : 6}. 未备案${vehicleType}数据`;
    case 8:
      return `${statisticsType === 1 ? 8 : 7}. 跨区域${vehicleType}数据`;
    case 9:
      return `${statisticsType === 1 ? 9 : 8}. 重点工作区域保障告警数据`;
    default:
      break;
  }
};

export const exportDescription = (modules, vehicleType, tabsKey) => {
  const timeTab = tabsKey === 1 ? '日' : tabsKey === 2 ? '周' : '月';
  const date = tabsKey === 1 ? '当日00:00-23:59' : tabsKey === 2 ? '一个自然周' : '一个月';
  switch (modules) {
    case 3:
      return (
        <div className="export-described ">
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}秩序人员出勤情况，统计时间为{date}。{vehicleType}
            秩序人员指职务类型为“{vehicleType}人员和{vehicleType}调运车辆”的人员。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企{vehicleType}秩序人员出勤数占比= （当{timeTab}A车企{vehicleType}秩序人员实际出勤数/当
              {timeTab}全部车企
              {vehicleType}秩序人员实际出勤数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}秩序人员出勤率= （当{timeTab}A车企实际出勤
            {vehicleType}
            秩序人员数/当{timeTab}A车企规定应配{vehicleType}秩序人员数）*100%
          </p>
        </div>
      );
    case 4:
      return (
        <div className="export-described ">
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}调运车辆出勤情况，统计时间为{date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企{vehicleType}调运车辆出勤数占比= （当{timeTab}A车企{vehicleType}调运车辆实际出勤数/当
              {timeTab}全部{vehicleType}调运车辆实际出勤数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}调运车辆出勤率= （当{timeTab}A车企实际出勤的
            {vehicleType}调运车辆数/当
            {timeTab}A车企规定应配{vehicleType}调运车辆数）*100%
          </p>
        </div>
      );
    case 5:
      return (
        <div className="export-described ">
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}秩序人员在线情况，统计时间为{date}。{vehicleType}
            秩序人员指职务类型为“{vehicleType}人员和{vehicleType}调运车辆”的人员。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企{vehicleType}秩序人员在线数占比= （当{timeTab}A车企{vehicleType}秩序人员实际在线数/当
              {timeTab}全部车企{vehicleType}秩序人员实际在线数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}秩序人员在线率= （当{timeTab}A车企实际在线
            {vehicleType}秩序人员数/当
            {timeTab}A车企规定应配{vehicleType}秩序人员数）*100%
          </p>
        </div>
      );
    case 6:
      return (
        <div className="export-described ">
          <p>
            指标说明：该项指标统计管理员管辖区域内的{vehicleType}调运车辆在线情况，统计时间为{date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企{vehicleType}调运车辆在线数占比= （当{timeTab}A车企{vehicleType}调运车辆实际在线数/当
              {timeTab}全部{vehicleType}调运车辆实际在线数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}调运车辆在线率= （当{timeTab}A车企实际在线
            {vehicleType}调运车辆数/当
            {timeTab}A车企规定应配{vehicleType}调运车辆数）*100%
          </p>
        </div>
      );
    case 7:
      return (
        <div className="export-described ">
          <p>
            指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到的未备案车辆（不含跨区域）情况，统计时间为
            {date}。
          </p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企未备案{vehicleType}数占比= （当{timeTab}监测到的A车企未备案{vehicleType}数/当{timeTab}
              监测到的全部车企未备案{vehicleType}数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}未备案率= （当{timeTab}监测到的A车企未备案
            {vehicleType}数/（当{timeTab}
            监测到的A车企未备案{vehicleType}数+当{timeTab}监测到的A车企跨区域{vehicleType}数+当{timeTab}
            统计时刻辖区下A车企备案{vehicleType}数））*100%
          </p>
        </div>
      );
    case 8:
      return (
        <div className="export-described ">
          <p>指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到的跨区域车辆情况，统计时间为{date}。</p>
          {tabsKey === 1 && (
            <p>
              图表（1）说明：A车企跨区域{vehicleType}数占比= （当{timeTab}监测到的A车企跨区域{vehicleType}数/当{timeTab}
              监测到的全部车企跨区域{vehicleType}数）*100%
            </p>
          )}
          <p>
            图表{tabsKey === 1 && '（2）'}说明：A车企{vehicleType}跨区域率= （当{timeTab}监测到的A车企跨区域
            {vehicleType}数/（当{timeTab}
            监测到的A车企未备案{vehicleType}数+当{timeTab}监测到的A车企跨区域{vehicleType}数+当{timeTab}
            统计时刻辖区下A车企备案{vehicleType}数））*100%
          </p>
        </div>
      );
    case 9:
      return (
        <div className="export-described ">
          <p>指标说明：该项指标统计管理员管辖区域内的定点信息采集设备监测到的跨区域车辆情况，统计时间为{date}。</p>
          <p>
            图表（1）说明：A车企告警数占比= （当{timeTab}监测到的A车企告警数/当{timeTab}监测到的全部告警数）*100%
          </p>
          <p>图表（2）说明：图表（右）说明：各车企重点工作区域保障告警数量</p>
        </div>
      );
    default:
      return;
  }
};

export const typeExport = (key) => {
  switch (key) {
    case 3:
      return '人员出勤';
    case 4:
      return '车辆出勤';
    case 5:
      return '人员在线';
    case 6:
      return '车辆在线';
    case 7:
      return '未备案';
    case 8:
      return '跨区域';
    case 9:
      return '重点工作区域保障';
    default:
      break;
  }
};
