import React from 'react';
import {Button, Popover, Space} from 'antd';
import CustomTable from '../../components/customTable';

import {GETATTENDANCEMANAGELIST} from '../../api/Urls';
import {Link} from 'react-router-dom';
import AttendanceInfoSimple from '../../components/baseInfo/AttendanceInfoSimple';


const AttendanceManagement = () => {
  const formList= [
    {
      label: '考勤组所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [
        {label: '考勤组名', value: 1},
        {label: '街道管理员姓名', value: 2},
      ],
    },
  ];

  const columns = [
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      width: 120,
      fixed: 'left',
    }, {
      title: '考勤组名',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: '组员数量与工作状态',
      width: 280,
      key: 'member',
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          <span>正常：{record.normal || 0}</span>
          <span>休假：{record.onVocation || 0}</span>
          <span>保障：{record.support || 0}</span>
        </Space>
      ),
    },
    {
      title: '区域管理员',
      dataIndex: 'areaManagerName',
      key: 'areaManagerName',
    },
    {
      title: '街道管理员',
      dataIndex: 'streetManagerName',
      key: 'streetManagerName',
    },
    {
      title: '考勤时段',
      dataIndex: 'count',
      key: 'count',
      // eslint-disable-next-line
      render: (count, record) => (<Popover
        placement="top"
        title="考勤时段"
        content={<AttendanceInfoSimple attendId={record.id}/>}
        trigger="click">
        <Button type="link">{count || 0}</Button>
      </Popover>),
    },
    {
      title: '操作',
      key: 'action',
      width: 80,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record) => (
        <Link to={`details/${record.id}`}>详情</Link>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        InitApi={GETATTENDANCEMANAGELIST}
        formList={formList}
        updateList={0}
        scroll={true}
        columns={columns}
        buttonGroup={()=>(<Button type="primary"><Link to={`add`}>新建考勤组</Link></Button>)}
      />
    </div>
  );
};


AttendanceManagement.propTypes = {};

export default AttendanceManagement;
