import React, {useEffect, useState, useRef} from 'react';
import moment from 'moment';
import {message} from 'antd';
import CustomTable from '../../../components/customTable';
// import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {messageReadStatus, bikeCompany, messageReadStatusExport,
  bikeCompanyOptions} from '../../../components/statusCollection';
import {batchReadMessage} from '../../../api';
import {GETMESSAGECENTERLIST} from '../../../api/Urls';
import {timeFormat} from '../../../components/baseTime';

const KeySecurityAreaMessage = () => {
  const {state}=useLocation();
  console.log(state, 'state获取路由传参数据');
  const [updateList, setUpdateList] = useState(0);
  const [resetOptions, setResetOptions] = useState(0);
  const isMounted = useRef(false);
  // 已读1，未读2，全部null
  const [tabsKey, setTabsKey]=useState(state?.type||null);
  const convertDate=(date)=>{
    const newDate = date?.replace(/\//g, '-');
    return newDate;
  };
  const formList = [
    {
      col: 8,
      key: 'address',
      name: 'searchContent',
      type: 'input2',
      label: '搜索重点工作区域保障名称',
      defaultValue: state?.searchContent?state.searchContent:'',
    },
    {
      label: '保障区域所属区域',
      key: 'streetId',
      col: 8,
      name: 'streetId',
      type: 'areaCascaderMul',
      placeholder: '请选择',
      // defaultValue: state?.streetId? state.streetId:'',
    },
    {
      label: '车企',
      key: 'company',
      col: 8,
      name: 'company',
      type: 'select',
      options: bikeCompanyOptions('bike'),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      defaultValue: state?.company?[state.company]:[],
    },
    {
      label: '告警生成时间',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD HH:mm:ss',
      defaultValue: state?.datePicker?
      [moment(moment(convertDate(state?.datePicker)).subtract(0, 'days').format('YYYY-MM-DD 00:00:00')),
        moment(moment(convertDate(state?.datePicker)).subtract(0, 'days').format('YYYY-MM-DD 23:59:59'))]:[],
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
    },
  ];
  // 批量已读消息
  const batchDelete = (ids) => {
    batchReadMessage({ids}, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    });
  };

  const columns = [
    {
      title: '保障区域名称',
      dataIndex: 'guaranteeName',
      key: 'guaranteeName',
      onExport: true,
    },
    {
      title: '保障区域所属区域',
      dataIndex: 'guaranteeAreaName',
      key: 'guaranteeAreaName',
      onExport: true,
    },
    {
      title: '车企',
      dataIndex: 'company',
      key: 'company',
      // eslint-disable-next-line
      render: (record, item) => bikeCompany(record),
      exportRender: (record) => bikeCompany(record.company),
      onExport: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (guaranteeStatus, item)=>messageReadStatus(guaranteeStatus),
      exportRender: (record) => messageReadStatusExport(record.status),
      onExport: true,
    },
    {
      title: '告警内容',
      dataIndex: 'alarmContent',
      key: 'alarmContent',
      onExport: true,
    },
    {
      title: '生成时间',
      dataIndex: 'createTime',
      key: 'createTime',
      // sorter: true,
      render: (createTime)=>timeFormat(createTime),
      onExport: true,
    },
  ];
  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, []);
  const changeTabs=(e)=>{
    console.log(e, 'e的值');
    setTabsKey(e);
    setUpdateList(updateList+1);
  };
  return (
    < >
      <CustomTable
        InitApi={GETMESSAGECENTERLIST}
        rowSelection={tabsKey!==1?true:false}
        customRowSelection={{
          button: '批量已读',
          modal: '确认批量已读',
        }}
        formList={formList}
        tabOption={[{
          label: '全部',
          value: null,
        }, {
          label: '已读',
          value: 1,
        }, {
          label: '未读',
          value: 2,
        },
        ]}
        toIndex={true}
        columns={columns}
        updateList={updateList}
        resetData={resetOptions}
        batchFunction={batchDelete}
        changeTabs={changeTabs}
        exportFileName={parseInt(tabsKey)===1?'已读消息':parseInt(tabsKey)===2?'未读消息':'全部'}
        tabsKey={tabsKey}
        tip="消息导出中..."
        customTabs={true}
        otherParam={{
          type: tabsKey===2?0:tabsKey,
          guaranteeId: state?.guaranteeId?state?.guaranteeId:null,
          company: state?.company?[state.company]:null,
          searchContent: state?.searchContent?state.searchContent:null,
          startTime: state?.datePicker?
          moment(convertDate(state?.datePicker)).subtract(0, 'days').format('YYYY-MM-DD 00:00:00'):null,
          endTime: state?.datePicker?
          moment(convertDate(state?.datePicker)).subtract(0, 'days').format('YYYY-MM-DD 23:59:59'):null,
        }}
      />
    </>
  );
};


export default KeySecurityAreaMessage;

