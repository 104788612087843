import React, {useEffect, useState, useMemo} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {
  Button,
  Card,
  Form,
  Result,
  Space,
  Steps,
  Spin,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Switch,
  Tooltip,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';
import './style.css';
import moment from 'moment/moment';
import PolyEdit from '../../../components/aMap/PolyEdit';
import {addGuarantArea, getAreaNumStatus, editGuarantArea} from '../../../api';
import {InfoCircleOutlined, InfoCircleFilled} from '@ant-design/icons';


const OperateArea = ({queryType}) => {
  const urlSrceen = location.pathname.indexOf('/bigScreen') === -1? '':'/bigScreen';
  const {state}=useLocation();
  const [current, setCurrent] = useState(0);
  const [companyArr, setcompanyArr]=useState(
      [{value: 1, label: '美团', status: 1}, {value: 2, label: '青桔', status: 1}, {value: 3, label: '哈啰', status: 1}]);
  const [basicInfo] = Form.useForm();
  const [finished, setFinished] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapInfo, setMapInfo]=useState({});
  const [polyPath, setPolyPath]=useState([]);
  const [areaId, setAreaId]=useState(0);
  const [initArea, setInitArea]=useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const enterprise=JSON.parse(localStorage.getItem('bikeEnterprise'))?.bikeEnterprise;

  const disabledDate = (current) => {
    return current && current <moment().subtract(1, 'days').endOf('day');
  };

  // 提示说明
  const tooltip = () => {
    return (
      <div className="tool-tip-color">
        <div>
          <InfoCircleFilled className="tool-tip" />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        <div className="tip-info">
          {/* 开启隐藏功能，前往大屏模块的地图上无法查案进行中和未开始状态的保障区域区域。 */}
          开启开关，【大屏展示-单车人员大屏】页面，地图隐藏进行中和未开始状态的保障区域，并暂停相关统计。
        </div>
      </div>
    );
  };
  const onSave=(value)=>{
    setMapInfo(value);
    setCurrent(current + 1);
  };
  const guaranteeAreaForm=useMemo(() =>
    <Form
      name="settingForm"
      form={basicInfo}
      layout="vertical"
    >
      <Row>
        <Col span={24}><div className="base-form-title">{'保障区域基础信息'}</div></Col>
        <Col className="padding-r-m">
          <Form.Item
            label="保障区域名称"
            name="guaranteeName"
            rules={[{
              required: true,
              max: 20,
            }]}
          >
            <Input placeholder="请输入（20字）" className="width-322"/>
          </Form.Item>
        </Col>
        <Col className="padding-r-m">
          <Form.Item
            label="开始时间"
            name="startTime"
            rules={[{
              required: true,
            }]}
          >
            <DatePicker
              allowClear
              disabledDate={disabledDate}
              // disabledTime={disabledTime}
              style={{width: '100%'}}
              showTime={{
                format: 'HH:mm',
              }}
              format="YYYY-MM-DD HH:mm:00"
              className="width-322"
            />
          </Form.Item>
        </Col>
        <Col span={8} />
        <Col span={24}><div className="base-form-title">{'各车企应配人数'}</div></Col>
        <Col span={24}>
          {companyArr.map((item)=>{
            return (
              <Row key={item.value}>
                <Col className="padding-r-m">
                  <Form.Item
                    label="人员所属车企"
                    name={['company'+item.value, 'bikeType']}
                  >
                    <Select disabled options={companyArr} className="width-322"/>
                  </Form.Item>
                </Col>
                <Col className="padding-r-m">
                  <Form.Item
                    label={(queryType===1?'单车':'电单车')+'人员应配数'}
                    name={['company'+item.value, queryType===1?'jobType1':'jobType5']}
                  >
                    <InputNumber min={0} disabled={item.status?!item.disabledPeo:true} className="width-322"/>
                  </Form.Item>
                </Col>
                <Col className="padding-r-m">
                  <Form.Item
                    label={(queryType===1?'单车':'电单车')+'调运车辆应配数'}
                    name={['company'+item.value, queryType===1?'jobType2':'jobType6']}
                  >
                    <InputNumber min={0} disabled={item.status?!item.disabledCar:true} className="width-322"/>
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span={24}><div className="base-form-title">{'区域设置'}</div></Col>
        <Form.Item
          label={
            <Space>
            隐藏区域并暂停统计
              <Tooltip placement="topLeft" title={tooltip()} color="white">
                <InfoCircleOutlined className="icon-color" />
              </Tooltip>
            </Space>
          }
          valuePropName="checked"
          name="hideArea"
        >
          <div className="">
            <Switch
              onChange={(checked) => {
                setIsCheck(checked);
              }}
              checked={isCheck}
            />
          </div>
        </Form.Item>
      </Row>
    </Form>, [basicInfo, companyArr, isCheck],
  );
  useEffect(()=>{
    const temp = [];
    for (const index of enterprise) {
      const res = companyArr.find((i) => i.value === index);
      if (res )temp.push(res);
    }
    getAreaNumStatus(queryType, 2).then((res)=>{
      if (res.data) {
        res.data.forEach((item)=>{
          temp.forEach((element)=>{
            if (item.bikeType===element.value) {
              if (item.jobType===1 || item.jobType===5) {
                element.disabledPeo=true;
              }
              if (item.jobType===2 || item.jobType===6) {
                element.disabledCar=true;
              }
            }
          });
        });
        temp.forEach((item)=>{
          if (item.disabledPeo!==true ) {
            basicInfo.setFieldsValue({company1: {jobType1: 0, jobType5: 0}});
          }
          if (item.disabledCar!==true) {
            basicInfo.setFieldsValue({company1: {jobType2: 0, jobType6: 0}});
          }
        });
        setcompanyArr([...temp]);
      }
    });
  }, []);
  useEffect(() => {
    console.log(state.baseInfo);
    basicInfo.setFieldsValue({company1: {bikeType: 1}, company2: {bikeType: 2}, company3: {bikeType: 3}});
    if (state.operationType==='edit') {
      // setInitAddress(JSON.parse(state.baseInfo.baseInfo.markInfo)[0]);
      // setInitCenter(JSON.parse(state.baseInfo.baseInfo.markInfo)[1]);
      setInitArea(state.baseInfo.baseInfo.guaranteeArea.split(','));
      setPolyPath(JSON.parse(state.baseInfo.baseInfo.markRange));
      setIsCheck(state.baseInfo.baseInfo.hideArea?true:false);
      basicInfo.setFieldsValue({
        company1: state.baseInfo.personNum.find((i)=>i.bikeType===1),
        company2: state.baseInfo.personNum.find((i)=>i.bikeType===2),
        company3: state.baseInfo.personNum.find((i)=>i.bikeType===3),
        guaranteeName: state.baseInfo.baseInfo.guaranteeName,
        startTime: moment(state.baseInfo.baseInfo.startTime, 'YYYY-MM-DD hh:mm'),
        hideArea: state.baseInfo.baseInfo.hideArea,
      },
      );
    } else {
      basicInfo.setFieldsValue({company1: {jobType1: 0, jobType2: 0, jobType5: 0, jobType6: 0},
        company2: {jobType1: 0, jobType2: 0, jobType5: 0, jobType6: 0},
        company3: {jobType1: 0, jobType2: 0, jobType5: 0, jobType6: 0}});
    }
  }, []);


  return (
    <div>
      <DetailsHeader pageName={(state.operationType==='add'?'新建':'编辑')+(queryType===1?'单车':'电单车')+'保障区域'}
        buttonGroup={()=>(
          <Button type="primary" hidden={state.operationType==='add'}>
            <Link to={queryType===1?`${urlSrceen}/guaranteeArea/bikeGuaranteeArea/details/${state.baseInfo&&state.baseInfo.id}`:
        `${urlSrceen}/guaranteeArea/electricBikeGuaranteeArea/details/${state.baseInfo&&state.baseInfo.id}`}
            >返回保障区域详情</Link>
          </Button>
        )}
      />
      <Card>
        <Steps
          type="navigation"
          current={current}
          className="site-navigation-steps"
        >
          <Steps.Step title="绘制保障区域"/>
          <Steps.Step title="填写保障区域信息"/>
        </Steps>
        {finished?(
          <div className="margin200">
            <Result
              status="success"
              title={'恭喜你，'+(state.operationType==='add'?'新建':'编辑')+(queryType===1?'单车':'电单车')+'保障区域成功'}
              subTitle="保障区域全部管理员可见，仅创建者可编辑"
              extra={state.operationType==='add'?[
                <Button key="buy">
                  <Link to={queryType===1?`${urlSrceen}/guaranteeArea/bikeGuaranteeArea/details/${areaId}`:
              `${urlSrceen}/guaranteeArea/electricBikeGuaranteeArea/details/${areaId}`}>
                   查看保障区域详情
                  </Link>
                </Button>,
                <Button type="primary" key="console">
                  <Link to={queryType===1? `${urlSrceen}/guaranteeArea/bikeGuaranteeArea`:
                  `${urlSrceen}/guaranteeArea/electricBikeGuaranteeArea`}>
                  返回
                  </Link>
                </Button>,
              ]:[<Button key="buy">
                <Link to={queryType===1?`${urlSrceen}/guaranteeArea/bikeGuaranteeArea/details/${state.baseInfo&&state.baseInfo.id}`:
              `${urlSrceen}/guaranteeArea/electricBikeGuaranteeArea/details/${state.baseInfo&&state.baseInfo.id}`}>
                 查看保障区域详情
                </Link>
              </Button>]}
            />
          </div>
        ):(<Spin spinning={loading}>
          <div>
            {current === 0 && (<div className="margin-t32">
              {state.operationType==='add'?<PolyEdit
                onSave={onSave}
                polyPath={mapInfo.path}
                initArea={mapInfo.adName}/>:
                <PolyEdit onSave={onSave} userCenter={polyPath[0]}
                  userZoom={12} polyPath={polyPath} initArea={initArea}/>}
            </div>)}
            {current === 1 && (
              <div className="bg-area-gray">
                {guaranteeAreaForm}
              </div>
            )}
          </div>
          <div className="text-center margin-t-l">
            <Space>
              {current !== 0 && (<Button onClick={() => {
                setCurrent(current - 1);
              }}>上一步</Button>)}
              {/* {current !==1 && (<Button type="primary" onClick={() => {
                if (current === 0) {
                  basicInfo.validateFields().then((values) => {
                    setCurrent(current + 1);
                  });
                }
              }}>下一步</Button>)} */}
              {current === 1 && (<Button type="primary" onClick={() => {
                basicInfo.validateFields().then(() => {
                  setLoading(true);
                  const info=basicInfo.getFieldsValue();
                  info.startTime=moment(info.startTime).format('YYYY-MM-DD HH:mm:ss');
                  // if (info.startTime>moment().format('YYYY-MM-DD HH:mm')) {
                  // info.guaranteeArea=mapInfo.adName.join();
                  // eslint-disable-next-line max-len
                  info.guaranteeArea='锦江区,青羊区,金牛区,武侯区,成华区,高新区,天府新区,郫都区,青白江区,温江区,龙泉驿区,新都区,双流区,成都东部新区,新津区,简阳市,都江堰市,彭州市,邛崃市,崇州市,金堂县,大邑县,蒲江县';
                  info.markRange=JSON.stringify(mapInfo.path);
                  info.markInfo=JSON.stringify([mapInfo.initAddress, mapInfo.initCenter]);
                  info.addSecurityAreaNum=[];
                  info.hideArea=isCheck?1:0;
                  // eslint-disable-next-line guard-for-in
                  for (const key in info) {
                    if (key.slice(0, key.length-1)==='company') {
                      // eslint-disable-next-line guard-for-in
                      for (const keyN in info[key]) {
                        if (keyN.slice(0, keyN.length-1)==='jobType') {
                          info.addSecurityAreaNum.push({bikeType: info[key].bikeType,
                            jobType: keyN.slice(keyN.length-1), personNum: info[key][keyN]?info[key][keyN]:0});
                        }
                      }
                    }
                  }
                  delete info.company1;
                  delete info.company2;
                  delete info.company3;
                  // console.log(info);
                  // setFinished(true);
                  // setLoading(false);
                  if (state.operationType==='add') {
                    addGuarantArea(queryType, info, 2).then((res)=>{
                      setFinished(true);
                      setAreaId(res.data);
                    }).finally(()=>{
                      setLoading(false);
                    });
                  } else {
                    editGuarantArea({id: state.baseInfo.id, ...info}, 2).then((res)=>{
                      setFinished(true);
                    }).finally(()=>{
                      setLoading(false);
                    });
                  }
                  // } else {
                  //   message.warning('开始时间已过，请重新设置。');
                  //   setLoading(false);
                  // }
                });
              }}>完成</Button>)}
            </Space>
          </div>
        </Spin>)}
      </Card>
    </div>
  );
};

OperateArea.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default OperateArea;
