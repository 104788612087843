import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getUnusualDetails} from '../../../../api';
import DetailsHeader from '../../../../components/detailsHeader';
import {Card, Col, Divider, Modal, Row, Skeleton, Image, Space} from 'antd';
import StaffInfo from '../../../../components/baseInfo/StaffInfo';
import {bikeCompany, bikeErrorText, bikeType} from '../../../../components/statusCollection';
import AttendanceInfo from '../../../../components/baseInfo/AttendanceInfo';
import CustomImage from '../../../../components/customImage';
import {timeFormat} from '../../../../components/baseTime';

const UnusualDetails = () => {
  const d = useParams();
  const [visible, setVisible] = useState(false);
  const [info, setInfo] = useState({bikeErrorType: parseInt(d.id)});
  const [loading, setLoading] = useState(false);
  const getDetails = () => {
    setLoading(true);
    getUnusualDetails(d.id, d.key, 2).then((res) => {
      setInfo(res.data);
    }).finally(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="核验异常记录详情" tabsKey={d.key}/>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <StaffInfo userID={d.operationId}/>
        <Divider/>
        {loading ? (<Skeleton/>) : (<div>
          <h3 className="details-title">
            核验异常详情
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l">
              <Col span={7}>
                <span >
                  异常类型：
                </span>
                <span className="text-gray">{bikeErrorText(info.bikeErrorType)}</span>
              </Col>
              <Col span={7}>
                <span >
                  核验区域：
                </span>
                <span className="text-gray">{info.verificationArea||'暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span >
                  提交时间：
                </span>
                <span className="text-gray">{timeFormat(info.createTime)}</span>
              </Col>
            </Row>
            <Row className="margin-b-l">
              <Col span={7}>
                <span >
                  车辆类型：
                </span>
                <span className="text-gray">{bikeType(info.type)}</span>
              </Col>
              <Col span={7}>
                <span >
                  所属车企：
                </span>
                <span className="text-gray">{bikeCompany(info.bikeType)}</span>
              </Col>
              <Col span={7}>
                <span >
                  所属区域：
                </span>
                <span className="text-gray">{info.bikeArea||'暂无数据'}</span>
              </Col>
            </Row>
            {info.bikeErrorType === 1 && (
              <div>
                <Row className="margin-b-l" >
                  <Col span={7}>
                    <span >蓝牙MAC地址：</span>
                    {info.bluetoothMacAddress ||'暂无数据'}<span className="text-gray"></span>
                  </Col>
                  <Col span={7}>
                    <span >车架号：</span>
                    <span className="text-gray">{info.frameNumber ||'暂无数据'}</span>
                  </Col>
                  <Col span={7}>
                    <span >车锁号：</span>
                    <span className="text-gray">{info.lockNumber || '暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l" >
                  <Col span={7}>
                    <span >实体车牌号：</span>
                    <span className="text-gray"> {info.licensePlate ||'暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={14}>
                    <span >提交地点：</span>
                    <span className="text-gray">{info.address ||'暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={24}>
                    <span >
                      备注：
                    </span>
                    <span className="text-gray">{info.remark ||'暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={7}>
                    <span>核验异常图片：</span>
                    <div className="padding-r-m padding-t-m">
                      <Image.PreviewGroup>
                        <Space size={'large'}>
                          {
                            info.imageUrl&&info.imageUrl.split(';').length>0?info.imageUrl.split(';').map((item)=>{
                              return <CustomImage key={item} src={item} width={276} height={259}/>;
                            }):<CustomImage src={''} width={276} height={259}/>
                          }
                        </Space>
                      </Image.PreviewGroup>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {info.bikeErrorType === 2 && (<div>
              <Row className="margin-b-l">
                <Col span={7}>
                  <span >未备案MAC地址 ：</span>
                  <span className="text-gray">{info.bluetoothMacAddress ||'暂无数据'}</span>
                </Col>
              </Row>
              <Row className="margin-b-l">
                <Col span={14}>
                  <span >提交地点：</span>
                  <span className="text-gray">{info.address ||'暂无数据'}</span>
                </Col>
              </Row>
              <Row className="margin-b-l">
                <Col span={24}>
                  <span >
                    备注：
                  </span>
                  <span className="text-gray">{info.remark ||'暂无数据'}</span>
                </Col>
              </Row>
            </div>)}
            {info.bikeErrorType === 3 && (<div>
              <Row className="margin-b-l">
                <Col span={7}>
                  <span >未备案车锁号 ：</span>
                  <span className="text-gray">{info.lockNumber || '暂无数据'}</span>
                </Col>
              </Row>
              <Row className="margin-b-l">
                <Col span={14}>
                  <span >提交地点：</span>
                  <span className="text-gray">{info.address || '暂无数据'}</span>
                </Col>
              </Row>
              <Row className="margin-b-l">
                <Col span={24}>
                  <span >
                    备注：
                  </span>
                  <span className="text-gray">{info.remark ||'暂无数据'}</span>
                </Col>
              </Row>
            </div>)}
            {info.bikeErrorType === 4 && (
              <div>
                <Row className="margin-b-l" >
                  <Col span={7}>
                    <span >蓝牙MAC地址：</span>
                    {info.bluetoothMacAddress ||'暂无数据'}<span className="text-gray"></span>
                  </Col>
                  <Col span={7}>
                    <span >车架号：</span>
                    <span className="text-gray">{info.frameNumber ||'暂无数据'}</span>
                  </Col>
                  <Col span={7}>
                    <span >车锁号：</span>
                    <span className="text-gray">{info.lockNumber || '暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={14}>
                    <span >提交地点：</span>
                    <span className="text-gray">{info.address || '暂无数据'}</span>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={7}>
                    <span>核验异常图片：</span>
                    <div className="padding-r-m padding-t-m">
                      <Image.PreviewGroup>
                        <Space size={'large'}>
                          {
                            info.imageUrl&&info.imageUrl.split(';').length>0?info.imageUrl.split(';').map((item)=>{
                              return <CustomImage key={item} src={item} width={276} height={259}/>;
                            }):<CustomImage src={''} width={276} height={259}/>
                          }
                        </Space>
                      </Image.PreviewGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="margin-b-l">
                  <Col span={24}>
                    <span >
                      备注：
                    </span>
                    <span className="text-gray">{info.remark ||'暂无数据'}</span>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>)}
        <Divider/>
        {/* {loading ? (<Skeleton/>) : (<div>
          <h3 className="details-title">
            核验异常备注
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l">
              <Col span={24}>
                <span >
                  备注：
                </span>
                <span className="text-gray">{info.remark ||'暂无数据'}</span>
              </Col>
            </Row>
          </div>
        </div>)} */}
      </Card>
      <Modal
        title="详细考勤规则"
        footer={null}
        visible={visible}
        destroyOnClose
        onCancel={() => setVisible(false)}
      >
        <AttendanceInfo attendId={d.id}/>
      </Modal>
    </div>
  );
};

UnusualDetails.propTypes = {};

export default UnusualDetails;
