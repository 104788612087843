import React, { } from 'react';
import PropTypes from 'prop-types';
import './index.less'

const CockpitBox = ({ children,title,height,size }) => {
    return (
        <div className={`cockpit-box ${size==='small'?'cockpit-box-s':'cockpit-box-m'} `} style={{ width: '100%', height }}>
            <div className='cockpit-title'>{title}</div>
            {/* <div className='cockpit-img'></div> */}
            {children}
        </div>
    );
};

CockpitBox.propTypes = {
};

export default CockpitBox;
