export const HTTPLOADING = 'http_loading';
export const SIDE = 'side';
export const LOGIN = 'login';
export const USERINFO = 'user_info';
export const CANDOAS = 'can_do_advanced_search';
export const GET_MENUS_DATA = 'layout/menus-path-data';
export const UPDATE_MSG = 'update_msg';
export const INIT_MSG = 'init_msg';
export const MYINFO = 'my_info';
export const NOTICEINFO = 'notice_info';
export const IMPORTYPE = 'import_type';


