import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Col, Row, Spin, Timeline, Collapse, Empty} from 'antd';
import Trace from '../../../../components/aMap/Trace';
import AMapLoader from '@amap/amap-jsapi-loader';
import {getTaskTrackDetails} from '../../../../api';
import PropTypes from 'prop-types';
import {trackFrequencyStatus} from '../../../../components/statusCollection';

const DispatchPosition = ({title}) => {
  const d = useParams();

  const [distance, setDistance] = useState('暂无数据');
  const [traceList, setTraceList] = useState(null);
  const [position, setPosition] = useState(null);
  const [pIndex, setPIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState(null);

  const getTraceList = () => {
    setLoading(true);
    getTaskTrackDetails({id: d.id,
      operationId: d.operationId,
    }, 2).then((res) => {
      setFrequency(res.data.trackFrequency);
      const traceList=res.data.tracks.map((item) => ({
        x: item.latitudeLongitude.split(',')[0],
        y: item.latitudeLongitude.split(',')[1],
        updateTime: item.updateTime,
        location: item.address,
      }));
      setTraceList(traceList);
      getDistance(traceList);
    }).finally(() => {
      setLoading(false);
      setPosition(null);
      setPIndex(null);
    });
  };

  const getDistance= (trace)=> {
    if (trace && trace.length > 1) {
      AMapLoader.load({
        key: '7ae46f4c2f423fe71744104412975856', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      }).then((AMap) => {
        const arr = []; // 经纬度坐标数组
        trace.forEach(({x, y}, index) => {
          if (index===0||index===(trace.length-1)) {
            arr.push(new AMap.LngLat(x, y));
          }
        });
        const distance = Math.round(AMap.GeometryUtil.distanceOfLine(arr)) + '米';
        setDistance(distance);
      });
    } else {
      return '暂无数据';
    }
  };

  useEffect(()=>{
    getTraceList();
  }, []);
  return (
    <div id="trace-info">
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel header={(
          <h3 className="details-title">
            {title||'详细轨迹'}
          </h3>)} key="1">
          <Spin spinning={loading}>

            <Row>
              <Col span={14} className="padding-r-l">
                <Trace traceData={traceList} position={position}/>
              </Col>
              <Col span={10}>
                <div className="margin-b-m">
                  <span className="details-key">调度距离：</span>
                  <span className="trace-info">
                    {distance}
                  </span>
                </div>
                <div className="margin-b-m">
                  <span className="details-key">轨迹上传频率：</span>
                  <span className="trace-info">{trackFrequencyStatus(frequency)}</span>
                </div>
                <div className="margin-b-s">
                  <span className="details-key">轨迹回放：</span>
                </div>
                <Timeline className="trace-list-h">
                  {traceList&&traceList.length>0 ?traceList.map((item, index) => (
                    <Timeline.Item
                      className={pIndex === index ? 'active' : ''}
                      color={pIndex === index ? '#08979C' : '#8C8C8C'}
                      onClick={() => {
                        setPosition([item.x, item.y]);
                        setPIndex(index);
                      }}
                      key={item.x + '_' + index}>
                      <span>[
                        {index === 0 ? `终点` :
                        index === (traceList.length - 1) ? `起点` :
                          `位置${traceList.length - index}`}]
                      </span>
                      {item.location}
                      <div className="trace-info">更新时间 {item.updateTime}</div>
                    </Timeline.Item>
                  )):(<div className="margin-t-l padding-t-l">
                    <Empty className="margin-t-l"/>
                  </div>)}
                </Timeline>
              </Col>
            </Row>

          </Spin>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

DispatchPosition.propTypes = {
  title: PropTypes.string,
};

export default DispatchPosition;
