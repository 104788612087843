import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {Button, Card, Col, DatePicker, Divider, Row, Timeline, Spin, Empty} from 'antd';
import {Link, useLocation, useParams} from 'react-router-dom';
import StaffInfo from '../../../components/baseInfo/StaffInfo';
import Trace from '../../../components/aMap/Trace';
import moment from 'moment';
import {getTrackDetails} from '../../../api';
// import {trackFrequencyStatus} from '../../../components/statusCollection';
import {timeFormatMin} from '../../../components/baseTime';

const AttendanceRecordPosition = () => {
  const l=useLocation().pathname.split('/');
  const d=useParams();
  const link=`/${l[1]}/${l[2]}/details/${d.id}`;
  const [traceList, setTraceList]= useState([]);
  const [position, setPosition] = useState(null);
  const [pIndex, setPIndex] = useState(null);
  // const [frequency, setFrequency] = useState(null);
  const [track, setTrack] = useState({});
  const [loading, setLoading] = useState(false);
  const disabledDate = (current) => {
    return current < moment().subtract(3, 'month') || current > moment().subtract(0, 'month');
  };

  const getTraceList=(value)=>{
    const data={
      date: value?moment(value).valueOf():moment().valueOf(),
    };
    setLoading(true);
    getTrackDetails(d.id, data, 2).then((res)=>{
      // setFrequency(res.data.frequency);
      setTrack(res.data.track);
      setTraceList(res.data.tracks.map((item)=>{
        return {
          x: item.latitudeLongitude.split(',')[0],
          y: item.latitudeLongitude.split(',')[1],
          updateTime: item.updateTime,
          location: item.address,
        };
      })||[]);
    }).finally(()=>{
      setLoading(false);
    });
    setPosition(null);
    setPIndex(null);
  };

  useEffect(()=>{
    getTraceList();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="定位与轨迹" buttonGroup={()=>(<Button type="primary">
        <Link to={link}>
        运维人员管理详情
        </Link>
      </Button>)}/>

      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <StaffInfo userID={d.id}/>
        <Divider />
        <Spin spinning={loading} >
          <h3 className="details-title">
            详细轨迹
          </h3>
          <div className="details-info margin-t-l">
            <Row>
              <Col span={14} className="padding-r-l">
                <Trace traceData={traceList} position={position}/>
              </Col>
              <Col span={10}>
                <div className="margin-b-m">
                  <span className="details-key">日期：</span>
                  <DatePicker
                    disabledDate={disabledDate}
                    onChange={getTraceList}
                    size="small"
                    className="width-m"
                  />
                </div>
                <div className="margin-b-m">
                  <span className="details-key">轨迹上传频率：</span>
                  <span className="trace-info">3分钟/次</span></div>
                <div className="margin-b-m">
                  <span className="details-key">实时位置：</span>
                  <span className="trace-info">{track?.address||'无实时位置数据'}</span>
                </div>
                <div className="margin-b-m">
                  <span className="details-key">最近更新时间：</span>
                  <span className="trace-info">
                    {track?.updateTime?timeFormatMin(track.updateTime):'无实时位置数据'}
                  </span>
                </div>
                <div className="margin-b-s">
                  <span className="details-key">轨迹回放：</span></div>
                <Timeline className="trace-list">
                  {traceList&&traceList.length>0?traceList.map((item, index) => (
                    <Timeline.Item
                      className={pIndex === index ? 'active' : ''}
                      color={pIndex === index ? '#08979C' : '#8C8C8C'}
                      onClick={() => {
                        setPosition([item.x, item.y]);
                        setPIndex(index);
                      }}
                      key={item.x+'_'+index} >
                      <span>[{index===0?`终点`:index===(traceList.length-1)?`起点`:`位置${traceList.length-index}`}] </span>
                      {item.location}
                      <div className="trace-info">更新时间 {timeFormatMin(item.updateTime)}</div>
                    </Timeline.Item>
                  )):(<div className="margin-t-l padding-t-l">
                    <Empty className="margin-t-l"/>
                  </div>)}
                </Timeline>
              </Col>
            </Row>
          </div>
        </Spin>
      </Card>
    </div>
  );
};

AttendanceRecordPosition.propTypes = {};

export default AttendanceRecordPosition;
