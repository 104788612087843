import {
  HTTPLOADING,
  SIDE,
  LOGIN,
  USERINFO,
  CANDOAS,
  UPDATE_MSG,
  INIT_MSG,
  GET_MENUS_DATA,
  MYINFO,
  NOTICEINFO,
  IMPORTYPE,
} from './actionTypes';

export const httpLoading = (f)=>{
  return {
    type: HTTPLOADING,
    loading: f,
  };
};

export const side = (f)=>{
  return {
    type: SIDE,
    collapsed: f,
  };
};

export const login = (f)=>{
  return {
    type: LOGIN,
    logged: f,
  };
};

export const userInfo = (u)=>{
  return {
    type: USERINFO,
    info: u,
  };
};

export const canDoAs = (f)=>{
  return {
    type: CANDOAS,
    can: f,
  };
};

export const setMenusData = (activeKey, openKeys, clickType) => ({
  type: GET_MENUS_DATA,
  activeKey,
  openKeys,
  clickType,
});

export const updateMessageList = (message)=>{
  return {
    type: UPDATE_MSG,
    message,
  };
};

export const initMessageList = ()=>{
  return {
    type: INIT_MSG,
  };
};


export const updateMyInfo = (n)=>{
  return {
    type: MYINFO,
    myInfoCount: n,
  };
};

export const sendNoticeInfo = (n)=>{
  return {
    type: NOTICEINFO,
    noticeInfo: n,
  };
};

export const changeImportType = (n)=>{
  return {
    type: IMPORTYPE,
    count: n,
  };
};
