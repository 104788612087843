import React, {useState, useMemo, useEffect} from 'react';
import {DatePicker, Button} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';

import moment from 'moment';
dayjs.extend(customParseFormat);
const {RangePicker} = DatePicker;

let startDay = '';
const HourChange = ({setNotRecord, reset, tab, menuTab, incomeDate}) => {
  const [dates, setDates] = useState();
  const [value, setValue] = useState(null);
  const [dataKey, setDataKey] = useState('');

  const rangePickerDif = useMemo(() => {
    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    const disabledDateTime = (current, start) => {
      if (!dates) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') >= 1;
      // const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 1;
      const tooEarly = current.diff(moment().subtract(8, 'days'), 'days') <= 0;
      return (current && current > moment(start).add(0, 'day')) || tooLate || tooEarly;
    };

    const disabledRangeTime = (data, type) => {
      if (type === 'start') {
        startDay = moment(data);
      }
      if (type === 'end') {
        if (!startDay) {
          startDay = moment(data);
        }
        const endDay = moment(data).format('YYYY-MM-DD');
        return {
          disabledHours: () => {
            if (moment(endDay).isAfter(startDay?.format('YYYY-MM-DD'))) {
              return range(0, 24).splice(startDay.get('hours') + 1, 24);
            }
          },
          disabledMinutes: () => {
            if (moment(data).hour() === startDay.hour() && !moment(data).isSame(startDay, 'day')) {
              return range(0, 60).splice(startDay.get('minutes') + 1, 60);
            }
          },
        };
      }
    };

    const disabledDateDay = (current, start) => {
      if (!dates) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
      const tooEarly = current.diff(moment().subtract(32, 'days'), 'days') <= 0;
      // const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 30;
      return (
        (current && current > (moment(incomeDate).add(1, 'day') || moment(start).add(-1, 'day'))) || tooLate || tooEarly
      );
    };

    const onOpenChange = (open) => {
      if (open) {
        setDates([null, null]);
      } else {
        setDates(null);
      }
    };
    const onChange = (val) => {
      if (val) {
        if (tab === 2 || tab === 4) {
          setNotRecord({
            startTime: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment(val[1]).format('YYYY-MM-DD HH:mm:ss'),
          });
        } else {
          setNotRecord({
            startTime: moment(val[0]).format('YYYY-MM-DD'),
            endTime: moment(val[1]).format('YYYY-MM-DD'),
          });
        }
      }
      setValue(val);
    };
    return (
      <>
        {tab === 2 || tab === 4 ? (
          <RangePicker
            disabledDate={disabledDateTime}
            disabledTime={disabledRangeTime}
            key={dataKey}
            showTime={{
              defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm:ss')],
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
            onCalendarChange={(val) => setDates(val)}
            onOpenChange={onOpenChange}
            value={dates || value}
          />
        ) : (
          <RangePicker
            value={dates || value}
            disabledDate={disabledDateDay}
            onCalendarChange={(val) => setDates(val)}
            onChange={onChange}
            onOpenChange={onOpenChange}
          />
        )}
      </>
    );
  }, [tab, dates, value, dataKey]);

  useEffect(() => {
    if (tab || menuTab) {
      setValue(null);
      setDates(null);
      setDataKey(new Date());
    }
  }, [tab, menuTab]);
  return (
    <div>
      {rangePickerDif}
      <Button
        className="margin-l-m"
        onClick={() => {
          setValue(null);
          setDates(null);
          setNotRecord();
          setDataKey(new Date());
          reset();
        }}
      >
        重置
      </Button>
    </div>
  );
};
HourChange.propTypes = {
  setNotRecord: PropTypes.any,
  reset: PropTypes.any,
  tab: PropTypes.number,
  menuTab: PropTypes.number,
  incomeDate: PropTypes.any,
};
export default HourChange;
