import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Form, Input, Space, Spin, message} from 'antd';
import PropTypes from 'prop-types';
import editIcon from '../../../asset/edit.png';
import forbidIcon from '../../../asset/forbid-edit.png';
import {InfoCircleOutlined} from '@ant-design/icons';
import ExitEdit from './ExitEdit';
import {keyRuleProduct} from '../../../api';
const KeyAreaSetting = ({orderInfo, onFinished, open, changeOpen, redact, setRedact}) => {
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [systemValue, setSystemValue] = useState();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    setRedact(values);
    keyRuleProduct(values, 2).then((resp) => {
      setEdit(false);
      message.success('操作成功');
      onFinished();
      setRedact('');
      setSystemValue(null);
    }).catch(() => {
      message.error('操作失败');
    }).finally(()=>{
      setLoading(false);
    });
  };
  // 校正规则
  const checked = (rule, value) => {
    const reg = /\S/g;
    const regname = /^\+?[1-9][0-9]*$/;
    if (!reg.test(value) || value === undefined) {
      setSystemValue(null);
      setRedact(null);
      return Promise.reject(new Error('此为必填项，请填写数值'));
    }
    if (value < 1 || value > 480||!regname.test(value)) {
      setSystemValue(null);
      setRedact(null);
      return Promise.reject(new Error('数值填写不符合规范，请填写1~480范围内的整数'));
    } else {
      setSystemValue(value);
      setRedact(value);
      return Promise.resolve();
    }
  };
    // 确认退出编辑
  const confirm=()=>{
    setEdit(false);
    setVisible(false);
    setSystemValue(null);
    setRedact('');
  };

  useEffect(() => {
    if (orderInfo && edit) {
      form.setFieldsValue({
        rounds: orderInfo,
      });
    }
  }, [orderInfo, edit]);

  useEffect(() => {
    if (open !== 'keyRules') {
      setEdit(false);
    }
  }, [open]);

  return (
    <div>
      <h2>重点工作区域保障相关设置</h2>
      <h3>
      1.重点工作区域保障告警生成规则
        <span
          onClick={() => {
            if (redact==='') {
              changeOpen();
              setEdit(true);
            }
          }}
          className="padding-l-m point"
        >
          <img src={edit?editIcon:redact===''?editIcon:forbidIcon} alt="edit" />
        </span>
      </h3>
      <div className="hint-system">
        <InfoCircleOutlined className="off-icon" />
        <p className="details-h6">
          {/* 工单创建并被运维负责人指派后，运维人员超过X分钟，未提交完成信息，自动过期，需重新指派。 */}
          进行中的“重点工作区域保障”中，应配人数大于0的车企在超管视角下连续X轮（3分钟/轮）的“车企到岗率”低于100%，
          将自动生成该车企的告警消息，并推送给管理平台的相关管理人员、运维端APP的相关运维负责人， 以及被指定普通运维人员。
        </p>
      </div>
      {edit ? (
        <Spin spinning={loading}>
          <Form name="base" form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={[16]} className="off-lines">
              <Col>
                <Form.Item
                  // label="工单过期判断条件:"
                  label="重点工作区域保障告警生成规则:"
                  name="rounds"
                  rules={[{required: true, validator: checked}]}
                >
                  <div >
                    <label >连续 </label>
                    <Input maxLength={10} className="off-input" defaultValue={orderInfo} />
                    <label> 轮（3分钟/轮）到岗率低于100%，将自动生成告警消息</label>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <div className="text-right">
              <Space>
                <Button
                  onClick={() => {
                    if (systemValue) {
                      setVisible(true);
                    } else {
                      setEdit(false);
                      setRedact('');
                    }
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Space>
            </div>
          </Form>
        </Spin>
      ) : (
        <div className="details-info margin-t-l">
          <Row className="margin-b-l">
            <Col span={12}>
              {/* <span className="padding-r-m">工单过期判断条件: </span>
              <span>指派后{orderInfo}分钟，未提交完成信息。</span> */}
              <span>重点工作区域保障告警生成规则：连续{orderInfo}轮（3分钟/轮）到岗率低于100%，将自动生成告警消息</span>
            </Col>
          </Row>
        </div>
      )}
      {/* 退出编辑 */}
      {visible&&<ExitEdit visible={visible} setVisible={()=>setVisible(false)} confirm={confirm}/>}
    </div>
  );
};

KeyAreaSetting.propTypes = {
  orderInfo: PropTypes.number,
  onFinished: PropTypes.func,
  open: PropTypes.string,
  changeOpen: PropTypes.func,
  redact: PropTypes.any,
  setRedact: PropTypes.func,
};

export default KeyAreaSetting;
