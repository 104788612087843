import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../components/detailsHeader';
import {
  Alert,
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Space,
  Steps,
  TimePicker,
  Spin,
} from 'antd';
import {DeleteOutlined, ExclamationCircleFilled, PlusOutlined} from '@ant-design/icons';
import {arr, options} from '../config';
import {Link} from 'react-router-dom';
import SetClockInArea from './SetClockInArea';
import {momentToClock} from '../../../components/baseTime';
import Member from './Member';
import {addAttendanceGroup, getAreaTreeFilter} from '../../../api';

const SettingAttendanceGroup = () => {
  const [current, setCurrent] = useState(0);
  const [basicInfo] = Form.useForm();
  const [commonRule] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [initAddress, setInitAddress] = useState(null);
  const [initLat, setInitLat] = useState(null);
  const [initLng, setInitLng] = useState(null);
  const [initRadius, setInitRadius] = useState(null);
  const [data, setData] = useState({});
  const [finished, setFinished] = useState(null);
  const [selectKeys, setSelectKeys] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id,
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}
            <span className="text-light"> {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span className="text-light"> {sub.managerName || ''}</span>
                {/* <span className={ (!sub.managerName || sub.groupName)?'text-light-disabled':'text-light'}>
                &nbsp;&nbsp;{sub.managerName|| ''}
                &nbsp;&nbsp;{sub.groupName || '无考勤组'}
                </span> */}
              </span>),
              value: sub.id,
              areaManagerName: sub.managerName || '',
              disabled: !sub.managerName || sub.groupName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}
                    <span className={ (!aff.managerName || aff.groupName)?'text-light-disabled':'text-light'}>
                    &nbsp;&nbsp;{aff.managerName|| ''}
                    &nbsp;&nbsp;{aff.groupName || '无考勤组'}
                    </span>
                  </span>),
                  value: aff.id,
                  streetManagerName: aff.managerName || '',
                  disabled: !aff.managerName || aff.groupName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
    });
  };

  const onChange = (value, selectedOptions) => {
    basicInfo.setFieldsValue({
      areaName: selectedOptions[1].managerName,
      streetName: selectedOptions[2].managerName,
      areaId: selectedOptions[1].id,
      streetId: selectedOptions[2].id,
    });
  };
  const displayRender = (labels, selectedOptions) => {
    return labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <span key={option.value}>
            {option.areaName}
          </span>
        );
      }
      return <span key={option.value}>{label.props.children[0]} / </span>;
    });
  };
  const onClose = () => {
    setVisible(false);
    setInitLat(null);
    setInitAddress(null);
    setInitLng(null);
    setInitRadius(null);
    setIndex(null);
  };
  const onSave = (data) => {
    const value = commonRule.getFieldValue('addAttendanceRules');
    value[index] = {
      ...value[index],
      ...data,
    };
    commonRule.setFieldsValue({
      addAttendanceRules: value,
    });
    commonRule.validateFields();
    setVisible(false);
  };

  useEffect(() => {
    getAreaGroup();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="新建考勤组"/>
      <Card>
        <Steps
          type="navigation"
          current={current}
          className="site-navigation-steps"
        >
          <Steps.Step title="填写基础信息"/>
          <Steps.Step title="设置考勤规则"/>
          <Steps.Step title="添加组员"/>
        </Steps>
        {finished?(
          <div className="margin200">
            <Result
              status="success"
              title="恭喜你，考勤组创建成功！"
              subTitle="考勤组创建完成后，考勤规则立即生效，请告知运维人员按照考勤规则完成打卡。"
              extra={[
                <Button key="buy">
                  <Link to={`/attendanceManagement/details/${finished}`}>
                  查看考勤组详情
                  </Link>
                </Button>,
                <Button type="primary" key="console">
                  <Link to="/attendanceManagement">
                  返回
                  </Link>
                </Button>,
              ]}
            />
          </div>
        ):(<Spin spinning={loading}>
          <div className="bg-gray">
            {current === 0 && (<Form
              className="padding-l-m padding-r-m"
              name="base"
              form={basicInfo}
              layout="vertical"
            >
              <Row gutter={[16, 16]}>
                <Col span={8} offset={4}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: '请输入',
                      },
                    ]}
                    label="考勤组名："
                    name="groupName">
                    <Input placeholder="请输入" maxLength={10}/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ]}
                    label="所属区域："
                    name="area">
                    <Cascader
                      allowClear={false}
                      onChange={onChange}
                      placeholder="请选择"
                      options={areaOptions}
                      displayRender={displayRender}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8} offset={4}>
                  <Form.Item hidden name="areaId">
                    <Input/>
                  </Form.Item>
                  <Form.Item label="区域管理员姓名（自动关联）：" name="areaName">
                    <Input disabled/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item hidden name="streetId">
                    <Input disabled/>
                  </Form.Item>
                  <Form.Item label="街道管理员姓名（自动关联）：" name="streetName">
                    <Input disabled/>
                  </Form.Item>
                </Col>
              </Row>
            </Form>)}
            {current === 1 && (
              <Form name="commentRules" form={commonRule}>
                <div className="padding-l-m padding-r-m">
                  <Alert message="考勤组创建成功后，考勤规则立即生效" type="warning" showIcon/>
                  <Row className="margin-t-l">
                    <Col flex="90px">
                      <h3 >通用规则</h3>
                    </Col>
                    <Col flex="600px">
                      <Form.Item label="考勤日:" name="attendanceDate" rules={[
                        {
                          required: true,
                          message: '请选择考勤日！',
                        },
                      ]}>
                        <Checkbox.Group options={options} className="margin-l-m"/>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item name="isExcludeHolidays">
                        <Checkbox.Group>
                          <Checkbox value={true}>中国节假日不打卡</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <Divider/>
                <Form.List name="addAttendanceRules" rules={[
                  {
                    required: true,
                    message: '请添加考勤时段！',
                  },
                ]}>
                  {(fields, {add, remove}) => (
                    <>
                      <Collapse defaultActiveKey={arr} ghost collapsible="header">
                        {fields.map(({key, name, ...restField}) => (
                          <Collapse.Panel
                            extra={fields.length !== 1 && (<DeleteOutlined
                              className="icon-btn"
                              onClick={() => {
                                remove(name);
                              }}/>)}
                            header={<h3>考勤时段{name+1}
                              <span className="tipInfo margin-l-m">
                                <ExclamationCircleFilled style={{color: '#FAAD14'}} />
                              &nbsp;考勤时段时间不可重叠</span></h3>}
                            key={name}>
                            <Row>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'startTime']}
                                  label="该考勤时段上班时间"
                                  rules={[
                                    {
                                      required: true,
                                      message: '请选择！',
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    format="HH:mm:ss" style={{width: 180}}/>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  label="该考勤时段下班时间"
                                  name={[name, 'endTime']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '请选择！',
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    format="HH:mm:ss" style={{width: 180}}/>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'earliestTime']}
                                  label="最早上班打卡时间"
                                  rules={[
                                    {
                                      required: true,
                                      message: '请选择！',
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    format="HH:mm:ss" style={{width: 180}}/>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  label="最晚下班打卡时间"
                                  name={[name, 'latestTime']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '请选择！',
                                    },
                                  ]}
                                >
                                  <TimePicker format="HH:mm:ss" style={{width: 180}}/>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'address']}
                                  label="打卡地点"
                                  className="padding-r-m"
                                  rules={[
                                    {
                                      required: true,
                                      message: '请设置！',
                                    },
                                  ]}
                                >
                                  <Input.TextArea disabled/>
                                </Form.Item>
                              </Col>
                              <Col span={5}>
                                <Form.Item
                                  {...restField}
                                  label="有效半径"
                                  name={[name, 'radius']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '请设置！',
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    addonAfter="米"
                                    min={1}
                                    disabled/>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Button onClick={() => {
                                  const values = commonRule.getFieldValue('addAttendanceRules')[name];
                                  if (values?.latitudeLongitude) {
                                    setInitLng(values.latitudeLongitude.split(',')[0]);
                                    setInitLat(values.latitudeLongitude.split(',')[1]);
                                    setInitAddress(values.address);
                                    setInitRadius(values.radius);
                                  } else {
                                    setInitLat(null);
                                    setInitAddress(null);
                                    setInitLng(null);
                                    setInitRadius(null);
                                  }
                                  setVisible(true);
                                  setIndex(name);
                                }} type="link">
                                  设置地点
                                </Button>
                                <Form.Item
                                  {...restField}
                                  label="经纬度"
                                  hidden
                                  name={[name, 'latitudeLongitude']}
                                >
                                  <Input/>
                                </Form.Item>
                              </Col>
                            </Row>
                            {name !== (fields.length - 1) && <Divider/>}
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                      <Form.Item className="padding-l-m padding-r-m">
                        <Button type="dashed" size="large" onClick={() => add()} block icon={<PlusOutlined/>}>
                          添加考勤时段
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            )}
            {current === 2 && (<Member
              type="add"
              onChange={setSelectKeys}
              streetId={basicInfo.getFieldValue('streetId')||''}/>)}
          </div>
          <div className="text-right margin-t-l">
            <Space>
              {current !== 0 && (<Button onClick={() => {
                setCurrent(current - 1);
              }}>上一步</Button>)}
              {current !== 2 && (<Button type="primary" onClick={() => {
                if (current === 0) {
                  basicInfo.validateFields().then((values) => {
                    setData({
                      groupBasicInfo: {
                        areaId: values.areaId,
                        groupName: values.groupName,
                        streetId: values.streetId,
                      },
                    });
                    setCurrent(current + 1);
                  });
                } else if (current === 1) {
                  commonRule.validateFields().then((values) => {
                    setData({
                      groupBasicInfo: data.groupBasicInfo,
                      attendanceCommonRule: {
                        attendanceDate: values.attendanceDate,
                        attendanceDayInterval: momentToClock(values.attendanceDayInterval),
                        isExcludeHolidays: values.isExcludeHolidays&&
                        values.isExcludeHolidays.length>0?
                        values.isExcludeHolidays[0]:false,
                      },
                      addAttendanceRules: values.addAttendanceRules.map((item) => ({
                        ...item,
                        startTime: momentToClock(item.startTime),
                        endTime: momentToClock(item.endTime),
                        earliestTime: momentToClock(item.earliestTime),
                        latestTime: momentToClock(item.latestTime),
                      })),
                    });
                    setCurrent(current + 1);
                  }).catch((error) => {
                    if (error.errorFields&&error.errorFields.length>0) {
                      if (error.errorFields[0].name[0] === 'addAttendanceRules' &&
                      error.errorFields[0].errors[0] === '请添加考勤时段！') {
                        message.error('还未配置考勤时段，请先添加考勤时段！');
                      }
                    }
                  });
                }
              }}>下一步</Button>)}
              {current === 2 && (<Button type="primary" onClick={() => {
                const params = {
                  ...data,
                  memberIds: selectKeys,
                };
                setLoading(true);
                addAttendanceGroup(params, 2).then((res) => {
                  setFinished(res.data);
                }).finally(()=>{
                  setLoading(false);
                });
              }}>创建</Button>)}
            </Space>
          </div>
        </Spin>)}
      </Card>
      <Drawer
        title="设置打卡地点"
        placement="right"
        width={768}
        onClose={onClose}
        visible={visible}
        destroyOnClose
        maskClosable={false}
      >
        <SetClockInArea
          onSave={onSave}
          initAddress={initAddress}
          initLat={initLat}
          initLng={initLng}
          initRadius={initRadius}
        />
      </Drawer>
    </div>
  );
};

SettingAttendanceGroup.propTypes = {};

export default SettingAttendanceGroup;
