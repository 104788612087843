import React, {useState} from 'react';
import CustomTable from '../../../components/customTable';
// import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {GETGUARANTEELIST} from '../../../api/Urls';
import {maintainStatus, bikeCompanyOptions, staffStatus, staffStatusText,
  bikeCompany} from '../../../components/statusCollection';
import {timeFormat} from '../../../components/baseTime';
// import CustomImageMin from '../../../components/customImage/CustomImageMin';
import {Image} from 'antd';
import errorImg from '../../../asset/error_min.png';

const MaintainRecord = () => {
  const [updateList] = useState(0);
  const formList= [
    {
      label: '运维人员所属区域',
      key: 'areaCascaderMul',
      col: 8,
      name: 'areaCascaderMul',
      type: 'areaCascaderMul',
    },
    {
      type: 'select',
      key: 'company',
      name: 'company',
      col: 8,
      label: '运维人员所属企业',
      options: bikeCompanyOptions(true),
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '职务类型',
      name: 'jobType',
      key: 'jobType',
      col: 8,
      type: 'select',
      options: [
        {
          value: 1,
          label: '单车人员',
        },
        {
          value: 2,
          label: '单车调运车辆',
        },
        {
          value: 3,
          label: '核验人员',
        },
        {
          value: 4,
          label: '其他',
        },
        {
          value: 5,
          label: '电单车人员',
        },
        {
          value: 6,
          label: '电单车调运车辆',
        },
        {
          value: 7,
          label: '安装人员',
        },
      ],
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      label: '任务完成状态',
      key: 'taskStatus',
      col: 4,
      name: 'taskStatus',
      type: 'select',
      options: [
        {
          value: 1,
          label: '已完成',
        },
        {
          value: 0,
          label: '未完成',
        },
      ],
    },
    {
      label: '定点运维任务开始日期',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'searchContent',
      typeOptions: [
        {label: '姓名', value: 1},
        {label: '任务创建地点', value: 2},
      ],
    },
  ];

  const columns = [
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      onExport: true,
      fixed: 'left',
      width: 100,
    },
    {
      title: '姓名',
      dataIndex: 'operationName',
      key: 'operationName',
      onExport: true,
      width: 100,
    },
    {
      title: '人员所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      // eslint-disable-next-line
      render: (record, item)=>bikeCompany(record),
      exportRender: (record) => bikeCompany(record.company),
      width: 120,
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      render: (jobType) => staffStatus(jobType),
      exportRender: (record) => staffStatusText(record.jobType),
      onExport: true,
      width: 160,
    },
    {
      title: '任务状态',
      dataIndex: 'taskStatus',
      key: 'taskStatus',
      render: (taskStatus)=>maintainStatus(taskStatus),
      exportRender: (record)=>maintainStatus(record.taskStatus),
      onExport: true,
      width: 100,
    },
    {
      title: '任务创建地点',
      dataIndex: 'taskAddress',
      key: 'taskAddress',
      onExport: true,
      width: 200,
    },
    {
      title: '运维前图片',
      dataIndex: 'firstGuaranteeImage',
      key: 'firstGuaranteeImage',
      onExport: true,
      // eslint-disable-next-line
      render: (firstGuaranteeImage)=>{
        return <Image.PreviewGroup
        >
          {
            firstGuaranteeImage?firstGuaranteeImage.split(';').map((item, index)=>{
              return <div key={item} style={index!==0?{display: 'none'}:{display: 'block'}}><Image
                width={72} height={40} fallback={errorImg}
                src={item||errorImg} /></div>;
            }):<Image fallback={errorImg}src={''} />
          }
        </Image.PreviewGroup>;
      },
      width: 130,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime)=>timeFormat(createTime),
      exportRender: (record) =>timeFormat(record.createTime),
      // sorter: true,
      onExport: true,
      width: 180,
      sorter: true,
    },
    {
      title: '运维后图片',
      dataIndex: 'lastGuaranteeImage',
      key: 'lastGuaranteeImage',
      onExport: true,
      // eslint-disable-next-line
      render: (lastGuaranteeImage)=>{
        return <Image.PreviewGroup
        >
          {
            lastGuaranteeImage?lastGuaranteeImage.split(';').map((item, index)=>{
              return <div key={item} style={index!==0?{display: 'none'}:{display: 'block'}}><Image
                width={72} height={40} fallback={errorImg}
                src={item||errorImg} /></div>;
            }):<Image fallback={errorImg}src={''} />
          }
        </Image.PreviewGroup>;
      },
      width: 130,
    },
    {
      title: '完成时间',
      dataIndex: 'completeTime',
      key: 'completeTime',
      width: 180,
      onExport: true,
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record) => (
        <Link to={`details/${record.id}/${record.operationsStaffId}`}>详情</Link>
      ),
    },
  ];


  return (
    <CustomTable
      InitApi={GETGUARANTEELIST}
      formList={formList}
      updateList={updateList}
      columns={columns}
      resType="POST"
      scroll={true}
      tip="定点运维记录导出中..."
      exportFileName="定点运维记录"
    />
  );
};

MaintainRecord.propTypes = {};

export default MaintainRecord;
