import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Statistic } from 'antd';
// import { toThousands } from '../../../../utils/dataFormat';
import { filingsDataTitle } from '../../../../components/statusCollection';
import CountUp from 'react-countup';
const formatter = (value) => <CountUp end={value} separator="," decimals={Number.isInteger(value)?0:2} />;
const countStyle = {
  fontSize: 24,
  // background: 'linear-gradient(90deg, #F6FCFF 0%, #50CBFF 100%)',
  backgroundImage: 'linear-gradient(to bottom, #F6FCFF, #50CBFF)',
  backgroundClip: 'text',
  color: 'transparent',
  'WebkitBackgroundClip': 'text',
  'WebkitTextFillColor': 'transparent',
  lineHeight: '28px',
  fontFamily: 'noDIN Pro, DIN Prormal',
  fontWeight: 500
}


const Statistics = ({ statisticsData, bikeType }) => {
  const [filingsTitle, setFilingsTitle] = useState('');
  const [isAreaName, setIsAreaName] = useState('');
  const [cityLength, setCityLength] = useState('');
  const CountUp = (value, suffix) => {
    return <Statistic value={value} formatter={formatter} valueStyle={countStyle} suffix={suffix} />
  }
  useEffect(() => {
    const areaName = JSON.parse(localStorage.getItem('myInfo'))?.areaName || '无区域';
    // const areaName = '成都市成华区测试街道/德源街道';
    if (areaName !== '全部区' && areaName !== '成都市全部区') {
      if (areaName.indexOf('街道') !== -1 || areaName.indexOf('镇') !== -1) setCityLength(1);
      else {
        const cityList = areaName.slice(areaName.indexOf('市') + 1).split('/');
        setCityLength(cityList.length);
      }
    }
    setIsAreaName(areaName);
    setFilingsTitle(filingsDataTitle(areaName));
  }, []);

  return (

    <div className="padding-l-m" style={{ padding: `${24/devicePixelRatio}px ${48/devicePixelRatio}px ${30/devicePixelRatio}px` }}>
      <Row gutter={[4/devicePixelRatio, 16/devicePixelRatio]} >
        {cityLength !== 1 && <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            {/* 全市备案{bikeType === 2 && '电'}单车总数 */}
            全市备案总数
          </div>
          <div className="statistics-data-pilot">
            {/* {toThousands(statisticsData ? statisticsData.allFiledNum : '--')} */}
            {statisticsData ? CountUp(statisticsData.allFiledNum) : '--'}
          </div>
        </Col>}
        {(isAreaName !== '全部区' && isAreaName !== '成都市全部区') && <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            {/* 区域备案{bikeType===2&&'电'}单车数 */}
            {filingsTitle}
          </div>
          <div className="statistics-data-pilot">
            {/* {toThousands(statisticsData?statisticsData.filedNum:'--')} */}
            {statisticsData ? CountUp(statisticsData.filedNum) : '--'}
          </div>
        </Col>}
        <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            区域监测数
          </div>
          <div className="statistics-data-pilot">
            {/* {toThousands(statisticsData?statisticsData.areaMonitorNum:'--')} */}
            {statisticsData ? CountUp(statisticsData.areaMonitorNum) : '--'}
          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            监测未备案数
          </div>
          <div className="statistics-data-pilot">
            {/* {toThousands(statisticsData ? statisticsData.monitorNotFiledNum : '--')} */}
            {statisticsData ? CountUp(statisticsData.monitorNotFiledNum) : '--'}
          </div>
        </Col>

        <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            监测跨区域数
          </div>
          <div className="statistics-data-pilot">
            {/* {toThousands(statisticsData ? statisticsData.monitorCrossAreaNum : '--')} */}
            {statisticsData ? CountUp(statisticsData.monitorCrossAreaNum) : '--'}
          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            跨区域率
          </div>
          <div className="statistics-data-pilot">
            {/* {statisticsData ? statisticsData.crossRegionalPercent : '--'} */}
            {statisticsData ? CountUp(statisticsData.crossRegionalPercent?.replace('%',''), '%') : '--'}
          </div>
        </Col>
        <Col span={6} className="">
          <div className="device-statistics-title-pilot">
            备案率
          </div>
          <div className="statistics-data-pilot">
            {/* {statisticsData ? statisticsData.filedPercent : '--'} */}
            {statisticsData ? CountUp(statisticsData.filedPercent?.replace('%',''), '%') : '--'}
          </div>
        </Col>
      </Row>
    </div>

  );
};

Statistics.propTypes = {
  statisticsData: PropTypes.object,
  bikeType: PropTypes.number.isRequired,
};

export default Statistics;

