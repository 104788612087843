import {Tabs} from 'antd';
import {React} from 'react';
import PropTypes from 'prop-types';
// title:页面标题
const SubTitleCustom = ({title, tab, onChangeTab, tabKey}) => {
  return (
    <div>
      <div className="statement-title">
        <h2>{title}</h2>
        <div>
        </div>
      </div>
      <div className="statement-Tabs">
        <Tabs
          defaultActiveKey={tabKey.toString()}
          onChange={(key) => {
            onChangeTab(key);
          }}
        >
          {tab.map((item, index)=>{
            return <Tabs.TabPane tab={item} key={index}></Tabs.TabPane>;
          })}
        </Tabs>
      </div>
    </div>
  );
};
SubTitleCustom.propTypes = {
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  tab: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  exportBtn: PropTypes.string,
  onExport: PropTypes.func,
  tabKey: PropTypes.number||PropTypes.string,
};

export default SubTitleCustom;
