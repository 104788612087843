export const areaStreetTreeData=(id, data, type)=>{
  if (!id) {
    return null;
  }
  if (type==='street'||type==='search'||type==='searchAll') {
    let streetIds=[];
    id.forEach((element) => {
      const arr=data.find(({id})=>id===element[0]);
      if (element.length===2) {
        const arr2=arr.children.find(({id})=>id===element[1]);
        arr2.children.forEach((item)=>{
          streetIds.push(item.id);
        });
        if (type==='searchAll') {
          if (element.length===2) {
            streetIds=[...streetIds, element[1]];
          } else {
            streetIds.push(element[0]);
          }
        }
      } else if (element.length===3) {
        streetIds.push(element[2]);
      } else {
        if (type==='searchAll') {
          streetIds.push(arr.id);
        }
        arr.children.forEach((item)=>{
          streetIds.push(item.id);
          if (item.children.length>0) {
            item.children.forEach((sub)=>{
              streetIds.push(sub.id);
            });
          }
        });
      }
    });
    return (streetIds);
  } else {
    return id.map((item)=>(item[0]));
  }
};
