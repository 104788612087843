import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import AMapLoader from '@amap/amap-jsapi-loader';
import {Button, Col, Form, Input, InputNumber, message, Row, Spin} from 'antd';
import {AMapKey} from '../../../components/aMap/aMapAPI';

let AMaps;
let circle;
let circleEditor;
const SetClockInArea = ({
  onSave,
  initLat,
  initLng,
  initAddress,
  initRadius,
}) => {
  const mapRef= useRef();
  const [lat, setLat]=useState(initLat||null);
  const [lng, setLng] = useState(initLng||null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(initLng&&initLng);
  const [addressForm] = Form.useForm();
  const mapInit=()=>{
    setLoading(true);
    AMapLoader.load({
      ...AMapKey,
      plugins: ['AMap.ToolBar', 'AMap.Circle', 'AMap.CircleEditor', 'AMap.PlaceSearch', 'AMap.AutoComplete'],
      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap)=> {
      mapRef.current.map = new AMap.Map('container', { // 设置地图容器id
        resizeEnable: true,
      });
      mapRef.current.map.addControl(new AMap.ToolBar());

      const autoOptions = {
        input: 'tipInput',
        city: '成都',
      };
      AMaps = AMap;
      AMap.plugin(['AMap.AutoComplete'], () => {
        const auto = new AMap.AutoComplete(autoOptions);
        auto.on('select', (e) => {
          const {lat} = e.poi.location;
          const {lng} = e.poi.location;
          if (lat && lng) {
            setLng(lng);
            setLat(lat);
            addressForm.setFieldsValue({
              address: e.poi.name,
              radius: addressForm.getFieldValue('radius')||300,
            });
            circleDraw(lng, lat, addressForm.getFieldValue('radius')||300);
          } else {
            message.error('输入地点有误，未匹配到相关地址，请检查后再输入！');
          }
        });// 注册监听，当选中某条记录时会触发
        auto.on('complete', (e) => {
          if (circle) {
            mapRef.current.map.remove(circle);
          }
          if (circleEditor) {
            circleEditor.close();
            mapRef.current.map.remove(circleEditor);
          }

          setLng(null);
          setLat(null);
        });// 注册监听，当选中某条记录时会触发
      });
    }).finally(()=>{
      setLoading(false);
    });
  };
  const circleDraw=(g, t, r, edit)=>{
    if (circle) {
      mapRef.current.map.remove(circle);
    }
    if (circleEditor) {
      circleEditor.close();
      mapRef.current.map.remove(circleEditor);
    }
    if (g) {
      circle = new AMaps.Circle({
        center: new AMaps.LngLat(g, t), // 圆心位置
        radius: r, // 圆半径
        borderWeight: 2,
        strokeColor: '#0B91AE',
        strokeWeight: 2,
        fillOpacity: 0.2,
        strokeStyle: 'dashed',
        strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: '#80D1FF',
        zIndex: 50,
        strokeOpacity: 0.2,
        move: false,
      });
      mapRef.current.map.add(circle);
      mapRef.current.map.setFitView([circle]);
      circleEditor = new AMaps.CircleEditor(mapRef.current.map, circle);
      circleEditor.on('move', function(event) {
        setLat(event.lnglat.lat);
        setLng(event.lnglat.lng);
      });
      circleEditor.on('adjust', function(event) {
        addressForm.setFieldsValue({
          radius: event.radius,
        });
      });
      if (edit) {
        circleEditor.close();
      } else {
        circleEditor.open();
      }
    }
  };

  useEffect(()=>{
    mapInit();
  }, []);

  useEffect(()=>{
    if (!loading) {
      if (initLng) {
        circleDraw(initLng, initLat, initRadius, true);
      }
    }
  }, [loading, initAddress]);

  return (
    <div>
      <Form name="Address" form={addressForm} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="address"
              label={<span>考勤地址<span className="text-light">（请选择搜索结果中地点）：</span>
              </span>} initialValue={initAddress}>
              <Input id="tipInput" disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name="radius" label="有效范围半径：" initialValue={initRadius}>
              <InputNumber
                addonAfter="米"
                min={1}
                style={{width: '100%'}}
                disabled={disabled}
                onBlur={(e)=>{
                  if (circleEditor) {
                    circleEditor.close();
                    mapRef.current.map.remove(circle);
                    mapRef.current.map.remove(circleEditor);
                  }
                  if (parseInt(e.target.value)) {
                    circleDraw(lng, lat, parseInt(e.target.value));
                  }
                }}
                onPressEnter={(e)=>{
                  if (circleEditor) {
                    circleEditor.close();
                    mapRef.current.map.remove(circle);
                    mapRef.current.map.remove(circleEditor);
                  }

                  if (parseInt(e.target.value)) {
                    circleDraw(lng, lat, parseInt(e.target.value));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label=" ">
              {disabled?( <Button type="primary" onClick={()=> {
                circleEditor.open();
                setDisabled(false);
              }} block>重置</Button>):( <Button
                type="primary"
                disabled={!lat}
                onClick={()=>
                  onSave({
                    ...addressForm.getFieldsValue(),
                    latitudeLongitude: `${lng},${lat}`,
                  })
                } block>保存</Button>)}

            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Spin spinning={loading}>
        <div ref={mapRef} id="container" className="map" style={{height: 600, width: '100%'}} />
      </Spin>
    </div>
  );
};

SetClockInArea.propTypes = {
  onSave: PropTypes.func.isRequired,
  initLat: PropTypes.number,
  initLng: PropTypes.number,
  initAddress: PropTypes.string,
  initRadius: PropTypes.number,
};

export default SetClockInArea;
