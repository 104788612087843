import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getStaffInfoData} from '../../api';
import {Col, Row, Skeleton} from 'antd';
import {staffStatus, bikeCompany} from '../statusCollection';

const StaffInfo = ({userID, showId, area, carCard, tabsKey, getInfo}) => {
  const [baseInfo, setBaseInfo] = useState({status: 3});
  const [loading, setLoading] = useState(false);
  const getInitData = () => {
    setLoading(true);
    getStaffInfoData(userID, 2).then((res) => {
      setBaseInfo(res.data||{});
      getInfo&&getInfo(res.data);
    }).finally(() => {
      setLoading(false);
    });
  };
  useEffect(()=>{
    getInitData();
  }, []);
  return (
    <div id="staff-info">
      <h3 className="details-title">
        人员信息
      </h3>
      {loading?<Skeleton/>:(<div className="details-info margin-t-l">
        <Row className="margin-b-l" gutter={24}>
          <Col span={7}>
            <span>
              姓名：
            </span>
            <span className="text-gray"> {baseInfo.name ||'暂无数据'}</span>
          </Col>
          <Col span={7}>
            <span>
              工号：
            </span>
            <span className="text-gray">{baseInfo.jobNumber||'暂无数据'}</span>
          </Col>
          <Col span={10}>
            <span>
              所属区域：
            </span>
            <span className="text-gray">{area?area:(baseInfo.areaName||'无区域')}</span>
          </Col>
        </Row>
        <Row className="margin-b-l" gutter={24}>
          <Col span={7}>
            <span>
              联系电话：
            </span>
            <span className="text-gray">{baseInfo.phoneNumber||'暂无数据'}</span>
          </Col>
          <Col span={showId?7:0}>
            <span>
              身份证号：
            </span>
            <span className="text-gray">{baseInfo.idCard ||'暂无数据'}</span>
          </Col>
          <Col span={7}>
            <span>职务类型：</span>
            <span className="text-gray">{staffStatus(baseInfo.jobType)}</span>
          </Col>
          <Col span={10}>
            <span>所属企业：</span>
            <span className="text-gray">{bikeCompany(baseInfo && baseInfo.company)}</span>
          </Col>
        </Row>
        {carCard&&(baseInfo.jobType===2 || baseInfo.jobType===6)&&
        <Row className="margin-b-l" gutter={24}>
          <Col span={8}>
                车牌号：<span className="text-gray">{baseInfo && baseInfo.licensePlate ||
                ('暂无数据')}</span>
          </Col>
        </Row>}
      </div>)}
    </div>
  );
};

StaffInfo.propTypes = {
  userID: PropTypes.any.isRequired,
  showId: PropTypes.bool,
  area: PropTypes.string,
  carCard: PropTypes.bool,
  tabsKey: PropTypes.any,
  getInfo: PropTypes.func,
};

export default StaffInfo;
