import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, Form, Input, message, Row, Space, Spin, Modal} from 'antd';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';
import './mapStyle.css';
import {getMapZoomCenter} from '../../api';
import {zoomLevel} from '../statusCollection';

let AMaps;
let polyEditor;
const autoOptions = {
  input: 'tipInput',
  city: '成都',
};

const PolyEdit =({
  onSave,
  initAddress,
  initCenter,
  polyPath,
  initArea,
  hiddenMaker,
})=> {
  const mapRef= useRef();
  const [addressForm] = Form.useForm();
  const [path, setPath]=useState(null);
  const [loading, setLoading] = useState(false);
  const [poly, setPoly] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [zoneNames, setZoneNames] = useState([]);
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState(null);
  const clearMarker=(value)=>{
    const overlays = mapRef.current?.map?.getAllOverlays('marker');
    if (overlays?.length>0) {
      // console.log(overlays);
      mapRef.current.map.remove(overlays);
    }
    if (value) {
      setAddress(value);
    }
  };

  const addNewPoly=()=> {
    //  clearMarker();
    if (poly) {
      mapRef.current.map.remove(poly);
    }
    polyEditor.close();
    polyEditor.setTarget();
    polyEditor.open();
    setPath(null);
    setIsDrawing(true);
  };
  const editPoly=()=> {
    // console.log(polyEditor);
    polyEditor.open();
    setIsDrawing(true);
  };
  const closePoly=()=> {
    polyEditor.close();
    setIsDrawing(false);
  };
  const onFinish=()=>{
    onSave({
      path,
      adName: zoneNames,
      initCenter: position||[],
      initAddress: address||'',
    });
  };

  const mapInit=async ()=>{
    let center=[104.065861, 30.657401];
    let zoom=13;
    const res= await getMapZoomCenter(2);
    if (res.data.latitudeLongitude) {
      center=res.data.latitudeLongitude.split(',');
    }
    zoom=zoomLevel(res.data.level);
    AMapLoader.load({
      ...AMapKey,
      plugins: ['AMap.ToolBar', 'AMap.PolygonEditor', 'AMap.MouseTool', 'AMap.GeometryUtil'],
      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap)=> {
      mapRef.current.map = new AMap.Map('container', { // 设置地图容器id
        resizeEnable: true,
        center,
        // mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
        zoom,
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      if (hiddenMaker) {
        // 隐藏标记
        if (initCenter) {
          mapRef.current.map.setCenter(initCenter);
          setPosition( initCenter);
          setAddress(initAddress);
        }
      } else {
        if (initCenter) {
          const initMarker = new AMap.Marker({
            position: initCenter,
            offset: new AMap.Pixel(-13, -30),
            clickable: true,
          });
          mapRef.current.map.add(initMarker);
          mapRef.current.map.setCenter(initCenter);
          setPosition( initCenter);
          setAddress(initAddress);
        }
      }
      AMap.plugin(['AMap.AutoComplete'], () => {
        const auto = new AMap.AutoComplete(autoOptions);
        auto.on('select', (e) => {
          const {lat} = e.poi.location;
          const {lng} = e.poi.location;
          if (lat && lng) {
            const marker = new AMap.Marker({
              position: [lng, lat],
              // 将 html 传给 content
              // content: markerContent(point),
              // 以 icon 的 [center bottom] 为原点
              offset: new AMap.Pixel(-13, -30),
              clickable: true,
            });
            // 将 markers 添加到地图
            mapRef.current.map.add(marker);
            mapRef.current.map.setCenter([lng, lat]);
            setPosition( [lng, lat]);
            // editPoly();
            // circleDraw(lng, lat, addressForm.getFieldValue('radius')||300);
          } else {
            message.error('输入地点有误，未匹配到相关地址，请检查后再输入！');
          }
        });// 注册监听，当选中某条记录时会触发
        auto.on('complete', (e) => {
          // if (poly) {
          //   mapRef.current.map.remove(poly);
          // }
          // if (polyEditor) {
          //   polyEditor.close();
          //   mapRef.current.map.remove(polyEditor);
          // }
        });// 注册监听，当选中某条记录时会触发
      });
      // 载入绘制；
      AMaps= AMap;
      let polygon;
      if (polyPath) {
        polygon = new AMap.Polygon({
          fillColor: '#ff7875', // 多边形填充颜色
          strokeColor: '#ff7875', // 线条颜色
          path: polyPath,
        });
        mapRef.current.map.add(polygon);
        setPoly(polygon);
      }
      polyEditor = new AMaps.PolygonEditor(mapRef.current.map, poly, {
        createOptions: {
          fillColor: '#ff7875', // 多边形填充颜色
          strokeColor: '#ff7875', // 线条颜色
        },
        editOptions: {
          fillColor: '#ff7875', // 多边形填充颜色
          strokeColor: '#ff7875', // 线条颜色
        },
      });

      if (polyPath) {
        polyEditor.addAdsorbPolygons(polygon);
        polyEditor.setTarget(polygon);
      }
      polyEditor.on('add', function(data) {
        const polygon = data.target;
        setPoly(polygon);
        polyEditor.addAdsorbPolygons(polygon);
      });
      polyEditor.on('end', function(data) {
        const Path=data.target.getPath().map((item)=>item.toArray());
        setPath(Path);
        const params={
          key: '933769504777f84930c18c160ef60a12',
          polygon: Path.map((item)=>item.join(',')).join('|'),
        };
        let url='https://restapi.amap.com/v3/place/polygon?';
        // use aMap API
        Object.keys(params).forEach((key) => {
          url+=key+'='+params[key]+'&';
        });

        url+='types=190000|130000&page_size=10000';

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
              const arr=[];
              data.pois.forEach(({adname})=>{
                if (!arr.find((item)=>item===adname)) {
                  arr.push(adname);
                }
              });
              setZoneNames(arr);
            });
      });
      polyEditor.on('removenode', function(data) {
        if (data.target.getPath().length===0) {
          polyEditor.close();
          // polyEditor.setTarget();
          setTimeout(()=>{
            polyEditor.open();
          }, 1000);
        }
      });
      setLoading(false);
      // addNewPoly();
      // demo();
    });
  };
  const confirm = () => {
    Modal.confirm({
      title: '重置保障区域',
      // icon: <ExclamationCircleOutlined />,
      content: '是否确认清除已绘制的保障区域？',
      okText: '确认',
      cancelText: '取消',
      onOk: ()=> addNewPoly(),
    });
  };
  useEffect(()=>{
    // if (initAddress) {
    //   addressForm.setFieldsValue({address: initAddress});
    // }
    if (polyPath) {
      setPath(polyPath);
      setZoneNames(initArea);
    }
    mapInit();
  }, [polyPath]);
  return (
    <Spin spinning={loading}>
      <Form name="Address" form={addressForm} layout="vertical">
        <Row className="margin-b-s">
          <Col span={8}>
            <Form.Item name="address"
              label="保障区域地图:"
              initialValue={initAddress}
            >
              <Input id="tipInput" allowClear placeholder="请输入搜索地点（请选择搜索结果中的地点）"
                onChange={(e)=>{
                  // if (e.target.value.length===0) {
                  clearMarker(e.target.value);
                  // }
                }} />
            </Form.Item>
          </Col>
          <Col span={8} className="padding-l-m ">
            <Form.Item label=" ">
              <Space>
                {/* <Button onClick={addNewPoly} disabled={loading}>新增多边形</Button> */}

                {!isDrawing&&(<Button
                  type="primary"
                  onClick={editPoly} disabled={isDrawing}>
                开始绘制
                </Button>)}

                {(!isDrawing&&path)&&( <Button
                  disabled={isDrawing}
                  onClick={confirm}
                >重置</Button>)}
                { isDrawing&&(<Button onClick={closePoly} ghost disabled={!isDrawing} type="primary">结束绘制</Button>)}
              </Space>
            </Form.Item>
          </Col>
        </Row>


      </Form>

      {isDrawing&&<Alert
        type="info" showIcon
        message="单击左键添加点，右键结束。结束后左键移动点，或左键单击白色点消除。完成后请点击【结束绘制】按钮。"
      />}
      <div ref={mapRef} id="container" className="guarantee-edit-map" />
      <div className="text-center">
        <Button onClick={onFinish} disabled={isDrawing|| !path } type="primary">下一步</Button>
      </div>
    </Spin>
  );
};

PolyEdit.propTypes ={
  // 下一步func
  onSave: PropTypes.func,
  // 查询地址
  initAddress: PropTypes.string,
  // 查询坐标
  initCenter: PropTypes.array,
  // 已绘制多边形
  polyPath: PropTypes.array,
  // 所属区域
  initArea: PropTypes.array,
  hiddenMaker: PropTypes.bool,
};

export default PolyEdit;
