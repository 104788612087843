import React, {useEffect, useState} from 'react';
import SubTitle from '../../../components/subtitle/SubTitle';
// import {Anchor, Row, Col, Space, Button} from 'antd';
import {Anchor, Col} from 'antd';
import LineGraph from '../components/LineGraph';
import ListDetail from '../components/ListDetail';
import AttendanceStatistics from './components/AttendanceStatistics';
import {getTrend} from '../../../api/index';
import {ATTENDANCETREND} from '../../../api/Urls';
import {PERSONNELLIST} from '../../../api/Urls';
import {bikeCompany} from '../../../components/statusCollection';
import '../style.css';

const BikePersonDataStatistics = () => {
  const [menuTab, setMenuTab] = useState(1);
  const [chartTime, setChartTime] = useState();
  const roleName = JSON.parse(localStorage.getItem('userInfo'))?.roleName;
  const [filedData, setFiledData] = useState();
  const [incomeDate, setIncomeDate] = useState();
  const bikeEnterprise = JSON.parse(localStorage.getItem('bikeEnterprise')).bikeEnterprise;

  const formListStaff = [
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'content',
      typeOptions: [
        {label: '运维人员姓名', value: 1},
        {label: '联系电话', value: 2},
      ],
    },
    {
      label: '人员所属企业',
      key: 'bikeType',
      col: 6,
      name: 'companyList',
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      options: [
        {label: '美团', value: 1},
        {label: '青桔', value: 2},
        {label: '哈啰', value: 3},
      ],
    },
    {
      label: '人员所属区域',
      key: 'areaList',
      col: 6,
      name: 'areaList',
      type: 'areaCascaderMul',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
  ];

  const columnsStaff = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
    },
    {
      title: '所属企业',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      onExport: true,
      render: (bikeEnterprise) => <span>{bikeCompany(bikeEnterprise)}</span>,
      exportRender: (render) => bikeCompany(render.bikeEnterprise),
    },
    {
      title: '联系电话',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      onExport: true,
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      render: (areaName) => <span>{areaName || '无区域'}</span>,
      exportRender: (render) => render.areaName || '无区域',
    },
    {
      title: '考勤组',
      dataIndex: 'groupName',
      key: 'groupName',
      onExport: true,
    },
  ];
  // 调api接口数据数据处理
  const disposeApiData = (tab, date) => {
    const obj = {
      apiType: tab === 1 ? 1 : 2,
    };
    if (date) {
      obj.startDate = date.startTime;
      obj.endDate = date.endTime;
    }
    return obj;
  };
  const bikeType = (type) => {
    switch (type) {
      case 0:
        return '全部';
      case 1:
        return '美团';
      case 2:
        return '青桔';
      case 3:
        return '哈啰';
      default:
        return '_';
    }
  };
  // data管辖区域处理
  const statisticData = (value) => {
    let temp = [];
    if (bikeEnterprise.indexOf(1) !== -1 && bikeEnterprise.indexOf(2) !== -1 && bikeEnterprise.indexOf(3) !== -1) {
      // temp=[...value];
      const res = value.filter((i) => i.bikeEnterprise < 4);
      temp=[...res];
    } else {
      for (const index of bikeEnterprise) {
        const res = value.find((i) => i.bikeEnterprise === index&&i.bikeEnterprise<4);
        if (res) temp.push(res);
      }
    }
    return temp;
  };
  // 折线图数据处理
  const disposeDate = (data) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const type = bikeType(data[i].bikeEnterprise);
      data[i]?.trend.map((item, index) => {
        arr.push(Object.assign(item, {type: type}));
      });
    }
    return arr;
  };
  // 出勤数趋势
  const attendanceTrend = (tab, data) => {
    setFiledData([]);
    const date = disposeApiData(tab, data);
    // console.log(date);
    getTrend(`${ATTENDANCETREND}1`, date, 2)
        .then((res) => {
          const resp=statisticData(res.data);
          const result = disposeDate(resp);
          setFiledData(result);
          const income = result[result.length - 1]?.date;
          setIncomeDate(income);
        })
        .catch((error) => {
          // setFiledData(dataMock);
        });
  };

  useEffect(() => {
    attendanceTrend(menuTab);
  }, []);

  return (
    <div>
      {/* 标题切换 */}
      <SubTitle
        title={`单车人员数据统计`}
        tab={[`出勤数（人员）`, `出勤数（车辆）`]}
        onChangeTab={(key) => {
          const tab = Number(key) + 1;
          setMenuTab(tab);
          attendanceTrend(tab);
          setChartTime();
        }}
      />
      <div className="entirety-person-statistics">
        {/* 单车出勤数趋势 */}
        <Col span={22} id="attendance-trend">
          <LineGraph
            filedData={filedData}
            promptTitle={menuTab === 1 ? '单车人员出勤数趋势' : '单车调运车辆出勤数趋势'}
            timeReset={() => attendanceTrend(menuTab)} // 时间重置
            timeSearch={(value) => {
              attendanceTrend(menuTab, value);
            }} // 时间搜索
            chartTime={(val) => setChartTime(val)} // 获取折线图点击时间
            menuTab={menuTab}
            incomeDate={incomeDate}
          />
        </Col>

        {/* 单车出勤数统计 */}
        <Col span={22} id="attendance-statistics">
          <AttendanceStatistics menuTab={menuTab} chartTime={chartTime} incomeDate={incomeDate} />
        </Col>

        {/* 单车出勤列表 */}
        {(roleName === '超级管理员'||roleName ==='超级管理') && (
          <Col span={22} id="attendance-list">
            <ListDetail
              initApi={`${PERSONNELLIST}1`}
              type="staff"
              formList={formListStaff}
              columns={columnsStaff}
              listTitle={`最近一日单车${menuTab === 1 ? '人员' : '调运车辆'}出勤列表`}
              menuTab={menuTab}
              apiType={{apiType: menuTab}}
              exportTime={true}
              statistics={'列表人员数'}
            />
          </Col>
        )}
      </div>

      <Anchor className="person-statistics-anchor">
        <Anchor.Link href="#attendance-trend" title="出勤趋势" />
        <Anchor.Link href="#attendance-statistics" title="出勤统计" />
        {roleName === '超级管理员' && <Anchor.Link href="#attendance-list" title="出勤列表" />}
      </Anchor>
    </div>
  );
};
export default BikePersonDataStatistics;
