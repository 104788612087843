import React from 'react';
import {InfoCircleOutlined} from '@ant-design/icons/lib/icons';
import PieCharts from './PieCharts';
import PropTypes from 'prop-types';
import BarGraph from './BarGraph';
import {Row, Col} from 'antd';
import {description, pieTitle, barTitle} from './IndicatorDescription';

const DataStatistics = ({title, pieData, barData, vehicleType, tabsKey}) => {
  const company = ['美团', '青桔', '哈啰'];
  return (
    <div className="dataStatistics-content">
      <div className="dataStatistics-hint">
        <InfoCircleOutlined className="dataStatistics-icon" />
        {description(title, vehicleType, tabsKey)}
      </div>
      <div className="dataStatistics-target-table">
        <Row gutter={[64, 16]}>
          {(tabsKey === 1 || title === '重点工作区域保障') && (
            <Col>
              <div>
                <p>{pieTitle(title, vehicleType)} </p>
                <div className="pie-charts-collect">
                  <PieCharts
                    data={pieData}
                    title={pieTitle(title, vehicleType)}
                    dataType={title === '重点工作区域保障' ? 'percent' : 'value'}
                  ></PieCharts>
                </div>
              </div>
            </Col>
          )}
          <Col span={12} className={(tabsKey === 1 || title === '重点工作区域保障') && 'bar-left'}>
            <div>
              <p>{barTitle(title, vehicleType)}</p>
              <div className="dot-chart">
                {company.map((item, index) => {
                  return (
                    <div key={index} className="dot-title">
                      <div
                        className={
                          index === 0 ?
                            'card-dot card-dot-M' :
                            index === 2 ?
                            'card-dot card-dot-H' :
                            'card-dot card-dot-Q'
                        }
                      ></div>
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
              <div className="bar-graph">
                <BarGraph data={barData} barType={title} title={barTitle(title, vehicleType)}
                  dataType={title === '重点工作区域保障' ? 'value':'percent'}></BarGraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

DataStatistics.propTypes = {
  title: PropTypes.string.isRequired,
  pieData: PropTypes.array.isRequired,
  barData: PropTypes.array.isRequired,
  vehicleType: PropTypes.string,
  tabsKey: PropTypes.number,
};

export default DataStatistics;
