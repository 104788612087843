
export const toThousands = (num) => `${num}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);

export const toBigNumUnit = (num) => {
  const unit = num >= 10000 ? `${(num / 10000)} W` :
    num >= 1000 ? `${(num / 1000)} K` : num;
  return unit;
};
export const toBigChartNumUnit = (num) => {
  const unit = num >= 10000 ? `${(num / 10000).toFixed(1)} W` :
    num >= 1000 ? `${(num / 1000).toFixed(1)} K` : parseInt(num);
  return unit;
};

export const getSize = (size) => {
  return size / devicePixelRatio
}

export const getHeightSize = (size) => {
  // return size / devicePixelRatio
  return size /1080 *100 + 'vh'
}