

/**
   * WebSocketClient class for creating and managing WebSocket connections.
   * @class
   * @param {string} url - The WebSocket server URL.
   * @param {string|string[]} protocols - The WebSocket protocols to use.
   */
class WebSocketClient {
  /**
       * Creates a new instance of the WebSocketClient class.
       * @constructor
       * @param {string} url - The WebSocket server URL.
       * @param {string|string[]} protocols - The WebSocket protocols to use.
       */
  constructor(url, protocols) {
    /**
         * The WebSocket connection object.
         * @type {WebSocket|null}
         */
    this.socket = null;

    /**
         * The callback function to be called when a message is received.
         * @type {function|undefined}
         */
    this.onMessageCallback = undefined;

    /**
         * The callback function to be called when the WebSocket connection is opened.
         * @type {function|undefined}
         */
    this.onOpenCallback = undefined;

    /**
         * The callback function to be called when the WebSocket connection is closed.
         * @type {function|undefined}
         */
    this.onCloseCallback = undefined;

    /**
         * The callback function to be called when an error occurs with the WebSocket connection.
         * @type {function|undefined}
         */
    this.onErrorCallback = undefined;

    /**
         * The interval (in milliseconds) to wait before attempting to reconnect to the WebSocket server.
         * @type {number}
         */
    this.reconnectInterval = 2 * 1000;

    /**
         * The number of times the WebSocket client has attempted to reconnect to the server.
         * @type {number}
         */
    this.reconnectAttempts = 0;

    /**
         * The maximum number of times the WebSocket client should attempt to reconnect to the server.
         * @type {number}
         */
    this.maxReconnectAttempts = 10;

    /**
         * The WebSocket server URL.
         * @type {string}
         */
    this.url = url;

    /**
         * The WebSocket protocols to use.
         * @type {string|string[]}
         */
    this.protocols = protocols;

    this.shouldReconnect = true;

    this.heartPeriod = 10 * 1000; // 10s发送一个心跳包，浏览器默认1分钟不发消息就会断开,但是后端设置的心跳频率估计默认是25s
  }
  /**
     * Connects to the WebSocket server.
     */
  connect() {
    console.log('WebSocket connecting...', new Date());

    this.socket = new WebSocket(this.url, this.protocols);

    this.socket.onopen = () => {
      console.log('WebSocket connected', new Date());
      this.reconnectAttempts = 0;
      // 开始心跳
      this.intervalId = setInterval(() => {
        this.onHeartbeat();
      }, this.heartPeriod);
      if (this.onOpenCallback) {
        this.onOpenCallback();
      }
    };

    this.socket.onmessage = (event) => {
      if (event.data === 'PONG') {
        return;
      }
      if (this.onMessageCallback) {
        this.onMessageCallback(event);
      }
    };

    this.socket.onclose = (event) => {
      console.log(`WebSocket closed with code ${event.code} and reason ${event.reason} ${new Date()}` );
      // 停止心跳
      clearInterval(this.intervalId);
      if (this.onCloseCallback) {
        this.onCloseCallback(event);
      }
      this.reconnect();
    };

    this.socket.onerror = (event) => {
      console.error('WebSocket error:', event, new Date());
      if (this.onErrorCallback) {
        this.onErrorCallback(event);
      }
      // this.reconnect();
    };
  }
  /**
     * Disconnects from the WebSocket server.
     */
  disconnect() {
    if (this.socket) {
      this.socket.close();
      console.log('WebSocket disconnected');
      this.shouldReconnect = false;
    }
  }

  /**
     * Sets the callback function to be called when the WebSocket connection is opened.
     * @param {function} callback - The callback function.
     */
  onOpen(callback) {
    this.onOpenCallback = callback;
  }

  /**
     * Sets the callback function to be called when the WebSocket connection is closed.
     * @param {function} callback - The callback function.
     */
  onClose(callback) {
    this.onCloseCallback = callback;
  }
  /**
     * Sets the callback function to be called when an error occurs with the WebSocket connection.
     * @param {function} callback - The callback function.
     */
  onError(callback) {
    this.onErrorCallback = callback;
  }
  /**
     * Sets the callback function to be called when a message is received from the WebSocket server.
     * @param {function} callback - The callback function.
     */
  onMessage(callback) {
    this.onMessageCallback = callback;
  }


  /**
     * Sends a heartbeat message to the WebSocket server.
     */
  onHeartbeat() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log('heart', new Date());
      this.socket.send('PING');
    } else {
      console.error('WebSocket is not open');
    }
  }

  /**
     * Sends a message to the WebSocket server.
     * @param {string} message - The message to send.
     */
  send(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open');
    }
  }
  /**
     * Attempts to reconnect to the WebSocket server if the connection is lost.
     */
  reconnect() {
    if (this.shouldReconnect &&this.reconnectAttempts < this.maxReconnectAttempts) {
      console.log(`WebSocket reconnecting in ${this.reconnectInterval / 1000} seconds...`);
      //重连间隔递增
      setTimeout(() => {
        this.connect();
        this.reconnectAttempts++;
      }, this.reconnectInterval + this.reconnectAttempts * 1000);
    } else {
      console.log(!this.shouldReconnect?'WebSocket disconnected':'WebSocket maximum reconnection attempts reached');
    }
  }
}

export default WebSocketClient;

