import React, {useEffect, useState} from 'react';
import {Link, useParams,
  // useLocation
} from 'react-router-dom';
import DetailsHeader from '../../../components/detailsHeader';
import PersonNumberKey from './PersonNumberKey';
import {Anchor, Card, Col, Divider, Row, Skeleton, Button, Form, Collapse, Table} from 'antd';
import CustomTable from '../../../components/customTable';
import {securityAreaDetail, getUserInfo} from '../../../api';
import {GETALLWORKERMESSAGE} from '../../../api/Urls';
import {guaranteeAreaStatus, positionLevel} from '../../../components/statusCollection';
import {staffStatus, bikeCompany, requireTypeStatus} from '../../../components/statusCollection';
import PolyShowDetail from '../../../components/aMap/PolyShowDetail';
import PropTypes from 'prop-types';

import {timeFormat} from '../../../components/baseTime';
import './style.css';
const {Panel} = Collapse;

const KeyAreaDetails = ({queryType}) => {
  const [detailForm] = Form.useForm();
  const d = useParams();
  const urlSrceen = location.pathname.indexOf('/bigScreen') === -1? '':'/bigScreen';
  // const {state} = useLocation();
  const [loading] = useState(false);
  const [numberModalKey, setNumberModalKey] = useState(false);
  const [baseInfo, setBaseInfo] = useState({});
  const [statistics, setStatistics] = useState({});
  const [allDetails, setAllDetails] = useState({});
  const [defaultCheck, setDefaultCheck] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  // 保障区域详情获取
  const getAreaDetailsFunc=async ()=>{
    const res=await securityAreaDetail(d?.id);
    if (res.code===200) {
      setDefaultCheck(res.data?.guaranteeDay?.split(','));
      setAllDetails(res.data);
      setBaseInfo(res.data);
    }
  };
  // 获取个人信息
  const getOnlineUserInfo = async () => {
    const res = await getUserInfo();
    if (res.code === 200) {
      setUserInfo(res.data);
    }
  };
  // 判断数组是否含有车企id
  const isInArray = (num, array) => array?.includes(num);
  // 车企statistic数据筛选
  const bikeCompanyFilter=(item, number)=>{
    const num=item?.statistic?.filter((item)=>item.bikeEnterprise===number)[0];
    return num;
  };
  const companyPan=(item, number)=>{
    const num=item?.filter((item)=>item.bikeEnterprise===number)[0];
    if (num) {
      return num;
    } else {
      return null;
    }
  };
  const options = [
    {
      label: '周一',
      value: '1',
    },
    {
      label: '周二',
      value: '2',
    },
    {
      label: '周三',
      value: '3',
    },
    {
      label: '周四',
      value: '4',
    },
    {
      label: '周五',
      value: '5',
    },
    {
      label: '周六',
      value: '6',
    },
    {
      label: '周日',
      value: '7',
    },
  ];
  const columnsTab = [
    {
      title: 'NO',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      render: (text) => text || '--',
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      render: (text) => text || '--',
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      render: (text) => positionLevel(text)|| '--',
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (text) => staffStatus(text) || '--',
    },
  ];
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      exportRender: (render) => render.index,
    },
    isInArray(1, userInfo?.bikeEnterprise)&&{
      title: '美团到岗数',
      dataIndex: 'mt',
      key: 'mt',
      company: 1,
      width: 330,
      // eslint-disable-next-line
      render: (mt, item) => (
        <>
          <span>实到：{bikeCompanyFilter(item, 1)?.actualCount || 0}</span> | &nbsp;
          <span>应到：{bikeCompanyFilter(item, 1)?.requireCount || 0}</span> | &nbsp;
          <span>到岗率：
            {bikeCompanyFilter(item, 1)?.actualCount===0&&
            bikeCompanyFilter(item, 1)?.requireCount===0?'100%':
            bikeCompanyFilter(item, 1)?.actualCount!==0&&
            bikeCompanyFilter(item, 1)?.requireCount===0?'-':
            (Math.round( bikeCompanyFilter(item, 1)?.actualCount/ bikeCompanyFilter(item, 1)?.requireCount * 10000)/100)
                .toFixed(0)+'%'}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `实到：${bikeCompanyFilter(render, 1)?.actualCount || 0} |  应到：${bikeCompanyFilter(render, 1)?.requireCount || 0}
       |  到岗率：:${bikeCompanyFilter(render, 1)?.actualCount===0&&bikeCompanyFilter(render, 1)?.requireCount===0?'100%':
       bikeCompanyFilter(render, 1)?.actualCount!==0&&bikeCompanyFilter(render, 1)?.requireCount===0?'-':
        (Math.round(bikeCompanyFilter(render, 1)?.actualCount/bikeCompanyFilter(render, 1)?.requireCount * 10000)/100)
            .toFixed(0)+'%'
}`,
    },
    isInArray(2, userInfo?.bikeEnterprise)&&{
      title: '青桔到岗数',
      dataIndex: 'qj',
      key: 'qj',
      company: 2,
      width: 330,
      // eslint-disable-next-line
      render: (mt, item) => (
        <>
          <span>实到：{bikeCompanyFilter(item, 2)?.actualCount || 0}</span> | &nbsp;
          <span>应到：{bikeCompanyFilter(item, 2)?.requireCount || 0}</span> | &nbsp;
          <span>到岗率：
            {bikeCompanyFilter(item, 2)?.actualCount===0&&
            bikeCompanyFilter(item, 2)?.requireCount===0?'100%':
            bikeCompanyFilter(item, 2)?.actualCount!==0&&
            bikeCompanyFilter(item, 2)?.requireCount===0?'-':
            (Math.round( bikeCompanyFilter(item, 2)?.actualCount/ bikeCompanyFilter(item, 2)?.requireCount * 10000)/100)
                .toFixed(0)+'%'}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `实到：${bikeCompanyFilter(render, 2)?.actualCount || 0} |  应到：${bikeCompanyFilter(render, 2)?.requireCount || 0}
       |  到岗率：:${bikeCompanyFilter(render, 2)?.actualCount===0&&bikeCompanyFilter(render, 2)?.requireCount===0?'100%':
       bikeCompanyFilter(render, 2)?.actualCount!==0&&bikeCompanyFilter(render, 2)?.requireCount===0?'-':
        (Math.round(bikeCompanyFilter(render, 2)?.actualCount/bikeCompanyFilter(render, 2)?.requireCount * 10000)/100)
            .toFixed(0)+'%'
}`,
    },
    isInArray(3, userInfo?.bikeEnterprise)&&{
      title: '哈啰到岗数',
      dataIndex: 'hl',
      key: 'hl',
      company: 3,
      width: 330,
      // eslint-disable-next-line
      render: (mt, item) => (
        <>
          <span>实到：{bikeCompanyFilter(item, 3)?.actualCount || 0}</span> | &nbsp;
          <span>应到：{bikeCompanyFilter(item, 3)?.requireCount || 0}</span> | &nbsp;
          <span>到岗率：
            {bikeCompanyFilter(item, 3)?.actualCount===0&&
            bikeCompanyFilter(item, 3)?.requireCount===0?'100%':
            bikeCompanyFilter(item, 3)?.actualCount!==0&&
            bikeCompanyFilter(item, 3)?.requireCount===0?'-':
            (Math.round( bikeCompanyFilter(item, 3)?.actualCount/ bikeCompanyFilter(item, 3)?.requireCount * 10000)/100)
                .toFixed(0)+'%'}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `实到：${bikeCompanyFilter(render, 3)?.actualCount || 0} |  应到：${bikeCompanyFilter(render, 3)?.requireCount || 0}
       |  到岗率：:${bikeCompanyFilter(render, 3)?.actualCount===0&&bikeCompanyFilter(render, 3)?.requireCount===0?'100%':
       bikeCompanyFilter(render, 3)?.actualCount!==0&&bikeCompanyFilter(render, 3)?.requireCount===0?'-':
        (Math.round(bikeCompanyFilter(render, 3)?.actualCount/bikeCompanyFilter(render, 3)?.requireCount * 10000)/100)
            .toFixed(0)+'%'
}`,
    },
    {
      title: '统计时间',
      dataIndex: 'statisticTime',
      key: 'statisticTime',
      onExport: true,
    },
    {
      title: '类型',
      dataIndex: 'requireType',
      key: 'requireType',
      onExport: true,
      render: (mt, item) => requireTypeStatus(mt),
      exportRender: (record) => requireTypeStatus(record.requireType),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      // eslint-disable-next-line
      render: (_, item) => (
        <Button
          type="link"
          onClick={() => {
            setNumberModalKey(true);
            setStatistics(item);
          }}
        >
          详情
        </Button>
      ),
    },
  ].filter(Boolean);
  useEffect(() => {
    getAreaDetailsFunc();
    getOnlineUserInfo();
    document.addEventListener('DOMContentLoaded', function() {
      window.scrollTo(0, 0);
    });
  }, []);
  return (
    <div>
      <DetailsHeader
        pageName="单车重点保障区域详情"
        buttonGroup={() => (
          // editStatus决定是否编辑
          allDetails?.editStatus===true?<Button type="primary">
            <Link
              to={`${urlSrceen}/guaranteeArea/keySecurityArea/add/edit`}
              state={{operationType: 'edit', baseInfo: {id: d.id, baseInfo}}}
            >
              编辑重点工作保障区域
            </Link>
          </Button>:<></>
        )}
      />
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <div>
          <h3 className="details-title" id="basic-information">
            基础信息
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  保障区域名称：<span className="text-gray">{baseInfo.guaranteeName||'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  状态：<span className="text-gray">{guaranteeAreaStatus(baseInfo.guaranteeStatus)||'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  创建人：<span className="text-gray">{baseInfo.createName||'暂无数据'}</span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  结束时间：<span className="text-gray">{baseInfo.endTime?timeFormat(baseInfo.endTime):'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  创建时间：<span className="text-gray">{baseInfo.createTime?timeFormat(baseInfo.createTime):'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  所属区域：<span className="text-gray">{baseInfo.areaName||'暂无数据'}</span>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="page-range">
            保障时段
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="area-details">
              <div>
                <Form form={detailForm}>
                  {/* <span style={{fontWeight: '600'}}>
                    通用规则：</span>
                  保障工作日: <span className="text-gray"> {options.filter((option)=>
                    defaultCheck.includes(option.value)).map((option)=>' '+ option.label+' ').toString()+' , '}
                  {allDetails?.isExcludeHolidays === 0 ? <> 法定节假日自动排休。</> : <> ,法定节假日不排休。</>}</span>
                  <br />
                  <br /> */}
                  <Row >
                    <Col className="gutter-row" span={2}><span style={{fontWeight: '600'}}>
                    通用规则：</span>
                    </Col>
                    <Col className="gutter-row" span={18}><span style={{fontWeight: '600'}}></span>
                    保障工作日 : <span className="text-gray"> {options.filter((option)=>
                        defaultCheck.includes(option.value)).map((option)=>' '+ option.label+' ').toString()+' , '}
                      {allDetails?.isExcludeHolidays === 0 ? <> 法定节假日自动排休。</> : <>
                  法定节假日不排休。</>}</span></Col><Col className="gutter-row" span={4}>
                    </Col></Row>
                  <br />
                  <Row >
                    <Col className="gutter-row" span={2}><span style={{fontWeight: '600'}}>
                    保障时段：</span>
                    </Col>
                    <Col className="gutter-row" span={18}><span style={{fontWeight: '600'}}></span>
                      <Row gutter={[16, 24]}>
                        {allDetails?.guaranteeTimeList && allDetails.guaranteeTimeList.map((item, index) => (
                          <Col className="gutter-row" span={6} key={index}>
                            <span key={index}>
                                保障时段{index + 1} : <span className="text-gray">
                                {item.startTime}</span>—<span className="text-gray">{item.endTime}  </span></span>
                          </Col>

                        ))}</Row></Col><Col className="gutter-row" span={4}>
                    </Col></Row>
                </Form>
              </div>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="page-range-map">
            保障地图
          </h3>
          <div>
            <PolyShowDetail
              polyPath={allDetails?.markRange?.length > 0 ?
              JSON.parse(allDetails?.markRange) : null}
              initCenter={allDetails?.markRange?.length > 0 ?
                JSON.parse(allDetails?.markRange)[0] : [104.065861, 30.657401]}
            />
          </div>
        </div>
        {allDetails?.requireType===2&& allDetails.operationList && allDetails.requireNumList&&<div>
          <Divider />
          <h3 className="details-title" id="job-information" >
            应配人数（指定人员）
          </h3>
          <Collapse accordion expandIconPosition={'right'} style={{marginTop: '22px'}}>
            {/* {allDetails&&allDetails.operationList && allDetails.requireNumList &&
            allDetails.operationList.map((item, index) => {
              return (
                <>
                  <Panel key={index} header={`${bikeCompany(item.bikeEnterprise)}应配人数:
            ${allDetails.requireNumList.filter((obj)=>
                  obj.bikeEnterprise===item.bikeEnterprise)[0]?.requireCount||0}
                  ${bikeCompany(item.bikeEnterprise)}指定人数:
            ${allDetails.requireNumList.filter((obj)=>
                  obj.bikeEnterprise===item.bikeEnterprise)[0]?.appointCount||0}`} >
                    <Table pagination={false} columns={columnsTab} key={'id'}
                      dataSource={item.operationInfo}></Table>
                  </Panel>
                </>
              );
            })
            } */}
            {isInArray(1, userInfo?.bikeEnterprise)&&companyPan(allDetails?.requireNumList, 1)?.appointCount!==0?
            <Panel header={`美团应配人数:
            ${companyPan(allDetails?.requireNumList, 1)?.requireCount} 美团指定人数:
            ${companyPan(allDetails?.requireNumList, 1)?.appointCount}`}
            key="1"
            collapsible={'arrow'}
            showArrow={true}>
              <Table pagination={false} columns={columnsTab} key={'id'}
                dataSource={companyPan(allDetails?.operationList, 1)?.operationInfo}></Table>
            </Panel>:companyPan(allDetails?.requireNumList, 1)?.appointCount===0?
            <Panel header={`  美团应配人数: 0 美团指定人数: 0`}
              key="1"
              collapsible={'disabled'}
              showArrow={false}>
            </Panel>:<></>
            }
            {isInArray(2, userInfo?.bikeEnterprise)&&companyPan(allDetails?.requireNumList, 2)?.appointCount!==0?
            <Panel header={`青桔应配人数:
            ${companyPan(allDetails?.requireNumList, 2)?.requireCount} 青桔指定人数:
            ${companyPan(allDetails?.requireNumList, 2)?.appointCount}`}
            key="2"
            collapsible={'arrow'}
            showArrow={true}>
              <Table pagination={false} columns={columnsTab} key={'id'}
                dataSource={companyPan(allDetails?.operationList, 2)?
                companyPan(allDetails?.operationList, 2)?.operationInfo:[]}></Table>
            </Panel>:companyPan(allDetails?.requireNumList, 2)?.appointCount===0?
            <Panel header={`  青桔应配人数: 0 青桔指定人数: 0`}
              key="2"
              collapsible={'disabled'}
              showArrow={false}>
            </Panel>:<></>
            }

            {isInArray(3, userInfo?.bikeEnterprise)&&companyPan(allDetails?.requireNumList, 3)?.appointCount!==0?
            <Panel header={`哈啰应配人数:
            ${companyPan(allDetails?.requireNumList, 3)?.requireCount} 哈啰指定人数:
            ${companyPan(allDetails?.requireNumList, 3)?.appointCount}`}
            key="3"
            collapsible={'arrow'}
            showArrow={true}>
              <Table pagination={false} columns={columnsTab} key={'id'}
                dataSource={companyPan(allDetails?.operationList, 3)?
                companyPan(allDetails?.operationList, 3)?.operationInfo:[]}></Table>
            </Panel>:companyPan(allDetails?.requireNumList, 3)?.appointCount===0?
            <Panel header={`  哈啰应配人数: 0 哈啰指定人数: 0`}
              key="3"
              collapsible={'disabled'}
              showArrow={false}>
            </Panel>:<></>
            }

          </Collapse>
        </div>}
        {allDetails?.requireType===1&&<><Divider />
          <div>
            <h3 className="details-title" id="job-information">
              应配人数
            </h3>
            {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={[16, 0]} className="margin-b-l">
                {allDetails?.requireNumList&&allDetails?.requireNumList?.map((item, index) => {
                  return (
                    <Col key={item.bikeEnterprise + index}>
                      <div className="number-item">
                        <div className="number-item-title">{bikeCompany(item.bikeEnterprise)}</div>
                        <div className="number-item-content">
                          <span className="text-gray">

                          </span>
                        </div>
                        <div className="number-item-content">
                          {('单车') + '应配人数：'}
                          <span className="text-gray">
                            {item.requireCount}
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
          </div>
        </>}
        <Divider />
        {/* 到岗统计 */}
        <div style={{marginRight: '70px'}}>
          <div id="person-setting"></div>
          <div className="margin-t-l">
            <CustomTable
              InitApi={`${GETALLWORKERMESSAGE}`}
              toIndex={true}
              columns={columns}
              resType="POST"
              firm={[1, 2]}
              updateList={0}
              customTitle={'到岗统计'}
              tip="到岗统计导出中..."
              exportFileName={'到岗统计记录'}
              otherParam={{
                guaranteeId: d.id,
              }}
            />
          </div>
        </div>
      </Card>
      <PersonNumberKey numberModalKey={numberModalKey} setNumberModalKey={setNumberModalKey} statistics={statistics} />
      {/* // 定位组件 */}
      <Anchor className="anchor">
        <Anchor.Link href="#basic-information" title="基础信息" />
        <Anchor.Link href="#page-range" title="保障时段" />
        <Anchor.Link href="#page-range-map" title="保障地图" />
        <Anchor.Link href="#job-information" title="应配人数" />
        <Anchor.Link href="#person-setting" title="到岗统计" />
      </Anchor>
    </div>
  );
};

KeyAreaDetails.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default KeyAreaDetails;
