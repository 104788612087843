import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import {useTranslation} from 'react-i18next';
import {getTableListData} from '../../api';
import {getColumnSearchProps, getColumnSearchTimeProps} from './ColumnSearch';

const SimpleTable = ({
  columns,
  InitApi,
  resType,
  id,
  toIndex,
  params,
}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10, current: 1, total: 0, size: 'small',
  });

  // add search input and other plugin
  const newColumns = (column) => {
    return column.map((item) => {
      let c = {
        ...item,
      };
      if (item?.search) {
        c = {...c, ...getColumnSearchProps()};
      }
      if (item?.searchDate) {
        c = {...c, ...getColumnSearchTimeProps()};
      }
      return c;
    });
  };

  // get table Data
  const getTableList = (data) => {
    setLoading(true);
    setTableData([]);
    getTableListData(InitApi, {...data, ...params}, t('flag'), resType || 'GET').then((res) => {
      if (toIndex) {
        const tableData=res.data.items.map((item, index) => (
          {
            ...item,
            index: (res.data.pageNum - 1) * res.data.pageSize + 1 + index,
          }
        ));
        setTableData([...tableData]);
      } else {
        setTableData(res.data.items);
      }
      setPagination({
        size: 'small',
        pageSize: res.data.pageSize,
        current: res.data.pageNum,
        total: res.data.totalPage * res.data.pageSize,
      });
    }).finally(() => setLoading(false)).catch(()=> {
      setTableData([]);
    });
  };
  const onTableChange = (pages) => {
    const data = {
      pageNum: pages.current,
      pageSize: pages.pageSize,
      id,
    };
    getTableList(data);
  };

  useEffect(() => {
    getTableList({
      id,
      pageNum: 1,
      pageSize: pagination.pageSize,
    });
  }, []);

  return (
    <Table
      loading={loading}
      dataSource={tableData}
      pagination={pagination}
      onChange={onTableChange}
      columns={newColumns(columns)}
      rowKey={(record) => record.id}
    />
  );
};

SimpleTable.propTypes = {
  resType: PropTypes.string,
  InitApi: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.number,
  toIndex: PropTypes.bool,
  params: PropTypes.object,
};

export default SimpleTable;
