import moment from 'moment';


export const STTodayValue= moment('00:00', 'HH:mm');

export const initTimeValue=(str)=>moment(str, 'HH:mm:ss');


export const timeFormat=(value)=> {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};
export const timeFormatMin=(value)=> {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};

export const timeFormatDateAndMin=(value)=> {
  if (value) {
    return moment(value).format('MM/DD HH:mm');
  }
  return '';
};

export const clockFormat=(value)=> {
  if (value) {
    if (value.length > 8) {
      return moment(value).format('HH:mm:ss');
    } else if (value.length > 0 || value.length <= 8) {
      return moment('2022-06-06 ' + value).format('HH:mm:ss');
    }
  }
  return '';
};

export const momentToClock=(m)=>{
  return moment(m).format('HH:mm:ss');
};

export const hourValue=(value)=>{
  if (value) {
    return moment(value).format('HH');
  }
  return '00';
};
