import * as Urls from './Urls';
import {request} from './ajax';

// login
export const userLogin = (params, flag) =>
  request(Urls.LOCALSIGNIN, params, 'POST', flag, {
    'Content-Type': 'application/json',
    'token': null,
  });
export const getVerify = (flag) =>
  request(Urls.GETVERIFY, null, 'GET', flag, {
    'Content-Type': 'application/json',
    'token': null,
  });
export const userLogout = (flag) => request(Urls.LOGOUT, null, 'GET', flag);
// api List

export const getApiListData = (url, params, type, flag) =>
  request(url, params, type, flag);

// tableList

export const getTableListData = (url, params, flag, type) =>
  request(url, params, type, flag);
export const uploadFile = (url, params, flag) =>
  request(
      url,
      params,
      'POST',
      flag,
      {
        'Content-Type': 'multipart/form-data',
      },
      360000,
  );
export const uploadFile2 = (url) => request(url, {}, 'GET', 2, {}, 180000);

// Roles
export const getRolesListData = (params, flag) =>
  request(Urls.GETROLELIST, params, 'POST', flag);
export const getMenuListData = (params, flag) =>
  request(Urls.GETMENULIST, params, 'GET', flag);
export const addRole = (params, flag) =>
  request(Urls.ADDROLE, params, 'POST', flag);
export const deleteRole = (id, flag) =>
  request(Urls.REMOVEROLE + id, null, 'DELETE', flag);
export const updateRole = (params, flag) =>
  request(Urls.UPDATEROLE, params, 'PUT', flag);
export const getMenusData = (params, flag) =>
  request(Urls.GETMENUBYID + params, null, 'GET', flag);
export const roleDetails = (id, flag) =>
  request(Urls.ROLEDETAILS + id, {}, 'GET', flag);

// Users
export const getUserDetails = (userId, flag) =>
  request(Urls.GETUSERDETAILS + userId, null, 'GET', flag);
export const deleteUser = (id, flag) =>
  request(Urls.REMOVEUSER + id, null, 'DELETE', flag);
export const saveUser = (params, flag) =>
  request(Urls.ADDUSER, params, 'POST', flag);
export const updateUser = (params, flag) =>
  request(Urls.UPDATEUSER, params, 'PUT', flag);
export const revisePwd = (params, flag) =>
  request(Urls.REVISEPWD, params, 'POST', flag);
export const updatePhone = (params, flag) =>
  request(Urls.UPDATEPHONE, params, 'POST', flag);
export const resetPwd = (id, flag) =>
  request(Urls.RESETPASSWORD + id, null, 'GET', flag);
export const addAreaToUser = (params, flag) =>
  request(Urls.ADDAREATOUSER, params, 'POST', flag);
export const disabledUser = (params, flag) =>
  request(Urls.DISABLEDUSER, params, 'POST', flag);
export const enabledUser = (id, flag) =>
  request(Urls.ENABLEUSER + id, {}, 'GET', flag);
export const getUserInfo = (flag) =>
  request(Urls.GETUSERINFO, null, 'GET', flag);

// Details
export const getStaffInfoData = (id, flag) =>
  request(Urls.GETSTAFFINFO + id, null, 'GET', flag);
export const getAttendanceDetails = (id, flag) =>
  request(Urls.GETATTENDLOGDETAILS + id, null, 'GET', flag);
export const getTrackDetails = (id, params, flag) =>
  request(Urls.GETTRACKDETAIL + id, params, 'POST', flag);
// eslint-disable-next-line max-len
export const getTaskTrackDetails = (params, flag) =>
  request('/api/attendanceLog/movingBikeTrack', params, 'POST', flag);
export const getTaskDetails = (id, flag) =>
  request(Urls.GETTASKDETAILS + id, null, 'GET', flag);
export const getBikeInfo = (id, flag) =>
  request(Urls.GETBIKEINFO + id, null, 'GET', flag);
export const getMaintainDetails = (id, flag) =>
  request(Urls.GETMAINTAINDETAILS + id, null, 'GET', flag);
export const getUnusualDetails = (id, key, flag) =>
  request(Urls.GETUNUSUALDETAILS + id + `/${key}`, null, 'GET', flag);

// bicycle Management
export const batchScrapBike = (params, key, flag) =>
  request(Urls.BATCHSCRAPBIKE + key, params, 'POST', flag);
export const getModuleList = (params, flag) =>
  request(Urls.GETMODULELIST, params, 'GET', flag);
export const getBikeCount = (parmars, key, flag) =>
  request(Urls.GETBIKECOUNT + key, parmars, 'POST', flag);
export const getBikeAreaNum = (params, flag) =>
  request(Urls.BIKEAREANUM, params, 'POST', flag);
export const bikeStatus = (key, flag) =>
  request(Urls.BIKESTATUS + key, null, 'GET', flag);
export const getUploadType = (flag) =>
  request(Urls.GETUPLOADLOGTYPE, null, 'GET', flag);

// Staff Management
export const getStaffDetails = (params, flag) =>
  request(Urls.GETSTAFFLISTDETAILS + params, null, 'GET', flag);
export const addStaff = (params, flag) =>
  request(Urls.ADDSTAFF, params, 'POST', flag);
export const deleteStaff = (params, flag) =>
  request(Urls.DELETESTAFF + params, null, 'DELETE', flag);
export const changeStaffStatus = (params, flag) =>
  request(Urls.CHANGESTAFFSTATUS, params, 'GET', flag);
export const quickSetting = (params, flag) =>
  request(Urls.QUICKSETTING, params, 'POST', flag);
export const resetStaffPwd = (params, flag) =>
  request(Urls.RESETSTAFFPWD + params, null, 'GET', flag);
export const updateStaff = (params, flag) =>
  request(Urls.UPDATESTAFF, params, 'POST', flag);
export const batchDeleteStaffs = (data, flag) =>
  request(Urls.BATCHDELETESTAFFS, null, 'DELETE', flag, {}, 25000, data);

export const getAttendanceRule = (id, flag) =>
  request(Urls.GETATTENDANCERULE + id, null, 'GET', flag);
export const getAttendanceSimpleRule = (id, flag) =>
  request(Urls.GETATTENDANCESIMPLERULE + id, null, 'GET', flag);

export const addJurisdiction = (params, flag) =>
  request(Urls.ADDJURISDICTION, params, 'POST', flag);
// 城市管理者
export const getCityDetails = (id, flag) =>
  request(`${Urls.GETCITYLISTDETAILS}${id}`, null, 'Get', flag);
export const addCity = (params, flag) =>
  request(Urls.ADDCITY, params, 'POST', flag);
export const updateCity = (params, flag) =>
  request(Urls.UPDATECITY, params, 'POST', flag);

// attendance Management
export const getAttendanceMemberList = (id, flag) =>
  request(Urls.GETADDGROUPMEMBERLIST + id, {}, 'GET', flag);
export const addAttendanceGroup = (params, flag) =>
  request(Urls.ADDATTENDANCEGROUP, params, 'POST', flag);
export const getAttendanceDetail = (id, flag) =>
  request(Urls.GETATTENDANCEDETAIL + id, null, 'GET', flag);
export const deleteGroup = (id, flag) =>
  request(Urls.REMOVEATTENDANCEGROUP + id, null, 'DELETE', flag);
export const updateGroupAttendanceRules = (id, params, flag) =>
  request(Urls.UPDATEGROUPATTENDANCERULES + id, params, 'PUT', flag);
export const updateGroupBasicInfo = (params, flag) =>
  request(Urls.UPDATEGROUPBASICINFO, params, 'PUT', flag);
// Area Tree
export const getAreaTree = (params, flag) =>
  request(Urls.GETAREATREE, params, 'POST', flag);
export const getAreaTreeFilter = (params, flag) =>
  request(Urls.GETATTENDANCEGROUPAREALIST, params, 'POST', flag);
export const getAreaTreeFilter2 = (params, flag) =>
  request(Urls.GETALLAREALIST, params, 'POST', flag);
export const getBikeAreaTree = (flag) =>
  request(Urls.GETBIKEAREATREE, null, 'GET', flag);
export const addGroupMember = (id, params, flag) =>
  request(Urls.ADDGROUPMEMBER + id, params, 'POST', flag);
export const removeGroupMember = (groupId, memberId, flag) =>
  request(
      Urls.REMOVEGROUPMEMBER + groupId + '/' + memberId,
      null,
      'DELETE',
      flag,
  );

export const updateAttendanceLogRemark = (params, flag) =>
  request(Urls.ATTENDANCELOGREMARK, params, 'POST', flag);

// Screen
export const getScreenQueryMap = (params, flag) =>
  request(Urls.SCREENQUERYMAP, params, 'POST', flag);
export const getMapZoomCenter = (flag) =>
  request(Urls.MAPZOOMCENTER, {}, 'GET', flag);
export const getBikeScreenMap = (bikeType, params, flag) =>
  request(`${Urls.BIKESCREENMAP}${bikeType}`, params, 'POST', flag);

// staff message
export const getNoticeMsgCount = (params, flag) =>request(Urls.NOTICEMSGCOUNT, params, 'GET', flag);
// Staff Screen Charts
export const getLineEcharts = (type, params, flag) =>
  request(Urls.LINECHART + `/${type}`, params, 'GET', flag);
export const getPieEcharts = (params, flag) =>
  request(Urls.PIECHART + `/${params.type}`, {}, 'GET', flag);
export const getAreaBarChart = (type, id, flag) =>
  request(Urls.GETAREABARCHARTDATA + type + `/${id}`, {}, 'GET', flag);
export const getStatisticEcharts = (params, flag) =>
  request(Urls.STATISTICS + `/${params.type}`, {}, 'GET', flag);
export const getSecurityAreaData = (type, flag) =>
  request(Urls.SECURITYAREASTATISTICS + `${type}`, {}, 'GET', flag);

export const getKeyAreaBarChart = (type, id, flag) =>
  request(Urls.GETKEYAREABARCHARTDATA + type + `/${id}`, {}, 'GET', flag);

export const getKeyAreaLineChart = (type, params, flag) =>
  request(Urls.KEYAREALINECHART + `/${type}`, params, 'GET', flag);

// Bike Screen Charts
export const getBikeScreenDevice = (bikeType, imei, flag) =>
  request(
      `${Urls.GETBIKESCREENDEVICE}${bikeType}/${imei}`,
      {},
      'GET',
      flag,
  );

export const getBikeScreenStatistics=(bikeType)=>request(`${Urls.BIKE_SCREEN_STATISTICS}${bikeType}`, {}, 'GET', 2);

export const getBikeScreenRadar=(bikeType)=>request(`${Urls.BIKE_SCREEN_RADAR}${bikeType}`, {}, 'GET', 2);

export const getBikeScreenLineChart=(bikeType)=>request(`${Urls.BIKE_SCREEN_LINE}${bikeType}`, {}, 'GET', 2);

export const minutesPieChart = (params) =>
  request(Urls.MONITORING_QUANTITY, params, 'POST', 2);


// getMarkRange
export const getMarkRange = () => request(Urls.GETMARKRANGE, {}, 'GET', 2);
export const setMarkRange = (params) =>
  request(Urls.SETMARKRANGE, params, 'POST', 2);

// guaranteeArea
export const addGuarantArea = (type, params, flag) =>
  request(Urls.ADDGUARANTEEAREA + type, params, 'POST', flag);
export const editGuarantArea = (params, flag) =>
  request(Urls.UPDATEGUARANTEEAREA, params, 'POST', flag);
export const getGuarantArea = (params, flag) =>
  request(Urls.GETGUARANTEEAREALIST, params, 'POST', flag);
export const updateGuarantAreaStatus = (params, flag) =>
  request(Urls.UPDATEGUARANTEEAREASTATUS, params, 'POST', flag);
export const deleteGuarantArea = (id, flag) =>
  request(Urls.DELETEGUARANTEEAREA + id, null, 'DELETE', flag);
export const getGuarantAreaDetails = (id, flag) =>
  request(Urls.GUARANTEEAREADETAILS + id, null, 'GET', flag);
export const getGuaranteeStatisticsList = (id, flag) =>
  request(Urls.GETGUARANTEESTATISTICSLIST + id, null, 'POST', flag);
export const getGuaranteePersonList = (id, flag) =>
  request(Urls.GETGUARANTEEPERSONLIST + id, null, 'POST', flag);
export const getAreaNumStatus = (type, flag) =>
  request(Urls.GETAREANUMSTATUS + type, null, 'GET', flag);
export const setHideArea = (params, flag) =>
  request(Urls.HIDEAREA, params, 'POST', flag);

// 汇总报表
export const getIndexEvaluation = (params, flag) =>
  request(Urls.GETINDEXEVALUATION, params, 'POST', flag);
export const getWeightInfo = (type, flag) =>
  request(Urls.GETWEIGHTINFO + type, null, 'GET', flag);
export const setWeightInfo = (params, flag) =>
  request(Urls.SETWEIGHTINFO, params, 'POST', flag);
export const getArrivalData = (params, flag) =>
  request(Urls.GETARRIVALDATA, params, 'POST', flag);
export const weightFind = (params, flag) =>
  request(Urls.WEIGHTFIND, params, 'POST', flag);
export const getSummaryStatistics = (params, flag) =>
  request(Urls.SUMMARYSTATISTIC, params, 'POST', flag);
export const weightSave = (params, flag) =>
  request(Urls.WEIGHTSAVE, params, 'POST', flag);

// 数据统计
// 单车数据统计展示接口
export const getStatisticInfo = (bikeType, flag) =>
  request(Urls.GETSTATISTIC + bikeType, null, 'GET', flag);
// 实时趋势图
export const getRealTimeTrend=(bikeType, param, flag)=>request(Urls.GETREALTIMETREND + bikeType, param, 'POST', flag);
// 日新增单车数趋势接口
export const getBikeStatis=(bikeType, timeInterval, flag)=>request(`${Urls.GETBIKESTATISTIC}${bikeType}/${timeInterval}`
    , null, 'GET', flag);
// 未备案趋势图
export const getNotFiledTrend=(bikeType, flag)=>request(`${Urls.GETNOTFILEDTREND}${bikeType}`
    , null, 'GET', flag);
// 车企区域监测单车数占比
export const getMonitorPercent = (bikeType, flag) =>
  request(Urls.GETMONITORPERCENT + bikeType, null, 'GET', flag);
export const getCrossRegionTrend = (bikeType, params, flag) =>
  request(Urls.CROSSREGIONTREND + bikeType, params, 'POST', flag);
// 出勤数统计
export const attendanceStatistics = (bikeType, params, flag) =>
  request(Urls.ATTENDANCESTATISTIC + bikeType, params, 'POST', flag);
// 数据统计趋势图接口
export const getTrend = (url, params, flag) =>
  request(url, params, 'POST', flag);


// 设备管理
export const equipmentDelete = (id, flag) =>
  request(Urls.DELETEEQUIPMENT + id, null, 'DELETE', flag);
export const equipmentDetail= (params, flag) =>
  request(Urls.EQUIPMENTDETAIL, params, 'POST', flag);
// 设备监测接口
export const getMonitorList = (bikeType, flag) =>
  request(Urls.GETMONITORLIST + bikeType, null, 'POST', flag);
// 设备检测详情
export const getMonitorDetails = (params, flag)=>request(Urls.GETMONITORDETAILS, params, 'POST', flag);


// 系统设置
export const getRound = (flag) =>request(Urls.GETROUND, null, 'GET', flag);
export const updateRound = (params, flag) => request(Urls.UPDATEROUND, params, 'POST', flag);
export const getRequiredNumber = (flag) =>request(Urls.GRTREQUIREDNUMBER, null, 'GET', flag);
export const updateRequiredNumber = (params, flag) => request(Urls.UPDATEREQUIREDNUMBER, params, 'POST', flag);
export const updateOrderExpireTime = (params, flag) => request(Urls.UPDATEORDEREXPIRETIME, params, 'POST', flag);
export const getOrderExpireTime = (params, flag) => request(Urls.GRTORDEREXPIRETIME, params, 'POST', flag);
export const getRequiredQuantity = (flag) => request(Urls.GETREQUIREDQUANTITY, null, 'POST', flag);
export const updateRequiredQuantity= (params, flag) => request(Urls.UPDATEREQUIREDQUANTITY, params, 'POST', flag);


// 获取工单子列表
export const getOrderChildList= (params, flag) => request(Urls.GETWORKORDERCHILDLISTBYID, params, 'POST', flag);
// 获取工单详情
export const getOrderDetail= (params, flag) => request(Urls.GETWORKORDERDETAILBYID, params, 'POST', flag);
// TRTC/IM
export const getUserSig = (userId) =>request(`${Urls.GET_USER_SIG}${userId}`, null, 'GET', '2');
// 获取所有区域数据-树状结构
export const getAllAreaData = (flag) =>request(Urls.GETWORKORDERAREA, null, 'GET', flag);
// 消息中心批量已读
export const batchReadMessage= (params, flag) => request(Urls.READMESSAGEBATCH, params, 'POST', flag);
// 系统设置app和web端的开关
export const appAndWebPlatformSwitch= (params, flag) => request(Urls.APPSWITCHCHANGE, params, 'POST', flag);
// 系统设置app和web端的状态
export const appAndWebPlatformStatus= (flag) => request(Urls.DEFALTESTATUS, null, 'GET', flag);
// 重点保障区域结束按钮
export const secureFinishButton= (id) => request(`${Urls.FINISHAREABUTTON}${id}`, null, 'GET', '2');
// 单车添加指定人员列表
export const specialWorkerList= (params, flag) => request(Urls.ADDSPECIALLIST, params, 'POST', flag);
// 重点保障区域结束按钮
export const securityAreaDetail= (id) => request(`${Urls.KEYAREADETAILS}${id}`, null, 'GET', '2');
// 重点保障区域新增
export const securityAreaAdd= (params, flag) => request(Urls.KEYAREAADD, params, 'POST', flag);
// 重点保障区域编辑
export const securityAreaEdit= (params, flag) => request(Urls.KEYAREAEDIT, params, 'POST', flag);
export const keyRuleProduct= ( params, flag) => request(Urls.KEYRULEDETAIL, params, 'POST', flag);
// 大屏模糊搜索
export const screenSearchLenovo= ( params, flag) => request(Urls.SCREENSEARCH, params, 'POST', flag);
// 获取成华区监测数据
export const getAreaRate = (flag) =>request(Urls.GETCHENGHUARATE, null, 'GET', flag);
// 修改成华区显示比率
export const setRateChengHua= ( params, flag) => request(Urls.SETCHENGHUARATE, params, 'POST', flag);
// SSO登录用户获取登录信息
export const getLoginTreeMenu = (flag) =>request(Urls.GETLOGINTREEMENU, null, 'GET', flag);
