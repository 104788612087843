import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, Input, message, Space, Spin, Tree} from 'antd';
import {addRole, getMenuListData, getMenusData, updateRole} from '../../../api';
import './style.css';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {GETROLELIST} from '../../../api/Urls';
import {Link} from 'react-router-dom';

const Roles = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [updateList, setUpdateList] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [menuList, setMenuList] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [treeKeys, setTreeKeys] = useState([]);
  const columns = [
    {
      title: t('role'),
      dataIndex: 'roleName',
      width: 200,
      key: 'roleName',
    },
    {
      title: '关联用户',
      width: 160,
      dataIndex: 'relevanceUser',
      key: 'relevanceUser',
    },
    {
      title: '角色备注',
      dataIndex: 'remark',
      key: 'remark',
    }, {
      title: t('action'),
      key: 'action',
      width: 300,
      // eslint-disable-next-line
      render: (record) => (
        <Space size="small">
          {parseInt(record.roleId)!==1&& <Button type="link" size="small" onClick={() => {
            handleMenuById(record.roleId);
            form.setFieldsValue(record);
            setModalType('edit');
            getTreeList(parseInt(record.roleId));
          }}>{t('edit')}</Button>}
          <Button type="link" size="small">
            <Link to={`details/${record.roleId}`}>详情</Link>
          </Button>
        </Space>
      ),
    },
  ];
  const menuTree = (
    <Tree
      checkable
      treeData={menuList}
      defaultExpandAll
      checkedKeys={checkedKeys}
      onCheck={(checkedKeys, info) => {
        setCheckedKeys(checkedKeys );
        setTreeKeys(checkedKeys.concat(info.halfCheckedKeys));
        form.setFieldsValue({
          menuIds: checkedKeys.filter((item) => parseInt(item) !== 0),
        });
      }}
    />
  );
  const handleRole = (values)=>{
    setLoading(true);
    if (checkedKeys.length===0) {
      message.error(t('roleMsg'));
      setLoading(false);
      return false;
    }
    const data={
      ...values,
      menuIds: treeKeys,
    };

    const RequestPromise = modalType === 'add' ?
      addRole(data, t('flag')) :
      updateRole(data, t('flag'));

    RequestPromise.then((res)=> {
      message.success(res.msg);
      setModalVisible(false);
      setUpdateList(updateList + 1);
    }).finally(()=>{
      setLoading(false);
    });
  };
  const editForm = ( <Form
    layout="vertical"
    name="settingForm"
    onFinish={handleRole}
    form={form}>
    {modalType==='edit'&&(<Form.Item
      name="roleId"
      label="Id"
      hidden
      shouldUpdate
    >
      <Input autoComplete="off" style={{width: 257}} disabled/>
    </Form.Item>)}
    <Form.Item
      name="roleName"
      label={t('roleName')}
      rules={[{
        required: true,
        message: t('roleNameMsg'),
        // type: 'string',
        max: 50,
      }]}
      shouldUpdate
    >
      <Input autoComplete="off" placeholder="请输入" maxLength={10} disabled={modalType==='edit'}/>
    </Form.Item>
    <Form.Item label="备注" name="remark">
      <Input.TextArea placeholder="请输入" maxLength={200}/>
    </Form.Item>
    <Form.Item
      name="menuIds"
      label="查看页面权限"
      rules={[{
        required: true,
        message: '请选择页面权限',
      }]}>
      <div className="border">
        {menuTree}
      </div>
      <Input style={{display: 'none'}}/>
    </Form.Item>

    {/* <Card title={t('permission')} style={{maxHeight: 480, overflow: 'scroll', marginBottom: 44}}>
      {menuTree}
    </Card>*/}
    <div className="padding-t-l padding-b-l"/>
    <div className="modal-button">
      <div>
        <Space>
          <Button type="primary" ghost onClick={() => {
            form.resetFields();
            setModalVisible(false);
          }}>
            {t('cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('submit')}
          </Button>
        </Space>
      </div>
    </div>
  </Form>);
  const handleMenuById = (id)=>{
    getMenusData(id, t('flag')).then((res) => {
      const a = [];
      const b = [];
      const c = [];
      res.data.children.forEach((item)=>{
        if (item.hasChildren) {
          item.children.forEach((inner)=>{
            b.push(inner.id);
          });
        } else {
          c.push(item.id);
        }
        a.push(item.id);
      });
      setCheckedKeys(b.concat(c));
      form.setFieldsValue({menuIds: a.concat(b)});
      setTreeKeys(a.concat(b));
      // setTreeKeys(checkedKeys);
      setModalVisible(true);
    }).finally(() => setLoading(false));
  };

  const getMenus = () => {
    // setLoading(true);
    getMenuListData({}, t('flag'))
        .then((res) => {
          setTreeList(res.data.children);
          getTreeList(0, res.data.children );
        });
  };
  const getTreeList= (roleId, list)=>{
    const tr=[];
    const List=list||treeList;
    List.forEach((item) => {
      tr.push({
        title: t('flag') === '1' ? item.text : item.cnName,
        key: item.id,
        disabled: roleId!==2&&item.id==='265',
        children: item.children.map((inner) => (
          {
            title: t('flag') === '1' ? inner.text : inner.cnName,
            key: inner.id,
            disabled: roleId!==2&&inner.id==='266'|| roleId!==2&&inner.id==='267',
          }
        )),
      });
    });
    const tree = [{
      key: '0',
      title: '全选',
      children: tr,
    }];
    setMenuList(tree);
  };
  const addNewRole = () => {
    setCheckedKeys([]);
    setModalVisible(true);
    form.resetFields();
    setModalType('add');
  };
  useEffect(() => {
    getMenus();
  }, [t('flag')]);
  return <div>
    <Spin spinning={loading}>
      <CustomTable
        hiddenExport={true}
        InitApi={GETROLELIST}
        updateList={updateList}
        columns={columns}
        resType="POST"
        addFuc={addNewRole}
      />
      <Drawer
        title={t(modalType)}
        visible={modalVisible}
        maskClosable={false}
        onClose={() => {
          setModalVisible(false);
        }}
        width={460}
        footer={null}
        // className='settings-modal'
        destroyOnClose>
        {editForm}
      </Drawer>
    </Spin>
  </div>;
};

Roles.propTypes = {

};

export default Roles;
