import axios from 'axios';
import {storage} from '../utils/storage';
import {message} from 'antd';
let msgAlert = false;
// const noPermission = false;
const menusId = (v) => {
  if (v && v.menuTree) {
    const menuTree = v.menuTree.children;
    const l = window.location.pathname.split('/');
    if (l.length < 2) {
      return '';
    }
    const pID = menuTree?.find(({menuType}) => menuType === l[1]);
    if (pID) {
      if (pID.children && pID.children.length > 0) {
        const cID = pID.children.find(({menuType}) => menuType === l[2]);
        return cID ? cID.id : '';
      }
      return pID.id;
    }
  }
  return '';
};

// axios.defaults.timeout = 20000;

axios.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  switch (parseInt(response.data.code)) {
    case 200:
      return response.data;
    case 604:
      return response.data;
    case 605:
      return response.data;
    case 606:
      return response.data;
    case 608:
      return response.data;
    case 609:
      return response.data;
    case 402: {
      if (!msgAlert) {
        storage.clearData('local', 'userInfo');
        storage.clearData('local', 'alarmType');
        storage.clearData('session', 'isShowKeyArea');
        storage.clearData('session', 'isShowMessage');
        msgAlert = true;
        message.error(response.data.msg);
        setTimeout(() => {
          msgAlert = false;
        }, 1000);
        return window.document.location.href = '/login';
      }
      return Promise.reject(response.data.msg);
    }
    case 408: {
      if (!msgAlert) {
        storage.clearData('local', 'userInfo');
        storage.clearData('local', 'alarmType');
        storage.clearData('session', 'isShowKeyArea');
        storage.clearData('session', 'isShowMessage');
        msgAlert = true;
        message.error(response.data.msg);
        setTimeout(() => {
          msgAlert = false;
        }, 1000);
        return window.document.location.href = '/login';
      }
      return Promise.reject(response.data.msg);
    }

    default: {
      const MSG = response.data.msg ? response.data.msg : '网络错误，请稍后再试！';
      message.error(MSG);
      return Promise.reject(MSG);
    }
  }
}, (error) => {
  const MSG = '网络错误，请稍后再试！';
  // message.error(MSG);
  return Promise.reject(MSG);
});


export const request = async (url, params, type, flag, header = {}, timeout, data) => {
  const userInfo = storage.getData('userInfo');
  const MID = userInfo ? menusId(userInfo) : null;
  const token = userInfo ? userInfo.token : null;
  const headers = {
    'Content-Type': 'application/json',
    'Client-Type': 'web',
    'Authorization': token,
    'menuId': MID,
    'flag': flag,
    ...header,
  };
  if (window.location.pathname !== '/login') {
    // if (token && !MID && !noPermission &&
    // window.location.pathname !== '/noPermission' && window.location.pathname !== '/') {
    // noPermission = false;
    setTimeout(() => {
      // noPermission = false;
    }, 1000);
    // return window.document.location.href = '/noPermission';
    // }
  }


  // if (!header&&token===null&&window.document.location.pathname.length<2) {
  //   // console.log(window.document.location.pathname, header.token, token);
  //   // setTimeout(()=>{
  //   //   window.document.location.href='/login';
  //   //   return null;
  //   // }, 10000);
  //   return null;
  // }

  let options = {
    method: type.toUpperCase(),
    headers: headers,
    timeout: timeout || 100 * 1000,
  };
  if (type.toUpperCase() === 'GET' || type.toUpperCase() === 'DELETE') {
    options = {
      ...options,
      url,
      params,
      data,
    };
  } else {
    options = {
      ...options,
      data: params,
      url,
    };
  }
  return await axios(options);
};


