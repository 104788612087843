import React, {useEffect, useState} from 'react';
import {Button, Col, Form, message, Modal, Row, Space, Spin, Table} from 'antd';
import PropTypes from 'prop-types';
import editIcon from '../../../asset/addMember.png';
import {
  bikeCompany,
  onlineStatus,
  ruleSwitchs,
  staffStatus,
  workStatus,
} from '../../../components/statusCollection';
import {addGroupMember, getAttendanceMemberList, quickSetting, removeGroupMember} from '../../../api';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import QuickSetting from '../../../components/quickSetting/QuickSetting';
import './style.css';

const MemberInfo = ({baseInfo, id, streetId, finished}) => {
  const [update, setUpdate] = useState(0);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('添加运维人员');
  const [jobStatus, setJobStatus] = useState(null);
  const [selected, setSelected] = useState(false);
  const [attendanceRuleList, setAttendanceRuleList] = useState([]);
  const [fromValue, setfromValue]=useState();
  const [quickMessage, setQuickMessage]=useState();

  const getMemberList = () => {
    getAttendanceMemberList(streetId, {}, 2)
        .then((res) => {
          setData(res.data);
        })
        .finally();
  };
  // 快捷设置
  const onSetting = () => {
    setLoading(true);
    const obj = {...fromValue};
    delete obj.name;
    delete obj.phoneNumber;
    quickSetting({
      ...obj,
    }).then((r) => {
      message.success(r.msg);
      setJobStatus(null);
      setVisible(false);
      form.resetFields();
      finished();
    }).finally(() => {
      setLoading(false);
    });
  };
  const onSave = () => {
    const data = {
      memberIds: selectedRowKeys,
    };
    setLoading(true);
    addGroupMember(id, data, 2)
        .then((res) => {
          message.success(res.msg);
          finished();
          setSelectedRowKeys([]);
          setVisible(false);
          setUpdate(update + 1);
        })
        .finally(() => {
          setLoading(false);
        });
  };
  const deleteMember = (memberId) => {
    removeGroupMember(id, memberId, 2).then((res) => {
      message.success(res.msg);
      setUpdate(update + 1);
      finished();
    });
  };

  const columns = [
    {
      title: '所属企业',
      dataIndex: 'company',
      render: (company) => bikeCompany(company),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
    },
    {
      title: '在线状态',
      dataIndex: 'onlineStatus',
      // eslint-disable-next-line
      render: (record) => onlineStatus(record),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (jobType) => staffStatus(jobType),
    },
  ];
  const columnsOwn = [
    {
      title: '所属企业',
      dataIndex: 'company',
      render: (company) => bikeCompany(company),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
    },
    {
      title: '在线状态',
      dataIndex: 'onlineStatus',
      // eslint-disable-next-line
      render: (record) => onlineStatus(record),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (jobType) => staffStatus(jobType),
    },
    {
      title: '工作状态',
      dataIndex: 'jobStatus',
      render: (jobStatus) => workStatus(jobStatus),
    },
    {
      title: '考勤规则',
      dataIndex: 'jobStatus',
      render: (jobStatus) => ruleSwitchs(jobStatus),
    },

    /*eslint-disable*/
    {
      title: '操作',
      dataIndex: 'id',
      render: (id, record) => (
        <Space>
          <Button type="link">
            <Link to={`/personnelManagement/staffManagement/details/${record.id}`}>详情</Link>
          </Button>
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setModalTitle('运维人员快捷设置');
              record.trackFrequency = '3分钟/次';
              setJobStatus(record.jobStatus);
              setAttendanceRuleList(record.attendanceRuleList);
              setQuickMessage({
                operationId:record.id,
                name: record.name,
                phoneNumber: record.phoneNumber,
                trackFrequency: '3分钟/次',
                jobStatus: record.jobStatus,
              });
            }}
          >
            快捷设置
          </Button>
          <Button
            type="link"
            onClick={() => {
              Modal.confirm({
                title: '确认移除组员',
                icon: <ExclamationCircleOutlined />,
                content: (
                  <div>
                    移除该运维人员后，运维人员无需打卡，是否确认移除？
                    <div className="attenddance-del">姓名：{record.name}</div>
                    <div>联系电话：{record.phoneNumber}</div>
                  </div>
                ),
                onOk() {
                  deleteMember(id);
                },
                onCancel() {},
              });
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
    /*eslint-disable*/
  ];

  useEffect(() => {
    getMemberList();
  }, [update]);

  return (
    <div>
      <h3 className="details-title">
        组员信息
        <span className="padding-l-m btn">
          <img
            src={editIcon}
            alt="edit"
            onClick={() => {
              setVisible(true);
              setModalTitle('添加运维人员');
            }}
          />
        </span>
      </h3>
      <div className="details-info margin-t-l">
        <Row className="margin-b-l">
          <Col span={6}>
            <span className="padding-r-m">组员总数：</span>
            <span className="text-gray"> {baseInfo.groupMemberCount || 0}</span>
          </Col>
          <Col span={18}>
            <span className="padding-r-m">组员各状态数量：</span>
            <Space size="large">
              <span>
                {workStatus(1)}
                <span className="padding-l-s text-gray">{baseInfo.normal}</span>
              </span>
              <span>
                {workStatus(2)}
                <span className="padding-l-s text-gray">{baseInfo.onVocation}</span>
              </span>
              <span>
                {workStatus(3)}
                <span className="padding-l-s text-gray">{baseInfo.support}</span>
              </span>
            </Space>
          </Col>
        </Row>
      </div>
      <Table dataSource={baseInfo.groupMemberInfoList} columns={columnsOwn} />
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        destroyOnClose
        width={modalTitle === '添加运维人员' ? 800 : 520}
        onCancel={() => {
          setVisible(false);
          setSelectedRowKeys([]);
          setSelected(false);
        }}
        footer={null}
      >
        <Spin spinning={loading}>
          {modalTitle === '添加运维人员' ? (
            <Table
              rowKey={(record) => record.id}
              dataSource={data}
              columns={columns}
              className="margin-b-l"
              rowSelection={{
                selectedRowKeys,
                onChange: (keys) => setSelectedRowKeys(keys),
              }}
            />
          ) : (
            <QuickSetting
            visible={visible}
            selected={selected}
            attendanceRuleList={attendanceRuleList}
            setSelected={setSelected}
            ruleSet={jobStatus}
            detailsList ={quickMessage}
            fromValue={setfromValue}
          />
          )}
          <div className="modal-button text-right">
            <div>
              <Space>
                <Button
                  onClick={() => {
                    setVisible(false);
                    if (modalTitle === '添加运维人员') {
                      setSelectedRowKeys([]);
                    } else {
                      setJobStatus(null);
                      form.resetFields();
                    }
                  }}
                >
                  取消
                </Button>
                {modalTitle === '添加运维人员' ? (
                  <Button type="primary" onClick={onSave}>
                    保存
                  </Button>
                ) : (
                  <Button type="primary" onClick={onSetting}>
                    确认
                  </Button>
                )}
              </Space>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

MemberInfo.propTypes = {
  baseInfo: PropTypes.object,
  streetId: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  finished: PropTypes.func.isRequired,
};

export default MemberInfo;
