import React from 'react';
import {Link} from 'react-router-dom';
import {Result, Button} from 'antd';
import {connect} from 'react-redux';

const ErrorPage=()=> {
  return (
    <Result
      className="marginT32"
      status="404"
      title="404"
      subTitle="抱歉，你访问的页面不存在。"
      extra={<Button type="primary"><Link to="/home" >返回主页</Link></Button>}
    />
  );
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(ErrorPage);
