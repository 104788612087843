import React from 'react';
import PropTypes from 'prop-types';
import ListDetail from './ListDetail';
import LineGraph from './LineGraph';
import {LISTBIKE} from '../../../api/Urls';
import {bikeCompany, bikeType as typeBike} from '../../../components/statusCollection';

const PolylineSearch = ({promptTitle, tab, filedData, timeReset, timeSearch, chartTime, menuTab, incomeDate}) => {
  const roleName = JSON.parse(localStorage.getItem('userInfo'))?.roleName;

  const formListBicycle = [
    {
      label: '所属车企',
      key: 'bikeType',
      col: 6,
      name: 'companyList',
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      options: [
        {label: '美团', value: 1},
        {label: '青桔', value: 2},
        {label: '哈啰', value: 3},
      ],
    },
    {
      label: '设备所属区域',
      key: 'areaList',
      col: 6,
      name: 'areaList',
      type: 'areaCascaderMul',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
    },
    {
      col: 7,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      valueName: 'content',
      typeOptions: [
        {label: '设备名称', value: 1},
        {label: '设备IMEI', value: 2},
      ],
    },
  ];

  const columnsBicycle = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      width: 70,
    },
    {
      title: '车辆类型',
      dataIndex: 'bikeType',
      key: 'bikeType',
      onExport: true,
      width: 80,
      render: (bikeType) => <span>{typeBike(bikeType)}</span>,
      exportRender: (render) => typeBike(render.bikeType),
    },
    {
      title: '所属车企',
      dataIndex: 'bikeEnterprise',
      key: 'bikeEnterprise',
      width: 80,
      render: (bikeEnterprise) => <span>{bikeCompany(bikeEnterprise)}</span>,
      exportRender: (render) => bikeCompany(render.bikeEnterprise),
      onExport: true,
    },
    {
      title: '实体车牌号',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      width: 110,
      type: 'cross',
      onExport: true,
      render: (licensePlate) => <span>{licensePlate || '-'}</span>,
      exportRender: (render) => render.licensePlate || '-',
    },
    {
      title: '车锁号',
      dataIndex: 'lockNumber',
      key: 'lockNumber',
      type: 'cross',
      width: 100,
      onExport: true,
      render: (lockNumber) => <span>{lockNumber || '-'}</span>,
      exportRender: (render) => render.lockNumber || '-',
    },
    {
      title: '蓝牙MAC地址',
      width: 130,
      dataIndex: 'bluetoothMacAddress',
      key: 'bluetoothMacAddress',
      onExport: true,
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      key: 'frameNumber',
      type: 'cross',
      width: 100,
      onExport: true,
      render: (frameNumber) => <span>{frameNumber || '-'}</span>,
      exportRender: (render) => render.frameNumber || '-',
    },
    {
      title: '车辆所属区域',
      dataIndex: 'bikeAreaName',
      key: 'bikeAreaName',
      type: 'cross',
      onExport: true,
      width: 140,
      render: (bikeAreaName) => <span>{bikeAreaName || '无区域'}</span>,
      exportRender: (render) => render.bikeAreaName || '无区域',
    },
    {
      title: 'RSSI',
      width: 70,
      dataIndex: 'signalStrengthIndicator',
      key: 'signalStrengthIndicator',
      onExport: true,
    },
    {
      title: '设备IMEI',
      width: 130,
      dataIndex: 'mobileEquipmentIdentity',
      key: 'mobileEquipmentIdentity',
      onExport: true,
    },
    {
      title: '设备名称',
      width: 130,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      onExport: true,
    },
    {
      title: '设备所属区域',
      width: 160,
      dataIndex: 'deviceAreaAddress',
      key: 'deviceAreaAddress',
      onExport: true,
      render: (_, render) => (
        <span>
          {render.equipmentCityName ?
            render.equipmentCityName + render.equipmentAreaName + render.equipmentStreetName :
            '无区域'}
        </span>
      ),
      exportRender: (render) =>
        render.equipmentCityName ?
          render.equipmentCityName + render.equipmentAreaName + render.equipmentStreetName :
          '无区域',
    },
    {
      title: '入库时间',
      width: 110,
      dataIndex: 'createTime',
      key: 'createTime',
      onExport: true,
      render: (createTime) => <span>{createTime || '-'}</span>,
      exportRender: (render) => render.createTime || '-',
    },
  ];

  const notRecordColumns = (tab) => {
    if (tab === 3) {
      const column = [...columnsBicycle];
      // eslint-disable-next-line no-prototype-builtins
      const data = column?.filter((item) => !item.hasOwnProperty('type'));
      return data;
    }
  };

  return (
    <div>
      <div className="car-column padding-m">
        <LineGraph
          filedData={filedData}
          promptTitle={promptTitle}
          timeReset={timeReset} // 时间重置
          timeSearch={timeSearch} // 时间搜索
          chartTime={chartTime} // 获取折线图点击时间
          menuTab={menuTab}
          incomeDate={incomeDate}
          tab={tab}
        />
        {(tab === 3 || tab === 5) && (roleName === '超级管理员'||roleName ==='超级管理') && (
          <ListDetail
            initApi={`${LISTBIKE}1`}
            tab={tab}
            formList={formListBicycle}
            columns={(tab === 3 ? notRecordColumns(tab) : columnsBicycle)}
            listTitle={`最近一日${tab == 3 ? '未备案' : '跨区域'}单车列表`}
            apiType={{apiType: tab === 3 ? 1 : 2}}
            exportTime={false}
            statistics={'列表单车数'}
          ></ListDetail>
        )}
      </div>
    </div>
  );
};
PolylineSearch.propTypes = {
  promptTitle: PropTypes.string,
  tab: PropTypes.number,
  filedData: PropTypes.any,
  timeReset: PropTypes.func,
  timeSearch: PropTypes.func,
  chartTime: PropTypes.any, // 列表与趋势图交互
  menuTab: PropTypes.number,
  incomeDate: PropTypes.any,
};
export default PolylineSearch;
