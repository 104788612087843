import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Select, Space, Button, Col, Row, Cascader} from 'antd';
import {useEffect} from 'react';
import {getAreaTreeFilter2} from '../../../../api';
// import {InfoCircleFilled} from '@ant-design/icons';

const SetStreetId=({onFinished, params, onCancel, initData})=> {
  const [settingForm] = Form.useForm();
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaOptions2, setAreaOptions2] = useState([]);
  const [streetId, setStreetId]= useState(null);
  const [streeIsAble, setStreeIsAble]=useState(false);
  const getAreaGroup = (params) => {
    getAreaTreeFilter2(params, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type=!params?1:
          params.roleId===2?2:
          params.roleId===3?3:
          params.roleId===4?4: 0;
      const data = res.data.map((item) => {
        return {
          ...item,
          value: item.id+'',
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}
            <span className="text-light"> &nbsp;&nbsp; {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              // ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={type===3&&sub.managerName?'text-light-disabled':'text-light'}
                > &nbsp;&nbsp;{sub.managerName || ''}</span>
              </span>),
              value: sub.id+'',
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName,
            };
          }) : [],
        };
      });
      const data2 = res.data.map((item) => {
        return {
          ...item,
          value: item.id+'',
          areaManagerName: item.managerName || '',
          label: (<span>
            {item.areaName}
            <span className="text-light"> &nbsp;&nbsp; {item.managerName || ''}</span>
          </span>),
          children: item.children ? item.children.map((sub) => {
            return {
              ...sub,
              label: (<span>
                {sub.areaName}
                <span
                  className={type===3&&sub.managerName?'text-light-disabled':'text-light'}
                > &nbsp;&nbsp;{sub.managerName || ''}</span>
              </span>),
              value: sub.id+'',
              streetManagerName: sub.managerName || '',
              disabled: type===3&&sub.managerName,
              children: sub.children ? sub.children.map((aff) => {
                return {
                  ...aff,
                  label: (<span>
                    {aff.areaName}
                    <span
                      className={type===4&&aff.managerName?'text-light-disabled':'text-light'}
                    > &nbsp;&nbsp;{aff.managerName || ''}</span>
                  </span>),
                  value: aff.id+'',
                  streetManagerName: aff.managerName || '',
                  disabled: type===4&&aff.managerName&&aff.userName!==initData.userName,
                };
              }) : [],
            };
          }) : [],
        };
      });
      setAreaOptions(data);
      setAreaOptions2(data2);
      const arr=[];
      initData.areaList.forEach((element, index) => {
        if (index === 0) {
          const fid=element.split(',').slice(0, 2);
          settingForm.setFieldsValue({
            fID: fid,
          });
          const list =data2.find((OptionsData) => OptionsData.value===fid[0]);
          const list1= list.children.find((item)=>item.value=== fid[1]);
          setStreetId(list1.children);
        }
        arr.push(element.split(',')[2]);
      });
      settingForm.setFieldsValue({
        areaId: arr,
      });
      if (settingForm.getFieldValue(['fID'])) {
        setStreeIsAble(true);
      }
    });
  };
  const onSubmit = (values) => {
    const data={};
    Object.keys(values).forEach((key) => {
      if (key!=='fID') {
        data[key]=values[key];
      }
    });
    onFinished(data);
  };
  useEffect(()=>{
    getAreaGroup(params);
    settingForm.setFieldsValue({
      roleId: initData.roleId,
      userId: initData.userId,
    });
  }, []);
  return (
    <Form name="area" onFinish={onSubmit} form={settingForm}>
      <Form.Item name="userId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="roleId" hidden>
        <Input />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item name="fID">
            <Cascader options={areaOptions}
              // optionLabelProp="areaName"
              onChange={(value)=>{
                if (value) {
                  setStreeIsAble(true);
                  const list =areaOptions2.find((OptionsData) => OptionsData.value===value[0]);
                  const list1= list.children.find((item)=>item.value=== value[1]);
                  setStreetId(list1.children);
                  settingForm.resetFields(['areaId']);
                } else {
                  setStreeIsAble(false);
                  settingForm.resetFields(['areaId']);
                }
              }}
              placeholder="请选择市/区(单选)"
            >
            </Cascader>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="areaId">
            <Select
              allowClear
              placeholder="请选择街道(多选)"
              options={streetId}
              disabled={!streeIsAble}
              maxTagCount="responsive"
              // optionLabelProp="areaName"
              mode="multiple" />
          </Form.Item>
        </Col>
      </Row>
      {/* <div className='padding-b-m text-light'>
        <InfoCircleFilled style={{color: '#FAAD14'}} className="margin-r-s"/>
                  管辖区域非必填，未选择区域则将自动显示为“无区域”
      </div> */}
      <Form.Item noStyle>
        <div className="modal-button">
          <div>
            <Space size="large">
              <Button onClick={() => {
                settingForm.resetFields();
                onCancel();
              }} type="primary" ghost>取消</Button>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Space>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};
SetStreetId.propTypes = {
  onFinished: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  initData: PropTypes.object.isRequired,
};

export default SetStreetId;
