import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import CustomTable from '../../components/customTable';
import FormOfDrawer from './components/FormOfDrawer';
import {Button, Space, Popconfirm, message, Modal, Form, Input, Cascader} from 'antd';
import {resetStaffPwd, batchDeleteStaffs, addJurisdiction} from '../../api';
import PropTypes from 'prop-types';
import {formStaffList, formCityList} from './components/FromSearchTitle';
import {areaStreetTreeData} from '../../components/forms/AreaTreeFuc';

import DocumentStaff from './../../asset/staffList.xlsx';
import DocumentCity from './../../asset/cityList.xlsx';
import './style.css';
import {GETSTAFFLIST, IMPORTEXCELSTAFF, IMPORTEXCELCITY, GETCITYLIST} from '../../api/Urls';
import {
  staffStatus,
  workStatus,
  workStatusText,
  staffStatusText,
  bikeCompany,
  onlineStatus,
  onlineStatusText,
  jobRank,
  manageEnterprise,
  administerArea,
  positionLevel,
} from '../../components/statusCollection';

const StaffManagement = ({menuType}) => {
  const [form] = Form.useForm();
  const [updateList, setUpdateList] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [formType, setFormType] = useState('');
  const [staffName, setStaffName] = useState('暂无数据');
  const [staffWordId, setStaffWorkId] = useState('暂无数据');
  const [visible, setVisible] = useState(false);
  const [areaOptions, setAreaOptions] = useState([]);

  const [resetOptions, setResetOptions] = useState(0);
  const isMounted = useRef(false);

  const columnsStaff = [
    {
      title: '所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 120,
      fixed: 'left',
      // eslint-disable-next-line
      render: (record, item) => bikeCompany(record),
      exportRender: (render) => bikeCompany(render.company),
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
      width: 110,
      fixed: 'left',
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '职务等级',
      dataIndex: 'positionLevel',
      key: 'positionLevel',
      onExport: true,
      width: 110,
      fixed: 'left',
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>
          {jobRank(item.positionLevel, parseInt(item.operationStatus))}
        </span>
      ),
      exportRender: (render) => positionLevel(render.positionLevel),
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      onExport: true,
      width: 110,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '在线状态',
      dataIndex: 'onlineStatus',
      key: 'onlineStatus',
      onExport: true,
      width: 110,
      // eslint-disable-next-line
      render: (status, record) => (record.jobType !== 7 ? onlineStatus(status) : '-'),
      exportRender: (render) => (render.jobType !== 7 ? onlineStatusText(render.onlineStatus) : '-'),
    },
    {
      title: '工作状态',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
      onExport: true,
      render: (record) => workStatus(record),
      width: 110,
      exportRender: (render) => workStatusText(render.jobStatus),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
      onExport: true,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      onExport: true,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{staffStatus(record)}</span>
      ),
      width: 150,
      exportRender: (record) => staffStatusText(record.jobType),
    },
    {
      title: '管辖区域',
      dataIndex: 'jurisdictionName',
      key: 'jurisdictionName',
      onExport: true,
      width: 200,
      // eslint-disable-next-line
       render: (jurisdictionName,item) => {
        return <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>
          {jurisdictionName ?administerArea(jurisdictionName):<span>{item.operationStatus===1?'-':'无区域'}</span>}
        </span>;
      },
      exportRender: (render) => render.jurisdictionName||'无区域',
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      width: 200,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record||'无区域'}</span>
      ),
      exportRender: (render) => render.areaName||'无区域',
    },
    {
      title: '考勤组',
      dataIndex: 'groupName',
      key: 'groupName',
      onExport: true,
      width: 180,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record||'-'}</span>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: 280,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record, item) => (
        <Space size="small">
          {/* 详情 */}
          <Button type="link">
            <Link to={`details/${record.id}`}>详情</Link>
          </Button>
          {/* 重置密码 */}
          {item.operationStatus == 1 && (
            <Popconfirm
              title={
                <div className="staffManagement-reset">
                  <div className="reset-title">是否确认重置密码？</div>
                  <div className="reset-title">重置后密码为88888888</div>
                  <div className="reset-title">姓名：{staffName || '暂无数据'}</div>
                  <div>联系电话：{staffWordId || '暂无数据'}</div>
                </div>
              }
              onConfirm={() => {
                resetStaffPwd(item.id, 2).then((res) => message.success('重置密码成功'));
              }}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link" onClick={() => setInfo(item)}>
                重置密码
              </Button>
            </Popconfirm>
          )}
          {/* 管辖区域 */}
          {item.operationStatus == 1 && item.positionLevel===2&&(
            <Button
              type="link"
              onClick={() => {
                // getAreaGroup();
                // 管辖区域数据回显
                form.setFieldsValue({
                  id: item.id,
                  name: item.name,
                  company: item.company === 1 ? '美团' : item.company === 2 ? '青桔' : '哈啰',
                  phoneNumber: item.phoneNumber,
                  jurisdiction: dateTypeChange(item.jurisdictionList),
                });
                setVisible(true);
              }}
            >
              管辖区域
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const columnsCity = [
    {
      title: '城管企业',
      dataIndex: 'manageEnterprise',
      key: 'manageEnterprise',
      onExport: true,
      width: 120,
      fixed: 'left',
      // eslint-disable-next-line
      render: (record, item) => manageEnterprise(record),
      exportRender: (render) => manageEnterprise(render.manageEnterprise),
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
      width: 110,
      fixed: 'left',
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
      onExport: true,
      width: 110,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '工作状态',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
      onExport: true,
      render: (record) => workStatus(record),
      width: 110,
      exportRender: (render) => workStatusText(render.jobStatus),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
      onExport: true,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record}</span>
      ),
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      width: 200,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{record||'无区域'}</span>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      fixed: 'right',
      // eslint-disable-next-line
      render: (record, item) => (
        <Space size="small">
          <Button type="link">
            <Link to={`details/${record.id}`}>详情</Link>
          </Button>
          {item.operationStatus == 1 && (
            <Popconfirm
              title={
                <div className="staffManagement-reset">
                  <div className="reset-title">是否确认重置密码？</div>
                  <div className="reset-title">重置后密码为88888888</div>
                  <div className="reset-title">姓名：{staffName || '暂无数据'}</div>
                  <div>联系电话：{staffWordId || '暂无数据'}</div>
                </div>
              }
              onConfirm={() => {
                resetStaffPwd(item.id, 2).then((res) => message.success('重置密码成功'));
              }}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link" onClick={() => setInfo(item)}>
                重置密码
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];


  // 所属区域
  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions && selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{option.areaName}</span>;
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };

  const setInfo = (item) => {
    setStaffName(item.name);
    setStaffWorkId(item.phoneNumber);
  };

  // 批量删除人员
  const batchDelete = (ids) => {
    batchDeleteStaffs({ids}, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    });
  };
  // 管辖区域设置弹框内容
  const jurisdiction = (
    <>
      <div style={{background: '#F5F5F5', padding: 8, marginBottom: 16}}>
        <div className="text-gray">运维负责人管辖区域非必填，可管辖多个区，多个街道。</div>
      </div>
      <Form name="settingForm" form={form} onFieldsChange={(e) => {}} initialValues={{}}>
        <Form.Item name="id" label="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="姓名" name="name">
          <Input bordered={false} disabled style={{color: 'rgba(0, 0, 0, 0.85)'}} />
        </Form.Item>
        <Form.Item label="所属企业" name="company">
          <Input bordered={false} disabled style={{color: 'rgba(0, 0, 0, 0.85)'}} />
        </Form.Item>
        <Form.Item label="联系电话" name="phoneNumber">
          <Input bordered={false} disabled style={{color: 'rgba(0, 0, 0, 0.85)'}} />
        </Form.Item>
        <Form.Item label="管辖区域" name="jurisdiction">
          <Cascader
            maxTagCount="responsive"
            displayRender={displayRender}
            placeholder="请选择管辖区域"
            options={areaOptions}
            className="example-img "
            multiple
          />
        </Form.Item>
      </Form>
    </>
  );
  // 设置管辖区域api
  const SubmitAdministerSet = () => {
    const areaValue = form.getFieldsValue();
    delete areaValue.name;
    delete areaValue.company;
    delete areaValue.phoneNumber;
    areaValue.jurisdiction = areaStreetTreeData(areaValue.jurisdiction, areaOptions, 'street');
    addJurisdiction({...areaValue}, 2).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
      setVisible(false);
    });
  };

  // 管辖区域数据转换
  const dateTypeChange = (data) => {
    const result = data?.map((str) => str.split(',').map((num) => parseInt(num)));
    // console.log(result);
    return result;
  };

  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, [menuType]);


  return (
    <div>
      <CustomTable
        customTitle={menuType === 'staff' ? '运维人员' : '城市管理者'}
        rowSelection={true}
        InitApi={menuType === 'staff' ? GETSTAFFLIST : GETCITYLIST}
        formList={menuType === 'staff' ? formStaffList : formCityList}
        updateList={updateList}
        columns={menuType === 'staff' ? columnsStaff : columnsCity}
        // batchFunction={menuType === 'staff' ? batchDelete : null}
        batchFunction={batchDelete}
        uploadUrl={menuType === 'staff' ? IMPORTEXCELSTAFF : IMPORTEXCELCITY}
        importType="staff"
        resType="POST"
        resetData={resetOptions}
        scroll={true}
        tip={menuType === 'staff' ? '运维人员管理导出中...' : '城市管理者导出中...'}
        addFuc={() => {
          setFormType('add');
          setDrawerVisible(true);
        }}
        exportFileName={menuType === 'staff' ? '运维人员记录' : '城市管理者记录'}
        updateBikeItems={updateList}
        setUpdateBikeItems={setUpdateList}
        buttonGroup={() => (
          <Button
            onClick={() => {
              message.success('导入模版下载成功');
            }}
            href={menuType === 'staff' ? window.location.origin + DocumentStaff : window.location.origin + DocumentCity}
            download={menuType === 'staff' ? '运维人员信息导入模板.xlsx' : '城市管理者信息导入模板.xlsx'}
          >
            下载导入模板
          </Button>
        )}
        getArea={(data)=>{
          setAreaOptions(data);
        }}
      />

      <FormOfDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        title={menuType === 'staff' ? '手动新增人员信息' : '手动新增城市管理者信息'}
        type={formType}
        menuType={menuType}
        updateList={updateList}
        setUpdateList={setUpdateList}
      />

      <Modal
        forceRender
        title="设置管辖区域"
        visible={visible}
        maskClosable={false}
        destroyOnClose
        closable={false}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        width={520}
        footer={[
          <Button
            key="1"
            onClick={() => {
              setVisible(false);
            }}
          >
            取消
          </Button>,
          <Button key="2" type="primary" onClick={SubmitAdministerSet}>
            确认
          </Button>,
        ]}
      >

        {jurisdiction}
      </Modal>
    </div>
  );
};

StaffManagement.propTypes = {
  menuType: PropTypes.string.isRequired,
};

export default StaffManagement;
