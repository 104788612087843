import { useEffect, useRef } from 'react';

const useResizeObserver = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        callback(entries[0].contentRect);
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [callback]);

  return ref;
};

export default useResizeObserver;
