import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Modal, Row, Space, Table, Button} from 'antd';
import {UserAddOutlined} from '@ant-design/icons';
import {getAttendanceMemberList} from '../../../api';
import {bikeCompany, onlineStatus, staffStatus} from '../../../components/statusCollection';


const Member = ({onChange, streetId}) => {
  const [data, setData] = useState([]);
  const [ownList, setOwnList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const getMemberList = () => {
    getAttendanceMemberList(streetId, 2).then((res)=>{
      setData(res.data);
    }).finally();
  };
  // const getOwnMemberList = () => {
  //   setOwnList([]);
  // };
  const columns = [
    {
      title: '所属企业',
      dataIndex: 'company',
      render: (company) => bikeCompany(company),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
    },
    {
      title: '在线状态',
      dataIndex: 'onlineStatus',
      // eslint-disable-next-line
      render: (record) => onlineStatus(record),
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (jobType) => staffStatus(jobType),
    },
  ];
  const columnsOwn = [
    {
      title: '所属企业',
      dataIndex: 'company',
      render: (company) => bikeCompany(company),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
    },
    {
      title: '在线状态',
      dataIndex: 'onlineStatus',
      // eslint-disable-next-line
      render: (record) => onlineStatus(record),
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      render: (jobType) => staffStatus(jobType),
    },
    /*eslint-disable*/
    {
      title: '操作',
      dataIndex: 'id',
      render: (id) => (
        <Button type="link" onClick={()=>{
          setOwnList(ownList.filter((item)=> item.id!==id));
          setSelectKeys(selectKeys.filter((item)=> item!==id));
          onChange(selectKeys.filter((item)=>item!==id));
        }}>删除</Button>
      ),
    },
    /*eslint-disable*/
  ];
  
  
  useEffect(() => {
    if(streetId){
      getMemberList();
    }
  }, [streetId]);
  return (
    <div>
      <Row>
        <Col span={20}>
          <h3 >考勤组员</h3>
        </Col>
        <Col span={4} className="text-right">
          <UserAddOutlined style={{fontSize: 20}} onClick={() => {
            setVisible(true);
          }}/>
        </Col>
      </Row>
      <Table dataSource={ownList} columns={columnsOwn}/>
      <Modal title="添加运维人员" 
      width={800}
      visible={visible} 
      destroyOnClose
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
        setSelectedRowKeys([]);
      }} footer={null}>
        <Table
          rowKey={(record)=>record.id}
          dataSource={data}
          columns={columns}
          rowSelection={{
            selectedRowKeys,
            onChange: (keys)=>setSelectedRowKeys(keys),
          }}/>
        <div className="modal-button text-right padding-t-m">
            <div>
        <Space>
          <Button 
          onClick={()=>{
            setVisible(false);
            setSelectedRowKeys([]);
          }}>取消</Button>
          <Button 
          type='primary'
          onClick={()=>{
            const arr=[];
            selectedRowKeys.forEach((item)=>{
              const l=data.find(({id})=>id===item);
              arr.push(l);
            });
            const keys= [...selectKeys,
              ...selectedRowKeys];
            const newKeys= Array.from(new Set(keys));
            setOwnList(arr);
            onChange(newKeys);
            setSelectKeys(newKeys);
            setSelectedRowKeys([]);
            setVisible(false);
          }}>保存</Button>
        </Space>
        </div>
        </div>
      </Modal>
    </div>
  );
};

Member.propTypes = {
  onChange: PropTypes.func.isRequired,
  streetId: PropTypes.string,
};

export default Member;
