/* eslint-disable react/react-in-jsx-scope */
import nation from './nation.json';
import politics from './politics.json';
import emergencyRelation from './emergencyRelation.json';
import degree from './degree.json';
import health from './health.json';
import maritalStatus from './maritalStatus.json';
import {Tooltip, Divider} from 'antd';
import {ExclamationCircleOutlined, ExclamationCircleFilled} from '@ant-design/icons';

const tooltip=(text)=>{
  return (
    <div style={{color: 'black'}}>
      <div>
        <ExclamationCircleFilled style={{color: '#FAAD14', marginRight: 9}}/>
        <span>提示说明</span>
      </div>
      <Divider className="margin-t-s margin-b-s"/>
      <p className="tipInfo">{text}</p>
    </div>
  );
};
const addForm=[
  {
    label: '基础信息',
    type: 'title',
  },
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    message: 'Please input !',
    message2: '请输入姓名',
    required: true,
    col: 12,
    placeholder: '请输入',
    maxLength: 10,
  },
  {
    label: '性别',
    name: 'sex',
    type: 'select',
    message: 'Please select send type!',
    placeholder: '请选择',
    // required: true,
    col: 12,
    options: [
      {
        value: 0,
        label1: '男',
        label2: '男',
      },
      {
        value: 1,
        label1: '女',
        label2: '女',
      },
    ],
  },
  {
    type: 'box',
    children: [{
      label: '联系电话',
      name: 'phoneNumber',
      type: 'input',
      rules: [
        {
          message: '请输入电话号',
          required: true,
        }, {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的电话号',
        }],
      placeholder: '请输入',
    },
    {
      label: '身份证号',
      name: 'idCard',
      type: 'input',
      onChangeKey: 'age',
      onChangeRule: 'age',
      rules: [
        {
          pattern: /(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '请输入正确的身份证号',
        }],
      placeholder: '请输入',
      maxLength: 18,
    },
    ],
    col: 12,
  },
  {
    label: '照片',
    name: 'file',
    type: 'image',
    message: 'Please select send type!',
    message2: '请上传照片！',
    col: 12,
  },
  {
    label: '年龄(自动计算)',
    name: 'age',
    type: 'input',
    message: 'Please input !',
    message2: '请输入',
    col: 12,
    disabled: true,
  },
  {
    label: '婚姻状况',
    name: 'maritalStatus',
    key: 'select Single1',
    type: 'select',
    col: 12,
    placeholder: '请选择',
    options: maritalStatus,
  },
  {
    label: '学历',
    name: 'degree',
    key: 'select Single2',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: degree,
  },
  {
    label: '民族',
    name: 'nation',
    key: 'select Single3',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: nation,
  },
  {
    label: '健康状况',
    name: 'health',
    key: 'select Single4',
    col: 12,
    type: 'select',
    // rules: [{
    //   message: '请选择健康状况',
    //   required: true,
    // }],
    placeholder: '请选择',
    options: health,
  },
  {
    label: '政治面貌',
    name: 'politics',
    key: 'select Single5',
    col: 12,
    type: 'select',
    placeholder: '请选择',
    options: politics,
  },
  {
    label: '紧急联系人姓名',
    name: 'emergencyName',
    type: 'input',
    message: 'Please input !',
    message2: '请输入紧急联系人姓名',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '紧急联系人电话',
    name: 'emergencyPhone',
    type: 'input',
    message: 'Please input !',
    message2: '请输入紧急联系人电话',
    rules: [
      {
        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        message: '请输入正确的电话号',
      }],
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '与紧急联系人的关系',
    name: 'emergencyRelation',
    key: 'select Single6',
    col: 12,
    type: 'select',
    // rules: [{
    //   message: '请选择与紧急联系人的关系',
    //   required: true,
    // }],
    placeholder: '请输入',
    options: emergencyRelation,
  },
  {label: ' ',
    type: '1',
    col: 12,
  },
  {
    label: '家庭地址',
    name: 'homeAddress',
    type: 'textArea',
    message: 'Please input !',
    message2: '请输入家庭地址!',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '联系地址',
    name: 'contactAddress',
    type: 'textArea',
    message: 'Please input !',
    message2: '请输入联系地址!',
    col: 12,
    placeholder: '请输入',
  },
  {
    label: '职务信息',
    type: 'title',
  },
  {
    label: '职务等级',
    name: 'positionLevel',
    col: 12,
    type: 'selectRank',
    key: 'rank',
    rank: 'rank',
    placeholder: '请选择',
    rules: [{
      message: '请选择职务等级',
      required: true,
    }],
    options: [
      {
        value: 1,
        label1: '普通 ',
        label2: '普通',
      },
      {
        value: 2,
        label1: '负责人',
        label2: '负责人',
      },
    ],
  },
  {
    label: '管辖区域',
    name: 'jurisdiction',
    message2: '请选择管辖区域',
    col: 12,
    type: 'jurisdiction',
    placeholder: '请选择',
  },
  {
    label: <span><span>所属区域</span> <Tooltip title={tooltip(
        '所属区域非必填，默认不填写显示‘无区域。’修改该运维人员所属区域，人员将同步从原考勤组中移除。')} color="white">
      <ExclamationCircleOutlined style={{color: '#8C8C8C', marginRight: 9}}/>
    </Tooltip></span>,
    name: 'areaCascader',
    key: 'areaCascader',
    message2: '请选择所属区域',
    required: false,
    col: 12,
    type: 'areaCascader',
    placeholder: '请选择',
  },
  {
    label: '所属企业',
    name: 'company',
    key: 'company',
    col: 12,
    type: 'select',
    rules: [{
      message: '请选择所属企业',
      required: true,
    }],
    placeholder: '请选择',
    options: [
      {
        value: 1,
        label1: '美团',
        label2: '美团',
      },
      {
        value: 2,
        label1: '青桔',
        label2: '青桔',
      },
      {
        value: 3,
        label1: '哈啰',
        label2: '哈啰',
      },
      {
        value: 4,
        label1: '电单车企业1',
        label2: '电单车企业1',
      },
      {
        value: 5,
        label1: '电单车企业2',
        label2: '电单车企业2',
      },
      {
        value: 6,
        label1: '电单车企业3',
        label2: '电单车企业3',
      },
      {
        value: 7,
        label1: '其他',
        label2: '其他',
      },
    ],
  },
  {
    label: <span><span>职务类型</span> <Tooltip title={tooltip(
        '职务类型为“安装人员”，无需考勤，无法加入考勤组。')} color="white">
      <ExclamationCircleOutlined style={{color: '#8C8C8C', marginRight: 9}}/>
    </Tooltip></span>,
    name: 'jobType',
    key: 'select Single9',
    col: 12,
    type: 'selectStaff',
    rules: [{
      message: '请选择职务类型',
      required: true,
    }],
    placeholder: '请选择',
    options: [
      {
        value: 1,
        label1: '单车人员',
        label2: '单车人员',
      },
      {
        value: 2,
        label1: '单车调运车辆',
        label2: '单车调运车辆',
      },
      {
        value: 3,
        label1: '核验人员',
        label2: '核验人员',
      },
      {
        value: 4,
        label1: '其他',
        label2: '其他',
      },
      {
        value: 5,
        label1: '电单车人员',
        label2: '电单车人员',
      },
      {
        value: 6,
        label1: '电单车调运车辆',
        label2: '电单车调运车辆',
      },
      {
        value: 7,
        label1: '安装人员',
        label2: '安装人员',
      },
    ],
  },
  {
    label: '车牌号',
    name: 'licensePlate',
    type: 'inputCard',
    message: 'Please input !',
    message2: '请输入',
    col: 12,
  },
  // 新增
  {
    label: '区域管理员姓名(自动关联)',
    key: 'areaManager',
    name: 'areaManager',
    type: 'input',
    message: 'Please input !',
    message2: '请输入',
    col: 12,
    disabled: true,
  },
  {
    label: '街道管理员姓名(自动关联)',
    name: 'streetManager',
    key: 'streetManager',
    type: 'input',
    message: 'Please input !',
    message2: '请输入',
    col: 12,
    disabled: true,
  },
  {
    label: '入职日期',
    name: 'entryDate',
    key: 'entryDate',
    type: 'date',
    // rules: [{
    //   message: '请选择入职日期',
    //   // required: true,
    // }],
    onChangeKey: 'workingYears',
    onChangeRule: 'workYear',
    col: 12,
  },
  {
    label: '工龄(自动计算)',
    name: 'workingYears',
    type: 'input',
    key: 'workingYears',
    message: 'Please input !',
    message2: '请输入',
    disabled: true,
    col: 12,
  },
  {
    name: 'areaId',
    key: 'areaId',
    type: 'input',
    col: 0,
    hidden: true,
  },
  {
    name: 'streetId',
    key: 'streetId',
    type: 'input',
    col: 0,
    hidden: true,
  },
];
export default addForm;
