import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import {attendanceStatistics} from '../../../../api/index';

const AttendanceStatistics = ({menuTab, chartTime, incomeDate}) => {
  const [attendanceNum, setAttendanceNum] = useState();
  const mockDate = [
    {bikeEnterprise: 0, actualQuantity: 0, requireQuantity: 0},
    {bikeEnterprise: 1, actualQuantity: 0, requireQuantity: 0},
    {bikeEnterprise: 2, actualQuantity: 0, requireQuantity: 0},
    {bikeEnterprise: 3, actualQuantity: 0, requireQuantity: 0},
  ];
  const bikeEnterprise = JSON.parse(localStorage.getItem('bikeEnterprise')).bikeEnterprise;

  const title = (key) => {
    switch (key) {
      case 0:
        return '总体出勤统计';
      case 1:
        return '美团出勤统计';
      case 2:
        return '青桔出勤统计';
      case 3:
        return '哈啰出勤统计';
      default:
        break;
    }
  };

  // data为空处理
  const statisticData = () => {
    let temp = [];
    if (bikeEnterprise.indexOf(1) !== -1 && bikeEnterprise.indexOf(2) !== -1 && bikeEnterprise.indexOf(3) !== -1) {
      temp=[...mockDate];
    } else {
      for (const index of bikeEnterprise) {
        const res = mockDate.find((i) => i.bikeEnterprise === index);
        if (res) temp.push(res);
      }
    }
    return temp;
  };
  // 单车人员出勤数统计
  const statistic = (menuTab, chartTime) => {
    const obj = {
      apiType: menuTab,
    };
    if (chartTime) {
      obj.statisticsDate = chartTime;
    }
    attendanceStatistics(1, {...obj}, 2)
        .then((resp) => {
          if (resp.data.length) {
            resp.data.sort((a, b) => a.bikeEnterprise - b.bikeEnterprise);
            setAttendanceNum(resp.data);
          } else {
            const newDate=statisticData();
            setAttendanceNum([...newDate]);
          }
        })
        .catch((err) => {
          console.log(err);
          // setAttendanceNum([...mockDate]);
          const newDate=statisticData();
          setAttendanceNum([...newDate]);
        });
  };

  useEffect(() => {
    if (chartTime) {
      statistic(menuTab, chartTime);
    } else {
      statistic(menuTab);
    }
  }, [menuTab, chartTime]);

  return (
    <div className="attendance-content">
      <p className="attendance-title">{`单车${menuTab === 1 ? '人员' : '调运车辆'}出勤数统计`}</p>
      <div className="list-detail-hint">
        <InfoCircleOutlined className="off-icon" />
        {`鼠标左键单击趋势图上的数据点，可快捷查询对应时间点入库的出勤${menuTab === 1 ? '人员' : '调运车辆'}统计数据。`}
      </div>
      <div className="entry-time">数据入库时间：{chartTime ? chartTime : incomeDate}</div>
      <div className="details-info margin-t-m">
        <Row gutter={[24, 0]} className="margin-b-l">
          {attendanceNum?.map((item, index) => {
            return (
              <Col key={item.bikeEnterprise} span={6}>
                <div className="number-item">
                  <div className="number-item-title">{title(item.bikeEnterprise)}</div>
                  <div className="number-item-content">
                    {'实出勤/应出勤：'}
                    <span className="text-gray">
                      {item.actualQuantity}/{item.requireQuantity}
                    </span>
                  </div>
                  <div>
                    {'出勤率：'}
                    <span className="text-gray">
                      {item?.actualQuantity && item?.requireQuantity ?
                        ((item.actualQuantity / item.requireQuantity) * 100).toFixed(2) :
                        '0.00'}
                      %
                    </span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
AttendanceStatistics.propTypes = {
  menuTab: PropTypes.number,
  chartTime: PropTypes.string,
  incomeDate: PropTypes.string,
};
export default AttendanceStatistics;
