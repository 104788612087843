import {
  workStatusOptions,
  bikeCompanyOptions,
  staffOptions,
  onlineStatusOptions,
  rankOptions,
  workOptions,
} from '../../../components/statusCollection';

export const formStaffList = [
  {
    label: '所属区域',
    key: 'areaCascaderMul',
    col: 6,
    name: 'areaCascaderMul',
    type: 'areaCascaderMul',
  },
  {
    label: '职务类型',
    name: 'jobTypeList',
    key: 'jobType',
    col: 6,
    type: 'select',
    options: staffOptions,
    mode: 'multiple',
    placeholder: '请选择(可多选)',
  },
  {
    label: '工作状态',
    name: 'jobStatus',
    key: 'jobStatus',
    col: 6,
    type: 'select',
    options: workStatusOptions(true),
  },
  {
    label: '在线状态',
    name: 'onlineStatus',
    key: 'onlineStatus',
    col: 6,
    type: 'select',
    options: onlineStatusOptions,
  },
  // {
  //   type: 'yearPicker',
  //   key: 'yearPicker',
  //   name: 'yearPicker',
  //   col: 4,
  //   label: '入职年度',
  // },
  {
    type: 'select',
    key: 'rank',
    name: 'positionLevel',
    col: 4,
    label: '职务等级',
    options: rankOptions(),
    mode: 'multiple',
    placeholder: '请选择(可多选)',
  },
  {
    label: '管辖区域',
    key: 'jurisdiction',
    col: 5,
    name: 'jurisdiction',
    type: 'jurisdiction',
  },
  {
    type: 'select',
    key: 'companyList',
    name: 'companyList',
    col: 5,
    label: '所属企业',
    options: bikeCompanyOptions(true),
    mode: 'multiple',
    placeholder: '请选择(可多选)',
  },
  {
    col: 6,
    key: 'address',
    name: 'composite',
    type: 'selectInput',
    label: '信息搜索',
    typeName: 'type',
    typeOptions: [
      {label: '姓名', value: 1},
      {label: '工号', value: 2},
      {label: '联系电话', value: 3},
    ],
    valueName: 'name',
  },
];
export const formCityList = [
  {
    col: 8,
    key: 'address',
    name: 'composite',
    type: 'selectInput',
    label: '信息搜索',
    typeName: 'type',
    typeOptions: [
      {label: '姓名', value: 1},
      {label: '工号', value: 2},
      {label: '联系电话', value: 3},
    ],
    valueName: 'name',
  },
  {
    type: 'select',
    key: 'companyList',
    name: 'manageEnterprise',
    col: 8,
    label: '城管企业',
    options: bikeCompanyOptions('cityManagement'),
    mode: 'multiple',
    placeholder: '请选择(可多选)',
  },

  {
    label: '所属区域',
    key: 'areaCascaderMul',
    col: 8,
    name: 'areaCascaderMul',
    type: 'areaCascaderMul',
  },
  {
    label: '工作状态',
    name: 'jobStatus',
    key: 'jobStatus',
    col: 6,
    type: 'select',
    mode: 'multiple',
    options: workOptions(),
  },
];
