/* eslint-disable guard-for-in */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import EditForms from '../../../components/forms/EditForms';
import addForm from '../configs/addForm';
import cityAddForm from '../configs/cityAddForm';
import editForm from '../configs/editForm';
import {Drawer, Modal, Space, message, Spin, Button} from 'antd';
import {addStaff, updateStaff, addCity, updateCity} from '../../../api';
// import Base64 from 'base-64';
import moment from 'moment';

let clickLoading = false;
const FormOfDrawer=(props)=> {
  const [loading, setLoading]=useState(false);
  const [modalVisible, setModalVisible]=useState(false);
  const [name, setName]=useState('');
  const [workId, setWordId]=useState('');
  const [updateList, setUpdateList]=useState(0);
  const [selected, setSelected]=useState(false);
  const [refuseModal, setRefuseModal] = useState(false);
  const [refuseModalText, setRefuseModalText] = useState('');

  // 新增或编辑
  const handleStaff = (values)=>{
    // console.log(values);
    if (typeof values.age !=='number'&&values.idCard) {
      message.error('身份证号输入有误，请确认！');
    } else {
      if (!values.streetId) {
        delete values.streetId;
      }
      if (props.type =='edit') {
        values.operationId=values.id;
      }
      delete values.id;
      delete values.workingYears;
      delete values.areaId;
      delete values.streetManager;
      if (!values.jurisdiction) delete values.jurisdiction;
      if (!values.file) delete values.file;

      const formData=new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
      setLoading(true);
      if (clickLoading) return false;
      clickLoading = true;
      if (props.menuType==='staff') {
        // 运维人员
        if (props.type=='add') {
          addStaff(formData, 2).then((res)=>{
            addedSuccess(res);
          }) .finally(() => {
            clickLoading = false;
          });
        } else {
          updateStaff(formData, 2).then((res)=>{
            if (res.data) {
              setRefuseModalText(res.data);
              setRefuseModal(true);
            } else {
              message.success('运维人员修改成功');
              editSuccess();
            }
          }).finally(() => {
            clickLoading = false;
          });
        }
      } else {
        // 城市管理者
        if (props.type=='add') {
          addCity(formData, 2).then((res)=>{
            addedSuccess(res);
          }).finally(() => {
            clickLoading = false;
          });
        } else {
          updateCity(formData, 2).then((res)=>{
            message.success('城市管理者修改成功');
            editSuccess();
          }).finally(() => {
            clickLoading = false;
          });
        }
      }

      setLoading(false);
    }
  };
  const isSelected=()=>{
    const nowDate=new Date().toLocaleTimeString();
    if (props.attendanceRuleList.some((item)=>{
      return nowDate>=moment(moment('2020-11-12 '+
      item.earliestTime).valueOf()-1000*5*60).format('HH:mm:ss') &&
      nowDate<=item.latestTime;
    })) {
      message.warning('已有考勤组，考勤时段内不可修改所属区域。');
      setSelected(true);
    }
  };
  // 新增成功
  const addedSuccess=(res)=>{
    setName(res.data.name);
    setWordId(res.data.job_number);
    setModalVisible(true);
    props.setDrawerVisible(false);
    props.setUpdateList(props.updateList+1);
  };
  // 编辑成功
  const editSuccess=()=>{
    props.setDrawerVisible(false);
    setUpdateList(updateList+1);
    props.update(updateList);
  };

  const addForms=(
    <>
      <Spin spinning={loading}>
        <EditForms
          pageLayout={'vertical'}
          columnsForm={props.menuType==='staff'?props.type=='add'?addForm:editForm:cityAddForm}
          loading={false}
          onCancel={()=>props.setDrawerVisible(false)}
          buttonStyle="modal-button"
          onFinish={handleStaff}
          buttonText={props.type=='edit'?'保存':''}
          initData={props.type=='edit'?props.detailsList:null}
          selected={props.type=='edit'?selected:false}
          isSelected={props.type=='edit'?isSelected:null}
          visible={props.drawerVisible}
        />
      </Spin>
    </>
  );
  return (
    <div>
      <Drawer
        forceRender
        title={props.title}
        placement="right"
        destroyOnClose
        onClose={() =>props.setDrawerVisible(false)}
        visible={props.drawerVisible}
        size="large"
        maskClosable={false}
      >
        {addForms}
      </Drawer>
      <Modal
        forceRender
        title="新增成功"
        visible={modalVisible}
        maskClosable={false}
        onCancel={() =>setModalVisible(false)}
        onOk={()=>setModalVisible(false)}
        width={520}
        destroyOnClose>
        {<>
          <div>新增{props.menuType==='staff'?'运维人员':'城市管理者'}成功，工号与初始密码如下：</div>
          <Space size={'large'}>
            <span>{name}</span>
            <span>工号：{workId}</span>
            <span>密码：{'88888888'}</span>
          </Space>
        </>}
      </Modal>
      <Modal
        title={'无法操作'}
        visible={refuseModal}
        maskClosable={false}
        destroyOnClose
        closable={false}
        width={400}
        footer={
          [
            <Button key="211" onClick={()=>{
              setRefuseModal(false);
            }}>
              确认
            </Button>,
          ]
        }
      >
        <p>该运维人员是“进行中”或“未开始”状态的重点工作区域保障指定人员，不可删除，禁用，编辑，修改工作状态。请将人员从区域移除后再编辑。</p>
        <p>重点工作区域保障名称：</p>
        <p>{refuseModalText}</p>
      </Modal>
    </div>
  );
};
FormOfDrawer.propTypes = {
  setDrawerVisible: PropTypes.func.isRequired,
  drawerVisible: PropTypes.bool,
  title: PropTypes.string,
  editValues: PropTypes.object,
  type: PropTypes.string,
  detailsList: PropTypes.object,
  update: PropTypes.func,
  attendanceRuleList: PropTypes.array,
  updateList: PropTypes.number,
  setUpdateList: PropTypes.func,
  menuType: PropTypes.string,
};
export default FormOfDrawer;


