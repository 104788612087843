import React from 'react';
import {Modal, Row, Col} from 'antd';
import SimpleTable from '../../../components/customTable/SimpleTable';
import PropTypes from 'prop-types';
import {GETGUARANTEEPERSONLIST} from '../../../api/Urls';
import {bikeCompany, staffStatus} from '../../../components/statusCollection';
import './style.css';

const PersonNumber = ({numberModal, setNumberModal, statistics}) => {
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      // width: 120,
    },
    {
      title: '所属企业',
      dataIndex: 'company',
      key: 'company',
      width: 90,
      // eslint-disable-next-line
      render: (record, item) => bikeCompany(record),
    },
    {
      title: '工号',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: '职务类型',
      dataIndex: 'jobType',
      key: 'jobType',
      width: 160,
      // eslint-disable-next-line
      render: (record, item) => (
        <span className={parseInt(item.operationStatus) !== 1 ? 'text-disabled' : ''}>{staffStatus(record)}</span>
      ),
    },
    {
      title: '记录时间',
      dataIndex: 'positionTime',
      key: 'positionTime',
    },
  ];

  return (
    <div>
      <Modal
        title="到岗统计-人员子表"
        visible={numberModal}
        maskClosable={true}
        width={850}
        footer={null}
        onCancel={() => {
          setNumberModal(false);
        }}
        destroyOnClose
      >
        <>
          <div>
            <div>统计时间：{statistics.createTime}</div>
            <div className="details-info margin-t-l">
              <Row gutter={[16, 0]} className="margin-b-l">
                {statistics.statistic &&
                  statistics.statistic.map((item, index) => {
                    return (
                      <Col key={item.company + index}>
                        <div className="number-children-item">
                          <div className="number-item-title">{bikeCompany(item.company)}</div>
                          <div className="number-children-item-content">
                            人员：{item.person}
                            <span className="text-gray"></span>
                          </div>
                          <div>
                            车辆：{item.car}
                            <span className="text-gray"></span>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
          <SimpleTable
            InitApi={`${GETGUARANTEEPERSONLIST}`}
            toIndex={true}
            columns={columns}
            resType={'POST'}
            params={{ids: statistics.id}}
          />
        </>
      </Modal>
    </div>
  );
};

PersonNumber.propTypes = {
  numberModal: PropTypes.any,
  setNumberModal: PropTypes.func,
  statistics: PropTypes.object,
};

export default PersonNumber;
