import React, {useRef} from 'react';
import {Line} from '@ant-design/plots';
import PropTypes from 'prop-types';
import {Button} from 'antd';

const DemoLine = ({data, slider, chartTime, title}) => {
  // console.log(data);
  const config = {
    // data: [],
    data,
    xField: 'date',
    yField: 'count',
    color: ({type}) => {
      switch (type) {
        case '美团':
          return '#E89E0D';
        case '哈啰':
          return '#1D39C4';
        case '青桔':
          return '#13BEC6';
        case '全部':
          return '#262626';
        default:
          return '#fff';
      }
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    seriesField: 'type',
    tooltip: {
      showMarkers: false,
      formatter: (datum) => {
        window.hoverData = datum;
        return {
          name: datum.type,
          value: datum.count,
        };
      },
    },
    point: {
      size: 3,
      shape: 'circle',
    },
    slider: {
      start: 0,
      end: 1.0,
      smooth: true,
    },
  };
  const ref = useRef();

  // 导出图片
  const downloadImage = (name) => {
    const downName = `${name}折线图`;
    ref.current?.downloadImage(downName);
  };
  return (
    <>
      <Button type="primary" onClick={() => downloadImage(title)} className="btn-export">
        下载
      </Button>
      <Line
        {...config}
        onReady={(plot) => {
          ref.current = plot;
          plot.on('element:click', (...args) => {
            if (chartTime) {
              chartTime(window.hoverData);
            }
          });
        }}
      />
    </>
  );
};
DemoLine.propTypes = {
  slider: PropTypes.bool,
  data: PropTypes.array,
  chartTime: PropTypes.any,
  title: PropTypes.string,
};
export default DemoLine;
