import React, {useEffect, useState, useRef} from 'react';
import {Button, message} from 'antd';
import CustomTable from '../../components/customTable';
import {bikeStatus} from '../../api';
import {IMPORTBIKE, GETBIKELIST, IMPORTEXCELBIKE2} from '../../api/Urls';
import {timeFormat} from '../../components/baseTime';
import './style.css';
import PropTypes from 'prop-types';
import documentTemplate from '../../asset/carList.xlsx';
import {bikeCompany} from '../../components/statusCollection';
import {Link} from 'react-router-dom';


const BicycleManagement = ({queryType}) => {
  const myInfo = JSON.parse(localStorage.getItem('myInfo'));
  // const [form] = Form.useForm();
  // const [scrapModal, setScrapModal] = useState(false);
  // const [scrap, setScrap] = useState(false);
  const [resetOptions, setResetOptions] = useState(0);
  // const [bikeItems, setBikeItems] = useState([
  //   // {
  //   //   'bikeType': 1,
  //   //   'status': 1,
  //   //   'bikeNum': 0,
  //   //   'isSelected': true,
  //   // }, {
  //   //   'bikeType': 5,
  //   //   'status': 1,
  //   //   'bikeNum': 0,
  //   //   'isSelected': true,
  //   // },
  // ]);
  // const [updateList, setUpdateList] = useState(0);
  const [updateBikeItems, setUpdateBikeItems] = useState(0);
  // const [updateCount, setUpdateCount] = useState(0);
  // const [areaOptions] = useState([]);
  const [bikeCompanyOptions, setBikeCompanyOptions] = useState([]);

  const isMounted = useRef(false);

  // const getAreaGroup = (params) => {
  //   getBikeAreaTree(params, 2).then((res) => {
  //     const data = res.data.map((item) => {
  //       return {
  //         ...item,
  //         value: item.id,
  //         label: (<span>
  //           {item.areaName}
  //         </span>),
  //         children: item.children ? item.children.map((sub) => {
  //           return {
  //             ...sub,
  //             label: (<span>
  //               {sub.areaName}
  //             </span>),
  //             value: sub.id,
  //           };
  //         }) : [],
  //       };
  //     });
  //     setAreaOptions(data);
  //   });
  // };
  const formList = [
    {
      label: '所属车企',
      key: 'bikeType',
      col: 8,
      name: 'bikeType',
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      options: bikeCompanyOptions,
    },
    {
      label: '所属区域',
      key: 'areaBike',
      col: 8,
      name: 'areaBike',
      type: 'areaBike',
    },
    {
      col: 8,
      key: 'blank',
      name: 'blank',
      type: 'blank',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      typeOptions: [
        {label: '车锁号', value: 1},
        {label: '实体车牌号', value: 2},
        {label: '蓝牙Mac地址', value: 3},
        {label: '录入人员用户名', value: 4},
      ],
      valueName: 'name',
    },
  ];

  const columns = [
    {
      title: '序列',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '所属车企',
      dataIndex: 'bikeType',
      key: 'bikeType',
      render: (record) => bikeCompany(record),
    },
    {
      title: '实体车牌号',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
    },
    {
      title: '车锁号',
      dataIndex: 'lockNumber',
      key: 'lockNumber',
    },
    {
      title: '蓝牙MAC地址',
      dataIndex: 'bluetoothMacAddress',
      key: 'bluetoothMacAddress',
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      key: 'frameNumber',
    },
    {
      title: '所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: '录入人员',
      dataIndex: 'createName',
      key: 'createName',
    },
    {
      title: '新增时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      // eslint-disable-next-line
      render: (createTime) => timeFormat(createTime),
      // eslint-disable-next-line
      exportRender: (record) => timeFormat(record.createTime),
    },
  ];
  // 报废提交
  // let flag = true;
  // const batchScrap = () => {
  //   const arr = [];
  //   Object.keys(form.getFieldsValue()).forEach((key) => {
  //     if (form.getFieldsValue()[key].bikeType) {
  //       const data = {};
  //       data.bikeType = form.getFieldsValue()[key].bikeType[0];
  //       if (form.getFieldsValue()[key].fID && form.getFieldsValue()[key].fID.length > 0) {
  //         data.areaId = form.getFieldsValue()[key].fID.map((item) => {
  //           if (item.length === 2) {
  //             return item[1];
  //           } else {
  //             const list = areaOptions.find((OptionsData) => OptionsData.value === item[0]);
  //             const listChildren = list.children.map((sub) => {
  //               return sub.value;
  //             });
  //             return listChildren;
  //           }
  //         });
  //         data.areaId = data.areaId.flat();
  //       }
  //       arr.push(data);
  //     }
  //   });
  //   if (flag) {
  //     batchScrapBike(arr, queryType, 2).then((res) => {
  //       setUpdateList(updateList + 1);
  //       setUpdateBikeItems(updateBikeItems + 1);
  //       message.success('车辆报废成功');
  //     }).finally(() => {
  //       setScrapModal(false);
  //     });
  //     flag = false;
  //   }
  // };
  // const downLoad = () => {
  //   const element = document.createElement('a');
  //   element.setAttribute('href', documentTemplate);
  //   element.setAttribute('download', '车辆批量导入模板.xlsx');
  //   element.style.display = 'none';
  //   element.click();
  //   message.success('导入模版下载成功');
  // };
  //
  // const displayRender = (labels, selectedOptions) => {
  //   if (selectedOptions[0]) {
  //     return labels.map((label, i) => {
  //       const option = selectedOptions[i];
  //       if (i === labels.length - 1) {
  //         return (
  //           <span key={option.value}>
  //             {option.areaName}
  //           </span>
  //         );
  //       }
  //       return <span key={option.value}>{label.props.children} / </span>;
  //     });
  //   }
  //   return <span>{labels}</span>;
  // };
  // 批量报废->
  // const checkboxScrap = (
  //   <>
  //     <Form
  //       name="settingForm"
  //       form={form}
  //       preserve={false}
  //     >
  //       <div>请选择需要报废车辆的车企以及车辆所属区域（多选）：</div>
  //       {bikeItems.map((item, index) => {
  //         return item.status === 1 && <div key={item.bikeType}>
  //           <Row>
  //             <Form.Item
  //               name={['bike' + item.bikeType, 'bikeType']}
  //               valuePropName="checked"
  //             >
  //               <Checkbox.Group>
  //                 <Checkbox value={item.bikeType} onChange={(value) => {
  //                   getBikeCount({bikeType: value.target.value}, queryType, 2).then((res) => {
  //                     item.isSelected = !value.target.checked;
  //                     item.bikeNum = value.target.checked ? res.data : 0;
  //                     setUpdateCount(updateCount + 1);
  //                   });
  //                   form.resetFields([['bike' + item.bikeType, 'fID']]);
  //                 }}>{bikeCompany(item.bikeType)} 已选：{item.bikeNum}辆{queryType === 1 ? '单车' : '电单车'}</Checkbox>
  //               </Checkbox.Group>
  //             </Form.Item>
  //           </Row>

  //           <Row>
  //             <Col span={24}>
  //               <Form.Item
  //                 name={['bike' + item.bikeType, 'fID']}
  //               >
  //                 <Cascader
  //                   options={areaOptions}
  //                   maxTagCount="responsive"
  //                   placeholder="全部市、区"
  //                   disabled={item.isSelected}
  //                   displayRender={displayRender}
  //                   multiple
  //                   onChange={(value) => {
  //                     const areaId = value.map((item) => {
  //                       if (item.length === 2) {
  //                         return item[1];
  //                       } else {
  //                         const list = areaOptions.find((OptionsData) => OptionsData.value === item[0]);
  //                         const listChildren = list.children.map((sub) => {
  //                           return sub.value;
  //                         });
  //                         return listChildren;
  //                       }
  //                     }).flat();
  //                     getBikeCount(
  //                         {
  //                           bikeType: form.getFieldsValue()['bike' + item.bikeType].bikeType[0],
  //                           areaId,
  //                         }, queryType, 2).then((res) => {
  //                       item.bikeNum = res.data;
  //                       setUpdateCount(updateCount + 1);
  //                     });
  //                   }}
  //                 >
  //                 </Cascader>
  //               </Form.Item>
  //             </Col>
  //           </Row>

  //         </div>;
  //       })}
  //     </Form>
  //   </>
  // );
  // useEffect(() => {
  //   setBikeItems(bikeItems);
  // }, [updateCount]);
  useEffect(() => {
    // getAreaGroup(2);
    const bikeOptions=[...bikeCompanyOptions];
    bikeStatus(queryType, 2).then((res) => {
      // console.log(res.data);
      if (res.data.length > 0) {
        if (res.data.every((item) => {
          return item.status === 0;
        })) {
          // setScrap(true);
          bikeOptions.length = 0;
          setBikeCompanyOptions(bikeOptions);
        } else {
          // 设置报废modal
          // setScrap(false);
          // const bike = res.data.map((item) => {
          //   item.bikeNum = 0;
          //   item.isSelected = true;
          //   return item;
          // });
          // console.log(bike);
          // setBikeItems(bike);
          // 设置下拉框选项+
          bikeOptions.length = 0;
          res.data.forEach((item) => {
            if (item.status === 1) {
              bikeOptions.push({value: item.bikeType, label: bikeCompany(item.bikeType)});
            }
          });
          setBikeCompanyOptions([...bikeOptions]);
        }
      }
    });
  }, [queryType, updateBikeItems]);
  useEffect(() => {
    if (isMounted.current) {
      setResetOptions(resetOptions + 1);
    } else {
      isMounted.current = true;
    }
  }, [queryType]);
  // useEffect(() => {
  // eslint-disable-next-line max-len
  //   fetch('https://ytkj-sources-public-test.oss-cn-chengdu.aliyuncs.com/bikeInErrorExcel/单车运维V1.09车辆批量导入错误数据_2023-08-21_14-39-18.xlsx',
  //       {
  //         mode: 'no-cors',
  //         method: 'HEAD',

  //       },
  //   )
  //       .then((response) => {
  //         console.log(response);
  //         // response.blob();
  //       })
  //       .then((blob) => {
  //         console.log(blob);
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.style.display = 'none';
  //         a.href = url;
  //         a.download = '文件名.xlsx';
  //         document.body.appendChild(a);
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //       })
  //       .catch((error) => console.error('下载文件时出错：', error));
  // }, []);

  return (
    <div>
      <CustomTable
        InitApi={`${GETBIKELIST}${queryType}`}
        formList={formList}
        toIndex={true}
        columns={columns}
        // updateList={updateList}
        resetData={resetOptions}
        // eslint-disable-next-line no-constant-condition
        uploadUrl={(queryType===1&& (myInfo.roleName === '超级管理' || myInfo.roleName === '超级管理员' ||
          myInfo.userName === '哈啰备案数据' || myInfo.userName === '青桔备案数据' || myInfo.userName === '美团备案数据')) ?
          `${IMPORTBIKE}` : ''}
        uploadUrl2={`${IMPORTEXCELBIKE2}${queryType}`}
        importType="car"
        buttonGroup={() => (
          <>
            {/* <Button
              onClick={() => {
                setIsConnect(true);
              }}
            >
              sss
            </Button> */}
            {(queryType === 1 && (myInfo.roleName === '超级管理' || myInfo.roleName === '超级管理员' ||
              myInfo.userName === '哈啰备案数据' || myInfo.userName === '青桔备案数据' || myInfo.userName === '美团备案数据')) &&
              <Link to={'/bicycleManagement/bikeManagement/importRecords'}>
                <Button>导入记录</Button>
              </Link>}
            {(queryType === 1 && (myInfo.roleName === '超级管理' || myInfo.roleName === '超级管理员' ||
              myInfo.userName === '哈啰备案数据' || myInfo.userName === '青桔备案数据' || myInfo.userName === '美团备案数据')) &&
              <Button
                onClick={() =>{
                  message.success('导入模版下载成功');
                }}
                href={window.location.origin+documentTemplate}
                download="车辆批量导入模板.xlsx">
              下载导入模板
              </Button>}
            {/* <Button
              disabled={scrap}
              onClick={() => {
                setScrapModal(true);
                setUpdateBikeItems(updateBikeItems + 1);
                setResetOptions(resetOptions + 1);
              }}>
              批量报废
            </Button> */}
          </>
        )}
        updateBikeItems={updateBikeItems}
        setUpdateBikeItems={setUpdateBikeItems}
      />
      {/* <Modal
        title="批量报废"
        visible={scrapModal}
        maskClosable={true}
        footer={[
          <Button key="1" onClick={() => {
            setScrapModal(false);
          }
          }>取消</Button>,
          <Button key="2" type="primary" onClick={batchScrap} disabled={
            bikeItems.every((item) => {
              return item.bikeNum === 0;
            })}>确认</Button>,
        ]}
        onCancel={() => {
          setScrapModal(false);
        }}
        destroyOnClose
      >
        <div className="bicycleManagement">{checkboxScrap}</div>
      </Modal> */}
    </div>
  );
};

BicycleManagement.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default BicycleManagement;


