import PropTypes from 'prop-types';
import React, {useRef, useState, useEffect} from 'react';
import {Spin} from 'antd';
import AMapLoader from '@amap/amap-jsapi-loader';
import {AMapKey} from './aMapAPI';
import './mapStyle.css';
import {getMapZoomCenter} from '../../api';
import {zoomLevel} from '../statusCollection';
const PolyShow =({polyPath, status, colors})=>{
  const mapRef = useRef();

  const [mapLoading, setMapLoading] = useState(true);
  const mapInit = async () => {
    setMapLoading(true);
    let center=[104.065861, 30.657401];
    let zoom=13;
    const res= await getMapZoomCenter(2);
    if (res.data.latitudeLongitude) {
      center=res.data.latitudeLongitude.split(',');
    }
    zoom=zoomLevel(res.data.level);
    AMapLoader.load({
      ...AMapKey,
      plugins: [
        'AMap.Scale',
        'AMap.Polygon',
        'AMap.ToolBar',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      mapRef.current.map = new AMap.Map('map', {
        resizeEnable: true,
        center,
        mapStyle: 'amap://styles/1e48c36ae6fb7340cb350ef5e97ac934',
        zoom,
        showLabel: true,
      });
      mapRef.current.map.addControl(new AMap.ToolBar());
      const color=
      status===0?(!colors?'#D9D9D9':colors[0]):
      status===1? (!colors?'#13C2C2':colors[1]):
      (!colors?'#575757':colors[2]);
      const polygon = new AMap.Polygon({
        path: polyPath,
        fillColor: color, // 多边形填充颜色
        strokeWeight: 1, // 线条宽度，默认为 1
        strokeColor: color, // 线条颜色
      });
      mapRef.current.map.add(polygon);
      setMapLoading(false);
    });
  };
  useEffect(()=>{
    mapInit();
  }, [polyPath]);
  return (
    <Spin spinning={mapLoading}>
      <div
        ref={mapRef}
        id="map"
        className="guarantee-map"
      />
    </Spin>
  );
};

PolyShow.propTypes ={
  // 已绘制多边形
  polyPath: PropTypes.array,
  // 保障区状态
  status: PropTypes.string,
  // 保障区颜色
  // [0] : status===0 的颜色
  // [1] : status===1 的颜色
  // [2] : status===2 的颜色
  colors: PropTypes.array,
};

export default PolyShow;
