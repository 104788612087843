import React from 'react';
import Statement from './components/statement';
import './style.css';
const BikeSummaryReport = () => {
  return (
    <div>
      {/* bikeType单车类型 1:单车 2:电单车 */}
      <Statement bikeType={1} vehicleType={'单车'}></Statement>
    </div>
  );
};
export default BikeSummaryReport;
