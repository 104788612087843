import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import DetailsHeader from '../../../../components/detailsHeader';
import {Anchor, Card, Col, Divider, Row, Skeleton, Image} from 'antd';
import DevicePosition from '../../../../components/aMap/DevicePosition';
import {equipmentDetail} from '../../../../api';
import photo from '../../../../asset/noPhoto.png';
import {timeFormat} from '../../../../components/baseTime';

const EquipmentDetails = () => {
  const [loading, setLoading] = useState(true);
  const [detail, setDetial] = useState();
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [imgList, setImgList]=useState();
  const location = useLocation();
  const id = location.pathname?.split('/')[4];
  const type = location.pathname?.split('/')[5];

  // 设备详情
  const detailEquipment = () => {
    equipmentDetail({id, type}, '2').then((resp) => {
      setLongitude(resp.data.latitudeLongitude?.split(',')[0]);
      setLatitude(resp.data.latitudeLongitude?.split(',')[1]);
      setDetial(resp.data);
      if (resp.data.imageUrl) {
        setImgList(resp.data.imageUrl.split(';'));
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    detailEquipment();
  }, []);

  return (
    <div>
      <DetailsHeader pageName="设备详情" buttonGroup={null} />
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <div>
          <h3 className="details-title" id="basic-information">
            基础信息
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  设备IMEI：<span className="text-gray">{detail?.mobileEquipmentIdentity || '暂无数据'}</span>
                </Col>
                <Col span={8} className="equipment-name">
                  设备名称：
                  <p className="text-gray text-wrap">{detail?.equipmentName || '暂无数据'}</p>
                </Col>
                <Col span={8}>
                  所属区域：
                  <span className="text-gray">{detail?.areaName || '暂无数据'}</span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  设备MAC地址：
                  <span className="text-gray"> {detail?.equipmentMac || '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  位置编号：
                  <span className="text-gray">{detail?.locationNo?.replace(/\//g, ',') || '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  点位类型：
                  <span className="text-gray">
                    {detail?.pointType ? (detail?.pointType === 1 ? '普通' : '其他') : '暂无数据'}
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  新增时间：<span className="text-gray">{timeFormat(detail?.createTime )|| '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  安装人员：<span className="text-gray">{detail?.createName || '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  联系电话：
                  <span className="text-gray">{detail?.phoneNumber || '暂无数据'}</span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>点位照片：</Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Image.PreviewGroup>
                  {imgList&&imgList.length?imgList.map((item, index)=>{
                    return (
                      <div key={index} className="eqipment-img">
                        <Image src={item} width={276} height={259} />
                      </div>);
                  }):<div>
                    <img src={photo} width={276} height={259} />
                  </div>}
                </Image.PreviewGroup>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  备注：<span className="text-gray">{detail?.community||'暂无数据'}</span>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="threshold-value">
            设备阙值
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  单车自定义阈值：
                  <span className="text-gray">
                    {detail?.bikeThresholdStatus ? (detail?.bikeThresholdStatus === 1 ? '开' : '关') : '暂无数据'}
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  {detail?.bikeThresholdStatus === 1?'自定义':'默认'}标准值：
                  <span className="text-gray">{detail?.bikeStandardNum || '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  {detail?.bikeThresholdStatus === 1 ? '自定义' : '默认'}预警值：
                  <span className="text-gray">{detail?.bikeAlertNum || '暂无数据'}</span>
                </Col>
                <Col span={8}>
                  {detail?.bikeThresholdStatus === 1?'自定义':'默认'}调运值：
                  <span className="text-gray">{detail?.bikeDispatchNum || '暂无数据'}</span>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="equipment-location">
            设备位置
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={[24, 24]} className="margin-b-l">
                <Col span={8}>
                  经纬度：
                  <span className="text-gray">{detail?.latitudeLongitude || '暂无数据'}</span>
                </Col>
              </Row>
              {/* 地图 */}
              <DevicePosition latitude={latitude} longitude={longitude} />
            </div>
          )}
        </div>
      </Card>
      <Anchor className="anchor">
        <Anchor.Link href="#basic-information" title="基础信息" />
        <Anchor.Link href="#threshold-value" title="设备阙值" />
        <Anchor.Link href="#equipment-location" title="设备位置" />
      </Anchor>
    </div>
  );
};

EquipmentDetails.propTypes = {};

export default EquipmentDetails;
