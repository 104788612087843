import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {G2, Pie} from '@ant-design/charts';
import {bikeCompany} from '../statusCollection';
import customTheme from './g2-theme.json';
import {toThousands} from '../../utils/dataFormat';
import {Button} from 'antd';

// 参数
// 1. height（非必传） 高度 默认100%
// 2. isDarkMode（非必传）
// 3. data 格式为{name: string or number =>公司名, value: number=> 占比数}

/**
 * 饼图组件 pie chart components
 * @author ziwei.Dong
 * @date 2022-12-23
 * {
 * @param {array} data eg： [{
      bikeEnterprise: 1,
      count: 0,
      percent: '0.00%',
    }]
 * @param {string} height chart height like ”100%” “200px“ 默认值 ”100%“
 * @param {string} dataType present/number  默认值number
 * @param {bool} isCustomMode 是否使用定制黑色主题  Whether to use a custom theme？ true/false
 * }
 * @return {JSXDom}
 */
const PieChart = ({data, height, isCustomMode, dataType}) => {
  const G = G2.getEngine('canvas');
  const chartData = (
    data.length > 0 ?
      data :
      [
        {
          bikeEnterprise: 1,
          count: 0,
          percent: '0.00%',
        },
        {
          bikeEnterprise: 2,
          count: 0,
          percent: '0.00%',
        },
        {
          bikeEnterprise: 3,
          count: 0,
          percent: '0.00%',
        },
      ]
  ).map((item) => ({...item, bikeEnterprise: bikeCompany(item.bikeEnterprise), percentStr: item.percent}));
  // console.log(chartData);

  let amount = 0;
  data.length &&
    data.forEach((item) => {
      amount = amount + item.count;
    });
  // console.log(amount);

  const {registerTheme} = G2;
  registerTheme('custom-theme', customTheme);
  const config = {
    appendPadding: 10,
    theme: isCustomMode ? 'custom-theme' : '',
    data: chartData,
    angleField: 'count',
    colorField: 'bikeEnterprise',
    radius: 0.75,
    pieStyle: {
      lineWidth: 0,
    },
    color: (d) => {
      switch (d.bikeEnterprise) {
        case '美团':
          return '#E89E0D';
        case '青桔':
          return '#13BEC6';
        case '哈啰':
          return '#1D39C4';
        default:
          return '#000000';
      }
    },
    legend: false,
    tooltip: {
      formatter: (datum) => ({
        name: datum.bikeEnterprise,
        value: `${toThousands(datum.count)}
         （${amount ? ((datum.count / amount) * 100).toFixed(2) : 0}%）`,
      }),
    },
    label: {
      type: 'spider',
      labelHeight: 40,
      content: `${dataType !== 'percent' ? '{value}' : '{name}\n{percentage}'}`,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 10,
            y: 8,
            text: data.bikeEnterprise,
            fill: isCustomMode ? '#F5F5F5' : '#333333',
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 0,
            y: 25,
            // text: `${
            //   dataType !== 'percent' ? toThousands(data.count) : Number(data.percentStr.split('%')[0]).toFixed(2)+'%'
            // }`,
            fill: isCustomMode ? '#F5F5F5' : '#333333',
            fontWeight: 700,
          },
        });
        return group;
      },
    },

    style: {
      width: '100%',
      height: height ? height : '100%',
    },
  };

  const ref = useRef();
  // 导出图片
  const downloadImage = (name) => {
    ref.current?.downloadImage(name);
  };

  return (
    <div>
      {dataType !== 'percent' ? (
        <Pie {...config} className="pie-large-size"/>
      ) : (
        <div>
          <Button type="primary" onClick={() => downloadImage('车企区域监测单车数占比饼状图')}>
            下载
          </Button>
          <Pie {...config} className="pie-statistics" onReady={(plot) => {
            ref.current = plot;
          }}/>
        </div>
      )}
    </div>
  );
};

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataType: PropTypes.string,
  height: PropTypes.string,
  isCustomMode: PropTypes.bool,
};

export default PieChart;
