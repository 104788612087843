import React, { useState } from 'react';
import SubTitleCustom from '../../components/subtitle/SubTitleCustom';
import CustomTable from '../../components/customTable';
import noData from '../../asset/noData.png';
// import {getAllAreaData} from '../../api';
import moment from 'moment';
import {
  bikeCompany, isContractArea, carCompanyOrderStatus,
  carCompanyOrderStatusOption, bikeCompanyOptions, companyOrderStatusOption
} from '../../components/statusCollection';
import { getOrderChildList } from '../../api';
import { GETWORKORDERMANGAGELIST, GETWORKORDERRECORDLIST } from '../../api/Urls';
import { Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './style.css';
const WorkOrder = () => {
  console.log(useLocation().state, 'useLocation().state useLocation().state');
  const [tab, setTab] = useState(useLocation().state || 0);
  // const [allAreaOptions, setAllAreaOptions] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [updateList, setUpdateList] = useState(0);
  const [childData, setChildData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const error = false;
  const carCensusFormList = [
    {
      label: '工单创建时间区间',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD HH:mm:ss',
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
    },
  ];
  const cityManageFormList = [
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'searchType',
      valueName: 'content',
      typeOptions: [
        { label: '城市管理者姓名', value: 1 },
        { label: '城市管理者联系电话', value: 2 },
      ],
    },
    {
      label: '工单创建时间区间',
      key: 'datePicker3',
      col: 8,
      name: 'datePicker3',
      type: 'datePicker3',
      format: 'YYYY-MM-DD HH:mm:ss',
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
    },
  ];
  const cityManageFormListTabTwo = [
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'searchType',
      valueName: 'content',
      typeOptions: [
        { label: '工单编号', value: 1 },
        { label: '城市管理者姓名', value: 2 },
        { label: '城市管理者联系电话', value: 3 },
        { label: '运维负责人名称', value: 4 },
      ],
    },
    {
      label: '创建时间区间',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD HH:mm:ss',
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
    },
    {
      label: '工单状态',
      key: 'workOrderStatus',
      col: 4,
      name: 'workOrderStatus',
      type: 'select',
      mode: 'multiple',
      options: carCompanyOrderStatusOption,
      placeholder: '请选择(可多选)',
    },
    {
      label: '是否包片',
      key: 'contractArea',
      col: 4,
      name: 'contractArea',
      type: 'select',
      // mode: 'multiple',
      options: [{
        value: 1,
        label: '是',
      },
      {
        value: 0,
        label: '否',
      }],
      placeholder: '请选择',
    },
    {
      label: '工单所属企业',
      key: 'company',
      col: 8,
      name: 'company',
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      options: bikeCompanyOptions('bike'),
    },
    {
      label: '工单所属区域',
      key: 'streetId',
      col: 8,
      name: 'streetId',
      type: 'areaCascaderMul',
      // mode: 'multiple',
      // options: allAreaOptions,
      placeholder: '请选择',
    },

  ];
  const cityManageFormListTabThree = [
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'searchType',
      valueName: 'content',
      typeOptions: [
        { label: '工单编号', value: 1 },
        { label: '城市管理者姓名', value: 2 },
        { label: '城市管理者联系电话', value: 3 },
        { label: '运维负责人名称', value: 4 },
      ],
    },
    {
      label: '创建时间区间',
      key: 'datePicker',
      col: 8,
      name: 'datePicker',
      type: 'datePicker',
      format: 'YYYY-MM-DD HH:mm:ss',
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      },
    },
    {
      label: '车企工单状态',
      key: 'workOrderStatus',
      col: 4,
      name: 'workOrderStatus',
      type: 'select',
      mode: 'multiple',
      options: companyOrderStatusOption,
      placeholder: '请选择(可多选)',
    },
    {
      label: '是否包片',
      key: 'contractArea',
      col: 4,
      name: 'contractArea',
      type: 'select',
      // mode: 'multiple',
      options: [{
        value: 1,
        label: '是',
      },
      {
        value: 0,
        label: '否',
      }],
      placeholder: '请选择',
    },
    {
      label: '运维负责人所属企业',
      key: 'operationCompany',
      col: 8,
      name: 'operationCompany',
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择(可多选)',
      options: bikeCompanyOptions('bike'),
    },
    {
      label: '工单所属区域',
      key: 'streetId',
      col: 8,
      name: 'streetId',
      type: 'areaCascaderMul',
      // mode: 'multiple',
      // options: allAreaOptions,
      placeholder: '请选择',
    },

  ];
  const carCompanyDataSource = [];
  for (let i = 0; i < 100; i++) {
    carCompanyDataSource.push({
      key: i + 1,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
    });
  }
  // useEffect(()=>{
  //   setUpdateList(updateList+1);
  // }, []);
  const carCensusColumns = [
    {
      title: '车企',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 120,
      // eslint-disable-next-line
      render: (record, item) => bikeCompany(record),
      exportRender: (record) => bikeCompany(record.company),
    },
    {
      title: '工单总数',
      dataIndex: 'workOrderCount',
      key: 'workOrderCount',
      onExport: true,
      width: 260,
      render: (record, item) => item.workOrderCount || 0,
    },
    {
      title: '待接受',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 1)[0]?.count || 0}</span>,
    },
    {
      title: '部分已接受',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 6)[0]?.count || 0}</span>,
    },
    {
      title: '待派发',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 2)[0]?.count || 0}</span>,
    },
    {
      title: '部分已派发',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 7)[0]?.count || 0}</span>,
    },
    {
      title: '待完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 3)[0]?.count || 0}</span>,
    },
    {
      title: '部分已完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 8)[0]?.count || 0}</span>,
    },
    {
      title: '已完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 4)[0]?.count || 0}</span>,
    },
    // {
    //   title: '已过期',
    //   key: 'firstDispatchAddress',
    //   width: 260,
    //   onExport: true,
    //   render: (record, index) =><span key={index}>
    //     {record.workOrderStatusList?.filter((item)=>item.status===5)[0]?.count}</span>,
    // },
    {
      title: '最近工单创建时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      onExport: true,
      width: 300,
    },
  ];
  const cityManageColumns = [
    {
      title: '城市管理者',
      dataIndex: 'managerName',
      key: 'managerName',
      onExport: true,
      width: 260,
      // fixed: 'left',
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      onExport: true,
      width: 260,
    },
    {
      title: '工单总数',
      dataIndex: 'workOrderCount',
      key: 'workOrderCount',
      onExport: true,
      width: 260,
    },
    {
      title: '待接受',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 1)[0]?.count || 0}</span>,
    },
    {
      title: '部分已接受',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 6)[0]?.count || 0}</span>,
    },
    {
      title: '待派发',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 2)[0]?.count || 0}</span>,
    },
    {
      title: '部分已派发',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 7)[0]?.count || 0}</span>,
    },
    {
      title: '待完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 3)[0]?.count || 0}</span>,
    },
    {
      title: '部分已完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 8)[0]?.count || 0}</span>,
    },
    {
      title: '已完成',
      key: 'firstDispatchAddress',
      width: 260,
      onExport: true,
      render: (record, index) => <span key={index}>
        {record.workOrderStatusList?.filter((item) => item.status === 4)[0]?.count || 0}</span>,
    },
    {
      title: '最近工单创建时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 300,
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
  ];
  const workOrderRecordColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      width: 60,
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      onExport: true,
      width: 200,
    },
    {
      title: '城市管理者',
      dataIndex: 'managerName',
      key: 'managerName',
      width: 180,
      onExport: true,
    },
    {
      title: '联系电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 180,
      // sorter: true,
      onExport: true,
    },
    {
      title: '工单状态',
      dataIndex: 'workOrderStatus',
      width: 180,
      key: 'workOrderStatus',
      onExport: true,
      render: (record, item) => carCompanyOrderStatus(record),
      exportRender: (record) => carCompanyOrderStatus(record.workOrderStatus),
    },
    {
      title: '工单所属区域',
      dataIndex: 'areaName',
      width: 180,
      key: 'areaName',
      onExport: true,
    },
    {
      title: '工单所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      width: 160,
      render: (record, item) => record.map((item, index) => {
        return bikeCompany(item) + ` `;
      }),
      exportRender: (record) => record?.company?.map((item) => bikeCompany(item)),
    },
    {
      title: '包片企业',
      key: 'contractCompany',
      dataIndex: 'contractCompany',
      width: 160,
      onExport: true,
      render: (record, item) => bikeCompany(record),
      exportRender: (record) => bikeCompany(record.contractCompany),
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime',
      width: 200,
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '完成时间',
      key: 'completeTime',
      dataIndex: 'completeTime',
      // fixed: 'right',
      width: 200,
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (record) => {
        return <>
          {/* <Link to={`details/${record.id}`}>详情</Link> */}
          <Link to={`details/${record.id}`} state={tab}>详情</Link>
        </>;
      },
    },
  ];

  const carCompanyColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: '所属企业',
      dataIndex: 'company',
      key: 'company',
      onExport: true,
      fixed: 'left',
      width: 120,
      render: (record, item) => bikeCompany(record),
      exportRender: (record) => bikeCompany(record.company),
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      width: 260,
      onExport: true,
    },
    {
      title: '工单所属区域',
      dataIndex: 'areaName',
      key: 'areaName',
      width: 180,
      // sorter: true,
      onExport: true,
    },
    {
      title: '城市管理者',
      dataIndex: 'managerName',
      key: 'managerName',
      width: 260,
      onExport: true,
    },
    {
      title: '运维负责人',
      dataIndex: 'responsibleName',
      width: 180,
      key: 'responsibleName',
      onExport: true,
    },
    {
      title: '联系电话',
      dataIndex: 'responsiblePhoneNumber',
      width: 180,
      key: 'responsiblePhoneNumber',
      onExport: true,
    },
    {
      title: '车企工单状态',
      dataIndex: 'workOrderStatus',
      width: 180,
      key: 'workOrderStatus',
      onExport: true,
      render: (record, item) => carCompanyOrderStatus(record),
      exportRender: (record) => carCompanyOrderStatus(record.workOrderStatus),
    },
    {
      title: '是否包片',
      dataIndex: 'contractArea',
      width: 180,
      key: 'contractArea',
      onExport: true,
      render: (record, item) => isContractArea(record),
      exportRender: (record) => isContractArea(record.contractArea),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      key: 'createTime',
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '接受时间',
      dataIndex: 'acceptTime',
      width: 180,
      key: 'acceptTime',
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '指派时间',
      dataIndex: 'distributeTime',
      width: 180,
      key: 'distributeTime',
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '完成时间',
      dataIndex: 'completeTime',
      width: 180,
      key: 'completeTime',
      onExport: true,
      render: (record) => {
        return <>
          <span>{record || '--'}</span>
        </>;
      },
    },
    {
      title: '详情',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (record) => {
        return <>
          {/* <Link to={`details/${record.id}`}>详情</Link> */}
          <Link to={`details/${record.id}`} state={tab}>详情</Link>
        </>;
      },
    },
  ];
  // 展开项
  const expandedRowRender = () => {
    const columns = [
      {
        title: '所属企业',
        dataIndex: 'company',
        key: 'company',
        render: (record, item) => bikeCompany(record),
        exportRender: (record) => bikeCompany(record.company),
        width: 180,
      },
      {
        title: '负责人',
        dataIndex: 'responsibleName',
        key: 'responsibleName',
        width: 180,
        render: (record) => {
          return <>
            <span>{record || '--'}</span>
          </>;
        },
      },
      {
        title: '车企工单状态',
        key: 'workOrderStatus',
        dataIndex: 'workOrderStatus',
        render: (record, item) => carCompanyOrderStatus(record),
        exportRender: (record) => carCompanyOrderStatus(record.workOrderStatus),
        width: 180,
      },
      {
        title: '接受时间',
        key: 'acceptTime',
        dataIndex: 'acceptTime',
        width: 280,
        render: (record) => {
          return <>
            <span>{record || '--'}</span>
          </>;
        },
      },
      {
        title: '完成时间',
        key: 'completeTime',
        dataIndex: 'completeTime',
        width: 280,
        render: (record) => {
          return <>
            <span>{record || '--'}</span>
          </>;
        },
      },
      {
        title: '完成人',
        key: 'completeName',
        dataIndex: 'completeName',
        width: 180,
        render: (record) => {
          return <>
            <span>{record || '--'}</span>
          </>;
        },
      },
    ];
    return <Table loading={tableLoading} columns={columns} dataSource={childData} pagination={false} />;
  };
  const Tab = [
    <div key={'1'}>
      <CustomTable
        noPageSize={true}
        noPageSizeComponent={true}
        dataElseItemStructure="bikeEnterpriseStatisticList"
        tableTypename={'车企统计'}
        InitApi={GETWORKORDERMANGAGELIST}
        scroll={true}
        formList={carCensusFormList}
        hidePageTitle={true}
        cantExportAction={true}
        resetData={updateList}
        columns={carCensusColumns}
        tip="车企统计导出中..."
        exportFileName="车企统计"
        setUpdateList={setUpdateList}
      />
      <div className="span-box"></div>
      <CustomTable
        noPageSize={true}
        dataElseItemStructure="managerStatisticList"
        tableTypename={'城市管理者统计'}
        InitApi={GETWORKORDERMANGAGELIST}
        scroll={true}
        formList={cityManageFormList}
        hidePageTitle={true}
        cantExportAction={true}
        resetData={updateList}
        columns={cityManageColumns}
        tip="城市管理者统计..."
        exportFileName="城市管理者统计"
        setUpdateList={setUpdateList}
      />
    </div>,
    <div key="2" className="data-tab">
      <CustomTable
        // noPageSize={true}
        changeListForTabKey={{ type: 1 }}
        // dataElseItemStructure="workOrderRecordList"
        InitApi={GETWORKORDERRECORDLIST}
        toIndex={true}
        scroll={true}
        formList={cityManageFormListTabTwo}
        hidePageTitle={true}
        resetData={updateList}
        columns={workOrderRecordColumns}
        setUpdateList={setUpdateList}
        tip="工单记录导出中..."
        exportFileName="工单记录"
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
          onExpand: ((expanded, record) => {
            setTableLoading(true);
            getOrderChildList({ workOrderId: record.id }, 2).then((res) => {
              setChildData(res.data);
            }).catch((err) => {
              console.log(err);
            }).finally(() => setTableLoading(false));
          }),
          onExpandedRowsChange: ((expandedRows) => {
            // 展开行变化触发
            console.log(expandedRows, 'expandedRows');
            setExpandedRowKeys(['0', expandedRows[expandedRows.length - 1]]);
          }),
          expandedRowKeys: expandedRowKeys, // 展开的行，控制属性
        }}

      />
    </div>,
    <div key="3" className="data-tab">
      <CustomTable
        // noPageSize={true}
        setUpdateList={setUpdateList}
        toIndex={true}
        changeListForTabKey={{ type: 2 }}
        // dataElseItemStructure="bikeEnterpriseRecordList"
        InitApi={GETWORKORDERRECORDLIST}
        scroll={true}
        formList={cityManageFormListTabThree}
        hidePageTitle={true}
        resetData={updateList}
        columns={carCompanyColumns}
        tip="车企记录导出中..."
        exportFileName="车企记录"
      />
    </div>,
  ];
  return (
    <>
      {!error ? (
        <div>
          <div>
            <SubTitleCustom
              // customTab={tab+1}
              tabKey={useLocation().state ? (parseInt(useLocation().state)) : parseInt(tab)}
              title="秩序及调运工单"
              tab={['工单统计', '工单记录', '车企记录']}
              onChangeTab={(e) => {
                setTab(e);
              }}
            />
            {Tab[tab]}
          </div>
        </div>
      ) : (
        <div className="no-data">
          <img src={noData}></img>
        </div>
      )}
    </>
  );
};

export default WorkOrder;
