import React, {useEffect, useState} from 'react';
import DetailsHeader from '../../../../components/detailsHeader';
import {Button, Card, Col, Divider, Modal, Row, Skeleton} from 'antd';
import {useLocation, useParams, Link} from 'react-router-dom';
import StaffInfo from '../../../../components/baseInfo/StaffInfo';
import {attendanceStatus, workStatus} from '../../../../components/statusCollection';
import AttendanceInfo from '../../../../components/baseInfo/AttendanceInfo';
import {getAttendanceDetails} from '../../../../api';
import {clockFormat} from '../../../../components/baseTime';
// import PropTypes from 'prop-types';

const AttendanceRecordDetails = () => {
  const l=useLocation().pathname.split('/');
  const d=useParams();
  const link=`/${l[1]}/${l[2]}/position/${d.id}/${d.operationId}`;
  const [visible, setVisible]= useState(false);
  const [info, setInfo]= useState({});
  const [loading, setLoading] = useState(false);
  const [baseInfo, setBaseInfo]=useState();
  const getDetails=()=>{
    setLoading(true);
    getAttendanceDetails(d.id, 2).then((res)=>{
      setInfo(res.data||{});
    }).finally(()=>{
      setLoading(false);
    });
  };
  const getInfo=(value)=>{
    setBaseInfo(value);
  };
  useEffect(()=>{
    getDetails();
  }, []);
  return (
    <div>
      <DetailsHeader pageName="考勤记录详情" buttonGroup={()=>
        (
          loading ? <Skeleton active paragraph={{rows: 0}}/>:baseInfo?.jobType!==7&&<Button type="primary">
            <Link to={link}>定位与轨迹</Link>
          </Button>)
      }/>
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <StaffInfo userID={d.operationId} getInfo={getInfo}/>
        <Divider />
        {loading?(<Skeleton active />):(<div>
          <h3 className="details-title">
            考勤结果
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>
                  考勤组名称：
                </span>
                <span className="text-gray">{info.groupName || '暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>
                考勤时段：
                </span>
                <span className="text-gray">{clockFormat(info.startTime)}-{clockFormat(info.endTime)} </span>
              </Col>
              <Col span={10}>
                <span>打卡规则：</span>
                <Button size="small" onClick={() => setVisible(true)}>查看详细规则</Button>
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>上班打卡结果：</span>
                {attendanceStatus(info.clockInStatus, 1)}
              </Col>
              <Col span={7}>
                <span>下班打卡结果：</span>
                {attendanceStatus(info.clockOutStatus)}
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>上班工作状态：</span>
                {workStatus(info.clockInJobStatus)}
              </Col>
              <Col span={7}>
                <span>下班工作状态：</span>
                {workStatus(info.clockOutJobStatus)}
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>上班打卡时间：</span>
                <span className="text-gray">{clockFormat(info.clockInTime) }</span>
              </Col>
              <Col span={7}>
                <span> 下班打卡时间：</span>
                {clockFormat(info.clockOutTime )}
              </Col>
            </Row>
            <Row className="margin-b-l" gutter={24}>
              <Col span={7}>
                <span>上班打卡位置：</span>
                <span className="text-gray">{info.clockInAddress||'暂无数据'}</span>
              </Col>
              <Col span={7}>
                <span>下班打卡位置：</span>
                <span className="text-gray">{info.clockOutAddress ||'暂无数据' }</span>
              </Col>
            </Row>
          </div>
        </div>)}
        <Divider />
        {loading?(<Skeleton active />):(<div >
          <h3 className="details-title">
            考勤备注
          </h3>
          <div className="details-info margin-t-l">
            <Row className="margin-b-l" gutter={24}>
              <Col span={24}>
                <span>
                  备注：
                </span>
                <span className="text-gray">{info.remark ||'暂无数据' }</span>
              </Col>
            </Row>
          </div>
        </div>)}
      </Card>
      <Modal
        title="详细考勤规则"
        footer={null}
        visible={visible}
        onCancel={()=>setVisible(false)}
      >
        <AttendanceInfo attendId={d.id}/>
      </Modal>
    </div>
  );
};

AttendanceRecordDetails.propTypes = {};

export default AttendanceRecordDetails;
