import React, {useState, useEffect} from 'react';
import CustomTable from '../../../components/customTable';
// import PropTypes from 'prop-types';
import {GETOPERATIONLIST} from '../../../api/Urls';
import {timeFormat} from '../../../components/baseTime';
import {getModuleList} from '../../../api';

const ConsoleLog = () => {
  const options=[];
  const [pageOptions, setPageOptions]=useState([]);
  const [updateList] = useState(0);
  const formList= [
    {
      label: '页面',
      name: 'menuId',
      key: 'menuId',
      col: 8,
      type: 'select',
      options: pageOptions,
      mode: 'multiple',
      placeholder: '请选择(多选)',
    },
    {
      type: 'datePicker',
      key: 'datePicker',
      name: 'datePicker',
      col: 8,
      label: '操作日期',
      format: 'YYYY-MM-DD',
    },
    {
      col: 8,
      key: 'blank',
      name: 'blank',
      type: 'blank',
    },
    {
      col: 8,
      key: 'address',
      name: 'composite',
      type: 'selectInput',
      label: '信息搜索',
      typeName: 'type',
      typeOptions: [
        {label: '管理员姓名', value: 1},
        {label: '用户名', value: 2},
      ],
      valueName: 'name',
    },
  ];
  const columns = [
    {
      title: '管理员',
      dataIndex: 'userName',
      key: 'userName',
      onExport: true,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      onExport: true,
    },
    {
      title: '页面',
      dataIndex: 'module',
      key: 'module',
      onExport: true,
    },
    {
      title: '功能',
      dataIndex: 'businessName',
      key: 'businessName',
      onExport: true,
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      key: 'createTime',
      onExport: true,
      sorter: true,
      render: (createTime)=>timeFormat(createTime),
      exportRender: (record) =>timeFormat(record.createTime),
    },
  ];
  const menuOptions=(arr)=>{
    arr.forEach((item)=>{
      if (item.children==0) {
        options.push({value: item.id, label: item.cnName});
      } else {
        menuOptions(item.children);
      }
    });
  };
  useEffect(()=>{
    getModuleList().then((res)=>{
      menuOptions(res.data.children);
      setPageOptions(options);
    });
  }, []);
  /*eslint-disable*/
  return (
    <div>
      <CustomTable
        InitApi={GETOPERATIONLIST}
        formList={formList}
        updateList={updateList}
        columns={columns}
        resType='POST'
        exportFileName="操作日志"
      />
    </div>
  );
  
};

ConsoleLog.propTypes = {};

export default ConsoleLog;
